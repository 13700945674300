import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getGetModalState } from '../../store/modal';
import OnboardingModal from '../modals/templateModals/onboardingModal';
import './productOnboarding.scss';
import { OnboardingSteps } from './steps';

export const onboardingID = 'onboarding-modal';
export const openOnboarding = openModalThunk(onboardingID);
export const closeOnboarding = closeModalThunk(onboardingID);

const ProductOnboarding = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [nextSlide, setNextSlide] = useState(0);
  const [remainingSteps, setRemainingSteps] = useState(OnboardingSteps.length - 1);
  const [startOnboarding, setStartOnboarding] = useState(false);
  const onboardingState = useSelector(getGetModalState(onboardingID));

  useEffect(() => {
    newModalThunk(onboardingID, false)();
  }, []);

  useEffect(() => {
    const onboardingFlag = localStorage.getItem('onboarding');
    if (!onboardingFlag || onboardingFlag === 'no' || onboardingState) {
      openOnboarding();
      setStartOnboarding(true);
      setIsOpen(true);
    } else {
      closeOnboarding();
      setStartOnboarding(false);
      setIsOpen(false);
    }
  }, [onboardingState]);

  const StepDisplay = (props: { step: number }) => {
    const Step = OnboardingSteps[props.step];

    return (
      <div className="step-body">
        <div className="title-section">
          <div className="step-counter" style={{ backgroundColor: Step.stepColor }}>
            {nextSlide + 1}
          </div>
          <div className="remaining-step-counter">
            <span>{remainingSteps !== 0 ? remainingSteps + ' steps left' : ''}</span>
          </div>
          <div className="title">{Step.title}</div>
        </div>

        <div className="media-section">
          {Step.media.type === 'video' && (
            <video
              className="media video"
              src={Step.media.url}
              preload="none"
              poster={Step.media.thumbnail}
              style={{ objectFit: 'fill' }}
              height="100%"
              width="100%"
              controls
              autoPlay
              loop
            />
          )}

          {Step.media.type === 'image' && (
            <img className="media image" src={Step.media.url} height="100%" width="100%" />
          )}

          {Step.media.type === 'embed' && (
            <iframe
              className="media embed"
              src={Step.media.url}
              height="100%"
              width="100%"
              allow="autoplay; encrypted-media; fullscreen;"
            />
          )}
        </div>

        <div className="description">
          <span>{Step.description}</span>
        </div>
      </div>
    );
  };

  return nextSlide !== OnboardingSteps.length && startOnboarding ? (
    <OnboardingModal
      isOpen={isOpen}
      showBack={false}
      header=""
      setActionOnClose={() => {
        // If onboarding is skipped, still set onboarding done is true
        setIsOpen(false);
        localStorage.setItem('onboarding', 'yes');
        closeOnboarding();
        setNextSlide(0);
      }}
      footerActionButtonLeft={nextSlide === 0 ? 'Skip' : 'Back'}
      setActionLeft={() => {
        if (nextSlide === 0) {
          // If onboarding is skipped, still set onboarding done is true
          setIsOpen(false);
          localStorage.setItem('onboarding', 'yes');
          closeOnboarding();
          setNextSlide(0);
        } else {
          setNextSlide(nextSlide - 1);
          setRemainingSteps(remainingSteps + 1);
        }
      }}
      footerActionButtonRight={OnboardingSteps[nextSlide].action}
      setActionRight={() => {
        // If final step is reached, set onboarding as true
        if (OnboardingSteps.length === nextSlide + 1) {
          localStorage.setItem('onboarding', 'yes');
          closeOnboarding();
          setNextSlide(0);
        }
        setNextSlide(nextSlide + 1);
        setRemainingSteps(remainingSteps - 1);
      }}
    >
      <StepDisplay step={nextSlide} />
    </OnboardingModal>
  ) : null;
};

export default ProductOnboarding;
