import firebase from 'firebase';
import { config, WASABIBASEURL } from '../../config';
import { setCustomClaimStore } from '../../store';
import { initPresence } from '../interactive/presence';
import userHelper from '../requests/users';

const googleProvider = new firebase.auth.GoogleAuthProvider();

if (!firebase.apps.length) firebase.initializeApp(config.firebase);

let presence: ReturnType<typeof initPresence> | undefined = undefined;
let roomIDOld: string = '';

let tempUser: firebase.User | null = null;

export async function signup(email: string, name: string, password: string) {
  try {
    await userHelper.updateGuestUser(email);
  } catch (e) {}
  let result = await firebase.auth().createUserWithEmailAndPassword(email, password);
  if (result.user) {
    await result.user.updateProfile({
      displayName: name,
    });
    tempUser = result.user;
  }
  if (result.user?.emailVerified) {
    tempUser = result.user;
    return result.user;
  } else {
    await result.user?.sendEmailVerification();
    tempUser = result.user;
    return null;
  }
}

export async function signinWithToken(token: string) {
  let result = await firebase.auth().signInWithCustomToken(token);
  return result.user;
}

export async function signin(email: string, password: string) {
  let result = await firebase.auth().signInWithEmailAndPassword(email, password);
  tempUser = result.user;
  if (result.user?.emailVerified) {
    return result.user;
  }
  return false;
}

export async function sendEmailVerification() {
  if (tempUser) {
    tempUser.sendEmailVerification();
  }
}

export function resetPassword(email: string) {
  firebase.auth().sendPasswordResetEmail(email);
}

export async function guestSignIn(email: string, name: string) {
  localStorage.setItem('guestName', name);
  localStorage.setItem('guestEmail', email);
  let result = await firebase.auth().signInAnonymously();
  // result.user?.updateEmail(email)
  // result.user?.updateProfile({ displayName: name })
  // if(result.user) {
  //   return result.user
  // }
  return null;
}

// export async function cleanUpCurrentRoomAndSpace(uid: string, spaceID: string, roomID: string) {
//   await firebase.firestore().collection('analytics').doc(`${presence}`).update({
//     till: firebase.firestore.FieldValue.serverTimestamp(),
//   });
//   if (presenceInterval) {
//     clearInterval(presenceInterval);
//   }
//   try {
//     await firebase.database().ref(`space/${spaceID}/room/${roomIDOld}/presence/${uid}`).set({
//       online: false,
//     });
//   } catch {}
// }

// export async function setCurrentRoomAndSpace(uid: string, spaceID: string, roomID: string) {
//   if (presence) {
//     await cleanUpCurrentRoomAndSpace(uid, spaceID, roomID);
//   }
//   roomIDOld = roomID;
//   presence = initPresence();
// }

export function signOut() {
  localStorage.removeItem('isUpdatedInDB');
  return firebase.auth().signOut();
}

export async function getClaimToken(setState = false, reissue = true) {
  const token = await firebase.auth().currentUser?.getIdTokenResult(reissue);
  if (token && setState) {
    setCustomClaimStore(token.claims as any);
  }
  return token;
}

export function onAuthChange(callback: (user: firebase.User | null) => void) {
  firebase.auth().onAuthStateChanged((user) => {
    let isAnonymous = user?.isAnonymous;
    if (user?.email === null) isAnonymous = true;
    if (user?.emailVerified || (user && isAnonymous)) {
      if (user?.isAnonymous && !localStorage.getItem('isUpdatedInDB')) {
        // try {
        //   firebase
        //     .firestore()
        //     .collection('users')
        //     .doc(user?.uid)
        //     .update({
        //       email: user?.email,
        //       displayName: user?.displayName,
        //     })
        //     .catch((e) => {});
        //   firebase
        //     .firestore()
        //     .collection('userV2')
        //     .doc(user?.uid)
        //     .update({
        //       email: user?.email,
        //       displayName: user?.displayName,
        //     })
        //     .catch((e) => {});
        //   localStorage.setItem('isUpdatedInDB', 'true');
        // } catch {}
      }
      try {
        if (window.smartlook) {
          window.smartlook('identify', user.uid, {
            name: user.displayName,
            email: user.email,
            verified: user.emailVerified,
            guest: user.isAnonymous,
          });
        }
      } catch {}
      callback({ ...user, isAnonymous: isAnonymous !== undefined ? isAnonymous : false });
    } else {
      callback(null);
    }
  });
}

export async function getToken() {
  return await firebase.auth().currentUser?.getIdToken(true);
}

export function initSignInWithGoogle() {
  return firebase.auth().signInWithPopup(googleProvider);
}

export async function completeSignInWithGoogle() {
  let result = await firebase.auth().getRedirectResult();
  return result.user;
}

export async function getEmailFromAppsumoOrder(billingID: string) {
  return await firebase.firestore().doc(`/billingOrders/${billingID}`).get();
}

export function watchBillingDetails(billingID: string) {
  return firebase.firestore().doc(`/plans/${billingID}`);
}

export function watchProfilePic() {
  let id = firebase.auth().currentUser?.uid;
  return firebase.firestore().doc(`/users/${id}`);
}

export async function setProfilePic(path: string, isAvatar: boolean) {
  let id = firebase.auth().currentUser?.uid;
  if (!isAvatar) {
    await firebase
      .firestore()
      .doc(`/userV2/${id}`)
      .set(
        {
          profilePic: WASABIBASEURL + path,
        },
        { merge: true }
      );
  } else {
    await firebase.firestore().doc(`/userV2/${id}`).set(
      {
        profilePic: path,
      },
      { merge: true }
    );
  }
}

export async function removeProfilePic() {
  let id = firebase.auth().currentUser?.uid;
  await firebase.firestore().doc(`/userV2/${id}`).set(
    {
      profilePic: '',
    },
    { merge: true }
  );
}

export async function setUserStatus(profileStatus: {
  name: string;
  about: string;
  status: string;
  socialProfiles?: {
    twitter?: string;
    linkedin?: string;
    facebook?: string;
    phone?: string;
    email?: string;
    link?: string;
  };
}) {
  let id = firebase.auth().currentUser?.uid;
  if (profileStatus.name === '') {
    await firebase.firestore().doc(`/userV2/${id}`).set(
      {
        desc: profileStatus.about,
        status: profileStatus.status,
        socialProfiles: profileStatus.socialProfiles,
      },
      { merge: true }
    );
  } else {
    await firebase.firestore().doc(`/userV2/${id}`).set(
      {
        name: profileStatus.name,
        desc: profileStatus.about,
        status: profileStatus.status,
        socialProfiles: profileStatus.socialProfiles,
      },
      { merge: true }
    );
  }
}

export function getUserFromFirebase() {
  return firebase.auth().currentUser;
}
