import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { createApi, OrderBy } from 'unsplash-js';
import { canvasSize } from '../../../config';
import { getCanvasChildren, setTemplate, setWallpaper, watchTemplates } from '../../../helper/interactive/firebaseRTD';
import { getSpacesRooms } from '../../../store/spacesRooms';
import Loader from '../../loader/loader';
import { useOutsideEvent } from '../giphy/Giphy';
import './unsplash.scss';

const unsplash = createApi({
  accessKey: 'QuQloxA_Jjsv7SsOfVFD1pPNglEwGbBfpmZ0qSltJEg',
});

const Grid = (props: { photos: any[]; loadMore: () => void; saveWallpaper: (url: string) => void }) => {
  const ref = useBottomScrollListener(props.loadMore, {
    offset: 100,
    debounce: 500,
    triggerOnNoScroll: false,
  });
  const saveWallpaper = (photo: any) => {
    unsplash.photos.trackDownload({ downloadLocation: photo.links.download_location });
    props.saveWallpaper(photo.urls?.full);
  };
  return (
    // @ts-ignore
    <div className="grid" ref={ref}>
      {props.photos.map((photo) => {
        // if(!photo.urls) return <></>
        if (photo.width < canvasSize.w || photo.height < canvasSize.h) {
          return <></>;
        }
        return (
          <div key={photo.id} className="img-container">
            <img src={photo.urls?.thumb} alt="unsplash Background" onClick={() => saveWallpaper(photo)} />
            <div className="attr-unsplash">
              Photo by{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href={photo.user.links.html + '?utm_source=reslash&utm_medium=referral'}
              >
                {photo.user.name}
              </a>{' '}
              on
              <a
                href={photo.user.links.html + '?utm_source=reslash&utm_medium=referral'}
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                Unsplash
              </a>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TemplateGrid = (props: { canvasID: string; spaceID: string; toClose: () => void; saveWallpaper: Function }) => {
  const [templates, setTemplates] = useState<{ [id: string]: any }>({});
  useEffect(() => {
    const unsub = watchTemplates((templates) => {
      setTemplates(templates);
    });
    return () => unsub();
  }, []);

  const set = (data: any) => {
    /**
     * Change the background
     */
    // props.saveWallpaper(data.wallpaper);

    /**
     * Get all the current canvas children
     */

    getCanvasChildren(props.spaceID, props.canvasID, (oldData: any) => {
      /**
       * take the union of oldData from canvas and newData from the template
       */
      const newData: any = {};
      Object.keys(oldData || {}).forEach((key: string) => {
        if (oldData[key].t !== 'gif') {
          newData[key] = oldData[key];
        }
      });
      setTemplate(props.spaceID, props.canvasID, {
        ...data,
        obj: { ...newData, ...(data.obj || {}) },
      });
    });

    props.toClose();
  };
  return (
    <div className="grid">
      {_.map(templates, (template) => {
        return (
          <div key={template.title} className="img-container" onClick={() => set(template.data)}>
            <img src={template.img} alt="template" className="static" />
            <img src={template.gif} alt="templateGif" className="gif" />
            <div className="title">{template.title}</div>
          </div>
        );
      })}
    </div>
  );
};

const CustomBackground = (props: { saveWallpaper: (url: string) => void }) => {
  // const [img, setImg] = useState<HTMLImageElement[]>([]);
  const [url, setURL] = useState<string>('');
  const [loading, setLoading] = useState(0);
  const [error, setErr] = useState<string>('');
  const setImage = (url: string) => {
    const image = document.getElementById('bg');
    if (image) image.style.backgroundImage = `url(${url})`;
  };
  const loadImg = (url: string) => {
    const img_ = new Image();
    setLoading(1);
    setErr('');
    img_.onload = function () {
      if (img_.height >= canvasSize.h && img_.width >= canvasSize.w) {
        setImage(url);
        setLoading(2);
      } else {
        setImage(url);
        setLoading(4);
        setErr(
          `Image should be atleast ${canvasSize.w}x${canvasSize.h}px, the image you provided is ${img_.width}x${img_.height}px`
        );
      }
    };
    img_.onerror = () => {
      setLoading(3);
      setErr(`Image could not be found, please check url.`);
    };
    img_.src = url;
  };
  return (
    <div className="customBg">
      <div className="search">
        <input
          className="unsplash-input"
          placeholder="Enter URL"
          value={url}
          onChange={(e) => setURL(e.target.value)}
        ></input>
        <div className="btn" onClick={() => loadImg(url)}>
          Go
        </div>
      </div>
      <div className="preview">
        {loading === 1 && <Loader />}
        {loading === 0 && (
          <div className="fillComplete center">The recommended and minimum image size is 3200x1800px.</div>
        )}
        {error.length > 0 && (
          <div className="message error">
            <span className="material-icons">error_outline</span> {error}
          </div>
        )}
        {loading === 5 && (
          <div className="message success">
            <span className="material-icons">done</span> Wallpaper saved!
          </div>
        )}
        <div id="bg" className={loading === 2 || loading === 4 ? '' : 'hide'} />
        {loading === 2 && (
          <div
            className="btn"
            onClick={() => {
              setLoading(5);
              props.saveWallpaper(url);
              setTimeout(() => setLoading(0), 2000);
            }}
          >
            Set Wallpaper
          </div>
        )}
      </div>
    </div>
  );
};
const Unsplash = (props: { canvasID: string; spaceID: string; toClose: () => void }) => {
  const [photos, setPhotos] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const spacesRooms = useSelector(getSpacesRooms);
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideEvent(ref, props.toClose, 'wallpaper_btn');

  const saveWallpaper = (url: string) => {
    setWallpaper(props.spaceID, props.canvasID, url);
    props.toClose();
  };
  const [searchOrPopular, setSearchOrPopular] = useState<'search' | 'popular'>('popular');
  useEffect(() => {
    setSearchOrPopular('popular');
    unsplash.photos.list({ perPage: 20, orderBy: OrderBy.POPULAR }).then((result) => {
      if (result.response) {
        setPhotos([...result.response.results]);
        setPage(2);
      }
    });
  }, []);
  const loadMore = () => {
    if (photos.length === 0) return;
    if (searchOrPopular === 'popular') {
      unsplash.photos.list({ perPage: 20, orderBy: OrderBy.POPULAR, page: page }).then((result) => {
        if (result.response) {
          setPhotos([...photos, ...result.response.results]);
          setPage(page + 1);
        }
      });
    } else {
      unsplash.search.getPhotos({ query: search, perPage: 20, page: page }).then((result) => {
        if (result.response) {
          setPhotos([...photos, ...result.response.results]);
          setPage(page + 1);
        }
      });
    }
  };

  const Search = () => {
    if (search.length === 0) {
      setSearchOrPopular('popular');
      unsplash.photos.list({ perPage: 20, orderBy: OrderBy.POPULAR, page: page }).then((result) => {
        if (result.response) {
          setPhotos([...photos, ...result.response.results]);
          setPage(page + 1);
        }
      });
    } else {
      setSearchOrPopular('search');
      unsplash.search.getPhotos({ query: search, perPage: 20, page: 1 }).then((result) => {
        if (result.response) {
          setPhotos([...result.response.results]);
          setPage(2);
        }
      });
    }
  };
  return (
    <div ref={ref}>
      <Tabs className="fillComplete">
        <TabList className="tablist">
          <Tab>Templates</Tab>
          <Tab>Wallpapers</Tab>
          {spacesRooms.permissionV2[spacesRooms.currentUserRole]?.urlwallpaper && <Tab>Wallpaper URL</Tab>}
        </TabList>
        <TabPanel>
          <TemplateGrid
            spaceID={props.spaceID}
            canvasID={props.canvasID}
            toClose={props.toClose}
            saveWallpaper={saveWallpaper}
          />
        </TabPanel>
        <TabPanel>
          <div className="search">
            <input
              className="unsplash-input"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></input>
            <div className="btn" onClick={Search}>
              Go!
            </div>
          </div>
          <Grid photos={photos} loadMore={loadMore} saveWallpaper={saveWallpaper} />
        </TabPanel>
        {spacesRooms.permissionV2[spacesRooms.currentUserRole]?.urlwallpaper && (
          <TabPanel className="fillComplete">
            <CustomBackground saveWallpaper={saveWallpaper} />
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
};

export default Unsplash;
