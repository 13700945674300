import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getGetModalState } from '../../store/modal';
import { getSpacesRooms } from '../../store/spacesRooms';
import { ModalStyle } from '../modal/modal';
import './roomSettings.scss';

let onConfirm = () => {};
let onCancel = () => {};

export const confirmAction = 'confirm-action';
let confirmMessage = 'Are you sure?';
export const openConfirmAction = (onConfirm_: () => void, onCancel_: () => void, confirmMessage_?: string) => {
  onConfirm = onConfirm_;
  onCancel = onCancel_;
  if (confirmMessage_) confirmMessage = confirmMessage_;
  else confirmMessage = 'Are you sure?';
  return openModalThunk(confirmAction);
};

// openConfirmAction(func1, func2)()
export const closeConfirmAction = closeModalThunk(confirmAction);

const ConfirmAction = () => {
  const state = useSelector(getGetModalState(confirmAction));
  const spacesRooms = useSelector(getSpacesRooms);
  const [msg, setMsg] = useState<string>('');
  useEffect(() => {
    newModalThunk(confirmAction, false)();
  }, []);
  return (
    <ReactModal
      isOpen={state}
      id="roomSettings"
      contentLabel={`${confirmAction} Modal`}
      style={ModalStyle}
      shouldCloseOnOverlayClick={true}
    >
      <>
        <div className="body">
          <div className="header hr-container">{confirmMessage}</div>
          <div className="bottom btn-container">
            <div className="left"></div>
            <div className="right">
              <div
                className="button"
                onClick={() => {
                  onConfirm();
                  closeConfirmAction();
                }}
              >
                Yes
              </div>
              <div
                className="button red"
                onClick={() => {
                  onCancel();
                  closeConfirmAction();
                }}
              >
                No
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="success-message">{msg}</div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default ConfirmAction;
