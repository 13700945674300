import React, { useEffect, useRef, useState } from 'react';
import { openShareLinkModal } from '../shareLinkBoxModal';
import './style.scss';

function ShareLinkBoxViewer(props: {
  url: string;
  title: string;
  favicon: string;
  onDeleteTrigger: Function;
  onUpdate: Function;
  watcher: Function;
  isAdmin: boolean;
  uname: string;
  uid: string;
  localUname: string;
  localUid: string;
}) {
  const [isFailedToLoadImg, setIsFailedToLoadImg] = useState(false);
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [favicon, setFavicon] = useState('');
  const faviconRef = useRef<string>('');

  const [isImageLoading, setIsImageLoading] = useState(true);

  const onMouseDownTimeRef = useRef<Date | null>(null);

  const onImageLoad = () => {
    setIsImageLoading(false);
    setIsFailedToLoadImg(false);
  };

  const onImageLoadFailed = () => {
    setIsFailedToLoadImg(true);
    setIsImageLoading(false);
  };

  const openUrlInNewTab = () => {
    window.open(props.url);
  };

  const onMouseDown = () => {
    onMouseDownTimeRef.current = new Date();
  };

  const onMouseUp = () => {
    if (onMouseDownTimeRef.current) {
      const secondsBetweenDownAndUp = getSecondsBetweenDates(onMouseDownTimeRef.current, new Date());
      if (secondsBetweenDownAndUp < 0.5) {
        openUrlInNewTab();
      }
    }
  };

  const getSecondsBetweenDates = (t1: Date, t2: Date) => {
    const dif = t1.getTime() - t2.getTime();
    const Seconds_from_T1_to_T2 = dif / 1000;
    return Math.abs(Seconds_from_T1_to_T2);
  };

  const onEdit = () => {
    openShareLinkModal({ title: title, url: url, updateCallback: props.onUpdate });
  };

  const changeFavicon = (favicon: string) => {
    faviconRef.current = favicon;
    setFavicon(favicon);
    if (favicon === '') {
      setIsImageLoading(false);
      setIsFailedToLoadImg(true);
    } else {
      setIsImageLoading(true);
      setIsFailedToLoadImg(false);
    }
  };
  const t = () => {
    changeFavicon(props.favicon);
    setTitle(props.title);
    setUrl(props.url);

    const mainWatcher = props.watcher((d: any) => {
      if (d && d.d.u !== url) {
        setUrl(d.d.u);
      }
      if (d && d.d && d.d.favicon !== faviconRef.current) {
        changeFavicon(d.d.favicon);
      }
      if (d && d.d.title !== title) {
        setTitle(d.d.title);
      }
    });
    return () => {
      mainWatcher();
    };
  };
  useEffect(() => {
    // fetchImg(props.url);
    return t();
  }, []);

  const shouldShowFallbackImage = () => {
    if (isFailedToLoadImg && !isImageLoading) {
      return true;
    }
    return false;
  };

  const shouldShowImage = () => {
    if (!isFailedToLoadImg && !isImageLoading) {
      return true;
    }
    return false;
  };

  const showLoader = () => {
    return isImageLoading;
  };

  const shouldShowEdit = () => {
    if (props.isAdmin || props.localUid === props.uid) {
      return true;
    }
    return false;
  };

  const shouldShowDelete = () => {
    if (props.isAdmin || props.localUid === props.uid) {
      return true;
    }
    return false;
  };

  return (
    <div style={{ cursor: 'pointer' }} className="share-linkbox-viewer">
      <div className={`loader ${showLoader() ? '' : 'hide'}`} />
      <div onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchStart={onMouseDown} onTouchEnd={onMouseUp}>
        <img
          className={`share-linkbox-viewer-img pointer-event-none ${shouldShowImage() ? '' : 'hide'}`}
          src={favicon}
          onLoad={onImageLoad}
          onError={onImageLoadFailed}
          alt=""
        />
        <span className={`material-icons link ${shouldShowFallbackImage() ? '' : 'hide'}`}>link</span>
      </div>
      <p className="title" title={title}>
        {title}
      </p>
      <div className="actions">
        {shouldShowEdit() && (
          <button
            className="edit"
            onClick={() => {
              onEdit();
            }}
          >
            Edit
          </button>
        )}
        {shouldShowDelete() && (
          <button className="delete" onClick={() => props.onDeleteTrigger()}>
            Delete
          </button>
        )}
      </div>
    </div>
  );
}

export default ShareLinkBoxViewer;
