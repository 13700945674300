import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { addNewCanvasChild } from '../../../helper/interactive/firebaseRTD';
import { getSpawnLocation } from '../../../helper/interactive/misc';
import { closeModalThunk, getAuthState, getListenerPosition, newModalThunk, openModalThunk } from '../../../store';
import { getGetModalState } from '../../../store/modal';
import { ModalStyle } from '../../modal/modal';
import '../ytDialog/ytDialog.scss';
import './insertImage.scss';

const insertImage = 'insertimage-modal';

export const openInsertImage = openModalThunk(insertImage);

export const closeInsertImage = closeModalThunk(insertImage);

const InsertImage = (props: { canvasID: string; spaceID: string; toClose: () => void }) => {
  const isOpen = useSelector(getGetModalState(insertImage));

  useEffect(() => {
    newModalThunk(insertImage, false)();
  }, []);
  const [url, setURL] = useState('');
  const [msg, setMsg] = useState('');
  const ref = useRef<HTMLDivElement | null>(null);

  const onCancel = () => {
    setURL('');
    setMsg('');
    closeInsertImage();
  };

  const onSave = () => {
    setMsg('Loading...');
    const img = new Image();
    const spawnLocation = getSpawnLocation(getListenerPosition(), 300);
    img.onload = () => {
      addNewCanvasChild(props.spaceID, props.canvasID, {
        w: 300,
        h: 300 * (img.width && img.height ? img.height / img.width : 1),
        x: spawnLocation.x,
        y: spawnLocation.y,
        t: 'image',
        d: { u: img.src, uid: getAuthState().user.id },
      });
      setMsg('');
      props.toClose();
    };
    img.onerror = () => {
      setMsg('Unable to load image, please check URL.');
    };
    img.src = url;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      id={`linkbox-modal`}
      contentLabel={`${insertImage} Modal`}
      // overlayClassName="hide"
      shouldCloseOnOverlayClick={true}
      style={ModalStyle}
    >
      <div ref={ref} className="">
        <div className="linkbox-modal-header">Insert Image</div>
        <div className="content">
          <div className="input-box">
            <div className="label">
              URL <span className="required">*</span>
            </div>
            <input placeholder="Link" value={url} onChange={(e) => setURL(e.target.value)}></input>
            <div className="second-txt">This must be a full URL including https:// part (URL must be https://).</div>
          </div>
        </div>
        <div className="hor-divider"></div>
        <div className="actions">
          <button className="btn cancel" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn" onClick={onSave}>
            Save
          </button>
        </div>
        <span className="noti">{msg}</span>
      </div>
    </ReactModal>
  );
};

export default InsertImage;
