import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getGetModalState } from '../../store/modal';
import { ModalStyle } from '../modal/modal';
import './startRecordingModal.scss';

let onConfirm = () => {};
let onCancel = () => {};

export const errorRecordingModalID = 'error-recording-id';
export const openErrorRecordingModal = (onConfirm_: () => void, onCancel_: () => void) => {
  onConfirm = onConfirm_;
  onCancel = onCancel_;
  return openModalThunk(errorRecordingModalID);
};
export const closeErrorRecordingModal = closeModalThunk(errorRecordingModalID);

export const ErrorRecordingModal = () => {
  const errorRecordingState = useSelector(getGetModalState(errorRecordingModalID));
  useEffect(() => {
    newModalThunk(errorRecordingModalID, false)();
  }, []);
  return (
    <ReactModal
      id={errorRecordingModalID}
      className={errorRecordingModalID}
      isOpen={errorRecordingState}
      style={ModalStyle}
    >
      <>
        <div className="body">
          <div className="title">Your browser is not compatible</div>
          The recording could not be instantiated right now. Try using another browser.
          <br />
          <br />
          Checkout this help{' '}
          <a href="https://reslash.tawk.help/article/how-to-use-recording" target="_blank">
            article
          </a>{' '}
          to know more about this recording feature.
          <br />
          <br />
          For more details, you can also reach out to{' '}
          <a href="mailto:support@reslash.co" target="_blank">
            support@reslash.co
          </a>
        </div>
        <div className="footer">
          <div className="buttons">
            <div
              className="cancel-btn"
              onClick={() => {
                onCancel();
                closeErrorRecordingModal();
              }}
            >
              Okay
            </div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default ErrorRecordingModal;
