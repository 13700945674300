import React, { useEffect, useState } from 'react';
import { ChevronsRight, Edit, MessageSquare, MoreHorizontal, Share2, Sliders, User } from 'react-feather';
import { useSelector } from 'react-redux';
import 'tippy.js/dist/tippy.css';
import ReslashLogo from '../../assets/svg/reslashLogo';
import Broadcast from '../../components/broadcast/broadcast';
import { RoomClosedModal, RoomClosedPermanentModal } from '../../components/customiseLogin/scheduledRoomModal';
import RoomSwitcher from '../../components/eventsUI/roomSwitcher';
import Helpdesk from '../../components/helpdeskWidget/helpdesk';
import InactivityModal, { KickedInactivityModal } from '../../components/inactivity/inactivity';
import Canvas from '../../components/interactive/canvas/canvas';
import CanvasView from '../../components/interactive/canvasView/canvasView';
import Room from '../../components/interactive/room/dailyRoom';
import InviteAdmin from '../../components/inviteAdmin/inviteAdmin';
import PasswordModal from '../../components/modals/passwordModal/passwordModal';
import { closeProfileEditModal } from '../../components/modals/templateModals/profileModal';
import NoPermissionModal from '../../components/noPermissionModal/noPermissionModal';
import Notifications from '../../components/notifications/notifications';
import PermissionV2Watcher from '../../components/permissionV2/permissionV2';
import RoomPermissions from '../../components/permissionV2/roomPermissions';
import PresenceV2 from '../../components/presenceV2/presenceV2';
import ProductOnboarding from '../../components/productOnboarding/productOnboarding';
import ProfileSetUpModal from '../../components/profile/profileSetupModal';
import ErrorRecordingModal from '../../components/recorder/errorRecordingModal';
import PreviewRecordingModal from '../../components/recorder/previewRecordingModal';
import StartRecordingModal from '../../components/recorder/startRecordingModal';
import ConfirmAction from '../../components/roomSettings/confirmAction';
import DeleteConfirm from '../../components/roomSettings/deleteConfirm';
import ShareWidget from '../../components/shareWidget/shareWidget';
import Sidebar from '../../components/sidebar/sidebar/sidebar';
import SpaceSwitcherModal from '../../components/spaceSwitcher/spaceSwitcher';
import { sendAmplitudeData } from '../../helper/amplitude';
import { formatLong, playSound, useTitle } from '../../helper/interactive/misc';
import {
  setRoomCreateOrEditStore,
  toggleBackgroundMenuOpenStore,
  toggleChatOpenStore,
  toggleMembersMenuStore,
  togglePermissionMenuStore,
  toggleRoomDetailsMenuStore,
  toggleRoomSettingsMenuStore,
  toggleRoomWallMenuStore,
  toggleSidebarStore,
} from '../../store';
import { getUnreadCount } from '../../store/chat';
import { getMediaPermissionsRedux } from '../../store/dailycoParticipants';
import { getHideMenuState } from '../../store/hideMenu';
import { getSidebarMenuStateRedux, getSidebarMenuStatusRedux } from '../../store/settingsMenu';
import { getSpacesRooms } from '../../store/spacesRooms';
import LoginPage from '../login/login';
import CustomiseDropDown from './customiseDropdown';
import DropDownRoom from './dropDown';
import NumPeopleInRoom from './numPeopleInRoom';
import './roomPage.scss';
import VolumeComponent from './volComp';

export const Title = (props: { title: string }) => {
  useTitle(props.title);
  return <></>;
};

const RoomPage = (props: { roomID: string; spaceID: string }) => {
  const [toggleStarted, settoggleStarted] = useState(false);
  // const [menuState, setMenuState] = useState(false);
  const menuState = useSelector(getSidebarMenuStateRedux);
  const menuStatus = useSelector(getSidebarMenuStatusRedux);
  const unreadMessages = useSelector(getUnreadCount);
  const spacesRooms = useSelector(getSpacesRooms);
  const [dropDownState, setDropDownState] = useState(false);
  const [toggleHelpdesk, setToggleHelpdesk] = useState(false);
  const [toggleShare, setToggleShare] = useState(false);
  const isGuest = spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isGuest;
  const isAdmin = spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin;
  const [showRoomPermissions, setShowRoomPermissions] = useState(false);
  const [showCustomiseDropdown, setShowCustomiseDropDown] = useState(false);
  const isPassword = spacesRooms.rooms[props.roomID]?.roomDetails?.enabledPassword;
  const locked = !(
    isAdmin ||
    (spacesRooms.currentRoomPassword && spacesRooms.currentRoomPassword?.length > 0) ||
    !isPassword
  );
  const fromTime = spacesRooms.rooms[props.roomID]?.roomDetails?.schedule?.from;
  const tillTime = spacesRooms.rooms[props.roomID]?.roomDetails?.schedule?.till;
  const isEnded = tillTime !== undefined && tillTime !== -1 && tillTime < new Date().getTime() / 1000;
  const isClosed =
    !spacesRooms.rooms[props.roomID]?.permission.isAdmin &&
    ((fromTime && fromTime > new Date().getTime() / 1000) || isEnded);

  const mediaPermissions = useSelector(getMediaPermissionsRedux);
  const isRoomAccessible =
    !locked && mediaPermissions?.permissionState === 2 && (!isClosed || spacesRooms.kickedOutOnEnd);

  const closeSidebar = () => {
    const toggleUI = document.getElementById('menuToggle');
    toggleSidebarStore(false);
    if (toggleUI) {
      toggleUI.style.left = '0px';
      const broadcastListener = document.getElementById('broadcast-listener');
      if (broadcastListener) {
        broadcastListener.style.left = '51px';
      }
    }
  };
  const openSidebar = () => {
    const toggleUI = document.getElementById('menuToggle');
    settoggleStarted(true);
    toggleSidebarStore(true);
    if (toggleUI) {
      toggleUI.style.left = '360px';
      const broadcastListener = document.getElementById('broadcast-listener');
      if (broadcastListener) {
        broadcastListener.style.left = '360px';
      }
    }
  };

  useEffect(() => {
    if (menuState) openSidebar();
    else closeSidebar();
  }, [menuState]);

  useEffect(() => {
    const adjust = () => {
      const toggleUI = document.getElementById('menuToggle');
      const sidebar = document.getElementsByClassName('sidebar')[0];
      if (sidebar) {
        if (toggleUI) {
          toggleUI.style.left = sidebar.getBoundingClientRect().width + 10 + 'px';
        }
      }
    };
    window.addEventListener('resize', adjust);
    return () => window.removeEventListener('resize', adjust);
  }, []);

  useEffect(() => {
    if (unreadMessages > 0) playSound('/sound/chatSound.mp3');
  }, [unreadMessages]);

  const hideMenuState = useSelector(getHideMenuState);
  const roomName = spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails.roomName ?? '';

  return (
    <div className="room">
      <PresenceV2 />
      <PermissionV2Watcher spaceID={props.spaceID} />
      <div className={menuState ? 'menu menu-active' : 'menu'}>
        <Sidebar toClose={closeSidebar} style={{ display: menuState ? 'flex' : 'none' }} />
      </div>
      {isRoomAccessible && (
        <div className="menuToggle" id="menuToggle" style={hideMenuState ? { display: 'none' } : {}}>
          {!menuState && (
            <div className="menuIcon has-v2-tooltip-shifted" data-tooltip-text="Sidebar" onClick={openSidebar}>
              <ChevronsRight />
            </div>
          )}
          <div
            className="room-name-container"
            id="room-name-container"
            style={hideMenuState ? { display: 'none' } : {}}
          >
            <div
              className="roomName"
              style={spacesRooms.currentUserRole !== 'h' && isGuest ? { borderRightWidth: '3px' } : {}}
            >
              <span
                className={roomName.length > 24 ? 'has-v2-tooltip-small-long' : ''}
                style={{ paddingRight: '10px' }}
                data-tooltip-text={roomName.length > 24 ? roomName : ''}
              >
                {formatLong(roomName, 24)}
              </span>
              {(spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isAdmin ||
                spacesRooms.currentUserRole === 'h') && (
                <div
                  className={
                    'room-settings-icon ' +
                    'has-v2-tooltip-vlong ' +
                    (spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isAdmin ||
                    spacesRooms.currentUserRole === 'h'
                      ? 'admin'
                      : '')
                  }
                  data-tooltip-text="Room permissions"
                  style={{
                    cursor:
                      spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isAdmin ||
                      spacesRooms.currentUserRole === 'h'
                        ? 'pointer'
                        : 'default',
                    borderRadius: isGuest ? '0px 16px 16px 0px' : '0px',
                  }}
                  onClick={() => {
                    if (
                      spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isAdmin ||
                      spacesRooms.currentUserRole === 'h'
                    ) {
                      setShowRoomPermissions(!showRoomPermissions);
                      setToggleShare(!toggleShare);
                      sendAmplitudeData('Room permissions clicked', {
                        state: !showRoomPermissions ? 'Enabled' : 'Disabled',
                      });
                    }
                  }}
                >
                  <Sliders color={'white'} size={18} />
                </div>
              )}

              {!isGuest && (
                <div
                  className="room-share-icon has-v2-tooltip-vlong "
                  data-tooltip-text="Share room link"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setToggleShare(!toggleShare);
                    setShowRoomPermissions(false);
                    sendAmplitudeData('Share room clicked', { state: !toggleShare ? 'Enabled' : 'Disabled' });
                  }}
                >
                  <Share2 color={'black'} size={18} />
                </div>
              )}
            </div>
            <div
              className="roomPermissionContainer"
              style={{
                display: showRoomPermissions ? 'block' : 'none',
                position: 'absolute',
                top: '40px',
                background: '#000000',
                borderRadius: 12,
                paddingRight: 10,
                paddingTop: 16,
                paddingBottom: 16,
                zIndex: 10,
              }}
            >
              <RoomPermissions
                onClose={() => {
                  setShowRoomPermissions(false);
                }}
              />
            </div>
            <div
              className="roomShareContainer"
              style={{
                display: toggleShare ? 'block' : 'none',
                position: 'absolute',
                top: '40px',
                zIndex: 10,
              }}
            >
              <ShareWidget
                onClose={() => {
                  setToggleShare(false);
                }}
              />
            </div>
          </div>
          <>
            <div
              className="membersOnline has-v2-tooltip-medium"
              style={hideMenuState ? { display: 'none' } : {}}
              data-tooltip-text="People Online"
              onClick={() => {
                if (menuState && !menuStatus.roomSettingsOpen && !menuStatus.chatOpen) {
                  closeSidebar();
                  toggleRoomWallMenuStore(false);
                  sendAmplitudeData('Members in room clicked', { state: 'Disabled' });
                } else {
                  openSidebar();
                  toggleRoomSettingsMenuStore(false);
                  togglePermissionMenuStore(false);
                  toggleChatOpenStore(false);
                  toggleMembersMenuStore(false);
                  toggleRoomDetailsMenuStore(false);
                  toggleRoomWallMenuStore(false);
                  setRoomCreateOrEditStore('edit');
                  toggleBackgroundMenuOpenStore(false);
                  sendAmplitudeData('Members in room clicked', { state: 'Enabled' });
                }
              }}
            >
              <User />
              <NumPeopleInRoom roomID={props.roomID} />
            </div>
            <div
              className="chatOpenContainer has-v2-tooltip"
              data-tooltip-text="chat"
              style={hideMenuState ? { display: 'none' } : {}}
            >
              <div
                className="chatOpen"
                onClick={() => {
                  if (menuState && menuStatus.chatOpen) {
                    closeSidebar();
                    sendAmplitudeData('Chat button clicked', { status: 'Enabled' });
                  } else {
                    openSidebar();
                    sendAmplitudeData('Chat button clicked', { state: 'Disabled' });
                    toggleChatOpenStore(true);
                    toggleBackgroundMenuOpenStore(false);
                  }
                }}
              >
                <MessageSquare />
              </div>
              {unreadMessages > 0 && (
                <>
                  <div className="chatCounter">
                    <span>{unreadMessages}</span>
                  </div>
                  <Title
                    title={unreadMessages.toString() + (unreadMessages === 1 ? ' new message !' : ' new messages !')}
                  />
                </>
              )}
            </div>

            {spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isAdmin && (
              <div
                className="customizeOpenContainer has-v2-tooltip"
                style={hideMenuState ? { display: 'none' } : {}}
                data-tooltip-text="customize"
                id="customizeOpenContainer"
              >
                <div
                  className="customizeOpen"
                  onClick={() => {
                    setShowCustomiseDropDown(!showCustomiseDropdown);
                  }}
                >
                  <Edit />
                </div>
                {showCustomiseDropdown && (
                  <CustomiseDropDown
                    canvasID={props.roomID}
                    spaceID={props.spaceID}
                    toClose={() => {
                      setShowCustomiseDropDown(false);
                    }}
                  />
                )}
              </div>
            )}
            <div className="menuOpenContainer moreHorizontalContainer" style={hideMenuState ? { display: 'none' } : {}}>
              <div
                className="menuOpen has-v2-tooltip-long"
                data-tooltip-text="Room Settings"
                onClick={() => setDropDownState(!dropDownState)}
              >
                <MoreHorizontal />
              </div>
              {dropDownState && (
                <DropDownRoom
                  toClose={() => {
                    setDropDownState(false);
                  }}
                />
              )}
            </div>
            <div className="volumeControl" style={hideMenuState ? { display: 'none' } : {}}>
              <VolumeComponent />
            </div>

            {spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails.isOverflow && !hideMenuState && <RoomSwitcher />}
          </>
        </div>
      )}

      {isRoomAccessible && (
        <div className="top-right-menu">
          <div className="helpdesk-menu-container" id="helpdesk-menu-container">
            <div
              className="helpdesk-icon has-v2-tooltip-medium"
              data-tooltip-text={
                spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isGuest && spacesRooms.currentUserRole === 'a'
                  ? "Let's Reslash"
                  : 'Help'
              }
              onClick={() => {
                spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isGuest && spacesRooms.currentUserRole === 'a'
                  ? window.open(
                      `https://reslash.co?utm_source=referral&utm_medium=in-app&utm_campaign=audience-redirect&ref_space=${
                        spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID
                      }&ref_room=${spacesRooms.currentRoom}`,
                      '_blank'
                    )
                  : setToggleHelpdesk(!toggleHelpdesk);

                sendAmplitudeData('Reslash logo clicked', {
                  type:
                    spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isGuest &&
                    spacesRooms.currentUserRole === 'a'
                      ? 'Guest redirect'
                      : 'Helpdesk',
                  state: !toggleHelpdesk ? 'Enabled' : 'Disabled',
                });
              }}
            >
              <ReslashLogo />
            </div>
            {!(
              spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isGuest && spacesRooms.currentUserRole === 'a'
            ) && (
              <div className="helpdesk" style={{ display: toggleHelpdesk ? 'block' : 'none', zIndex: 10 }}>
                <Helpdesk
                  onClose={() => {
                    setToggleHelpdesk(false);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {isRoomAccessible && <Notifications />}
      {false ? (
        <LoginPage modal="room-closed" inApp={false} opensAt={isEnded ? undefined : fromTime} />
      ) : !locked ? (
        mediaPermissions?.permissionState === 2 ? (
          <CanvasView canvasID={props.roomID} spaceID={props.spaceID}>
            <>
              <Canvas id={props.roomID} spaceID={props.spaceID} />
              {/* <Ambisonic /> */}
              <Room id={props.roomID} spaceID={props.spaceID} />
            </>
          </CanvasView>
        ) : (
          <LoginPage modal={mediaPermissions?.permissionState === 1 ? 'voiceAndVideoSettings' : 'allowPermissions'} />
        )
      ) : (
        <LoginPage modal="room-password" opensAt={fromTime} />
      )}
      <DeleteConfirm />
      <InviteAdmin />
      {!locked && mediaPermissions?.permissionState === 2 && <ProductOnboarding />}
      <NoPermissionModal />
      <Broadcast />
      <InactivityModal />
      <KickedInactivityModal />
      <SpaceSwitcherModal />
      <ConfirmAction />
      <StartRecordingModal />
      <ErrorRecordingModal />
      <PreviewRecordingModal />
      <PasswordModal />
      <RoomClosedModal />
      <RoomClosedPermanentModal />
      <ProfileSetUpModal
        toClose={() => {
          closeProfileEditModal();
        }}
      />
    </div>
  );
};

export default RoomPage;
