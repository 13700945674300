import React, { useState } from 'react';
import { Lock } from 'react-feather';
import { useSelector } from 'react-redux';
import { getClaimToken } from '../../helper/firebase/auth';
import rooms from '../../helper/requests/rooms';
import { setCurrentRoomPassword } from '../../store';
import { getSpacesRooms } from '../../store/spacesRooms';
import FloatInput from '../floatInput/floatInput';
import LoaderRing from '../loader/loaderRing';
import './quickBio.scss';

const EnterPassword = () => {
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const spacesRooms = useSelector(getSpacesRooms);

  const checkPassword = async () => {
    if (loading) return;
    if (password.length < 1) {
      setMsg('Incorrect password, try again.');
      return;
    }
    try {
      setLoading(true);
      const token = await getClaimToken(false, false);
      if (token?.token) {
        await rooms.setRoomCustomClaim(spacesRooms.currentRoom, token.token, password);
        setCurrentRoomPassword(password);
        await getClaimToken(true, true);
      }
    } catch (e) {
      setMsg('Incorrect password, try again.');
    }
    setLoading(false);
  };

  return (
    <>
      <div className="quick-bio-container">
        <div className="quick-bio-form-section">
          <div className="quick-bio-form-items">
            <div className="quick-bio-form-header">
              <div
                className="quick-bio-form-header-title"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  fontSize: '24px',
                  fontWeight: 900,
                  lineHeight: '29px',
                  textAlign: 'center',
                }}
              >
                <Lock size={24} style={{ marginRight: '6px', transform: 'translateY(2px)' }} />
                {spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.roomName || 'Room'}
              </div>

              {/* <div className="quick-bio-form-user-avatar">
                <img src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png" />
              </div> */}
            </div>
            <div className="quick-bio-form-email">
              <FloatInput
                type={'password'}
                label={'Password'}
                showHide={true}
                onChangeInput={(inputText) => {
                  setPassword(inputText);
                  setMsg('');
                }}
              />
            </div>
            {/* <div className="quick-bio-form-email">
              <FloatInput
                type={'text'}
                label={'Email (optional)'}
                onChangeInput={(inputText) => {
                  setEmail(inputText);
                }}
              />
            </div> */}
          </div>

          {msg.length > 0 && (
            <div className="sign-up-form-error-state">
              <span>{msg}</span>
            </div>
          )}

          <div className="quick-bio-form-action">
            <div className="quick-bio-form-button" onClick={checkPassword}>
              {!loading && <span>Continue</span>}
              {loading && <LoaderRing size={'16px'} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterPassword;
