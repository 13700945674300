import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { guestSignIn } from '../../helper/firebase/auth';
import { validateSpecialChars } from '../../helper/signin';
import { getAuth } from '../../store/auth';
import FloatInput from '../floatInput/floatInput';
import './quickBio.scss';

const QuickBio = () => {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const auth = useSelector(getAuth);
  const history = useHistory();

  useEffect(() => {
    let redirection = localStorage.getItem('redirection');
    if (!redirection || redirection === '[object Object]') redirection = '/';
    if (auth.isLoggedIn) {
      history.replace(redirection);
    }
  }, [auth]);

  const guestSignin = async () => {
    const name = fname.trim() + ' ' + lname.trim();

    if (fname.trim().length !== fname.length && fname.trim().length === 0) {
      setMsg('First name should not contain only spaces');
      return;
    }

    if (fname.trim().length < 3) {
      setMsg('First name should be atleast 3 characters.');
      return;
    }

    if (fname.trim().length > 25) {
      setMsg('First name should be atmost 25 characters.');
      return;
    }

    if (lname.trim().length > 25) {
      setMsg('Last name should be atmost 25 characters.');
      return;
    }

    if (validateSpecialChars(fname) || validateSpecialChars(lname)) {
      setMsg('Name should not contain special characters.');
      return;
    }

    try {
      await guestSignIn(email, fname + ' ' + lname);
    } catch (e) {
      setMsg(`Error occured: ${e.message}`);
    }
  };

  return (
    <>
      <div className="quick-bio-container">
        <div className="quick-bio-form-section">
          <div className="quick-bio-form-items">
            <div className="quick-bio-form-header">
              <div className="quick-bio-form-header-title">
                <span>Enter your details</span>
              </div>

              {/* <div className="quick-bio-form-user-avatar">
                <img src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png" />
              </div> */}
            </div>
            <div className="quick-bio-form-name">
              <div className="quick-bio-form-fname">
                <FloatInput
                  type={'text'}
                  label={'First Name*'}
                  onChangeInput={(inputText) => {
                    setFname(inputText);
                    setMsg('');
                  }}
                />
              </div>
              <div className="quick-bio-form-lname">
                <FloatInput
                  type={'text'}
                  label={'Last Name'}
                  onChangeInput={(inputText) => {
                    setLname(inputText);
                    setMsg('');
                  }}
                />
              </div>
            </div>
            {/* <div className="quick-bio-form-email">
              <FloatInput
                type={'text'}
                label={'Email (optional)'}
                onChangeInput={(inputText) => {
                  setEmail(inputText);
                }}
              />
            </div> */}
          </div>

          {msg.length > 0 && (
            <div className="sign-up-form-error-state">
              <span>{msg}</span>
            </div>
          )}

          <div className="quick-bio-form-action">
            <div className="quick-bio-form-button" onClick={guestSignin}>
              <span>Continue</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickBio;
