import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getGetModalState } from '../../store/modal';
import { ModalStyle } from '../modal/modal';

export const noPermissionID = 'no-cam-permission';
export const openNoPermission = openModalThunk(noPermissionID);
export const closeNoPermission = closeModalThunk(noPermissionID);

const NoPermissionModal = () => {
  const state = useSelector(getGetModalState(noPermissionID));
  useEffect(() => {
    newModalThunk(noPermissionID, false)();
  }, []);
  return (
    <ReactModal isOpen={state} id={noPermissionID} contentLabel={`${noPermissionID} Modal`} style={ModalStyle}>
      <img src={'/images/permissionCam.gif'} alt="Grant Permission"></img>
      <div className="btn" onClick={closeNoPermission} style={{ marginTop: '3px' }}>
        close
      </div>
    </ReactModal>
  );
};

export default NoPermissionModal;
