import axios from 'axios';
import axiosRetry from 'axios-retry';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { config, fileUploadLimit } from '../../../config';
import { getClaimToken } from '../../../helper/firebase/auth';
import { addNewCanvasChild } from '../../../helper/interactive/firebaseRTD';
import { getFileName, getSpawnLocation } from '../../../helper/interactive/misc';
import { closeModalThunk, getAuthState, getListenerPosition, newModalThunk, openModalThunk } from '../../../store';
import { getGetModalState } from '../../../store/modal';
import { getSpacesRooms } from '../../../store/spacesRooms';
import { ModalStyle } from '../../modal/modal';
import '../../roomSettings/roomSettings.scss';
import FileIcon from './fileIcon.png';
import './fileIcons.css';
import './fileUploadModal.scss';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

export const modalID = 'file-upload-modal';
export const openFileUpload = openModalThunk(modalID);
export const closeFileUpload = closeModalThunk(modalID);

const addToCanvas = (
  spaceID: string,
  canvasID: string,
  icon: string,
  title: string,
  userName: string,
  userID: string,
  url: string
) => {
  const spawnLocation = getSpawnLocation(getListenerPosition(), 300);
  addNewCanvasChild(spaceID, canvasID, {
    w: 80,
    h: 80,
    x: spawnLocation.x,
    y: spawnLocation.y,
    t: 'file',
    d: { u: url, i: icon, title, uname: userName, uid: userID },
  });
};

const FileUpload = (props: { spaceID: string; roomID: string }) => {
  const state = useSelector(getGetModalState(modalID));
  const spacesRooms = useSelector(getSpacesRooms);
  const [title, setTitle] = useState('');
  const [file, setFile] = useState<{ fileName: string; icon: string; file: File | null }>({
    fileName: '',
    icon: '',
    file: null,
  });
  const [msg, setMsg] = useState<string>('');
  useEffect(() => {
    setMsg('');
  }, [state]);
  useEffect(() => {
    newModalThunk(modalID, false)();
  }, []);
  return (
    <ReactModal
      isOpen={state}
      id="roomSettings"
      contentLabel={`${modalID} Modal`}
      style={ModalStyle}
      shouldCloseOnOverlayClick={true}
    >
      <>
        <div className="body ">
          <div className="header fileUpload hr-container">
            Upload file to the room
            <div className="upload">
              {file.fileName.length < 1 ? (
                <>
                  <label htmlFor="inputId" className="button">
                    Choose File
                  </label>
                  <input
                    id="inputId"
                    name="file"
                    type="file"
                    style={{ position: 'fixed', top: '-1000px' }}
                    onChange={(e) => {
                      if (
                        e.target.files &&
                        e.target.files.length > 0 &&
                        e.target.files[0].size < fileUploadLimit * 1024 * 1024
                      ) {
                        const fileName = e.target.value;
                        setTitle(getFileName(fileName));
                        setFile({
                          fileName,
                          icon: window.FileIcons.getClassWithColor(fileName),
                          file: e.target.files[0],
                        });
                        setMsg('');
                      } else {
                        setMsg(`File should be less than ${fileUploadLimit}MB in size.`);
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  <div className="icon">
                    <div className="fileIcon">
                      {!file.icon || file.icon.length < 1 ? <img src={FileIcon} /> : <i className={file.icon}></i>}
                      <div
                        className="remove"
                        onClick={() => {
                          setFile({
                            fileName: '',
                            icon: '',
                            file: null,
                          });
                        }}
                      >
                        <i className="material-icons">cancel</i>
                      </div>
                    </div>
                  </div>
                  <input
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="bottom btn-container">
            <div className="left"></div>
            <div className="right">
              <div
                className="button"
                onClick={async (e) => {
                  if (title.length < 3) setMsg('File name should be atleast 3 characters');
                  if (file.file == null || title.length < 3) return;
                  const token = await getClaimToken();
                  if (token) {
                    setMsg('');
                    let formData = new FormData();
                    formData.append('file', file.file);
                    formData.append('token', token.token);
                    formData.append('spaceID', props.spaceID);
                    formData.append('roomID', props.roomID);
                    axios
                      .request({
                        method: 'POST',
                        url: config.firebaseCloudURL + '/uploadFile',
                        data: formData,
                        onUploadProgress: (p) => {
                          const progress = (100 * p.loaded) / p.total;
                          setMsg(`uploading: ` + progress.toFixed(1) + '%');
                        },
                      })
                      .then((response) => {
                        const path = response.data.path;
                        addToCanvas(
                          props.spaceID,
                          props.roomID,
                          file.icon,
                          title,
                          getAuthState().user.name,
                          getAuthState().user.id,
                          path
                        );
                        setFile({
                          fileName: '',
                          icon: '',
                          file: null,
                        });
                        closeFileUpload();
                      })
                      .catch((e) => {
                        console.error(e);
                        setMsg('Something went wrong.');
                      });
                  }
                }}
              >
                Upload
              </div>
              <div className="button red" onClick={() => closeFileUpload()}>
                Cancel
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="success-message">{msg}</div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default FileUpload;
