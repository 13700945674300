import { DailyParticipant } from '@daily-co/daily-js';
import { configureStore, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export type JoinNowFunc = (
  camDisabled: boolean,
  micDisabled: boolean,
  camOn: boolean,
  micOn: boolean,
  cameraID: string | undefined,
  micID: string | undefined
) => void;

/*
permissions.isVidDisabled,
        permissions.isMicDisabled,
        !permissions.isVidMute,
        !permissions.isMute,
        permissions.vidID,
        permissions.micID
*/

export type mediaPermissions = {
  isVidDisabled: boolean;
  isMicDisabled: boolean;
  isVidMute: boolean;
  isMute: boolean;
  vidID: string | undefined;
  micID: string | undefined;
  permissionState?: 1 | 2;
};

export type localParticipantDailyco = {
  participant: DailyParticipant | null;
  muteFunc: (flag: boolean) => void;
  muteVidFunc: (flag: boolean) => void;
  screenshare: (
    flag: boolean,
    screenShareDevice: string,
    screenShareAudioDevice?: string,
    isFullScreen?: boolean
  ) => void;
  isScreensharing: boolean;
  isMute: boolean;
  isVidMute: boolean;
  isMicDisabled: boolean;
  isVidDisabled: boolean;
  changeMicCamera: (camID: string | undefined, micID: string | undefined) => Promise<void>;
  joined: boolean;
  stream: {
    videoID: string | undefined;
    micID: string | undefined;
    video: MediaStreamTrack | null;
    audio: MediaStreamTrack | null;
  };
};

const initialState: {
  local: localParticipantDailyco;
  remote: { [id: string]: DailyParticipant };
  localVol: number;
  remoteVol: { [id: string]: number };
  join?: JoinNowFunc;
  mediaPermissions?: mediaPermissions;
} = {
  local: {
    participant: null,
    muteFunc: () => {},
    muteVidFunc: () => {},
    screenshare: () => {},
    isScreensharing: false,
    isMute: true,
    isVidMute: true,
    isMicDisabled: true,
    isVidDisabled: true,
    changeMicCamera: async (camID: string | undefined, micID: string | undefined) => {},
    joined: false,
    stream: {
      videoID: undefined,
      micID: undefined,
      audio: null,
      video: null,
    },
  },
  remote: {},
  localVol: 0,
  remoteVol: {},
  join: undefined,
  mediaPermissions: undefined,
};

export const dailyParticipantsSlice = createSlice({
  name: 'dailyParticipants',
  initialState,
  reducers: {
    addLocal(state, action) {
      state.local = action.payload;
    },

    addRemote(state, action) {
      state.remote[action.payload.id] = action.payload.participant;
    },

    updateRemote(state, action) {
      state.remote = action.payload;
    },

    removeRemote(state, action) {
      delete state.remote[action.payload.id];
    },

    updateJoined(state, action) {
      state.local.joined = action.payload;
    },

    removeAllParticipants(state, action) {
      state.remote = {};
    },

    updateLocalMute(state, action) {
      if (state.local) {
        if (action.payload.mic) state.local.isMute = action.payload.flag;
        else state.local.isVidMute = action.payload.flag;
      }
    },

    updateScreenshare(state, action) {
      if (state.local) {
        state.local.isScreensharing = action.payload.flag;
      }
    },

    setJoinFunc(state, action) {
      state.join = action.payload;
    },

    setMediaPermissions(state, action) {
      state.mediaPermissions = action.payload;
    },

    setLocalStream(state, action) {
      if (!state.local) return;
      state.local.stream = _.cloneDeep({
        ...state.local.stream,
        audio: action.payload.audio,
        video: action.payload.video,
      });
    },
    setLocalStreamID(state, action) {
      state.local.stream.micID = action.payload.micID;
      state.local.stream.videoID = action.payload.videoID;
    },
  },
});

export const dailyParticipantStore = configureStore({
  reducer: dailyParticipantsSlice.reducer,
});

export const getRemoteParticipants = () => (state: {
  dailyParticipants: ReturnType<typeof dailyParticipantStore.getState>;
}) => state.dailyParticipants.remote;

export const getRemoteState = (id: string) => (state: {
  dailyParticipants: ReturnType<typeof dailyParticipantStore.getState>;
}) => state.dailyParticipants.remote[id];

export const getLocalState = (state: { dailyParticipants: ReturnType<typeof dailyParticipantStore.getState> }) =>
  state.dailyParticipants.local;

export const getDailyJoinedState = (state: { dailyParticipants: ReturnType<typeof dailyParticipantStore.getState> }) =>
  state.dailyParticipants.local.joined;

export const getLocalParticipantState = (state: {
  dailyParticipants: ReturnType<typeof dailyParticipantStore.getState>;
}) => state.dailyParticipants.local?.participant;

export const getLocalStreamRedux = (state: { dailyParticipants: ReturnType<typeof dailyParticipantStore.getState> }) =>
  state.dailyParticipants.local.stream;

export const getJoinFuncRedux = (state: { dailyParticipants: ReturnType<typeof dailyParticipantStore.getState> }) =>
  state.dailyParticipants.join;

export const getMediaPermissionsRedux = (state: {
  dailyParticipants: ReturnType<typeof dailyParticipantStore.getState>;
}) => state.dailyParticipants.mediaPermissions;
