import { ImagesSearchResponse } from '@azure/cognitiveservices-imagesearch/esm/models';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'querystring';
import { config } from '../../config';
import logger from '../logger';
import { headers } from './spaces';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

class ImageSearch {
  async search(token: string, query: string, offset: number): Promise<ImagesSearchResponse> {
    logger.info('ImageSearch called:', { query, offset });
    try {
      const result = await axios.post(
        `${config.firebaseCloudURL}/imageSearch`,
        qs.stringify({ token, query, offset }),
        headers
      );
      logger.info('ImageSearch returned:', { result: result.data });
      return result.data.result;
    } catch (e) {
      logger.warn('ImageSearch error:', { error: e?.response?.data });
      throw e;
    }
  }
}

export default new ImageSearch();
