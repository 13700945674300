import _ from 'lodash';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { ChevronDown, ChevronUp, Circle } from 'react-feather';
import { convertCamelcaseToRegular } from '../../helper/interactive/misc';
import './dropdown.scss';

export function useOutsideClickDD(ref: React.MutableRefObject<HTMLDivElement | null>, doSomething: () => void) {
  function handleClickOutside(event: any) {
    if (ref.current && ref.current.contains(event.target)) {
      // inside click
      return;
    }
    // outside click
    doSomething();
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

const DropDown = (props: any) => {
  const [isListOpen, SetIsListOpen] = useState(false);

  const node = useRef<HTMLDivElement | null>(null);
  useOutsideClickDD(node, () => {
    SetIsListOpen(false);
  });

  const selectItem = (option: any, index: any) => {
    const optionText = {
      index: index,
      header: option.text ? option.text : option,
      close: true,
      color: option.color ? option.color : '',
    };
    if (props.isItemDict) {
      return props.onSelectItem(optionText, index);
    } else {
      return props.onSelectItem(optionText);
    }
  };

  const setStatusOptionStyle = (color: string) => {
    const statusOptionStyle: CSSProperties = {
      color: color,
      background: color,
      borderRadius: '50%',
      marginRight: '10px',
      border: '1px solid #000000',
      boxSizing: 'border-box',
      width: '18px',
      height: '18px',
      display: 'block',
    };

    return statusOptionStyle;
  };

  return (
    <div className="dd-wrapper" id="dd-wrapper" ref={node}>
      <div className="dd-header xyz" style={props.styles.dropDownHeader} onClick={() => SetIsListOpen(!isListOpen)}>
        <div className="optional">
          {props.showStatusColor ? (
            <div>
              <Circle style={setStatusOptionStyle(props.headerTitle.color)} />
            </div>
          ) : (
            <></>
          )}
          <div className="dd-header-title">
            {props.optionsListType && props.optionsListType === 'array' ? props.headerTitle : props.headerTitle.text}
          </div>
        </div>
        <div className="dd-header-button">
          <div style={{ display: isListOpen ? 'block' : 'none' }}>
            {props.arrowSize === undefined ? (
              <ChevronUp id="dd-icon" />
            ) : (
              <ChevronUp id="dd-icon" size={props.arrowSize} />
            )}
          </div>
          <div style={{ display: !isListOpen ? 'block' : 'none' }}>
            {props.arrowSize === undefined ? (
              <ChevronDown id="dd-icon" />
            ) : (
              <ChevronDown id="dd-icon" size={props.arrowSize} />
            )}
          </div>
        </div>
      </div>

      {isListOpen && (
        <div className="dd-list-container">
          <div className="dd-list" style={props.styles.listOuter}>
            {_.map(props.options, (option: any, index: number) => (
              <div
                className="dd-list-item hoverDark"
                key={index}
                style={{
                  ...props.styles.listItems,
                }}
                onClick={(e) => {
                  selectItem(option, index);
                  SetIsListOpen(!isListOpen);
                  e.stopPropagation();
                }}
              >
                <div className="optional">
                  {props.showStatusColor ? (
                    <div>
                      <Circle style={setStatusOptionStyle(option.color)} />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="dd-list-title">
                    {props.optionsListType && props.optionsListType === 'array'
                      ? props.isCamelcase
                        ? convertCamelcaseToRegular(option)
                        : option
                      : option.text}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
