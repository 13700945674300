import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import LoaderPage from '../../components/loader/loaderPage';
import { getClaimToken } from '../../helper/firebase/auth';
import { getMainDomain, isSubDomain } from '../../helper/interactive/misc';
import spaceObj from '../../helper/requests/spaces';
import { setCurrentRoom, setCurrentSpace, setOneSpace, setRooms, setSpaceClaimLoading } from '../../store';
import { getAuth } from '../../store/auth';
import { getSpacesRooms, roomType, spaceType } from '../../store/spacesRooms';
import LoginPage from '../login/login';
import RoomPage from '../roomPage/roomPage';

type jumpstartPayload = {
  spaceID: string;
  subdomain: string;
  roomID: string;
  userID: string;
  dailyToken: string;
  spaceDetails: spaceType;
  roomDetails: roomType;
};

const RoomPageContainer = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  return <RoomPage roomID={spacesRooms.currentRoom} spaceID={spacesRooms.currentSpace} />;
};

const EnterSpaceV2 = () => {
  let { spaceID, roomID } = useParams<{ spaceID: string; roomID: string | undefined }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const auth = useSelector(getAuth);
  const [allDataState, setAllData] = useState<null | jumpstartPayload>(null);
  let subdomainFlag = false;
  if (!spaceID && isSubDomain()) {
    spaceID = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : '';
    subdomainFlag = true;
  }

  useEffect(() => {
    const func = async () => {
      console.log(auth, auth.isLoggedIn);
      if (auth && auth.isLoggedIn) {
        let token = await getClaimToken();
        if (token) {
          setSpaceClaimLoading(true);
          try {
            const allData: jumpstartPayload = (
              await spaceObj.jumpstart(token.token, spaceID, roomID ? roomID : '', subdomainFlag)
            ).data.result;
            setAllData(allData);
            if (!subdomainFlag) {
              if (
                allData.subdomain &&
                allData.subdomain.length > 1 &&
                !window.location.href.includes('http://localhost') &&
                !window.location.href.includes('bojack')
              ) {
                window.location.href = 'https://' + allData.subdomain + '.' + getMainDomain();
              }
            }
            let t = await getClaimToken(true, true);
            if (t && t.claims.spacesV2 && t.claims.spacesV2[allData.spaceID]) {
              setCurrentSpace(allData.spaceID);
              setOneSpace(allData.spaceID, allData.spaceDetails);
              setRooms({ [allData.roomID]: allData.roomDetails });
              setCurrentRoom(allData.roomID);
              setSpaceClaimLoading(false);
              setLoading(false);
            } else {
              setLoading(false);
              setError('The user does not have access to this Space/Room.');
            }
          } catch (e) {
            console.error(e, e.response?.data);
            setError("The space or room doesn't exist, or you dont have permission to it.");
            setLoading(false);
          }
        }
      }
    };
    func();
  }, [auth.isLoggedIn]);

  const from = allDataState?.roomDetails.roomDetails.schedule?.from;
  const isClosed =
    allDataState?.roomDetails.roomDetails.schedule &&
    allDataState?.roomDetails.roomDetails.schedule.till !== -1 &&
    allDataState?.roomDetails.roomDetails.schedule?.till < new Date().getTime() / 1000;

  if (error.length < 1 && loading) {
    return <LoaderPage />;
  } else if (error.length > 0) {
    return (
      <div className="errorInviteContainer">
        <div className="errorMessageInvite">
          {error}
          <div
            className="button"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            Go Back to App
          </div>
        </div>
      </div>
    );
  } else if (
    allDataState?.roomDetails.roomDetails.schedule &&
    !allDataState?.roomDetails.permission.isAdmin &&
    (isClosed || (from && from > new Date().getTime() / 1000))
  ) {
    return <LoginPage modal="room-closed" opensAt={isClosed ? undefined : from} />;
  } else if (allDataState) {
    return <RoomPageContainer />;
  } else {
    return <LoaderPage />;
  }
};

export default EnterSpaceV2;
