import React, { useEffect, useRef, useState, VideoHTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import { canvasSize } from '../../../config';
import { getDist } from '../../../helper/interactive/spacialVolume';
import { getAddListener, getAddSource, getListenerPosition, getUpdateListener, getUpdateSource } from '../../../store';
import { getDailyJoinedState } from '../../../store/dailycoParticipants';
import { getScreenVolRedux } from '../../../store/wallpaper';
import { dimension, firebaseCallback, xy } from '../../../types/canvasFB';
import './video.scss';

type PropsType = VideoHTMLAttributes<HTMLVideoElement> & {
  srcObject: MediaStream;
  id: string;
  isAudio?: boolean;
  isMuted?: boolean;
};

export function ScreenShareNew(props: {
  id: string;
  videoStream: MediaStreamTrack | undefined;
  trackID: string;
  position: xy;
  setContent: (attach: boolean, ele: HTMLVideoElement) => boolean;
  isMuted: boolean;
  isLocal: boolean;
  isScreen: boolean;
  streamLoading: boolean;
}) {
  const hasJoined = useSelector(getDailyJoinedState);
  const [isLoading, setLoading] = useState(true);
  const isLoaded = useRef(false);
  const vidEle = useRef<HTMLVideoElement>(null);
  const currentlyPlaying = useRef('');

  useEffect(() => {
    if (props.isLocal) getAddListener()({ el: null });
    else getAddSource(props.id)({ position: { x: props.position.x, y: props.position.y }, el: null });
  }, [props.id, props.isLocal]);

  useEffect(() => {
    if (vidEle.current && !isLoaded.current) {
      vidEle.current.volume = 0;
      setLoading(true);
      if (props.videoStream && !props.streamLoading) {
        vidEle.current.srcObject = null;
        vidEle.current.srcObject = new MediaStream([props.videoStream]);
        currentlyPlaying.current = props.videoStream.id;
        vidEle.current.onloadedmetadata = (e) => {
          vidEle.current?.play();
        };
      }
      vidEle.current.onloadeddata = () => {
        setLoading(false);
        isLoaded.current = true;
      };
    }
  }, [vidEle, props.videoStream, hasJoined, props.streamLoading]);
  const video = (
    <video
      id={props.id}
      ref={vidEle}
      key={props.trackID}
      autoPlay
      playsInline
      style={{
        position: 'relative',
        objectFit: 'fill',
        objectPosition: '50% 50%',
        width: '100%',
        height: 'auto',
      }}
      data-isloading={isLoading}
      muted
    />
  );
  return video;
}

export function ScreenShareFullScreen(props: {
  id: string;
  videoStream: MediaStreamTrack | undefined;
  trackID: string;
  position: xy;
  setContent: (attach: boolean, ele: HTMLVideoElement) => boolean;
  isMuted: boolean;
  isLocal: boolean;
  isScreen: boolean;
  streamLoading: boolean;
}) {
  const hasJoined = useSelector(getDailyJoinedState);
  const [isLoading, setLoading] = useState(true);
  const isLoaded = useRef(false);
  const vidEle = useRef<HTMLVideoElement>(null);
  const currentlyPlaying = useRef('');

  useEffect(() => {
    if (props.isLocal) getAddListener()({ el: null });
    else getAddSource(props.id)({ position: { x: props.position.x, y: props.position.y }, el: null });
  }, [props.id, props.isLocal]);

  useEffect(() => {
    if (vidEle.current && !isLoaded.current) {
      vidEle.current.volume = 0;
      setLoading(true);
      if (props.videoStream && !props.streamLoading) {
        vidEle.current.srcObject = null;
        vidEle.current.srcObject = new MediaStream([props.videoStream]);
        currentlyPlaying.current = props.videoStream.id;
        vidEle.current.onloadedmetadata = (e) => {
          vidEle.current?.play();
        };
      }
      vidEle.current.onloadeddata = () => {
        setLoading(false);
        isLoaded.current = true;
      };
    }
  }, [vidEle, props.videoStream, hasJoined, props.streamLoading]);
  const video = (
    <video
      id={props.id}
      ref={vidEle}
      key={props.trackID}
      autoPlay
      playsInline
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        objectFit: 'cover',
        objectPosition: '50% 50%',
        width: canvasSize.w + 'px',
        height: canvasSize.h + 'px',
        userSelect: 'none',
        pointerEvents: 'none',
      }}
      data-isloading={isLoading}
      muted
    />
  );
  return video;
}

export function ScreenShareAudioFullScreen(props: {
  id: string;
  videoStream: MediaStreamTrack | undefined;
  trackID: string;
  position: xy;
  setContent: (attach: boolean, ele: HTMLVideoElement) => boolean;
  isMuted: boolean;
  isLocal: boolean;
  isScreen: boolean;
  streamLoading: boolean;
}) {
  const hasJoined = useSelector(getDailyJoinedState);
  const [isLoading, setLoading] = useState(true);
  const isLoaded = useRef(false);
  const vidEle = useRef<HTMLVideoElement>(null);
  const currentlyPlaying = useRef('');
  const volume = useSelector(getScreenVolRedux);

  useEffect(() => {
    if (vidEle.current) vidEle.current.volume = volume / 100;
  }, [volume, vidEle.current]);

  useEffect(() => {
    if (vidEle.current && !isLoaded.current) {
      setLoading(true);
      if (props.videoStream && !props.streamLoading) {
        vidEle.current.srcObject = null;
        vidEle.current.srcObject = new MediaStream([props.videoStream]);
        currentlyPlaying.current = props.videoStream.id;
        vidEle.current.onloadedmetadata = (e) => {
          vidEle.current?.play();
        };
      }
      vidEle.current.onloadeddata = () => {
        setLoading(false);
        isLoaded.current = true;
      };
    }
  }, [vidEle, props.videoStream, hasJoined, props.streamLoading]);
  const video = (
    <audio
      id={props.id}
      ref={vidEle}
      key={props.trackID}
      autoPlay
      playsInline
      data-isloading={isLoading}
      muted={props.isLocal || volume === 0}
      controls
    />
  );
  return video;
}

export function MediaJitsiNew(props: {
  id: string;
  videoStream: MediaStreamTrack | undefined;
  trackID: string;
  position: xy;
  setContent: (attach: boolean, ele: HTMLVideoElement) => boolean;
  isAudio: boolean;
  isMuted: boolean;
  isLocal: boolean;
  isScreen: boolean;
  streamLoading: boolean;
}) {
  const hasJoined = useSelector(getDailyJoinedState);
  const [isLoading, setLoading] = useState(true);
  const isLoaded = useRef(false);
  const vidEle = useRef<HTMLVideoElement>(null);
  const currentlyPlaying = useRef('');

  useEffect(() => {
    if (!props.isAudio) return;
    if (props.isLocal) getAddListener()({ el: null });
    else getAddSource(props.id)({ position: { x: props.position.x, y: props.position.y }, el: null });
  }, [props.id, props.isLocal]);

  useEffect(() => {
    if (props.isAudio) {
      if (props.isLocal) getUpdateListener()({ el: document.getElementById(props.id) });
      else getUpdateSource(props.id)({ el: document.getElementById(props.id) });
    }
  }, [props.trackID, props.isMuted]);

  useEffect(() => {
    if (vidEle.current && !isLoaded.current) {
      vidEle.current.volume = 0;
      setLoading(true);
      if (props.videoStream && !props.streamLoading) {
        vidEle.current.srcObject = null;
        vidEle.current.srcObject = new MediaStream([props.videoStream]);
        currentlyPlaying.current = props.videoStream.id;
        vidEle.current.onloadedmetadata = (e) => {
          vidEle.current?.play();
        };
      }
      vidEle.current.onloadeddata = () => {
        setLoading(false);
        isLoaded.current = true;
      };
    }
  }, [vidEle, props.videoStream, hasJoined, props.streamLoading]);
  const video = (
    <video
      id={props.id}
      ref={vidEle}
      key={props.trackID}
      autoPlay
      playsInline
      className={props.isScreen ? '' : 'circle-vid'}
      style={{
        position: 'relative',
        objectFit: props.isScreen ? 'fill' : 'cover',
        objectPosition: props.isScreen ? '50% 50%' : 'center',
        width: '100%',
        height: props.isScreen ? 'auto' : '100%',
        transform: props.isScreen || !props.isLocal ? '' : 'rotateY(180deg)',
      }}
      data-isloading={isLoading}
      muted
    />
  );
  const audio = (
    <audio
      ref={vidEle}
      id={props.id}
      key={props.trackID}
      muted={props.isLocal}
      autoPlay
      playsInline
      style={{
        display: 'none',
      }}
    />
  );
  if (!props.isAudio) return video;
  else return audio;
}

const getDistance = (dims: dimension, screenPos: xy, listenerPos: xy, padding = 0) => {
  const radius = Math.max(dims.w, dims.h) / 2;
  return Math.sqrt(getDist(screenPos, listenerPos)) - radius - padding;
  //return calcNewVolume(plyrPos, listenerPos, radius + 200, radius + 800, radius)
};

export function ScreenAudioEle(props: {
  id: string;
  videoStream: MediaStreamTrack | undefined;
  trackID: string;
  dimension: dimension;
  setContent: (attach: boolean, ele: HTMLVideoElement) => boolean;
  isMuted: boolean;
  isLocal: boolean;
  streamLoading: boolean;
  isSpatial: boolean;
  videoEleID: string;
  watcher: (callback: (c: dimension) => void) => firebaseCallback;
}) {
  const hasJoined = useSelector(getDailyJoinedState);
  const [isLoading, setLoading] = useState(true);
  const isLoaded = useRef(false);
  const vidEle = useRef<HTMLVideoElement>(null);
  const currentlyPlaying = useRef('');
  const listener = useSelector(getListenerPosition);
  const [dimension, setDim] = useState({
    w: props.dimension.w,
    h: props.dimension.h,
    x: props.dimension.x,
    y: props.dimension.y,
  });

  useEffect(() => {
    if (vidEle.current) {
      if (props.isSpatial) {
        const xy = {
          x: dimension.x + dimension.w / 2,
          y: dimension.y + dimension.h / 2 - 150,
        };
        const newXY = xy;
        const listenerPos = listener;
        const newDist = getDistance(
          { x: dimension.x, y: dimension.y, w: dimension.w, h: dimension.w }, //INTENSIONAL SINCE HEIGHT IS WRONG.
          newXY,
          { x: listenerPos.x + 50, y: listenerPos.y + 50 },
          50
        );
        const max = dimension.w / 4 + 50;
        const min = dimension.w / 4;
        const convertToVol = (d: number) => (max - d) / (max - min);
        let vol = convertToVol(newDist);
        vol = vol > 1 ? 1 : vol;
        vol = vol < 0 ? 0 : vol;
        vidEle.current.volume = vol;
      } else vidEle.current.volume = 1;
    }
  }, [listener, dimension]);

  useEffect(() => {
    const watcher = props.watcher((c) => {
      setDim({
        w: c.w,
        h: c.h,
        x: c.x,
        y: c.y,
      });
    });
    return () => watcher();
  }, [props.watcher]);

  useEffect(() => {
    if (vidEle.current && !isLoaded.current) {
      vidEle.current.volume = 0;
      setLoading(true);
      if (props.videoStream && !props.streamLoading) {
        vidEle.current.srcObject = null;
        vidEle.current.srcObject = new MediaStream([props.videoStream]);
        currentlyPlaying.current = props.videoStream.id;
        vidEle.current.onloadedmetadata = (e) => {
          vidEle.current?.play();
        };
      }
      vidEle.current.onloadeddata = () => {
        setLoading(false);
        isLoaded.current = true;
      };
    }
  }, [vidEle, props.videoStream, hasJoined, props.streamLoading]);
  const audio = (
    <>
      <audio
        ref={vidEle}
        id={props.id}
        key={props.trackID}
        muted={props.isLocal}
        autoPlay
        playsInline
        style={{
          display: 'none',
        }}
      />
    </>
  );
  return audio;
}

export default function Video({ srcObject, ...props }: PropsType) {
  const refVideo = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!refVideo.current) return;
    refVideo.current.srcObject = srcObject;
    refVideo.current.autoplay = true;
  }, [srcObject]);
  if (!props.isAudio)
    return (
      <video
        ref={refVideo}
        playsInline={true}
        autoPlay
        muted={true}
        className="circle-vid"
        style={{
          position: 'relative',
          objectFit: 'cover',
          objectPosition: 'center',
          width: '100%',
          height: '100%',
        }}
        {...props}
      />
    );
  else
    return (
      <audio
        ref={refVideo}
        playsInline={true}
        autoPlay
        muted={props.isMuted}
        style={{
          display: 'none',
        }}
        {...props}
      />
    );
}
