//createTokenDailyco

import axios from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'querystring';
import { config } from '../../config';
import logger from '../logger';
import { headers } from './spaces';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

export async function getDailyToken(room: string, token: string) {
  logger.info('Called getDailyToken:', { room });
  try {
    const response = await axios.post(
      `${config.firebaseCloudURL}/createTokenDailyco`,
      qs.stringify({ room, token }),
      headers
    );
    logger.info('GetDailyToken response:', { result: response.data });
    return response.data.token;
  } catch (e) {
    logger.warn('GetDailyToken error:', { error: e?.response?.data });
    throw e;
  }
}
