import React, { useEffect, useRef, useState } from 'react';
import { Pause, Play, RecordFill, StopFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { sendAmplitudeData } from '../../helper/amplitude';
import dailyCoObj from '../../helper/interactive/dailyco';
import { getSupportedMimeTypes, playSound } from '../../helper/interactive/misc';
import logger from '../../helper/logger';
import { setIsRecordingStore } from '../../store';
import { getAuth } from '../../store/auth';
import { getSpacesRooms } from '../../store/spacesRooms';
import { md5 } from '../interactive/games/codename/codename';
import { openErrorRecordingModal } from './errorRecordingModal';
import { openPreviewRecordingModal } from './previewRecordingModal';
import './recorder.scss';
import { openRecordingModal } from './startRecordingModal';

export const RecordingAnimation = () => {
  return (
    <>
      <div className="recorder-animation">
        <div className="blink">
          <RecordFill color="red" size={32} />
        </div>
        <span>REC</span>
      </div>
    </>
  );
};

const getDuration = (timer: { startTime: Date; endTime: Date; paused: { startTime: Date; endTime: Date }[] }) => {
  const totalDuration = timer.endTime.getTime() - timer.startTime.getTime();
  let pauseDuration = 0;
  for (let i in timer.paused) {
    const pause = timer.paused[i];
    pauseDuration += pause.endTime.getTime() - pause.startTime.getTime();
  }
  return totalDuration - pauseDuration;
};

const Recorder = () => {
  const [recording, setRecordingState] = useState(false);
  const [pause, setPauseState] = useState(false);
  const spacesRooms = useSelector(getSpacesRooms);
  const auth = useSelector(getAuth);

  //@ts-ignore
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const streams = useRef<MediaStream[]>([]);
  const mediaChunks = useRef<Blob[]>([]);
  const mediaStream = useRef<MediaStream | null>(null);
  const [status, setStatus] = useState<string>('idle');
  const [recordingURL, setRecordingURL] = useState<string>('');
  const [timer, setTimer] = useState<{
    startTime: Date;
    endTime: Date;
    paused: { startTime: Date; endTime: Date }[];
  }>({
    startTime: new Date(),
    endTime: new Date(),
    paused: [],
  });
  const timerRef = useRef<typeof timer>({
    startTime: new Date(),
    endTime: new Date(),
    paused: [],
  });

  const bestMimeType =
    getSupportedMimeTypes() && getSupportedMimeTypes().length > 0
      ? getSupportedMimeTypes()[0]
      : 'video/webm;codecs:vp9';
  const blobOptions = bestMimeType.split(';')[0];
  const recorderStreamFormat = blobOptions.split('/')[1];

  useEffect(() => {
    timerRef.current = timer;
  }, [timer]);

  useEffect(() => {
    if (status === 'recording stopped' && recordingURL !== '') {
      const filename = generateFileName(recorderStreamFormat);

      openPreviewRecordingModal(
        () => {
          downloadBlob(recordingURL, filename);
        },
        () => {},
        recordingURL
      )();
      setRecordingURL('');
    }
  }, [status]);

  useEffect(() => {
    setRecordingState(false);
    stopRecording();
    setIsRecordingStore(false);
  }, [spacesRooms.currentRoom]);

  const mergeAudioStreams = (desktopStream: MediaStream, voiceStream: MediaStream) => {
    let context;
    if (window.AudioContext) context = new AudioContext();
    else if (window.webkitAudioContext) {
      // @ts-ignore
      context = new window.webkitAudioContext();
    } else {
      return [];
    }
    const destination = context.createMediaStreamDestination();
    let hasDesktop = false;
    let hasVoice = false;
    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      // If you don't want to share Audio from the desktop it should still work with just the voice.
      const source1 = context.createMediaStreamSource(desktopStream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
      hasDesktop = true;
    }

    if (voiceStream && voiceStream.getAudioTracks().length > 0) {
      const source2 = context.createMediaStreamSource(voiceStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source2.connect(voiceGain).connect(destination);
      hasVoice = true;
    }

    return hasDesktop || hasVoice ? destination.stream.getAudioTracks() : [];
  };

  const getStream = async () => {
    setStatus('initiate media stream');
    logger.info('Recording - initiate media stream', {});
    sendAmplitudeData('Recording initiated', {});
    //@ts-ignore
    const desktopStream: MediaStream = await navigator.mediaDevices.getDisplayMedia({
      video: {
        width: { ideal: 1280, max: 1920 },
        height: { ideal: 720, max: 1080 },
        frameRate: { ideal: 60, max: 60 },
      },
      audio: { echoCancellation: false, noiseSuppression: false },
    });
    streams.current.push(desktopStream);
    desktopStream.getTracks().forEach((track) => {
      track.onended = () => {
        if (mediaRecorder.current.state === 'recording') {
          setRecordingState(false);
          stopRecording();
          setIsRecordingStore(false);
        }
        cleanUpStreams();
      };
    });
    // const micStream: MediaStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });

    const mictrack = dailyCoObj.callObj?.participants().local.tracks.audio.track;
    const micStream: MediaStream = mictrack ? new MediaStream([mictrack]) : new MediaStream();

    const tracks = [...desktopStream.getVideoTracks(), ...mergeAudioStreams(desktopStream, micStream)];

    mediaStream.current = new MediaStream(tracks);

    //@ts-ignore
    mediaRecorder.current = new MediaRecorder(mediaStream.current, {
      audioBitsPerSecond: 320000, // 320kbps
      videoBitsPerSecond: 3000000, // 3Mbps deal for 1920*1080
      mimeType: `${bestMimeType},opus`,
    });
    mediaRecorder.current.ondataavailable = (e: any) => {
      onRecordingActive(e.data);
    };
    mediaRecorder.current.onstop = onRecordingStop;
    mediaRecorder.current.onerror = () => {
      setStatus('idle');
    };
  };

  const onRecordingActive = (data: any) => {
    mediaChunks.current.push(data);
  };

  const onRecordingStop = () => {
    const blob = new Blob(mediaChunks.current, { type: blobOptions });
    const duration = getDuration(timerRef.current);
    window.ysFixWebmDuration(blob, duration, (fixedBlob) => {
      let url = URL.createObjectURL(fixedBlob);
      setRecordingURL(url);
      setStatus('recording stopped');
      logger.info('Recording - stopped', {
        recordingDurationInSeconds: (duration ? duration / 1000 : 0.0).toPrecision(2),
        recordingSizeInMB: (blob ? blob.size / 1000000 : 0.0).toPrecision(2),
      });
      sendAmplitudeData('Recording stopped', {
        recordingDurationInSeconds: (duration ? duration / 1000 : 0.0).toPrecision(2),
        recordingSizeInMB: (blob ? blob.size / 1000000 : 0.0).toPrecision(2),
      });
    });
  };

  const startRecording = () => {
    mediaRecorder.current.start();
    setStatus('recording started');
    logger.info('Recording - started', {});
    sendAmplitudeData('Recording started', {});
    setTimer({
      startTime: new Date(),
      endTime: new Date(),
      paused: [],
    });
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      if (mediaRecorder.current.state !== 'inactive') {
        setStatus('recording stopping');
        logger.info('Recording - stopping', {});
        sendAmplitudeData('Recording stopping', {});
        mediaRecorder.current.stop();
        cleanUpStreams();
        mediaChunks.current = [];
        setTimer((timer) => {
          const newTimer = {
            ...timer,
          };
          newTimer.endTime = new Date();
          return newTimer;
        });
      }
    }
  };

  const cleanUpStreams = () => {
    mediaStream.current?.getTracks().forEach((track) => {
      track.enabled = false;
      track.stop();
    });
    streams.current?.forEach((stream) => {
      stream.getTracks().forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    });
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.pause();
      setStatus('recording paused');
      logger.info('Recording - paused', {});
      sendAmplitudeData('Recording paused', {});
      setTimer((timer) => {
        return {
          ...timer,
          paused: [...timer.paused, { startTime: new Date(), endTime: new Date() }],
        };
      });
    }
  };
  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'paused') {
      mediaRecorder.current.resume();
      setStatus('recording resumed');
      logger.info('Recording - resumed', {});
      sendAmplitudeData('Recording resumed', {});
      setTimer((timer) => {
        timer.paused[timer.paused.length - 1].endTime = new Date();
        return timer;
      });
    }
  };

  function generateFileName(extension: string) {
    const currentTimestamp = new Date().valueOf();
    const spaceId = spacesRooms.currentSpace;
    const roomId = spacesRooms.currentRoom;
    const userId = auth.user.id;

    const fileid = spaceId.concat(roomId).concat(userId).concat(currentTimestamp.toString());

    let base64data = md5(fileid);
    return base64data.concat('.' + extension);
  }

  async function downloadBlob(blobUrl: string, fileName: string) {
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
    if (blobUrl) URL.revokeObjectURL(blobUrl); // Clear the blob from Browser

    logger.info('Recording - downloaded', { filename: fileName, blobURL: blobUrl });
    sendAmplitudeData('Recording downloaded', { filename: fileName, blobURL: blobUrl });
  }

  return (
    <>
      <div className="record_btn ">
        {!recording && (
          <div
            className="tool has-v2-tooltip-top-vlong"
            data-tooltip-text="Start Recording"
            id="rec_btn"
            onClick={async () => {
              sendAmplitudeData('Recording button clicked', { state: 'Start recording' });
              try {
                await getStream();
                openRecordingModal(
                  () => {
                    playSound('/sound/recordingSound.mp3');
                    setIsRecordingStore(true);
                    startRecording();
                    setRecordingState(!recording);
                  },
                  () => {
                    cleanUpStreams();
                    setRecordingState(false);
                  }
                )();
              } catch (error) {
                setStatus('Initiation failed');

                if (!error.message.includes('Permission denied')) {
                  openErrorRecordingModal(
                    () => {},
                    () => {}
                  )();

                  logger.error('Recording - Initiation failed', { error });
                } else {
                  logger.info('Recording - Initiation cancelled', { error });
                }
              }
            }}
          >
            <RecordFill color="red" />
          </div>
        )}

        {recording && (
          <div
            className="tool has-v2-tooltip-top-vlong"
            data-tooltip-text="Stop Recording"
            id="rec_btn"
            onClick={() => {
              setRecordingState(!recording);
              stopRecording();
              setIsRecordingStore(false);
              sendAmplitudeData('Recording button clicked', { state: 'Stop recording' });
            }}
          >
            <StopFill />
          </div>
        )}
      </div>

      {recording && !pause && (
        <div
          className="tool has-v2-tooltip-top-vlong"
          data-tooltip-text="Pause Recording"
          id="rec_btn"
          onClick={() => {
            setPauseState(!pause);
            pauseRecording();
            sendAmplitudeData('Recording button clicked', { state: 'Pause recording' });
          }}
        >
          <Pause />
        </div>
      )}

      {recording && pause && (
        <div
          className="tool has-v2-tooltip-top-vlong"
          data-tooltip-text="Resume Recording"
          id="rec_btn"
          onClick={() => {
            setPauseState(!pause);
            resumeRecording();
            sendAmplitudeData('Recording button clicked', { state: 'Resume recording' });
          }}
        >
          <Play />
        </div>
      )}
    </>
  );
};

export default Recorder;
