import React, { useEffect, useRef, useState } from 'react';
import Moveable, { OnResize, OnResizeEnd, OnResizeStart } from 'react-moveable';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { canvasSize } from '../../../../config';
import { getAuthState } from '../../../../store';
import { getSpacesRooms } from '../../../../store/spacesRooms';
import { dimension, firebaseCallback } from '../../../../types/canvasFB';
import { useOutsideEvent } from '../../giphy/Giphy';
import { screenshareControllerObj } from '../../room/screenshareController';
import './basicDiv.scss';

const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0;
function BasicDiv(props: {
  hideFloatingCrossBtn?: boolean;
  hideFloatingDragBtn?: boolean;
  canResize?: boolean;
  showScreenshareHeader?: boolean;
  isLocalUserScreenshare?: boolean;
  screenshareUsername?: string;
  id: string;
  w: number;
  dragHandle: boolean;
  h: number;
  x: number;
  y: number;
  d?: any;
  onDeleteTrigger: undefined | (() => void);
  onDimChange: (d: dimension) => void;
  watcher: (callback: (c: dimension) => void) => firebaseCallback;
  children: JSX.Element;
  type: string;
  uid?: string;
  onDataChange?: (d: any, path: string) => void;
  turnSpatialOff?: (flag: boolean) => void;
}) {
  const dispatch = useDispatch();
  const spacesRooms = useSelector(getSpacesRooms);
  const [canDrag, setCanDrag] = useState(false);
  const ref = useRef<Rnd>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const shape = useRef({ w: props.w || 0, h: props.h || 0 });
  const [isSpatialOn, setSpatialOn] = useState(true);
  // const [lastResize, setLastResize] = useState(0);

  const frame = useRef({
    translate: [props.x, props.y],
  });

  useEffect(() => {
    const watcher = props.watcher((c) => {
      if (c) {
        // setPosition({ x: c.x, y: c.y });
        // setShape({ w: c.w, h: c.h });
        frame.current.translate = [c.x, c.y];
        shape.current = { w: c.w, h: c.h };
        const target = document.getElementById(`basicDiv_${props.id}`)!;
        if (target) {
          target.style.transform = `translate(${c.x}px, ${c.y}px)`;
          target.style.width = `${c.w}px`;
          target.style.height = `${c.h}px`;
        }
        if (c.d?.sm !== undefined) {
          setShowMenuBar(c.d.sm);
        }
      }
    });
    return () => {
      watcher();
    };
  }, [props.watcher]);

  useEffect(() => {
    if (!props.id.includes('_screen')) {
      // setCanDrag(
      //   (props.type === 'gif' && spacesRooms.permissionV2[spacesRooms.currentUserRole]?.gifs ? true : false) ||
      //     ((props.type === 'yt' || props.type === 'gc' || props.type === 'iframe' || props.type === 'whiteboard') &&
      //     spacesRooms.permissionV2[spacesRooms.currentUserRole]?.apps
      //       ? true
      //       : false) ||
      //     ((props.type === 'file' || props.type === 'image' || props.type === 'linkbox') &&
      //     spacesRooms.permissionV2[spacesRooms.currentUserRole]?.objects
      //       ? true
      //       : false) ||
      //     (props.type === 'screen' &&
      //       (spacesRooms.permissionV2[spacesRooms.currentUserRole]?.moveself
      //         ? true
      //         : (false && props.id.includes(getAuthState().user.id)) ||
      //           spacesRooms.permissionV2[spacesRooms.currentUserRole]?.moveothers
      //         ? true
      //         : false))
      // );
      if (getAuthState().user.id === props.uid || spacesRooms.currentUserRole === 'h') {
        setCanDrag(true);
      } else {
        setCanDrag(false);
      }
    } else {
      if (props.id.includes(getAuthState().user.id) || spacesRooms.currentUserRole === 'h') setCanDrag(true);
      else if (spacesRooms.permissionV2[spacesRooms.currentUserRole]?.moveothers) setCanDrag(true);
      else setCanDrag(false);
    }
  }, [spacesRooms]);

  const dragHandleClass = props.dragHandle ? 'handle' + props.id : undefined;

  const requestFullScreen = () => {
    /**
     * Find the vid element in children and request fullscreen
     */
    const outerEl = document.getElementById(`basicDiv_${props.id}`);
    const video = outerEl?.querySelector('video');
    const requestFullScreenWrapper: () => Promise<void> =
      video?.requestFullscreen ||
      // @ts-ignore
      video?.mozRequestFullScreen ||
      // @ts-ignore
      video?.webkitRequestFullScreen ||
      // @ts-ignore
      video?.msRequestFullscreen;
    if (requestFullScreenWrapper) {
      if (video) video.style.objectFit = 'contain';
      requestFullScreenWrapper.call(video).catch((e) => {});
    }
  };

  const onResizeStart = (e: OnResizeStart) => {
    e.setOrigin(['%', '%']);
    e.dragStart && e.dragStart.set(frame.current.translate);
    e.target.style.height = `${shape.current.h}px`;
    if (props.type === 'yt' && e.target.children.length > 3 && e.target.children[4]?.classList?.contains('vjs-paused'))
      return;
    showOverlay();
  };

  const onResize = (e: OnResize) => {
    const beforeTranslate = e.drag.beforeTranslate;
    frame.current.translate = beforeTranslate;
    if (e.height < 150 && props.showScreenshareHeader) {
      e.width = (150 * 16) / 9;
      e.height = 150;
    }
    if (e.width < 266.66 && props.showScreenshareHeader) {
      e.width = (150 * 16) / 9;
      e.height = 150;
    }
    if (props.showScreenshareHeader) {
      e.height = (e.width * 9) / 16;
    }
    e.target.style.width = `${e.width}px`;
    e.target.style.height = `${e.height}px`;
    e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`;
    const newShape = {
      w: e.width,
      h: e.height,
    };
    shape.current = newShape;
    props.onDimChange({
      x: beforeTranslate[0],
      y: beforeTranslate[1],
      d: { sm: showMenuBar },
      ...newShape,
    });
  };

  const onResizeEnd = (e: OnResizeEnd) => {
    hideOverlay();
    e.target.style.height = `${shape.current.h}px`;
  };

  const hideOverlay = () => {
    if (overlayRef && overlayRef.current) {
      overlayRef.current.style.display = 'none';
    }
  };

  const showOverlay = () => {
    if (overlayRef && overlayRef.current) {
      overlayRef.current.style.display = 'flex';
    }
  };

  const shouldShowScreenshareCloseBtn = () => {
    if (props.isLocalUserScreenshare) {
      return true;
    }
    return false;
  };

  const [showSettings, setShowSettings] = useState(false);
  const [showMenuBar, setShowMenuBar] = useState(props.d?.sm !== undefined ? props.d.sm : true);

  const frameSettingsRef = useRef<HTMLDivElement | null>(null);

  useOutsideEvent(
    frameSettingsRef,
    () => {
      setShowSettings(false);
    },
    'frame-settings',
    '#frame-settings'
  );

  return (
    <>
      <div
        id={`basicDiv_${props.id}`}
        className={`content ${canDrag ? 'interactive' : ''} ${props.showScreenshareHeader ? 'bottom-radius' : ''} ${
          props.isLocalUserScreenshare ? 'local-user-screenshare' : ''
        }`}
        style={{
          width: props.w || 0,
          height: props.h || 0,
          minHeight: props.showScreenshareHeader ? '150px' : '',
          transform: `translate(${props.x}px, ${props.y}px)`,
          position: 'absolute',
          zIndex: props.canResize ? 4 : 5,
        }}
      >
        <div className="content-frame-type" style={{ height: 'inherit', width: 'inherit' }}>
          {props.children}
        </div>
        {props.showScreenshareHeader && (
          <div
            className="screenshare-header"
            style={{
              opacity: showMenuBar ? '1' : '0',
              visibility:
                showMenuBar || props.uid === getAuthState().user.id || spacesRooms.currentUserRole === 'h'
                  ? 'visible'
                  : 'hidden',
            }}
            id={`${props.id}_header`}
          >
            <p className="screen-name">{props.screenshareUsername?.split(' ')[0]}'s Screen</p>
            <div className="screenshare-right-icons">
              {(props.uid === getAuthState().user.id || spacesRooms.currentUserRole === 'h') && (
                <div
                  className="icon-btn frame-settings"
                  style={{ padding: '1px 6px' }}
                  onClick={() => {
                    setShowSettings(!showSettings);
                  }}
                  id="frame-settings"
                  ref={frameSettingsRef}
                >
                  <span className="material-icons">more_horiz</span>
                  {showSettings && (
                    <div className="frame-settings-dropdown">
                      <div
                        className="option"
                        onClick={() => {
                          props.onDimChange({
                            x: frame.current.translate[0],
                            y: frame.current.translate[1],
                            d: { sm: !showMenuBar },
                            ...shape.current,
                          });
                          setShowMenuBar(!showMenuBar);
                        }}
                      >
                        {showMenuBar ? 'Hide menu bar' : 'Show menu bar'}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {!props.isLocalUserScreenshare && (
                <>
                  <button className={`icon-btn`} onClick={requestFullScreen} id={`fullscreen_${props.id}`}>
                    <span className="material-icons">fullscreen</span>
                  </button>
                  <div className="visiblity_btn" style={{ display: 'none' }}>
                    <button
                      className="icon-btn"
                      id={`visible_${props.id}`}
                      onClick={() => screenshareControllerObj.toggle(props.id, false, true)}
                      style={{ display: 'none' }}
                    >
                      <span className="material-icons">visibility_off</span>
                    </button>
                    <button
                      className="icon-btn"
                      id={`hidden_${props.id}`}
                      onClick={() => screenshareControllerObj.toggle(props.id, true, true)}
                      style={{ display: 'none' }}
                    >
                      <span className="material-icons">visibility</span>
                    </button>
                  </div>
                </>
              )}
              {shouldShowScreenshareCloseBtn() && (
                <button className="icon-btn" onClick={props.onDeleteTrigger} id={`close_${props.id}`}>
                  <span className="material-icons">close</span>
                </button>
              )}
            </div>
          </div>
        )}
        {/* {props.type === 'yt' && (
          <div
            className="screenshare-header"
            style={{
              opacity: showMenuBar ? '1' : '0',
              visibility:
                showMenuBar || props.uid === getAuthState().user.id || spacesRooms.currentUserRole === 'h'
                  ? 'visible'
                  : 'hidden',
            }}
            id={`yt_${props.id}_header`}
          >
            <p className="screen-name">
              <img src={YouTubeImg} style={{ width: '26px', marginRight: '4px' }} /> YouTube Player
            </p>
            <div className="screenshare-right-icons">
              {(props.uid === getAuthState().user.id || spacesRooms.currentUserRole === 'h') && (
                <div
                  className="icon-btn frame-settings"
                  style={{ padding: '1px 6px' }}
                  onClick={() => {
                    setShowSettings(!showSettings);
                  }}
                  id="frame-settings"
                  ref={frameSettingsRef}
                >
                  <span className="material-icons">more_horiz</span>
                  {showSettings && (
                    <div className="frame-settings-dropdown">
                      <div
                        className="option"
                        onClick={() => {
                          if (props.onDataChange) props.onDataChange(!showMenuBar, '/sm');
                          setShowMenuBar(!showMenuBar);
                        }}
                      >
                        {showMenuBar ? 'Hide menu bar' : 'Show menu bar'}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {canDrag && (
                <>
                  <button
                    className={'icon-btn spatial-off ' + (!isSpatialOn ? 'off' : '')}
                    onClick={() => {
                      if (props.turnSpatialOff) props.turnSpatialOff(!isSpatialOn);
                      setSpatialOn(!isSpatialOn);
                    }}
                    id={`turnoffspatial_${props.id}`}
                  >
                    <Soundwave />
                  </button>
                  <button className="icon-btn" onClick={props.onDeleteTrigger} id={`close_${props.id}`}>
                    <span className="material-icons">close</span>
                  </button>
                </>
              )}
            </div>
          </div>
        )} */}
        <div className="cross">
          {!props.hideFloatingCrossBtn && canDrag && props.onDeleteTrigger && (
            <div className="close" onClick={props.onDeleteTrigger}>
              <span className="material-icons">close</span>
            </div>
          )}
          {!props.hideFloatingDragBtn && canDrag && props.dragHandle && (
            <div className={dragHandleClass}>
              <span className="material-icons">drag_handle</span>
            </div>
          )}
        </div>
        {props.type === 'gc' && (
          <div
            className="screenshare-header chess"
            style={{
              opacity: showMenuBar ? '1' : '0',
              visibility:
                showMenuBar || props.uid === getAuthState().user.id || spacesRooms.currentUserRole === 'h'
                  ? 'visible'
                  : 'hidden',
            }}
            id={`gc_${props.id}_header`}
          >
            <p className="screen-name">
              <svg
                enableBackground="new 0 0 512 512"
                id="chess_btn_svg"
                version="1.1"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <path d="M140.791,392.415l0.004,27.411l-13.053,0.003l0.006,44.381l185.473-0.027h36.433v-44.458h-11.755l-0.004-27.339h-11.33  c0.852-6.296,1.553-12.532,1.672-18.769c0.108-6.688-0.582-13.157-1.894-19.447c-3.351-16.072-10.759-30.969-19.264-45.34  c-14.976-25.185-34.646-46.464-55.019-67.22c-2.184-2.124-4.248-4.382-7.778-8.091c17.858,4.17,33.098,0.369,48.068-4.838  c10.798-3.726,19.219-0.646,26.922,7.056c16.275,16.524,32.474,19.213,52.978,9.325c8.688-4.249,16.777-20.433,14.966-29.84  c-0.583-3.006-1.855-6.535-0.884-9.167c3.14-9.139-1.692-13.834-8.049-18.979c-14.778-12.008-29.378-24.344-43.319-37.264  c-7.015-6.49-12.296-14.79-18.578-22.043c-2.708-3.096-5.804-6.176-9.334-8.225c-4.367-2.572-9.378-3.992-14.135-5.906  c-10.261-4.171-13.207-5.382-14.061-11.484l0.133-1.571c0.455-5.407-3.133-10.328-8.42-11.548l-7.239-1.67l-0.006-39.574h-0.449  c-22.269,0.004-40.573,16.967-42.708,38.684c-47.26,14.305-75.133,47.138-89.155,93.25c-12.647,41.476-5.014,81.378,7.735,121.294  c7.721,24.229,14.53,48.92,20.068,73.791c1.212,5.578,0.958,11.562,0.376,17.604L140.791,392.415z M287.989,160.093  c-5.181,0-9.38-4.2-9.38-9.38s4.199-9.38,9.38-9.38s9.38,4.199,9.38,9.38S293.17,160.093,287.989,160.093z" />
              </svg>{' '}
              Chess
            </p>
            <div className="screenshare-right-icons">
              {canDrag && (
                <>
                  <button className="icon-btn" onClick={props.onDeleteTrigger} id={`close_${props.id}`}>
                    <span className="material-icons">close</span>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
        {props.type !== 'gc' && props.type !== 'portal' ? (
          <div className="iframe-overlay" id="iframeOverlay" ref={overlayRef}></div>
        ) : (
          <></>
        )}
        {/* <div>x: {position.x.toFixed(0)}, y: {position.y.toFixed(0)} w: {shape.w.toFixed(0)}, h: {shape.h.toFixed(0)}</div> */}
      </div>
      <Moveable
        draggable={canDrag}
        resizable={canDrag && props.canResize}
        throttleResize={0}
        className={`interactive ${canDrag && props.canResize ? '' : 'moveable-hide-moveguides'}`}
        keepRatio={props.type !== 'whiteboard' && props.type !== 'iframe' && props.type !== 'nogo'}
        target={document.getElementById(`basicDiv_${props.id}`)}
        edgeDraggable={false}
        onDragStart={(e) => {
          e.set(frame.current.translate);
          if (
            props.type === 'yt' &&
            e.target.children.length > 3 &&
            e.target.children[4]?.classList?.contains('vjs-paused')
          )
            return;
          showOverlay();
        }}
        onDragEnd={(e) => {
          hideOverlay();
          if (!e.isDrag) {
            var isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0;
            if (
              e.inputEvent.srcElement.className == 'close' ||
              e.inputEvent.srcElement.parentElement.className == 'close'
            ) {
              if (props.onDeleteTrigger) props.onDeleteTrigger();
            }
            const srcElement = e.inputEvent.srcElement;
            if (srcElement.tagName === 'SPAN' && srcElement.parentElement?.id === `fullscreen_${props.id}`) {
              srcElement.parentElement.click();
            }
            if (srcElement.tagName === 'BUTTON' && srcElement.id === `fullscreen_${props.id}`) {
              srcElement.click();
            }
            if (srcElement.id === `visible_${props.id}`) {
              srcElement.click();
            }
            if (srcElement.parentElement.id === `visible_${props.id}`) {
              srcElement.parentElement.click();
            }
            if (srcElement.id === `hidden_${props.id}`) {
              srcElement.click();
            }
            if (srcElement.parentElement.id === `hidden_${props.id}`) {
              srcElement.parentElement.click();
            }
            if (srcElement.tagName === 'SPAN' && srcElement.parentElement?.id === `close_${props.id}`) {
              srcElement.parentElement.click();
            }
            if (srcElement.tagName === 'BUTTON' && srcElement.id === `close_${props.id}`) {
              srcElement.click();
            }
          }
        }}
        renderDirections={[]}
        edge={true}
        origin={false}
        snappable={true}
        bounds={{ left: 0, right: canvasSize.w, top: 0, bottom: canvasSize.h }}
        dragTarget={
          props.dragHandle
            ? (document.querySelector(`.${dragHandleClass}`) as HTMLElement)
            : props.type === 'yt'
            ? (document.querySelector(`#yt_${props.id}_header`) as HTMLElement)
            : props.type === 'gc'
            ? (document.querySelector(`#gc_${props.id}_header`) as HTMLElement)
            : undefined
        }
        onDrag={(e) => {
          frame.current.translate = e.beforeTranslate;
          e.target.style.transform = `translate(${e.beforeTranslate[0]}px, ${e.beforeTranslate[1]}px)`;
          props.onDimChange({
            x: e.beforeTranslate[0],
            y: e.beforeTranslate[1],
            d: { sm: showMenuBar },
            ...shape.current,
          });
        }}
        onResizeStart={onResizeStart}
        onResizeEnd={onResizeEnd}
        onResize={onResize}
      />
    </>
  );
}

BasicDiv.defaultProps = {
  canResize: true,
};

export default React.memo(BasicDiv);
