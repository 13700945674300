import React, { useEffect, useState } from 'react';
import { reslashAppsFirebase } from '../firebase';

export interface ReslashAppsContextType {
  firebase: any;
}

export const ReslashAppsContext = React.createContext<ReslashAppsContextType | null>(null);

interface ReslashAppsProviderType {
  children: React.ReactNode;
  firebase: any;
}

export const ReslashAppsProvider = (props: ReslashAppsProviderType) => {
  const [isFirebaseSetup, setIsFirebaseSetup] = useState(false);
  useEffect(() => {
    /**
     * Setup firebase
     */
    reslashAppsFirebase.setUpFirebase(props.firebase);
    setIsFirebaseSetup(true);
  }, []);

  return <React.Fragment>{isFirebaseSetup && props.children}</React.Fragment>;
};
