import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import './App.scss';
import FirebaseWatcher from './components/firebaseWatcher/firebaseWatcher';
import SpaceSwitcherModal from './components/spaceSwitcher/spaceSwitcher';
import { mainSubDomain } from './config';
import { initAmplitude } from './helper/amplitude';
import { findGetParameter } from './helper/interactive/misc';
import AddToSpace from './pages/addToSpace/addToSpace';
import AppsumoSignup from './pages/appsumo/signup';
import { BroadcastPage } from './pages/broadcastPage/broadcastPage';
import EnterSpaceV2 from './pages/enterSpace/enterSpaceV2';
import KickedPage from './pages/kicked/kicked';
import LoginPage from './pages/login/login';
import Settings from './pages/settingsV2/settings';
import { StreamToOverflow } from './pages/streamOverflow/streamToOverflow';
import WhiteBoardPage from './pages/whiteboard/whiteboard';
import { setRedirectToStore } from './store';
import { getAuth } from './store/auth';

const ProtectedRoute = (props: {
  children: JSX.Element;
  path: string;
  exact: boolean;
  isNoGuest?: boolean;
  isGuestAllowed?: boolean;
}) => {
  const auth = useSelector(getAuth);
  const history = useHistory();
  const setLastPath = (state: any) => {
    if (state?.location) {
      setRedirectToStore(state.location);
      localStorage.setItem('redirection', state.location.pathname);
    }
    const loginToken = findGetParameter('loginToken');
    if (loginToken) {
      history.push(`/login?loginToken=${loginToken}`);
      return false;
    }
    if (state?.location && (state.location.search?.includes('?redirect-guest') || props.isGuestAllowed)) {
      // window.location.href = `${window.location.origin}/login?guest`;
      history.push('/guestLogin');
      return false;
    }
    return true;
  };
  initAmplitude();
  return (
    <Route
      exact={props.exact}
      path={props.path}
      render={(state) => {
        return auth.isLoggedIn
          ? props.children
          : setLastPath(state) && (
              <Redirect
                to={{
                  pathname: '/login',
                  search: props.isNoGuest ? '?ng' : '',
                  state: { from: state.location },
                }}
              />
            );
      }}
    />
  );
};
function App() {
  if (!window.location.href.includes(mainSubDomain) && !window.location.href.includes('localhost')) {
    return (
      <>
        <Router>
          <FirebaseWatcher />
          <div className="App">
            <Switch>
              <ProtectedRoute path="/onboard" exact={false} isNoGuest={true}>
                <LoginPage modal={'createSpace'} />
              </ProtectedRoute>
              <ProtectedRoute path="/createSpace" exact={false} isNoGuest={true}>
                <LoginPage modal={'spaceInputs'} />
              </ProtectedRoute>
              <ProtectedRoute path="/allowPermissions" exact={false} isGuestAllowed={true}>
                <LoginPage modal={'allowPermissions'} />
              </ProtectedRoute>
              <ProtectedRoute path="/voiceSettings" exact={false} isGuestAllowed={true}>
                <LoginPage modal={'voiceAndVideoSettings'} />
              </ProtectedRoute>
              <ProtectedRoute path="/invite/:link" exact={false} isNoGuest={true}>
                <AddToSpace />
              </ProtectedRoute>
              <ProtectedRoute path="/s/:spaceID/r/:roomID/c/:whiteboardID" exact={false}>
                <WhiteBoardPage />
              </ProtectedRoute>
              <ProtectedRoute path="/settings" exact={false}>
                <Settings />
              </ProtectedRoute>
              <ProtectedRoute path="/r/:roomID/kicked" exact={false}>
                <KickedPage />
              </ProtectedRoute>
              <ProtectedRoute path="/r/:roomID" exact={false} isGuestAllowed={true}>
                <EnterSpaceV2 />
              </ProtectedRoute>
              <ProtectedRoute path="/" exact={true} isGuestAllowed={true}>
                <EnterSpaceV2 />
              </ProtectedRoute>
              <ProtectedRoute path="/broadcastPage" exact={false}>
                <BroadcastPage />
              </ProtectedRoute>
              <ProtectedRoute path="/overflowStream" exact={false}>
                <StreamToOverflow />
              </ProtectedRoute>
              <ProtectedRoute path="/roomClosed" exact={false}>
                <LoginPage modal={'room-closed'} />
              </ProtectedRoute>
              <Route path="/login">
                <LoginPage modal={'signIn'} />
              </Route>
              <Route path="/signup">
                <LoginPage modal={'signUp'} />
              </Route>
              <Route path="/guestLogin">
                <LoginPage modal={'guestLogIn'} />
              </Route>
              <Route path="/confirmEmail">
                <LoginPage modal={'confirmEmail'} />
              </Route>
              <Route path="/loginAsGuest">
                <LoginPage modal={'loginAsGuest'} />
              </Route>
            </Switch>
          </div>
        </Router>
      </>
    );
  } else {
    return (
      <>
        <Router>
          <div className="App">
            <FirebaseWatcher />
            <SpaceSwitcherModal />
            <Switch>
              <ProtectedRoute path="/" exact>
                <LoginPage modal={'spaceSwitcher'} />
              </ProtectedRoute>
              <ProtectedRoute path="/createSpace" exact={false} isNoGuest={true}>
                <LoginPage modal={'spaceInputs'} />
              </ProtectedRoute>
              <ProtectedRoute path="/allowPermissions" exact={false} isGuestAllowed={true}>
                <LoginPage modal={'allowPermissions'} />
              </ProtectedRoute>
              <ProtectedRoute path="/voiceSettings" exact={false} isGuestAllowed={true}>
                <LoginPage modal={'voiceAndVideoSettings'} />
              </ProtectedRoute>
              <ProtectedRoute path="/onboard" exact={false} isNoGuest={true}>
                <LoginPage modal={'createSpace'} />
              </ProtectedRoute>
              <ProtectedRoute path="/invite/:link" exact={false} isNoGuest={true}>
                <AddToSpace />
              </ProtectedRoute>
              <ProtectedRoute path="/s/:spaceID/r/:roomID/c/:whiteboardID" exact={false}>
                <WhiteBoardPage />
              </ProtectedRoute>
              <ProtectedRoute path="/s/:spaceID/settings" exact={false}>
                <Settings />
              </ProtectedRoute>
              {/* <ProtectedRoute path="/s/:spaceID/customise" exact={false}>
                <LoginPage />
              </ProtectedRoute> */}
              <ProtectedRoute path="/s/:spaceID/r/:roomID/kicked" exact={false}>
                <KickedPage />
              </ProtectedRoute>
              <ProtectedRoute path="/s/:spaceID/r/:roomID" exact={false} isGuestAllowed={true}>
                <EnterSpaceV2 />
              </ProtectedRoute>
              <ProtectedRoute path="/s/:spaceID" exact={false} isGuestAllowed={true}>
                <EnterSpaceV2 />
              </ProtectedRoute>
              <ProtectedRoute path="/broadcastPage" exact={false}>
                <BroadcastPage />
              </ProtectedRoute>
              <ProtectedRoute path="/overflowStream" exact={false}>
                <StreamToOverflow />
              </ProtectedRoute>
              <ProtectedRoute path="/roomClosed" exact={false}>
                <LoginPage modal={'room-closed'} />
              </ProtectedRoute>
              {/* <Route path="/login">
                <Signin />
              </Route> */}
              <Route path="/login">
                <LoginPage modal={'signIn'} />
              </Route>
              <Route path="/signup">
                <LoginPage modal={'signUp'} />
              </Route>
              <Route path="/guestLogin">
                <LoginPage modal={'guestLogIn'} />
              </Route>
              <Route path="/confirmEmail">
                <LoginPage modal={'confirmEmail'} />
              </Route>
              <Route path="/loginAsGuest">
                <LoginPage modal={'loginAsGuest'} />
              </Route>
              <Route path="/appsumoSignup">
                <AppsumoSignup />
              </Route>
            </Switch>
          </div>
        </Router>
      </>
    );
  }
}

export default App;
