import firebase from 'firebase';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { sendAmplitudeData } from '../../helper/amplitude';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getAuth } from '../../store/auth';
import { getGetModalState } from '../../store/modal';
import { getSpacesRooms } from '../../store/spacesRooms';
import { ModalStyle } from '../modal/modal';
import './exitFeedback.scss';

const Star = (props: { selected: boolean; key: string }) => {
  const activeColor = '#FFED76';
  const inactiveColor = '#121621';
  const size = 42;

  return (
    <svg
      style={{
        color: props.selected ? activeColor : inactiveColor,
        fill: props.selected ? 'rgba(0, 0, 0, 0.02)' : 'rgba(0, 0, 0, 0.04)',
        paddingLeft: '4px',
        paddingRight: '4px',
        cursor: 'pointer',
        display: 'block',
        height: size,
        width: size,
        transition: 'color 0.5s ease-in-out, fill 0.5s ease-in-out',
      }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 34"
    >
      <path
        fill="currentColor"
        d="M19.6859343,0.861782958 L24.8136328,8.05088572 C25.0669318,8.40601432 25.4299179,8.6717536 25.8489524,8.80883508 L34.592052,11.6690221 C35.6704701,12.021812 36.2532905,13.1657829 35.8938178,14.2241526 C35.8056709,14.4836775 35.6647294,14.7229267 35.4795411,14.9273903 L29.901129,21.0864353 C29.5299163,21.4962859 29.3444371,22.0366367 29.3872912,22.5833831 L30.1116131,31.8245163 C30.1987981,32.9368499 29.3506698,33.9079379 28.2172657,33.993502 C27.9437428,34.0141511 27.6687736,33.9809301 27.4085205,33.8957918 L18.6506147,31.0307612 C18.2281197,30.8925477 17.7713439,30.8925477 17.3488489,31.0307612 L8.59094317,33.8957918 C7.51252508,34.2485817 6.34688429,33.6765963 5.98741159,32.6182265 C5.90066055,32.3628116 5.86681029,32.0929542 5.88785051,31.8245163 L6.61217242,22.5833831 C6.65502653,22.0366367 6.46954737,21.4962859 6.09833466,21.0864353 L0.519922484,14.9273903 C-0.235294755,14.0935658 -0.158766688,12.8167745 0.690852706,12.0755971 C0.899189467,11.8938516 1.14297067,11.7555303 1.40741159,11.6690221 L10.1505113,8.80883508 C10.5695458,8.6717536 10.9325319,8.40601432 11.1858308,8.05088572 L16.3135293,0.861782958 C16.9654141,-0.0521682813 18.2488096,-0.274439442 19.1800736,0.365326425 C19.3769294,0.500563797 19.5481352,0.668586713 19.6859343,0.861782958 Z"
      />
    </svg>
  );
};

export const exitFeedbackID = 'exit-feedback-id';
let type: 'exit' | 'feedback' = 'exit';
export const openExitFeedbackModal = (t: 'exit' | 'feedback') => {
  type = t;
  return openModalThunk(exitFeedbackID)();
};
export const closeExitFeedbackModal = closeModalThunk(exitFeedbackID);

const ExitFeedbackModal = () => {
  const exitFeedbackState = useSelector(getGetModalState(exitFeedbackID));
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    newModalThunk(exitFeedbackID, false)();
  }, []);

  useEffect(() => {
    setRating(0);
    setFeedback('');
  }, [exitFeedbackState]);

  const auth = useSelector(getAuth);
  const user = auth.user;
  const spacesRooms = useSelector(getSpacesRooms);
  const feedbackRef = firebase.firestore().collection('feedback');

  const getUserType = () => {
    const isAdmin = spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isAdmin;
    const isTeamMember = spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isTeamMember;

    if (isAdmin) return 'Admin';
    else if (isTeamMember) return 'Team member';
    else return 'Guest';
  };

  const sendFeedback = async (e: any) => {
    e.preventDefault();

    if (rating > 0) {
      await feedbackRef.add({
        type: type,
        rating: rating,
        feedback: feedback,
        createdOn: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: user.id,
        email: user.email,
        username: user.name,
        spaceID: spacesRooms.currentSpace,
        space: spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID,
        roomID: spacesRooms.currentRoom,
        userRole: spacesRooms.currentUserRole,
        userType: getUserType(),
      });
    }

    if (type === 'exit') window.location.reload();
  };

  return (
    <ReactModal id={exitFeedbackID} className={exitFeedbackID} isOpen={exitFeedbackState} style={ModalStyle}>
      <>
        <div className="body">
          <div className="title">Tell us about your reslash experience</div>
          <div className="rating-section">
            <div className="star-line">
              {_.map([1, 2, 3, 4, 5], function (value) {
                return (
                  <div onClick={() => setRating(value)}>
                    <Star key={value + '-star'} selected={rating >= value ? true : false} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="leave-feedback-section" style={{ display: rating > 0 || type !== 'exit' ? 'block' : 'none' }}>
            <textarea
              className="input-textarea"
              readOnly={rating > 0 ? false : true}
              maxLength={2048}
              style={{ resize: 'none', height: '150px' }}
              value={feedback}
              placeholder="Leave feedback (optional) "
              onChange={(event) => {
                setFeedback(event.target.value);
              }}
            ></textarea>
          </div>
        </div>
        <div className="footer">
          <div className="buttons">
            <div
              className="skip-feedback"
              onClick={(e) => {
                closeExitFeedbackModal();
                sendFeedback(e);
                sendAmplitudeData('Exit feedback skipped', {});
              }}
            >
              Skip
            </div>
            <div
              className="submit-feedback"
              onClick={(e) => {
                closeExitFeedbackModal();
                sendFeedback(e);
                sendAmplitudeData('Exit feedback submitted', { rating, feedback });
              }}
            >
              Submit
            </div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default ExitFeedbackModal;
