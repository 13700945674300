import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendEmailVerification } from '../../helper/firebase/auth';
import { getAuth } from '../../store/auth';
import './confirmEmail.scss';

const ConfirmEmail = () => {
  const history = useHistory();
  const [isVerified, setIsVerified] = useState(false);
  const auth = useSelector(getAuth);

  useEffect(() => {
    const interval = setInterval(() => {
      firebase
        .auth()
        .currentUser?.reload()
        .then(() => {
          const emailVerified = firebase.auth().currentUser?.emailVerified;
          setIsVerified(emailVerified !== undefined ? emailVerified : false);
          if (emailVerified) {
            if (auth.redirectTo) history.push(auth.redirectTo);
          }
        });
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
      <div className="confirm-email-container">
        <div className="confirm-email-form-section">
          <div className="confirm-email-form-items">
            <div className="confirm-email-form-header">
              <div className="confirm-email-form-header-title">
                <span>Confirm your email address</span>
              </div>
              <div className="confirm-email-form-header-subtitle">
                {/* @ts-ignore */}
                <span>A confirmation email has been sent to {history.location?.state?.email} </span>
              </div>
            </div>
          </div>

          <div className="confirm-email-form-action">
            {isVerified && (
              <div className="confirm-email-form-button">
                <span
                  onClick={() => {
                    history.push('/login');
                  }}
                >
                  Log in
                </span>
              </div>
            )}
            {!isVerified && (
              <div className="confirm-email-msg-section">
                <span>
                  Didn't receive it? Check your spam folder. Still not there?{' '}
                  <a
                    href="#"
                    onClick={() => {
                      sendEmailVerification();
                    }}
                  >
                    Click here
                  </a>{' '}
                  to get it again.
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmEmail;
