import Bowser from 'bowser';
import firebase from 'firebase';
import { v4 } from 'uuid';
import { createUsageRoom, getUsageRef, setUsageOffline, updateUsageRoom } from '../interactive/firebaseRTD';
import { getIPAddress } from '../requests/getIP';
import { updatePresenceV2 } from '../requests/updatePresence';

type onTerminationCallback = (sessionID: string, session: Session) => void;

export default class Session {
  private readonly sessionID: string;
  readonly roomID: string;
  readonly spaceID: string;
  readonly userID: string;
  private isSessionActive: boolean;
  private isTerminating: boolean;
  private pingTimer?: NodeJS.Timeout;
  dailyInfo?: {
    sessionID: string;
    participantID: string;
  };
  private readonly onTerminationCallbacks: onTerminationCallback[];

  constructor(roomID: string, spaceID: string, userID: string) {
    this.sessionID = v4();
    this.roomID = roomID;
    this.spaceID = spaceID;
    this.userID = userID;
    this.isSessionActive = false;
    this.isTerminating = false;
    this.onTerminationCallbacks = [];
  }

  getSessionID() {
    return this.sessionID;
  }

  getIsSessionActive() {
    return this.isSessionActive;
  }

  async initiateSession() {
    const ipAddress = await getIPAddress();
    const browserInfo = Bowser.parse(window.navigator.userAgent);

    await createUsageRoom(this.sessionID, {
      roomID: this.roomID,
      spaceID: this.spaceID,
      userID: this.userID,
      ipAddress,
      userAgent: window.navigator.userAgent,
      parsedUA: browserInfo,
    });

    const usageRef = getUsageRef(this.sessionID);
    await usageRef.onDisconnect().update({ online: false, endTime: firebase.database.ServerValue.TIMESTAMP });

    this.isSessionActive = true;
    this.startPing();
  }

  async setDailyInfo(sessionID: string, participantID: string) {
    this.dailyInfo = { sessionID, participantID };
    await updateUsageRoom(this.sessionID, { sessionID, participantID });
  }

  private startPing() {
    this.pingTimer = setInterval(() => {
      updatePresenceV2(this.sessionID).catch(() => {});
    }, 60000);
  }

  private terminatePing() {
    if (this.pingTimer) clearInterval(this.pingTimer);
  }

  onTermination(callback: onTerminationCallback) {
    this.onTerminationCallbacks.push(callback);
  }

  async endSession() {
    if (this.isTerminating || !this.isSessionActive) return;
    this.isTerminating = true;
    await setUsageOffline(this.sessionID);
    this.terminatePing();
    const usageRef = getUsageRef(this.sessionID);
    await usageRef.onDisconnect().cancel();
    this.isSessionActive = false;
    for (const callback of this.onTerminationCallbacks) {
      callback(this.sessionID, this);
    }
  }
}
