import React, { useEffect, useState } from 'react';
import { ThreeDots } from 'react-bootstrap-icons';
import { Check, X } from 'react-feather';
import { useSelector } from 'react-redux';
import {
  addNewCanvasChild,
  deleteCanvasChild,
  deleteRestrictedZone,
  setRestrictedZone,
} from '../../../helper/interactive/firebaseRTD';
import { getSpawnLocation } from '../../../helper/interactive/misc';
import { getListenerPosition } from '../../../store';
import { getAuth } from '../../../store/auth';
import { canvasChild, firebaseCallback, restrictedZoneType } from '../../../types/canvasFB';
import './noGoZones.scss';

const NoGoZone = (props: {
  spaceID?: string;
  roomID?: string;
  watcher?: (callback: (c: canvasChild) => void) => firebaseCallback;
  onDataChange?: (d: any, path: string) => void;
  showSettings: boolean;
  showFinaliseOptions: boolean;
  highlight: boolean;
  tempID?: string;
  mainID?: string;
  dimensions?: restrictedZoneType;
}) => {
  const [dims, setDims] = useState<restrictedZoneType>();
  const [dropDown, setDropDown] = useState(false);
  const auth = useSelector(getAuth);

  const addNoGoZone = (spaceID: string, canvasID: string, mainID: string, dims: restrictedZoneType) => {
    const spawnLocation = getSpawnLocation(getListenerPosition(), 500);
    addNewCanvasChild(spaceID, canvasID, {
      w: dims.width,
      h: dims.height,
      x: dims.x,
      y: dims.y,
      t: 'nogo',
      d: { d: 1, uid: auth.user.id, mainID },
    });
  };

  useEffect(() => {
    let unsub = () => {};
    if (props.tempID && props.watcher) {
      unsub = props.watcher((c) => {
        if (c)
          setDims({
            x: c.x,
            y: c.y,
            width: c.w,
            height: c.h,
          });
      });
    }
    return unsub;
  }, [props.tempID, props.watcher]);
  return (
    <>
      <div
        className="nogo-zone-container"
        style={props.highlight ? { border: 'border: 2px solid #ff0033' } : { display: 'none' }}
      >
        {props.showFinaliseOptions && props.tempID && (
          <div className="top-right-options">
            <div
              className="option green"
              onClick={() => {
                props.spaceID &&
                  props.roomID &&
                  dims &&
                  setRestrictedZone(props.spaceID, props.roomID, dims, props.mainID);
                props.spaceID &&
                  props.roomID &&
                  props.tempID &&
                  deleteCanvasChild(props.spaceID, props.roomID, props.tempID.split('_')[1]);
              }}
            >
              <Check />
            </div>
            <div
              className="option red"
              onClick={() => {
                props.spaceID &&
                  props.roomID &&
                  props.tempID &&
                  deleteCanvasChild(props.spaceID, props.roomID, props.tempID.split('_')[1]);
              }}
            >
              <X />
            </div>
          </div>
        )}
        {props.showSettings && (
          <div className="top-right-options">
            <div
              className="option"
              onClick={() => {
                setDropDown(!dropDown);
              }}
            >
              <ThreeDots />
            </div>
            {dropDown && (
              <div className="dropDown-settings">
                <div
                  className="setting"
                  onClick={() => {
                    if (props.spaceID && props.roomID && props.mainID && props.dimensions) {
                      addNoGoZone(props.spaceID, props.roomID, props.mainID, props.dimensions);
                    }
                  }}
                >
                  Edit
                </div>
                <div
                  className="setting"
                  onClick={() => {
                    props.spaceID &&
                      props.roomID &&
                      props.mainID &&
                      deleteRestrictedZone(props.spaceID, props.roomID, props.mainID);
                  }}
                >
                  Delete
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default NoGoZone;
