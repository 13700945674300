//createTokenDailyco

import axios from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'querystring';
import { config } from '../../config';
import logger from '../logger';
import { headers } from './spaces';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

export function clearRoomChat(roomID: string, token: string) {
  logger.info('Called ClearRoomChat', { roomID });
  const result = axios.post(`${config.firebaseCloudURL}/clearRoomChat_v2`, qs.stringify({ roomID, token }), headers);
  result
    .then((result) => {
      logger.info('ClearRoomChat Result:', { result: result.data });
    })
    .catch((error) => {
      logger.warn('ClearRoomChat Error:', { error: error?.response?.data });
      throw error;
    });
  return result;
}
