import { configureStore, createSlice } from '@reduxjs/toolkit';

export const dragResizeSlice = createSlice({
  name: 'dragResize',
  initialState: {
    isDragging: false,
    isResizing: false,
  },
  reducers: {
    setDragging: (state, action) => {
      state.isDragging = action.payload;
    },
    setResizing: (state, action) => {
      state.isResizing = action.payload;
    },
  },
});

export const dragStore = configureStore({
  reducer: dragResizeSlice.reducer,
  devTools: true,
});

export const getDraggingResizingState = (state: { dragResize: ReturnType<typeof dragStore.getState> }) =>
  state.dragResize;
