import React from 'react';
import '../styles/modalBody.scss';

export default function ModalBody(props: any) {
  return (
    <div className="modal-body" style={props.style}>
      {props.children}
    </div>
  );
}
