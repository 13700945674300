import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { PencilFill } from 'react-bootstrap-icons';
import { ChevronsLeft, Info, Settings, UserPlus } from 'react-feather';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeData } from '../../../helper/amplitude';
import { formatLong } from '../../../helper/interactive/misc';
import { setCreateRoomStepStore, setRoomCreateOrEditStore, setUnreadChatMessageCountStore } from '../../../store';
import { getAuth } from '../../../store/auth';
import { getCreateRoomParamsRedux } from '../../../store/createRoomFlow';
import { getPresenceAll } from '../../../store/presence';
import {
  getBackgroundMenuOpenStateRedux,
  getChatOpenStateRedux,
  getRoomSettingsOpenStateRedux,
} from '../../../store/settingsMenu';
import { getSpacesRooms, roomType } from '../../../store/spacesRooms';
import Avatar from '../../avatars/avatar';
import { ChatWidget } from '../../chatBox/chatbox';
import CustomiseBackground from '../../customiseBackground/customiseBackground';
import { useOutsideEvent } from '../../interactive/giphy/Giphy';
import { openInvite } from '../../inviteAdmin/inviteAdmin';
import { openProfileEditModal } from '../../modals/templateModals/profileModal';
import SidebarOption from '../sidebarOption/sidebarOption';
import CreateNewRoom from './createNewRoom';
import DropDownSpace from './dropDownSpaces';
import SettingsSidebar from './settingsSidebar';
import './sidebar.scss';

const Sidebar = (props: { toClose: () => void; style: React.CSSProperties }) => {
  const auth = useSelector(getAuth);
  const spacesRooms = useSelector(getSpacesRooms);
  const [openHeader, setOpenHeader] = useState(false);
  const [spacesettingsOpen, setSpaceSettingsOpen] = useState(false);
  const presence = useSelector(getPresenceAll);
  // const [roomSettings, setRoomSettings] = useState(true);
  const roomSettings = useSelector(getRoomSettingsOpenStateRedux);
  const chatOpen = useSelector(getChatOpenStateRedux);
  const backgroundMenuOpen = useSelector(getBackgroundMenuOpenStateRedux);
  const [openCreateNewRoom, setOpenCreateNewRoom] = useState(false);
  const roomParams = useSelector(getCreateRoomParamsRedux);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [createOpen, setCreateOpen] = useState(false);
  const space = spacesRooms.spaces[spacesRooms.currentSpace];
  let history = useHistory();
  const ref = useRef<HTMLDivElement | null>(null);
  const [spatialInfo, setSpatialInfo] = useState(false);
  const [dynInfo, setDynInfo] = useState(false);
  const refRoom = useRef<HTMLDivElement>(null);

  useOutsideEvent(
    refRoom,
    () => {
      setCreateOpen(false);
    },
    'roomMenu1',
    '.roomMenu1'
  );

  useEffect(() => {
    if (chatOpen) setUnreadChatMessageCountStore(0);
  }, [chatOpen]);
  // useOutsideEvent(
  //   ref,
  //   () => {
  //     toggleSidebarStore(false);
  //   },
  //   'menuToggle'
  // );

  const getRooms = (rooms: { [roomID: string]: roomType | undefined }) => {
    const roomsFiltered: { [roomID: string]: roomType | undefined } = {};
    if (searchQuery === '') return rooms;
    const query = searchQuery.toLowerCase();
    for (let key in rooms) {
      const room = rooms[key];
      const roomID = room?.roomID;
      if (room?.roomDetails.roomName.toLowerCase().includes(query)) roomsFiltered[key] = room;
      if (
        _.filter(presence[roomID ?? ''], (presenceRoom) => {
          for (let uid in presenceRoom) {
            const user = presenceRoom[uid];
            if (!user.online) return false;
            if (
              (user.name && user.name.toLowerCase().includes(query)) ||
              (user.email && user.email.toLowerCase().includes(query))
            )
              return true;
          }
          return false;
        }).length > 0
      )
        roomsFiltered[key] = room;
    }
    return roomsFiltered;
  };

  const goTo = (path: string) => {
    window.location.href = path;
  };
  return (
    <>
      {/* {openProfileModal && (
        <ProfileSetUpModal
          toClose={(e) => {
            setOpenProfileModal(false);
          }}
        />
      )} */}
      <div className="sidebar" ref={ref} style={props.style}>
        {!chatOpen && !(backgroundMenuOpen || roomParams.step === 'background') && (
          <>
            <div className="sidebar__header">
              <div className="details">
                <div
                  className="leftSection has-v2-tooltip-shiftedLeftDown"
                  data-tooltip-text="Edit Profile"
                  onClick={() => {
                    openProfileEditModal();
                    sendAmplitudeData('Edit profile clicked', {});
                  }}
                >
                  <div className="edit-profile-container">
                    <div className="edit-button">
                      <PencilFill size={12} />
                    </div>
                  </div>
                  <Avatar name={auth.userDetails.name} src={auth.userDetails.profilePic} smallerFont={true} />
                </div>
                <div className="rightSection">
                  <div className="sidebar__header-profile">
                    <h3>{formatLong(auth.userDetails.name, 15)}</h3>
                    {/* <div className="sidebar__header-dot active"></div> */}
                  </div>
                  <span data-text="workspace-name">{space?.spaceInfo.spaceName}</span>
                </div>
              </div>
              <div className="action-icons">
                <div className="spacesettings" id="spacesettings">
                  <div
                    className="action-icon has-v2-tooltip-long"
                    data-tooltip-text="space settings"
                    onClick={(e) => {
                      setSpaceSettingsOpen(!spacesettingsOpen);
                      sendAmplitudeData('Space settings clicked', {
                        state: !spacesettingsOpen ? 'Enabled' : 'Disabled',
                      });
                    }}
                  >
                    <Settings />
                  </div>
                  {spacesettingsOpen && (
                    <DropDownSpace
                      onClose={() => {
                        setSpaceSettingsOpen(false);
                      }}
                    />
                  )}
                </div>
                <div className="action-icon" onClick={props.toClose}>
                  <ChevronsLeft />
                </div>
              </div>
            </div>

            {!roomSettings && roomParams.step === 'none' && (
              <div className="sidebar__rooms-container">
                <div className="sidebar__rooms">
                  <div className="sidebar__rooms-header">
                    <div className="room-label">Rooms</div>
                    {(spacesRooms.spaces[spacesRooms.currentSpace]?.permission?.isTeamMember ||
                      spacesRooms.spaces[spacesRooms.currentSpace]?.permission?.isOwner) && (
                      <div className="button-icon roomMenu1">
                        <span
                          className="material-icons cursor has-v2-tooltip-long"
                          data-tooltip-text="Add a room"
                          onClick={() => {
                            setCreateOpen(!createOpen);
                            sendAmplitudeData('Create room button clicked', {});
                          }}
                        >
                          add_circle
                        </span>
                        {createOpen && (
                          <div
                            className="dropdown customise roomMenu1"
                            ref={refRoom}
                            style={{ width: '150px', transform: 'translateY(25px)' }}
                          >
                            <div
                              className="option roomMenu1"
                              onClick={() => {
                                // setOpenCreateNewRoom(true);
                                setRoomCreateOrEditStore('create');
                                setCreateRoomStepStore('create');
                                setCreateOpen(false);
                                sendAmplitudeData('Create room type selected', { type: 'spatial' });
                              }}
                            >
                              Spatial Room
                              <Info
                                style={{ width: 16, height: 16, transform: 'translateY(3px)' }}
                                onMouseOver={() => setSpatialInfo(true)}
                                onMouseOut={() => setSpatialInfo(false)}
                              />
                              {spatialInfo && (
                                <div className="info-text-tooltip">
                                  <span>You can have up to 100 people in a room along with spatial audio.</span>
                                </div>
                              )}
                            </div>
                            <div
                              className="option roomMenu1"
                              onClick={() => {
                                // setOpenCreateNewRoom(true);
                                setRoomCreateOrEditStore('create-dynamic');
                                setCreateRoomStepStore('create');
                                setCreateOpen(false);
                                sendAmplitudeData('Create room type selected', { type: 'dynamic' });
                              }}
                            >
                              Dynamic Room
                              <Info
                                style={{ width: 16, height: 16, transform: 'translateY(3px)' }}
                                onMouseOver={() => setDynInfo(true)}
                                onMouseOut={() => setDynInfo(false)}
                              />
                              {dynInfo && (
                                <div className="info-text-tooltip">
                                  <span>
                                    We dynamically create multiple room sections. Works for up to 1000 people.
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="searchBarRooms">
                    <input
                      placeholder="Search for a room or people"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                  <div className="room-list-container">
                    {_.map(getRooms(spacesRooms.rooms), (room, id) => {
                      if (room?.roomDetails?.isHidden && id !== spacesRooms.currentRoom && !room.permission?.isAdmin)
                        return <div key={id}></div>;
                      return <SidebarOption title={room?.roomDetails.roomName ?? ''} key={id} id={id} />;
                    })}
                  </div>
                </div>

                {(spacesRooms.spaces[spacesRooms.currentSpace]?.permission?.isTeamMember ||
                  spacesRooms.spaces[spacesRooms.currentSpace]?.permission?.isOwner) && (
                  <div
                    className="sidebar__rooms inviteBar"
                    onClick={() => {
                      openInvite();
                      sendAmplitudeData('Invite button clicked', {});
                    }}
                  >
                    <div className="invite">
                      <div className="button">
                        <UserPlus />
                        Invite people to your space
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {roomSettings && <SettingsSidebar />}
            {!roomSettings && roomParams.step === 'create' && (
              <CreateNewRoom
                toClose={() => {
                  setOpenCreateNewRoom(false);
                  setCreateRoomStepStore('none');
                }}
              />
            )}
          </>
        )}
        {chatOpen && <ChatWidget toClose={props.toClose} />}
        {(backgroundMenuOpen || roomParams.step === 'background') && <CustomiseBackground toClose={props.toClose} />}
      </div>
    </>
  );
};

export default Sidebar;
