const url = new URL('https://app.reslash.co/avatar?');

export const avatarAttributes = {
  accessory: ['shades', 'roundGlasses', 'tinyGlasses', 'none'],
  body: ['male', 'female'],
  circleColor: ['blue'],
  clothing: ['shirt', 'dressShirt', 'vneck', 'tankTop', 'dress'],
  clothingColor: ['white', 'blue', 'black', 'green', 'red'],
  eyebrows: ['raised', 'leftLowered', 'serious', 'angry', 'concerned'],
  eyes: ['normal', 'leftTwitch', 'happy', 'content', 'squint', 'simple', 'dizzy', 'wink', 'heart'],
  facialHair: ['none', 'none2', 'none3', 'stubble', 'mediumBeard'],
  graphic: ['none', 'redwood', 'gatsby', 'vue', 'react', 'graphql'],
  hair: ['none', 'long', 'bun', 'short', 'pixie', 'balding', 'buzz', 'afro', 'bob'],
  hairColor: ['blonde', 'orange', 'black', 'white', 'brown', 'blue', 'pink'],
  hat: ['none', 'none2', 'none3', 'none4', 'none5', 'beanie', 'turban'],
  hatColor: ['white', 'blue', 'black', 'green', 'red'],
  lashes: ['true', 'false'],
  lipColor: ['red', 'purple', 'pink', 'turqoise', 'green'],
  mask: ['false'],
  faceMask: ['false'],
  mouth: ['grin', 'sad', 'openSmile', 'lips', 'serious', 'tongue'], //removed 'open',
  skinTone: ['light', 'yellow', 'brown', 'dark', 'red', 'black'],
};

export const random = (x: string[]) => {
  const y = x[Math.floor(Math.random() * x.length)];
  return y;
};

export const avatarRandomizer = () => {
  const randomAttributes = {
    accessory: random(avatarAttributes.accessory),
    body: random(avatarAttributes.body),
    circleColor: random(avatarAttributes.circleColor),
    clothing: random(avatarAttributes.clothing),
    clothingColor: random(avatarAttributes.clothingColor),
    eyebrows: random(avatarAttributes.eyebrows),
    eyes: random(avatarAttributes.eyes),
    facialHair: random(avatarAttributes.facialHair),
    graphic: random(avatarAttributes.graphic),
    hair: random(avatarAttributes.hair),
    hairColor: random(avatarAttributes.hairColor),
    hat: random(avatarAttributes.hat),
    hatColor: random(avatarAttributes.hatColor),
    lashes: random(avatarAttributes.lashes),
    lipColor: random(avatarAttributes.lipColor),
    mask: random(avatarAttributes.mask),
    faceMask: random(avatarAttributes.faceMask),
    mouth: random(avatarAttributes.mouth),
    skinTone: random(avatarAttributes.skinTone),
  };

  url.search = new URLSearchParams(randomAttributes).toString();
  return url.toString();
};

export const avatarGenerator = (attributesState: any) => {
  const desiredAttributes = {
    accessory: attributesState.accessory,
    body: attributesState.body,
    clothing: attributesState.clothing,
    clothingColor: attributesState.clothingColor,
    eyebrows: attributesState.eyebrows,
    eyes: attributesState.eyes,
    facialHair: attributesState.facialHair,
    graphic: attributesState.graphic,
    hair: attributesState.hair,
    hairColor: attributesState.hairColor,
    hat: attributesState.hat,
    hatColor: attributesState.hatColor,
    lashes: attributesState.lashes,
    lipColor: attributesState.lipColor,
    faceMask: attributesState.faceMask,
    mouth: attributesState.mouth,
    skinTone: attributesState.skinTone,
  };

  url.search = new URLSearchParams(desiredAttributes).toString();
  return url.toString();
};
