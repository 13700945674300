import { createSlice } from '@reduxjs/toolkit';
import { restrictedZonesType } from '../types/canvasFB';

const initialState: { restrictedZones: restrictedZonesType; zonesVisible: boolean } = {
  restrictedZones: {},
  zonesVisible: false,
};

export const restrictedZoneSlice = createSlice({
  name: 'restrictedZones',
  initialState,
  reducers: {
    setRestrictedZones(state, action) {
      const oldState = state;
      try {
        if (oldState) {
          for (let id in oldState.restrictedZones) {
            if (action.payload[id]) action.payload[id].visible = oldState?.restrictedZones[id]?.visible;
          }
        }
      } catch {}
      state.restrictedZones = action.payload;
    },
    setRestrictedZoneVisible(state, action) {
      if (state.restrictedZones && state.restrictedZones[action.payload.id]) {
        state.restrictedZones[action.payload.id] = {
          ...state.restrictedZones[action.payload.id],
          visible: action.payload.visible,
        };
      } else console.warn(`Zone with id ${action.payload.id} not found.`);
    },
    setZonesVisible(state, action) {
      state.zonesVisible = action.payload;
    },
  },
});

export const getRestrictedZonesRedux = (globalState: {
  restrictedZones: { restrictedZones: restrictedZonesType; zonesVisible: boolean };
}) => globalState.restrictedZones.restrictedZones;

export const getZonesVisibleRedux = (globalState: {
  restrictedZones: { restrictedZones: restrictedZonesType; zonesVisible: boolean };
}) => globalState.restrictedZones.zonesVisible;

export const getOneRestrictedZoneRedux = (id: string) => (globalState: {
  restrictedZones: { restrictedZones: restrictedZonesType };
}) => globalState.restrictedZones.restrictedZones[id];
