import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Search } from 'react-bootstrap-icons';
import { Info, Users, X } from 'react-feather';
import { useSelector } from 'react-redux';
import { getPresenceOfRoom } from '../../store/presence';
import { getSpacesRooms } from '../../store/spacesRooms';
import { useOutsideEvent } from '../interactive/giphy/Giphy';
import DynamicRoom from './dynamicRoom';
import './roomSwitcher.scss';
import RoomSwitcherModal from './switcherModal';

const DynamicRoomsSection = (props: { children: JSX.Element }) => {
  return <div className="dynamic-rooms-section-container">{props.children}</div>;
};

const RoomSwitcher = () => {
  const [openDynamicRooms, setOpenDynamicRooms] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState<string>('');
  const searchTextRef = useRef('');
  const spacesRooms = useSelector(getSpacesRooms);
  const presenceOfRoom = useSelector(getPresenceOfRoom(spacesRooms.currentRoom));
  const [participants, setParticipants] = useState(0);
  const [online, setOnline] = useState<{
    [roomCopy: string]: {
      [userID: string]: { email: string; online: boolean; profilePic: string; color: string; name: string };
    };
  }>({});
  const [maxRoom, setMaxRoom] = useState(0);

  useEffect(() => {
    let online_: typeof online = {};
    let max = 0;
    let part = 0;
    for (let i in presenceOfRoom) {
      online_[i] = {};
      if (parseInt(i) > max) max = parseInt(i);
      const copy = presenceOfRoom[i];
      for (let uid in copy) {
        const user = copy[uid];
        if (user.online) {
          online_[i][uid] = {
            email: user.email,
            online: true,
            profilePic: user.profilePic,
            color: user.color,
            name: user.name,
          };
          part += 1;
        }
      }
    }
    setParticipants(part);
    setMaxRoom(max);
    setOnline(online_);
  }, [presenceOfRoom]);

  useEffect(() => {
    searchTextRef.current = searchText;
  }, [searchText]);

  const [showInfo, setShowInfo] = useState(false);

  const closeDynamicRooms = () => {
    setOpenDynamicRooms(false);
  };

  const dynamicRoomsRef = useRef<HTMLDivElement | null>(null);
  useOutsideEvent(dynamicRoomsRef, closeDynamicRooms, 'switcher-room-modal', '#room-switch-btn-right');
  useEffect(() => {}, [spacesRooms]);
  return (
    <>
      <div className="room-switcher-container">
        <div className="room-switcher-type">
          <span>Room sections</span>
        </div>
        <div className="room-switcher-box">
          <div className="room-members-left">
            <Users size={16} color={'#ffffff'} />
            <div className="partiticpant-count">{participants}</div>
          </div>
          <div className="room-detail-desc">
            <span>
              You are in room {spacesRooms.currentRoomCopy + 1} out of {maxRoom + 1}
            </span>
          </div>
          <div
            className="room-switch-btn-right"
            id="room-switch-btn-right"
            onClick={() => {
              setOpenDynamicRooms(!openDynamicRooms);
            }}
          >
            <span>Switch</span>
          </div>
        </div>
      </div>

      <RoomSwitcherModal isOpen={openDynamicRooms} switcherRef={dynamicRoomsRef}>
        <>
          <div className="dynamic-room-search-bar">
            <div
              className="left-icon"
              onClick={() => {
                setOpenDynamicRooms(false);
              }}
            >
              <X />
            </div>
            <div className="dynamic-room-msgbox">
              <span>SWITCH ROOM SECTIONS</span>
              <div
                className="info"
                onClick={() => {
                  setShowInfo(!showInfo);
                }}
              >
                <Info />
              </div>
              {showInfo && (
                <div className="info-text-tooltip">
                  <span>Switch room sections to interact with new people.</span>
                </div>
              )}
            </div>
            {/*Search box is temporarily hidden for now using css visibility */}
            <div className="search-box-container" style={{ visibility: 'hidden' }}>
              <input
                placeholder="Search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (e.target.value.length < 1) setSearch('');
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setSearch(searchTextRef.current);
                  }
                }}
              />
              <Search
                color={'black'}
                onClick={() => {
                  setSearch(searchTextRef.current);
                }}
              />
            </div>
          </div>
          <DynamicRoomsSection>
            <>
              {_.map(online, (presence, roomCopy) => {
                return (
                  <DynamicRoom
                    presence={presence}
                    ind={parseInt(roomCopy)}
                    roomName={spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails.roomName ?? ''}
                    onClose={() => {
                      setOpenDynamicRooms(false);
                    }}
                  />
                );
              })}
              {/* <DynamicRoom ind={0} presence={{}} isPlaceHolder={true} roomName={''} /> */}
            </>
          </DynamicRoomsSection>
        </>
      </RoomSwitcherModal>
    </>
  );
};

export default RoomSwitcher;
