import { createSlice } from '@reduxjs/toolkit';

export type broadcastType = {
  broadcaster: {
    uid: string;
    name: string;
    profilePic: string;
    listeners: number;
    isLive: boolean;
    ping: number;
  };
  broadcasterStreams: {
    streams: null | {
      video: MediaStreamTrack | null;
      audio: MediaStreamTrack | null;
    };
  };
  broadcastFunc: {
    isLocal: boolean;
    toMute: (flag: boolean) => void;
    toVidMute: (flag: boolean) => void;
    isMuted: boolean;
    isVidMuted: boolean;
  };
  token: string | null;
};

const broadcastInitialState: broadcastType = {
  broadcaster: {
    uid: '',
    name: '',
    profilePic: '',
    listeners: 0,
    isLive: false,
    ping: 0,
  },
  broadcasterStreams: {
    streams: null,
  },
  broadcastFunc: {
    isLocal: false,
    toMute: (flag: boolean) => {},
    toVidMute: (flag: boolean) => {},
    isMuted: true,
    isVidMuted: true,
  },
  token: null,
};

export const broadcastSlice = createSlice({
  name: 'broadcast',
  initialState: broadcastInitialState,
  reducers: {
    setBroadcaster: (state, action) => {
      state.broadcaster.uid = action.payload.uid;
      state.broadcaster.name = action.payload.name;
      state.broadcaster.profilePic = action.payload.profilePic;
      state.broadcaster.listeners = action.payload.listeners;
      state.broadcaster = { ...state.broadcaster };
    },
    setBroadcastStatus: (state, action) => {
      state.broadcaster.isLive = action.payload;
    },
    setBroadcasterMediaTrack: (state, action) => {
      state.broadcasterStreams.streams = {
        video: action.payload.video,
        audio: action.payload.audio,
      };
    },
    // setBroadcasterVideo: (state, action) => {
    //   state.broadcaster.streams = {
    //     video: action.payload,
    //     audio: state.broadcaster.streams?.audio || null,
    //   };
    // },
    // setBroadcasterAudio: (state, action) => {
    //   state.broadcaster.streams = {
    //     video: state.broadcaster.streams?.audio || null,
    //     audio: action.payload,
    //   };
    // },
    setBroadcasterFunc: (state, action) => {
      state.broadcastFunc = {
        isLocal: action.payload.isLocal,
        toMute: action.payload.toMute,
        toVidMute: action.payload.toVidMute,
        isMuted: action.payload.isMuted,
        isVidMuted: action.payload.isVidMuted,
      };
    },
    setTokenForBroadcast: (state, action) => {
      state.token = action.payload;
    },
    setBroadcasterMute: (state, action) => {
      if (action.payload.mic === true) state.broadcastFunc.isMuted = action.payload.flag;
      else state.broadcastFunc.isVidMuted = action.payload.flag;
    },
  },
});

export const getBroadcasterDetailsRedux = (globalState: { broadcast: broadcastType }) => {
  return {
    uid: globalState.broadcast.broadcaster.uid,
    name: globalState.broadcast.broadcaster.name,
    profilePic: globalState.broadcast.broadcaster.profilePic,
  };
};

export const getBroadcasterRedux = (globalState: { broadcast: broadcastType }) => globalState.broadcast.broadcaster;

export const getBroadcastListenersRedux = (globalState: { broadcast: broadcastType }) =>
  globalState.broadcast.broadcaster.listeners;

export const getBroadcasterStreamsRedux = (globalState: { broadcast: broadcastType }) =>
  globalState.broadcast.broadcasterStreams.streams;

export const getBroadcasterTokenRedux = (globalState: { broadcast: broadcastType }) => globalState.broadcast.token;

export const getBroadcasterFuncRedux = (globalState: { broadcast: broadcastType }) =>
  globalState.broadcast.broadcastFunc;

export const getBroadcasterStreamRedux = (globalState: { broadcast: broadcastType }) =>
  globalState.broadcast.broadcasterStreams.streams;
