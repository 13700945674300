import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../../store';
import { getLocalState } from '../../../store/dailycoParticipants';
import { getGetModalState } from '../../../store/modal';
import { ModalStyle } from '../../modal/modal';
import { openNoPermission } from '../../noPermissionModal/noPermissionModal';
import './camSettings.scss';

export const camasscreenID = 'cam-as-screen';
export const openCamAsScreen = openModalThunk(camasscreenID);
export const closeCamAsScreen = closeModalThunk(camasscreenID);

const CamAsScreen = (props: { onScreenSuccess: (flag: boolean) => void }) => {
  const state = useSelector(getGetModalState(camasscreenID));
  const [vidDevices, setVidDevices] = useState<MediaDeviceInfo[]>([]);
  const [vidID, setVidID] = useState<string | undefined>(localStorage.getItem('camAsScreenID') || undefined);
  const [vid, setVid] = useState<MediaStream>();
  const [warning, setWarning] = useState('');
  const localDailyParticipant = useSelector(getLocalState);

  const selectVidID = (vidID: string) => {
    localStorage.setItem('vidID', vidID);
    setVidID(vidID);
  };

  useEffect(() => {
    newModalThunk(camasscreenID, false)();
  }, []);

  useEffect(() => {
    if (!state) {
      vid?.getTracks().forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    }
  }, [state, vid]);

  const vidIDref = useRef<string | undefined>(undefined);
  useEffect(() => {
    vidIDref.current = vidID;
  }, [vidID]);

  useEffect(() => {
    let stream = new MediaStream();
    if (state) {
      (async () => {
        function getCam() {
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            setVidDevices([]);

            for (const i in devices) {
              if (devices[i].kind === 'videoinput') {
                setVidID((vidID) => {
                  if (!vidID) vidID = devices[i].deviceId;
                  return vidID;
                });
                setVidDevices((vidDevices) => [...vidDevices, devices[i]]);
              }
            }
          });
        }
        const cameraOnly = {
          audio: false,
          video: { deviceId: vidIDref.current },
        };
        const accessCam = async (level: MediaStreamConstraints) => {
          // @ts-ignore
          if (
            // @ts-ignore
            (level.audio === undefined || level.audio.deviceId === undefined) &&
            // @ts-ignore
            (level.video === undefined || level.video.deviceId === undefined)
          ) {
            return;
          }
          stream.getTracks().forEach((track) => {
            track.enabled = false;
            track.stop();
          });
          if (!state) return;
          stream = await navigator.mediaDevices.getUserMedia(level);
          getCam();
          setVid(stream);
        };
        const displayVideo = () => {
          let vidHTML: any = document.getElementById('previewScreen');
          if (vidHTML) vidHTML.srcObject = stream;
        };
        try {
          getCam();
          await accessCam(cameraOnly);
          displayVideo();
        } catch (e) {
          setWarning("Camera permission hasn't been granted, you will not be able to share your camera as screen.");
        }
      })();
    }
    return () => {
      stream.getTracks().forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    };
  }, [vidID, state]);

  return (
    <>
      <ReactModal isOpen={state} id={camasscreenID} contentLabel={`${camasscreenID} Modal`} style={ModalStyle}>
        <>
          <div className="cam-setting">
            <div className="square" style={{ maxWidth: 450 }}>
              <video
                id="previewScreen"
                style={{
                  position: 'relative',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  width: '100%',
                  height: '100%',
                }}
                muted={true}
                autoPlay
                controls={false}
              ></video>
            </div>
          </div>
          <div className="select-cont">
            <span className="material-icons">videocam</span>
            <select
              className="select"
              style={{ maxWidth: 'none' }}
              value={vidID}
              onChange={(e) => selectVidID(e.target.value)}
            >
              {vidDevices.map((vidDevice) => {
                return (
                  <option key={vidDevice.deviceId} value={vidDevice.deviceId}>
                    {vidDevice.label}
                  </option>
                );
              })}
            </select>
          </div>
          {warning.length > 0 ? (
            <div className="flexCenter">
              <span className="material-icons marginRight">warning</span>{' '}
              <div className="warning">
                <a href="#" onClick={openNoPermission}>
                  {warning}
                </a>
              </div>
            </div>
          ) : (
            <div className="flexCenter" style={{ padding: '0px', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <div
                className="button"
                style={{ backgroundColor: 'white' }}
                onClick={() => {
                  closeCamAsScreen();
                  props.onScreenSuccess(false);
                }}
              >
                Cancel
              </div>
              <div
                className="button"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  vidID && localDailyParticipant.screenshare(true, vidID);
                  closeCamAsScreen();
                }}
              >
                Share as screen
              </div>
            </div>
          )}
        </>
      </ReactModal>
    </>
  );
};

export default CamAsScreen;
