import firebase from 'firebase';
const debug = (...data: any[]) => {};

const convertArrayToObject = (array: any, key: string) => {
  const initialValue = {};
  return array.reduce((obj: any, item: any) => {
    return {
      ...obj,
      [item[key]]: item,
    };
  }, initialValue);
};

const convertObjectToArray = (obj: any) => {
  const objArray: any = [];
  Object.keys(obj).forEach((key) =>
    objArray.push({
      ...obj[key],
    })
  );
  return objArray;
};

interface ReslashFirebaseState {
  isLoggedIn: boolean;
  user: firebase.User;
}

export enum APP_TYPE {
  WHITE_BOARDS = 'whiteboards',
}

export const getAppDefaultData = (appType: APP_TYPE, userId: string) => {
  if (appType === APP_TYPE.WHITE_BOARDS) {
    return {
      appData: {
        sceneData: {
          //  @ts-ignore
          // elements: [],
          elements: [
            {
              type: 'rectangle',
              version: 141,
              versionNonce: 361174001,
              isDeleted: false,
              id: 'oDVXy8D6rom3H1-LLH2-f',
              fillStyle: 'hachure',
              strokeWidth: 1,
              strokeStyle: 'solid',
              roughness: 1,
              opacity: 100,
              angle: 0,
              x: -100,
              y: -100,
              strokeColor: '#c92a2a',
              backgroundColor: 'transparent',
              width: 0,
              height: 0,
              seed: 1968410350,
              groupIds: [],
            },
          ],
          appState: {
            viewBackgroundColor: '#ffffff',
          },
        },
      },
      createdBy: userId,
      updatedBy: userId,
      cursorPresence: {},
    };
  }
};

export const database = firebase.database();

export const createApp = (appType: APP_TYPE, spaceId: string, roomId: string, appId: string, userId: string) => {
  return new Promise((resolve, reject) => {
    const defaultApp = getAppDefaultData(appType, userId);
    const app = {
      appData: {
        sceneData: {
          elements: convertArrayToObject(defaultApp?.appData.sceneData.elements, 'id'),
          appState: defaultApp?.appData.sceneData.appState,
        },
      },
      createdBy: userId,
      updatedBy: userId,
    };

    debug('saving app to firebase: ', app);

    database.ref(`apps/${appType}/${spaceId}/${roomId}/${appId}`).set(app, (error) => {
      if (error) {
        /**
         * throw error
         */
        reject(error);
      }
      resolve(
        database
          .ref(`apps/${appType}/${spaceId}/${roomId}/${appId}`)
          .get()
          .then(async (snapshot) => {
            const firebaseApp = snapshot.val();
            debug('getting app from firebase: ', firebaseApp);
            /**
             * convert elements of object to array
             */
            const app = {
              appData: {
                sceneData: {
                  elements: convertObjectToArray(firebaseApp?.appData.sceneData.elements),
                  appState: firebaseApp?.appData.sceneData.appState,
                },
              },
              createdBy: userId,
              updatedBy: userId,
            };
            debug('returning app to excalidraw: ', app);
            return app;
          })
      );
    });
  });
};

export const getApp = async (appType: APP_TYPE, spaceId: string, roomId: string, appId: string, userId: string) => {
  /**
   * check if the app is already present if not create app with basic data
   */
  const value = (await database.ref(`apps/${appType}/${spaceId}/${roomId}/${appId}`).get()).val();
  if (value === null) {
    /**
     * create new app
     */
    return new Promise((resolve, reject) => {
      database.ref(`apps/${appType}/${spaceId}/${roomId}/${appId}`).set(getAppDefaultData(appType, userId), (error) => {
        if (error) {
          /**
           * throw error
           */
          reject(error);
        }
        resolve(
          database
            .ref(`app/${appType}/${spaceId}/${roomId}/${appId}`)
            .get()
            .then((snapshot) => snapshot.val())
        );
      });
    });
  } else {
    return value;
  }
};

export const updateAppElement = async (
  appType: APP_TYPE,
  spaceId: string,
  roomId: string,
  appId: string,
  userId: string,
  element: any
) => {
  debug('updating app element: ', element);
  database.ref(`apps/${appType}/${spaceId}/${roomId}/${appId}/appData/sceneData/elements/${element.id}`).set(element);
};

export const updateAppState = async (
  appType: APP_TYPE,
  spaceId: string,
  roomId: string,
  appId: string,
  userId: string,
  appState: any
) => {
  debug('firebase updateAppState called');
  database.ref(`apps/${appType}/${spaceId}/${roomId}/${appId}appData/sceneData/appState`).set(appState);
};

export function watchForAppChanges(
  appType: APP_TYPE,
  spaceId: string,
  roomId: string,
  appId: string,
  userId: string,
  callBack: (p: any) => void
) {
  const ref = database.ref(`apps/${appType}/${spaceId}/${roomId}/${appId}/appData`);
  // const callback = (snapshot: any) => {
  //   callBack(snapshot.val());
  // };
  const valueCallback = (snapshot: any) => {
    const firebaseAppData = snapshot.val();
    debug('valueCallback before: ', firebaseAppData);
    let app: any = null;
    if (firebaseAppData !== null) {
      //  @ts-ignore
      /**
       * convert elements of object to array
       */
      app = {
        appData: {
          sceneData: {
            elements: convertObjectToArray(firebaseAppData.sceneData.elements),
            appState: firebaseAppData.sceneData.appState,
          },
        },
        createdBy: userId,
        updatedBy: userId,
      };
    }
    debug('valueCallback after: ', app);
    callBack(app);
  };
  ref.on('value', valueCallback);
  return () => {
    ref.off('value', valueCallback);
  };
}

export function watchForCursorPresenceChanges(
  appType: APP_TYPE,
  spaceId: string,
  roomId: string,
  appId: string,
  callBack: (p: any) => void
) {
  const ref = database.ref(`apps/${appType}/${spaceId}/${roomId}/${appId}/cursorPresence`);
  const valueCallback = (snapshot: any) => {
    const cursorPresence = snapshot.val();
    debug('valueCallback before: ', cursorPresence);
    callBack(cursorPresence);
  };
  ref.on('value', valueCallback);
  return () => {
    ref.off('value', valueCallback);
  };
}

export function updateUserCursorPresence(
  appType: APP_TYPE,
  spaceId: string,
  roomId: string,
  appId: string,
  userId: string,
  updatePayload: { x: number; y: number; uname: string }
) {
  debug('updating user cursor presence');
  database.ref(`apps/${appType}/${spaceId}/${roomId}/${appId}/cursorPresence/${userId}`).set(updatePayload);
}
