import { canvasSize, volBounds } from '../config';
import dailyCoObj, { DailyCoCall } from '../helper/interactive/dailyco';
import { watchMegaphone } from '../helper/interactive/firebaseRTD';
import { findGetParameter, scale } from '../helper/interactive/misc';
import { getSpatialAudio, getUserAudioZoneFromStore } from '../store';
import { audioZone } from '../store/audiozones';
import { source, spatialAudio } from '../store/spatialAudio';

const cellSize = 128;
window.volMeters = {};

export function cleanSpatial(userID: string) {
  if (window.volMeters[userID]) {
    window.volMeters[userID].destroy();
    delete window.volMeters[userID];
  }
}

export function updateAllBubbleSize(bubbleSize: number) {
  const spatialAudioObj = getSpatialAudio();
  //console.log(_.keys(spatialAudioObj.sources).length)
  for (const sourceID in spatialAudioObj.sources) {
    const el: any = document.getElementById(sourceID);
    if (el) {
      const vol = el.volume;
      const size = scale(vol, 0, 1, 45, 100) / 100;
      const fontSize = scale(vol, 0, 1, 15, 40);
      // el.parentElement.parentElement.style.width = bubbleSize * size + 'px';
      // el.parentElement.parentElement.style.height = bubbleSize * size + 'px';

      el.parentElement.parentElement.style.fontSize = fontSize + 'px';
    }
  }
}

export function callVolumeChanged(dailyCoID: string, vol: number, callObj: DailyCoCall, vidOnPerm: boolean) {
  if (findGetParameter('dontSubscribe')) return { video: false, audio: false };
  if (vol > 0) {
    callObj.callObj?.updateParticipant(dailyCoID, { setSubscribedTracks: { video: true, audio: true } });
    return {
      video: true,
      audio: true,
    };
  } else {
    callObj.callObj?.updateParticipant(dailyCoID, {
      setSubscribedTracks: { video: vidOnPerm ? true : false, audio: false },
    });
    return {
      video: vidOnPerm ? true : false,
      audio: false,
    };
  }
}

function scaleSource(sourceID: string, scale: number) {
  const el = document.getElementById(`onlydrag_${sourceID}`);
  if (el) {
    const child = el.children[0] as HTMLElement;
    if (child) {
      child.style.transform = `scale(${scale})`;
    }
    el.style.setProperty('--bubbleScale', scale + '');
  }
}

class SpatialAudio {
  gridLookup: { [location: string]: string[] };
  listenerPosition: [number, number];
  listenerActualPosition: [number, number];
  distanceLookup: { [location: string]: number };
  maxXCells: number;
  maxYCells: number;
  megaphones: { [userID: string]: boolean };
  bubbleSize: number;
  nonZeroVols: { [sourceID: string]: boolean };
  callObj!: DailyCoCall;
  userIDDailyIDMapping: { [userID: string]: string };
  firstEnableVideo: { [userID: string]: boolean };
  localID: string;
  isSpatialOff: boolean;
  audioZone: audioZone | null;
  subscribedTo: { [dailyID: string]: boolean };
  vidOnPerm: boolean;
  videoSubscribed: { [dailyID: string]: boolean };
  rcLookup: { [userID: string]: number };

  constructor() {
    this.gridLookup = {};
    this.distanceLookup = {};
    this.maxXCells = Math.ceil(canvasSize.w / cellSize);
    this.maxYCells = Math.ceil(canvasSize.h / cellSize);
    //this.populateDistLookup();
    this.listenerPosition = [-1, -1];
    this.listenerActualPosition = [-1, -1];
    this.megaphones = {};
    this.bubbleSize = 128;
    this.nonZeroVols = {};
    this.userIDDailyIDMapping = {};
    this.firstEnableVideo = {};
    this.localID = '';
    this.isSpatialOff = false;
    this.audioZone = null;
    this.subscribedTo = {};
    this.vidOnPerm = false;
    this.videoSubscribed = {};
    this.rcLookup = {};
  }

  setLocalID(localID: string) {
    this.localID = localID;
  }

  setRcLookup(userID: string, rc: number) {
    this.rcLookup[userID] = rc;
  }

  setCallObj(callObj: DailyCoCall) {
    this.callObj = callObj;
  }

  setVideoOnPerm(flag: boolean) {
    if (flag !== undefined) this.vidOnPerm = flag;
    else this.vidOnPerm = false;

    const spatialAudioObj = getSpatialAudio();
    for (let sourceID in spatialAudioObj.sources) {
      const userID = sourceID.split('_')[0];
      const dailyCoID = this.userIDDailyIDMapping[userID];
      dailyCoObj?.callObj?.updateParticipant(dailyCoID, {
        setSubscribedTracks: { video: this.vidOnPerm ? true : this.subscribedTo[dailyCoID] },
      });
      this.videoSubscribed[dailyCoID] = this.vidOnPerm ? true : this.subscribedTo[dailyCoID];
      if (this.vidOnPerm) scaleSource(sourceID, 1);
    }
    this.recalculateAllVolumesInInfluence();
  }

  setUserIDDailyID(userID: string, dailyID: string) {
    this.userIDDailyIDMapping[userID] = dailyID;
  }

  setBubbleSize(bubbleSize: number) {
    if (bubbleSize) this.bubbleSize = bubbleSize;
    else this.bubbleSize = 128;
  }

  sqr(x: number) {
    return Math.pow(x, 2);
  }

  calcDist(x1: number, y1: number, x2: number, y2: number) {
    return Math.sqrt(this.sqr(x1 - x2) + this.sqr(y1 - y2));
  }

  populateDistLookup() {
    for (let i = 0; i < this.maxXCells; i++) {
      for (let j = 0; j < this.maxYCells; j++) {
        for (let i1 = 0; i1 < this.maxXCells; i1++) {
          for (let j1 = 0; j1 < this.maxYCells; j1++) {
            this.distanceLookup[`${i}:${j}<>${i1}:${j1}`] = this.calcDist(i, j, i1, j1);
          }
        }
      }
    }
  }

  getGridPosition(x: number, y: number): [number, number] {
    return [Math.ceil(x / cellSize), Math.ceil(y / cellSize)];
  }

  calculateVolume(location: [number, number]) {
    const distance = this.calcDist(
      location[0],
      location[1],
      this.listenerActualPosition[0],
      this.listenerActualPosition[1]
    );
    const lower = (this.bubbleSize * volBounds.lower) / 128;
    const upper = (this.bubbleSize * volBounds.upper) / 128;
    if (distance > upper) {
      return 0;
    } else if (distance < lower) {
      return 1;
    } else {
      return 1 - scale(distance, lower, upper, 0, 1);
    }
  }

  changeDailySubscription(userID: string, flag: boolean, vidOff?: boolean, force?: boolean) {
    const dailyID = this.userIDDailyIDMapping[userID];
    if (this.subscribedTo[dailyID] !== flag || force) {
      const state = callVolumeChanged(
        this.userIDDailyIDMapping[userID],
        flag ? 1 : 0,
        this.callObj,
        !vidOff ? this.vidOnPerm : false
      );
      this.subscribedTo[dailyID] = flag;
      this.videoSubscribed[dailyID] = state.video;
    }
  }

  volChanged(el: HTMLAudioElement, vol: number) {
    if (el) {
      const userID = el.id.split('_')[0];
      if (
        this.isSpatialOff ||
        (this.megaphones && this.megaphones[userID]) ||
        (this.audioZone && this.audioZone.participants[userID] && !this.audioZone.participants[userID].exited)
      )
        vol = 1;
      if (this.audioZone && (!this.audioZone.participants[userID] || this.audioZone.participants[userID].exited))
        vol = 0;
      // if (vol > 0) {
      // } else {
      //   if (window.volMeters[userID]) {
      //     cleanSpatial(userID);
      //   }
      // }
      if (userID !== this.localID) {
        const size = scale(vol, 0, 1, 75, 100) / 100;
        if (!this.vidOnPerm) scaleSource(el.id, size);
        if (el.volume > 0) this.nonZeroVols[el.id] = true;
        else delete this.nonZeroVols[el.id];
      }
      try {
        el.volume = vol;
      } catch (e) {}
      if (this.userIDDailyIDMapping[userID] && this.callObj) this.changeDailySubscription(userID, vol > 0);
      if (vol > 0) {
        this.nonZeroVols[el.id] = true;
      }
    }
  }

  spatialOff(
    spatialAudioObj: {
      sources: spatialAudio;
      listener: source;
    },
    flag: boolean
  ) {
    for (let sourceID in spatialAudioObj.sources) {
      const el: HTMLAudioElement = document.getElementById(sourceID) as HTMLAudioElement;
      this.volChanged(el, flag ? 1 : 0);
    }
    this.isSpatialOff = flag;
  }

  recalculatePosition(
    sourceID: string,
    spatialAudioObj: {
      sources: spatialAudio;
      listener: source;
    },
    calculateVol = true
  ) {
    const userID = sourceID.split('_')[0];
    const position = spatialAudioObj.sources[sourceID].position;
    const location = this.getGridPosition(position.x, position.y);
    const locationString = `${location[0]}:${location[1]}`;
    if (!this.gridLookup[locationString]) {
      this.gridLookup[locationString] = [];
    }
    this.gridLookup[locationString].push(sourceID);
    if (calculateVol) {
      const el: HTMLAudioElement = document.getElementById(sourceID) as HTMLAudioElement;
      if (el) {
        if (this.megaphones && this.megaphones[userID]) {
          this.volChanged(el, 1);
        } else {
          const vol = this.calculateVolume([position.x, position.y]);
          this.volChanged(el, vol);
        }
      }
    }
  }

  calculateListenPosition(position: { x: number; y: number }) {
    this.listenerPosition = this.getGridPosition(position.x, position.y);
    this.listenerActualPosition = [position.x, position.y];
  }

  muteSource(sourceID: string) {
    const el: HTMLAudioElement = document.getElementById(sourceID) as HTMLAudioElement;
    if (el) {
      this.volChanged(el, 0);
    }
  }

  recalculateAllVolumesInInfluence() {
    const audioZone = getUserAudioZoneFromStore();
    if (audioZone) this.audioZone = audioZone.zone;
    const upper = (this.bubbleSize * volBounds.upper) / 128;
    const spatialAudioObj = getSpatialAudio();
    const radiusOfInfluence = Math.ceil(upper / cellSize);
    const lowerX = Math.max(this.listenerPosition[0] - radiusOfInfluence, 0);
    const upperX = Math.min(this.listenerPosition[0] + radiusOfInfluence, this.maxXCells);
    const lowerY = Math.max(this.listenerPosition[1] - radiusOfInfluence, 0);
    const upperY = Math.min(this.listenerPosition[1] + radiusOfInfluence, this.maxYCells);
    const nonZeroVols: { [sourceID: string]: boolean } = {};
    for (let x = lowerX; x <= upperX; x++) {
      for (let y = lowerY; y <= upperY; y++) {
        for (let ind in this.gridLookup[`${x}:${y}`]) {
          const sourceID = this.gridLookup[`${x}:${y}`][ind];
          const position = spatialAudioObj.sources[sourceID].position;
          let vol = this.calculateVolume([position.x, position.y]);
          const userID = sourceID.split('_')[0];
          const el: HTMLAudioElement = document.getElementById(sourceID) as HTMLAudioElement;
          if (el) {
            if (this.megaphones && this.megaphones[userID]) vol = 1;
            nonZeroVols[sourceID] = true;
            this.volChanged(el, vol);
          }
        }
      }
    }
    for (const sourceID in this.nonZeroVols) {
      if (!nonZeroVols[sourceID]) {
        const el = document.getElementById(sourceID) as HTMLAudioElement;
        this.volChanged(el, 0);
      }
    }
  }

  megaphoneListener(spaceID: string, roomID: string) {
    if (roomID.length < 2) return () => {};
    return watchMegaphone(spaceID, roomID, (megaphone) => {
      this.megaphones = megaphone;
      for (let userID in this.megaphones) {
        if (this.megaphones && this.megaphones[userID]) {
          const sourceID = userID + '_audio';
          const el: any = document.getElementById(userID + '_audio');
          //console.log(el);
          if (el) {
            const megaphoneIcon = document.getElementById(`${userID}_megaphone`);
            //console.log(megaphoneIcon);
            if (megaphoneIcon) megaphoneIcon.style.display = 'flex';
            this.volChanged(el, 1);
          }
        } else {
          const megaphoneIcon = document.getElementById(`${userID}_megaphone`);
          //console.log(megaphoneIcon);
          if (megaphoneIcon) megaphoneIcon.style.display = 'none';
          if (userID !== this.localID) {
            const el: any = document.getElementById(userID + '_audio');
            if (el) this.recalculatePosition(el.id, getSpatialAudio(), true);
          }
        }
      }
    });
  }
}

const spatialAudioController = new SpatialAudio();

export default spatialAudioController;
