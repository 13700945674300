//old

import React, { useEffect, useRef, useState } from 'react';
import { VolumeMuteFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { canvasSize, volBounds } from '../../../../config';
import { sendAmplitudeData } from '../../../../helper/amplitude';
import { watchBubbleRoomCopy } from '../../../../helper/interactive/firebaseRTD';
import { detectIfInRestrictedZone } from '../../../../helper/interactive/misc';
import spatialAudioController from '../../../../spatialAudio/spatialAudioOptimized';
import { getListenerPosition } from '../../../../store';
import { getOtherUserAudioZoneID, getUserAudioZones } from '../../../../store/audiozones';
import { getAuth } from '../../../../store/auth';
import { getUserPresence, presenceUser } from '../../../../store/presence';
import { getRestrictedZonesRedux } from '../../../../store/restrictedZones';
import { getSpacesRooms } from '../../../../store/spacesRooms';
import { dimension, firebaseCallback, xy } from '../../../../types/canvasFB';
import { Reaction } from '../../../reactionsModal/reactions';
import { RecordingAnimation } from '../../../recorder/recorder';
import NameTag from './nameTag';
import './OnlyDrag.scss';
import SimpleDraggable from './simpleDraggable';

export default function OnlyDrag(props: {
  childrenKey: string;
  name: string;
  x: number;
  y: number;
  onDimChange: (d: dimension) => void;
  watcher: (callback: (c: xy) => void) => firebaseCallback;
  children: JSX.Element;
  isParticipant?: boolean;
  presence?: presenceUser | null;
}) {
  const spacesRooms = useSelector(getSpacesRooms);
  const [canDrag, setCanDrag] = useState(false);
  const auth = useSelector(getAuth);
  const size = useRef(128);
  const currentZone = useRef('');
  const id = props.childrenKey.split('_')[0];
  const myAudioZone = useSelector(getUserAudioZones);
  const userAudioZone = useSelector(getOtherUserAudioZoneID(id));
  const [bounds, setBounds] = useState({ left: 0, right: canvasSize.w, top: 0, bottom: canvasSize.h });
  const noGoZones = useSelector(getRestrictedZonesRedux);
  const bubbleRoomCopy = useRef(0);
  const simpleDraggableRef = useRef<SimpleDraggable | null>(null);

  const muteAudioForMe = false;

  const [zIndex, setZIndex] = useState(auth.user.id === id ? 6 : 5);
  const frame = useRef({
    translate: [props.x, props.y],
  });

  useEffect(() => {
    let unsub = () => {};
    const id = props.childrenKey.split('_')[0];
    if (id) {
      unsub = watchBubbleRoomCopy(spacesRooms.currentSpace, spacesRooms.currentRoom, id, (roomCopy) => {
        if (roomCopy) bubbleRoomCopy.current = roomCopy;
        else bubbleRoomCopy.current = 0;
      });
    }
    return unsub;
  }, [props.childrenKey]);

  const presence = useSelector(getUserPresence(spacesRooms.currentRoom, spacesRooms.currentRoomCopy + '', id));

  useEffect(() => {
    if (userAudioZone && currentZone.current !== userAudioZone.zoneID && id === auth.user.id) {
      const zone = userAudioZone.zone;
      const position = zone.participants[auth.user.id].position;
      const xy = { x: 0, y: 0 };
      xy.y = zone.position.y + (Math.ceil(position / 2) - 1) * 180 + 10;
      if (position % 2) xy.x = zone.position.x + 190;
      else xy.x = zone.position.x + 10;
      props.onDimChange({
        x: xy.x,
        y: xy.y,
        w: size.current,
        h: size.current,
        d: {
          rc: bubbleRoomCopy.current,
        },
      });
      currentZone.current = userAudioZone.zoneID;
    }
  }, [userAudioZone, auth, size]);

  useEffect(() => {
    if (userAudioZone) {
      setBounds({
        left: userAudioZone.zone.position.x,
        right: userAudioZone.zone.size.w,
        top: userAudioZone.zone.position.y,
        bottom: userAudioZone.zone.position.y + userAudioZone.zone.size.h,
      });
    }
  }, [userAudioZone]);

  useEffect(() => {
    const watcher = props.watcher((c) => {
      frame.current.translate = [c.x, c.y];
      const target = document.getElementById(`onlydrag_${props.childrenKey}`)!;
      if (target) target.style.transform = `translate(${c.x}px, ${c.y}px)`;
      if (simpleDraggableRef.current) {
        simpleDraggableRef.current?.currentPositionUpdate(c);
      }
    });
    return () => {
      watcher();
    };
  }, [props.watcher]);

  useEffect(() => {
    const target = document.getElementById(`onlydrag_${props.childrenKey}`)!;
    target.style.transform = `translate(${props.x}px, ${props.y}px)`;
  }, [props.x, props.y]);

  useEffect(() => {
    const canMoveBubble = () => {
      if (spacesRooms.permissionV2[spacesRooms.currentUserRole]?.moveself && auth.user.id === id) return true;
      else if (!userAudioZone) {
        if (spacesRooms.permissionV2[spacesRooms.currentUserRole]?.moveothers) return true;
        return false;
      } else if (userAudioZone.zoneID === myAudioZone?.zoneID) {
        if (spacesRooms.permissionV2[spacesRooms.currentUserRole]?.moveothers) return true;
        return false;
      } else return false;
    };
    setCanDrag(canMoveBubble());

    size.current = spacesRooms.currentRoomPermission.size;
    const target = document.getElementById(`scaler_${props.childrenKey}`)!;
    target.style.width = `${size.current}px`;
    target.style.height = `${size.current}px`;
  }, [
    auth.user,
    props.childrenKey,
    spacesRooms.currentRoom,
    spacesRooms.permissionV2,
    spacesRooms.currentUserRole,
    spacesRooms.currentRoomPermission,
  ]);

  const [showNametag, setShowNametag] = useState(false);
  const isMouseOver = useRef(false);

  useEffect(() => {
    if (simpleDraggableRef.current) simpleDraggableRef.current.isDraggable = canDrag;
  }, [canDrag]);

  useEffect(() => {
    const simpleDraggable = new SimpleDraggable(
      document.getElementById(`onlydrag_${props.childrenKey}`) as HTMLElement,
      canDrag,
      `interactive ` + (props.isParticipant ? 'userMoveable' : ''),
      bounds
    );
    simpleDraggable.addEventListener('onDragStart', () => {
      if (id === auth.user.id) {
        const { x, y } = getListenerPosition();
        const spatialRadius = (spatialAudioController.bubbleSize * volBounds.upper) / 128;
        sendAmplitudeData('Bubble drag started', {
          x: frame.current.translate[0],
          y: frame.current.translate[1],
          position: { x, y, spatialRadius },
        });
      }
    });
    simpleDraggable.addEventListener('onDrag', (e) => {
      let translateNew = { x: e.newPos.x, y: e.newPos.y, d: parseInt(size.current + '') };
      let oldTranslate = { x: frame.current.translate[0], y: frame.current.translate[1] };
      translateNew = detectIfInRestrictedZone(oldTranslate, translateNew, noGoZones);
      frame.current.translate = [translateNew.x, translateNew.y];
      // e.target.style.transform = `translate(${frame.current.translate[0]}px, ${frame.current.translate[1]}px)`;
      const id = simpleDraggable.target.id;
      const ele = document.getElementById(id);
      if (ele) {
        ele.style.transform = `translate(${frame.current.translate[0]}px, ${frame.current.translate[1]}px)`;
      }
      props.onDimChange({
        x: frame.current.translate[0],
        y: frame.current.translate[1],
        w: size.current,
        h: size.current,
        d: {
          rc: bubbleRoomCopy.current,
        },
      });
    });
    simpleDraggable.addEventListener('onDragEnd', () => {
      if (id === auth.user.id) {
        const { x, y } = getListenerPosition();
        const spatialRadius = (spatialAudioController.bubbleSize * volBounds.upper) / 128;
        sendAmplitudeData('Bubble drag stopped', {
          x: frame.current.translate[0],
          y: frame.current.translate[1],
          position: { x, y, spatialRadius },
        });
      }
    });
    const cleanup = simpleDraggable.initListeners();
    simpleDraggableRef.current = simpleDraggable;
    return () => {
      cleanup();
    };
  }, []);

  return (
    <>
      <div
        id={`onlydrag_${props.childrenKey}`}
        className={`content ${canDrag ? 'interactive' : ''} uncover`}
        onMouseOver={() => {
          setZIndex(7);
        }}
        onMouseLeave={() => {
          setZIndex(auth.user.id === id ? 6 : 5);
        }}
        style={{
          width: 'auto',
          height: 'auto',
          transform: `translate(${props.x}px, ${props.y}px)`,
          position: 'absolute',
          zIndex: zIndex,
        }}
      >
        <div
          className="onlyDragContent"
          style={{ width: 'auto', height: 'auto' }}
          onMouseEnter={(e) => {
            isMouseOver.current = true;
            setShowNametag(true);
          }}
          onMouseLeave={(e) => {
            isMouseOver.current = false;
            setTimeout(() => {
              if (!isMouseOver.current) setShowNametag(false);
            }, 1500);
          }}
        >
          <div
            className="scaler"
            id={`scaler_${props.childrenKey}`}
            style={{
              width: size.current,
              height: size.current,
            }}
          >
            {props.children}
          </div>

          {presence?.isRecording && (
            <div
              className="is-recording-label"
              style={{
                marginTop: '8px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <RecordingAnimation />
            </div>
          )}

          {muteAudioForMe && (
            <div
              className="is-muted-for-me"
              style={{
                marginTop: '8px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <VolumeMuteFill size={42} fill={'rgba(0, 0, 0, 0.25)'} color={'white'} stroke={'grey'} />
            </div>
          )}
        </div>

        {presence &&
          presence.reaction &&
          presence.reaction.type &&
          presence.reaction.type === 'text' &&
          presence.reaction.payload &&
          presence.reaction.payload.text &&
          presence.reaction.payload.text !== '' &&
          presence.reaction.sentTime && (
            <Reaction
              id={props.childrenKey}
              type={presence.reaction.type}
              payload={{
                text: presence.reaction.payload.text,
              }}
              sentTime={presence.reaction.sentTime}
            />
          )}

        {props.isParticipant && showNametag && (
          <NameTag
            id={id}
            name={props.name}
            showNametag={(state: boolean) => {
              setShowNametag(state);
            }}
            getIsMouseOver={() => isMouseOver.current}
            setIsMouseOver={(state: boolean) => {
              isMouseOver.current = state;
            }}
          />
        )}
      </div>

      {/*<Moveable*/}
      {/*  target={document.getElementById(`onlydrag_${props.childrenKey}`)}*/}
      {/*  draggable={canDrag}*/}
      {/*  className={`interactive ` + (props.isParticipant ? 'userMoveable' : '')}*/}
      {/*  throttleDrag={0}*/}
      {/*  startDragRotate={0}*/}
      {/*  throttleDragRotate={0}*/}
      {/*  onDragStart={(e) => {*/}
      {/*    e.set(frame.current.translate);*/}
      {/*    if (id === auth.user.id) {*/}
      {/*      const { x, y } = getListenerPosition();*/}
      {/*      const spatialRadius = (spatialAudioController.bubbleSize * volBounds.upper) / 128;*/}
      {/*      sendAmplitudeData('Bubble drag started', {*/}
      {/*        x: frame.current.translate[0],*/}
      {/*        y: frame.current.translate[1],*/}
      {/*        position: { x, y, spatialRadius },*/}
      {/*      });*/}
      {/*    }*/}
      {/*  }}*/}
      {/*  scalable={false}*/}
      {/*  snappable={true}*/}
      {/*  bounds={bounds}*/}
      {/*  origin={false}*/}
      {/*  onDrag={(e) => {*/}
      {/*    let translateNew = { x: e.beforeTranslate[0], y: e.beforeTranslate[1], d: parseInt(size.current + '') };*/}
      {/*    let oldTranslate = { x: frame.current.translate[0], y: frame.current.translate[1] };*/}
      {/*    translateNew = detectIfInRestrictedZone(oldTranslate, translateNew, noGoZones);*/}
      {/*    frame.current.translate = [translateNew.x, translateNew.y];*/}
      {/*    // e.target.style.transform = `translate(${frame.current.translate[0]}px, ${frame.current.translate[1]}px)`;*/}
      {/*    const id = e.target.id;*/}
      {/*    const ele = document.getElementById(id);*/}
      {/*    if (ele) {*/}
      {/*      ele.style.transform = `translate(${frame.current.translate[0]}px, ${frame.current.translate[1]}px)`;*/}
      {/*    }*/}
      {/*    props.onDimChange({*/}
      {/*      x: frame.current.translate[0],*/}
      {/*      y: frame.current.translate[1],*/}
      {/*      w: size.current,*/}
      {/*      h: size.current,*/}
      {/*      d: {*/}
      {/*        rc: bubbleRoomCopy.current,*/}
      {/*      },*/}
      {/*    });*/}
      {/*  }}*/}
      {/*  onDragEnd={(e) => {*/}
      {/*    if (id === auth.user.id) {*/}
      {/*      const { x, y } = getListenerPosition();*/}
      {/*      const spatialRadius = (spatialAudioController.bubbleSize * volBounds.upper) / 128;*/}
      {/*      sendAmplitudeData('Bubble drag stopped', {*/}
      {/*        x: frame.current.translate[0],*/}
      {/*        y: frame.current.translate[1],*/}
      {/*        position: { x, y, spatialRadius },*/}
      {/*      });*/}
      {/*    }*/}
      {/*  }}*/}
      {/*/>*/}
    </>
  );
}
