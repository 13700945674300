import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { CameraVideoFill, CameraVideoOffFill, MicFill, MicMuteFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { setMediaPermissionsStore } from '../../store';
import { getAuth } from '../../store/auth';
import { getMediaPermissionsRedux } from '../../store/dailycoParticipants';
import { getSpacesRooms } from '../../store/spacesRooms';
import Avatar from '../avatars/avatar';
import DropDown from '../dropdown/dropdown';
import './voiceVideoSettings.scss';

const VideoPreview = (props: { stream: MediaStream | null }) => {
  const previewRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (previewRef.current && props.stream) previewRef.current.srcObject = props.stream;
  }, [props.stream, previewRef]);
  return (
    <video
      style={{ objectFit: 'cover', transform: 'scaleX(-1)' }}
      className="profile-pic circle-vid preview"
      id="preview"
      ref={previewRef}
      playsInline
      autoPlay
      muted
    />
  );
};

const VoiceAndVideoSettings = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  const [vidDevices, setVidDevices] = useState<{ [id: string]: string }>({});
  const [audDevices, setAudDevices] = useState<{ [id: string]: string }>({});
  const cameraSaved = localStorage.getItem('vidID');
  const micSaved = localStorage.getItem('micID');
  const [vidID, setVidID] = useState<string | undefined>(cameraSaved ? cameraSaved : undefined);
  const [micID, setMicID] = useState<string | undefined>(micSaved ? micSaved : undefined);
  const [audOn, setAudOn] = useState<boolean>(true);
  const [vidOn, setVidOn] = useState<boolean>(true);
  const [vidDisabled, setVidDisabled] = useState(false);
  const [audDisabled, setAudDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const auth = useSelector(getAuth);
  const mediaPermissions = useSelector(getMediaPermissionsRedux);
  // useEffect(() => {
  //   setVidRoomPermission(spacesRooms.permissionV2[spacesRooms.currentUserRole]?.cam === true);
  //   setMicRoomPermission(spacesRooms.permissionV2[spacesRooms.currentUserRole]?.mic === true);
  // }, [spacesRooms.permissionV2, spacesRooms.currentUserRole]);

  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices: typeof vidDevices = {};
      const audioDevices: typeof audDevices = {};
      for (let i in devices) {
        const device = devices[i];
        if (device.kind === 'videoinput') {
          videoDevices[device.deviceId] = device.label;
        } else if (device.kind === 'audioinput') {
          audioDevices[device.deviceId] = device.label;
        }
        setVidDevices({ ...videoDevices });
        setAudDevices({ ...audioDevices });
        const savedVidID = localStorage.getItem('vidID');
        const savedMicID = localStorage.getItem('micID');
        if (savedVidID && videoDevices[savedVidID]) {
          setVidID(savedVidID);
        } else if (!vidID || !videoDevices[vidID]) {
          _.keys(videoDevices).length > 0 && setVidID(_.keys(videoDevices)[0]);
        }
        if (savedMicID && audioDevices[savedMicID]) {
          setMicID(savedMicID);
        } else if (!micID || !audioDevices[micID]) {
          _.keys(audioDevices).length > 0 && setMicID(_.keys(audioDevices)[0]);
        }
      }
    })();
  }, []);

  const [isValidBrowser, setIsValidBrowser] = useState(false);

  useEffect(() => {
    if (
      window.SharedArrayBuffer !== undefined &&
      (window as any).VideoDecoder !== undefined &&
      (window as any).VideoEncoder !== undefined &&
      (window as any).AudioEncoder !== undefined &&
      (window as any).AudioDecoder !== undefined
    ) {
      setIsValidBrowser(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (loading) return;
      try {
        setLoading(true);
        stream?.getVideoTracks().forEach((track) => {
          track.enabled = false;
          track.stop();
        });
        if (vidOn && mediaPermissions?.permissionState === 1)
          setStream(await navigator.mediaDevices.getUserMedia({ video: { deviceId: vidID } }));
        setLoading(false);
        setVidDisabled(false);
      } catch (e) {
        setVidDisabled(true);
        setLoading(false);
      }
    })();
    return () => {
      stream?.getVideoTracks().forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    };
  }, [vidID, vidDevices, vidOn, mediaPermissions?.permissionState]);

  const DropDownStyle = {
    dropDownHeader: {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontSize: '14px',
      lineHeight: '17px',
    },
    listOuter: {
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      border: '1px solid #000000',
      top: '38px',
      maxHeight: '120px',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    listItems: {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingBottom: '6px',
      paddingTop: '6px',
      fontSize: '14px',
      lineHeight: '17px',
    },
  };

  return (
    <>
      <div className="voice-video-settings-container">
        <div className="voice-video-settings-form-section">
          <div className="voice-video-settings-form-items">
            <div className="voice-video-settings-form-header">
              <div className="voice-video-settings-form-header-title">
                <span>Voice and video settings</span>
              </div>
              <div className="voice-video-settings-form-user-avatar-section">
                <div className="voice-video-settings-form-user-avatar">
                  {(!vidOn || vidDisabled) && <Avatar src={auth.userDetails.profilePic} name={auth.userDetails.name} />}
                  {vidOn && !vidDisabled && stream && <VideoPreview stream={stream} />}
                </div>
                <div className="voice-video-settings-form-control-icons">
                  <div
                    className="control-icon"
                    style={{ marginRight: '18px' }}
                    onClick={() => {
                      setVidOn(!vidOn);
                    }}
                  >
                    {vidOn && <CameraVideoFill />}
                    {!vidOn && <CameraVideoOffFill color={'#5097F3'} />}
                  </div>
                  <div
                    className="control-icon"
                    onClick={() => {
                      setAudOn(!audOn);
                    }}
                  >
                    {audOn && <MicFill />}
                    {!audOn && <MicMuteFill color={'#5097F3'} />}
                  </div>
                </div>
              </div>
            </div>

            <div className="voice-video-settings-field-item">
              <div className="voice-video-settings-field-label">
                <span>Camera</span>
              </div>
              <div className="voice-video-settings-field">
                <DropDown
                  headerTitle={vidID && vidDevices[vidID] ? vidDevices[vidID] : 'Select camera'}
                  styles={DropDownStyle}
                  options={vidDevices}
                  optionsListType="array"
                  isCamelcase={false}
                  isItemDict={true}
                  onSelectItem={(e: any, key: string) => {
                    // setAudioHeaderTitle(e.header);
                    localStorage.setItem('vidID', key);
                    setVidID(key);
                  }}
                />
              </div>
            </div>
            <div className="voice-video-settings-field-item">
              <div className="voice-video-settings-field-label">
                <span>Audio</span>
              </div>
              <div className="voice-video-settings-field">
                <DropDown
                  headerTitle={micID && audDevices[micID] ? audDevices[micID] : 'Select mic'}
                  styles={DropDownStyle}
                  options={audDevices}
                  optionsListType="array"
                  isCamelcase={false}
                  isItemDict={true}
                  onSelectItem={(e: any, key: string) => {
                    // setAudioHeaderTitle(e.header);
                    localStorage.setItem('micID', key);
                    setMicID(key);
                  }}
                />
              </div>
              {/* <div className="voice-feedback-bar"></div> */}
            </div>
          </div>

          {isValidBrowser && (
            <div className="voice-video-settings-form-action">
              <div
                className="voice-video-settings-form-button"
                onClick={() => {
                  setMediaPermissionsStore({
                    isVidDisabled: vidDisabled,
                    isMicDisabled: false,
                    isVidMute: !vidOn,
                    isMute: !audOn,
                    vidID,
                    micID,
                    permissionState: 2,
                  });
                  stream?.getVideoTracks().forEach((track) => {
                    track.enabled = false;
                    track.stop();
                  });
                }}
              >
                <span>Continue</span>
              </div>
            </div>
          )}
          {!isValidBrowser && (
            <div className="voice-video-settings-error-message">
              <span>
                {' '}
                Your browser is NOT supported. Please use Chrome 105.0+ from your Windows, Android, Mac or Linux
                devices.{' '}
              </span>
              <span> Please note: iOS devices (iPhone and iPad) are NOT currently supported.</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VoiceAndVideoSettings;
