import { useParams } from 'react-router-dom';
import './kicked.scss';

const KickedPage = () => {
  const { spaceID, roomID } = useParams<{ spaceID: string; roomID: string }>();
  return (
    <>
      <div className="errorInviteContainerKick">
        <div className="errorMessageInvite">
          You have been removed from the room by one of the host.
          {/* <div
            className="button"
            onClick={() => {
              window.location.href = `/s/${spaceID}/r/${roomID}`;
            }}
          >
            Go back to room
          </div> */}
        </div>
      </div>
    </>
  );
};

export default KickedPage;
