import axios from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'querystring';
import { config } from '../../config';
import { headers } from './spaces';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

class TawkFunction {
  async getHash(email: string) {
    return await axios.post(
      `${config.firebaseCloudURL}/createHashForTawk`,
      qs.stringify({
        email,
      }),
      headers
    );
  }
}

export default new TawkFunction();
