import { ChessInstance } from 'chess.js';
import React, { useEffect } from 'react';
import { canvasChild, firebaseCallback } from '../../../../types/canvasFB';
import './chess.scss';
import './chessboard-1.0.0.css';
let ChessReq: any = import('chess.js');

const ChessBoard = (props: {
  boardID: string;
  watcher: (callback: (c: canvasChild) => void) => firebaseCallback;
  onDataChange: (d: any, path: string) => void;
}) => {
  useEffect(() => {
    const game: ChessInstance = new ChessReq();
    function onDragStart(_s: any, piece: string, _p: any, _o: any) {
      // do not pick up pieces if the game is over
      if (game.game_over()) return false;

      // only pick up pieces for the side to move
      if ((game.turn() === 'w' && piece.search(/^b/) !== -1) || (game.turn() === 'b' && piece.search(/^w/) !== -1)) {
        return false;
      }
    }
    function onDrop(source: any, target: any) {
      // see if the move is legal
      var move = game.move({
        from: source,
        to: target,
        promotion: 'q', // NOTE: always promote to a queen for example simplicity
      });

      // illegal move
      if (move === null) return 'snapback';
    }
    function onSnapEnd() {
      // board.position(game.fen())
      props.onDataChange(game.fen(), 'fen');
    }
    var config = {
      draggable: true,
      position: 'start',
      pieceTheme: '/images/chesspieces/wikipedia/{piece}.png',
      onDragStart: onDragStart,
      onDrop: onDrop,
      onSnapEnd: onSnapEnd,
    };
    const board = window.Chessboard(props.boardID, config);
    const boardUI = document.getElementById(props.boardID);
    const unsub = props.watcher((c) => {
      setTimeout(board.resize, 200);
      if (c && c.d) {
        if (c.d.fen) {
          board.position(c.d.fen);
          game.load(c.d.fen);
        }
      }
    });
    return () => unsub();
  }, []);

  return <div id={props.boardID} className="chessboard"></div>;
};

export default ChessBoard;
