import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getRestrictedZonesRedux, getZonesVisibleRedux } from '../../../store/restrictedZones';
import { getSpacesRooms } from '../../../store/spacesRooms';
import NoGoZone from './noGoZones';

const NoGoZonesComp = (props: { spaceID: string; roomID: string }) => {
  const noGoZones = useSelector(getRestrictedZonesRedux);
  const spacesRooms = useSelector(getSpacesRooms);
  const zonesVisible = useSelector(getZonesVisibleRedux);

  return (
    <div className="nogoComp">
      {_.map(noGoZones, (noGoZone, id) => {
        if (noGoZone.visible === true || zonesVisible) {
          return (
            <div
              style={{
                width: noGoZone.width,
                height: noGoZone.height,
                position: 'absolute',
                transform: `translate(${noGoZone.x}px, ${noGoZone.y}px)`,
              }}
            >
              <NoGoZone
                showSettings={spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin ?? false}
                spaceID={props.spaceID}
                roomID={props.roomID}
                showFinaliseOptions={true}
                highlight={true}
                mainID={id}
                key={id}
                dimensions={noGoZone}
              />
            </div>
          );
        } else return <div key={id}></div>;
      })}
    </div>
  );
};

export default NoGoZonesComp;
