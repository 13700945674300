import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { getInitials } from '../../../helper/interactive/misc';
import { closeModalThunk, newModalThunk, openModalThunk, setDeviceSetting } from '../../../store';
import { getAuth } from '../../../store/auth';
import { getLocalState } from '../../../store/dailycoParticipants';
import { getDevice } from '../../../store/device';
import { getGetModalState } from '../../../store/modal';
import { getSpacesRooms } from '../../../store/spacesRooms';
import { ModalStyle } from '../../modal/modal';
import NoPermissionModal, { openNoPermission } from '../../noPermissionModal/noPermissionModal';
import './camSettings.scss';

export const camSettingID = 'cam-settings';
export const openCamSettings = openModalThunk(camSettingID);
export const closeCamSettings = closeModalThunk(camSettingID);

const CamSetting = (props: {
  joinCall: (
    camDisabled: boolean,
    micDisabled: boolean,
    camOn: boolean,
    micOn: boolean,
    cameraID: string | undefined,
    micID: string | undefined
  ) => void;
  roomID: string;
}) => {
  const state = useSelector(getGetModalState(camSettingID));
  const spacesRooms = useSelector(getSpacesRooms);
  const auth = useSelector(getAuth);
  const [vidDevices, setVidDevices] = useState<MediaDeviceInfo[]>([]);
  const [audDevices, setAudDevices] = useState<MediaDeviceInfo[]>([]);
  const [vid, setVid] = useState<MediaStream>();
  const [vidID, setVidID] = useState<string | undefined>(undefined);
  const [micID, setMicID] = useState<string | undefined>(undefined);
  const [videoOn, setVideoOn] = useState(true);
  const [audioOn, setAudioOn] = useState(true);
  const [loader, setLoader] = useState(false);
  const [vol, setVol] = useState(0);
  const vidIDref = useRef(vidID);
  const micIDref = useRef(micID);
  const audioOnref = useRef(audioOn);
  const videoOnref = useRef(videoOn);
  const [vidDisabled, setVidDisabled] = useState(false);
  const [audDisabled, setAudDisabled] = useState(false);
  const [warning, setWarning] = useState('');
  const [vidRoomPermission, setVidRoomPermission] = useState(true);
  const [micRoomPermission, setMicRoomPermission] = useState(true);
  const localDailyParticipant = useSelector(getLocalState);
  const attempted = useRef(false);
  const disabled = useRef(false);
  const vidRef = useRef(vid);

  const device = useSelector(getDevice);

  useEffect(() => {
    /**
     * effect for when the modal is open
     */
    if (state) {
      /**
       * if the modal is open read the camID and micID from the device store
       */
      if (device.deviceSetting.cameraID) {
        setVidID(device.deviceSetting.cameraID);
      }
      if (device.deviceSetting.micID) {
        setMicID(device.deviceSetting.micID);
      }
    }
  }, [state]);

  useEffect(() => {
    vidIDref.current = vidID;
    micIDref.current = micID;
    audioOnref.current = audioOn;
    videoOnref.current = videoOn;
    vidRef.current = vid;
  }, [micID, vidID, audioOn, videoOn]);

  useEffect(() => {
    setVidRoomPermission(spacesRooms.permissionV2[spacesRooms.currentUserRole]?.cam === true);
    setMicRoomPermission(spacesRooms.permissionV2[spacesRooms.currentUserRole]?.mic === true);
  }, [spacesRooms.permissionV2]);

  useEffect(() => {
    newModalThunk(camSettingID, false)();
  }, []);

  useEffect(() => {
    if (!state) {
      vid?.getTracks().forEach((track) => {
        track.enabled = false;
        track.stop();
      });
      vidRef.current?.getTracks().forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    } else {
      setLoader(false);
    }
  }, [state]);

  const selectVidID = (vidID: string) => {
    localStorage.setItem('vidID', vidID);
    setVidID(vidID);
  };

  const selectMicID = (micID: string) => {
    localStorage.setItem('micID', micID);
    setMicID(micID);
  };

  useEffect(() => {
    let frameNum: number;
    let stream = new MediaStream();
    if (!state) {
      stream.getTracks().forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    }
    (async () => {
      setLoader(false);
      function getCam() {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          setVidDevices([]);
          setAudDevices([]);
          for (const i in devices) {
            if (devices[i].kind === 'videoinput') {
              setVidID((vidID) => {
                if (!vidID) vidID = devices[i].deviceId;
                return vidID;
              });
              setVidDevices((vidDevices) => [...vidDevices, devices[i]]);
            }
            if (devices[i].kind === 'audioinput') {
              setMicID((micID) => {
                if (!micID) micID = devices[i].deviceId;
                return micID;
              });
              setAudDevices((audDevices) => [...audDevices, devices[i]]);
            }
          }
        });
      }
      const cameraAndMic = {
        audio: audioOnref.current ? { deviceId: micIDref.current } : false,
        video: videoOnref.current ? { deviceId: vidIDref.current } : false,
      };
      const micOnly = {
        audio: audioOnref.current ? { deviceId: micIDref.current } : false,
        video: false,
      };
      const cameraOnly = {
        audio: false,
        video: videoOnref.current ? { deviceId: vidIDref.current } : false,
      };
      const accessCamMic = async (level: MediaStreamConstraints) => {
        const flag = localStorage.getItem('vidID') && localStorage.getItem('micID');
        // @ts-ignore
        if (
          flag &&
          // @ts-ignore
          (level.audio === undefined || level.audio.deviceId === undefined) &&
          // @ts-ignore
          (level.video === undefined || level.video.deviceId === undefined)
        ) {
          return;
        }
        stream.getTracks().forEach((track) => {
          track.enabled = false;
          track.stop();
        });
        if (state)
          if (level.audio || level.video) {
            disabled.current = true;
            stream = await navigator.mediaDevices.getUserMedia(level);
            disabled.current = false;
            if (!state) {
              stream.getTracks().forEach((track) => {
                track.enabled = false;
                track.stop();
              });
            }
          }
        // window.AudioContext = window.AudioContext || window.webkitAudioContext;
        // if (stream.getAudioTracks().length > 0) {
        //   const audioContext = new AudioContext();
        //   const mediaStreamSource = audioContext.createMediaStreamSource(stream);
        //   const meter = window.createAudioMeter(audioContext);
        //   getCam();
        //   mediaStreamSource.connect(meter);
        //   const drawLoop = () => {
        //     setVol(meter.volume);
        //     frameNum = window.requestAnimationFrame(drawLoop);
        //   };
        //   drawLoop();
        // }
        // stream.getAudioTracks()[0]?.stop();
        // stream.getVideoTracks()[0]?.stop();
        getCam();
        setVid(stream);
      };
      const displayVideo = () => {
        let vidHTML: any = document.getElementById('preview');
        if (vidHTML) vidHTML.srcObject = stream;
      };
      try {
        await accessCamMic(cameraAndMic);
        setVidDisabled(false);
        setAudDisabled(false);
        displayVideo();
        setWarning('');
      } catch {
        try {
          getCam();
          await accessCamMic(micOnly);
          setVidDisabled(true);
          setAudDisabled(false);
          setWarning("Reslash doesn't have camera permissions or it's in use by another app.");
        } catch (e) {
          try {
            getCam();
            await accessCamMic(cameraOnly);
            setAudDisabled(true);
            setVidDisabled(false);
            displayVideo();
            setWarning("Reslash doesn't have mic permissions or it's in use by another app.");
          } catch (e) {
            setVidDisabled(true);
            setAudDisabled(true);
            setWarning("Reslash doesn't have mic and camera permissions. Click here to see how to fix it.");
          }
        }
      }
    })();
    return () => {
      const tracks = stream.getTracks();
      try {
        window.webkitCancelAnimationFrame(frameNum);
      } catch {}
      try {
        window.cancelAnimationFrame(frameNum);
      } catch {}
      tracks.forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    };
  }, [vidID, props.roomID, micID, audioOn, videoOn, state]);

  return (
    <>
      <ReactModal isOpen={state} id={camSettingID} contentLabel={`${camSettingID} Modal`} style={ModalStyle}>
        <>
          <div className="cam-setting">
            <div className={vid && vidRoomPermission && !vidDisabled && videoOn ? 'preview' : 'preview long'}>
              {vid && vidRoomPermission && !vidDisabled && videoOn ? (
                <video
                  id="preview"
                  className="circle-vid"
                  style={{
                    position: 'relative',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    width: '100%',
                    height: '100%',
                    transform: 'rotateY(180deg)',
                  }}
                  muted={true}
                  autoPlay
                  controls={false}
                ></video>
              ) : (
                <div className="videoPlaceholder" style={{ backgroundColor: '#FFAE42', position: 'relative' }}>
                  {getInitials(auth.user.name)}
                </div>
              )}
              {!localDailyParticipant.joined && (
                <div className="toolKit">
                  <div
                    className={
                      vidRoomPermission && !vidDisabled && videoOn
                        ? 'tool'
                        : vidRoomPermission
                        ? 'tool off'
                        : 'tool off has-tooltip-long'
                    }
                    data-tooltip-text={!vidRoomPermission ? 'Admin has disabled camera for guests.' : ''}
                    onClick={() => {
                      !loader && vidRoomPermission && !vidDisabled && setVideoOn(!videoOn);
                    }}
                  >
                    <span className="material-icons">
                      {vidRoomPermission && !vidDisabled && videoOn ? 'videocam' : 'videocam_off'}
                    </span>
                  </div>
                  <div
                    className={
                      micRoomPermission && audioOn && !audDisabled
                        ? 'tool'
                        : micRoomPermission
                        ? 'tool off'
                        : 'tool off has-tooltip-long'
                    }
                    data-tooltip-text={!micRoomPermission ? 'Admin has disabled mic for guests.' : ''}
                    onClick={() => {
                      !loader && micRoomPermission && !audDisabled && setAudioOn(!audioOn);
                    }}
                  >
                    <span className="material-icons">
                      {micRoomPermission && audioOn && !audDisabled ? 'mic' : 'mic_off'}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="tips">
            <span className="pro">Pro Tips</span> <br />
            • Scroll up and down to zoom in and out <br />• Drag your bubble to move around
          </div>
          <div className="select-cont">
            <span className="material-icons">videocam</span>
            <select className="select" value={vidID} onChange={(e) => selectVidID(e.target.value)}>
              {vidDevices.map((vidDevice) => {
                return (
                  <option key={vidDevice.deviceId} value={vidDevice.deviceId}>
                    {vidDevice.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="select-cont">
            <span className="material-icons">mic</span>
            <select className="select" value={micID} onChange={(e) => selectMicID(e.target.value)}>
              {audDevices.map((audDevice) => {
                return (
                  <option key={audDevice.deviceId} value={audDevice.deviceId}>
                    {audDevice.label}
                  </option>
                );
              })}
            </select>
          </div>
          {/* {micRoomPermission && (
            <div className="flexCenter volMeter">
              <span className="material-icons">{audioOn && !audDisabled ? 'mic' : 'mic_off'}</span>
              <progress className="volMeter" value={vol * 100} max="100"></progress>
            </div>
          )} */}
          {warning.length > 0 && (
            <div className="flexCenter">
              <span className="material-icons marginRight">warning</span>{' '}
              <div className="warning">
                <a href="#" onClick={openNoPermission}>
                  {warning}
                </a>
              </div>
            </div>
          )}
          <div className="flexCenter">
            {loader && <div className="loader"></div>}
            {!loader && !localDailyParticipant.joined && (
              <div
                className="button"
                onClick={() => {
                  setLoader(true);
                  props.joinCall(
                    vidDisabled,
                    audDisabled,
                    vidRoomPermission && !vidDisabled && videoOn,
                    micRoomPermission && !audDisabled && audioOn,
                    vidID,
                    micID
                  );
                }}
              >
                Join Room
              </div>
            )}
            {!loader && localDailyParticipant.joined && (
              <>
                <div
                  className="button-sec"
                  onClick={() => {
                    if (!disabled.current) closeCamSettings();
                  }}
                >
                  Cancel
                </div>
                <div
                  className="button"
                  onClick={() => {
                    if (disabled.current) return;
                    setLoader(true);
                    localDailyParticipant.changeMicCamera(vidID, micID).then(() => {
                      setDeviceSetting({ cameraID: vidID, micID });
                      /**
                       * On Change of mic and camera update the store
                       */
                      setLoader(false);
                      closeCamSettings();
                    });
                  }}
                >
                  Save
                </div>
              </>
            )}
          </div>
        </>
      </ReactModal>
      <NoPermissionModal />
    </>
  );
};

export default CamSetting;
