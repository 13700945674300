export const isEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const isURL = (url: string) => {
  if (url === '') return true;
  try {
    new URL(url);
  } catch (e) {
    // console.error(e);
    return false;
  }
  return true;
};

export const isTwitterURL = (url: string) => {
  try {
    new URL(url);
  } catch (e) {
    // console.error(e);
    return false;
  }

  const isTwitter = url.toLowerCase().includes('twitter');

  return true && isTwitter;
};

export const isLinkedInURL = (url: string) => {
  try {
    new URL(url);
  } catch (e) {
    // console.error(e);
    return false;
  }

  const isLinkedIn = url.toLowerCase().includes('linkedin');

  return true && isLinkedIn;
};

export const isFacebookURL = (url: string) => {
  try {
    new URL(url);
  } catch (e) {
    // console.error(e);
    return false;
  }

  const isFacebook = url.toLowerCase().includes('facebook');

  return true && isFacebook;
};

export const isPhoneNumber = (phone: string) => {
  var re = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/;
  return re.test(phone);
};
