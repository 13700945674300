import _ from 'lodash';
import React, { useState } from 'react';
import { openCrowdManagementModal } from '../../components/crowdManagemetModal/crowdManagementModal';
import DropDown from '../../components/dropdown/dropdown';
import './crowdManagement.scss';

export const Switcher = (props: {
  options: {
    item: string;
    value: string;
  }[];
  itemStyle?: React.CSSProperties;
  selectedItem: (optionType: string) => void;
}) => {
  const [switcher, selectOption] = useState(props.options[0].value);

  const classSelector = (option: { item: string; value: string }) => {
    let classString = 'switcher-item';

    if (switcher === option.value) classString = classString + ' selected';
    if (option.value === props.options[0].value) classString = classString + ' left';
    if (option.value === props.options[props.options.length - 1].value) classString = classString + ' right';

    return classString;
  };

  return (
    <>
      <div className="switcher">
        {_.map(props.options, function (option) {
          return (
            <div
              className={classSelector(option)}
              key={option ? option.value : ''}
              style={props.itemStyle}
              onClick={() => {
                selectOption(option.value);
                props.selectedItem(option.value);
              }}
            >
              {option.item}
            </div>
          );
        })}
      </div>
    </>
  );
};

const CrowdManagement = (props: { spaceID: string }) => {
  const DropDownStyle = {
    dropDownHeader: {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontSize: '16px',
    },
    listOuter: {
      backgroundColor: 'rgba(55, 57, 62, 1)',
      borderRadius: '12px',
      border: '1px solid #000000',
      top: '52px',
      maxHeight: '300px',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    listItems: {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingBottom: '6px',
      paddingTop: '6px',
      fontSize: '16px',
    },
  };

  const rules = [
    'Move everyone in the room',
    'Move users with specific roles in the room',
    'Move specific users in the room',
  ];
  const [ruleType, setRuleType] = useState(rules[0]);

  const ruleTypeOptions = [
    { item: 'LIVE', value: 'live' },
    { item: 'ARCHIVED', value: 'archived' },
  ];

  return (
    <>
      <div className="setting-header-label">
        <span>Crowd management rules</span>
        <div className="crowd-management-section">
          <span>
            You can limit access of a crowd to a specific room or rooms using crowd management rules. Right now, you can
            upload a CSV of email ids and the rooms you want them to have access to.
            <br /> <br />
            If you run multiple rules at the same time, the rule on the top will get priority. You can change this.
          </span>

          <div className="add-rule-section">
            <div className="section-header">SELECT A RULE TO APPLY</div>
            <div className="rule-section-box">
              <div className="rules-drop-down">
                <DropDown
                  headerTitle={ruleType}
                  styles={DropDownStyle}
                  options={rules}
                  optionsListType="array"
                  onSelectItem={(e: any) => {
                    setRuleType(e.header);
                  }}
                />
              </div>
              <div
                className="add-rule-button"
                onClick={() => {
                  openCrowdManagementModal(
                    ruleType,
                    () => {},
                    () => {}
                  )();
                }}
              >
                <span>Add rule</span>
              </div>
            </div>
          </div>

          <div className="rules-section-container">
            <div className="rules-section-header">
              <div className="section-header" style={{ display: 'flex', alignSelf: 'center', paddingBottom: '0px' }}>
                RULES
              </div>
              <div className="rule-switcher">
                <Switcher
                  options={ruleTypeOptions}
                  itemStyle={{ padding: '8px 12px' }}
                  selectedItem={(e) => {
                    console.log('Selected switcher option', e);
                  }}
                />
              </div>
            </div>
            <div className="rules-container"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrowdManagement;
