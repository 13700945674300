import React, { useRef, useState } from 'react';
import { EnvelopeFill, Facebook, Google, Linkedin, Twitter } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { getFrontendURL, handleSubdomain } from '../../helper/interactive/misc';
import {
  emailLink,
  facebookLink,
  googleCalendarLink,
  linkedinLink,
  office365CalendarLink,
  outlookCalendarLink,
  twitterLink,
} from '../../helper/interactive/socialShare';
import { getSpacesRooms } from '../../store/spacesRooms';
import { useOutsideEvent } from '../interactive/giphy/Giphy';
import './shareWidget.scss';

const ShareWidget = (props: { onClose: () => void }) => {
  const spacesRooms = useSelector(getSpacesRooms);
  const [isCopied, setIsCopied] = useState(false);
  const roomLink = `${getFrontendURL()}${handleSubdomain(
    `/s/${spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID}/r/${spacesRooms.currentRoom}`
  )}`;

  const shareRef = useRef<HTMLDivElement | null>(null);

  useOutsideEvent(shareRef, props.onClose, 'share-container', '#share-container');
  return (
    <>
      <div className="share-widget-container" ref={shareRef}>
        <div className="invite-to-room-link">
          <div
            className="copy-link-button"
            onClick={() => {
              navigator.clipboard.writeText(roomLink).then(() => {
                setIsCopied(true);
                setTimeout(() => setIsCopied(false), 2000);
              });
            }}
          >
            <span> {isCopied ? 'Copied!' : 'Copy room invite link'} </span>
          </div>
        </div>

        <div className="schedule-calendar-section">
          <label>SCHEDULE MEETING</label>
          <div className="schedule-calendar-options">
            <div
              className="option has-v2-tooltip-small"
              data-tooltip-text="Google"
              onClick={() => {
                window.open(
                  googleCalendarLink(roomLink, {
                    title: "Let's meet on Reslash!\n",
                    description:
                      'Join me at this link: \n' +
                      roomLink +
                      '\n\nFirst time on Reslash? Check out how it works here: https://www.youtube.com/watch?v=bNlim_QOGsg',
                    location: roomLink,
                  }),
                  '_blank'
                );
              }}
            >
              <Google />
            </div>

            <div
              className="option has-v2-tooltip-small"
              data-tooltip-text="Outlook"
              onClick={() => {
                window.open(
                  outlookCalendarLink(roomLink, {
                    title: "Let's meet on Reslash!\n",
                    description:
                      'Join me at this link: \n' +
                      roomLink +
                      '\n\nFirst time on Reslash? Check out how it works here: https://www.youtube.com/watch?v=bNlim_QOGsg',
                    location: roomLink,
                  }),
                  '_blank'
                );
              }}
            >
              <img
                src="https://s3.us-west-1.wasabisys.com/reslashco-assets/outlook.png"
                style={{ width: '18px', height: '18px', border: 'none' }}
              />
            </div>

            <div
              className="option has-v2-tooltip-small"
              data-tooltip-text="Office365"
              onClick={() => {
                window.open(
                  office365CalendarLink(roomLink, {
                    title: "Let's meet on Reslash!\n",
                    description:
                      'Join me at this link: \n' +
                      roomLink +
                      '\n\nFirst time on Reslash? Check out how it works here: https://www.youtube.com/watch?v=bNlim_QOGsg',
                    location: roomLink,
                  }),
                  '_blank'
                );
              }}
            >
              <img
                src="https://s3.us-west-1.wasabisys.com/reslashco-assets/office360.png"
                style={{ width: '18px', height: '18px', border: 'none' }}
              />
            </div>
          </div>
        </div>
        <div className="social-share-section">
          <label>SOCIAL SHARE</label>
          <div className="social-share-options">
            <div
              className="option has-v2-tooltip-small"
              data-tooltip-text="Twitter"
              onClick={() => {
                window.open(
                  twitterLink(roomLink, {
                    title: 'Hey, I built a new space on Reslash (@reslashapp)! Check it out here:\n',
                    hashtags: ['\n', 'reslash', 'virtualhq'],
                  }),
                  '_blank'
                );
              }}
            >
              <Twitter size={18} />
            </div>
            <div
              className="option has-v2-tooltip-small"
              data-tooltip-text="Facebook"
              onClick={() => {
                window.open(
                  facebookLink(roomLink, {
                    quote: 'Hey, I built a new space on Reslash! Check it out here: \n',
                    hashtag: 'reslash',
                  }),
                  '_blank'
                );
              }}
            >
              <Facebook size={18} />
            </div>
            <div
              className="option has-v2-tooltip-small"
              data-tooltip-text="LinkedIn"
              onClick={() => {
                window.open(linkedinLink(roomLink), '_blank');
              }}
            >
              <Linkedin size={18} />
            </div>
            <div
              className="option email has-v2-tooltip-small"
              data-tooltip-text="Email"
              onClick={() => {
                window.open(
                  emailLink(roomLink, {
                    subject: 'Checkout my new Reslash room!',
                    body: 'Hey! \n\n I built a new space on Reslash! Check it out here:\n',
                    separator: '\n',
                    footer:
                      "\n\nHere's how Reslash works: https://www.youtube.com/watch?v=bNlim_QOGsg \n\nCan't wait to see you there!\n\nThanks,",
                  }),
                  '_blank'
                );
              }}
            >
              <EnvelopeFill size={18} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareWidget;
