import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../../store';
import { getGetModalState } from '../../../store/modal';
import Modal from '../components/modal';
import ModalBody from '../components/modalBody';
import ModalFooter from '../components/modalFooter';
import ModalHeader from '../components/modalHeader';

export const profileEditModal = 'profile-edit-modal';
export const openProfileEditModal = openModalThunk(profileEditModal);
export const closeProfileEditModal = closeModalThunk(profileEditModal);

const ProfileModal = (props: any) => {
  const state = useSelector(getGetModalState(profileEditModal));

  useEffect(() => {
    if (props.isOpen) {
      openProfileEditModal();
    } else {
      closeProfileEditModal();
    }
  }, [props.isOpen]);

  useEffect(() => {
    newModalThunk(profileEditModal, false)();
  }, []);

  return (
    <Modal isOpen={state}>
      <ModalHeader
        showBack={props.showBack}
        header={props.header}
        onClose={() => {
          props.onClose(false);
        }}
        onBack={props.setBackStep}
      ></ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter
        footerActionButtonLeft={props.footerActionButtonLeft ? props.footerActionButtonLeft : 'Cancel'}
        onActionLeft={() => {
          props.onClose(false);
        }}
        footerActionButtonRight={props.footerActionButtonRight}
        onActionRight={props.setActionRight}
        buttonSize="112px"
      ></ModalFooter>
    </Modal>
  );
};

export default ProfileModal;
