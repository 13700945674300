import { DailyParticipant } from '@daily-co/daily-js';
import { combineReducers, createStore } from '@reduxjs/toolkit';
import _ from 'lodash';
import { restrictedZonesType, roomPermissions } from '../types/canvasFB';
import { audioZones, audioZoneSlice } from './audiozones';
import { authSlice } from './auth';
import { broadcastSlice } from './broadcast';
import { ChatMessage, chatSlice } from './chat';
import { createRoomFlowSlice } from './createRoomFlow';
import { claims, customClaimSlice } from './customClaim';
import { dailyParticipantsSlice, JoinNowFunc, localParticipantDailyco, mediaPermissions } from './dailycoParticipants';
import { DeviceSettingAction, deviceSlice } from './device';
import { dragResizeSlice } from './dragResizeState';
import { hideMenuSlice } from './hideMenu';
import { html2StreamSlice } from './html2stream';
import { inactivitySlice } from './inactivity';
import { modalSlice } from './modal';
import { overflowStreamSlice } from './overflowStream';
import { presenceRoom, presenceSlice } from './presence';
import { reactionsSlice } from './reactions';
import { recordingSlice } from './recording';
import { restrictedZoneSlice } from './restrictedZones';
import { screen, screenshareSlice } from './screenshares';
import { settingsMenuSlice } from './settingsMenu';
import { permissionV2, roomType, spacesRoomsSlice, spaceType, userRole, userRoleType } from './spacesRooms';
import { spatialAudioSlice } from './spatialAudio';
import { newBGType, screenWallV2, wallpaperSlice } from './wallpaper';
import { zoomSlice } from './zoom';

export const reducers = combineReducers({
  auth: authSlice.reducer,
  zoom: zoomSlice.reducer,
  modal: modalSlice.reducer,
  dragResize: dragResizeSlice.reducer,
  spatialAudio: spatialAudioSlice.reducer,
  spacesRooms: spacesRoomsSlice.reducer,
  html2stream: html2StreamSlice.reducer,
  screenshare: screenshareSlice.reducer,
  dailyParticipants: dailyParticipantsSlice.reducer,
  audioZones: audioZoneSlice.reducer,
  device: deviceSlice.reducer,
  settingsMenu: settingsMenuSlice.reducer,
  presence: presenceSlice.reducer,
  chat: chatSlice.reducer,
  customClaim: customClaimSlice.reducer,
  wallpaper: wallpaperSlice.reducer,
  broadcast: broadcastSlice.reducer,
  inactivity: inactivitySlice.reducer,
  recording: recordingSlice.reducer,
  overflowStream: overflowStreamSlice.reducer,
  restrictedZones: restrictedZoneSlice.reducer,
  hideMenu: hideMenuSlice.reducer,
  createRoomFlow: createRoomFlowSlice.reducer,
  reactions: reactionsSlice.reducer,
});

const mainStore = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// @ts-ignore
window.mainStore = mainStore;

export default mainStore;

/**
 *
 * Todo: Clean this actions file
 */

//zoom helper
export const getZoomFromStore = () => mainStore.getState().zoom.level;
export const setZoomStore = (zoom: number) => mainStore.dispatch(zoomSlice.actions.setZoom(zoom));

// action helpers
export const getUpdateSource = (id: string) => (payload: any) =>
  mainStore.dispatch(spatialAudioSlice.actions.updateSource({ ...payload, id, isListener: false }));
export const getUpdateListener = () => (payload: any) =>
  mainStore.dispatch(spatialAudioSlice.actions.updateSource({ ...payload, isListener: true }));
export const getAddSource = (id: string) => (payload: any) =>
  mainStore.dispatch(spatialAudioSlice.actions.addSource({ ...payload, id }));
export const getAddListener = () => (payload: any) =>
  mainStore.dispatch(spatialAudioSlice.actions.addListener({ ...payload }));

// Modal helpers
export const newModalThunk = (id: string, isOpen: boolean) => () =>
  mainStore.dispatch(modalSlice.actions.newModal({ id, isOpen }));
export const openModalThunk = (id: string) => () => mainStore.dispatch(modalSlice.actions.openModal(id));
export const closeModalThunk = (id: string) => () => mainStore.dispatch(modalSlice.actions.closeModal(id));
export const newNotificationThunk = (id: string, isOpen: boolean) => () => {
  mainStore.dispatch(modalSlice.actions.newNotification({ id, isOpen }));
};
export const showNotificationThunk = (id: string) => () => mainStore.dispatch(modalSlice.actions.showNotification(id));
export const hideNotificationThunk = (id: string) => () => mainStore.dispatch(modalSlice.actions.hideNotification(id));

// SpacesRooms Helper
export const setRooms = (rooms: { [id: string]: roomType | undefined }) =>
  mainStore.dispatch(spacesRoomsSlice.actions.addRooms({ rooms }));
export const deleteRoom = (roomID: string) => mainStore.dispatch(spacesRoomsSlice.actions.deleteRoom({ roomID }));
export const setSpaces = (spaces: { [id: string]: spaceType | undefined }) =>
  mainStore.dispatch(spacesRoomsSlice.actions.addSpaces({ spaces }));
export const setKickedOutOnEndStore = (flag: boolean) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setKickedOutOnEnd(flag));
export const setOneSpace = (spaceID: string, space: spaceType) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setOneSpace({ spaceID, space }));
export const getCurrentRoomFromStore = () => mainStore.getState().spacesRooms.currentRoom;
export const getRoomsFromStore = () => mainStore.getState().spacesRooms.rooms;
export const getSpacesRoomsState = () => mainStore.getState().spacesRooms;
export const getRoomPermissionState = () => mainStore.getState().spacesRooms.currentRoomPermission;
export const setCurrentRoom = (roomID: string, password?: string) => {
  mainStore.dispatch(spacesRoomsSlice.actions.setCurrentRoom({ roomID, password }));
};
export const setCurrentSpace = (spaceID: string) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setCurrentSpace({ spaceID }));
export const getCurrentSpace = () => mainStore.getState().spacesRooms.currentSpace;
export const getCurrentRoom = () => mainStore.getState().spacesRooms.currentRoom;
export const setSpaceLoading = (flag: boolean) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setSpacesLoading({ flag }));
export const setRoomCopyLoading = (flag: boolean) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setRoomCopyLoading({ flag }));
export const setRoomLoading = (flag: boolean) => mainStore.dispatch(spacesRoomsSlice.actions.setRoomsLoading({ flag }));
export const setRoomPermission = (permission: roomPermissions) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setCurrentRoomPermission({ permission }));
export const setRoomPermissionV2 = (type: userRoleType, permissions: permissionV2) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setPermissionV2({ type, permissions }));
export const setCurrentUserRoleV2 = (type: userRoleType) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setCurrentUserRole(type));
export const setCurrentRoomUserRoles = (type: userRoleType, users: userRole) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setCurrentRoomUserRoles({ type, users }));
export const setSpaceClaimLoading = (flag: boolean) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setSpaceClaimLoading({ flag }));
export const setRoomsClaimLoading = (flag: boolean) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setRoomsClaimLoading({ flag }));
export const setCurrentWall = (url: string) => mainStore.dispatch(wallpaperSlice.actions.setWallpaper(url));
export const setCurrentWallV2 = (wallObj: newBGType | null) =>
  mainStore.dispatch(wallpaperSlice.actions.setWallpaperV2(wallObj));
export const setCurrentScreenWall = (id: string) => mainStore.dispatch(wallpaperSlice.actions.setScreenWall(id));
export const setIsScreenWall = (flag: boolean) => mainStore.dispatch(wallpaperSlice.actions.setIsScreenWall(flag));
export const setCurrentScreenWallV2 = (screenWallV2: screenWallV2 | undefined) =>
  mainStore.dispatch(wallpaperSlice.actions.setScreenWallV2(screenWallV2));
export const setCurrentScreenVol = (vol: number) => mainStore.dispatch(wallpaperSlice.actions.setScreenVol(vol));
export const setUploadProgressWall = (progress: number) =>
  mainStore.dispatch(wallpaperSlice.actions.setUploadProgress(progress));
export const setIsBackgroundLoadingState = (isLoading: boolean) =>
  mainStore.dispatch(wallpaperSlice.actions.setIsBackgroundLoading(isLoading));
export const getCurrentScreenWall = () => mainStore.getState().wallpaper.screenWall;
export const getCurrentRoomPermissionFromStore = () => {
  const state = mainStore.getState().spacesRooms;
  return {
    permission: state.rooms[state.currentRoom]?.permission,
    role: state.currentUserRole,
  };
};
export const setCurrentRoomCopy = (copy: number) => {
  mainStore.dispatch(spacesRoomsSlice.actions.setCurrentRoomCopy(copy));
};
export const getCurrentRoomCopy = () => mainStore.getState().spacesRooms.currentRoomCopy;
export const setCurrentRoomPassword = (password?: string) =>
  mainStore.dispatch(spacesRoomsSlice.actions.setCurrentRoomPassword(password));
export const getCurrentRoomPasswordStore = () => mainStore.getState().spacesRooms.currentRoomPassword;

//CustomClaim
export const setCustomClaimStore = (tokenClaim: claims) =>
  mainStore.dispatch(customClaimSlice.actions.setCustomClaim(tokenClaim));
export const getCustomClaimStore = () => mainStore.getState().customClaim;

//html2stream
type numUn = number | undefined;
export const addH2SSource = (id: string) => mainStore.dispatch(html2StreamSlice.actions.addSource({ id }));
export const updateH2SSource = (id: string, x: numUn, y: numUn, z: numUn, w: numUn, h: numUn) =>
  mainStore.dispatch(html2StreamSlice.actions.updateSource({ id, x, y, z, w, h }));
export const deleteH2SSource = (id: string) => mainStore.dispatch(html2StreamSlice.actions.removeSource({ id }));

//spatialAudio
export const getSpatialAudio = () => mainStore.getState().spatialAudio;
export const getListenerPosition = () => mainStore.getState().spatialAudio.listener.position;
export const setSpatialAudio = (payload: { id: string; volume: number }) =>
  mainStore.dispatch(spatialAudioSlice.actions.updateSource(payload));
//auth
export const getAuthState = () => mainStore.getState().auth;
export const setUserStore = (user: { id: string; name: string; email: string; token: string; isGuest: boolean }) =>
  mainStore.dispatch(authSlice.actions.login(user));
export const setAuthClaim = (claims: any) => mainStore.dispatch(authSlice.actions.setClaims({ claims }));
export const setUserDetails = (details: {
  name: string;
  username: string;
  email: string;
  profilePic: string;
  desc: string;
  status: string;
  socialProfiles?:
    | {
        twitter: string | undefined;
        facebook: string | undefined;
        linkedin: string | undefined;
        phone: string | undefined;
        email: string | undefined;
        link: string | undefined;
      }
    | undefined;
}) => mainStore.dispatch(authSlice.actions.setUserDetails(details));
export const setLoadingAuthStore = (flag: boolean) => mainStore.dispatch(authSlice.actions.setLoading(flag));
export const setRedirectToStore = (path: string) => mainStore.dispatch(authSlice.actions.setRedirectTo(path));
//screenshare
export const addScreen = (id: string) => mainStore.dispatch(screenshareSlice.actions.addScreen({ id }));
export const updateScreen = (id: string, screen: screen) => {
  mainStore.dispatch(
    screenshareSlice.actions.updateScreen({
      id,
      position: screen.position,
      shape: screen.shape,
      paused: screen.paused,
      sessionID: screen.sessionID,
    })
  );
};
export const updateCanvas = (canvas: { position: { x: number; y: number }; shape: { w: number; h: number } }) => {
  mainStore.dispatch(screenshareSlice.actions.updateCanvas({ canvas }));
};
export const removeScreen = (id: string) => mainStore.dispatch(screenshareSlice.actions.removeScreen({ id }));
export const getNumScreenshares = () => _.keys(mainStore.getState().screenshare.screens).length;
export const setLoadingInStore = (id: string, flag: boolean) =>
  mainStore.dispatch(screenshareSlice.actions.setLoading({ id, flag }));
export const getLoadingState = (id: string) => mainStore.getState().screenshare.screens[id]?.loading;
export const getScreensharesFromStore = () => mainStore.getState().screenshare;

//dailycoParticipants
export const addLocalDailyco = (participant: localParticipantDailyco) =>
  mainStore.dispatch(dailyParticipantsSlice.actions.addLocal(participant));
export const addRemoteDailyco = (id: string, participant: DailyParticipant) =>
  mainStore.dispatch(dailyParticipantsSlice.actions.addRemote({ id, participant }));
export const updateRemoteDailyco = (participants: { [id: string]: DailyParticipant }) =>
  mainStore.dispatch(dailyParticipantsSlice.actions.updateRemote(participants));
export const removeRemoteDailyco = (id: string) =>
  mainStore.dispatch(dailyParticipantsSlice.actions.removeRemote({ id }));
export const updateLocalScreenDailyco = (flag: boolean) =>
  mainStore.dispatch(dailyParticipantsSlice.actions.updateScreenshare({ flag }));
export const updateDailycoLocalMute = (mic: boolean, flag: boolean) =>
  mainStore.dispatch(dailyParticipantsSlice.actions.updateLocalMute({ mic, flag }));
export const getRemoteParticipantsDailyco = () => mainStore.getState().dailyParticipants.remote;
export const getRemoteParticipantFromDailyco = (id: string) => mainStore.getState().dailyParticipants.remote[id];
export const getLocalParticipantStateFromStoreDailyco = () => mainStore.getState().dailyParticipants.local.participant;
export const getLocalParticipantFromStoreDailyco = () => mainStore.getState().dailyParticipants.local;
export const getRemoteParticipantDailyco = (id: string) => (state: ReturnType<typeof mainStore.getState>) =>
  state.dailyParticipants.remote[id];
export const changeJoinStatus = (joined: boolean) =>
  mainStore.dispatch(dailyParticipantsSlice.actions.updateJoined(joined));
export const getJoinStatus = (state: ReturnType<typeof mainStore.getState>) => state.dailyParticipants.local.joined;
export const getJoinStatusFromStore = () => mainStore.getState().dailyParticipants.local.joined;
export const setDeviceSetting = (deviceSetting: DeviceSettingAction) =>
  mainStore.dispatch(deviceSlice.actions.setDeviceSetting(deviceSetting));
export const removeAllParticipantsDaily = () =>
  mainStore.dispatch(dailyParticipantsSlice.actions.removeAllParticipants({}));
export const setStreamForLocalDailyStore = (video: MediaStreamTrack | null, audio: MediaStreamTrack | null) => {
  mainStore.dispatch(dailyParticipantsSlice.actions.setLocalStream({ video, audio }));
};
export const setLocalStreamIDDailyStore = (videoID: string | undefined, micID: string | undefined) => {
  mainStore.dispatch(dailyParticipantsSlice.actions.setLocalStreamID({ micID, videoID }));
};
export const getStreamForLocalDailyStore = () => mainStore.getState().dailyParticipants.local.stream;
export const setDailyScreenSharingStore = (flag: boolean) =>
  mainStore.dispatch(dailyParticipantsSlice.actions.updateScreenshare({ flag }));
export const setJoinNowStore = (joinNow: JoinNowFunc) =>
  mainStore.dispatch(dailyParticipantsSlice.actions.setJoinFunc(joinNow));
export const setMediaPermissionsStore = (mediaPermissions?: mediaPermissions) =>
  mainStore.dispatch(dailyParticipantsSlice.actions.setMediaPermissions(mediaPermissions));

//audioZones
export const setAudioZones = (audioZones: audioZones) =>
  mainStore.dispatch(audioZoneSlice.actions.setAudioZones(audioZones));
export const getAudioZonesFromStore = () => mainStore.getState().audioZones;
export const getUserAudioZoneFromStore = () => mainStore.getState().audioZones.myZone;
export const setUserAudioZoneForOther = (uid: string, zid: string) =>
  mainStore.dispatch(audioZoneSlice.actions.setUserZone({ uid, zid }));

//sidebar
export const toggleSidebarStore = (flag: boolean) => mainStore.dispatch(settingsMenuSlice.actions.toggleSidebar(flag));
export const toggleRoomSettingsMenuStore = (flag: boolean) =>
  mainStore.dispatch(settingsMenuSlice.actions.toggleRoomSettings(flag));
export const togglePermissionMenuStore = (flag: boolean) =>
  mainStore.dispatch(settingsMenuSlice.actions.togglePermissions(flag));
export const toggleMembersMenuStore = (flag: boolean) =>
  mainStore.dispatch(settingsMenuSlice.actions.toggleMembers(flag));
export const setHighlightedRoomStore = (roomID: string) =>
  mainStore.dispatch(settingsMenuSlice.actions.setHighLightRoom(roomID));
export const setRoomCreateOrEditStore = (flag: 'create' | 'create-dynamic' | 'edit') =>
  mainStore.dispatch(settingsMenuSlice.actions.setRoomCreationOrEdit(flag));
export const toggleRoomDetailsMenuStore = (flag: boolean) =>
  mainStore.dispatch(settingsMenuSlice.actions.toggleRoomDetailsMenu(flag));
export const toggleRoomWallMenuStore = (flag: boolean) =>
  mainStore.dispatch(settingsMenuSlice.actions.toggleRoomWallMenu(flag));
export const toggleChatOpenStore = (flag: boolean) => mainStore.dispatch(settingsMenuSlice.actions.toggleChat(flag));
export const getChatOpenStore = () => {
  if (!mainStore.getState().settingsMenu.sidebarOpen) return false;
  else return mainStore.getState().settingsMenu.chatOpen;
};
export const toggleBackgroundMenuOpenStore = (flag: boolean) =>
  mainStore.dispatch(settingsMenuSlice.actions.toggleBackground(flag));
export const getBackgroundMenuOpenStore = () => {
  if (!mainStore.getState().settingsMenu.sidebarOpen) return false;
  else return mainStore.getState().settingsMenu.backgroundMenuOpen;
};

//Presence
export const updatePresenceOfRoomStore = (roomID: string, presence: { [roomCopy: string]: presenceRoom }) => {
  mainStore.dispatch(presenceSlice.actions.updateRoomPresence({ roomID, presence }));
};
export const updatePresenceOfUsersRoomStore = (roomID: string, userID: string, roomCopy: string, flag: boolean) => {
  mainStore.dispatch(presenceSlice.actions.updateUsersRoomPresence({ roomID, userID, roomCopy, flag }));
};
export const getPresenceOfRoomStore = (roomID: string) => {
  return mainStore.getState().presence[roomID];
};

//Chat
export const setChatMessagesStore = (messages: ChatMessage[]) =>
  mainStore.dispatch(chatSlice.actions.setMessages(messages));
export const setUnreadChatMessageCountStore = (count: number) => mainStore.dispatch(chatSlice.actions.setUnread(count));
export const getUnreadChatCountStore = () => mainStore.getState().chat.unread;

//Broadcast
export const setBroadcasterDetailsStore = (details: {
  uid: string;
  name: string;
  profilePic: string;
  listeners: number;
  isLive: boolean;
}) => mainStore.dispatch(broadcastSlice.actions.setBroadcaster(details));
// export const setBroadcasterVideoStore = (stream: MediaStreamTrack | null) =>
//   mainStore.dispatch(broadcastSlice.actions.setBroadcasterVideo(stream));
// export const setBroadcasterAudioStore = (stream: MediaStreamTrack | null) =>
//   mainStore.dispatch(broadcastSlice.actions.setBroadcasterAudio(stream));
export const setBroadcasterMediaTrackStore = (audio: MediaStreamTrack | null, video: MediaStreamTrack | null) =>
  mainStore.dispatch(broadcastSlice.actions.setBroadcasterMediaTrack({ audio, video }));
export const setTokenForBroadcastStore = (token: string | null) =>
  mainStore.dispatch(broadcastSlice.actions.setTokenForBroadcast(token));
export const getTokenForBroadcastStore = () => mainStore.getState().broadcast.token;
export const setBroadcasterStatusStore = (flag: boolean) =>
  mainStore.dispatch(broadcastSlice.actions.setBroadcastStatus(flag));
export const setBroadcasterFuncStore = (broadcasterFunc: {
  isLocal: boolean;
  toMute: (flag: boolean) => void;
  toVidMute: (flag: boolean) => void;
  isMuted: boolean;
  isVidMuted: boolean;
}) => mainStore.dispatch(broadcastSlice.actions.setBroadcasterFunc(broadcasterFunc));
export const setBroadcasterMuteStore = (mic: boolean, flag: boolean) =>
  mainStore.dispatch(broadcastSlice.actions.setBroadcasterMute({ mic, flag }));

//inactivity
export const setActivityTimestampStore = (timestamp: number) => {
  mainStore.dispatch(inactivitySlice.actions.setLastActivityStamp(timestamp));
};

//recording
export const getIsRecordingStore = () => {
  return mainStore.getState().recording.isRecording;
};
export const setIsRecordingStore = (flag: boolean) => {
  mainStore.dispatch(recordingSlice.actions.setIsRecording(flag));
};
export const getIsRecordingState = () => {
  return mainStore.getState().recording;
};

//reactions
export const getReactionStore = () => {
  return mainStore.getState().reactions.reaction;
};
export const setReactionStore = (
  reaction:
    | { type: 'text' | 'sticker' | undefined; payload: { text?: string; url?: string }; sentTime?: number }
    | undefined
) => {
  mainStore.dispatch(reactionsSlice.actions.setReaction(reaction));
};
export const getReactionState = () => {
  return mainStore.getState().reactions;
};

//overflowStream
export const setOverflowStreamBroadcasterID = (broadcasterID: string) =>
  mainStore.dispatch(overflowStreamSlice.actions.setBroadcasterID(broadcasterID));

export const setOverflowStreamRoomID = (roomID: string) =>
  mainStore.dispatch(overflowStreamSlice.actions.setRoomID(roomID));

export const setOverflowStreamIsLive = (isLive: boolean) =>
  mainStore.dispatch(overflowStreamSlice.actions.setIsLive(isLive));

export const setOverflowStreamLoading = (flag: boolean) =>
  mainStore.dispatch(overflowStreamSlice.actions.setLoading(flag));

//restricted Zones
export const setRestrictedZonesStore = (restrictedZones: restrictedZonesType) =>
  mainStore.dispatch(restrictedZoneSlice.actions.setRestrictedZones(restrictedZones));
export const setRestrictedZoneVisibleStore = (id: string, visible: boolean) =>
  mainStore.dispatch(restrictedZoneSlice.actions.setRestrictedZoneVisible({ id, visible }));
export const setZonesVisibleStore = (flag: boolean) =>
  mainStore.dispatch(restrictedZoneSlice.actions.setZonesVisible(flag));

//hide all menus - to be used for global in future
export const setHideMenuStore = (flag: boolean) => mainStore.dispatch(hideMenuSlice.actions.setHideMenuState(flag));

//Create Room Flow
export const setCreateRoomStepStore = (step: 'none' | 'create' | 'background') =>
  mainStore.dispatch(createRoomFlowSlice.actions.setStep(step));
export const setCreateAdvanceParamsStore = (params: {
  roomType: 'spatial' | 'dynamic';
  password: string;
  spillOverAt: number;
  limit: number;
  startTime: number;
  endTime: number;
  timeZone: {
    name: string;
    offset: number;
  };
}) => mainStore.dispatch(createRoomFlowSlice.actions.setAdvanceParams(params));
export const setCreateRoomNameStore = (name: string) => mainStore.dispatch(createRoomFlowSlice.actions.setName(name));
export const setCreateRoomTypeStore = (type: 'public' | 'team' | 'private') =>
  mainStore.dispatch(createRoomFlowSlice.actions.setType(type));
export const setCreateRoomEmailsStore = (emails: string[]) =>
  mainStore.dispatch(createRoomFlowSlice.actions.setEmails(emails));
