import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//@ts-ignore
import UrlSafeString from 'url-safe-string';
import { sendAmplitudeData } from '../../helper/amplitude';
import { getClaimToken, signOut } from '../../helper/firebase/auth';
import { findGetParameter } from '../../helper/interactive/misc';
import rooms from '../../helper/requests/rooms';
import spaceObj from '../../helper/requests/spaces';
import { getAuth } from '../../store/auth';
import { getSpacesRooms } from '../../store/spacesRooms';
import './spaceInput.scss';

const SpaceInputs = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  const [spaceName, setSpaceName] = useState('');
  const [spaceID, setSpaceID] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const auth = useSelector(getAuth);

  const history = useHistory();

  const IDGen = UrlSafeString();
  const sticky = findGetParameter('sticky');

  useEffect(() => {
    if (
      !auth.user.isGuest &&
      spacesRooms.currentSpace &&
      spacesRooms.currentSpace.length > 2 &&
      spacesRooms.spaces[spacesRooms.currentSpace] &&
      !sticky
    ) {
      history.push(`/s/${spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID}`);
    }
  }, [spacesRooms.currentSpace, spacesRooms.spaces, history, sticky]);

  const createSpace = async () => {
    if (loading) return;
    if (auth.user.isGuest) {
      signOut();
      history.push('/login');
      return;
    }
    if (spaceName.trim().length < 3 || spaceID.trim().length < 3) {
      setMsg('Space name/ID must be 3 or more characters each.');
      sendAmplitudeData('Space created', {
        spaceID,
        spaceName,
        status: 'Failed',
        state: 'Space name/ID must be 3 or more characters each.',
      });
      return;
    }
    if (spaceName.trim().length === 0 || spaceID.trim().length === 0) {
      setMsg('Space name/ID cannot be empty.');
      return;
    }

    if (spaceName.trim().length !== spaceName.length && spaceName.trim().length === 0) {
      setMsg('Space name should not contain only spaces');
      return;
    }

    if (spaceID.trim().length !== spaceID.length && spaceID.trim().length === 0) {
      setMsg('Space ID should not contain only spaces');
      return;
    }

    if (spaceID.trim().length > 30) {
      setMsg('Space ID can be atmost 30 characters long');
      return;
    }

    if (spaceName.trim().length > 50) {
      setMsg('Space name can be atmost 50 characters long');
      return;
    }

    try {
      setLoading(true);
      const token = await getClaimToken();
      if (token) {
        let resp = await spaceObj.create(spaceName, token.token, spaceID);
        await spaceObj.setSpaceCustomClaim(resp.data.space.id, token.token);
        await rooms.setRoomCustomClaim(resp.data.space.defaultRoom, token.token);
        if (resp.data.space) {
          history.push('/s/' + spaceID);
          setLoading(false);
        }
        sendAmplitudeData('Space created', { spaceID, spaceName, status: 'Success' });
      }
    } catch (e) {
      console.error('Error creating space:', e);
      setMsg(`${e.response?.data?.error}`);
      sendAmplitudeData('Space created', { spaceID, spaceName, status: 'Failed', state: e.response?.data?.error });
      setLoading(false);
    }
  };

  return (
    <>
      <div className="space-input-container">
        <div className="space-input-form-section">
          <div className="space-input-form-items">
            <div className="space-input-form-header">
              <div className="space-input-form-header-title">
                <span>Your new virtual estate</span>
              </div>
              {/* <div className="space-input-form-company-logo-inputs">
                <div className="company-logo-image">
                  <img src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png" />
                </div>
                <div className="company-logo-change-button">
                  <span>CHANGE LOGO</span>
                </div>
              </div> */}
              <div className="space-input-field-item">
                <div className="space-input-field-label">
                  <span>NAME YOUR SPACE</span>
                </div>
                <div className="space-input-field">
                  <input
                    className="space-name-input"
                    maxLength={256}
                    value={spaceName}
                    onChange={(e) => {
                      setSpaceName(e.target.value);
                      setMsg('');
                    }}
                  ></input>
                </div>
              </div>

              <div className="space-input-field-item">
                <div className="space-input-field-label">
                  <span>YOUR SPACE URL</span>
                </div>
                <div className="space-input-field">
                  <span>https://app.reslash.co/s/</span>
                  <input
                    className="space-id-input"
                    maxLength={256}
                    value={spaceID}
                    onChange={(e) => {
                      setSpaceID(IDGen.generate(e.target.value));
                      setMsg('');
                    }}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          {msg.length > 0 && (
            <div className="sign-up-form-error-state">
              <span>{msg}</span>
            </div>
          )}

          <div
            className="space-input-form-action"
            onClick={() => {
              sendAmplitudeData('Space create clicked', { spaceID, spaceName });
              createSpace();
            }}
          >
            <div className="space-input-form-button">
              <span>Continue</span>
            </div>
            <div className="space-input-msg-section">
              <span>Your space name will be used to personalize your Reslash experience</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpaceInputs;
