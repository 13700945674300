import axios from 'axios';
import qs from 'querystring';
import { config } from '../../config';
import { setUploadProgressWall } from '../../store';
import logger from '../logger';
import { headers } from './spaces';

export async function uploadImage(
  file: string | Blob,
  token: string,
  fileType: 'background' | 'canvas',
  spaceID: string,
  roomID: string
) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('token', token);
  formData.append('fileType', fileType);
  formData.append('spaceID', spaceID);
  formData.append('roomID', roomID);

  setUploadProgressWall(0);

  try {
    logger.info('uploadImage called.', { fileType });
    const result = await axios.post(`${config.firebaseCloudURL}/uploadImage`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (p) => {
        const progress = (100 * p.loaded) / p.total;
        setUploadProgressWall(progress);
      },
    });
    logger.info('uploadImage response:', { result: result.data });
    return result.data;
  } catch (e) {
    logger.warn('uploadImage error:', { error: e?.response?.data });
    return { error: e?.response?.data ?? true };
  }
}

export async function uploadImageFromURL(
  fileURL: string,
  token: string,
  fileType: 'background' | 'canvas',
  spaceID: string,
  roomID: string
) {
  try {
    logger.info('uploadImageFromURL called.', { fileType });
    const result = await axios.post(
      `${config.firebaseCloudURL}/uploadImageURL`,
      qs.stringify({ fileURL, token, fileType, spaceID, roomID }),
      {
        headers,
      }
    );
    logger.info('uploadImageFromURL response:', { result: result.data });
    return result.data;
  } catch (e) {
    logger.warn('uploadImageFromURL error:', { error: e?.response?.data });
    return { error: e?.response?.data ?? true };
  }
}
