import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getGetModalState } from '../../store/modal';
import { ModalStyle } from '../modal/modal';
import './previewRecordingModal.scss';

let onConfirm = () => {};
let onCancel = () => {};
let previewURL: string;

export const previewRecordingModalID = 'preview-recording-id';
export const openPreviewRecordingModal = (onConfirm_: () => void, onCancel_: () => void, previewURL_: string) => {
  onConfirm = onConfirm_;
  onCancel = onCancel_;
  previewURL = previewURL_;
  return openModalThunk(previewRecordingModalID);
};
export const closePreviewRecordingModal = closeModalThunk(previewRecordingModalID);

export const PreviewRecordingModal = () => {
  const previewRecordingState = useSelector(getGetModalState(previewRecordingModalID));
  useEffect(() => {
    newModalThunk(previewRecordingModalID, false)();
  }, []);
  return (
    <ReactModal
      id={previewRecordingModalID}
      className={previewRecordingModalID}
      isOpen={previewRecordingState}
      style={ModalStyle}
    >
      <>
        <div className="body">
          <div className="title">Your recording is now ready!</div>
          <div className="video-container">
            <iframe
              className="media embed"
              //   src="https://locate-me-fe.s3.amazonaws.com/69739216-715e-4c1c-98bf-dd9511febd2f-blob?dl=1"
              src={previewURL}
              height="100%"
              width="100%"
              allow="autoplay; encrypted-media; fullscreen;"
            />
          </div>
          <div style={{ fontSize: '10px' }}>
            <p>Note: </p>
            <p>
              Checkout this help{' '}
              <a href="https://reslash.tawk.help/article/how-to-use-recording" target="_blank">
                article
              </a>{' '}
              to know more about this recording feature.
            </p>
          </div>
        </div>
        <div className="footer">
          <div className="buttons">
            <div
              className="cancel-btn"
              onClick={() => {
                onCancel();
                closePreviewRecordingModal();
              }}
            >
              Cancel
            </div>
            <div
              className="action-btn"
              onClick={() => {
                onConfirm();
              }}
            >
              Download
            </div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default PreviewRecordingModal;
