import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Hint } from 'react-autocomplete-hint'; // https://github.com/ejmudi/react-autocomplete-hint
import { X } from 'react-feather';
import { formatLong, validateEmail } from '../../helper/interactive/misc';
import { openConfirmAction } from '../roomSettings/confirmAction';
import './tagInput.scss';

const TagInput = (props: {
  suggestions:
    | (
        | {
            id: number;
            label: string;
          }
        | {
            id: string;
            label: string;
          }
      )[]
    | string[];
  fetchTags: (tags: any) => void;
  existingTags: string[];
  undeleteableTag?: string;
  highlightedTag?: string[];
}) => {
  const [inputText, setInputText] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    setTags((tags) => [...tags, ...props.existingTags]);
  }, [props.existingTags]);

  const addToTags = (e: any, delimiter?: string) => {
    const delimiterSeparatedTags = inputText.split(delimiter ? delimiter : ',');
    if (delimiterSeparatedTags.length > 1) {
      delimiterSeparatedTags.forEach((inputString) => {
        const inp = inputString.trim();
        if (!validateEmail(inp)) return;
        if (inp.length && !tags.includes(inp)) {
          e.preventDefault();
          setTags((prevState) => [...prevState, inp]);
          setInputText('');
        }
      });
    } else {
      const trimmedInput = inputText.trim();
      if (!validateEmail(trimmedInput)) return;
      if (trimmedInput.length && !tags.includes(trimmedInput)) {
        e.preventDefault();
        setTags((prevState) => [...prevState, trimmedInput]);
        setInputText('');
      }
    }
  };

  const deleteTag = (tag_: string) => {
    setTags((prevState) => prevState.filter((tag, i) => tag !== tag_));
  };

  const deleteAllUndeleteableTags = () => {
    setTags((oldTags) => {
      let tags = oldTags;
      let newTags: string[] = [];
      for (let i in tags) {
        const tag = tags[i];
        if (props.highlightedTag && props.highlightedTag.includes(tag)) newTags.push(tag);
      }
      return newTags;
    });
  };

  useEffect(() => {
    props.fetchTags(_.uniqBy(tags, (tag) => tag));
  }, [tags]);

  return (
    <>
      <div className="tag-input-container">
        <div className="input-tag-container">
          <Hint options={props.suggestions} allowTabFill={true}>
            <input
              value={inputText}
              placeholder={'Type an email'}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  addToTags(e);
                }
              }}
            />
          </Hint>
        </div>
        {tags.length > 0 && (
          <div className="tags-container">
            {!props.highlightedTag ||
            _.uniqBy(tags, (tag) => tag).length > _.uniqBy(props.highlightedTag, (tag) => tag).length ? (
              <div
                className={'tag clickable'}
                onClick={() => {
                  deleteAllUndeleteableTags();
                }}
              >
                <span>remove all non-admins</span>
              </div>
            ) : (
              <></>
            )}
            {_.sortBy(
              _.uniqBy(tags, (tag) => tag),
              (tag) => {
                if (tag === props.undeleteableTag) {
                  return [-1, tag];
                }
                if (props.highlightedTag && props.highlightedTag.includes(tag)) return [0.5, tag];
                return [1, tag];
              }
            ).map((tag, index) => (
              <div className={'tag ' + (props.highlightedTag && props.highlightedTag.includes(tag) ? 'green' : '')}>
                <span>{formatLong(tag, 32)}</span>
                {tag !== props.undeleteableTag && (
                  <div
                    className="tag-delete"
                    onClick={() => {
                      if (props.highlightedTag && props.highlightedTag.includes(tag)) {
                        openConfirmAction(
                          function () {
                            deleteTag(tag);
                          },
                          () => {},
                          `${tag} is an admin, are you sure?`
                        )();
                      } else {
                        deleteTag(tag);
                      }
                    }}
                  >
                    <X />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TagInput;
