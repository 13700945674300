import { formatRelative } from 'date-fns';
import firebase from 'firebase';
import React, { useRef, useState } from 'react';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import Linkify from 'react-linkify';
import { useSelector } from 'react-redux';
import { SecureLink } from 'react-secure-link';
import { formatLong } from '../../helper/interactive/misc';
import { getAuth } from '../../store/auth';
import { getSpacesRooms } from '../../store/spacesRooms';
import Avatar from '../avatars/avatar';
import { useOutsideEvent } from '../interactive/giphy/Giphy';
import './message.scss';

const formatDate = (date: Date) => {
  let formattedDate = '';
  if (date) {
    // Convert the date in words relative to the current date
    formattedDate = formatRelative(date, new Date());
    // Uppercase the first letter
    formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }
  return formattedDate;
};

const Message = (props: {
  createdAt: { seconds: number; nanoseconds: number };
  createdBy: string;
  text: string;
  displayName: string;
  photoURL: string;
  id: string;
  email?: string;
  status?: string;
}) => {
  // if (!text) return null;

  const [showOptionButton, setShowOptionButton] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const auth = useSelector(getAuth);
  const user = auth.user;
  const spacesRooms = useSelector(getSpacesRooms);
  const userRole = spacesRooms.currentUserRole;
  const isAdmin = spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin;

  const msgSettingsRef = useRef<HTMLDivElement | null>(null);
  const messagesRef = firebase.firestore().collection('roomV2').doc(spacesRooms.currentRoom).collection('messages');

  const deleteMessage = async (messageId: string) => {
    await messagesRef.doc(messageId).update({
      isDeleted: true,
      updatedBy: user.id,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  useOutsideEvent(
    msgSettingsRef,
    () => {
      setShowOptions(false);
    },
    'message-options-icon',
    '#message-options-icon'
  );

  return (
    <div
      className="message-container"
      key={props.id}
      onMouseEnter={(e) => {
        if (isAdmin || userRole === 'h' || auth.user.id === props.createdBy) setShowOptionButton(true);
      }}
      onMouseLeave={(e) => {
        if (isAdmin || userRole === 'h' || auth.user.id === props.createdBy) {
          setShowOptionButton(false);
          setShowOptions(false);
        }
      }}
    >
      <div style={{ width: '55px', height: '45px' }} className="user-avatar">
        <Avatar src={props.photoURL} name={props.displayName} smallerFont={true} />
      </div>
      <div className="message-body">
        <div className="message-sent">
          {props.displayName ? (
            <p className="user-name">{formatLong(props.displayName, 18)}</p>
          ) : (
            <p className="user-name">{'Guest'}</p>
          )}

          <div className="message-sent-right-side">
            {props.createdAt && props.createdAt.seconds !== 0 ? (
              <span className="message-time">{formatDate(new Date(props.createdAt.seconds * 1000))}</span>
            ) : null}
            <div
              className="message-options-icon"
              id="message-options-icon"
              ref={msgSettingsRef}
              style={{ visibility: showOptionButton ? 'visible' : 'hidden' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowOptions(!showOptions);
              }}
            >
              <ThreeDotsVertical size={12} />

              {showOptions && (
                <div className="msg-menu">
                  <div
                    className="msg-option"
                    onClick={() => {
                      deleteMessage(props.id);
                    }}
                  >
                    Delete
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <SecureLink href={decoratedHref} key={key}>
              {decoratedText}
            </SecureLink>
          )}
        >
          <p className="message-text">{props.text}</p>
        </Linkify>
      </div>
    </div>
  );
};

export default Message;
