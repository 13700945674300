import { BigHead } from '@bigheads/core';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { volBounds } from '../../config';
import { checkIfImgUrlIsValid, findGetParameterFromURL, getInitials } from '../../helper/interactive/misc';
import { getUserPresence } from '../../store/presence';
import { getSpacesRooms } from '../../store/spacesRooms';
import { Reaction } from '../reactionsModal/reactions';
import './avatar.scss';

const sanitizeAttr = (val: string) => {
  if (val === 'female' || val === 'breasts') return 'breasts';
  else if (val === 'male' || val === 'chest') return 'chest';
  return val;
};

const BadgeStar = () => {
  return (
    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.6426 1.55602L13.4127 1.43691L13.1827 1.55602L9.91869 3.24661L6.28433 3.79744L6.02828 3.83625L5.91225 4.06777L4.2653 7.35404L1.64881 9.93589L1.46447 10.1178L1.50669 10.3733L2.1059 14L1.50669 17.6267L1.46447 17.8822L1.64881 18.0641L4.2653 20.646L5.91225 23.9322L6.02828 24.1638L6.28433 24.2026L9.91869 24.7534L13.1827 26.444L13.4127 26.5631L13.6426 26.444L16.9067 24.7534L20.541 24.2026L20.7971 24.1638L20.9131 23.9322L22.5601 20.646L25.1765 18.0641L25.3609 17.8822L25.3187 17.6267L24.7195 14L25.3187 10.3733L25.3609 10.1178L25.1765 9.93589L22.5601 7.35404L20.9131 4.06777L20.7971 3.83625L20.541 3.79744L16.9067 3.24661L13.6426 1.55602Z"
        stroke="#C4C4C4"
      />
    </svg>
  );
};

export function getAvatarStateFromURL(url: string, booleanFlag = true) {
  const avatarAttributes: any = {
    accessory: findGetParameterFromURL('accessory', url),
    body: sanitizeAttr(findGetParameterFromURL('body', url)),
    circleColor: findGetParameterFromURL('circleColor', url),
    clothing: findGetParameterFromURL('clothing', url),
    clothingColor: findGetParameterFromURL('clothingColor', url),
    eyebrows: findGetParameterFromURL('eyebrows', url),
    eyes: findGetParameterFromURL('eyes', url),
    facialHair: findGetParameterFromURL('facialHair', url),
    graphic: findGetParameterFromURL('graphic', url),
    hair: findGetParameterFromURL('hair', url),
    hairColor: findGetParameterFromURL('hairColor', url),
    hat: findGetParameterFromURL('hat', url),
    hatColor: findGetParameterFromURL('hatColor', url),
    lashes: findGetParameterFromURL('lashes', url) == 'true',
    lipColor: findGetParameterFromURL('lipColor', url),
    mask: findGetParameterFromURL('mask', url) == 'true',
    faceMask: findGetParameterFromURL('faceMask', url) == 'true',
    mouth: findGetParameterFromURL('mouth', url),
    skinTone: findGetParameterFromURL('skinTone', url),
  };
  if (!booleanFlag) {
    avatarAttributes.lashes = avatarAttributes.lashes ? 'true' : 'false';
    avatarAttributes.mask = avatarAttributes.mask ? 'true' : 'false';
    avatarAttributes.faceMask = avatarAttributes.faceMask ? 'true' : 'false';
  }
  return avatarAttributes;
}

export const BadgeWrapper = (props: {
  children: JSX.Element;
  isAdmin: boolean;
  isHost: boolean;
  isSpeaker: boolean;
  id: string;
  isLocalUser?: boolean;
}) => {
  /* 
  When we want to move the bubble to dynamic sizes, 
  then this function can be used to set radius when 
  onMouseover, OnTouchmove etc event happen on the interface.
  */

  // const getSpatialRadiusStyle = (bubbleSize: number) => {
  //   const circleRadius = bubbleSize;
  //   return {
  //     borderRadius: '50%',
  //     boxShadow: `0 0 0 ${circleRadius}px rgba(196, 196, 196, 0.2), 0 0 0 ${
  //       circleRadius * 2
  //     }px rgba(196, 196, 196, 0.2), 0 0 0 ${circleRadius * 3}px rgba(196, 196, 196, 0.2), 0 0 0 ${
  //       circleRadius * 4
  //     }px rgba(196, 196, 196, 0.2)`,
  //     transition: 'box-shadow 0.1s ease-in-out',
  //   };
  // };
  const sizes: { [size: number]: string } = {
    128: 'Small',
    184: 'Medium',
    256: 'Large',
  };

  const spacesRooms = useSelector(getSpacesRooms);

  const [spatialRadius, setSpatialRadius] = useState({
    radius: (spacesRooms.currentRoomPermission.size * volBounds.upper) / 128,
    bubble: sizes[spacesRooms.currentRoomPermission.size],
  });

  const getSpatialRadius = () => {
    const bubbleSize = spacesRooms.currentRoomPermission.size;
    const isSpatialEnabled = spacesRooms.currentRoomPermission.spatial;
    const spatialRadius = isSpatialEnabled ? (bubbleSize * volBounds.upper) / 128 - bubbleSize / 2 : 0;
    return { radius: spatialRadius, bubble: sizes[bubbleSize] };
  };

  useEffect(() => {
    setSpatialRadius(getSpatialRadius());
  }, [spacesRooms.currentRoomPermission.size, spacesRooms.currentRoomPermission.spatial]);

  const selectSpatialClass = () => {
    if (props.isLocalUser && spatialRadius.radius !== 0) {
      if (spatialRadius.bubble === 'Small') return 'spatialRangeSmall';
      else if (spatialRadius.bubble === 'Medium') return 'spatialRangeMedium';
      else if (spatialRadius.bubble === 'Large') return 'spatialRangeLarge';
      else return 'spatialRangeSmall';
    } else return '';
  };

  // const reactionData = useSelector(getReactionRedux);
  const presence = useSelector(getUserPresence(spacesRooms.currentRoom, spacesRooms.currentRoomCopy + '', props.id));

  return (
    <>
      <div className={`badgeContainer ${selectSpatialClass()}`}>
        {(props.isAdmin || props.isHost || props.isSpeaker) && (
          <div className="badge">
            <div className="internalBadge">
              <BadgeStar />
              {props.isAdmin && (
                <div className="label" style={{ color: '#34E3B9' }}>
                  A
                </div>
              )}
              {!props.isAdmin && props.isHost && (
                <div className="label" style={{ color: '#7593FF' }}>
                  H
                </div>
              )}
              {!props.isAdmin && !props.isHost && props.isSpeaker && (
                <div className="label" style={{ color: '#EC7B39' }}>
                  S
                </div>
              )}
            </div>
          </div>
        )}
        {props.children}
        {presence &&
          presence.reaction &&
          presence.reaction.type &&
          presence.reaction.type === 'sticker' &&
          presence.reaction.payload &&
          presence.reaction.payload.url &&
          presence.reaction.payload.url !== '' && (
            <Reaction
              type={presence.reaction.type}
              payload={{
                url: presence.reaction.payload.url,
              }}
              sentTime={presence.reaction.sentTime}
            />
          )}
      </div>
    </>
  );
};

type propType = {
  src: string;
  name: string;
  color?: string;
  key?: string;
  omitCircle?: boolean;
  smallerFont?: boolean;
};
const Avatar = (props: propType) => {
  const videoPlaceholderRef = useRef<HTMLDivElement | null>(null);
  const [url, setUrl] = useState('');

  const hideVideoForMe = false; // Add it as a prop or get value from useSelector and check user id for which changes need to be made

  useEffect(() => {
    //add check for avatar valid;
    if (!props.src || props.src?.length < 1) {
      setUrl('');
    } else if (props.src.includes('/avatar?') && props.src.includes('app.reslash.co')) {
      setUrl(props.src);
    } else {
      (async () => {
        if (await checkIfImgUrlIsValid(props.src)) {
          setUrl(props.src);
        } else {
          setUrl('');
        }
      })();
    }
  }, [props.src]);

  if (url.length < 1 || hideVideoForMe) {
    return (
      <div
        className={props.omitCircle ? 'profile-pic-square' : 'profile-pic' + (props.smallerFont ? ' smaller' : '')}
        key={props.key}
      >
        <div
          className={props.omitCircle ? 'profile' : 'videoPlaceholder profile'}
          ref={videoPlaceholderRef}
          style={
            props.omitCircle
              ? {}
              : {
                  backgroundColor:
                    props.color && !hideVideoForMe ? props.color : hideVideoForMe ? '#C4C4C4' : '#85C5E5',
                  position: 'relative',
                  border: 'none',
                }
          }
        >
          <div className="initials-class">
            <span>{props.name ? getInitials(props.name) : 'A'}</span>
          </div>
        </div>
      </div>
    );
  } else if (url.includes('/avatar?') && url.includes('app.reslash.co') && !hideVideoForMe) {
    const avatarAttributes = getAvatarStateFromURL(url);
    return (
      <div className={props.omitCircle ? 'profile-pic-square' : 'profile-pic'} key={props.key}>
        <BigHead
          accessory={avatarAttributes.accessory}
          body={avatarAttributes.body === 'female' || avatarAttributes.body === 'breasts' ? 'breasts' : 'chest'}
          circleColor={avatarAttributes.circleColor}
          clothing={avatarAttributes.clothing}
          clothingColor={avatarAttributes.clothingColor}
          eyebrows={avatarAttributes.eyebrows}
          eyes={avatarAttributes.eyes}
          facialHair={avatarAttributes.facialHair}
          graphic={avatarAttributes.graphic}
          hair={avatarAttributes.hair}
          hairColor={avatarAttributes.hairColor}
          hat={avatarAttributes.hat}
          hatColor={avatarAttributes.hatColor}
          lashes={avatarAttributes.lashes}
          lipColor={avatarAttributes.lipColor}
          mask={avatarAttributes.mask}
          faceMask={avatarAttributes.faceMask}
          mouth={avatarAttributes.mouth}
          skinTone={avatarAttributes.skinTone}
        />
      </div>
    );
  } else {
    return <img className={props.omitCircle ? 'profile-pic-square' : 'profile-pic'} src={props.src} key={props.key} />;
  }
};

export default Avatar;
