import Session from './session';

type onNewSessionCallback = (sessionID: string, session: Session, oldSession?: Session[]) => void;

export class Usage {
  private readonly sessions: { [sessionID: string]: Session | undefined };
  private currentSessionID: string;
  private readonly onNewSessionCallBacks: onNewSessionCallback[];
  isCurrentSessionBeingGenerated: string;
  isOffline: boolean;

  constructor() {
    this.sessions = {};
    this.currentSessionID = '';
    this.onNewSessionCallBacks = [];
    this.isOffline = false;
    this.isCurrentSessionBeingGenerated = '';
  }

  getCurrentSession() {
    if (this.sessions[this.currentSessionID]?.getSessionID()) return this.sessions[this.currentSessionID];
    return undefined;
  }

  async createNewSession(roomID: string, spaceID: string, userID: string, reconnected = false) {
    if (this.isCurrentSessionBeingGenerated === roomID && !reconnected) {
      return;
    }
    this.isCurrentSessionBeingGenerated = roomID;
    try {
      const newSession = new Session(roomID, spaceID, userID);
      await newSession.initiateSession();
      const oldSessions = await this.endAllSessions(newSession.getSessionID());
      this.sessions[newSession.getSessionID()] = newSession;
      this.currentSessionID = newSession.getSessionID();
      newSession.onTermination((sessionID) => {
        delete this.sessions[sessionID];
      });
      for (const callback of this.onNewSessionCallBacks) {
        callback(newSession.getSessionID(), newSession, oldSessions);
      }
      this.isOffline = false;
      this.isCurrentSessionBeingGenerated = '';
    } catch (e) {
      this.isCurrentSessionBeingGenerated = '';
      throw e;
    }
  }

  async endAllSessions(ignore?: string) {
    const oldSessions: Session[] = [];
    for (const oldSessionID in this.sessions) {
      if (oldSessionID === ignore) continue;
      const oldSession = this.sessions[oldSessionID];
      await Usage.endSession(this.sessions[oldSessionID]);
      if (oldSession) oldSessions.push(oldSession);
    }
    return oldSessions;
  }

  private static async endSession(session?: Session) {
    try {
      await session?.endSession();
    } catch {}
  }

  async setDailyInfo(sessionID: string, participantID: string) {
    await this.sessions[this.currentSessionID]?.setDailyInfo(sessionID, participantID);
  }

  onNewSession(callback: onNewSessionCallback) {
    this.onNewSessionCallBacks.push(callback);
    const currentSession = this.sessions[this.currentSessionID];
    if (currentSession) {
      callback(this.currentSessionID, currentSession);
    }
  }
}

export default new Usage();
