import axios from 'axios';
import axiosRetry from 'axios-retry';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { EnvelopeFill, Facebook, Link, Linkedin, TelephoneFill, Twitter, XSquareFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { config } from '../../config';
import { getClaimToken, removeProfilePic, setProfilePic, setUserStatus } from '../../helper/firebase/auth';
import { avatarAttributes, avatarGenerator, random } from '../../helper/interactive/avatarRandomizer';
import { convertCamelcaseToRegular } from '../../helper/interactive/misc';
import {
  isEmail,
  isFacebookURL,
  isLinkedInURL,
  isPhoneNumber,
  isTwitterURL,
  isURL,
} from '../../helper/interactive/validator';
import { getAuth } from '../../store/auth';
import Avatar, { getAvatarStateFromURL } from '../avatars/avatar';
import DropDown from '../dropdown/dropdown';
import ImageCropper from '../imageCropper/imageCropper';
import LoaderV2 from '../loader/loaderV2';
import ProfileModal from '../modals/templateModals/profileModal';
import ToggleButtonV2 from '../toggleButton/toggleButtonV2';
import './profileSetupModal.scss';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

const ProfileSetUpBody = (props: any) => {
  const Options = [
    {
      color: '#34e3b9',
      text: 'Available',
    },
    {
      color: 'red',
      text: 'Busy',
    },
    {
      color: 'orange',
      text: 'Away',
    },
  ];

  const getHeader = (status: string) => {
    for (let i = 0; i < Options.length; i++) if (Options[i].text === status) return i;
    return 0;
  };

  const [headerTitle, setHeader] = useState(Options[getHeader('Available')]);
  const auth = useSelector(getAuth);

  const [input, setInput] = useState({
    name: auth.userDetails.name ? auth.userDetails.name : auth.user.name,
    about: auth.userDetails.desc ? auth.userDetails.desc : '',
    status: auth.userDetails.status ? auth.userDetails.status : 'Available',
    socialProfiles: auth.userDetails.socialProfiles
      ? {
          twitter: auth.userDetails.socialProfiles.twitter ? auth.userDetails.socialProfiles.twitter : '',
          linkedin: auth.userDetails.socialProfiles.linkedin ? auth.userDetails.socialProfiles.linkedin : '',
          facebook: auth.userDetails.socialProfiles.facebook ? auth.userDetails.socialProfiles.facebook : '',
          phone: auth.userDetails.socialProfiles.phone ? auth.userDetails.socialProfiles.phone : '',
          email: auth.userDetails.socialProfiles.email ? auth.userDetails.socialProfiles.email : '',
          link: auth.userDetails.socialProfiles.link ? auth.userDetails.socialProfiles.link : '',
        }
      : { twitter: '', linkedin: '', facebook: '', phone: '', email: '', link: '' },
  });
  const [effectWorkaround, setEffectWorkaround] = useState(false); // later find how to use useEffect with changes in dictionary

  useEffect(() => {
    setInput({
      name: auth.userDetails.name ? auth.userDetails.name : auth.user.name,
      about: auth.userDetails.desc ? auth.userDetails.desc : '',
      status: auth.userDetails.status ? auth.userDetails.status : 'Available',
      socialProfiles: auth.userDetails.socialProfiles
        ? {
            twitter: auth.userDetails.socialProfiles.twitter ? auth.userDetails.socialProfiles.twitter : '',
            linkedin: auth.userDetails.socialProfiles.linkedin ? auth.userDetails.socialProfiles.linkedin : '',
            facebook: auth.userDetails.socialProfiles.facebook ? auth.userDetails.socialProfiles.facebook : '',
            phone: auth.userDetails.socialProfiles.phone ? auth.userDetails.socialProfiles.phone : '',
            email: auth.userDetails.socialProfiles.email ? auth.userDetails.socialProfiles.email : '',
            link: auth.userDetails.socialProfiles.link ? auth.userDetails.socialProfiles.link : '',
          }
        : { twitter: '', linkedin: '', facebook: '', phone: '', email: '', link: '' },
    });

    setHeader(Options[getHeader(auth.userDetails.status ? auth.userDetails.status : 'Available')]);
  }, [auth]);
  useEffect(() => {
    props.onAction(input.name, input.about, input.status, input.socialProfiles);
  }, [input, effectWorkaround]);

  return (
    <>
      <div className="profile-body-container">
        <div className="image-cropper">
          <Avatar src={auth.userDetails.profilePic} name={auth.user.name} />
          <div
            className="overlayImage"
            onClick={(e) => {
              props.onImageClick(true);
            }}
          >
            CHANGE
            <br />
            AVATAR
          </div>
        </div>

        {auth.userDetails.profilePic && auth.userDetails.profilePic.length > 1 && (
          <div
            className="remove-avatar-button"
            onClick={() => {
              removeProfilePic();
            }}
          >
            <span>Remove avatar</span>
          </div>
        )}

        <div className="form-container">
          <div className="form-component">
            <label>Your Name</label>
            <div className="input-textbox">
              <input
                type="text"
                value={input.name}
                onChange={(event) => {
                  setInput({
                    name: event.target.value,
                    about: input.about,
                    status: input.status,
                    socialProfiles: {
                      twitter: input.socialProfiles.twitter,
                      facebook: input.socialProfiles.facebook,
                      linkedin: input.socialProfiles.linkedin,
                      phone: input.socialProfiles.phone,
                      email: input.socialProfiles.email,
                      link: input.socialProfiles.link,
                    },
                  });
                  setEffectWorkaround(!effectWorkaround);
                }}
              />
            </div>
          </div>

          <div className="form-component">
            <label>About You</label>
            <textarea
              className="input-textarea"
              maxLength={512}
              value={input.about}
              onChange={(event) => {
                setInput({
                  name: input.name,
                  about: event.target.value,
                  status: input.status,
                  socialProfiles: {
                    twitter: input.socialProfiles.twitter,
                    facebook: input.socialProfiles.facebook,
                    linkedin: input.socialProfiles.linkedin,
                    phone: input.socialProfiles.phone,
                    email: input.socialProfiles.email,
                    link: input.socialProfiles.link,
                  },
                });
                setEffectWorkaround(!effectWorkaround);
              }}
            ></textarea>
          </div>

          <div className="form-component">
            <label>Status</label>

            <div className="input-dropdown">
              <DropDown
                headerTitle={headerTitle}
                styles={{
                  dropDownHeader: { paddingLeft: '8px', paddingRight: '8px' },
                  listOuter: {
                    backgroundColor: '#393B41',
                    borderRadius: '8px',
                    border: '1px solid #000000',
                    top: '44px',
                  },
                  listItems: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    overflow: 'hidden',
                    paddingBottom: '6px',
                    paddingTop: '6px',
                  },
                }}
                showStatusColor={true}
                options={Options}
                onSelectItem={(e: any) => {
                  setHeader(Options[e.index]);
                  setInput({
                    name: input.name,
                    about: input.about,
                    status: e.header === '' ? headerTitle.text : e.header,
                    socialProfiles: {
                      twitter: input.socialProfiles.twitter,
                      facebook: input.socialProfiles.facebook,
                      linkedin: input.socialProfiles.linkedin,
                      phone: input.socialProfiles.phone,
                      email: input.socialProfiles.email,
                      link: input.socialProfiles.link,
                    },
                  });
                  setEffectWorkaround(!effectWorkaround);
                }}
              />
            </div>
          </div>

          <div className="form-component">
            <label>Social Profiles</label>

            <div className="input-textbox" style={{ paddingLeft: '8px', paddingRight: '8px', marginBottom: '18px' }}>
              <Twitter size={18} />
              <input
                style={{ paddingLeft: '10px' }}
                type="text"
                placeholder="Twitter"
                value={input.socialProfiles.twitter}
                onChange={(event) => {
                  setInput({
                    name: input.name,
                    about: input.about,
                    status: input.status,
                    socialProfiles: {
                      twitter: event.target.value,
                      facebook: input.socialProfiles.facebook,
                      linkedin: input.socialProfiles.linkedin,
                      phone: input.socialProfiles.phone,
                      email: input.socialProfiles.email,
                      link: input.socialProfiles.link,
                    },
                  });
                  setEffectWorkaround(!effectWorkaround);
                }}
              />
              {!isTwitterURL(input.socialProfiles.twitter) && input.socialProfiles.twitter !== '' && (
                <XSquareFill style={{ color: 'red', paddingRight: '8px' }} size={14} />
              )}
            </div>

            <div className="input-textbox" style={{ paddingLeft: '8px', paddingRight: '8px', marginBottom: '18px' }}>
              <Linkedin size={18} />
              <input
                style={{ paddingLeft: '10px' }}
                type="text"
                placeholder="LinkedIn"
                value={input.socialProfiles.linkedin}
                onChange={(event) => {
                  setInput({
                    name: input.name,
                    about: input.about,
                    status: input.status,
                    socialProfiles: {
                      twitter: input.socialProfiles.twitter,
                      facebook: input.socialProfiles.facebook,
                      linkedin: event.target.value,
                      phone: input.socialProfiles.phone,
                      email: input.socialProfiles.email,
                      link: input.socialProfiles.link,
                    },
                  });
                  setEffectWorkaround(!effectWorkaround);
                }}
              />
              {!isLinkedInURL(input.socialProfiles.linkedin) && input.socialProfiles.linkedin !== '' && (
                <XSquareFill style={{ color: 'red', paddingRight: '8px' }} size={14} />
              )}
            </div>

            <div className="input-textbox" style={{ paddingLeft: '8px', paddingRight: '8px', marginBottom: '18px' }}>
              <Facebook size={18} />
              <input
                style={{ paddingLeft: '10px' }}
                type="text"
                placeholder="Facebook"
                value={input.socialProfiles.facebook}
                onChange={(event) => {
                  setInput({
                    name: input.name,
                    about: input.about,
                    status: input.status,
                    socialProfiles: {
                      twitter: input.socialProfiles.twitter,
                      facebook: event.target.value,
                      linkedin: input.socialProfiles.linkedin,
                      phone: input.socialProfiles.phone,
                      email: input.socialProfiles.email,
                      link: input.socialProfiles.link,
                    },
                  });
                  setEffectWorkaround(!effectWorkaround);
                }}
              />
              {!isFacebookURL(input.socialProfiles.facebook) && input.socialProfiles.facebook !== '' && (
                <XSquareFill style={{ color: 'red', paddingRight: '8px' }} size={14} />
              )}
            </div>

            <div className="input-textbox" style={{ paddingLeft: '8px', paddingRight: '8px', marginBottom: '18px' }}>
              <TelephoneFill size={18} />
              <input
                style={{ paddingLeft: '10px' }}
                type="text"
                placeholder="Contact #"
                value={input.socialProfiles.phone}
                onChange={(event) => {
                  setInput({
                    name: input.name,
                    about: input.about,
                    status: input.status,
                    socialProfiles: {
                      twitter: input.socialProfiles.twitter,
                      facebook: input.socialProfiles.facebook,
                      linkedin: input.socialProfiles.linkedin,
                      phone: event.target.value,
                      email: input.socialProfiles.email,
                      link: input.socialProfiles.link,
                    },
                  });
                  setEffectWorkaround(!effectWorkaround);
                }}
              />
              {!isPhoneNumber(input.socialProfiles.phone) && input.socialProfiles.phone !== '' && (
                <XSquareFill style={{ color: 'red', paddingRight: '8px' }} size={14} />
              )}
            </div>

            <div className="input-textbox" style={{ paddingLeft: '8px', paddingRight: '8px', marginBottom: '18px' }}>
              <EnvelopeFill size={18} />
              <input
                style={{ paddingLeft: '10px' }}
                type="text"
                placeholder="Email Id"
                value={input.socialProfiles.email}
                onChange={(event) => {
                  setInput({
                    name: input.name,
                    about: input.about,
                    status: input.status,
                    socialProfiles: {
                      twitter: input.socialProfiles.twitter,
                      facebook: input.socialProfiles.facebook,
                      linkedin: input.socialProfiles.linkedin,
                      phone: input.socialProfiles.phone,
                      email: event.target.value,
                      link: input.socialProfiles.link,
                    },
                  });
                  setEffectWorkaround(!effectWorkaround);
                }}
              />
              {!isEmail(input.socialProfiles.email) && input.socialProfiles.email !== '' && (
                <XSquareFill style={{ color: 'red', paddingRight: '8px' }} size={14} />
              )}
            </div>

            <div className="input-textbox" style={{ paddingLeft: '8px', paddingRight: '8px' }}>
              <Link size={18} />
              <input
                style={{ paddingLeft: '10px' }}
                type="text"
                placeholder="Other Links"
                value={input.socialProfiles.link}
                onChange={(event) => {
                  setInput({
                    name: input.name,
                    about: input.about,
                    status: input.status,
                    socialProfiles: {
                      twitter: input.socialProfiles.twitter,
                      facebook: input.socialProfiles.facebook,
                      linkedin: input.socialProfiles.linkedin,
                      phone: input.socialProfiles.phone,
                      email: input.socialProfiles.email,
                      link: event.target.value,
                    },
                  });
                  setEffectWorkaround(!effectWorkaround);
                }}
              />
              {!isURL(input.socialProfiles.link ? input.socialProfiles.link : '') &&
                input.socialProfiles.link !== '' && (
                  <XSquareFill style={{ color: 'red', paddingRight: '8px' }} size={14} />
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ProfileEditAvatarBody = (props: any) => {
  const [useAvatar, setUseAvatar] = useState(false);
  const auth = useSelector(getAuth);

  const [profileURL, setProfileURL] = useState(
    'https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png'
  );

  const forbiddenAvatarCustomisations: string[] = ['circleColor', 'mask', 'color', 'faceMask'];

  const randomAvatar = {
    accessory: random(avatarAttributes.accessory),
    body: random(avatarAttributes.body),
    clothing: random(avatarAttributes.clothing),
    clothingColor: random(avatarAttributes.clothingColor),
    eyebrows: random(avatarAttributes.eyebrows),
    eyes: random(avatarAttributes.eyes),
    facialHair: random(avatarAttributes.facialHair),
    graphic: random(avatarAttributes.graphic),
    hair: random(avatarAttributes.hair),
    hairColor: random(avatarAttributes.hairColor),
    hat: random(avatarAttributes.hat),
    hatColor: random(avatarAttributes.hatColor),
    lashes: random(avatarAttributes.lashes),
    lipColor: random(avatarAttributes.lipColor),
    mouth: random(avatarAttributes.mouth),
    skinTone: random(avatarAttributes.skinTone),
  };

  const [avatarState, setAvatarState] = useState(randomAvatar);
  const [avatarURL, setAvatarURL] = useState(avatarGenerator(avatarState));
  const [effectWorkaround, setEffectWorkaround] = useState(false); // later find how to use useEffect with changes in dictionary

  const handleAvatarStateChange = (attribute: any, value: any) => {
    setAvatarState((avatarState: any) => {
      avatarState[attribute] = value;
      setEffectWorkaround(!effectWorkaround);
      return avatarState;
    });
  };

  /* This is triggered whenever any attribute in the avatar customisation is changed and it sets the avatarURL. */
  useEffect(() => {
    setAvatarURL(avatarGenerator(avatarState));
  }, [avatarState, effectWorkaround]);

  useEffect(() => {
    if (
      auth.userDetails.profilePic &&
      auth.userDetails.profilePic.includes('/avatar?') &&
      auth.userDetails.profilePic.includes('app.reslash.co')
    ) {
      setAvatarState(getAvatarStateFromURL(auth.userDetails.profilePic, false));
      setAvatarURL(auth.userDetails.profilePic);
      setUseAvatar(true);
    } else {
      setAvatarState(randomAvatar);
      setUseAvatar(false);
      setAvatarURL(auth.userDetails.profilePic);
    }
  }, [auth.userDetails.profilePic]);

  /* This is triggered whenever the avatar URL changes and pushes it back to parent onAction prop. */
  useEffect(() => {
    props.onAction(avatarURL, useAvatar);
  }, [avatarURL]);

  const inputImageFile = useRef<HTMLInputElement>(null);
  const [blob, setBlob] = useState(null);
  const [inputImg, setInputImg] = useState<any>('');
  const [fileName, setFileName] = useState('');

  const getBlob = (blob: any) => {
    setBlob(blob);
  };

  const [preventReupload, setPreventReupload] = useState(false);

  // useEffect(() => {
  //   if (!preventReupload) inputImageFile.current?.click();
  // }, [preventReupload]);

  const onUploadChange = (e: any) => {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        setInputImg(reader.result);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
      setFileName(e.target.value);
      setPreventReupload(true);
    } else setPreventReupload(false);
  };

  useEffect(() => {
    props.getFile(blob, fileName, useAvatar);
  }, [blob]);

  const [zoomLevel, setZoomLevel] = useState(1);

  return (
    <>
      <div className="profile-body-container">
        <div
          className="image-cropper"
          style={{
            borderRadius: '4px',
            backgroundColor: 'rgba(0,0,0,0.2)',
            marginTop: '19px',
            pointerEvents: useAvatar ? 'none' : 'auto',
            overflow: 'hidden',
          }}
          onClick={() => {
            if (!preventReupload) inputImageFile.current?.click();
          }}
        >
          {!preventReupload && <Avatar src={avatarURL} name={auth.userDetails.name} />}
          {!preventReupload && !useAvatar && (
            <div className="overlayImage">
              UPLOAD
              <br />
              IMAGE
            </div>
          )}

          <input
            type="file"
            accept="image/*"
            ref={inputImageFile}
            onChange={onUploadChange}
            style={{ display: 'none' }}
          />
          {!useAvatar && inputImg && <ImageCropper getBlob={getBlob} inputImg={inputImg} customZoom={zoomLevel} />}
        </div>

        {/* Commenting out Profile Pic Zoom Range Slider */}
        {/* <div className="slider-container" style={{ pointerEvents: useAvatar || fileName.length < 1 ? 'none' : 'auto' }}>
          <Image size={13.5} style={{ color: '#C4C4C4', marginLeft: '15px' }} />
          <RangeSlider
            rangeMin={1}
            rangeMax={100}
            onRangeValue={(value: any) => setZoomLevel(value / 10)}
            styles={{
              border: 'none',
              background: 'transparent',
            }}
          />
          <Image size={18} style={{ color: '#C4C4C4', marginRight: '15px' }} />
        </div> */}

        <div className="avatar-toggle">
          <span>Use avatar instead of an image</span>
          <ToggleButtonV2
            val={useAvatar}
            name={'AvatarToggle'}
            onChange={(e) => {
              setUseAvatar(e);
              setPreventReupload(false);
              if (e) {
                setEffectWorkaround(!effectWorkaround);
              } else {
                setAvatarURL(auth.userDetails.profilePic);
              }
            }}
          />
        </div>

        <div
          className="toggle-overlay-wrapper"
          style={
            !useAvatar
              ? {
                  position: 'relative',
                  top: '0px',
                  left: '0px',
                  right: '0px',
                  bottom: '0px',
                  pointerEvents: 'none',
                }
              : {}
          }
        >
          <div className="personalize-avatar">
            <div className="personalize-avatar-menu">
              <span>Personalize your avatar</span>
            </div>
            <div
              className="avatar-randomize-button"
              style={
                useAvatar ? { background: '#34E3b9', color: '#000000' } : { background: '#636771', color: '#FFFFFF' }
              }
              onClick={() => {
                setAvatarState(randomAvatar);
                setEffectWorkaround(!effectWorkaround);
                // setAvatarURL(avatarRandomizer());
              }}
            >
              <span>Randomize</span>
            </div>
          </div>

          <div className="avatar-customisations-section">
            {_.map(avatarAttributes as any, (_val, attribute) => {
              if (forbiddenAvatarCustomisations.includes(attribute)) return null;
              return (
                <div className={'avatar-attribute ' + attribute}>
                  <span className="attribute-label">{convertCamelcaseToRegular(attribute)} </span>
                  <DropDown
                    headerTitle={convertCamelcaseToRegular((avatarState as any)[attribute])}
                    styles={{
                      dropDownHeader: {
                        marginLeft: '12px',
                        marginRight: '8px',
                        color: '#d4d4d4',
                        fontSize: '14px',
                        fontWeight: 500,
                      },
                      listOuter: {
                        marginTop: '0px',
                        backgroundColor: '#393B41',
                        borderRadius: '8px',
                        border: '1px solid #000000',
                        top: '20px',
                        boxShadow: '2px 5px #00000035',
                      },
                      listItems: {
                        paddingLeft: '6px',
                        overflow: 'hidden',
                        paddingBottom: '2px',
                        color: '#ffffff',
                        fontSize: '12px',
                      },
                    }}
                    showStatusColor={false}
                    optionsListType="array"
                    options={_val}
                    isCamelcase={true}
                    arrowSize={12}
                    onSelectItem={(e: any) => {
                      handleAvatarStateChange(attribute, e.header);
                    }}
                  />
                </div>
              );
            })}
          </div>

          {!useAvatar ? (
            <div
              className="toggle-overlay-cover"
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                pointerEvents: 'none',
                color: '#393B4150',
                backgroundColor: '#393B4150',
                height: '100%',
                width: '100%',
                borderRadius: '4px',
              }}
            ></div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const ProfileSetUpModal = (props: { toClose: (state: boolean) => void }) => {
  const [showBack, setShowBack] = useState(false);
  const [profileURL, setProfileURL] = useState<string>('');
  const [isAvatar, setIsAvatar] = useState<boolean>(false);
  const [profileStatus, setProfileStatus] = useState({
    name: '',
    about: '',
    status: '',
    socialProfiles: { twitter: '', linkedin: '', facebook: '', phone: '', email: '', link: '' },
  });
  const [navInitialState, setNavInitialState] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(true);
  const [profilePicFile, setProfilePicFile] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);

  const handleProfileUpload = async (profilePicFile: { file: string | Blob; fileName: any }): Promise<void> => {
    let formData = new FormData();
    const token = await getClaimToken();
    if (token) {
      formData.append('file', profilePicFile.file);
      formData.append('token', token.token);
      return new Promise((resolve) => {
        axios
          .request({
            method: 'POST',
            url: config.firebaseCloudURL + '/uploadProfile',
            data: formData,
            onUploadProgress: (p: { loaded: number; total: number }) => {
              const progress = (100 * p.loaded) / p.total;
            },
          })
          .then((response: { data: { path: any } }) => {
            const path = response.data.path;
            setProfileURL(path);
            setProfilePic(path, isAvatar);
            resolve();
          })
          .catch((e: any) => {
            console.error(e);
            resolve();
          });
      });
    }
  };

  return (
    <>
      {navInitialState && (
        <ProfileModal
          onClose={() => {
            props.toClose(false);
          }}
          isOpen={isOpen}
          showBack={showBack}
          header="Set Your Profile"
          footerActionButtonRight="Save Profile"
          setActionRight={() => {
            if (
              (isTwitterURL(profileStatus.socialProfiles.twitter) || profileStatus.socialProfiles.twitter === '') &&
              (isFacebookURL(profileStatus.socialProfiles.facebook) || profileStatus.socialProfiles.facebook === '') &&
              (isLinkedInURL(profileStatus.socialProfiles.linkedin) || profileStatus.socialProfiles.linkedin === '') &&
              (isEmail(profileStatus.socialProfiles.email) || profileStatus.socialProfiles.email === '') &&
              (isPhoneNumber(profileStatus.socialProfiles.phone) || profileStatus.socialProfiles.phone === '') &&
              (isURL(profileStatus.socialProfiles.link) || profileStatus.socialProfiles.link === '')
            ) {
              setUserStatus(profileStatus);
              setIsOpen(false);
            }
          }}
        >
          <ProfileSetUpBody
            onAction={(
              name: string,
              about: string,
              status: string,
              socialProfiles: {
                twitter: string;
                linkedin: string;
                facebook: string;
                phone: string;
                email: string;
                link: string;
              }
            ) => {
              setProfileStatus({ name: name, about: about, status: status, socialProfiles: socialProfiles });
            }}
            onImageClick={(imageClicked: boolean) => {
              setNavInitialState(!navInitialState);
              setShowBack(!showBack);
            }}
          />
        </ProfileModal>
      )}

      {!navInitialState && (
        <ProfileModal
          onClose={() => {
            props.toClose(false);
          }}
          isOpen={isOpen}
          showBack={showBack}
          setBackStep={() => {
            setShowBack(!showBack);
            setNavInitialState(!navInitialState);
          }}
          header="Set Your Avatar"
          footerActionButtonRight={!isLoading ? <span>Apply</span> : <LoaderV2 />}
          setActionRight={async () => {
            if (isLoading) return;
            // Please review this once
            setIsLoading(true);
            if (profileURL !== '' && isAvatar) await setProfilePic(profileURL, isAvatar);
            if (!isAvatar) await handleProfileUpload(profilePicFile);
            setIsLoading(false);
            setIsOpen(false);
            // setNavInitialState(false);
          }}
        >
          <ProfileEditAvatarBody
            onAction={(url: string, useAvatar: boolean) => {
              setProfileURL(url);
              setIsAvatar(useAvatar);
            }}
            getFile={(file: any, fileName: string, useAvatar: boolean) => {
              setIsAvatar(useAvatar);
              setProfilePicFile({ file: file, fileName: fileName });
            }}
          />
        </ProfileModal>
      )}
    </>
  );
};

export default ProfileSetUpModal;
