import { configureStore, createSlice } from '@reduxjs/toolkit';

//brah!

export type newBGType = {
  original: string;
  full: string;
  regular: string;
  small: string;
  thumbnail: string;
  properties: {
    backgroundSize: string;
    backgroundPosition: string;
    backgroundRepeat: string;
  };
};

export type screenWallV2 = {
  routerID: string;
  streamID: string;
  producerID: string;
};

const initialState: {
  wall: string;
  screenWall: string;
  screenWallV2?: screenWallV2;
  isScreenWall: boolean;
  screenVol: number;
  wallv2: newBGType | null;
  uploadProgress?: number;
  isBackgroundLoading?: boolean;
} = {
  wallv2: null,
  wall: 'loading',
  screenWall: '',
  isScreenWall: false,
  screenVol: 100,
  uploadProgress: 0,
  isBackgroundLoading: false,
};

export const wallpaperSlice = createSlice({
  name: 'wallpaper',
  initialState,
  reducers: {
    setWallpaper: (state, action) => {
      state.wall = action.payload;
    },
    setWallpaperV2: (state, action) => {
      state.wallv2 = action.payload;
    },
    setScreenWall: (state, action) => {
      state.screenWall = action.payload;
    },
    setScreenWallV2: (state, action) => {
      state.screenWallV2 = action.payload;
    },
    setIsScreenWall: (state, action) => {
      state.isScreenWall = action.payload;
    },
    setScreenVol: (state, action) => {
      state.screenVol = action.payload;
    },
    setUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
    setIsBackgroundLoading: (state, action) => {
      state.isBackgroundLoading = action.payload;
    },
  },
});

const wallpaperStore = configureStore({
  reducer: wallpaperSlice.reducer,
});

export const getWallpaperRedux = (state: { wallpaper: ReturnType<typeof wallpaperStore.getState> }) =>
  state.wallpaper.wall;

export const getWallpaperV2Redux = (state: { wallpaper: ReturnType<typeof wallpaperStore.getState> }) =>
  state.wallpaper.wallv2;

export const getScreenWallRedux = (state: { wallpaper: ReturnType<typeof wallpaperStore.getState> }) =>
  state.wallpaper.screenWall;

export const getScreenVolRedux = (state: { wallpaper: ReturnType<typeof wallpaperStore.getState> }) =>
  state.wallpaper.screenVol;

export const getIsScreenWallRedux = (state: { wallpaper: ReturnType<typeof wallpaperStore.getState> }) =>
  state.wallpaper.isScreenWall;

export const getUploadProgress = (state: { wallpaper: ReturnType<typeof wallpaperStore.getState> }) =>
  state.wallpaper.uploadProgress;

export const getIsBackgroundLoading = (state: { wallpaper: ReturnType<typeof wallpaperStore.getState> }) =>
  state.wallpaper.isBackgroundLoading;

export const getScreenWallV2 = (state: { wallpaper: ReturnType<typeof wallpaperStore.getState> }) =>
  state.wallpaper.screenWallV2;
