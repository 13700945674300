import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { maxPlayableScreenshares } from '../../config';
import { newNotificationThunk } from '../../store';
import { getGetNotificationState } from '../../store/modal';
import { getSpacesRooms } from '../../store/spacesRooms';
import NotificationToast from '../notificationToast/notificationToast';

const networkWarningID = 'network-warning';
export const showNetworkWarning = newNotificationThunk(networkWarningID, true);
export const hideNetworkWarning = newNotificationThunk(networkWarningID, false);

const NetworkWarningNotification = () => {
  useEffect(() => {
    newNotificationThunk(networkWarningID, false)();
  }, []);
  const state = useSelector(getGetNotificationState(networkWarningID));
  return (
    <NotificationToast
      message={'Slow network detected.'}
      show={state}
      icons={<span className="material-icons">warning</span>}
      width={220}
      onClose={() => {}}
      id={'low-network'}
    />
  );
};

const JoiningRoomID = 'joining-room';
export const showJoiningRoom = newNotificationThunk(JoiningRoomID, true);
export const hideJoiningRoom = newNotificationThunk(JoiningRoomID, false);

const JoiningRoom = () => {
  useEffect(() => {
    newNotificationThunk(JoiningRoomID, false)();
  }, []);
  const state = useSelector(getGetNotificationState(JoiningRoomID));
  return (
    <NotificationToast
      message={'Joining Room'}
      show={state}
      icons={<div className="loader"></div>}
      width={220}
      onClose={() => {}}
      lower={true}
      id={JoiningRoomID}
    />
  );
};

const RejoiningRoomID = 'rejoining-room';
export const showRejoiningRoom = newNotificationThunk(RejoiningRoomID, true);
export const hideRejoiningRoom = newNotificationThunk(RejoiningRoomID, false);

const RejoiningRoom = () => {
  useEffect(() => {
    newNotificationThunk(RejoiningRoomID, false)();
  }, []);
  const state = useSelector(getGetNotificationState(RejoiningRoomID));
  return (
    <NotificationToast
      message={'You have been disconnected. Rejoining Room'}
      show={state}
      icons={<div className="loader"></div>}
      width={400}
      onClose={() => {}}
      lower={true}
      id={RejoiningRoomID}
    />
  );
};

const ScreenShare = 'screenshare';
export const showSS = newNotificationThunk(ScreenShare, true);
export const hideSS = newNotificationThunk(ScreenShare, false);

const ScreenShareNotification = () => {
  useEffect(() => {
    newNotificationThunk(ScreenShare, false)();
  }, []);
  const state = useSelector(getGetNotificationState(ScreenShare));
  useEffect(() => {
    if (state) {
      setTimeout(() => hideSS(), 10000);
    }
  }, [state]);
  return (
    <NotificationToast
      message={`You can turn on upto ${maxPlayableScreenshares} screens at a time. Press 'v' to hide all screens.`}
      show={state}
      icons={<></>}
      width={300}
      onClose={() => {}}
      lower={true}
      persistent={true}
      id={ScreenShare}
    />
  );
};

const ScreenshareClosedNotification = 'screenshare-closed-notification';
export const showSSClosed = newNotificationThunk(ScreenshareClosedNotification, true);
export const hideSSClosed = newNotificationThunk(ScreenshareClosedNotification, false);

const ScreenshareClosedNoti = () => {
  useEffect(() => {
    newNotificationThunk(ScreenshareClosedNotification, false)();
  }, []);
  const state = useSelector(getGetNotificationState(ScreenshareClosedNotification));
  useEffect(() => {
    if (state) {
      setTimeout(() => hideSSClosed(), 10000);
    }
  }, [state]);
  return (
    <NotificationToast
      message={`We hide all screens when there are more than ${maxPlayableScreenshares} of them.
      Click on '👁' to view up to ${maxPlayableScreenshares} screens of your choice.`}
      show={state}
      icons={
        <span className="material-icons" style={{ fontSize: '20px' }}>
          warning
        </span>
      }
      width={450}
      onClose={() => {}}
      lower={true}
      persistent={false}
      id={ScreenshareClosedNotification}
    />
  );
};

const changingUserRole = 'changing-user-role';
export const showChangingUserRole = newNotificationThunk(changingUserRole, true);
export const hideChangingUserRole = newNotificationThunk(changingUserRole, false);

const ChangingUserRoleNotification = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  useEffect(() => {
    newNotificationThunk(changingUserRole, false)();
  }, []);
  const state = useSelector(getGetNotificationState(changingUserRole));
  let roleString: 'Audience' | 'Speaker' | 'Host' = 'Audience';
  if (spacesRooms.currentUserRole === 'h') roleString = 'Host';
  else if (spacesRooms.currentUserRole === 's') roleString = 'Speaker';
  return (
    <NotificationToast
      message={'Changing Role to ' + roleString}
      show={state}
      icons={<div className="loader"></div>}
      width={220}
      onClose={() => {}}
      lower={false}
      id={changingUserRole}
    />
  );
};

const changedUserRole = 'changed-user-role';
export const showChangedUserRole = newNotificationThunk(changedUserRole, true);
export const hideChangedUserRole = newNotificationThunk(changedUserRole, false);

const ChangedUserRoleNotification = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  useEffect(() => {
    newNotificationThunk(changedUserRole, false)();
  }, []);
  const state = useSelector(getGetNotificationState(changedUserRole));
  let roleString: 'Audience' | 'Speaker' | 'Host' = 'Audience';
  if (spacesRooms.currentUserRole === 'h' || spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin)
    roleString = 'Host';
  else if (spacesRooms.currentUserRole === 's') roleString = 'Speaker';
  return (
    <NotificationToast
      message={'Changed Role to ' + roleString}
      show={state}
      icons={<span className="material-icons">done</span>}
      width={220}
      onClose={() => {}}
      lower={false}
      id={changedUserRole}
    />
  );
};

const changingUserType = 'changing-user-type';
export const showChangingUserType = newNotificationThunk(changingUserType, true);
export const hideChangingUserType = newNotificationThunk(changingUserType, false);

const ChangingUserTypeNotification = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  useEffect(() => {
    newNotificationThunk(changingUserType, false)();
  }, []);
  const state = useSelector(getGetNotificationState(changingUserType));
  return (
    <NotificationToast
      message={
        'Changing Type to ' + (spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin ? 'Admin' : 'Team Member')
      }
      show={state}
      icons={<div className="loader"></div>}
      width={220}
      onClose={() => {}}
      lower={false}
      id={changingUserType}
    />
  );
};

const changedUserType = 'changed-user-type';
export const showChangedUserType = newNotificationThunk(changedUserType, true);
export const hideChangedUserType = newNotificationThunk(changedUserType, false);

const ChangedUserTypeNotification = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  useEffect(() => {
    newNotificationThunk(changedUserType, false)();
  }, []);
  const state = useSelector(getGetNotificationState(changedUserType));
  return (
    <NotificationToast
      message={
        'Changed Type to ' + (spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin ? 'Admin' : 'Team Member')
      }
      show={state}
      icons={<span className="material-icons">done</span>}
      width={220}
      onClose={() => {}}
      lower={false}
      id={changedUserType}
    />
  );
};

const overflowStreamLoading = 'overflow-stream-loading';
export const showOverflowStreamLoading = newNotificationThunk(overflowStreamLoading, true);
export const hideOverflowStreamLoading = newNotificationThunk(overflowStreamLoading, false);

const OverflowStreamLoadingNotification = () => {
  useEffect(() => {
    newNotificationThunk(overflowStreamLoading, false)();
  }, []);
  const state = useSelector(getGetNotificationState(overflowStreamLoading));
  return (
    <NotificationToast
      message={'Sharing your screen as background'}
      show={state}
      icons={<div className="loader"></div>}
      width={220}
      onClose={() => {}}
      lower={false}
      id={overflowStreamLoading}
    />
  );
};

const Notifications = () => {
  return (
    <>
      <NetworkWarningNotification />
      <JoiningRoom />
      <RejoiningRoom />
      <ScreenShareNotification />
      <ScreenshareClosedNoti />
      <ChangingUserRoleNotification />
      <ChangedUserRoleNotification />
      <ChangingUserTypeNotification />
      <ChangedUserTypeNotification />
      <OverflowStreamLoadingNotification />
    </>
  );
};

export default Notifications;
