import './loaderRing.scss';

const LoaderRing = (props: { size?: string; color?: string } | undefined) => {
  return (
    <>
      <div
        className="loaderRingCont"
        style={{
          height: props && props.size ? props.size : '65px',
          width: props && props.size ? props.size : '65px',
        }}
      >
        <svg viewBox="0 0 100 100">
          <defs>
            <filter id="shadow">
              <feDropShadow
                dx="0"
                dy="0"
                stdDeviation="1.5"
                flood-color={props && props.color ? props.color : '#ffffff'}
              />
            </filter>
          </defs>
          <circle
            id="spinner"
            style={{
              fill: 'transparent',
              stroke: props && props.color ? props.color : '#ffffff',
              strokeWidth: '7px',
              strokeLinecap: 'round',
              filter: 'url(#shadow)',
            }}
            cx="50"
            cy="50"
            r="45"
          />
        </svg>
      </div>
    </>
  );
};

export default LoaderRing;
