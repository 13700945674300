import React, { useEffect, useState } from 'react';
import './toggleButtonV2.scss';

const ToggleButtonV2 = (props: { val: boolean; name: string; onChange: (e: boolean) => void }) => {
  const [state, setState] = useState(props.val);

  useEffect(() => {
    setState(props.val);
  }, [props.val]);

  return (
    <>
      <div className="toggle-input-button">
        <input
          id={props.name}
          checked={state}
          type="checkbox"
          onClick={() => {
            setState((state) => {
              props.onChange(!state);
              return !state;
            });
          }}
          readOnly={true}
        />
        <label htmlFor={props.name}></label>
      </div>
    </>
  );
};

export default ToggleButtonV2;
