import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import GoogleLogo from '../../assets/svg/googleLogo';
import { sendAmplitudeData } from '../../helper/amplitude';
import { initSignInWithGoogle, resetPassword } from '../../helper/firebase/auth';
import { signinHelper } from '../../helper/signin';
import { getAuth } from '../../store/auth';
import FloatInput from '../floatInput/floatInput';
import './signin.scss';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [state, setState_] = useState<{ message: string; user: boolean | null; success: boolean }>({
    message: '',
    user: null,
    success: false,
  });
  const history = useHistory();
  const auth = useSelector(getAuth);
  const [resetSent, setResetSent] = useState(false);

  useEffect(() => {
    if (state.user === false) {
      history.push('/confirmEmail', { email: email });
    }
    if (state.user) {
      if (auth.redirectTo) history.push(auth.redirectTo);
      else history.push('/');
    }
  }, [state]);

  const onSignin = async () => {
    const state = await signinHelper(email, password);
    if (state.success) {
      sendAmplitudeData('Signed in', { state, status: 'success' });
    } else {
      sendAmplitudeData('Signed in', { state, status: 'failed' });
    }
    setState_({ ...state });
  };

  return (
    <>
      <div className="sign-in-container">
        <div className="sign-in-form-section">
          <div className="sign-in-form-items">
            <div className="sign-in-form-header">
              <div className="sign-in-form-header-title">
                <span>Sign in</span>
              </div>
              <div className="sign-in-form-header-subtitle">
                <span>Enter your details to get in</span>
              </div>
            </div>

            <div className="sign-in-form-field">
              <FloatInput
                type={'text'}
                label={'Email Address'}
                onChangeInput={(inputText) => {
                  setEmail(inputText);
                  setState_({ message: '', user: null, success: false });
                }}
              />
            </div>
            <div className="sign-in-form-field">
              <FloatInput
                type={'password'}
                label={'Password'}
                onChangeInput={(inputText) => {
                  setPassword(inputText);
                  setState_({ message: '', user: null, success: false });
                }}
                showHide={true}
              />
            </div>

            {state.message.length > 0 && (
              <div className="sign-in-form-error-state">
                <span>
                  {state.message}
                  {email.length > 3 && (
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        if (email.length > 3) resetPassword(email);
                        setResetSent(true);
                      }}
                    >
                      {!resetSent ? 'Forgot password?' : 'Reset link sent! Send again?'}
                    </span>
                  )}
                </span>
              </div>
            )}
          </div>

          <div className="sign-in-form-action">
            <div
              className="sign-in-form-button"
              onClick={() => {
                sendAmplitudeData('Sign in clicked', {});
                onSignin();
              }}
            >
              <span>Continue</span>
            </div>
            <div className="sign-in-msg-section">
              <span>
                Haven't signed up?{' '}
                <a
                  href="#"
                  onClick={() => {
                    history.push('/signup');
                  }}
                >
                  Do it here.
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="sign-in-sso-section">
          <div
            className="sso-button google"
            onClick={() => {
              initSignInWithGoogle();
            }}
          >
            <GoogleLogo size={24} />
            <span style={{ paddingLeft: '14px' }}>Sign in with Google</span>
          </div>
          {/* <div className="sso-button apple">
            <AppleLogo size={24} />
            <span style={{ paddingLeft: '14px' }}>Sign in with Apple</span>
          </div>
          <div className="sso-button facebook">
            <Facebook size={24} />
            <span style={{ paddingLeft: '14px' }}>Sign in with Facebook</span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SignIn;
