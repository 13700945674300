import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useAsyncEffect from 'use-async-effect';
import { closeModalThunk, newModalThunk, openModalThunk, toggleSidebarStore } from '../../../../store';
import { getAuth } from '../../../../store/auth';
import { getDailyJoinedState, getLocalState } from '../../../../store/dailycoParticipants';
import { getGetModalState } from '../../../../store/modal';
import { getPresenceOfRoom } from '../../../../store/presence';
import { getCurrentRoomCopyRedux, getCurrentRoomRedux, getSpacesRooms } from '../../../../store/spacesRooms';
import Avatar from '../../../avatars/avatar';
import DropDown from '../../../dropdown/dropdown';
import { openNoPermission } from '../../../noPermissionModal/noPermissionModal';
import { onboardingID } from '../../../productOnboarding/productOnboarding';
import JoinRoomModal from '../../templateModals/joinRoomModal';
import './joinRoom.scss';

// const VideoDeviceOptions = ['Default Camera', 'Logitech Webcam 2.0', 'Bobby Makhana'];
// const AudioDeviceOptions = ['Default Mic', 'Realtek Media 2.0 K', 'Falana Dimkana'];

export const joinNowModalID = 'joinnow-modal';
export const openJoinNow = openModalThunk(joinNowModalID);
export const closeJoinNow = closeModalThunk(joinNowModalID);

const MicIcon = (props: { on: boolean; onClick: () => void }) => {
  if (props.on)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-mic-fill green"
        viewBox="0 0 16 16"
        onClick={props.onClick}
      >
        <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
        <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
      </svg>
    );
  else
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-mic-mute-fill"
        viewBox="0 0 16 16"
        onClick={props.onClick}
      >
        <path d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z" />
        <path d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z" />
      </svg>
    );
};

const VidIcon = (props: { on: boolean; onClick: () => void }) => {
  if (props.on)
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-camera-video-fill green"
        viewBox="0 0 16 16"
        onClick={props.onClick}
      >
        <path
          fill-rule="evenodd"
          d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"
        />
      </svg>
    );
  else
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-camera-video-off-fill"
        viewBox="0 0 16 16"
        onClick={props.onClick}
      >
        <path
          fill-rule="evenodd"
          d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925-10-14 .814-.58 10 14-.814.58z"
        />
      </svg>
    );
};

const formatLong = (text: string) => {
  if (text.length < 30) return text;
  else return text.slice(0, 30) + '...';
};

const VideoPreview = (props: { stream: MediaStream | null }) => {
  const previewRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (previewRef.current && props.stream) previewRef.current.srcObject = props.stream;
  }, [props.stream, previewRef]);
  return <video className="profile-pic circle-vid preview" id="preview" ref={previewRef} playsInline autoPlay muted />;
};

const JoinRoomBody = (props: {
  onSettingsChange: (settings: {
    camDisabled: boolean;
    micDisabled: boolean;
    camOn: boolean;
    micOn: boolean;
    cameraID: string | undefined;
    micID: string | undefined;
    loading: boolean;
  }) => void;
}) => {
  const joinNowState = useSelector(getGetModalState(joinNowModalID));
  const localDailyParticipant = useSelector(getLocalState);
  const spacesRooms = useSelector(getSpacesRooms);
  // const [videoHeaderTitle, setVideoHeaderTitle] = useState(VideoDeviceOptions[0]);
  // const [audioHeaderTitle, setAudioHeaderTitle] = useState(AudioDeviceOptions[0]);
  const auth = useSelector(getAuth);
  const [vidDevices, setVidDevices] = useState<{ [id: string]: string }>({});
  const [audDevices, setAudDevices] = useState<{ [id: string]: string }>({});
  const cameraSaved = localStorage.getItem('vidID');
  const micSaved = localStorage.getItem('micID');
  const [vidID, setVidID] = useState<string | undefined>(cameraSaved ? cameraSaved : undefined);
  const [micID, setMicID] = useState<string | undefined>(micSaved ? micSaved : undefined);
  const [audOn, setAudOn] = useState<boolean>(true);
  const [vidOn, setVidOn] = useState<boolean>(true);
  const [vidDisabled, setVidDisabled] = useState(false);
  const [audDisabled, setAudDisabled] = useState(false);
  const [vidRoomPermission, setVidRoomPermission] = useState(true);
  const [micRoomPermission, setMicRoomPermission] = useState(true);

  const stream = useRef<MediaStream | null>(null);
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState('');

  useEffect(() => {
    setVidRoomPermission(spacesRooms.permissionV2[spacesRooms.currentUserRole]?.cam === true);
    setMicRoomPermission(spacesRooms.permissionV2[spacesRooms.currentUserRole]?.mic === true);
  }, [spacesRooms.permissionV2, spacesRooms.currentUserRole]);

  async function getCam(video: boolean, audio: boolean) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video, audio });
      stream.getTracks().forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    } catch {}
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      setVidDevices({});
      setAudDevices({});
      for (const i in devices) {
        if (devices[i].kind === 'videoinput') {
          setVidID((vidID) => {
            if (!vidID) vidID = devices[i].deviceId;
            return vidID;
          });
          // setVidDevices((vidDevices) => [...vidDevices, devices[i]]);
          setVidDevices((vidDevices) => {
            return {
              ...vidDevices,
              [devices[i].deviceId]: devices[i].label,
            };
          });
        }
        if (devices[i].kind === 'audioinput') {
          setMicID((micID) => {
            if (!micID) micID = devices[i].deviceId;
            return micID;
          });
          setAudDevices((audDevices) => {
            return {
              ...audDevices,
              [devices[i].deviceId]: devices[i].label,
            };
          });
        }
      }
    } catch {}
  }
  useEffect(() => {
    getCam(true, false);
    getCam(false, true);
  }, []);

  useAsyncEffect(async () => {
    if (_.keys(spacesRooms.permissionV2).length === 0) return;
    const cameraAndMic = {
      audio: audOn ? { deviceId: micID } : false,
      video: vidOn ? { deviceId: vidID } : false,
    };
    const micOnly = {
      audio: audOn ? { deviceId: micID } : false,
      video: false,
    };
    const cameraOnly = {
      audio: false,
      video: vidOn ? { deviceId: vidID } : false,
    };
    const accessCamMic = async (level: MediaStreamConstraints) => {
      await getCam(true, true);
      const flag = localStorage.getItem('vidID') && localStorage.getItem('micID');
      // @ts-ignore
      if (
        flag &&
        // @ts-ignore
        (level.audio === undefined || level.audio.deviceId === undefined) &&
        // @ts-ignore
        (level.video === undefined || level.video.deviceId === undefined)
      ) {
        return;
      }
      if (joinNowState) {
        if (level.audio || level.video) {
          stream.current?.getTracks().forEach((track) => {
            track.enabled = false;
            track.stop();
          });
          if (
            _.keys(audDevices).length > 0 ||
            _.keys(vidDevices).length > 0 ||
            (vidID && typeof level.video === 'object' && vidID === level.video.deviceId)
          ) {
            stream.current = await navigator.mediaDevices.getUserMedia(level);
          } else {
          }
          // @ts-ignore
          // document.getElementById('preview').srcObject = stream;
        }
      } else {
        stream.current?.getTracks().forEach((track) => {
          track.enabled = false;
          track.stop();
        });
      }
    };
    try {
      setLoading(true);
      await accessCamMic(cameraAndMic);
      setLoading(false);
      setVidDisabled(false);
      setAudDisabled(false);
      setWarning('');
    } catch (e) {
      try {
        setLoading(true);
        await getCam(false, true);
        await accessCamMic(micOnly);
        setLoading(false);
        setVidDisabled(true);
        setAudDisabled(false);
        setWarning("Your camera is in use by another app or we don't have permissions.");
      } catch (e) {
        try {
          setLoading(true);
          await getCam(true, false);
          await accessCamMic(cameraOnly);
          setLoading(false);
          setAudDisabled(true);
          setVidDisabled(false);
          setWarning("Your mic is in use by another app or we don't have permissions.");
        } catch (e) {
          setVidDisabled(true);
          setAudDisabled(true);
          setLoading(false);
          setWarning("Your camera and mic is in use by another app or we don't have permissions.");
        }
      }
    }
    return () => {
      const tracks = stream.current?.getTracks();
      tracks?.forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    };
  }, [micID, vidID, audOn, vidOn, joinNowState, spacesRooms.permissionV2]);

  useEffect(() => {
    props.onSettingsChange({
      camDisabled: vidDisabled,
      micDisabled: audDisabled,
      camOn: vidOn && vidRoomPermission,
      micOn: audOn && micRoomPermission,
      cameraID: vidID,
      micID,
      loading,
    });
  }, [micID, vidID, audOn, vidOn, audDisabled, vidDisabled, vidRoomPermission, micRoomPermission, loading]);

  return (
    <div className="join-room-container">
      <div className="permissions">
        <div className="camera-circle">
          <div className="image-cropper">
            {(!vidOn || vidDisabled || !vidRoomPermission) && (
              <Avatar src={auth.userDetails.profilePic} name={auth.userDetails.name} />
            )}
            {vidOn && !vidDisabled && vidRoomPermission && <VideoPreview stream={stream.current} />}
          </div>
          {!localDailyParticipant.joined && (
            <div className="camControl">
              <MicIcon
                on={!audDisabled && audOn && micRoomPermission}
                onClick={() => {
                  if (loading) return;
                  setLoading(true);
                  setAudOn(!audOn);
                }}
              />
              <VidIcon
                on={!vidDisabled && vidOn && vidRoomPermission}
                onClick={() => {
                  if (loading) return;
                  setLoading(true);
                  setVidOn(!vidOn);
                }}
              />
            </div>
          )}
        </div>
        <div className="permission-dropdown-section">
          <div className="permission-item video">
            <div className="label">
              <label>Video</label>
            </div>
            <div className="input-dropdown">
              <DropDown
                headerTitle={vidID && vidDevices[vidID] ? formatLong(vidDevices[vidID]) : 'Select Camera'}
                styles={{
                  dropDownHeader: { paddingLeft: '8px', paddingRight: '8px', fontSize: '14px' },
                  listOuter: {
                    backgroundColor: '#393B41',
                    borderRadius: '8px',
                    border: '1px solid #000000',
                    top: '42px',
                  },
                  listItems: {
                    paddingLeft: '8px',
                    overflow: 'hidden',
                    paddingBottom: '6px',
                    paddingTop: '6px',
                    fontSize: '14px',
                  },
                }}
                showStatusColor={false}
                options={vidDevices}
                optionsListType="array"
                isCamelcase={false}
                isItemDict={true}
                onSelectItem={(e: any, key: any) => {
                  if (loading) return;
                  // setVideoHeaderTitle(e.header);
                  localStorage.setItem('vidID', key);
                  setVidID(key);
                }}
              />
            </div>
          </div>

          <div className="permission-item audio">
            <div className="label">
              <label>Audio</label>
            </div>
            <div className="input-dropdown">
              <DropDown
                headerTitle={micID && audDevices[micID] ? formatLong(audDevices[micID]) : 'Select Mic'}
                styles={{
                  dropDownHeader: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    fontSize: '14px',
                    // width: '50%',
                    // whiteSpace: 'nowrap',
                    // overflow: 'hidden',
                    // textOverflow: 'ellipsis',
                  },
                  listOuter: {
                    backgroundColor: '#393B41',
                    borderRadius: '8px',
                    border: '1px solid #000000',
                    top: '42px',
                  },
                  listItems: {
                    paddingLeft: '8px',
                    overflow: 'hidden',
                    paddingBottom: '6px',
                    paddingTop: '6px',
                    fontSize: '14px',
                  },
                }}
                showStatusColor={false}
                options={audDevices}
                optionsListType="array"
                isCamelcase={false}
                isItemDict={true}
                onSelectItem={(e: any, key: string) => {
                  if (loading) return;
                  // setAudioHeaderTitle(e.header);
                  localStorage.setItem('micID', key);
                  setMicID(key);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="warning" onClick={() => openNoPermission()}>
        {warning}
      </div>
    </div>
  );
};

const JoinRoomNew = (props: {
  joinCall: (
    camDisabled: boolean,
    micDisabled: boolean,
    camOn: boolean,
    micOn: boolean,
    cameraID: string | undefined,
    micID: string | undefined
  ) => void;
}) => {
  const joinNowState = useSelector(getGetModalState(joinNowModalID));
  const onboardingState = useSelector(getGetModalState(onboardingID));
  const localDailyParticipant = useSelector(getLocalState);
  const onboardingFlag = localStorage.getItem('onboarding');
  const currentRoom = useSelector(getCurrentRoomRedux);
  const presenceRoom = useSelector(getPresenceOfRoom(currentRoom));
  const [isFull, setIsFull] = useState(false);
  const roomCopy = useSelector(getCurrentRoomCopyRedux);
  const spacesRooms = useSelector(getSpacesRooms);
  const presenceOfRoom = useSelector(getPresenceOfRoom(spacesRooms.currentRoom));
  const isJoined = useSelector(getDailyJoinedState);
  const [loading, setLoading] = useState(false);
  const [setRoomCopyFlag, setSetRoomCopyFlag] = useState(false);

  useEffect(() => {
    setSetRoomCopyFlag(false);
  }, [spacesRooms.currentRoom]);

  const [settings, setSettings] = useState<{
    camDisabled: boolean;
    micDisabled: boolean;
    camOn: boolean;
    micOn: boolean;
    cameraID: string | undefined;
    micID: string | undefined;
    loading: boolean;
  }>({
    camDisabled: false,
    micDisabled: false,
    camOn: true,
    micOn: true,
    cameraID: undefined,
    micID: undefined,
    loading: true,
  });

  useEffect(() => {
    newModalThunk(joinNowModalID, false)();
  }, []);

  return (
    <JoinRoomModal
      isOpen={joinNowState && !(onboardingState || onboardingFlag === 'no')}
      showBack={false}
      header="Voice and video settings"
      footerActionButtonLeft={localDailyParticipant.joined ? 'Cancel' : isFull ? 'This room is full.' : null}
      setActionLeft={() => {
        if (localDailyParticipant.joined) closeJoinNow();
      }}
      buttonSize={localDailyParticipant.joined ? '125px' : isFull ? '200px' : '125px'}
      setSettings={setSettings}
      footerActionButtonRight={
        settings.loading || loading
          ? null
          : localDailyParticipant.joined
          ? 'Save'
          : isFull
          ? 'Select another room'
          : 'Join Room'
      }
      setActionRight={() => {
        if (settings.loading) return;
        if (isFull && !localDailyParticipant.joined) {
          toggleSidebarStore(true);
        } else if (!localDailyParticipant.joined) {
          props.joinCall(
            settings.camDisabled,
            settings.micDisabled,
            settings.camOn,
            settings.micOn,
            settings.cameraID,
            settings.micID
          );
        } else {
          localDailyParticipant.changeMicCamera(settings.cameraID, settings.micID);
          closeJoinNow();
        }
      }}
    >
      <JoinRoomBody onSettingsChange={setSettings} />
    </JoinRoomModal>
  );
};

export default JoinRoomNew;
