import firebase from 'firebase';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { MoreHorizontal, Search } from 'react-feather';
import { useSelector } from 'react-redux';
import { useOutsideClickDD } from '../../components/dropdown/dropdown';
import LoaderV2 from '../../components/loader/loaderV2';
import PresenceV2 from '../../components/presenceV2/presenceV2';
import { openConfirmAction } from '../../components/roomSettings/confirmAction';
import { sendAmplitudeData } from '../../helper/amplitude';
import { getClaimToken, getToken } from '../../helper/firebase/auth';
import { isSubDomain } from '../../helper/interactive/misc';
import spaces from '../../helper/requests/spaces';
import { setCurrentSpace } from '../../store';
import { getAuth } from '../../store/auth';
import { getPresenceAll } from '../../store/presence';
import { getSpacesRooms, spaceType } from '../../store/spacesRooms';
import './spaceMembers.scss';

const Member = (props: {
  userID: string;
  spaceID: string;
  name: string;
  email: string;
  userType: string;
  presence: boolean;
  self: boolean;
}) => {
  const [userType, setUserType] = useState({ type: props.userType, color: '#34e3b9' });
  const [optionsOpen, setOptionsOpen] = useState(false);
  const moreRef = useRef<HTMLDivElement | null>(null);

  useOutsideClickDD(moreRef, () => {
    setOptionsOpen(false);
  });

  const [loading, setLoading] = useState(false);

  const getUserRole = (role: any) => {
    if (role === 'Space Owner') return 'owner';
    else if (role === 'Team Member') return 'teammember';
    else if (role === 'Guest') return 'guest';
    return 'guest';
  };

  useEffect(() => {
    setUserType((userType) => {
      userType.type = props.userType;
      return userType;
    });
  }, [props.userType]);

  return (
    <>
      <div className="member-block">
        <div className="left-side">
          <div className="name-part">
            <div
              className="presence-bubble"
              style={props.presence ? { backgroundColor: '#08A53D' } : { backgroundColor: 'rgba(55, 57, 62, 0.7)' }}
            ></div>
            <div className="name">{props.name}</div>
          </div>
          <div className="email-part">{props.email.toLowerCase()}</div>
        </div>
        <div className="right-side">
          <div className="permission-type" style={{ color: userType.color }}>
            {!loading ? (
              <span>{userType.type}</span>
            ) : (
              <>
                <span>Updating </span>
                <div className="loaderV2Container">
                  <LoaderV2 />
                </div>
              </>
            )}
          </div>

          {!props.self && (
            <div className="permission-dropdown">
              <MoreHorizontal size={24} onClick={() => setOptionsOpen(!optionsOpen)} id={props.userID + '_more'} />
              <div className="memberOption" style={{ display: optionsOpen ? 'block' : 'none' }} ref={moreRef}>
                {props.userType !== 'Space Owner' && (
                  <div
                    className="permission-option"
                    onClick={async () => {
                      const token = await getToken();
                      if (token) {
                        setLoading(true);
                        setOptionsOpen(!optionsOpen);
                        let response = await spaces.updatePermission(
                          token,
                          'owner',
                          props.spaceID,
                          props.userID,
                          false
                        );
                        if (response.status !== 200) {
                          setUserType({ type: 'Failed to update', color: 'red' });
                          sendAmplitudeData('Space Member control changed', {
                            type: 'make space owner',
                            status: 'failed',
                          });
                        } else {
                          setUserType({ type: 'Space Owner', color: userType.color });
                          sendAmplitudeData('Space Member control changed', {
                            type: 'make space owner',
                            status: 'success',
                          });
                        }
                        setLoading(false);
                      }
                    }}
                  >
                    Make Space Owner
                  </div>
                )}
                {props.userType !== 'Team Member' && (
                  <div
                    className="permission-option"
                    onClick={async () => {
                      const token = await getToken();
                      if (token) {
                        setLoading(true);
                        setOptionsOpen(!optionsOpen);
                        let response = await spaces.updatePermission(
                          token,
                          'teammember',
                          props.spaceID,
                          props.userID,
                          false
                        );
                        if (response.status !== 200) {
                          setUserType({ type: 'Failed to update', color: 'red' });
                          sendAmplitudeData('Space Member control changed', {
                            type: 'make team member',
                            status: 'failed',
                          });
                        } else {
                          setUserType({ type: 'Team Member', color: userType.color });
                          sendAmplitudeData('Space Member control changed', {
                            type: 'make team member',
                            status: 'success',
                          });
                        }
                        setLoading(false);
                      }
                    }}
                  >
                    Make Team Member
                  </div>
                )}
                {props.userType !== 'Guest' && (
                  <div
                    className="permission-option"
                    onClick={async () => {
                      const token = await getToken();
                      if (token) {
                        setLoading(true);
                        setOptionsOpen(!optionsOpen);
                        let response = await spaces.updatePermission(
                          token,
                          'guest',
                          props.spaceID,
                          props.userID,
                          false
                        );
                        if (response.status !== 200) {
                          setUserType({ type: 'Failed to update', color: 'red' });
                          sendAmplitudeData('Space Member control changed', {
                            type: 'make guest',
                            status: 'failed',
                          });
                        } else {
                          setUserType({ type: 'Guest', color: userType.color });
                          sendAmplitudeData('Space Member control changed', {
                            type: 'make guest',
                            status: 'success',
                          });
                        }
                        setLoading(false);
                      }
                    }}
                  >
                    Make Guest
                  </div>
                )}

                {props.userType !== 'undefined' ? (
                  <div
                    className="permission-option"
                    onClick={() => {
                      openConfirmAction(
                        async () => {
                          const token = await getToken();
                          if (token) {
                            setLoading(true);
                            setOptionsOpen(!optionsOpen);
                            let response = await spaces.updatePermission(
                              token,
                              getUserRole(props.userType),
                              props.spaceID,
                              props.userID,
                              true
                            );
                            if (response.status !== 200) {
                              setUserType({ type: 'Failed to delete', color: 'red' });
                              sendAmplitudeData('Space Member control changed', {
                                type: 'delete member',
                                status: 'failed',
                              });
                            } else {
                              sendAmplitudeData('Space Member control changed', {
                                type: 'delete member',
                                status: 'success',
                              });
                            }
                            // else setUserType({ type: 'Guest', color: userType.color });
                            setLoading(false);
                          }
                        },
                        () => {}
                      )();
                    }}
                  >
                    <span style={{ color: 'red' }}>Delete</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const SpaceMembersV2 = (props: { spaceID: string }) => {
  const spacesRooms = useSelector(getSpacesRooms);
  const auth = useSelector(getAuth);
  const membersRef = useRef<HTMLDivElement | null>(null);
  const [members, setMembers] = useState<{
    [id: string]: spaceType;
  }>({});
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);

  const searchRef = useRef('');
  const [toShow, setToShow] = useState<typeof members>({});
  const [till, setTill] = useState(10);

  const spacePresence = useSelector(getPresenceAll);
  const [userPresence, setUserPresence] = useState<{
    [id: string]: { online: boolean };
  }>({});

  useEffect(() => {
    const presence: {
      [id: string]: { online: boolean };
    } = {};

    for (var room in spacePresence) {
      for (var user in spacePresence[room]) {
        let userInfo = spacePresence[room][user];
        if (userInfo.online) {
          presence[user] = { online: true };
        }
      }
    }
    setUserPresence(presence);
  }, [spacePresence]);

  useEffect(() => {
    filterByNameOrEmail(members);
  }, [members]);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  useEffect(() => {
    (async () => {
      let token = await getClaimToken();
      if (token) {
        if (!isSubDomain()) {
          const spaceid = (await spaces.getID(props.spaceID, token.token)).data.id;
          await spaces.setSpaceCustomClaim(spaceid, token.token);
          setCurrentSpace(spaceid);
        } else {
          const spaceid = (await spaces.getIDFromSubdomain(props.spaceID, token.token)).data.id;
          await spaces.setSpaceCustomClaim(spaceid, token.token);
          setCurrentSpace(spaceid);
        }
      }
    })();
  }, [props.spaceID]);

  useEffect(() => {
    getClaimToken().then((token) => console.log(token));
    let unsub = () => {};
    setLoading(true);

    if (spacesRooms.currentSpace && spacesRooms.currentSpace.length > 2) {
      unsub = firebase
        .firestore()
        .collection('spacePermissionV2')
        .where('spaceID', '==', spacesRooms.currentSpace)
        .where('isDeleted', '==', false)
        .onSnapshot((docs) => {
          const membersNew: typeof members = {};
          docs.docs.map((doc) => {
            const space: spaceType = doc.data() as spaceType;
            membersNew[space.userID] = space;
          });

          setMembers(membersNew);
          if (!docs.metadata.fromCache) {
            setLoading(false);
          }

          //   setLoading(false);
        });
      return () => unsub();
    }
  }, [spacesRooms.currentSpace]);

  const filterByNameOrEmail = (memberDict: typeof members) => {
    const search = searchRef.current.toLowerCase();
    if (search.length < 1) setToShow(memberDict);
    const memberList = _.values(memberDict);
    const nameList = _.filter(memberList, (member) => {
      return member.userInfo.name.toLowerCase().includes(search);
    });

    const emailList = _.filter(memberList, (member) => {
      return member.userInfo.email.toLowerCase().includes(search);
    });

    const result = [...nameList, ...emailList];
    const resultDict: typeof members = {};
    for (let i in result) {
      resultDict[result[i].userID] = result[i];
    }
    setToShow(resultDict);
  };

  return (
    <>
      <PresenceV2 />
      <div className="space-members-container">
        <div className="search-bar">
          <input
            placeholder="Search"
            onChange={(e) => {
              const str = e.target.value;
              setSearch(str);
              if (str.length < 1) filterByNameOrEmail(members);
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                filterByNameOrEmail(members);
              }
            }}
          />
          <Search
            onClick={() => {
              filterByNameOrEmail(members);
            }}
          />
        </div>

        <div className="members-list">
          {loading && (
            <div className="loaderV2Container">
              <LoaderV2 />
            </div>
          )}
          {!loading && (
            <div
              className="members"
              ref={membersRef}
              onScroll={() => {
                if (membersRef.current) {
                  if (membersRef.current.scrollHeight - membersRef.current.scrollTop >= membersRef.current.clientHeight)
                    setTill((till) => till + 10);
                }
              }}
            >
              {_.map(_.keys(toShow).slice(0, till), (key) => {
                const member = members[key];
                if (!member) return <></>;
                let type: 'Space Owner' | 'Guest' | 'Team Member' = 'Guest';
                if (member?.permission?.isOwner) type = 'Space Owner';
                else if (member?.permission?.isTeamMember) type = 'Team Member';
                else type = 'Guest';

                let presence = userPresence[member.userID] ? userPresence[member.userID].online : false;

                return (
                  <>
                    <Member
                      userID={member.userID}
                      spaceID={member.spaceID}
                      name={member.userInfo.name}
                      email={member.userInfo.email}
                      userType={type}
                      key={key}
                      presence={presence}
                      self={member.userID === auth.user.id}
                    />
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SpaceMembersV2;
