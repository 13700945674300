import React from 'react';
import Modal from '../components/modal';
import ModalBody from '../components/modalBody';
import ModalFooter from '../components/modalFooter';
import ModalHeader from '../components/modalHeader';

const OnboardingModal = (props: any) => {
  return (
    <Modal isOpen={props.isOpen} className="onboarding-modal" style={{ width: '558px', height: '598px' }}>
      <ModalHeader showBack={false} header={props.header} onClose={props.setActionOnClose}></ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter
        footerActionButtonLeft={props.footerActionButtonLeft ? props.footerActionButtonLeft : null}
        onActionLeft={props.setActionLeft ? props.setActionLeft : null}
        footerActionButtonRight={props.footerActionButtonRight}
        onActionRight={props.setActionRight}
        buttonSize="112px"
      ></ModalFooter>
    </Modal>
  );
};

export default OnboardingModal;
