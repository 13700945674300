import { configureStore, createSlice } from '@reduxjs/toolkit';

export type DeviceSetting = {
  camDisabled: boolean;
  micDisabled: boolean;
  camOn: boolean;
  micOn: boolean;
  cameraID: string;
  micID: string;
};

export type DeviceSettingAction = {
  camDisabled?: boolean;
  micDisabled?: boolean;
  camOn?: boolean;
  micOn?: boolean;
  cameraID?: string;
  micID?: string;
};

export type SetDeviceSettingAction = {
  payload: DeviceSettingAction;
};

const defaultDeviceSetting = {
  camDisabled: false,
  micDisabled: false,
  camOn: false,
  micOn: false,
  cameraID: '',
  micID: '',
};

const saveDeviceSettingToLocalStorage = (deviceSetting: DeviceSetting) => {
  return localStorage.setItem('deviceSetting', JSON.stringify(deviceSetting));
};

const getDeviceSettingFromLocalStorage = (): DeviceSetting => {
  return JSON.parse(localStorage.getItem('deviceSetting') || JSON.stringify(defaultDeviceSetting));
};

const initialState = {
  firstSaveDone: false,
  deviceSetting: getDeviceSettingFromLocalStorage(),
};

export const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setDeviceSetting: (state, action: SetDeviceSettingAction) => {
      state.firstSaveDone = true;
      state.deviceSetting = {
        ...state.deviceSetting,
        ...action.payload,
      };
      saveDeviceSettingToLocalStorage(state.deviceSetting);
    },
  },
});

export const deviceStore = configureStore({
  reducer: deviceSlice.reducer,
});

export const getDevice = (state: { device: ReturnType<typeof deviceStore.getState> }) => state.device;
