import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getGetModalState } from '../../store/modal';
import { ModalStyle } from '../modal/modal';
import './startRecordingModal.scss';

let onConfirm = () => {};
let onCancel = () => {};

export const startRecordingModalID = 'start-recording-id';
export const openRecordingModal = (onConfirm_: () => void, onCancel_: () => void) => {
  onConfirm = onConfirm_;
  onCancel = onCancel_;
  return openModalThunk(startRecordingModalID);
};
export const closeRecordingModal = closeModalThunk(startRecordingModalID);

export const StartRecordingModal = () => {
  const startRecordingState = useSelector(getGetModalState(startRecordingModalID));
  useEffect(() => {
    newModalThunk(startRecordingModalID, false)();
  }, []);
  return (
    <ReactModal
      id={startRecordingModalID}
      className={startRecordingModalID}
      isOpen={startRecordingState}
      style={ModalStyle}
    >
      <>
        <div className="body">
          <div className="title">You are now ready to record</div>
          Press the button below to start recording
        </div>
        <div className="footer">
          <div className="buttons">
            <div
              className="cancel-btn"
              onClick={() => {
                onCancel();
                closeRecordingModal();
              }}
            >
              Cancel
            </div>
            <div
              className="action-btn"
              onClick={() => {
                onConfirm();
                closeRecordingModal();
              }}
            >
              Record now
            </div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default StartRecordingModal;
