import axios from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'querystring';
import { config } from '../../config';
import logger from '../logger';
import { headers } from './spaces';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

export async function getLinkPreview(url: string) {
  try {
    logger.info('createURLPreview called.', { url });
    const response = await axios.post(`${config.firebaseCloudURL}/createURLPreview`, qs.stringify({ url }), headers);
    logger.info('createURLPreview response:', { result: response.data });
    return response.data;
  } catch (e) {
    logger.warn('createURLPreview error:', { error: e?.response?.data });
    throw e;
  }
}

export async function getPreviewFavicon(url: string, timeout = 0) {
  let isCallbackFired = false;
  return new Promise(async (resolve) => {
    if (timeout !== 0) {
      setTimeout(() => {
        if (!isCallbackFired) {
          isCallbackFired = true;
          resolve('');
        }
      }, timeout);
    }
    try {
      const d = await getLinkPreview(url);
      if (d.favicons && d.favicons[0]) {
        resolve(d.favicons[0]);
      }
    } catch (error) {
      resolve('');
    }
  });
}
