import Bowser from 'bowser';
import firebase from 'firebase';
import { v4 } from 'uuid';
import { canvasSize, config } from '../../config';
import { permissionV2, userRoleType } from '../../store/spacesRooms';
import { newBGType, screenWallV2 } from '../../store/wallpaper';
import {
  canvas,
  canvasChild,
  dimension,
  overflowStreamType,
  restrictedZonesType,
  restrictedZoneType,
  roomPermissions,
} from '../../types/canvasFB';
import logger from '../logger';

if (!firebase.apps.length) firebase.initializeApp(config.firebase);
let database = firebase.database();

export function saveCanvas(spaceID: string, canvasID: string, obj: canvas) {
  database.ref(`space/${spaceID}/canvas/${canvasID}/obj`).set(obj);
}

export function setWallpaper(spaceID: string, canvasID: string, url: string) {
  logger.info('Wallpaper set rtd called.', { url });
  database.ref(`space/${spaceID}/canvas/${canvasID}/wallpaper`).set(url);
}

export function setWallpaperV2(spaceID: string, canvasID: string, wallObj: newBGType) {
  logger.info('wallpaper set v2 called', { wallObj });
  database.ref(`space/${spaceID}/canvas/${canvasID}/wallpaperV2`).set(wallObj);
}

export function setScreenWall(spaceID: string, canvasID: string, id: string) {
  database.ref(`space/${spaceID}/canvas/${canvasID}/screenWall`).set(id);
}

export async function getOverflowStream(spaceID: string, roomID: string): Promise<overflowStreamType> {
  return (await database.ref(`space/${spaceID}/room/${roomID}/overflowStream`).once('value')).val();
}

export async function setOverflowStream(spaceID: string, roomID: string, data: overflowStreamType) {
  return await database.ref(`space/${spaceID}/room/${roomID}/overflowStream`).set(data);
}

export async function clearOverflowStream(spaceID: string, roomID: string) {
  return await database.ref(`space/${spaceID}/room/${roomID}/overflowStream`).remove();
}

export async function setRestrictedZone(spaceID: string, roomID: string, data: restrictedZoneType, id?: string) {
  if (!id) id = v4().split('-')[0];
  return await database.ref(`space/${spaceID}/room/${roomID}/restrictedZone/${id}`).set(data);
}

export async function deleteRestrictedZone(spaceID: string, roomID: string, id: string) {
  await database.ref(`space/${spaceID}/room/${roomID}/restrictedZone/${id}`).remove();
}

export function watchRestrictedZone(spaceID: string, roomID: string, callback: (data: restrictedZonesType) => void) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/restrictedZone`);
  const callBack = (snapshot: any) => {
    callback(snapshot.val());
  };
  ref.on('value', callBack);
  return () => {
    ref.off('value', callBack);
  };
}

export function watchOverflowStream(spaceID: string, roomID: string, callback: (data: overflowStreamType) => void) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/overflowStream`);
  const callBack = (snapshot: any) => {
    callback(snapshot.val());
  };
  ref.on('value', callBack);
  return () => {
    ref.off('value', callBack);
  };
}

export function watchWallpaper(spaceID: string, canvasID: string, callback: (url: string) => void) {
  const ref = database.ref(`space/${spaceID}/canvas/${canvasID}/wallpaper`);
  const callBack = (snapshot: any) => {
    callback(snapshot.val());
  };
  ref.on('value', callBack);
  return () => {
    ref.off('value', callBack);
  };
}

export function watchWallpaperV2(spaceID: string, canvasID: string, callback: (wallObj: newBGType | null) => void) {
  const ref = database.ref(`space/${spaceID}/canvas/${canvasID}/wallpaperV2`);
  const callBack = (snapshot: any) => {
    callback(snapshot.val());
  };
  ref.on('value', callBack);
  return () => {
    ref.off('value', callBack);
  };
}

export function watchScreenWallpaper(spaceID: string, canvasID: string, callback: (url: string) => void) {
  const ref = database.ref(`space/${spaceID}/canvas/${canvasID}/screenWall`);
  const callBack = (snapshot: any) => {
    callback(snapshot.val());
  };
  ref.on('value', callBack);
  return () => {
    ref.off('value', callBack);
  };
}

export function watchScreenWallV2(
  spaceID: string,
  canvasID: string,
  callback: (screenWallV2: screenWallV2 | undefined) => void
) {
  const ref = database.ref(`space/${spaceID}/canvas/${canvasID}/screenWallV2`);
  const callBack = (snapshot: any) => {
    callback(snapshot.val());
  };
  ref.on('value', callBack);
  return () => {
    ref.off('value', callBack);
  };
}

export async function setScreenWallV2RTD(spaceID: string, canvasID: string, screenWallV2: screenWallV2 | undefined) {
  const ref = database.ref(`space/${spaceID}/canvas/${canvasID}/screenWallV2`);
  await ref.set(screenWallV2);
}

export function watchBroadcast(
  spaceID: string,
  callback: (
    broadcastInfo: {
      uid: string;
      name: string;
      profilePic: string;
      listeners: number;
      isLive: boolean;
      ping: number;
    } | null
  ) => void
) {
  const ref = database.ref(`space/${spaceID}/broadcast`);
  const callBack = (snapshot: any) => {
    callback(snapshot.val());
  };
  ref.on('value', callBack);
  return () => {
    ref.off('value', callBack);
  };
}

export async function setListenersBroadcast(spaceID: string, listeners: number) {
  return await database.ref(`space/${spaceID}/broadcast/listeners`).set(listeners);
}

export function setBroadcast(
  spaceID: string,
  broadcastInfo: {
    uid: string;
    name: string;
    profilePic: string;
    listeners: number;
    isLive: boolean;
    ping: number;
  } | null
) {
  const ref = database.ref(`space/${spaceID}/broadcast`);
  return ref.set(broadcastInfo);
}

export function setBroadcastPing(spaceID: string, time: number) {
  const ref = database.ref(`space/${spaceID}/broadcast/ping`);
  return ref.set(time);
}

export function watchTemplates(callback: (templates: any) => void) {
  const ref = database.ref(`template`);
  const callBack = (snapshot: any) => {
    callback(snapshot.val());
  };
  ref.on('value', callBack);
  return () => {
    ref.off('value', callBack);
  };
}

export function clearCanvas(spaceID: string, canvasID: string) {
  database.ref(`space/${spaceID}/canvas/${canvasID}/obj`).set({});
}

export function setTemplate(spaceID: string, canvasID: string, data: any) {
  database.ref(`space/${spaceID}/canvas/${canvasID}`).set(data);
}

export function updateCanvasChild(spaceID: string, canvasID: string, objID: string, obj: canvasChild) {
  database.ref(`space/${spaceID}/canvas/${canvasID}/obj`).update({
    [objID]: obj,
  });
}

export function updatePartiallyCanvasChild(spaceID: string, canvasID: string, objID: string, key: string, data: any) {
  database.ref(`space/${spaceID}/canvas/${canvasID}/obj/${objID}`).update({
    [key]: data,
  });
}

export function watchCanvasChild(spaceID: string, canvasID: string, objID: string, callBack: (c: canvasChild) => void) {
  const ref = database.ref(`space/${spaceID}/canvas/${canvasID}/obj/${objID}`);
  const callback = (snapshot: any) => {
    callBack(snapshot.val());
  };
  ref.on('value', callback);
  return () => {
    ref.off('value', callback);
  };
}

export function watchForNewCanvasChild(
  spaceID: string,
  canvasID: string,
  callBack: (c: canvasChild, id: string | null) => void
) {
  const ref = database.ref(`space/${spaceID}/canvas/${canvasID}/obj`);
  const callback = (snapshot: any) => {
    callBack(snapshot.val(), snapshot.key);
  };
  ref.on('child_added', callback);
  return () => {
    ref.off('child_added', callback);
  };
}

export function getCanvasChildren(
  spaceID: string,
  canvasID: string,
  callBack: (c: canvasChild[], id: string | null) => void
) {
  const ref = database.ref(`space/${spaceID}/canvas/${canvasID}/obj`);
  ref.once('value', (snapshot) => {
    callBack(snapshot.val(), snapshot.key);
  });
}

export function watchForDeleteCanvasChild(
  spaceID: string,
  canvasID: string,
  callBack: (c: canvasChild, id: string | null) => void
) {
  const ref = database.ref(`space/${spaceID}/canvas/${canvasID}/obj`);
  const callback = (snapshot: any) => {
    callBack(snapshot.val(), snapshot.key);
  };
  ref.on('child_removed', callback);
  return () => {
    ref.off('child_removed', callback);
  };
}

export function addNewCanvasChild(spaceID: string, canvasID: string, obj: canvasChild) {
  let id = v4().split('-')[0];
  return database
    .ref(`space/${spaceID}/canvas/${canvasID}/obj`)
    .child(id)
    .set(obj)
    .then((val) => ({ value: val, id }));
}

export async function deleteCanvasChild(spaceID: string, canvasID: string, objID: string) {
  await database.ref(`space/${spaceID}/canvas/${canvasID}/obj`).child(objID).remove();
}

export async function getCanvas(spaceID: string, canvasID: string): Promise<canvas> {
  return (await database.ref(`space/${spaceID}/canvas/${canvasID}/obj`).once('value')).val();
}

export function addEleRoom(spaceID: string, roomID: string, objID: string, obj: dimension) {
  var room: { [id: string]: dimension } = {};
  room[objID] = obj;
  return firebase.database().ref(`space/${spaceID}/room/${roomID}/obj`).update(room);
}

export function setEleRoomCopy(spaceID: string, roomID: string, objID: string, roomCopy: number) {
  return firebase
    .database()
    .ref(`space/${spaceID}/room/${roomID}/obj/${objID}`)
    .update({ d: { rc: roomCopy } });
}

export function watchBubbleRoomCopy(
  spaceID: string,
  roomID: string,
  uid: string,
  callback: (roomCopy: number) => void
) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/obj/${uid}/d/rc`);
  const callBack = (snapshot: any) => {
    callback(snapshot.val());
  };
  ref.on('value', callBack);
  return () => {
    ref.off('value', callBack);
  };
}

export function updateRoomChild(spaceID: string, roomID: string, objID: string, obj: dimension) {
  database.ref(`space/${spaceID}/room/${roomID}/obj`).update({
    [objID]: obj,
  });
}

export function watchRoomChild(spaceID: string, roomID: string, objID: string, callBack: (c: dimension) => void) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/obj/${objID}`);
  const spawnX = 0 + ((canvasSize.w * 3) / 4) * (Math.random() * 2 - 1);
  const spawnY = 100 + 700 * Math.random();
  const callback = (snapshot: any) => {
    let val: dimension = snapshot.val();
    let flag = false;
    const buffer = 200;
    if (!val) {
      val = {
        x: spawnX,
        y: spawnY,
        w: 128,
        h: 128,
      };
      flag = true;
    }
    if (val.x > canvasSize.w) {
      val.x = canvasSize.w - buffer;
      flag = true;
    } else if (val.x < 0) {
      val.x = buffer;
      flag = true;
    }
    if (val.y > canvasSize.h) {
      val.y = canvasSize.h - buffer;
      flag = true;
    } else if (val.y < 0) {
      val.y = buffer;
      flag = true;
    }
    if (flag) updateRoomChild(spaceID, roomID, objID, val);
    else callBack(val);
  };
  ref.on('value', callback);
  return () => {
    ref.off('value', callback);
  };
}

export async function getRoom(spaceID: string, roomID: string): Promise<canvas> {
  try {
    return (await database.ref(`space/${spaceID}/room/${roomID}/obj`).once('value')).val();
  } catch (e) {
    console.error('firebase error:', e);
  }
  return new Promise((resolve) => resolve({}));
}

export async function toggleMegaphone(spaceID: string, roomID: string, userID: string, flag: boolean) {
  await database.ref(`space/${spaceID}/room/${roomID}/megaphone`).update({
    [userID]: flag,
  });
}

export function watchMegaphone(
  spaceID: string,
  roomID: string,
  callBack: (megaphone: { [userID: string]: boolean }) => void
) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/megaphone`);
  const callback = (snapshot: any) => {
    callBack(snapshot.val());
  };
  ref.on('value', callback);
  return () => {
    ref.off('value', callback);
  };
}

export function watchMegaphoneForOneUser(
  spaceID: string,
  roomID: string,
  userID: string,
  callBack: (megaphone?: boolean) => void
) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/megaphone/${userID}`);
  const callback = (snapshot: any) => {
    callBack(snapshot.val());
  };
  ref.on('value', callback);
  return () => {
    ref.off('value', callback);
  };
}

export function watchMuted(
  spaceID: string,
  roomID: string,
  userID: string,
  callBack: (muted: { isMuted: boolean; isVideoMuted: boolean } | null) => void
) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/muted/${userID}`);
  const callback = (snapshot: any) => {
    callBack(snapshot.val());
  };
  ref.on('value', callback);
  return () => {
    ref.off('value', callback);
  };
}

export function watchKicked(spaceID: string, roomID: string, userID: string, callBack: (flag: boolean | null) => void) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/kicked/${userID}`);
  const callback = (snapshot: any) => {
    callBack(snapshot.val());
  };
  ref.on('value', callback);
  return () => {
    ref.off('value', callback);
  };
}

export async function setMicMuted(userID: string, spaceID: string, roomID: string, data: boolean) {
  await database.ref(`space/${spaceID}/room/${roomID}/muted/${userID}/isMuted`).set(data);
}

export async function setVideoMuted(userID: string, spaceID: string, roomID: string, data: boolean) {
  await database.ref(`space/${spaceID}/room/${roomID}/muted/${userID}/isVideoMuted`).set(data);
}

export async function setKicked(userID: string, spaceID: string, roomID: string, data: boolean) {
  await database.ref(`space/${spaceID}/room/${roomID}/kicked/${userID}`).set(data);
}

export async function getMegaphoneOnce(spaceID: string, roomID: string, userID: string) {
  return (await database.ref(`space/${spaceID}/room/${roomID}/megaphone/${userID}`).once('value')).val();
}

export function watchRoomPermissions(spaceID: string, roomID: string, callBack: (p: roomPermissions) => void) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/permissions`);
  const callback = (snapshot: any) => {
    callBack(snapshot.val());
  };
  ref.on('value', callback);
  return () => {
    ref.off('value', callback);
  };
}

export async function saveRoomPermissions(spaceID: string, roomID: string, permission: roomPermissions) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/permissions`);
  await ref.set(permission);
}

export async function saveRoomPermissionsV2(spaceID: string, roomID: string, permission: permissionV2) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/permissions/a`);
  await ref.set(permission);
}

export async function saveRoomPermissionForObjectsV2(spaceID: string, roomID: string, flag: boolean) {
  const permissions = ['apps', 'objects'];
  for (let i in permissions) {
    const ref = database.ref(`space/${spaceID}/room/${roomID}/permissions/a/${permissions[i]}`);
    await ref.set(flag);
  }
}

export async function saveOnePermissionV2(
  spaceID: string,
  roomID: string,
  flag: boolean,
  permission:
    | 'cam'
    | 'mic'
    | 'screen'
    | 'megaphone'
    | 'gifs'
    | 'chat'
    | 'recording'
    | 'moveself'
    | 'openroom'
    | 'lockroom'
) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/permissions/a/${permission}`);
  await ref.set(flag);
}

export async function saveBubbleSize(spaceID: string, roomID: string, size: number) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/permissions/size`);
  await ref.set(size);
}

export async function saveSpatialState(spaceID: string, roomID: string, spatial: boolean) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/permissions/spatial`);
  await ref.set(spatial);
}

export async function saveVideoAlwaysOnState(spaceID: string, roomID: string, videoOn: boolean) {
  const ref = database.ref(`space/${spaceID}/room/${roomID}/permissions/vidOnPerm`);
  await ref.set(videoOn);
}
export function getUsageRef(sessionID: string) {
  return database.ref(`usageData/${sessionID}`);
}

export async function createUsageRoom(
  sessionID: string,
  spaceRoomUser: {
    roomID: string;
    spaceID: string;
    userID: string;
    ipAddress: string;
    userAgent: string;
    parsedUA: Bowser.Parser.ParsedResult;
  },
  online = true
) {
  try {
    const ref = getUsageRef(sessionID);
    const { roomID, spaceID, userID } = spaceRoomUser;
    const { ipAddress, userAgent, parsedUA } = spaceRoomUser;
    console.time('SessionCreate-' + sessionID);
    await ref.set({ roomID, spaceID, userID, online, userInfo: { ipAddress, userAgent, parsedUA } });
    console.timeEnd('SessionCreate-' + sessionID);
  } catch (e) {
    logger.error('Setting for usageData failed:', { usageSessionID: sessionID, task: 'creating', errorObj: e });
    throw e;
  }
}

export async function updateUsageRoom(sessionID: string, dailyInfo: { sessionID: string; participantID: string }) {
  const ref = database.ref(`usageData/${sessionID}`);
  try {
    await ref.update({ dailyInfo });
  } catch (e) {
    logger.error('Update for usageData failed:', { usageSessionID: sessionID, task: 'settingDailyInfo', errorObj: e });
  }
}

export async function setUsageOffline(sessionID: string) {
  try {
    await database.ref(`usageData/${sessionID}`).update({ online: false });
    await database.ref(`usageData/${sessionID}`).update({ endTime: firebase.database.ServerValue.TIMESTAMP });
  } catch (e) {
    logger.error('Update for usageData failed:', { usageSessionID: sessionID, task: 'settingOffline', errorObj: e });
    throw e;
  }
}

export function presenceObjRef(spaceID: string, roomID: string, userID: string) {
  return database.ref(`space/${spaceID}/room/${roomID}/presence/${userID}`);
}

export function presenceRef(spaceID: string, roomID: string, userID: string) {
  return database.ref(`space/${spaceID}/room/${roomID}/presence/${userID}/online`);
}

export function presenceVersionRef(spaceID: string, roomID: string, userID: string) {
  return database.ref(`space/${spaceID}/room/${roomID}/presence/${userID}/version`);
}

export function presenceOnDeleteListener(
  spaceID: string,
  roomID: string,
  callback: (deletedKey: string, roomCopy: string) => void
) {
  const cb = (child: any) => {
    if (child.userID) callback(child.userID, child.roomCopy ?? '0');
    else callback('', '0');
  };
  const ref = database.ref(`space/${spaceID}/room/${roomID}/presence`);
  ref.on('child_removed', cb);
  return () => {
    ref.off('child_removed', cb);
  };
}

export async function setPresenceFlagInRTD(
  spaceID: string,
  roomID: string,
  userID: string,
  flag: boolean,
  deleteIfOffline = true
) {
  const ref = presenceRef(spaceID, roomID, userID);
  await ref.set(flag);
  await presenceVersionRef(spaceID, roomID, userID).set('v2');
  if (deleteIfOffline && !flag) await presenceObjRef(spaceID, roomID, userID).remove();
}

export async function setUserRole(
  userRole: userRoleType,
  userID: string,
  roomID: string,
  spaceID: string,
  createdBy: string
) {
  let roleString = 'audience';
  let removeString = ['hosts', 'speakers'];
  if (userRole === 'h') {
    roleString = 'hosts';
    removeString = ['speakers', 'audience'];
  } else if (userRole === 's') {
    roleString = 'speakers';
    removeString = ['hosts', 'audience'];
  }
  const ref = database.ref(`space/${spaceID}/room/${roomID}/${roleString}/${userID}`);
  await ref.set({ userID, createdOn: new Date().getTime(), createdBy });
  removeString.map(async (roleString) => {
    try {
      await database.ref(`space/${spaceID}/room/${roomID}/${roleString}/${userID}`).remove();
    } catch (e) {}
  });
}
