import _ from 'lodash';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { config } from '../../../config';
import { sendAmplitudeData } from '../../../helper/amplitude';
import { signOut } from '../../../helper/firebase/auth';
import { formatLong, handleSubdomain } from '../../../helper/interactive/misc';
import { getAuth } from '../../../store/auth';
import { getSpacesRooms } from '../../../store/spacesRooms';
import { useOutsideEvent } from '../../interactive/giphy/Giphy';
import './dropDownSpace.scss';

const DropDownSpace = (props: { onClose: () => void }) => {
  const spacesRooms = useSelector(getSpacesRooms);
  const space = spacesRooms.spaces[spacesRooms.currentSpace];
  const auth = useSelector(getAuth);
  const goTo = (path: string) => {
    window.location.href = config.frontendURL + path;
  };
  const spaceDropRef = useRef<HTMLDivElement | null>(null);
  useOutsideEvent(spaceDropRef, props.onClose, 'spacesettings', '#spacesettings');

  return (
    <div className="dropdownspace" ref={spaceDropRef}>
      {space?.permission.isOwner && (
        <div className="option borderbottom first">
          <div
            className="label"
            onClick={() => {
              window.open(`${handleSubdomain(`/s/${space?.spaceInfo.spaceID}`)}/settings`, '_blank');
              props.onClose();
            }}
          >
            {spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceName} space settings
          </div>
        </div>
      )}
      {/* {space.permission.isOwner && (
        <div className="option borderbottom first">
          <div
            className="label"
            onClick={() => {
              window.open(`${handleSubdomain(`/s/${space.spaceInfo.spaceID}`)}/customise`, '_blank');
              props.onClose();
            }}
          >
            Customise Login Page
          </div>
        </div>
      )} */}
      <div className="spacesList">
        {_.map(spacesRooms.spaces, (space) => {
          return (
            <>
              {!space?.permission.isGuest && (
                <div
                  className="option"
                  key={space?.spaceID}
                  onClick={() => {
                    sendAmplitudeData('Space switched', {
                      toSpace: space?.spaceInfo.spaceID,
                    });
                    goTo(`/s/${space?.spaceInfo.spaceID}`);
                  }}
                >
                  {space?.spaceInfo.spaceName}
                </div>
              )}
            </>
          );
        })}
      </div>
      <div
        className="option borderbottom bordertop"
        onClick={() => {
          sendAmplitudeData('Space create clicked', { type: 'sidebar' });
          goTo('/onboard?sticky');
        }}
      >
        Create new space
      </div>
      <div
        className={auth.user.email && auth.user.email.length > 25 ? 'option last has-v2-tooltip-email' : 'option last'}
        data-tooltip-text={auth.user.email && auth.user.email.length > 25 ? auth.user.email : ''}
        onClick={async () => {
          sendAmplitudeData('Sign out clicked', {});
          await signOut();
          sendAmplitudeData('Sign out completed', {});
          goTo('/login');
        }}
      >
        Logout ({formatLong(auth.user.email, 25)})
      </div>
    </div>
  );
};

export default DropDownSpace;
