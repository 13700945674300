import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import spatialAudioController from '../../../spatialAudio/spatialAudioOptimized';
import { getSpatialAudio, getUpdateSource } from '../../../store';
import { getCurrentRoomPermission } from '../../../store/spacesRooms';
import { xy } from '../../../types/canvasFB';
import OnlyDrag from '../draggables/onlyDrag/OnlyDrag';
import { MediaJitsiNew } from '../video/video';

const FakeSource = () => {
  const watcher = (c: (c: xy) => void) => () => {};
  const roomPermissions = useSelector(getCurrentRoomPermission);
  const [id] = useState(v4());
  const [x] = useState(Math.floor(Math.random() * 3200));
  const [y] = useState(Math.floor(Math.random() * 1800));
  const alpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const [letter] = useState(alpha[Math.floor(Math.random() * 26)]);
  const [color] = useState(Math.floor(Math.random() * 16777215).toString(16));
  const audioStream = useMemo(() => {
    const url = '/sound/bird-2.wav';
    const audio: any = new Audio(url);
    audio.loop = true;
    audio.crossOrigin = 'anonymous';
    audio.play();
    const ctx = new (window.AudioContext || window.webkitAudioContext)();
    const stream_dest = ctx.createMediaStreamDestination();
    const source = ctx.createMediaElementSource(audio);
    source.connect(stream_dest);
    return stream_dest.stream;
  }, []);
  const setAudio = (flag: boolean, ele: HTMLElement) => {
    if (flag) {
      const audio: any = ele;
      if (audio) {
        audio.src = '/sound/bird-2.wav';
        audio.crossOrigin = 'anonymous';
        audio.load();
        audio.loop = true;
        audio.play();
      }
      // setInterval(() => {
      //   if (audio.paused) audio.play();
      // }, 5000);
    }
    return true;
  };
  const updateVolume = (id: string, xy: { x: number; y: number }, isListener: boolean) => {
    const audioID = `${id}_audio`;
    getUpdateSource(audioID)({ x: xy.x, y: xy.y });
    spatialAudioController.recalculatePosition(audioID, getSpatialAudio(), true);
  };

  useEffect(() => {
    updateVolume(id, { x, y }, false);
  }, []);
  return (
    <OnlyDrag
      key={id}
      childrenKey={`${id}_audio`}
      x={x}
      y={y}
      name="sound source"
      onDimChange={(d) => updateVolume(id, d, false)}
      watcher={watcher}
      children={
        <>
          <MediaJitsiNew
            videoStream={audioStream.getAudioTracks()[0]}
            streamLoading={false}
            isMuted={false}
            trackID={id}
            id={`${id}_audio`}
            position={{ x, y }}
            isAudio={true}
            setContent={setAudio}
            isLocal={false}
            isScreen={false}
          />
          <div className="videoPlaceholder" style={{ backgroundColor: '#' + color }}>
            {letter}
          </div>
        </>
      }
    />
  );
};

const FakeSources = (props: { num: number }) => {
  let children: JSX.Element[] = [];
  for (let i = 0; i < props.num; i++) {
    children.push(<FakeSource key={i} />);
  }
  return <>{children}</>;
};

export default FakeSources;
