import { getTimeZones } from '@vvo/tzdb';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { formatLong, getUserTimezoneString } from '../../../helper/interactive/misc';
import DropDown from '../../dropdown/dropdown';
import './timezonePicker.scss';

const TimeZonePicker = (props: {
  value?: { name: string; offset: number };
  onChange?: (timezone: { name: string; offset: number }) => void;
}) => {
  //   const [timezone, setTimezone] = useState(getUserTimezone().name);
  const [timezone, setTimezone] = useState(getUserTimezoneString());

  const timeZones = useMemo(() => {
    const timezones = getTimeZones();
    const timezoneDict: { [name: string]: number } = {};
    for (let i in timezones) {
      const timezone = timezones[i];
      const title = timezone.currentTimeFormat.split(' ')[0] + ' ' + timezone.name;
      timezoneDict[title] = timezone.rawOffsetInMinutes;
    }
    return timezoneDict;
  }, []);

  useEffect(() => {
    if (!props.value || !props.onChange) return;
    if (timeZones[props.value.name]) return;
    let flag = false;
    for (let t in timeZones) {
      if (t.includes(props.value.name)) {
        props.onChange({ name: t, offset: timeZones[t] });
        flag = true;
        break;
      }
    }
    if (!flag) {
      for (let t in timeZones) {
        if (timeZones[t] === props.value.offset) {
          props.onChange({ name: t, offset: timeZones[t] });
          break;
        }
      }
    }
  }, [props.value?.name, timeZones]);

  useEffect(() => {
    if (props.value) {
      setTimezone(props.value.name);
    }
  }, [props.value]);
  return (
    <div className="timeZonePicker">
      <DropDown
        headerTitle={formatLong(timezone, 28)}
        styles={{
          dropDownHeader: {
            paddingLeft: '8px',
            paddingRight: '8px',
            fontSize: '14px',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          listOuter: {
            backgroundColor: '#393B41',
            borderRadius: '8px',
            border: '2px solid #000000',
            top: '36px',
            height: '250px',
          },
          listItems: {
            paddingLeft: '8px',
            paddingBottom: '6px',
            paddingTop: '6px',
            fontSize: '14px',
            whiteSpace: 'nowrap',
          },
        }}
        showStatusColor={false}
        options={_.keys(timeZones)}
        optionsListType="array"
        isCamelcase={false}
        isItemDict={false}
        onSelectItem={(e: any) => {
          if (props.onChange) {
            props.onChange({
              name: e.header,
              offset: timeZones[e.header],
            });
          }
          setTimezone(e.header);
        }}
      />
    </div>
  );
};

export default TimeZonePicker;
