import React, { useEffect, useState } from 'react';
import { Check, X } from 'react-feather';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getGetModalState } from '../../store/modal';
import { ModalStyle } from '../modal/modal';
import './crowdManagementModal.scss';

let onConfirm = () => {};
let onCancel = () => {};
let modalType = '';

export const crowdManagementModalID = 'crowd-management-modal';
export const openCrowdManagementModal = (_type: string, onConfirm_: () => void, onCancel_: () => void) => {
  modalType = _type;
  onConfirm = onConfirm_;
  onCancel = onCancel_;
  return openModalThunk(crowdManagementModalID);
};

export const closeCrowdManagementModal = closeModalThunk(crowdManagementModalID);

const CrowdManagementModal = () => {
  const crowdManagementModalState = useSelector(getGetModalState(crowdManagementModalID));

  useEffect(() => {
    newModalThunk(crowdManagementModalID, false)();
  }, []);

  const [optionNo, setOptionNo] = useState(false);

  const ModalForEveryoneRule = () => {
    return (
      <>
        <div className="cm-modal-container">
          <div className="cm-modal-header">
            <span>SETUP YOUR RULE</span>
            <div
              className="cm-modal-close"
              onClick={() => {
                closeCrowdManagementModal();
              }}
            >
              <X />
            </div>
          </div>
          <div className="cm-modal-body">
            <span style={{ paddingBottom: '20px' }}>
              You can limit access of members of a room to a particular room or rooms.
            </span>
            <div className="cm-modal-body-option-container-row">
              <div className="cm-left-label">Select rooms that you’ll give access to</div>
              <div className="cm-right-option">Lobby</div>
            </div>
            <div className="cm-modal-body-option-container-row">
              <div className="cm-left-label">Select rooms that you’ll give access to</div>
              <div className="cm-right-option">6 rooms selected</div>
            </div>

            <div className="cm-modal-body-option-container-col">
              <div className="cm-col-label">Rooms selected</div>
              <div className="cm-col-option">Lobby, Room 1, Room 2, Room 4, Room 5</div>
            </div>

            <div className="cm-modal-body-checkbox">
              <div
                className="cm-checkbox"
                onClick={() => {
                  setOptionNo(!optionNo);
                }}
              >
                {optionNo && <Check color={'white'} />}
              </div>
              <span>Give people option to say no</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const ModalForRoomRolesRule = () => {
    return (
      <>
        <div className="cm-modal-container">
          <div className="cm-modal-header">
            <span>SETUP YOUR RULE</span>
            <div
              className="cm-modal-close"
              onClick={() => {
                closeCrowdManagementModal();
              }}
            >
              <X />
            </div>
          </div>
          <div className="cm-modal-body">
            <span style={{ paddingBottom: '20px' }}>
              You can limit access of members with specific roles of a room to a particular room or rooms.
            </span>
            <div className="cm-modal-body-option-container-row">
              <div className="cm-left-label">Select the origin room</div>
              <div className="cm-right-option">Lobby</div>
            </div>
            <div className="cm-modal-body-option-container-row">
              <div className="cm-left-label">Select rooms that you’ll give access to</div>
              <div className="cm-right-option">6 rooms selected</div>
            </div>
            <div className="cm-modal-body-option-container-row">
              <div className="cm-left-label">Select room roles</div>
              <div className="cm-right-option">Lobby</div>
            </div>

            <div className="cm-modal-body-option-container-col">
              <div className="cm-col-label">Rooms selected</div>
              <div className="cm-col-option">Lobby, Room 1, Room 2, Room 4, Room 5</div>
            </div>

            <div className="cm-modal-body-option-container-col">
              <div className="cm-col-label">Rooms roles selected</div>
              <div className="cm-col-option">Audience, Speakers</div>
            </div>

            <div className="cm-modal-body-checkbox">
              <div
                className="cm-checkbox"
                onClick={() => {
                  setOptionNo(!optionNo);
                }}
              >
                {optionNo && <Check color={'white'} />}
              </div>
              <span>Give people option to say no</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const ModalForCSVRule = () => {
    return (
      <>
        <div className="cm-modal-container">
          <div className="cm-modal-header">
            <span>SETUP YOUR RULE</span>
            <div
              className="cm-modal-close"
              onClick={() => {
                closeCrowdManagementModal();
              }}
            >
              <X />
            </div>
          </div>
          <div className="cm-modal-body">
            <span style={{ paddingBottom: '20px' }}>
              You can upload an email list and the rooms they’ll have limited access to. The columns we need are email
              and one column for each room. <br />
              <a
                href="https://google.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#c4c4c4', textDecoration: 'none', borderBottom: '1px solid #c4c4c4' }}
              >
                Download sample CSV
              </a>
            </span>
            <div className="cm-modal-body-option-container-row">
              <div className="cm-left-label" style={{ color: '#34E3B9' }}>
                Upload CSV
              </div>
              <div className="cm-right-option">
                <a
                  href="https://google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#ffffff', textDecorationLine: 'underline' }}
                >
                  Lobby.csv
                </a>
              </div>
            </div>

            <div className="cm-modal-body-checkbox">
              <div
                className="cm-checkbox"
                onClick={() => {
                  setOptionNo(!optionNo);
                }}
              >
                {optionNo && <Check color={'white'} />}
              </div>
              <span>Give people option to say no</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <ReactModal
      id={crowdManagementModalID}
      className={crowdManagementModalID}
      isOpen={crowdManagementModalState}
      style={ModalStyle}
      shouldCloseOnOverlayClick={true}
    >
      <>
        {modalType === 'Move everyone in the room' && <ModalForEveryoneRule />}
        {modalType === 'Move users with specific roles in the room' && <ModalForRoomRolesRule />}
        {modalType === 'Move specific users in the room' && <ModalForCSVRule />}
        <div className="cm-modal-footer">
          <div className="cm-modal-buttons">
            <div
              className="cm-cancel-btn"
              onClick={() => {
                onCancel();
                closeCrowdManagementModal();
              }}
            >
              Cancel
            </div>
            <div
              className="cm-confirm-btn"
              onClick={() => {
                onConfirm();
                closeCrowdManagementModal();
              }}
            >
              Confirm
            </div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default CrowdManagementModal;
