import { configureStore, createSlice } from '@reduxjs/toolkit';

export const inactivitySlice = createSlice({
  name: 'inactivity',
  initialState: {
    lastActivity: new Date().getTime(),
  },
  reducers: {
    setLastActivityStamp: (state, action) => {
      state.lastActivity = action.payload;
    },
  },
});

export const inactivityStore = configureStore({
  reducer: inactivitySlice.reducer,
  devTools: true,
});

export const getLastActivityStampRedux = (globalState: { inactivity: ReturnType<typeof inactivityStore.getState> }) =>
  globalState.inactivity.lastActivity;
