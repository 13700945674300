import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { addNewCanvasChild, updatePartiallyCanvasChild } from '../../../helper/interactive/firebaseRTD';
import { getSpawnLocation } from '../../../helper/interactive/misc';
import { getPreviewFavicon } from '../../../helper/requests/urlPreview';
import { closeModalThunk, getAuthState, getListenerPosition, newModalThunk, openModalThunk } from '../../../store';
import { getGetModalState } from '../../../store/modal';
import { ModalStyle } from '../../modal/modal';
import { useOutsideEvent } from '../giphy/Giphy';
import '../ytDialog/ytDialog.scss';
import './style.scss';

function validURL(str: string) {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

const shareLinkBoxModalId = 'linkbox-modal';

type EditData = {
  title: string;
  url: string;
  updateCallback: Function;
};

type DefaultEditOptions = {
  isEditing: boolean;
  editData: EditData | undefined;
};

let editOptions: DefaultEditOptions = {
  isEditing: false,
  editData: undefined,
};

export const openShareLinkModal = (editData?: EditData) => {
  if (editData) {
    editOptions = {
      editData: editData,
      isEditing: true,
    };
  } else {
    editOptions = {
      editData: undefined,
      isEditing: false,
    };
  }
  return openModalThunk(shareLinkBoxModalId)();
};

export const closeShareLinkModal = closeModalThunk(shareLinkBoxModalId);

const ShareLinkBoxModal = (props: { canvasID: string; spaceID: string; toClose: () => void }) => {
  const isOpen = useSelector(getGetModalState(shareLinkBoxModalId));

  const [url, setURL] = useState('');
  const [title, setTitle] = useState('');
  const [msg, setMsg] = useState('');
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideEvent(ref, props.toClose, 'iframe_btn');
  const auth = getAuthState();

  const onClose = () => {
    /**
     * Clear the state for close
     */
    setTitle('');
    setURL('');
    closeShareLinkModal();
  };

  const addLinkBox = async () => {
    if (validURL(url)) {
      const spawnLocation = getSpawnLocation(getListenerPosition(), 500);

      addNewCanvasChild(props.spaceID, props.canvasID, {
        w: 80,
        h: 80,
        x: spawnLocation.x,
        y: spawnLocation.y,
        t: 'linkbox',
        d: {
          u: url,
          p: false,
          title: title,
          s: 0,
          st: '',
          favicon: '',
          uname: auth.user.name,
          uid: auth.user.id,
        },
      }).then(async ({ value, id }) => {
        onClose();
        /**
         * now update the favicon of this child
         */
        //  get the favicon from the url and save the address in the firebase
        const favicon = await getPreviewFavicon(url);
        updatePartiallyCanvasChild(props.spaceID, props.canvasID, id + '/d', '/favicon', favicon);
      });
    } else setMsg('Invalid URL.');
  };

  const onCancel = () => {
    /**
     * Close the modal
     */
    onClose();
  };

  const updateLinkBox = async () => {
    if (validURL(url)) {
      // editOptions.editData?.updateCallback({ u: url, title: title });
      editOptions.editData?.updateCallback(url, '/u');
      editOptions.editData?.updateCallback(title, '/title');
      onClose();
      const favicon = await getPreviewFavicon(url);
      editOptions.editData?.updateCallback(favicon, '/favicon');
    } else setMsg('Invalid URL.');
  };

  const onSave = () => {
    if (editOptions.isEditing) {
      updateLinkBox();
    } else {
      addLinkBox();
    }
  };

  useEffect(() => {
    newModalThunk(shareLinkBoxModalId, false)();
  }, []);

  useEffect(() => {
    if (isOpen) {
      if (editOptions.isEditing && editOptions.editData) {
        setURL(editOptions.editData.url);
        setTitle(editOptions.editData.title);
      }
    } else {
    }
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      id="linkbox-modal"
      contentLabel={`${shareLinkBoxModalId} Modal`}
      // overlayClassName="hide"
      shouldCloseOnOverlayClick={true}
      style={ModalStyle}
    >
      <div ref={ref} className="">
        <div className="linkbox-modal-header">{editOptions.isEditing ? 'Edit Link' : 'Create a new Link'}</div>
        <div className="content">
          <div className="input-box">
            <div className="label">
              Title <span className="required">*</span>
            </div>
            <input placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)}></input>
            {/* <div className="second-txt">You can search for links by their title in the sidebar</div> */}
          </div>

          <div className="input-box">
            <div className="label">
              URL <span className="required">*</span>
            </div>
            <input placeholder="Link" value={url} onChange={(e) => setURL(e.target.value)}></input>
            <div className="second-txt">This must be a full URL including https:// part</div>
          </div>
        </div>
        <div className="hor-divider"></div>
        <div className="actions">
          <button className="btn cancel" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn" onClick={onSave}>
            Save
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default ShareLinkBoxModal;
