import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../components/loader/loader';
import { getToken, signOut } from '../../helper/firebase/auth';
import { findGetParameter, handleSubdomain } from '../../helper/interactive/misc';
import rooms from '../../helper/requests/rooms';
import spaceObj from '../../helper/requests/spaces';
import { getAuth } from '../../store/auth';
import './addToSpace.scss';

const AddToSpace = () => {
  const auth = useSelector(getAuth);
  const roomID = findGetParameter('roomID');
  const spaceID = findGetParameter('spaceID');
  const spaceInfoSpaceID = findGetParameter('spaceInfoSpaceID');
  const params: any = useParams();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.user.isGuest && window.location.href.includes('/invite/') && !window.location.href.includes('/s/')) {
      signOut().then(() => {
        history.push('/login?signup=true&ng');
      });
    }
  }, [auth]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (spaceID) {
        try {
          const token = await getToken();
          if (!token) throw new Error('Login first.');
          const response = await spaceObj.useLink(params.link, token, spaceID);
          history.replace(handleSubdomain('/s/' + response.data.spaceDetails.spaceID));
        } catch (e) {
          setError(true);
          setLoading(false);
        }
      } else if (roomID && spaceInfoSpaceID) {
        try {
          const token = await getToken();
          if (!token) throw new Error('Login first.');
          const response = await rooms.useLink(params.link, token, roomID);
          history.replace(handleSubdomain(`/s/${spaceInfoSpaceID}/r/${response.data.roomDetails.id}`));
        } catch (e) {
          console.log(`Something went wrong: ${e.response?.data?.error}`);
          setError(true);
          setLoading(false);
        }
      } else {
        setError(true);
        setLoading(false);
      }
    })();
  }, [auth]);
  return (
    <>
      {loading && <Loader />}
      {!loading && error && (
        <div className="errorInviteContainer">
          <div className="errorMessageInvite">
            The invite link seems to be wrong. It might have expired, please ask the space owner or team member to copy
            the new link and share it with you.
            <div
              className="button"
              onClick={() => {
                window.location.href = '/';
              }}
            >
              Go Back to App
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddToSpace;
