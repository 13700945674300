import { addMinutes } from 'date-fns';
import firebase from 'firebase';
import { DateTime } from 'luxon';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Calendar, Check, ChevronDown, ChevronRight, User, X } from 'react-feather';
import { useSelector } from 'react-redux';
import { maxParticipants, spillOverLimit } from '../../../config';
import { getClaimToken } from '../../../helper/firebase/auth';
import { convertJSDateToLuxonFFormat, formatLong, getUserTimezone } from '../../../helper/interactive/misc';
import rooms from '../../../helper/requests/rooms';
import {
  setCreateRoomStepStore,
  toggleMembersMenuStore,
  togglePermissionMenuStore,
  toggleRoomSettingsMenuStore,
} from '../../../store';
import { getPermissionMembersOpenStateRedux, getRoomSettingsOpenStateRedux } from '../../../store/settingsMenu';
import { getSpacesRooms } from '../../../store/spacesRooms';
import TimeZonePicker from '../../formComponents/timezonePicker/timezonePicker';
import LoaderRing from '../../loader/loaderRing';
import './advancedSettings.scss';
import CreateNewRoom from './createNewRoom';
import MembersV2 from './members';

let database = firebase.database();

export const AdvancedSettings = forwardRef(
  (
    props: {
      onChange: (state: boolean) => void;
      isCreate?: boolean;
      isDynamicCreate?: boolean;
      createParams?: (
        password: string,
        startDate: number,
        endDate: number,
        timezone: { name: string; offset: number },
        capacity: number,
        spillOver: number
      ) => void;
      advanceParams?: (
        password: string,
        startDate: number,
        endDate: number,
        timezone: { name: string; offset: number },
        capacity: number,
        spillOver: number
      ) => void;
    },
    ref
  ) => {
    const [roomLimit, setRoomLimit] = useState(maxParticipants);
    const [roomSectionLimit, setRoomSectionLimit] = useState(Math.ceil(roomLimit * 0.8));
    const [passwordCheck, setPasswordCheck] = useState(false);
    const [password, setPassword] = useState('');
    const [showPass, setShowPass] = useState(false);
    const [scheduleRoomCheck, setScheduleRoomCheck] = useState(false);
    const [endRoom, setEndRoom] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(addMinutes(startDate, 15));
    const [timeZone, setTimeZone] = useState(getUserTimezone());

    useEffect(() => {
      if (props.isCreate) {
        let startTime = -1;
        let endTime = -1;
        if (scheduleRoomCheck) {
          startTime = DateTime.fromFormat(convertJSDateToLuxonFFormat(startDate), 'F', {
            zone: timeZone.name.split(' ').length > 1 ? timeZone.name.split(' ')[1] : 'Africa/Ouagadougou', //AFRICE/Ouagadougou is GMT
          })
            .toUTC()
            .toSeconds();
          if (endRoom) {
            endTime = DateTime.fromFormat(convertJSDateToLuxonFFormat(endDate), 'F', {
              zone: timeZone.name.split(' ').length > 1 ? timeZone.name.split(' ')[1] : 'Africa/Ouagadougou',
            })
              .toUTC()
              .toSeconds();
          }
        }
        if (props.createParams)
          props.createParams(passwordCheck ? password : '', startTime, endTime, timeZone, roomLimit, roomSectionLimit);
      }
    }, [
      props.isCreate,
      passwordCheck,
      password,
      scheduleRoomCheck,
      endRoom,
      startDate,
      endDate,
      timeZone,
      roomLimit,
      roomSectionLimit,
    ]);

    type ROOMDATA = {
      copyNumber: number;
      createdBy: string;
      createdOn: Date;
      guestAllowed: boolean;
      isCopy: boolean;
      isDeleted: boolean;
      isLocked: boolean;
      isOpen: boolean;
      name: string;
      parentRoom: string;
      spaceID: string;
      updatedOn: Date;
      passwordEnabled?: boolean;
      schedule?: {
        from: number;
        till: number;
        timezone: {
          timezoneOffset: number;
          timezoneStr: string;
        };
      };
    };

    const [roomCurrent, setRoomCurrent] = useState<ROOMDATA | null>(null);
    const spacesRooms = useSelector(getSpacesRooms);

    useEffect(() => {
      setRoomSectionLimit(Math.ceil(roomLimit * 0.8));
    }, [roomLimit]);

    useEffect(() => {
      if (roomCurrent?.passwordEnabled) {
        setPasswordCheck(true);
      } else {
        setPasswordCheck(false);
      }
    }, [roomCurrent]);

    useEffect(() => {
      if (props.isCreate) return;
      let unsub = () => {};
      let unsub2 = () => {};
      unsub = firebase
        .firestore()
        .collection('roomV2')
        .doc(spacesRooms.currentRoom)
        .onSnapshot((room) => {
          const roomData: ROOMDATA = room.data() as ROOMDATA;
          if (roomData) setRoomCurrent(roomData);
          if (roomData.schedule) {
            const offset = parseInt(roomData.schedule.timezone.timezoneOffset + '') || 0;
            const startTime = roomData.schedule.from;
            const endTime = roomData.schedule.till;
            setTimeZone({
              name: roomData.schedule.timezone.timezoneStr,
              offset: offset,
            });
            if (roomData.schedule.from !== -1) {
              setScheduleRoomCheck(true);
              setStartDate(new Date(startTime * 1000));
            }
            if (roomData.schedule.till !== -1) {
              setEndRoom(true);
              setEndDate(new Date(endTime * 1000));
            }
          }
        });
      unsub2 = firebase
        .firestore()
        .collection('roomV2')
        .doc(spacesRooms.currentRoom)
        .collection('secrets')
        .onSnapshot((docs) => {
          if (docs.empty) {
            return;
          }
          const doc = docs.docs[0];
          const secrets: any = doc.data();
          setPassword(secrets.password);
        });

      return () => {
        unsub();
        unsub2();
      };
    }, [spacesRooms.currentRoom]);

    useEffect(() => {
      if (props.isCreate) return;
      setRoomLimit(spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.capacity || maxParticipants);
      setRoomSectionLimit(spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.spillOverAt || spillOverLimit);
    }, [spacesRooms.rooms[spacesRooms.currentRoom]]);

    useEffect(() => {
      if (roomLimit > maxParticipants) setRoomLimit(maxParticipants);
      if (roomSectionLimit > roomLimit) setRoomSectionLimit(roomLimit);
    }, [roomLimit, roomSectionLimit]);

    useEffect(() => {
      if (!props.isCreate && roomCurrent) {
        let startTime = -1;
        let endTime = -1;
        if (scheduleRoomCheck) {
          startTime = DateTime.fromFormat(convertJSDateToLuxonFFormat(startDate), 'F', {
            zone: timeZone.name.split(' ').length > 1 ? timeZone.name.split(' ')[1] : 'Africa/Ouagadougou', //AFRICA/Ouagadougou is GMT
          })
            .toUTC()
            .toSeconds();
          if (endRoom) {
            endTime = DateTime.fromFormat(convertJSDateToLuxonFFormat(endDate), 'F', {
              zone: timeZone.name.split(' ').length > 1 ? timeZone.name.split(' ')[1] : 'Africa/Ouagadougou',
            })
              .toUTC()
              .toSeconds();
          }
        }
        if (props.advanceParams) {
          props.advanceParams(
            passwordCheck ? password : '',
            startTime,
            endTime,
            {
              name: timeZone.name.split(' ').length > 1 ? timeZone.name.split(' ')[1] : 'Africa/Ouagadougou',
              offset: timeZone.offset,
            },
            roomLimit,
            roomSectionLimit
          );
        }
      }
    }, [
      props.isCreate,
      roomCurrent,
      startDate,
      endDate,
      password,
      passwordCheck,
      roomLimit,
      roomSectionLimit,
      scheduleRoomCheck,
    ]);

    const DateRef = useRef<HTMLDivElement>(null);
    const CustomDateInputBox = forwardRef((props: any, ref) => (
      <div className="date-custom-input-box" ref={DateRef}>
        <span>{props.value}</span>
        <Calendar onClick={props.onClick} />
      </div>
    ));

    return (
      <>
        <div className="advanced-settings-container">
          <div className="advanced-setting-option-limit">
            <span>Limit each room to </span>
            <input
              className="room-limit-input"
              value={roomLimit}
              type="number"
              onChange={(e) => {
                setRoomLimit(parseInt(e.target.value) || maxParticipants);
                props.onChange(true);
              }}
            />
            <span> people</span>
          </div>
          {((!props.isCreate && spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.isOverflow) ||
            (props.isCreate && props.isDynamicCreate)) && (
            <div className="advanced-setting-option-dynamic-room-limit">
              <span>Automatically create room sections if there are more than </span>
              <input
                className="room-dynamic-limit-input"
                value={roomSectionLimit}
                type="number"
                onChange={(e) => {
                  setRoomSectionLimit(parseInt(e.target.value) || spillOverLimit);
                  props.onChange(true);
                }}
              />
              <span> people</span>
            </div>
          )}

          <div className="advanced-setting-option-enable-password">
            <div
              className="password-checkbox"
              onClick={() => {
                setPasswordCheck(!passwordCheck);
                props.onChange(true);
              }}
            >
              {passwordCheck && <Check color={'white'} size={12} />}
            </div>
            <span>Enable password</span>
            {passwordCheck && (
              <>
                <div className="password-input-box">
                  <input
                    className="password-input"
                    type={showPass ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      props.onChange(true);
                    }}
                  />
                  <div
                    className="showHidePassword"
                    style={password.length === 0 ? { visibility: 'hidden' } : { visibility: 'visible' }}
                    onClick={() => {
                      setShowPass(!showPass);
                    }}
                  >
                    {showPass ? <EyeSlashFill /> : <EyeFill />}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="advanced-setting-option-schedule-room-container">
            <div className="advanced-setting-option-schedule-room">
              <div
                className="schedule-room-checkbox"
                onClick={() => {
                  setScheduleRoomCheck(!scheduleRoomCheck);
                  props.onChange(true);
                }}
              >
                {scheduleRoomCheck && <Check color={'white'} size={12} />}
              </div>
              <span>Schedule Room</span>
            </div>
            {scheduleRoomCheck && (
              <div className="schedule-room-input-options">
                <div className="schedule-room-input-option-start">
                  <div className="schedule-room-input-option-start-label">
                    <span>Room Start Date and Time</span>
                  </div>
                  <div className="schedule-room-input-option-start-inputs">
                    <DatePicker
                      selected={startDate}
                      timeInputLabel="Time:"
                      dateFormat="MMM dd, yyyy h:mm aa"
                      showTimeInput
                      onChange={(d: any) => {
                        setStartDate(d);
                        setEndDate(addMinutes(d, 15));
                        props.onChange(true);
                      }}
                      customInput={<CustomDateInputBox />}
                    />
                  </div>
                </div>
                <div className="schedule-room-input-option-end">
                  <div className="schedule-room-input-option-end-label">
                    <div
                      className="schedule-room-input-option-end-label-checkbox"
                      onClick={() => {
                        setEndRoom(!endRoom);
                      }}
                    >
                      {endRoom && <Check color={'white'} size={12} />}
                    </div>
                    <span>Room End Date and Time</span>
                  </div>
                  {endRoom && (
                    <div className="schedule-room-input-option-end-inputs">
                      <DatePicker
                        selected={endDate}
                        minDate={startDate}
                        minTime={startDate}
                        timeInputLabel="Time:"
                        dateFormat="MMM dd, yyyy h:mm aa"
                        showTimeInput
                        onChange={(d: any) => {
                          setEndDate(d);
                          props.onChange(true);
                        }}
                        customInput={<CustomDateInputBox />}
                      />
                      {endDate < startDate && (
                        <div className="error-message">
                          End date cannot be less than Start Date. Please select valid end date.
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="schedule-room-input-option-timezone">
                  <div className="schedule-room-input-option-timezone-label">
                    <span>Timezone</span>
                  </div>
                  <div className="schedule-room-input-option-timezone-inputs">
                    <TimeZonePicker
                      value={timeZone}
                      onChange={(timeZoneSelected) => {
                        setTimeZone(timeZoneSelected);
                        props.onChange(true);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);

const SettingsSidebar = (props: {}) => {
  const { membersOpen, permissionOpen } = useSelector(getPermissionMembersOpenStateRedux);
  const setPermissionOpen = togglePermissionMenuStore;
  const setMembersOpen = toggleMembersMenuStore;
  const setRoomSettings = toggleRoomSettingsMenuStore;
  const roomSettings = useSelector(getRoomSettingsOpenStateRedux);
  const spacesRooms = useSelector(getSpacesRooms);
  const [numMembers, setNumMembers] = useState(0);
  const isAdmin = spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin;
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);
  const [saveState, setSaveState] = useState(false);
  const advancedSettingsRef = useRef<any>();
  const [loading, setLoading] = useState(false);
  const [advancedSettings, setAdvancedSettings] = useState<{
    password: string;
    startDate: number;
    endDate: number;
    timezone: { name: string; offset: number };
    capacity: number;
    spillOver: number;
  } | null>(null);
  const [basicSettings, setBasicSettings] = useState<{
    name: string;
    permission: 'public' | 'team' | 'private';
    emails?: string[];
  }>({
    name: spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.roomName ?? '',
    permission: 'public',
    emails: [],
  });

  return (
    <div className="sidebar__rooms-container">
      <div className="sidebar__rooms">
        <div className={'sidebar__rooms-header ' + (roomSettings ? 'roomsettings' : '')}>
          <div className="room-label">
            {formatLong(spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails.roomName ?? '', 24)} Settings
          </div>
          <div
            className="button-icon"
            style={{ paddingRight: '14px' }}
            onClick={() => {
              setRoomSettings(false);
              setCreateRoomStepStore('none');
            }}
          >
            <X />
          </div>
        </div>
        <div className="permissionOptions">
          {/* <div className="permissionOption bold">
            <div className="label">Notifications</div>
            <ToggleButtonV2
              val={toggles['noti'] === true}
              name={'notification'}
              onChange={(e) => {
                setToggles((toggles) => {
                  toggles['noti'] = e;
                  return toggles;
                });
              }}
            />
          </div> */}

          <CreateNewRoom
            toClose={() => {}}
            onChange={(state) => {
              setSaveState(state);
            }}
            newValues={(name, permission, emails) => {
              setBasicSettings({ name, permission, emails });
            }}
          />

          {isAdmin && (
            <div className="settingsSection">
              <div
                className="settingHeading"
                onClick={() => {
                  setMembersOpen(!membersOpen);
                  setPermissionOpen(false);
                }}
              >
                <div className="leftSide">
                  {membersOpen ? <ChevronDown size={14} /> : <ChevronRight size={14} />}
                  Members
                </div>
                {membersOpen && (
                  <div className="onlineUser">
                    <User />
                    <div className="numOnline">{numMembers}</div>
                  </div>
                )}
              </div>
              {membersOpen && <MembersV2 setMembersCount={(num) => setNumMembers(num)} />}
            </div>
          )}
          <div className="settingsSection">
            <div
              className="settingHeading"
              onClick={() => {
                setAdvancedSettingsOpen(!advancedSettingsOpen);
                setMembersOpen(false);
              }}
            >
              <div className="leftSide">
                {advancedSettingsOpen ? <ChevronDown size={14} /> : <ChevronRight size={14} />}
                Advanced Settings
              </div>
            </div>
            <div style={{ display: advancedSettingsOpen ? 'flex' : 'none' }}>
              <AdvancedSettings
                ref={advancedSettingsRef}
                onChange={(state) => {
                  setSaveState(state);
                }}
                advanceParams={(password, startDate, endDate, timezone, capacity, spillOver) => {
                  setAdvancedSettings({
                    password,
                    startDate,
                    endDate,
                    timezone,
                    capacity,
                    spillOver,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="btnContainer"
          style={{
            justifyContent: 'flex-end',
          }}
        >
          <>
            {!saveState && (
              <div
                className="saveBtn"
                onClick={() => {
                  setRoomSettings(false);
                }}
              >
                Done
              </div>
            )}
            {saveState && (
              <>
                <div
                  className="cancel"
                  onClick={() => {
                    if (loading) return;
                    setAdvancedSettingsOpen(false);
                    setTimeout(() => setAdvancedSettingsOpen(true), 300);
                    setSaveState(false);
                  }}
                >
                  Cancel
                </div>
                <div
                  className="saveBtn"
                  onClick={async () => {
                    if (loading) return;
                    setLoading(true);
                    const token = await getClaimToken(false, false);
                    if (token?.token && advancedSettings) {
                      await rooms.edit(
                        basicSettings.name,
                        token.token,
                        basicSettings.permission === 'private',
                        basicSettings.permission !== 'team',
                        false,
                        spacesRooms.currentRoom,
                        spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.isOverflow ?? false,
                        advancedSettings.spillOver,
                        advancedSettings.capacity,
                        advancedSettings.startDate,
                        advancedSettings.endDate,
                        advancedSettings.timezone,
                        advancedSettings.password,
                        basicSettings.emails
                      );
                    }
                    setLoading(false);
                  }}
                >
                  {!loading ? 'Save' : <LoaderRing size={'16px'} color={'black'} />}
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default SettingsSidebar;
