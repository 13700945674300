import Bowser from 'bowser';
import { formatRelative } from 'date-fns';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import Route from 'route-parser';
import { canvasSize, mainSubDomain } from '../../config';
import { setRestrictedZoneVisibleStore } from '../../store';
import { xy } from '../../types/canvasFB';

export function findGetParameterFromURL(parameterName: string, urlString: string) {
  const url = new URL(urlString);
  const value = url.searchParams.get(parameterName)!;
  return value;
}

export function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function findGetParameter(parameterName: string) {
  var result: undefined | string,
    tmp = [];
  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

export function sq(x: number) {
  return Math.pow(x, 2);
}

export const scale = (num: number, in_min: number, in_max: number, out_min: number, out_max: number) => {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

export const getSpawnLocation = (listenerPosition: xy, radius: number) => {
  const getRandom = () => (Math.random() * 2 - 1) * radius;
  let x = listenerPosition.x + getRandom();
  let y = listenerPosition.y + getRandom();
  if (x > canvasSize.w) x = canvasSize.w - 100;
  if (x < 0) x = 100;
  if (y > canvasSize.h) y = canvasSize.h - 100;
  if (y < 0) y = 100;
  return { x, y };
};

export function debounce<Params extends any[]>(func: (...args: Params) => any, delay: number) {
  let inDebounce: NodeJS.Timeout;
  return function (...args: Params) {
    // @ts-ignore
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func(...args), delay);
  };
}

export function clip(val: number, low: number, high: number) {
  if (val > high) return high;
  if (val < low) return low;
  return val;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}
export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getInitials = (name: string) => {
  try {
    const parts = name.split(' ');
    if (parts.length == 1 || parts[parts.length - 1].length == 0) {
      return name[0].toUpperCase();
    } else {
      return parts[0][0].toUpperCase() + parts[parts.length - 1][0].toUpperCase();
    }
  } catch {
    return name;
  }
};

export const getFirstName = (name: string) => {
  try {
    return name.split(' ')[0];
  } catch {
    return name;
  }
};

export const getFileName = (path: string) => {
  let parts = path.split('/');
  if (parts.length < 2) {
    parts = path.split('\\');
    return parts[parts.length - 1];
  }
  return parts[parts.length - 1];
};

export const convertCamelcaseToRegular = (text: string) => {
  let result = text.replace(/([A-Z])/g, ' $1');
  let finalResult = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
  return finalResult;
};

export const checkIfImgUrlIsValid = (url: string) => {
  return new Promise<boolean>((resolve) => {
    const img_ = new Image();
    img_.onload = function () {
      resolve(true);
    };
    img_.onerror = () => {
      resolve(false);
    };
    img_.src = url;
  });
};

export const formatLong = (text: string, length: number) => {
  if (!text) return '';
  if (text.length < length) return text;
  else return text.slice(0, length) + '...';
};

export const handleSubdomain = (url: string) => {
  if (!window.location.href.includes(mainSubDomain) && !window.location.href.includes('http://localhost')) {
    let route = new Route('/s/:spaceID/r/:roomID');
    if (url.includes('/c/')) {
      route = new Route('/s/:spaceID/r/:roomID/c/:appID');
      const ids = route.match(url);
      if (ids) {
        const { roomID, spaceID, appID } = ids;
        return `/r/${roomID}/c/${appID}`;
      }
    } else if (!url.includes('/r/')) {
      route = new Route('/s/:spaceID');
      return ``;
    }
    const ids = route.match(url);
    if (ids) {
      const { roomID, spaceID, appID } = ids;
      return `/r/${roomID}`;
    }
  }
  return url;
};

export const getMainDomain = () => {
  var parts = window.location.hostname.split('.');
  parts.shift();
  var upperleveldomain = parts.join('.');
  return upperleveldomain;
};

export const isSubDomain = () => {
  return !window.location.href.includes(mainSubDomain) && !window.location.href.includes('localhost');
};

export const getFrontendURL = () => {
  return `${window.location.protocol}//${window.location.hostname}`;
};

export function useTitle(title: string) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  });
}

export function playSound(url: string) {
  const audio = new Audio(url);
  audio.volume = 0.25;
  audio.play();
}

export function getSupportedMimeTypes() {
  const VIDEO_TYPES = ['webm', 'ogg', 'mp4', 'x-matroska'];
  const VIDEO_CODECS = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus'];

  const supportedTypes: string[] = [];
  VIDEO_TYPES.forEach((videoType) => {
    const type = `video/${videoType}`;
    VIDEO_CODECS.forEach((codec) => {
      const variations = [
        `${type};codecs=${codec}`,
        `${type};codecs:${codec}`,
        `${type};codecs=${codec.toUpperCase()}`,
        `${type};codecs:${codec.toUpperCase()}`,
        `${type}`,
      ];
      variations.forEach((variation) => {
        //@ts-ignore
        if (MediaRecorder.isTypeSupported(variation)) supportedTypes.push(variation);
      });
    });
  });
  return supportedTypes;
}

export function getBrowserDetails() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getBrowserName(true);
}

export function detectIfInRestrictedZone(
  oldxy: { x: number; y: number },
  xy: { x: number; y: number; d: number },
  zones: { [id: string]: { x: number; y: number; width: number; height: number } }
) {
  let newTranslate = { x: xy.x, y: xy.y, d: xy.d };
  if (_.keys(zones).length === 0) return newTranslate;
  let newX = newTranslate.x;
  let newY = newTranslate.y;
  const dx = newX - oldxy.x;
  const dy = newY - oldxy.y;
  let x = { left: newX, right: newX };
  let y = { top: newY, bottom: newY };
  for (let id in zones) {
    let flag = false;
    const zone = zones[id];
    if (
      xy.x + xy.d > zone.x &&
      xy.y + xy.d > zone.y &&
      xy.y < zone.y + zone.height &&
      xy.x + xy.d < zone.x + zone.width
    ) {
      x.left = zone.x - xy.d - 10;
      flag = true;
    }
    if (xy.x < zone.x + zone.width && xy.y + xy.d > zone.y && xy.y < zone.y + zone.height && xy.x + xy.d > zone.x) {
      x.right = zone.x + zone.width + 10;
      flag = true;
    }
    if (
      xy.y + xy.d > zone.y &&
      xy.x + xy.d > zone.x &&
      xy.x < zone.x + zone.width &&
      xy.y + xy.d < zone.y + zone.height
    ) {
      y.top = zone.y - xy.d - 10;
      flag = true;
    }
    if (xy.y < zone.y + zone.height && xy.x + xy.d > zone.x && xy.x < zone.x + zone.width && xy.y > zone.y) {
      y.bottom = zone.y + zone.height + 10;
      flag = true;
    }
    if (flag) {
      setRestrictedZoneVisibleStore(id, true);
      setTimeout(() => {
        setRestrictedZoneVisibleStore(id, false);
      }, 2000);
    }
  }
  if (Math.abs(dx) > Math.abs(dy))
    if (dx === 0) {
      newTranslate.x = newX;
    } else if (dx > 0) {
      newTranslate.x = x.left;
    } else {
      newTranslate.x = x.right;
    }
  else {
    if (dy === 0) {
      newTranslate.y = newY;
    } else if (dy > 0) {
      newTranslate.y = y.top;
    } else {
      newTranslate.y = y.bottom;
    }
  }
  return newTranslate;
}

export function generateRoomLink(spaceID: string, roomID: string) {
  const roomLink = `${getFrontendURL()}:3000${handleSubdomain(`/s/${spaceID}/r/${roomID}`)}`;
  return roomLink;
}

export function getUserTimezone() {
  const userTz = DateTime.local().zoneName;
  let { 0: continent, length, [length - 1]: city } = userTz.split('/');
  if (city === 'Calcutta') city = 'Kolkata';
  const timeZoneToUseInTheSelect = `${continent}/${city}`;
  return { name: timeZoneToUseInTheSelect, offset: DateTime.local().offset };
}

export function getUserTimezoneString() {
  return `
  ${Math.floor(DateTime.local().offset / 60)}:${DateTime.local().offset % 60} ${DateTime.local().offsetNameLong} ${
    DateTime.local().zoneName
  }
  `;
}

function formatAMPM(date: Date) {
  let hours = date.getHours();
  let minutes: any = date.getMinutes();
  let seconds: any = date.getSeconds();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  return hours + ':' + minutes + ':' + seconds + ' ' + ampm;
}

export function convertJSDateToLuxonFFormat(d: Date) {
  return d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear() + ', ' + formatAMPM(d);
}

export function utcToLocalTime(time: string) {
  const timestamp = parseInt(time);
  const date = new Date(DateTime.fromSeconds(timestamp).toISO());
  return formatRelative(date, new Date());
}

export const useProgressiveImage = (src: string, fallbackSrc: string) => {
  const [sourceLoaded, setSourceLoaded] = useState<string | null>(null);
  useEffect(() => {
    const mainImage = new Image();
    const fallbackImage = new Image();

    mainImage.src = src;
    fallbackImage.src = fallbackSrc;

    mainImage.onload = () => {
      setSourceLoaded(src);
    };

    fallbackImage.onload = () => {
      setSourceLoaded(fallbackSrc);
    };
  }, [src, fallbackSrc]);

  return sourceLoaded;
};
