import firebase from 'firebase';
import { v4 } from 'uuid';
import {
  getAuthState,
  getCurrentRoomCopy,
  getCurrentRoomFromStore,
  getCurrentRoomPermissionFromStore,
  getCurrentSpace,
  getIsRecordingStore,
} from '../../store';

export let presenceInterval: NodeJS.Timeout | null = null;

export function initPresence() {
  // Create a reference to this user's specific status node.
  // This is where we will store data about being online/offline.
  const sessionID = v4();
  const auth = getAuthState();
  var userStatusDatabaseRef = firebase.database().ref('/status/' + auth.user.id + '/' + sessionID);

  // We'll create two constants which we will write to
  // the Realtime database when this device is offline
  // or online.
  var isOfflineForDatabase = {
    till: firebase.database.ServerValue.TIMESTAMP,
  };

  var isOfflinePresence = {
    online: false,
    name: auth.userDetails.name.length < 1 ? auth.user.name : auth.userDetails.name,
    profilePic: auth.userDetails.profilePic,
    email: auth.user.email,
    permission: getCurrentRoomPermissionFromStore(),
    isRecording: getIsRecordingStore(),
    roomCopy: getCurrentRoomCopy(),
  };

  var isOnlinePresence = {
    online: true,
    name: auth.userDetails.name.length < 1 ? auth.user.name : auth.userDetails.name,
    profilePic: auth.userDetails.profilePic,
    email: auth.user.email,
    permission: getCurrentRoomPermissionFromStore(),
    isRecording: getIsRecordingStore(),
    roomCopy: getCurrentRoomCopy(),
  };

  var isOnlineForDatabase = {
    from: firebase.database.ServerValue.TIMESTAMP,
  };

  if (presenceInterval) clearInterval(presenceInterval);
  presenceInterval = setInterval(async () => {
    const auth = getAuthState();
    const presenceRef = firebase
      .database()
      .ref(`space/${getCurrentSpace()}/room/${getCurrentRoomFromStore()}/presence/${auth.user.id}`);
    await presenceRef.set({
      name: auth.userDetails.name.length < 1 ? auth.user.name : auth.userDetails.name,
      profilePic: auth.userDetails.profilePic,
      email: auth.user.email,
      online: true,
      permission: getCurrentRoomPermissionFromStore(),
      timestamp: new Date().getTime(),
      isRecording: getIsRecordingStore(),
      roomCopy: getCurrentRoomCopy(),
    });
    try {
      await firebase.firestore().collection('analytics').doc(`${sessionID}`).update({
        spaceID: getCurrentSpace(),
        roomID: getCurrentRoomFromStore(),
        userID: auth.user.id,
        till: firebase.firestore.FieldValue.serverTimestamp(),
      });
    } catch {
      await firebase.firestore().collection('analytics').doc(`${sessionID}`).set({
        spaceID: getCurrentSpace(),
        roomID: getCurrentRoomFromStore(),
        userID: auth.user.id,
        from: firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
  }, 20000);
  firebase
    .database()
    .ref('.info/connected')
    .on('value', function (snapshot) {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() === false) {
        return;
      }

      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      const presenceRef = firebase
        .database()
        .ref(`space/${getCurrentSpace()}/room/${getCurrentRoomFromStore()}/presence/${auth.user.id}`);
      presenceRef
        .onDisconnect()
        .update(isOfflinePresence)
        .then(function () {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect()
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.
          presenceRef.update({
            timestamp: new Date().getTime(),
            ...isOnlinePresence,
          });
        });
    });
  // Create a reference to the special '.info/connected' path in
  // Realtime Database. This path returns `true` when connected
  // and `false` when disconnected.
  firebase
    .database()
    .ref('.info/connected')
    .on('value', function (snapshot) {
      // If we're not currently connected, don't do anything.
      if (snapshot.val() === false) {
        return;
      }

      // If we are currently connected, then use the 'onDisconnect()'
      // method to add a set which will only trigger once this
      // client has disconnected by closing the app,
      // losing internet, or any other means.
      userStatusDatabaseRef
        .onDisconnect()
        .update(isOfflineForDatabase)
        .then(function () {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect()
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.
          userStatusDatabaseRef.update(isOnlineForDatabase);
        });
    });
  return sessionID;
}

export function getPresence(spaceID: string, roomID: string, callback: Function) {
  const cb = (snapshot: any) => {
    callback(snapshot.val());
  };
  const presenceRef = firebase.database().ref(`space/${spaceID}/room/${roomID}/presence`);
  presenceRef.on('value', cb);
  return () => presenceRef.off('value', cb);
}
