import * as mediasoupClient from 'mediasoup-client';
import { v4 } from 'uuid';
import { yetiServerIP } from './config';
import { Socket } from './socket';
import { handshakeStep } from './types/types';

export class Consumer {
  socket?: Socket;
  deviceConsumer: mediasoupClient.types.Device;
  consumerTransport?: mediasoupClient.types.Transport;
  consumerID: string;
  pinger?: NodeJS.Timeout;
  onStop?: () => void;

  constructor() {
    this.deviceConsumer = new mediasoupClient.Device();
    this.consumerID = v4();
  }

  destroy() {
    if (this.onStop) {
      this.onStop();
    }
    if (this.pinger) {
      clearInterval(this.pinger);
    }
    if (this.socket) {
      this.socket?.socket.close();
    }
  }

  connect(streamID: string, producerID: string, routerID: string) {
    return new Promise<MediaStreamTrack>((resolve) => {
      this.socket = new Socket();
      this.socket.connect(yetiServerIP);
      this.socket.socket.onopen = () => {
        this.pinger = setInterval(() => {
          this.socket?.send({ type: 'ping', payload: { participantID: this.consumerID } });
        }, 2000);
      };
      this.socket.socket.onclose = () => {
        if (this.onStop) this.onStop();
      };
      this.socket.onmessage((e) => {
        const handshake: handshakeStep = JSON.parse(e.data);
        switch (handshake.type) {
          case 'server-config': {
            (async () => {
              await this.deviceConsumer.load({
                routerRtpCapabilities: handshake.payload.consumerRouterRtpCapabilities,
              });
              this.consumerTransport = this.deviceConsumer.createRecvTransport(handshake.payload.consumerTransport);
              this.consumerTransport.on('connect', ({ dtlsParameters }, callback) => {
                this.socket?.send({
                  type: 'transport-connect',
                  payload: {
                    participantID: this.consumerID,
                    kind: 'consumer',
                    dtlsParameters,
                  },
                });
                callback();
              });
              this.socket?.send({
                type: 'consumer-init',
                payload: {
                  participantID: this.consumerID,
                  streamID,
                  producerID,
                  routerID,
                  rtpCapabilities: this.deviceConsumer.rtpCapabilities,
                },
              });
            })();
            break;
          }
          case 'consumer-created': {
            (async () => {
              if (this.consumerTransport) {
                const consumer = await this.consumerTransport.consume({
                  id: handshake.payload.consumerID,
                  kind: handshake.payload.consumerKind,
                  rtpParameters: handshake.payload.rtpParams,
                  producerId: handshake.payload.producerID,
                });
                this.socket?.send({
                  type: 'consumer-resume',
                  payload: {
                    participantID: this.consumerID,
                    streamID: handshake.payload.streamID,
                  },
                });
                const { track } = consumer;
                consumer.observer.on('close', () => {
                  if (this.onStop) this.onStop();
                });
                consumer.observer.on('pause', () => {
                  if (this.onStop) this.onStop();
                });
                consumer.observer.on('trackended', () => {
                  if (this.onStop) this.onStop();
                });
                consumer.observer.on('transportclose', () => {
                  if (this.onStop) this.onStop();
                });
                this.consumerTransport.observer.on('close', () => {
                  if (this.onStop) this.onStop();
                });
                resolve(track);
              }
            })();
            break;
          }
        }
      });
      this.socket?.send({
        type: 'init',
        payload: {
          participantID: this.consumerID,
        },
      });
    });
  }
}
