import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/modal.scss';

export default function Modal(props: any) {
  if (!props.isOpen) return null;

  const newmodal = document.getElementById('newmodal');
  if (!newmodal) return null;
  return ReactDOM.createPortal(
    <>
      <div className="overlay"></div>
      <div className={'modal ' + (props.className ? props.className : '')} style={props.style}>
        {props.children}
      </div>
    </>,
    newmodal
  );
}
