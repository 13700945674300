import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp, Info, Search, X } from 'react-feather';
import { useSelector } from 'react-redux';
import { createApi, OrderBy } from 'unsplash-js';
import ReslashLogo from '../../assets/svg/reslashLogo';
import { fileUploadLimit } from '../../config';
import { getClaimToken } from '../../helper/firebase/auth';
import {
  getCanvasChildren,
  setTemplate,
  setWallpaper,
  setWallpaperV2,
  watchTemplates,
} from '../../helper/interactive/firebaseRTD';
import logger from '../../helper/logger';
import { uploadImage, uploadImageFromURL } from '../../helper/requests/imageUpload';
import rooms from '../../helper/requests/rooms';
import { Switcher } from '../../pages/settingsV2/crowdManagement';
import {
  setCreateRoomStepStore,
  setHighlightedRoomStore,
  setUploadProgressWall,
  toggleBackgroundMenuOpenStore,
} from '../../store';
import { getCreateRoomParamsRedux } from '../../store/createRoomFlow';
import { getSpacesRooms } from '../../store/spacesRooms';
import {
  getIsBackgroundLoading,
  getUploadProgress,
  getWallpaperRedux,
  getWallpaperV2Redux,
} from '../../store/wallpaper';
import LoaderRing from '../loader/loaderRing';
import './customiseBackground.scss';

const BackgroundUploadBox = (props: {
  isImageURL: boolean;
  isUpload: boolean;
  URL: string;
  setProperties: (properties: { backgroundSize: string; backgroundPosition: string; backgroundRepeat: string }) => void;
}) => {
  const imageTypeOptions = [
    { item: 'Fill', value: 'fill' },
    { item: 'Fit', value: 'fit' },
    { item: 'Tile', value: 'tile' },
  ];

  const [imageType, setImageType] = useState('fill');

  const [msg, setMsg] = useState('IMAGE WILL SHOW UP HERE');
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [backgroundStyle, setBackgroundStyle] = useState<React.CSSProperties>({});
  const [showImagePatternSwitcher, setShowImagePatternSwitcher] = useState(false);

  const loadImg = (url: string) => {
    const img_ = new Image();
    setLoading(true);
    img_.onload = function () {
      setImage(url);
      setLoading(false);
    };
    img_.onerror = () => {
      setMsg('INVALID URL');
      setLoading(false);
    };
    img_.src = url;
  };

  const getBackgroundStyle = () => {
    let _backgroundStyle: React.CSSProperties;
    if (imageType === 'fit') {
      _backgroundStyle = {
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${image})`,
      };
    } else if (imageType === 'fill') {
      _backgroundStyle = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${image})`,
      };
    } else if (imageType === 'tile') {
      _backgroundStyle = {
        backgroundSize: '40px',
        backgroundPosition: 'center',
        backgroundRepeat: 'repeat',
        backgroundImage: `url(${image})`,
      };
    } else {
      _backgroundStyle = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${image})`,
      };
    }

    return _backgroundStyle;
  };

  useEffect(() => {
    if (props.URL !== '') {
      loadImg(props.URL);
      setBackgroundStyle(getBackgroundStyle());
    }
  }, [image, props.URL]);

  useEffect(() => {
    setBackgroundStyle(getBackgroundStyle());
  }, [imageType]);

  useEffect(() => {
    props.setProperties({
      backgroundSize: imageType !== 'tile' ? (backgroundStyle.backgroundSize as string) : '200px',
      backgroundPosition: backgroundStyle.backgroundPosition as string,
      backgroundRepeat: backgroundStyle.backgroundRepeat as string,
    });
  }, [backgroundStyle, imageType]);

  useEffect(() => {
    setImage('');
    setBackgroundStyle({});
    if (props.URL === '') setMsg('IMAGE WILL SHOW UP HERE');
  }, [props.URL]);

  return (
    <>
      <div className="background-preview-section-container">
        <div
          className="background-preview-container"
          onMouseEnter={(e) => {
            setShowImagePatternSwitcher(true);
          }}
          onMouseLeave={(e) => {
            setShowImagePatternSwitcher(false);
          }}
        >
          <div className="background-preview-inner" style={backgroundStyle}>
            {!loading && image === '' && (
              <div className="background-inner-msg-template">
                <span>{msg}</span>
                {msg === 'IMAGE WILL SHOW UP HERE' && <p>Recommended size is 3200 x 1800 px</p>}

                {msg === 'INVALID URL' && <p>Check the image url and enter again</p>}
              </div>
            )}
            <div className="background-preview-logo">
              <ReslashLogo size={18} />
            </div>

            <div
              className="background-image-pattern-switcher"
              style={!showImagePatternSwitcher ? { visibility: 'hidden' } : {}}
            >
              <Switcher
                options={imageTypeOptions}
                itemStyle={{
                  padding: '12px 18px',
                  width: '54px',
                  minWidth: '54px',
                  height: '24px',
                  lineHeight: '15px',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  border: 'none',
                  backgroundColor: '#4e5159',
                }}
                selectedItem={(type) => {
                  setImageType(type);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TemplateSection = (props: {
  setBackground: (
    backgroundURLs: { original: string; full: string; regular: string; small: string; thumbnail: string },
    backgroundType: 'template' | 'wallpaper',
    data?: any
  ) => void;
}) => {
  const unsplash = createApi({
    accessKey: 'QuQloxA_Jjsv7SsOfVFD1pPNglEwGbBfpmZ0qSltJEg',
  });

  const [templates, setTemplates] = useState<{ title: string; img: string; gif: string; data: any }[]>([]);
  const [photos, setPhotos] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [searchOrPopular, setSearchOrPopular] = useState<'search' | 'popular'>('popular');
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState<string>('');
  const [templateWallDD, setTemplateWallDD] = useState(false);
  const templateWallRef = useRef<HTMLDivElement | null>(null);
  const [templateOrWall, setTemplateOrWall] = useState<'all' | 'wallpapers' | 'templates'>('all');
  const [currentWall, setCurrentWall] = useState<string | null>(null);
  const spacesRooms = useSelector(getSpacesRooms);

  const loadMore = () => {
    if (photos.length === 0) return;
    if (searchOrPopular === 'popular') {
      unsplash.photos.list({ perPage: 10, orderBy: OrderBy.POPULAR, page: page }).then((result) => {
        if (result.response) {
          setPhotos([...photos, ...result.response.results]);
          setPage(page + 1);
        }
      });
    } else {
      unsplash.search.getPhotos({ query: search, perPage: 10, page: page }).then((result) => {
        if (result.response) {
          setPhotos([...photos, ...result.response.results]);
          setPage(page + 1);
        }
      });
    }
  };

  const bottomScrollRef = useBottomScrollListener(loadMore, {
    offset: 100,
    debounce: 500,
    triggerOnNoScroll: false,
  });

  const [selectedTemplate, setSelectedTemplate] = useState<{
    title: string;
    img: string;
    gif: string;
    data: any;
  } | null>(null);

  const searchTextRef = useRef('');

  useEffect(() => {
    setSearchOrPopular('popular');
    unsplash.photos.list({ perPage: 10, orderBy: OrderBy.POPULAR }).then((result) => {
      if (result.response) {
        setPhotos([...result.response.results]);
        setPage(2);
      }
    });
  }, []);

  const SearchUnsplash = (search: string) => {
    if (search.length === 0) {
      setSearchOrPopular('popular');
      setPhotos([]);
      setPage(0);
      unsplash.photos.list({ perPage: 10, orderBy: OrderBy.POPULAR, page: page }).then((result) => {
        if (result.response) {
          setPhotos([...photos, ...result.response.results]);
          setPage(page + 1);
        }
      });
    } else {
      setSearchOrPopular('search');
      unsplash.search.getPhotos({ query: search, perPage: 10, page: 1 }).then((result) => {
        setPhotos([]);
        if (result.response) {
          setPhotos([...result.response.results]);
          setPage(2);
        }
      });
    }
  };
  useEffect(() => {
    SearchUnsplash(search);
  }, [search]);

  useEffect(() => {
    searchTextRef.current = searchText;
  }, [searchText]);

  useEffect(() => {
    const unsub = watchTemplates((templates) => {
      setTemplates(templates);
      // setSelectedTemplate(templates[0]);
      setIsLoading(false);
    });
    return () => unsub();
  }, []);

  const filterTemplates = (search: string, templateList: typeof templates) => {
    if (search.length < 1) return templateList;
    return _.filter(templateList, (template) => {
      return template.title.toLowerCase().includes(search.toLowerCase());
    });
  };

  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      {!isLoading && (
        <div className="template-background-container">
          <div className="switchTemplateWallpaper">
            <div className="templateSwitch">
              <div className="label" onClick={() => setTemplateWallDD(!templateWallDD)}>
                {templateOrWall}
                {!templateWallDD ? <ChevronDown size={14} /> : <ChevronUp size={14} />}
              </div>

              {templateWallDD && (
                <div className="templateOrWallpaper" ref={templateWallRef}>
                  <div
                    className="option"
                    onClick={() => {
                      setTemplateOrWall('all');
                      setTemplateWallDD(false);
                    }}
                  >
                    ALL
                  </div>
                  <div
                    className="option"
                    onClick={() => {
                      setTemplateOrWall('templates');
                      setTemplateWallDD(false);
                    }}
                  >
                    TEMPLATES
                  </div>
                  <div
                    className="option"
                    onClick={() => {
                      setTemplateOrWall('wallpapers');
                      setTemplateWallDD(false);
                    }}
                  >
                    WALLPAPERS
                  </div>
                </div>
              )}
            </div>
            <div className="inputSearch">
              <input
                placeholder="Search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (e.target.value.length < 1) setSearch('');
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setSearch(searchTextRef.current);
                  }
                }}
              />
              <Search
                onClick={() => {
                  setSearch(searchTextRef.current);
                }}
              />
            </div>
          </div>
          {/* @ts-ignore */}
          <div className="templateWallpaper" ref={bottomScrollRef}>
            {(templateOrWall == 'all' || templateOrWall == 'templates') && (
              <>
                <div className="templates">
                  {_.map(filterTemplates(search, templates), (template) => {
                    return (
                      <div
                        key={template.title}
                        className={'img-container ' + (selectedTemplate?.title === template.title ? 'selected' : '')}
                        onClick={() => {
                          setCurrentWall(null);
                          setSelectedTemplate(template);
                          // setTemplateForRoom(template.data);
                          props.setBackground(
                            {
                              original: template.data.wallpaper,
                              full: template.data.wallpaper,
                              regular: template.data.wallpaper,
                              small: template.data.wallpaper,
                              thumbnail: template.img,
                            },
                            'template',
                            template.data
                          );
                        }}
                      >
                        <div className="img">
                          <img src={template.img} alt="template" className="static" />
                        </div>
                        <div className="title">{template.title}</div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {(templateOrWall == 'all' || templateOrWall == 'wallpapers') && (
              <>
                {templateOrWall === 'all' && <div className="label">WALLPAPERS</div>}
                <div className="templates">
                  {_.map(photos, (photo) => {
                    // const photoURL = photo.urls?.raw+"&q=80&fm=jpg&w=1080&fit=max";
                    return (
                      <div
                        key={photo.id}
                        className={'img-container ' + (currentWall === photo.urls?.regular ? 'selected' : '')}
                        onClick={() => {
                          setCurrentWall(photo.urls?.regular);
                          setSelectedTemplate(null);
                          props.setBackground(
                            {
                              original: photo.urls?.raw,
                              full: photo.urls?.raw + '&q=30',
                              regular: photo.urls?.regular,
                              small: photo.urls?.small,
                              thumbnail: photo.urls?.thumb,
                            },
                            'wallpaper'
                          );
                        }}
                      >
                        <div className="img">
                          <img src={photo.urls?.thumb} alt="template" className="static" />
                        </div>
                        <div className="attr-unsplash">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={photo.user.links.html + '?utm_source=reslash&utm_medium=referral'}
                          >
                            {photo.user.name}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {isLoading && (
        <div
          style={{ display: 'flex', width: '100%', height: '100vh', justifyContent: 'center', alignItems: 'center' }}
        >
          <LoaderRing />
        </div>
      )}
    </>
  );
};

const CustomiseBackground = (props: { toClose: () => void }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [showLinkBox, setShowLinkBox] = useState(false);
  const [imageURL, setImageURL] = useState<any>('');
  const roomParams = useSelector(getCreateRoomParamsRedux);

  const [templateData, setTemplateData] = useState<any>(null);

  const [isImageURL, setIsImageURL] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isTemplate, setIsTemplate] = useState(false);
  const [isWallpaper, setIsWallpaper] = useState(false);

  const inputImageFile = useRef<HTMLInputElement>(null);
  const spacesRooms = useSelector(getSpacesRooms);
  const [uploadFile, setUploadFile] = useState<any>();

  const [imageStatus, setImageStatus] = useState<'Apply' | 'Uploading' | 'Processing' | 'Applying' | 'Creating'>(
    'Apply'
  );
  const [message, setMessage] = useState('');

  const [bgProp, setBgProp] = useState<{
    backgroundSize: string;
    backgroundPosition: string;
    backgroundRepeat: string;
  }>({
    backgroundSize: '',
    backgroundPosition: '',
    backgroundRepeat: '',
  });
  const [bgs, setbgs] = useState<{
    original: string;
    full: string;
    regular: string;
    small: string;
    thumbnail: string;
  }>({
    original: '',
    full: '',
    regular: '',
    small: '',
    thumbnail: '',
  });

  const setTemplateForRoom = (data: any, roomID: string) => {
    /**
     * Get all the current canvas children
     */

    logger.info('Template changed.', { data });

    getCanvasChildren(spacesRooms.currentSpace, spacesRooms.currentRoom, (oldData: any) => {
      /**
       * take the union of oldData from canvas and newData from the template
       */
      const newData: any = {};
      if (roomParams.step !== 'background')
        Object.keys(oldData || {}).forEach((key: string) => {
          if (oldData[key].t !== 'gif') {
            newData[key] = oldData[key];
          }
        });
      setTemplate(spacesRooms.currentSpace, roomID, {
        ...data,
        obj: { ...newData, ...(data.obj || {}) },
      });
    });
  };

  const createRoomFunc = async () => {
    const space = spacesRooms.spaces[spacesRooms.currentSpace];
    const token = await getClaimToken();
    if (token) {
      const roomDetails = (
        await rooms.create(
          roomParams.name,
          token.token,
          space?.spaceID ?? '',
          roomParams.type !== 'team',
          roomParams.roomType === 'dynamic',
          roomParams.spillOverAt,
          roomParams.limit,
          roomParams.startTime,
          roomParams.endTime,
          roomParams.timeZone,
          roomParams.password,
          roomParams.type === 'private',
          roomParams.emails
        )
      ).data.room;
      await rooms.setRoomCustomClaim(roomDetails.id, token.token);
      return roomDetails;
    } else {
      throw 'No token, please login again.';
    }
  };

  const onUploadChange = (e: any) => {
    const file = e.target.files[0];
    setImageURL('');
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      async () => {
        setImageURL(reader.result);
        setUploadFile(file);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
      setIsUpload(true);
      setIsImageURL(false);
      setIsTemplate(false);
      setIsWallpaper(false);
    } else {
      setIsUpload(false);
      setIsImageURL(false);
      setIsTemplate(false);
      setIsWallpaper(false);
    }
  };

  async function uploadImageURL(imageURL: string, roomID: string) {
    const token = await getClaimToken();
    if (token) return uploadImageFromURL(imageURL, token.token, 'background', spacesRooms.currentSpace, roomID);
  }

  const currentWall = useSelector(getWallpaperRedux);
  const currentWallV2 = useSelector(getWallpaperV2Redux);
  const currentUploadProgress = useSelector(getUploadProgress);
  const isBackgroundLoading = useSelector(getIsBackgroundLoading);

  useEffect(() => {
    if (isBackgroundLoading) {
      setImageStatus('Applying');
    } else {
      setImageStatus('Apply');
    }
  }, [currentWall, currentWallV2, isBackgroundLoading]);

  useEffect(() => {
    setMessage('');
  }, [imageURL]);

  useEffect(() => {
    if (currentUploadProgress === 100) {
      setImageStatus('Processing');
      setUploadProgressWall(0);
    }
  }, [isUpload, currentUploadProgress]);

  return (
    <>
      <div className="customise-background-container">
        <div className="customise-background-header-section">
          <div className="customise-background-header">
            <div className="background-header-left">
              <div
                className="background-header-back-icon"
                onClick={() => {
                  toggleBackgroundMenuOpenStore(false);
                  if (roomParams.step === 'background') setCreateRoomStepStore('create');
                }}
              >
                <ChevronLeft />
              </div>
              <div className="background-header-title">Select room background</div>
            </div>

            <div className="background-header-right">
              <div
                className="background-header-tooltip-icon"
                onClick={() => {
                  setShowInfo(!showInfo);
                }}
              >
                {' '}
                <Info />
              </div>
              {roomParams.step !== 'background' && (
                <div
                  className="background-header-close-icon"
                  onClick={() => {
                    props.toClose();
                  }}
                >
                  <X />
                </div>
              )}
            </div>
          </div>
          {showInfo && (
            <div className="info-text-tooltip">
              <span>Select a custom background or choose one from our templates.</span>
            </div>
          )}
        </div>

        <div
          className="customise-background-options-container"
          style={imageStatus !== 'Apply' ? { pointerEvents: 'none' } : {}}
        >
          <div className="customise-background-options-inputs">
            <div
              className="customise-background-options-inputs-upload"
              onClick={() => {
                inputImageFile.current?.click();
                setShowLinkBox(false);
              }}
            >
              Upload Image
            </div>
            <input
              type="file"
              id="background-upload"
              accept="image/png, image/jpeg, image/jpg"
              ref={inputImageFile}
              onChange={(e) => {
                if (
                  e.target.files &&
                  e.target.files.length > 0 &&
                  e.target.files[0].size < (fileUploadLimit + 5) * 1024 * 1024
                ) {
                  onUploadChange(e);
                } else {
                  setMessage(
                    `Image should be less than ${fileUploadLimit + 5}MB in size. Please upload a smaller size image.`
                  );
                }
              }}
              style={{ display: 'none' }}
            />
            <div
              className="customise-background-options-inputs-link"
              onClick={() => {
                setShowLinkBox(!showLinkBox);
                setImageURL('');
              }}
            >
              {' '}
              Use link{' '}
            </div>
          </div>
          {showLinkBox && (
            <div className="customise-background-options-inputs-link-box">
              <input
                placeholder="Paste link here"
                value={imageURL}
                onChange={(e) => {
                  setImageURL(e.target.value);
                  if (e.target.value.length < 1) setImageURL('');
                  else {
                    setIsUpload(false);
                    setIsTemplate(false);
                    setIsWallpaper(false);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    imageURL && imageURL.length > 1 ? setIsImageURL(true) : setIsImageURL(false);
                    setIsUpload(false);
                    setIsTemplate(false);
                    setIsWallpaper(false);
                  }
                }}
              />
              <ChevronRight
                color={'white'}
                onClick={() => {
                  imageURL && imageURL.length > 1 ? setIsImageURL(true) : setIsImageURL(false);
                  setIsUpload(false);
                  setIsTemplate(false);
                  setIsWallpaper(false);
                }}
              />
            </div>
          )}

          {(isImageURL || isUpload || isTemplate || isWallpaper || imageURL.length > 1) && (
            <BackgroundUploadBox
              isImageURL={isImageURL}
              isUpload={isUpload}
              URL={isUpload || isImageURL ? imageURL : bgs.thumbnail}
              setProperties={(prop) => {
                setBgProp(prop);
              }}
            />
          )}

          <div className="customise-background-options-templates-section">
            <TemplateSection
              setBackground={(backgroundURL, backgroundType, data) => {
                setbgs(backgroundURL);
                setImageURL(backgroundURL.original);
                if (backgroundType === 'template') {
                  setIsTemplate(true);
                  setIsWallpaper(false);
                  setIsUpload(false);
                  setIsImageURL(false);
                  setTemplateData(data);
                } else if (backgroundType === 'wallpaper') {
                  setIsTemplate(false);
                  setIsWallpaper(true);
                  setIsUpload(false);
                  setIsImageURL(false);
                }
              }}
            />
          </div>
        </div>
        {(imageURL.length > 1 || message !== '') && (
          <div className="customise-background-footer">
            {message !== '' && (
              <div className="background-error-message-box">
                <span>{message}</span>
              </div>
            )}
            <div className="customise-background-footer-buttons">
              {roomParams.step !== 'background' && (
                <div
                  className="customise-background-footer-buttons-left"
                  onClick={() => {
                    // props.toClose();
                    setImageURL('');
                    setIsTemplate(false);
                    setIsWallpaper(false);
                    setIsUpload(false);
                    setIsImageURL(false);
                  }}
                >
                  Discard
                </div>
              )}
              <div
                className="customise-background-footer-buttons-right"
                style={imageStatus !== 'Apply' || message !== '' ? { pointerEvents: 'none' } : {}}
                onClick={async () => {
                  setMessage('');
                  try {
                    setImageStatus('Creating');
                    let roomID = spacesRooms.currentRoom;
                    if (roomParams.step === 'background') {
                      const room = await createRoomFunc();
                      roomID = room.id;
                    }
                    if (isWallpaper) {
                      setWallpaper(spacesRooms.currentSpace, roomID, imageURL);
                      setWallpaperV2(spacesRooms.currentSpace, roomID, {
                        ...bgs,
                        properties: bgProp,
                      });
                    } else if (isTemplate) {
                      setTemplateForRoom(templateData, roomID);
                      setWallpaperV2(spacesRooms.currentSpace, roomID, {
                        original: templateData.wallpaper,
                        full: templateData.wallpaper,
                        regular: templateData.wallpaper,
                        small: templateData.wallpaper,
                        thumbnail: templateData.wallpaper,
                        properties: bgProp,
                      });
                    } else if (isUpload) {
                      setImageStatus('Uploading');
                      const token = await getClaimToken();
                      if (token) {
                        const result = await uploadImage(
                          uploadFile,
                          token.token,
                          'background',
                          spacesRooms.currentSpace,
                          roomID
                        );
                        if (!result.error) {
                          setWallpaperV2(spacesRooms.currentSpace, roomID, {
                            ...result.paths,
                            properties: bgProp,
                          });
                        } else {
                          setMessage(result.error.error);
                          setImageStatus('Apply');
                        }
                      }
                    } else if (isImageURL) {
                      setImageStatus('Processing');
                      const result = await uploadImageURL(imageURL, roomID);
                      if (!result.error) {
                        setWallpaperV2(spacesRooms.currentSpace, roomID, {
                          ...result.paths,
                          properties: bgProp,
                        });
                      } else {
                        setMessage(result.error.error);
                        setImageStatus('Apply');
                      }
                    }
                    if (roomParams.step === 'background') {
                      setHighlightedRoomStore(roomID);
                      setCreateRoomStepStore('none');
                    }
                  } catch (e) {
                    setImageStatus('Apply');
                    setMessage('Something went wrong');
                  }
                }}
              >
                {imageStatus !== 'Apply' && (
                  <>
                    <LoaderRing size={'14px'} color={'black'} />
                    <span style={{ marginLeft: '8px' }}>
                      {' '}
                      {imageStatus === 'Uploading' && currentUploadProgress
                        ? `${imageStatus} (${Math.round(currentUploadProgress)}%)`
                        : imageStatus}
                    </span>
                  </>
                )}
                {imageStatus === 'Apply' && <span>{roomParams.step === 'background' ? 'Create Room' : 'Apply'}</span>}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomiseBackground;
