import React, { memo, useEffect, useRef, useState } from 'react';
import Cursor from './cursor';
import './cursor.scss';

const getRandomColor = (): string => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

interface CursorPresenceProps {
  x: number;
  y: number;
  userId: string;
  uname: string;
}

const CursorPresence = (props: CursorPresenceProps) => {
  const { x, y } = props;

  const [randomColor, setRandomColor] = useState('#000');
  const [isVisible, setIsVisible] = useState(false);
  const visibleTimeoutRef = useRef<any>(null);

  // const visibleTimeout = () => {
  // if (visibleTimeoutRef.current) {
  // clearTimeout(visibleTimeoutRef.current);
  // }
  // setIsVisible(true);
  // visibleTimeoutRef.current = setTimeout(() => {
  //   setIsVisible(false);
  // }, 2000);
  // };

  useEffect(() => {
    setRandomColor(getRandomColor());
    // visibleTimeout();
  }, []);

  // useEffect(() => {
  //   visibleTimeout();
  // }, [props.x, props.y]);

  const top = y;
  const left = x;

  return (
    <div className="cursor-wrapper" style={{ top, left }}>
      <Cursor color={randomColor} />
      <div className="cursor-uname" style={{ background: randomColor }}>
        {props.uname}
      </div>
    </div>
  );
};

export default memo(CursorPresence);
