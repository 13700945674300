import * as React from 'react';

export default function ReslashLogo(props: any) {
  return (
    <svg
      width={props.size ? props.size : '32'}
      height={props.size ? props.size : '32'}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <path
          d="M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16Z"
          fill="#34E3B9"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M25.377 0.0666504H5.37695C2.06324 0.0666504 -0.623047 2.75294 -0.623047 6.06665V26.0667C-0.623047 29.3804 2.06324 32.0667 5.37695 32.0667H25.377C28.6907 32.0667 31.377 29.3804 31.377 26.0667V6.06665C31.377 2.75294 28.6907 0.0666504 25.377 0.0666504Z"
          fill="#A2E9D8"
        />
        <g filter="url(#filter0_f)">
          <g filter="url(#filter1_f)">
            <path
              d="M17.877 22.0667C28.0942 22.0667 36.377 13.7839 36.377 3.56665C36.377 -6.65062 28.0942 -14.9333 17.877 -14.9333C7.65969 -14.9333 -0.623047 -6.65062 -0.623047 3.56665C-0.623047 13.7839 7.65969 22.0667 17.877 22.0667Z"
              fill="#00DCA3"
            />
          </g>
          <path
            d="M-2.12305 46.5667C8.09422 46.5667 16.377 38.2839 16.377 28.0667C16.377 17.8494 8.09422 9.56665 -2.12305 9.56665C-12.3403 9.56665 -20.623 17.8494 -20.623 28.0667C-20.623 38.2839 -12.3403 46.5667 -2.12305 46.5667Z"
            fill="#1DABCA"
          />
        </g>
        <g filter="url(#filter2_f)">
          <path
            d="M33.877 46.0667C44.0942 46.0667 52.377 37.7839 52.377 27.5667C52.377 17.3494 44.0942 9.06665 33.877 9.06665C23.6597 9.06665 15.377 17.3494 15.377 27.5667C15.377 37.7839 23.6597 46.0667 33.877 46.0667Z"
            fill="#EBFF00"
          />
        </g>
        <path
          d="M6.63477 20.5667H8.47997V17.4729H9.82657L11.48 20.5667H13.5169L11.6632 17.1746C12.6561 16.7485 13.2058 15.8834 13.2058 14.6903C13.2058 12.9559 12.0595 11.8394 10.078 11.8394H6.63477V20.5667ZM8.47997 15.99V13.3479H9.72427C10.7896 13.3479 11.3053 13.8209 11.3053 14.6903C11.3053 15.5553 10.7896 15.99 9.73282 15.99H8.47997ZM17.3532 20.6945C18.9725 20.6945 20.0634 19.9062 20.3191 18.6917L18.6401 18.5809C18.4569 19.0795 17.9881 19.3394 17.383 19.3394C16.4754 19.3394 15.9001 18.7386 15.9001 17.7627V17.7584H20.3575V17.2599C20.3575 15.0354 19.0109 13.936 17.2807 13.936C15.3546 13.936 14.106 15.3039 14.106 17.3238C14.106 19.3991 15.3376 20.6945 17.3532 20.6945ZM15.9001 16.6334C15.9384 15.8877 16.5052 15.2911 17.3106 15.2911C18.0989 15.2911 18.6444 15.8536 18.6486 16.6334H15.9001ZM25.3432 11.4303H23.7793L20.9668 21.8792H22.5307L25.3432 11.4303Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_f"
          x="-36.623"
          y="-30.9333"
          width="89"
          height="93.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter1_f"
          x="-16.623"
          y="-30.9333"
          width="69"
          height="69"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter2_f"
          x="-0.623047"
          y="-6.93335"
          width="69"
          height="69"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  );
}
