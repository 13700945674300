import { createSlice } from '@reduxjs/toolkit';

export type overflowStreamStoreType = {
  broadcasterID: string;
  roomID: string;
  isLive: boolean;
  loading: boolean;
};

const initialState: overflowStreamStoreType = {
  broadcasterID: '',
  roomID: '',
  isLive: false,
  loading: false,
};

export const overflowStreamSlice = createSlice({
  initialState,
  name: 'overflowStream',
  reducers: {
    setBroadcasterID(state, action) {
      state.broadcasterID = action.payload;
    },
    setRoomID(state, action) {
      state.roomID = action.payload;
    },
    setIsLive(state, action) {
      state.isLive = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const getOverflowStreamRedux = (globalState: { overflowStream: overflowStreamStoreType }) =>
  globalState.overflowStream;
