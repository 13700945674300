import { configureStore, createSlice } from '@reduxjs/toolkit';

export type ChatMessage = {
  text: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  isDeleted: false;
  userInfo: { name: string; isDeleted: boolean; nickname: string; profilePic: string; status: string };
  permissions: { isGuest: boolean };
};

const initialState: {
  messages: ChatMessage[];
  unread: number;
} = {
  messages: [],
  unread: 0,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessages(state, action) {
      state.messages = action.payload;
    },
    setUnread(state, action) {
      state.unread = action.payload;
    },
  },
});

const chatStore = configureStore({
  reducer: chatSlice.reducer,
});

export const getChatMessages = (state: { chat: ReturnType<typeof chatStore.getState> }) => state.chat.messages;
export const getUnreadCount = (state: { chat: ReturnType<typeof chatStore.getState> }) => state.chat.unread;
