import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useOutsideEvent } from '../../components/interactive/giphy/Giphy';
import { openJoinNow } from '../../components/modals/uiOnlyModals/joinRoom/joinRoom';
import { openConfirmAction } from '../../components/roomSettings/confirmAction';
import { openRoomDeleteConfirm } from '../../components/roomSettings/deleteConfirm';
import { sendAmplitudeData } from '../../helper/amplitude';
import { clearCanvas } from '../../helper/interactive/firebaseRTD';
import { getFrontendURL, handleSubdomain } from '../../helper/interactive/misc';
import {
  setRoomCreateOrEditStore,
  toggleChatOpenStore,
  toggleMembersMenuStore,
  togglePermissionMenuStore,
  toggleRoomDetailsMenuStore,
  toggleRoomSettingsMenuStore,
  toggleRoomWallMenuStore,
  toggleSidebarStore,
} from '../../store';
import { getDailyJoinedState } from '../../store/dailycoParticipants';
import { getSpacesRooms } from '../../store/spacesRooms';
import './dropDown.scss';

const DropDownRoom = (props: { toClose: () => void }) => {
  const spacesRooms = useSelector(getSpacesRooms);
  const space = spacesRooms.spaces[spacesRooms.currentSpace];
  const isAdmin = spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin;
  const isGuest = spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isGuest;
  const ref = useRef<HTMLDivElement | null>(null);
  const callJoined = useSelector(getDailyJoinedState);

  const copy = () => {
    if (!spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID) return;
    navigator.clipboard.writeText(
      `${getFrontendURL()}${handleSubdomain(
        `/s/${spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID}/r/${spacesRooms.currentRoom}`
      )}`
    );
  };

  useOutsideEvent(ref, props.toClose, 'moreHorizontalContainer');
  return (
    <div className="dropdown" ref={ref}>
      {/* <div
        className={isGuest ? 'option disabled' : 'option'}
        onClick={() => {
          copy();
          props.toClose();
        }}
      >
        Copy Share Link
      </div> */}
      {callJoined && (
        <div
          className="option"
          onClick={() => {
            openJoinNow();
            props.toClose();
          }}
        >
          Voice and video settings
        </div>
      )}
      {isAdmin && (
        <div
          className="option"
          onClick={() => {
            toggleSidebarStore(true);
            toggleRoomSettingsMenuStore(true);
            togglePermissionMenuStore(false);
            toggleChatOpenStore(false);
            toggleMembersMenuStore(false);
            toggleRoomDetailsMenuStore(true);
            toggleRoomWallMenuStore(false);
            setRoomCreateOrEditStore('edit');
            props.toClose();
          }}
        >
          Configure room
        </div>
      )}
      {/* {(isAdmin || spacesRooms.currentUserRole === 'h') && (
        <div
          className="option"
          onClick={() => {
            toggleSidebarStore(true);
            toggleRoomSettingsMenuStore(true);
            togglePermissionMenuStore(true);
            toggleChatOpenStore(false);
            toggleMembersMenuStore(false);
            toggleRoomDetailsMenuStore(false);
            toggleRoomWallMenuStore(false);
            setRoomCreateOrEditStore('edit');
            props.toClose();
          }}
        >
          Define permissions
        </div>
      )} */}
      {/* {isAdmin && (
        <div
          className="option"
          onClick={() => {
            toggleSidebarStore(true);
            toggleRoomSettingsMenuStore(true);
            toggleChatOpenStore(false);
            togglePermissionMenuStore(false);
            toggleMembersMenuStore(true);
            toggleRoomDetailsMenuStore(false);
            toggleRoomWallMenuStore(false);
            setRoomCreateOrEditStore('edit');
            props.toClose();
          }}
        >
          <div className="label">Configure members</div>
        </div>
      )} */}
      {/* {isAdmin && (
        <div
          className="option"
          onClick={() => {
            toggleSidebarStore(true);
            toggleRoomSettingsMenuStore(true);
            toggleChatOpenStore(false);
            togglePermissionMenuStore(false);
            toggleMembersMenuStore(false);
            toggleRoomDetailsMenuStore(false);
            toggleRoomWallMenuStore(true);
            setRoomCreateOrEditStore('edit');
            props.toClose();
          }}
        >
          Customize
        </div> 
        )} */}
      {isAdmin && (
        <div
          className="option"
          onClick={() => {
            openConfirmAction(
              () => {
                clearCanvas(spacesRooms.currentSpace, spacesRooms.currentRoom);
                props.toClose();
              },
              () => {
                props.toClose();
              }
            )();
          }}
        >
          Clear the room canvas
        </div>
      )}
      {/* {space?.permission?.isOwner && (
        <div className="option">
          <div
            className="label"
            onClick={() => {
              window.open(`${handleSubdomain(`/s/${space.spaceInfo.spaceID}`)}/settings`, '_blank');
            }}
          >
            Space settings
          </div>
        </div>
      )} */}
      {isAdmin && (
        <div
          className="option danger last"
          onClick={() => {
            sendAmplitudeData('Delete room clicked', {});
            openRoomDeleteConfirm();
            props.toClose();
          }}
        >
          Delete room
        </div>
      )}
    </div>
  );
};

export default DropDownRoom;
