import amplitude from 'amplitude-js';
import { config } from '../config';
import { permissionV2, userRoleType } from '../store/spacesRooms';
import logger from './logger';

type spacePermissionType = {
  isOwner: boolean;
  isGuest: boolean;
  isTeamMember: boolean;
};

type roomPermissionType = {
  isAdmin: boolean;
  isTeamMember: boolean;
  isGuest: boolean;
};

type roomAccessPermissionType = {
  roomAccessPermission: permissionV2;
  roomUserRole: 'h' | 's' | 'a';
  spatialOnOff: boolean;
  bubbleSize: number;
};

let space: spacePermissionType | undefined = undefined;
let spaceID = {};
let room = '';
let roomCopy = '';
let userRole: userRoleType | string = '';
let userPermission: roomPermissionType | undefined = undefined;
let roomAccessPermission: roomAccessPermissionType | {} = {};

export const initAmplitude = () => {
  if (config.amplitudeKey.length > 0) amplitude.getInstance().init(config.amplitudeKey);
};

export const setAmplitudeUserId = (userId: string | null) => {
  if (config.amplitudeKey.length > 0) {
    amplitude.getInstance().setUserId(userId);
  }
};

export const setAmplitudeUserProperties = (properties: any) => {
  if (config.amplitudeKey.length > 0) {
    amplitude.getInstance().setUserProperties({ ...properties, env: config.env });
  }
};

export const setRoomAccessPermissions = (permissions: roomAccessPermissionType) => {
  roomAccessPermission = permissions;
};

export const setSpaceRoom = (
  currentSpaceID: string,
  currentSpace: spacePermissionType | undefined,
  currentRoom: string,
  currentRoomCopy: string,
  currentUserRole: userRoleType | string,
  currentRoomPermissionType: roomPermissionType | undefined
) => {
  space = currentSpace;
  spaceID = currentSpaceID;
  room = currentRoom;
  roomCopy = currentRoomCopy;
  userRole = currentUserRole === 'h' ? 'host' : currentUserRole === 's' ? 'speaker' : 'audience';
  userPermission = currentRoomPermissionType;
};

export const sendAmplitudeData = (
  eventType: string,
  eventProperties: any,
  callback?: amplitude.Callback | undefined
) => {
  try {
    if (config.amplitudeKey.length > 0) {
      amplitude.getInstance().logEvent(eventType, {
        ...eventProperties,
        ...roomAccessPermission,
        space,
        spaceID,
        room,
        roomCopy,
        userRole,
        userPermission,
        env: config.env,
      });
    }
  } catch (e) {
    logger.warn('amplitude error.', { error: e });
  }
};
