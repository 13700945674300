// @ts-ignore
import Excalidraw from '@excalidraw/excalidraw';
// @ts-ignore
import { cloneDeep, isEqual } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { getAuthState } from '../../../../store';
import * as reslashAppsFirebase from './firebase';
import './styles.scss';
import WhiteBoardCursors from './WhiteBoardCursors';

const debug = (...data: any[]) => {};

// @ts-ignore
window.isEqual = isEqual;
interface ExcaliDrawProps {
  roomId: string;
  spaceId: string;
  appId: string;
  userId: string;
  x?: number;
  y?: number;
}

const debounce = (func: any, wait: number) => {
  let timeout: any;
  return function executedFunction(...args: any) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const equalsIgnoreOrder = (a: any, b: any) => {
  debug('a, b', { a, b });
  if (a.length !== b.length) return false;
  const uniqueValues: any = new Set([...a, ...b]);
  debug({ uniqueValues });
  for (const v of uniqueValues) {
    debug('v: ', v);
    const aCount = a.filter((e: any) => e === v).length;
    const bCount = b.filter((e: any) => e === v).length;
    if (aCount !== bCount) {
      debug('not equal: , ', { aCount, bCount });
      return false;
    }
  }
  return true;
};

const removeFirebaseFalsyValues = (obj: any): any => {
  let newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
    } else if (obj[key] === undefined || obj[key] === null) {
    } else {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const ExcaliDraw = (props: ExcaliDrawProps) => {
  // const [reslashFirebase] = useRecoilState(reslashFirebaseState);
  const excalidrawRef = useRef<any>(null);
  const previousElementsStateRef = useRef<any>(null);
  const previousAppStateRef = useRef<any>({});

  const oldDataHandler = useRef(null);

  const excalidrawWrapperRef = useRef(null);

  const isAppDataLoaded = useRef(false);

  const updateFirebaseElement = debounce(reslashAppsFirebase.updateAppElement, 100);
  const updateFirebaseAppState = debounce(reslashAppsFirebase.updateAppState, 100);
  // const updateFirebaseUserCursorPointer = debounce(reslashAppsFirebase.updateUserCursorPresence, 5);

  const updateExcaliDraw = (elements: any, appState: any) => {
    debug('updateExcaliDraw', elements, appState);
    let finalElements: any = [];
    if (elements) {
      finalElements = elements.map((element: any) => ({
        ...element,
        groupIds: element.groupIds || [],
      }));
    } else {
      finalElements = [];
    }
    previousElementsStateRef.current = cloneDeep(finalElements);
    previousAppStateRef.current = cloneDeep(appState);

    if (excalidrawRef.current) {
      excalidrawRef.current.updateScene({
        elements: [...finalElements],
        appState: { ...appState },
      });
    }
  };

  useEffect(() => {
    debug({ props });
    const unWatchCallback = reslashAppsFirebase.watchForAppChanges(
      reslashAppsFirebase.APP_TYPE.WHITE_BOARDS,
      props.spaceId,
      props.roomId,
      props.appId,
      props.userId,
      (app) => {
        /**
         * TODO: if this update is by me don't update call the excalidraw
         */
        if (app === null) {
          debug('creating new app');
          /** Create app doesn't exists */
          reslashAppsFirebase
            .createApp(
              reslashAppsFirebase.APP_TYPE.WHITE_BOARDS,
              props.spaceId,
              props.roomId,
              props.appId,
              props.userId
            )
            .then((app: any) => {
              debug('creating new val resolve: ', app);
              updateExcaliDraw(app.appData.sceneData.elements, app.appData.sceneData.appState);
              isAppDataLoaded.current = true;
            });
          // } else if (app.updatedBy !== props.userId || !isAppDataLoaded.current) {
        } else {
          debug('changes subscribed from firebase: ', app, props.userId);
          const { elements, appState } = app.appData.sceneData;
          let finalElements: any = [];
          if (elements) {
            finalElements = elements.map((element: any) => ({
              ...element,
              groupIds: element.groupIds || [],
            }));
          } else {
            finalElements = [];
          }
          if (!isAppDataLoaded.current) {
            debug('loading app for the first time');
            previousElementsStateRef.current = cloneDeep(finalElements);
            excalidrawRef.current.updateScene({
              elements: [...finalElements],
              appState: { ...appState },
            });
            isAppDataLoaded.current = true;
            return;
          }

          const oldElements = [...excalidrawRef.current.getSceneElementsIncludingDeleted()];
          for (let i = 0; i < finalElements.length; i++) {
            const element = finalElements[i];
            if (element.updatedBy === props.userId) {
              debug('changes by the user so no update');
              /**
               * don't update my updated to me
               */
              continue;
            }
            const oldElement = oldElements.find((e: any) => e.id === element.id);
            const oldElementIndex = oldElements.findIndex((e: any) => e.id === element.id);
            if (oldElementIndex === -1) {
              debug('new element: ', element);
              /**
               * if old element is not found push as a element to the elements list
               */
              oldElements.push(element);
            } else if (oldElement.version !== element.version) {
              debug('update element: ', element);
              /**
               * update the element
               */
              oldElements[oldElementIndex] = element;
            }
          }
          if (excalidrawRef.current) {
            previousElementsStateRef.current = cloneDeep(oldElements);
            excalidrawRef.current.updateScene({
              elements: [...oldElements],
              appState: { ...appState },
            });
          }
        }
      }
    );
    return () => {
      /**
       * unsubscribe to changes of app data
       */
      unWatchCallback();
    };
  }, []);

  /**
   *
   * @param args effect to refresh excalidraw
   */

  debug(props.x, props.y);

  const handleSceneDataChange = (...args: any) => {
    debug('handleSceneDataChange', args);
    const [elements, appState] = args;
    if (isAppDataLoaded.current) {
      /**
       * Don't callback updateFirebaseData if data is not changed
       */
      const oldElements = cloneDeep(previousElementsStateRef.current);
      const oldAppState = cloneDeep(previousAppStateRef.current);
      for (let i = 0; i < elements.length; i++) {
        const element = removeFirebaseFalsyValues(elements[i]);
        const oldElement = removeFirebaseFalsyValues(
          oldElements.find((e: any) => e.id === element.id) || { version: 0 }
        );
        if (element.version !== oldElement.version) {
          /**
           * update to firebase
           */
          previousElementsStateRef.current = cloneDeep(elements);
          updateFirebaseElement(
            reslashAppsFirebase.APP_TYPE.WHITE_BOARDS,
            props.spaceId,
            props.roomId,
            props.appId,
            props.userId,
            { ...element, updatedBy: props.userId }
          );
        }
      }
      // const elementsCheck = elementsStringify !== oldElementsStringify;
      /**
       * App State Check only includes background color
       */
      const appStateCheck = oldAppState.viewBackgroundColor !== appState.viewBackgroundColor;
      if (appStateCheck) {
        previousAppStateRef.current = cloneDeep(appState);
        updateFirebaseAppState(
          reslashAppsFirebase.APP_TYPE.WHITE_BOARDS,
          props.spaceId,
          props.roomId,
          props.appId,
          props.userId,
          appState
        );
      }
    }
  };

  const pointerUpdate = (payload: {
    pointer: {
      x: number;
      y: number;
    };
    button: 'down' | 'up';
    pointersMap: any;
  }) => {
    const auth = getAuthState();
    reslashAppsFirebase.updateUserCursorPresence(
      reslashAppsFirebase.APP_TYPE.WHITE_BOARDS,
      props.spaceId,
      props.roomId,
      props.appId,
      props.userId,
      { ...payload.pointer, uname: auth.user.name }
    );
  };

  return (
    <div
      className="excalidraw-wrapper"
      ref={excalidrawWrapperRef}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <WhiteBoardCursors spaceId={props.spaceId} roomId={props.roomId} appId={props.appId} userId={props.userId} />
      <Excalidraw ref={excalidrawRef} onChange={handleSceneDataChange} onPointerUpdate={pointerUpdate} />
    </div>
  );
};
