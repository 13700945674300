import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setMediaPermissionsStore } from '../../store';
import NoPermissionModal, { openNoPermission } from '../noPermissionModal/noPermissionModal';
import './allowPermissions.scss';

type permissionState = 'allowed' | 'dismissed' | 'denied' | 'not-asked';

const AllowPermissions = () => {
  const history = useHistory();
  const [micPer, setMicPer] = useState(false);
  const [vidPer, setVidPer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vidState, setVidState] = useState<permissionState>('not-asked');
  const [micState, setMicState] = useState<permissionState>('not-asked');

  const checkPermissionFirst = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    let flagMic = false;
    let flagCam = false;
    for (let i in devices) {
      if (devices[i].kind === 'audioinput' && devices[i].label.length > 0) flagMic = true;
      if (devices[i].kind === 'videoinput' && devices[i].label.length > 0) flagCam = true;
    }
    return flagMic && flagCam;
  };

  // useEffect(() => {
  //   if (micPer && !loading) history.push('/voiceSettings?redirect=' + redirect);
  // }, [micPer, loading]);

  const getPermission = async () => {
    setLoading(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
      setMicPer(true);
      setMicState('allowed');
      stream.getAudioTracks().forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    } catch (e) {
      if (e.message.includes('dismissed')) {
        setMicState('dismissed');
      } else {
        setMicState('denied');
      }
      setMicPer(false);
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      setVidPer(true);
      setVidState('allowed');
      stream.getVideoTracks().forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    } catch (e) {
      if (e.message.includes('dismissed')) {
        setVidState('dismissed');
      } else {
        setVidState('denied');
      }
      setVidPer(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const flag = await checkPermissionFirst();
        if (flag) {
          setMediaPermissionsStore({
            isVidDisabled: false,
            isMicDisabled: false,
            isMute: false,
            isVidMute: false,
            vidID: '',
            micID: '',
            permissionState: 1,
          });
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <div className="allow-permission-container">
        {
          <div className="allow-permission-form-section">
            <div className="allow-permission-form-items">
              <div className="allow-permission-form-header">
                <div className="allow-permission-form-header-title">
                  <span>Voice and video settings</span>
                </div>

                <div className="allow-permission-form-header-subtitle">
                  <span>We need audio and video permissions to enable a smooth experience for you ahead.</span>
                </div>

                {!(
                  vidState === 'not-asked' ||
                  micState === 'not-asked' ||
                  (vidState === 'allowed' && micState === 'allowed')
                ) && (
                  <div className="error-state">
                    <span>
                      {vidState === 'dismissed' &&
                        micState === 'allowed' &&
                        "You haven't given camera access. We need it to have the app function smoothly."}
                      {micState === 'dismissed' &&
                        "You haven't given microphone access. We need it to have the app function smoothly."}
                      {vidState === 'denied' &&
                        micState === 'allowed' &&
                        "You've denied camera access. It may cause issues using Reslash."}
                      {vidState === 'allowed' &&
                        micState === 'denied' &&
                        "You've denied microphone access. Reslash cannot work without this. Please enable it to start using Reslash."}
                      {vidState === 'denied' &&
                        micState === 'denied' &&
                        "You've denied camera and microphone access. Please enable it to start using Reslash."}
                    </span>
                  </div>
                )}

                {/* <div className="allow-permission-form-user-avatar">
                <img src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png" />
              </div> */}
              </div>
            </div>

            <div className="allow-permission-form-action">
              <div
                className="allow-permission-form-button"
                onClick={() => {
                  if (micState === 'not-asked' || micState === 'dismissed') getPermission();
                  else if (vidState === 'dismissed') {
                    getPermission();
                  } else if (micState === 'allowed') {
                    setMediaPermissionsStore({
                      isVidDisabled: false,
                      isMicDisabled: false,
                      isMute: false,
                      isVidMute: false,
                      vidID: '',
                      micID: '',
                      permissionState: 1,
                    });
                  } else if (micState === 'denied') {
                    window.open('https://reslash.tawk.help/', '_blank')?.focus();
                  }
                }}
              >
                <span>
                  {vidState === 'not-asked' && micState === 'not-asked' && 'Allow access'}
                  {vidState === 'dismissed' && micState === 'dismissed' && 'Allow access'}
                  {vidState === 'dismissed' && micState === 'allowed' && 'Allow camera access'}
                  {vidState === 'allowed' && micState === 'dismissed' && 'Allow microphone access'}
                  {micState === 'allowed' && vidState !== 'dismissed' && 'Continue'}
                  {micState === 'denied' && 'Read help article'}
                </span>
              </div>
              {micState === 'allowed' && vidState === 'denied' && (
                <div
                  className="bottom-text"
                  onClick={() => {
                    openNoPermission();
                  }}
                >
                  Here's how you allow the camera access again.
                </div>
              )}
            </div>
          </div>
        }
      </div>
      <NoPermissionModal />
    </>
  );
};

export default AllowPermissions;
