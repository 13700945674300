import { useState } from 'react';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import './floatInput.scss';

const FloatInput = (props: {
  type: string;
  label: string;
  onChangeInput: (inputText: string) => void;
  showHide?: boolean;
}) => {
  const [value, setValue] = useState('');
  const [show, setShow] = useState(false);

  function handleChange(e: any) {
    setValue(e.target.value);
    props.onChangeInput(e.target.value);
  }

  return (
    <div className="input-container">
      <input type={!show ? props.type : 'text'} value={value} onChange={handleChange} />
      {props.showHide && value.length > 0 && (
        <div className="showHideEye" onClick={() => setShow(!show)}>
          <EyeSlashFill color={'black'} style={{ display: show ? 'flex' : 'none' }} />
          <EyeFill color={'black'} style={{ display: show ? 'none' : 'flex' }} />
        </div>
      )}
      <label className={value && 'filled'}>{props.label}</label>
    </div>
  );
};

export default FloatInput;
