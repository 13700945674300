import React from 'react';
import { ChevronLeft, X } from 'react-feather';
import '../styles/modalHeader.scss';

export default function ModalHeader(props: any) {
  return (
    <div className="modal-header" style={props.style}>
      <div>
        {props.showBack ? (
          <button onClick={props.onBack}>
            <ChevronLeft />
          </button>
        ) : (
          <></>
        )}
      </div>

      <span> {props.header} </span>

      {props.hideClose ? (
        <></>
      ) : (
        <button onClick={props.onClose}>
          <X />
        </button>
      )}
      {props.children}
    </div>
  );
}
