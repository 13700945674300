import ReconnectingSocket from 'reconnecting-websocket';
import { removeItemAll } from './misc';
import { handshakeStep, handshakeType } from './types/types';

export class Socket {
  listener: {
    [type: string]: Function[];
  };
  socket!: ReconnectingSocket;

  constructor() {
    this.listener = {};
  }

  on(eventType: handshakeType, callback: (payload: handshakeStep) => void | Promise<void>) {
    if (!this.listener[eventType]) {
      this.listener[eventType] = [];
    }
    this.listener[eventType].push(callback);
  }

  onmessage(callback: (d: any) => void) {
    if (!this.listener['message']) {
      this.listener['message'] = [];
    }
    this.listener['message'].push(callback);
  }

  off(eventType: handshakeType, callback: (payload: handshakeStep) => void | Promise<void>) {
    if (!this.listener[eventType]) return;
    removeItemAll(this.listener[eventType], callback);
  }

  connect(src: string) {
    this.socket = new ReconnectingSocket(src);
    this.socket.addEventListener('message', (e) => {
      const handshake: handshakeStep = JSON.parse(e.data);
      for (let i in this.listener[handshake.type]) {
        const callback = this.listener[handshake.type][i];
        callback(handshake);
      }
      for (let i in this.listener['message']) {
        this.listener['message'][i](e);
      }
    });
  }

  send(payload: handshakeStep) {
    this.socket.send(JSON.stringify(payload));
  }
}
