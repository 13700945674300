import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getSpacesRooms } from '../../store/spacesRooms';
import DropDown from '../dropdown/dropdown';
import './spaceSwitcher.scss';

const NewSpaceSwitcher = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  const history = useHistory();
  const [spaceList, setSpaceList] = useState<{ [id: string]: string }>({});
  const [space, setSpace] = useState(_.keys(spacesRooms.spaces)[0]);

  const getSpacesIDFormatted = () => {
    let spaceIDSet = false;
    for (const i in spacesRooms.spaces) {
      if (spacesRooms.spaces[i]?.permission.isGuest) continue;
      setSpaceList((spaceList) => {
        if (!spaceIDSet && !spacesRooms.spaces[i]?.permission.isGuest) {
          setSpace(spacesRooms.spaces[i]?.spaceInfo.spaceID ?? '');
          spaceIDSet = true;
        }
        return {
          ...spaceList,
          [spacesRooms.spaces[i]?.spaceInfo.spaceID ?? '']: spacesRooms.spaces[i]?.spaceInfo.spaceName ?? '',
        };
      });
    }
  };

  useEffect(() => {
    if (spacesRooms.spacesLoading) return;

    if (_.keys(spacesRooms.spaces).length === 0) {
      history.push('/onboard?sticky');
    }
    if (_.keys(spacesRooms.spaces).length === 1) {
      history.push('/s/' + spacesRooms.spaces[_.keys(spacesRooms.spaces)[0]]?.spaceInfo.spaceID);
    }
    getSpacesIDFormatted();
  }, [spacesRooms]);

  const DropDownStyle = {
    dropDownHeader: {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontSize: '14px',
      lineHeight: '17px',
    },
    listOuter: {
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      border: '1px solid #000000',
      top: '38px',
      maxHeight: '200px',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    listItems: {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingBottom: '6px',
      paddingTop: '6px',
      fontSize: '14px',
      lineHeight: '17px',
    },
  };

  return (
    <>
      <div className="space-switcher-container">
        <div className="space-switcher-form-section">
          <div className="space-switcher-form-items">
            <div className="space-switcher-form-header">
              <div className="space-switcher-form-header-title">
                <span>You are part of multiple spaces</span>
              </div>
              <div className="space-switcher-form-header-subtitle">
                <span>Select a space to move ahead</span>
              </div>
            </div>

            <div className="space-switcher-field-item">
              <div className="space-switcher-field">
                <DropDown
                  headerTitle={space && spaceList[space] ? spaceList[space] : 'Select space'}
                  styles={DropDownStyle}
                  options={spaceList}
                  optionsListType="array"
                  isCamelcase={false}
                  isItemDict={true}
                  onSelectItem={(e: any, key: any) => {
                    setSpace(key);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="space-switcher-form-action">
            <div
              className="space-switcher-form-button"
              onClick={() => {
                history.push('/s/' + space);
              }}
            >
              <span>Continue</span>
            </div>
          </div>

          <div className="space-switcher-bottom-section">
            <div className="space-switcher-bottom-section-msg">
              <span>Looking to build a new space?</span>
            </div>
            <div
              className="space-switcher-form-button-white"
              onClick={() => {
                history.push('/onboard?sticky');
              }}
            >
              <span>Create a new space</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSpaceSwitcher;
