import { configureStore, createSlice } from '@reduxjs/toolkit';
import { roomPermissions } from '../types/canvasFB';

export type permissionV2 = {
  gifs: boolean;
  wallpaper: boolean;
  urlwallpaper: boolean;
  apps: boolean;
  objects: boolean;
  moveself: boolean;
  moveothers: boolean;
  megaphone: boolean;
  cam: boolean;
  mic: boolean;
  screen: boolean;
  chat?: boolean;
  recording?: boolean;
  openroom?: boolean;
  lockroom?: boolean;
};

export type userRoleType = 'h' | 's' | 'a';
export type userRole = { [id: string]: { role: userRoleType; createdBy: string } };

export type roomType = {
  permission: {
    isAdmin: boolean;
    isTeamMember: boolean;
    isGuest: boolean;
  };
  userInfo: {
    name: string;
    username: string;
    profilePic: string;
    desc: string;
    email: string;
  };
  roomDetails: {
    roomName: string;
    isOverflow: boolean;
    spillOverAt: number;
    capacity: number;
    enabledPassword?: boolean;
    isHidden?: boolean;
    schedule?: {
      from: number;
      till: number;
      ejectOnEnd: boolean;
    };
  };
  revoked?: {
    status: boolean;
    reason: 'password-changed' | '';
    revokedAt: number;
  };
  roomID: string;
  spaceID: string;
  createdBy: string;
  userID: string;
  createdOn: Date;
  updatedBy: string;
  updatedOn: { seconds: number; nanoseconds: number } | undefined;
  isDeleted: boolean;
};

export type spaceType = {
  permission: {
    isOwner: boolean;
    isGuest: boolean;
    isTeamMember: boolean;
  };
  spaceID: string;
  spaceInfo: {
    spaceID: string;
    spaceName: string;
  };
  userInfo: {
    name: string;
    username: string;
    profilePic: string;
    desc: string;
    email: string;
  };
  updatedBy: string;
  updatedOn: { seconds: number; nanoseconds: number } | undefined;
  userID: string;
  createdOn: Date;
  createdBy: string;
  isDeleted: boolean;
};

const initialState: {
  spacesLoading: boolean;
  roomsLoading: boolean;
  roomCopyLoading: boolean;
  spaceClaimLoading: boolean;
  roomClaimLoading: boolean;
  currentWall: string;
  kickedOutOnEnd: boolean;
  spaces: {
    [id: string]: spaceType | undefined;
  };
  rooms: {
    [id: string]: roomType | undefined;
  };
  currentSpace: string;
  currentRoom: string;
  currentRoomCopy: number;
  currentRoomPermission: roomPermissions;
  permissionV2: {
    [type: string]: permissionV2 | undefined;
  };
  currentUserRole: userRoleType;
  currentRoomHosts: userRole;
  currentRoomSpeakers: userRole;
  currentRoomAudience: userRole;
  currentRoomPassword?: string;
} = {
  spacesLoading: true,
  roomsLoading: true,
  spaceClaimLoading: true,
  roomClaimLoading: true,
  roomCopyLoading: true,
  kickedOutOnEnd: false,
  spaces: {},
  rooms: {},
  currentRoom: '',
  currentSpace: '',
  currentRoomCopy: 0,
  currentRoomPermission: {
    des: false,
    im: false,
    meg: false,
    par: false,
    wall: false,
    spatial: true,
    mic: true,
    cam: true,
    size: 128,
    move: true,
    chat: true,
    rec: true,
    vidOnPerm: false,
    openroom: false,
    lockroom: false,
  },
  permissionV2: {},
  currentUserRole: 'a',
  currentRoomAudience: {},
  currentRoomHosts: {},
  currentRoomSpeakers: {},
  currentWall: 'loading',
};
export const spacesRoomsSlice = createSlice({
  name: 'spacesRooms',
  initialState,
  reducers: {
    addSpaces: (state, action) => {
      state.spaces = action.payload.spaces;
    },
    setOneSpace: (state, action) => {
      if (!state.spaces) state.spaces = {};
      state.spaces[action.payload.spaceID] = action.payload.space;
    },
    setCurrentSpace: (state, action) => {
      state.currentSpace = action.payload.spaceID;
    },
    setKickedOutOnEnd(state, action) {
      state.kickedOutOnEnd = action.payload;
    },
    addRooms: (state, action) => {
      for (let key in action.payload.rooms) {
        state.rooms[key] = action.payload.rooms[key];
      }
    },
    setCurrentRoom: (state, action) => {
      if (state.currentRoom !== action.payload.roomID) {
        state.currentRoomPassword = action.payload.password;
      }
      state.currentRoom = action.payload.roomID;
    },
    setCurrentRoomCopy: (state, action) => {
      state.currentRoomCopy = action.payload;
    },
    deleteRoom: (state, action) => {
      if (state.rooms[action.payload.roomID]) delete state.rooms[action.payload.roomID];
    },
    setSpacesLoading: (state, action) => {
      state.spacesLoading = action.payload.flag;
    },
    setRoomsLoading: (state, action) => {
      state.roomsLoading = action.payload.flag;
    },
    setRoomCopyLoading: (state, action) => {
      state.roomCopyLoading = action.payload.flag;
    },
    setRoomsClaimLoading: (state, action) => {
      state.roomClaimLoading = action.payload.flag;
    },
    setCurrentWall: (state, action) => {
      state.currentWall = action.payload.url;
    },
    setSpaceClaimLoading: (state, action) => {
      state.spaceClaimLoading = action.payload.flag;
    },
    setCurrentRoomPermission: (state, action) => {
      state.currentRoomPermission = action.payload.permission;
    },
    setPermissionV2: (state, action) => {
      state.permissionV2[action.payload.type] = action.payload.permissions;
    },
    setCurrentUserRole: (state, action) => {
      state.currentUserRole = action.payload;
    },
    setCurrentRoomUserRoles: (state, action) => {
      if (action.payload.type === 'h') state.currentRoomHosts = action.payload.users;
      else if (action.payload.type === 's') state.currentRoomSpeakers = action.payload.users;
      else if (action.payload.type === 'a') state.currentRoomAudience = action.payload.users;
    },
    setCurrentRoomPassword: (state, action) => {
      state.currentRoomPassword = action.payload;
    },
  },
});

export const spacesRoomStore = configureStore({
  reducer: spacesRoomsSlice.reducer,
});

export const getSpacesRooms = (state: { spacesRooms: ReturnType<typeof spacesRoomStore.getState> }) =>
  state.spacesRooms;
export const getCurrentRoomRedux = (state: { spacesRooms: ReturnType<typeof spacesRoomStore.getState> }) =>
  state.spacesRooms.currentRoom;
export const getCurrentRoomPermission = (state: { spacesRooms: ReturnType<typeof spacesRoomStore.getState> }) =>
  state.spacesRooms.currentRoomPermission;
export const getCurrentSpace = (state: { spacesRooms: ReturnType<typeof spacesRoomStore.getState> }) =>
  state.spacesRooms.currentSpace;
export const getSpaces = (state: { spacesRooms: ReturnType<typeof spacesRoomStore.getState> }) =>
  state.spacesRooms.spaces;
export const getLoadingStateRedux = (state: { spacesRooms: ReturnType<typeof spacesRoomStore.getState> }) => {
  return {
    spaceLoading: state.spacesRooms.spacesLoading,
    roomLoading: state.spacesRooms.roomsLoading,
  };
};
export const getCurrentUserRoleRedux = (globalState: { spacesRooms: ReturnType<typeof spacesRoomStore.getState> }) => {
  const state = globalState.spacesRooms;
  return state.currentUserRole;
};
export const getCurrentWall = (state: { spacesRooms: ReturnType<typeof spacesRoomStore.getState> }) =>
  state.spacesRooms.currentWall;
export const getCurrentRoomPermissionAndUserRoleRedux = (globalState: {
  spacesRooms: ReturnType<typeof spacesRoomStore.getState>;
}): {
  permission:
    | {
        isAdmin: boolean;
        isTeamMember: boolean;
        isGuest: boolean;
      }
    | undefined;
  role: userRoleType;
} => {
  const state = globalState.spacesRooms;
  return {
    permission: state.rooms[state.currentRoom]?.permission,
    role: state.currentUserRole,
  };
};

export const getCurrentRoomPermissionV2Redux = (roomID: string) => (globalState: {
  spacesRooms: ReturnType<typeof spacesRoomStore.getState>;
}) => globalState.spacesRooms.rooms[roomID]?.permission;

export const getCurrentRoomCopyRedux = (state: { spacesRooms: ReturnType<typeof spacesRoomStore.getState> }) =>
  state.spacesRooms.currentRoomCopy;

export const getRoomCopyLoadingRedux = (state: { spacesRooms: ReturnType<typeof spacesRoomStore.getState> }) =>
  state.spacesRooms.roomCopyLoading;
