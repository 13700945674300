import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CrowdManagementModal from '../../components/crowdManagemetModal/crowdManagementModal';
import DropDown from '../../components/dropdown/dropdown';
import LoaderPage from '../../components/loader/loaderPage';
import LoaderV2 from '../../components/loader/loaderV2';
import PlanCard from '../../components/planCard/planCard';
import ConfirmAction from '../../components/roomSettings/confirmAction';
import { sendAmplitudeData } from '../../helper/amplitude';
import { getClaimToken, getToken, watchBillingDetails } from '../../helper/firebase/auth';
import { formatLong, isSubDomain } from '../../helper/interactive/misc';
import { getBillingID, getInvoiceItem } from '../../helper/requests/appsumo';
import spaceObj from '../../helper/requests/spaces';
import { setCurrentSpace, setSpaceClaimLoading } from '../../store';
import { getSpacesRooms } from '../../store/spacesRooms';
import CrowdManagement from './crowdManagement';
import './settings.scss';
import SpaceMembersV2 from './spaceMembers';

const SpaceBilling = (props: { spaceID: string }) => {
  const [billingID, setBillingID] = useState('loading');
  const [billingDets, setBillingDets] = useState<any>(null);
  const [invoiceId, setInvoiceId] = useState('');

  useEffect(() => {
    let unsub = () => {};
    (async () => {
      if (billingID === 'loading') {
        const token = await getToken();
        if (token) {
          const response1 = await getBillingID(props.spaceID, token, isSubDomain());
          setBillingID(response1.data.plan);

          const response2 = await getInvoiceItem(props.spaceID, token, isSubDomain());
          setInvoiceId(response2.data.invoiceItemUuid);
        }
      }
    })();

    if (billingID !== 'loading') {
      // console.log(billingID);
      const watcher = watchBillingDetails(billingID);
      unsub = watcher.onSnapshot((d) => {
        setBillingDets(d.data());
      });
    }
    return () => unsub();
  }, [billingID]);

  return (
    <>
      {billingID === 'loading' || billingDets === null || !billingDets ? (
        <LoaderPage />
      ) : (
        <div className="setting-header-label">
          <span>Billing</span>

          <div className="billing">
            <div className="plans-container">
              {billingID === 'loading' || billingDets === null || !billingDets ? (
                <></>
              ) : (
                <>
                  {billingDets.id && billingDets.id === 'reslash-free' && (
                    <PlanCard planCardType="current" product="free" members="10 USERS" unitPrice="$0" />
                  )}

                  {billingDets.id && billingDets.id === 'reslash-appsumo-tier-1' && (
                    <PlanCard
                      planCardType="current"
                      product="appsumo"
                      plan="tier-1"
                      duration="UNLIMITED"
                      members="5 USERS"
                      unitPrice="$69"
                      invoiceItemId={invoiceId}
                    />
                  )}

                  {billingDets.id && billingDets.id === 'reslash-appsumo-tier-2' && (
                    <PlanCard
                      planCardType="current"
                      product="appsumo"
                      plan="tier-2"
                      duration="UNLIMITED"
                      members="20 USERS"
                      unitPrice="$169"
                      invoiceItemId={invoiceId}
                    />
                  )}

                  {billingDets.id && billingDets.id === 'reslash-appsumo-tier-3' && (
                    <PlanCard
                      planCardType="current"
                      product="appsumo"
                      plan="tier-3"
                      duration="UNLIMITED"
                      members="75 USERS"
                      unitPrice="$299"
                      invoiceItemId={invoiceId}
                    />
                  )}

                  {billingDets.id && billingDets.id === 'reslash-appsumo-tier-4' && (
                    <PlanCard
                      planCardType="current"
                      product="appsumo"
                      plan="tier-4"
                      duration="UNLIMITED"
                      members="150 USERS"
                      unitPrice="$499"
                      invoiceItemId={invoiceId}
                    />
                  )}

                  {billingDets.id && billingDets.id === 'reslash-appsumo-tier-4-event' && (
                    <PlanCard
                      planCardType="current"
                      product="appsumo"
                      plan="tier-4-event"
                      duration="MONTHLY"
                      members="750 Attendees"
                      unitPrice="$499"
                      invoiceItemId={invoiceId}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const SpaceControls = (props: { spaceID: string }) => {
  const DropDownStyle = {
    dropDownHeader: {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontSize: '16px',
    },
    listOuter: {
      backgroundColor: 'rgba(55, 57, 62, 1)',
      borderRadius: '12px',
      border: '1px solid #000000',
      top: '52px',
      maxHeight: '300px',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    listItems: {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingBottom: '6px',
      paddingTop: '6px',
      fontSize: '16px',
    },
  };

  const spacesRooms = useSelector(getSpacesRooms);
  const [spaceName, setSpaceName] = useState('loading');
  const [spaceNameLoading, setSpaceNameLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaderText, setLoaderText] = useState('Updating');
  const [defaultRoom, setDefaultRoom] = useState('loading');
  const [roomList, setRoomList] = useState([
    {
      id: '',
      text: (
        <div className="rooms-loader">
          <span style={{ paddingRight: '10px', paddingTop: '5px' }}>Loading rooms</span>
          <LoaderV2 />
        </div>
      ),
    },
  ]);

  const getRoomsList = (rooms: typeof spacesRooms.rooms) => {
    const roomList: any = [];
    if (_.keys(spacesRooms.rooms).length !== 0) {
      _.map(spacesRooms.rooms, (room, id) => {
        const roomData = { id: id, text: room?.roomDetails.roomName };
        roomList.push(roomData);
      });
      setRoomList(roomList);
    }
  };

  useEffect(() => {
    getRoomsList(spacesRooms.rooms);
  }, [spacesRooms]);

  useEffect(() => {
    (async () => {
      const token = await getToken();
      if (token) {
        if (!isSubDomain()) {
          const id = (await spaceObj.getID(props.spaceID, token)).data.id;
          await spaceObj.setSpaceCustomClaim(id, token);
          setCurrentSpace(id);
          setSpaceClaimLoading(false);
          const defaultRoom = (await spaceObj.getDefaultRoom(id, token)).data.defaultRoom;
          // const spaceName = (await spaceObj.getSpaceName(id, token)).data.spaceName;
          await getClaimToken();
          setDefaultRoom(defaultRoom);
          // setSpaceName(spaceName);
          setSpaceName(spacesRooms.spaces[id]?.spaceInfo.spaceName ?? '');
        } else {
          const id = (await spaceObj.getIDFromSubdomain(props.spaceID, token)).data.id;
          await spaceObj.setSpaceCustomClaim(id, token);
          setCurrentSpace(id);
          setSpaceClaimLoading(false);
          const defaultRoom = (await spaceObj.getDefaultRoom(id, token)).data.defaultRoom;
          // const spaceName = (await spaceObj.getSpaceName(id, token)).data.spaceName;
          await getClaimToken();
          setDefaultRoom(defaultRoom);
          // setSpaceName(spaceName);
          setSpaceName(spacesRooms.spaces[id]?.spaceInfo.spaceName ?? '');
        }
      }
    })();
  }, []);

  return (
    <>
      <div className="setting-header-label">
        <span>Space Controls</span>
        <div className="space-controls">
          <div className="rename-space-section">
            <div className="section-header">RENAME SPACE NAME</div>
            <div className="input-box">
              {spaceName === 'loading' && (
                <div className="space-name-loader">
                  <LoaderV2 />
                </div>
              )}
              {spaceName !== 'loading' && (
                <input
                  type="text"
                  value={spaceName}
                  onChange={(event) => {
                    setSpaceName(event.target.value);
                  }}
                />
              )}
              <div
                className="update-button"
                onClick={async () => {
                  try {
                    setSpaceNameLoading(true);
                    const token = await getToken();
                    if (token) {
                      let response = await spaceObj.updateSpaceName(spacesRooms.currentSpace, token, spaceName);
                      if (response.status !== 200) {
                        setLoaderText('Failed to Update');
                        sendAmplitudeData('Space name rename', { status: 'failed', newSpaceName: spaceName });
                      } else {
                        setSpaceName(spaceName);
                        setSpaceNameLoading(false);
                        sendAmplitudeData('Space name rename', { status: 'success', newSpaceName: spaceName });
                      }
                    }
                  } catch (e) {
                    console.log('Something went wrong while updating space name', e);
                  }
                }}
              >
                {!spaceNameLoading && <span> Update </span>}
                {spaceNameLoading && (
                  <span style={{ color: 'black' }}>
                    <LoaderV2 />
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="default-room-section">
            <div className="section-header">SELECT DEFAULT ROOM</div>
            <div className="drop-down">
              <DropDown
                headerTitle={{
                  text: (
                    <div className="drop-down-header">
                      {(defaultRoom === 'loading' || _.keys(spacesRooms.rooms).length === 0) && (
                        <>
                          <span>Default Room</span>
                          <div style={{ transform: 'translate(-10px, -4px)' }}>
                            <LoaderV2 />
                          </div>
                        </>
                      )}
                      {/* {console.log(spacesRooms)} */}

                      {defaultRoom !== 'loading' && _.keys(spacesRooms.rooms).length !== 0 && (
                        <>
                          <span>Default Room</span>
                          <span>
                            {!loading ? (
                              formatLong(spacesRooms.rooms[defaultRoom]?.roomDetails.roomName ?? '', 30)
                            ) : (
                              <>
                                <div className="rooms-loader">
                                  <span style={{ paddingRight: '10px', paddingTop: '5px' }}>{loaderText}</span>
                                  <LoaderV2 />
                                </div>
                              </>
                            )}
                          </span>
                        </>
                      )}
                    </div>
                  ),
                }}
                styles={DropDownStyle}
                options={roomList}
                onSelectItem={async (e: any) => {
                  // console.log(e);
                  try {
                    setLoading(true);
                    const token = await getToken();
                    if (token) {
                      let response = await spaceObj.setDefaultRoom(
                        spacesRooms.currentSpace,
                        token,
                        roomList[e.index].id
                      );
                      if (response.status !== 200) {
                        setLoaderText('Failed to Update');
                        sendAmplitudeData('Default room selected', {
                          status: 'failed',
                          newSpaceName: spaceName,
                          newDefaultRoom: roomList[e.index].id,
                        });
                      } else {
                        setDefaultRoom(roomList[e.index].id);
                        setLoading(false);
                        sendAmplitudeData('Default room selected', {
                          status: 'success',
                          newSpaceName: spaceName,
                          newDefaultRoom: roomList[e.index].id,
                        });
                      }
                    }
                  } catch (e) {
                    console.log('Something went wrong while setting default room', e);
                  }
                }}
              />
            </div>
          </div>
          <div className="space-owners-section">
            <div className="section-header">SELECT SPACE OWNERS FROM THE TEAM</div>
            <SpaceMembersV2 spaceID={props.spaceID} />
          </div>

          {/* To be used later */}
          {/* <div className="settings-buttons-section">
            <div className="cancel-btn">
              <span>Cancel</span>
            </div>
            <div className="save-changes-btn">
              <span>Save Changes</span>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

const Settings = () => {
  let { spaceID } = useParams<{ spaceID: string }>();
  if (!spaceID) {
    spaceID = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : '';
  }
  const [settingItem, setSettingItem] = useState('billing');

  return (
    <>
      <div className="settings-page">
        <div className="settings-page-sidebar">
          <div className="sidebar-header">
            <span>SPACE SETTINGS</span>
          </div>
          {/* <div className="sidebar-menu-item " onClick={() => setSettingItem('profile')}>
            <div>User Profile</div>
          </div> */}
          <div
            className="sidebar-menu-item"
            onClick={() => {
              setSettingItem('billing');
              sendAmplitudeData('Space Billing section clicked', {});
            }}
          >
            <div>Space Billing</div>
          </div>
          <div
            className="sidebar-menu-item"
            onClick={() => {
              setSettingItem('members');
              sendAmplitudeData('Space members section clicked', {});
            }}
          >
            <div>Space Controls</div>
          </div>
          {/* <div className="sidebar-menu-item" onClick={() => setSettingItem('crowd')}>
            <div>Crowd Management</div>
          </div> */}
        </div>
        <div className="settings-page-section">
          {/* {settingItem === 'profile' && <UserProfile />} */}
          {settingItem === 'billing' && <SpaceBilling spaceID={spaceID} />}
          {settingItem === 'members' && <SpaceControls spaceID={spaceID} />}
          {settingItem === 'crowd' && <CrowdManagement spaceID={spaceID} />}
        </div>
      </div>
      <ConfirmAction />
      <CrowdManagementModal />
    </>
  );
};

export default Settings;
