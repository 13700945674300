import * as mediasoupClient from 'mediasoup-client';
import { v4 } from 'uuid';
import { yetiServerIP } from './config';
import { Socket } from './socket';
import { handshakeStep } from './types/types';

export class Producer {
  socket?: Socket;
  deviceProducer: mediasoupClient.types.Device;
  producerTransport?: mediasoupClient.types.Transport;
  producerID: string;
  streamProducerCallback: { [streamID: string]: (arg: { id: string }) => void };
  producerRouterID?: string;
  mediasoupProducer?: mediasoupClient.types.Producer;
  pinger?: NodeJS.Timeout;

  destroy() {
    if (this.pinger) {
      clearInterval(this.pinger);
    }
    if (this.socket) {
      this.socket?.socket.close();
    }
  }

  constructor() {
    this.deviceProducer = new mediasoupClient.Device();
    this.producerID = v4();
    this.streamProducerCallback = {};
  }

  connect() {
    return new Promise<void>((resolve) => {
      this.socket = new Socket();
      this.socket.connect(yetiServerIP);
      this.socket.socket.onopen = () => {
        this.pinger = setInterval(() => {
          this.socket?.send({ type: 'ping', payload: { participantID: this.producerID } });
        }, 2000);
      };
      this.socket.onmessage((e) => {
        const handshake: handshakeStep = JSON.parse(e.data);
        switch (handshake.type) {
          case 'server-config': {
            (async () => {
              await this.deviceProducer.load({
                routerRtpCapabilities: handshake.payload.producerRouterRtpCapabilities,
              });
              this.producerTransport = this.deviceProducer.createSendTransport(handshake.payload.producerTransport);
              resolve();
              this.producerTransport.on('connect', ({ dtlsParameters }, callback) => {
                this.socket?.send({
                  type: 'transport-connect',
                  payload: {
                    participantID: this.producerID,
                    kind: 'producer',
                    dtlsParameters,
                  },
                });
                callback();
              });
              this.producerTransport.on('produce', async ({ kind, rtpParameters, appData }, callback) => {
                this.socket?.send({
                  type: 'transport-produce',
                  payload: {
                    participantID: this.producerID,
                    streamID: appData.streamID,
                    transportID: this.producerTransport?.id || '',
                    kind,
                    rtpParameters,
                    appData,
                  },
                });
                this.streamProducerCallback[appData.streamID] = callback;
                //callback({ id: handshake.payload.producerID })
              });
            })();
            break;
          }
          case 'produce-callback': {
            const callback = this.streamProducerCallback[handshake.payload.streamID];
            this.producerRouterID = handshake.payload.routerID;
            if (callback) {
              callback({ id: handshake.payload.producerID });
            }
            break;
          }
        }
      });
      this.socket?.send({
        type: 'init',
        payload: {
          participantID: this.producerID,
        },
      });
    });
  }

  async pushStream(track: MediaStreamTrack) {
    const streamID = v4();
    if (this.mediasoupProducer) {
      await this.mediasoupProducer.replaceTrack({ track });
    } else {
      this.mediasoupProducer = await this.producerTransport?.produce({
        track,
        encodings: [{ maxBitrate: 192000 }],
        appData: {
          streamID,
        },
      });
    }
    return {
      streamID,
      producerID: this.mediasoupProducer?.id || '',
      routerID: this.producerRouterID || '',
    };
  }
}
