import { DailyTrackState } from '@daily-co/daily-js';
import _ from 'lodash';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { MegaphoneFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { canvasSize, maxPlayableScreenshares } from '../../../config';
import {
  DailyCoCall,
  default as dailyco,
  default as DailyCoObj,
  default as dailyCoObj,
} from '../../../helper/interactive/dailyco';
import {
  addEleRoom,
  getRoom,
  setEleRoomCopy,
  updateRoomChild,
  watchBubbleRoomCopy,
  watchMegaphoneForOneUser,
  watchRoomChild,
} from '../../../helper/interactive/firebaseRTD';
import { findGetParameter } from '../../../helper/interactive/misc';
import overflowStream from '../../../helper/interactive/overflowStream';
import logger from '../../../helper/logger';
import rooms from '../../../helper/requests/rooms';
import { Consumer } from '../../../helper/yetiLiteClient/consumer';
import spatialAudioController from '../../../spatialAudio/spatialAudioOptimized';
import {
  getCustomClaimStore,
  getLocalParticipantFromStoreDailyco,
  getNumScreenshares,
  getSpatialAudio,
  getUpdateListener,
  getUpdateSource,
  setDeviceSetting,
  setIsScreenWall,
  updateScreen,
} from '../../../store';
import { getAuth } from '../../../store/auth';
import { getCustomClaimFromRedux } from '../../../store/customClaim';
import { getMediaPermissionsRedux, getRemoteParticipants, getRemoteState } from '../../../store/dailycoParticipants';
import { getOverflowStreamRedux } from '../../../store/overflowStream';
import { getUserPresence } from '../../../store/presence';
import { getCurrentRoomCopyRedux, getCurrentRoomPermission, getRoomCopyLoadingRedux } from '../../../store/spacesRooms';
import { getScreenVolRedux, getScreenWallRedux, getScreenWallV2 } from '../../../store/wallpaper';
import { canvas, dimension } from '../../../types/canvasFB';
import Avatar, { BadgeWrapper } from '../../avatars/avatar';
import Loader from '../../loader/loader';
import JoinRoomNew, { closeJoinNow } from '../../modals/uiOnlyModals/joinRoom/joinRoom';
import {
  hideNetworkWarning,
  hideOverflowStreamLoading,
  showNetworkWarning,
  showOverflowStreamLoading,
} from '../../notifications/notifications';
import BasicDiv from '../draggables/basicDiv/basicDiv';
import OnlyDrag from '../draggables/onlyDrag/OnlyDrag';
import {
  MediaJitsiNew,
  ScreenAudioEle,
  ScreenShareAudioFullScreen,
  ScreenShareFullScreen,
  ScreenShareNew,
} from '../video/video';
import { closeCamSettings } from './camSettings';
import FakeSources from './fakeSource';
import './jitsiRoom.scss';
import ScreenshareController, { screenshareControllerObj } from './screenshareController';

const overflow = overflowStream;

const getSetContent = (track: MediaStreamTrack | undefined, ele: HTMLVideoElement) => {
  return (flag: boolean) => {
    if (track) {
      if (flag) {
        const attach = () => {
          ele.srcObject = new MediaStream([track]);
          ele.autoplay = true;
          ele.playsInline = true;
        };
        attach();
      } else {
        ele.srcObject = null;
      }
      return true;
    }
    return false;
  };
};

const spawnX = 0 + ((canvasSize.w * 3) / 4) * (Math.random() * 2 - 1);
const spawnY = 100 + 700 * Math.random();

const OverflowStream = memo((props: { spaceID: string; roomID: string }) => {
  const overflowStream = useSelector(getOverflowStreamRedux);
  const auth = useSelector(getAuth);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [vidStream, setVidStream] = useState<MediaStreamTrack>();
  const [audStream, setAudStream] = useState<MediaStreamTrack>();
  const volume = useSelector(getScreenVolRedux);

  useEffect(() => {
    window.addEventListener('touchstart', () => {
      try {
        const iframe = document.querySelector('#canvas > iframe') as HTMLIFrameElement;
        if (iframe) {
          const audio = iframe.contentDocument?.getElementById('overflow-stream_audio') as HTMLAudioElement;
          if (audio && audio.paused) {
            audio.muted = false;
            audio.play();
          }
        }
      } catch (e) {}
    });
  }, []);

  useEffect(() => {
    //@ts-ignore
    window.setStream = (mediaStream: MediaStreamTrack) => {
      if (mediaStream) hideOverflowStreamLoading();
      setVidStream(mediaStream);
    };
    //@ts-ignore
    window.setAudioStream = (mediaStream: MediaStreamTrack) => {
      setAudStream(mediaStream);
    };
    //@ts-ignore
    window.audioPlayable = (flag: boolean) => {
      if (auth.user.id !== overflowStream.broadcasterID) setIsScreenWall(flag);
    };
    //@ts-ignore
    window.screenStopped = (isBroadcaster: boolean, flag: boolean) => {
      if (isBroadcaster && flag) overflow.stop(props.roomID, props.spaceID);
    };
    //@ts-ignore
    window.roomSpace = {
      roomID: props.roomID,
      spaceID: props.spaceID,
    };
  }, []);

  useEffect(() => {
    //@ts-ignore
    window.overflowBroadcasterID = overflowStream.broadcasterID;
    if (overflowStream.isLive) {
      if (overflowStream.broadcasterID === auth.user.id) showOverflowStreamLoading();
    } else {
      setVidStream(undefined);
      setAudStream(undefined);
      setIsScreenWall(false);
    }
  }, [overflowStream]);

  useEffect(() => {
    if (iframeRef.current?.contentWindow && iframeRef.current?.contentWindow.setVolume) {
      iframeRef.current?.contentWindow.setVolume(volume);
    }
  }, [iframeRef, volume]);

  useEffect(() => {
    if (audStream) setIsScreenWall(true);
    else setIsScreenWall(false);
  }, [audStream, overflowStream.broadcasterID, auth.user.id]);

  if (!overflowStream.isLive) return <></>;
  else
    return (
      <>
        <iframe
          ref={iframeRef}
          src={`/overflowStream?roomID=${props.roomID}&spaceID=${props.spaceID}&micID=${
            overflow.micID ? overflow.micID : ''
          }&vidID=${overflow.vidID ? overflow.vidID : ''}`}
          style={{ visibility: 'hidden' }}
          allow="microphone; camera; autoplay; display-capture;"
        />
        {vidStream && (
          <ScreenShareFullScreen
            key={'overflow-stream-screenshare'}
            isMuted={false}
            videoStream={vidStream}
            trackID={'overflow-stream-audio'}
            id={`overflow-stream_video`}
            position={{ x: spawnX, y: spawnY }}
            setContent={(flag, ele) => getSetContent(vidStream, ele)(flag)}
            isLocal={auth.user.id === overflowStream.broadcasterID}
            isScreen={false}
            streamLoading={false}
          />
        )}
      </>
    );
});

const Participant = memo(
  (props: {
    id: string;
    spaceID: string;
    roomID: string;
    isLocal: boolean;
    positions: canvas | null;
    roomCopy: string;
  }) => {
    let participant = useSelector(getRemoteState(props.id));
    const overflowStream = useSelector(getOverflowStreamRedux);
    let localParticipantDaily = getLocalParticipantFromStoreDailyco();
    const auth = useSelector(getAuth);
    const roomPermissions = useSelector(getCurrentRoomPermission);
    const firstUpdateVolCalled = useRef(false);
    const screenWall = useSelector(getScreenWallRedux);
    const roomCopy = useSelector(getCurrentRoomCopyRedux);
    const [bubbleRoomCopy, setBubbleRoomCopy] = useState(0);
    const screenWallV2 = useSelector(getScreenWallV2);
    const [screenHighQuality, setScreenHighQuality] = useState<MediaStreamTrack | null>(null);
    const [isMegaphoneEquipped, setMegaphoneEquipped] = useState(false);
    let screenEle = <></>;
    let id = participant.user_id;
    const positionRef = useRef(
      props.positions && props.positions[id]
        ? props.positions[id]
        : {
            w: roomPermissions.size,
            h: roomPermissions.size,
            x: spawnX,
            y: spawnY,
            d: {
              rc: 0,
            },
          }
    );
    const divRef = useRef<HTMLDivElement>(null);
    const subscribedToScreen = useRef(false);

    useEffect(() => {
      spatialAudioController.setUserIDDailyID(participant.user_id, props.id);
      if (props.isLocal) spatialAudioController.setLocalID(id);
    }, [props.id, participant.user_id]);

    useEffect(() => {
      if (roomCopy !== bubbleRoomCopy && !isMegaphoneEquipped) {
        spatialAudioController.changeDailySubscription(participant.user_id, false, true, true);
      } else if (spatialAudioController.vidOnPerm) {
        spatialAudioController.changeDailySubscription(participant.user_id, false, false, true);
      }
      spatialAudioController.setRcLookup(participant.user_id, bubbleRoomCopy);
    }, [roomCopy, bubbleRoomCopy, participant.user_id, isMegaphoneEquipped]);

    useEffect(() => {
      if (!(screenWallV2 && screenWall === id)) return;
      let consumer: Consumer | undefined = undefined;
      let isYetiConnected = false;
      const connectYetiIfNetwork = async () => {
        const dailystats = await dailyCoObj.callObj?.getNetworkStats();
        if (dailystats && dailystats.quality < 50 && isYetiConnected) {
          logger.info('Low quality, disconnecting high quality audio:', {
            quality: dailystats.quality,
            threshold: dailystats.threshold,
          });
          consumer?.destroy();
          consumer = undefined;
          setScreenHighQuality(null);
          isYetiConnected = false;
        } else if (dailystats && dailystats.quality > 50 && !isYetiConnected) {
          try {
            logger.info('high quality, connecting high quality audio:', {
              quality: dailystats.quality,
              threshold: dailystats.threshold,
            });
            consumer = new Consumer();
            const track = await consumer.connect(screenWallV2.streamID, screenWallV2.producerID, screenWallV2.routerID);
            logger.info('got yetiLite track:', { trackID: track.id });
            setScreenHighQuality(track);
            isYetiConnected = true;
            consumer.onStop = () => {
              setScreenHighQuality(null);
              isYetiConnected = false;
            };
          } catch {}
        }
      };
      connectYetiIfNetwork().catch(() => {});
      const networkCheck = setInterval(() => {
        connectYetiIfNetwork().catch(() => {});
      }, 10000);
      return () => {
        clearTimeout(networkCheck);
      };
    }, [screenWallV2, screenWall]);

    const screenID = `${id}_screen`;
    let watcher = (_callback: (c: dimension) => void) => () => {};
    const color = useMemo(() => Math.floor(Math.random() * 16777215).toString(16), []);

    const presenceAvatar = useSelector(getUserPresence(props.roomID, props.roomCopy, id));

    const [availabilityColor, setAvailabilityColor] = useState({ state: 'available', color: '34e3b9' });

    useEffect(() => {
      if (presenceAvatar && presenceAvatar.status) {
        if (presenceAvatar.status === 'Available') setAvailabilityColor({ state: 'available', color: '#34e3b9' });
        else if (presenceAvatar.status === 'Busy') setAvailabilityColor({ state: 'busy', color: 'red' });
        else if (presenceAvatar.status === 'Away') setAvailabilityColor({ state: 'away', color: 'orange' });
        else setAvailabilityColor({ state: 'available', color: '#34e3b9' });
      }
    }, [presenceAvatar?.status]);

    //@ts-ignore
    if (!window.presence) window.presence = {};
    //@ts-ignore
    window.presence[id] = presenceAvatar;

    const audioTrack = useMemo(() => (participant.tracks.audio ? participant.tracks.audio : undefined), [
      participant.tracks.audio,
    ]);

    const videoTrack = useMemo(() => {
      return participant.tracks.video.track && participant.tracks.video.state === 'playable'
        ? participant.tracks.video.track
        : undefined;
    }, [participant.tracks.video, participant.tracks.video.track]);

    const screenTrack = useMemo(
      () =>
        participant.tracks.screenVideo &&
        (participant.tracks.screenVideo.state === 'playable' || participant.tracks.screenVideo.state === 'sendable')
          ? participant.tracks.screenVideo.track
          : undefined,
      [participant.tracks.screenVideo]
    );

    const screenAudioTrack: DailyTrackState | undefined = useMemo(() => {
      return participant.tracks.screenAudio && participant.tracks.screenAudio.state === 'playable'
        ? participant.tracks.screenAudio
        : undefined;
    }, [participant.tracks.screenAudio]);

    useEffect(() => {
      if (participant.tracks.screenAudio.state === 'playable' && screenWall === id && !props.isLocal) {
        setIsScreenWall(true);
      } else if (
        !overflowStream.isLive &&
        (!screenWall ||
          screenWall.length < 1 ||
          (screenWall === id && participant.tracks.screenAudio.state !== 'playable'))
      ) {
        setIsScreenWall(false);
      }
    }, [participant.tracks.screenAudio, screenWall, props.isLocal]);

    const updateVolume = (id: string, xy: { x: number; y: number }, isListener: boolean) => {
      const audioID = `${id}_audio`;
      if (isListener) getUpdateListener()({ x: xy.x, y: xy.y });
      else {
        getUpdateSource(audioID)({ x: xy.x, y: xy.y });
      }
      if (!props.isLocal) spatialAudioController.recalculatePosition(audioID, getSpatialAudio(), true);
      else {
        spatialAudioController.calculateListenPosition(xy);
        spatialAudioController.recalculateAllVolumesInInfluence();
      }
    };

    useEffect(() => {
      if (positionRef.current && audioTrack?.state === 'playable')
        updateVolume(
          id,
          {
            x: positionRef.current.x,
            y: positionRef.current.y,
          },
          props.isLocal
        );
    }, [audioTrack]);

    useEffect(() => {
      if (positionRef.current && divRef.current && audioTrack?.state === 'sendable' && !firstUpdateVolCalled.current) {
        // if (!props.isLocal) spatialAudioController.muteSource(`${id}_audio`)
        updateVolume(
          id,
          {
            x: positionRef.current.x,
            y: positionRef.current.y,
          },
          props.isLocal
        );
        firstUpdateVolCalled.current = true;
      }
    }, [positionRef, divRef, audioTrack]);

    useEffect(() => {
      let unsub = () => {};
      if (participant.user_id) {
        unsub = watchBubbleRoomCopy(props.spaceID, props.roomID, participant.user_id, (roomCopy) => {
          if (roomCopy) setBubbleRoomCopy(roomCopy);
          else setBubbleRoomCopy(0);
        });
      }
      return unsub;
    }, [participant]);

    useEffect(() => {
      const unsub = watchMegaphoneForOneUser(props.spaceID, props.roomID, participant.user_id, (flag) => {
        setMegaphoneEquipped(flag === true);
      });
      return unsub;
    }, [props.spaceID, props.roomID, participant.user_id]);

    useEffect(() => {
      if (positionRef.current?.d?.rc !== undefined) {
        setBubbleRoomCopy(positionRef.current.d.rc);
      }
    }, [positionRef.current]);

    useEffect(() => {
      if (participant.tracks.screenVideo.state === 'playable') {
        screenshareControllerObj.setIsSubscribed(screenID, true);
      } else {
        screenshareControllerObj.setIsSubscribed(screenID, false);
      }
    }, [participant.tracks.screenVideo.state]);

    if (participant) {
      watcher = (callback: (c: dimension) => void) => {
        return watchRoomChild(props.spaceID, props.roomID, id, (c: dimension) => {
          if (c == null) return;
          positionRef.current = c;
          updateVolume(`${id}`, c, id === auth.user.id);
          callback(c);
          if (c?.d?.rc) setBubbleRoomCopy(c.d.rc);
        });
      };
      const watcherScreen = (callback: (c: dimension) => void) => {
        return watchRoomChild(props.spaceID, props.roomID, screenID, (c: dimension) => {
          if (c == null) return;
          const ele = document.getElementById(screenID + '_video') as HTMLElement;
          const rect = ele?.parentElement?.parentElement?.getBoundingClientRect();
          if (
            rect &&
            (participant.tracks.screenVideo.state === 'playable' || participant.tracks.screenVideo.state === 'sendable')
          ) {
            updateScreen(screenID, {
              position: { x: rect.x, y: rect.y },
              shape: { w: rect.width, h: rect.height },
              paused: false,
              playable: true,
              loading: false,
              sessionID: participant.session_id,
            });
          }
          callback(c);
        });
      };
      const streamUI: JSX.Element[] = [
        <div
          className="megaphone"
          style={{
            display: spatialAudioController.megaphones && spatialAudioController.megaphones[id] ? 'flex' : 'none',
            padding: '2px',
          }}
          id={`${id}_megaphone`}
          key={`${id}_megaphone`}
        >
          <div className="megaphone-icon">
            <MegaphoneFill style={{ width: '80%', height: '80%' }} />
          </div>
        </div>,
      ];
      if (participant.tracks) {
        _.map(participant.tracks, (track, key) => {
          if (
            key === 'screenVideo' &&
            participant.tracks.screenVideo.state !== 'off' &&
            participant.tracks.screenVideo.state !== 'blocked' &&
            participant.tracks.screenVideo.state !== 'loading'
          ) {
            const onDeleteTrigger = () => {
              localParticipantDaily.screenshare(false, '');
            };
            if (screenWall === id)
              screenEle = (
                <>
                  {screenHighQuality && !props.isLocal ? (
                    <ScreenShareAudioFullScreen
                      key={screenHighQuality?.id ? screenHighQuality?.id : 'stream-screenshare'}
                      isMuted={false}
                      videoStream={screenHighQuality}
                      trackID={screenHighQuality?.id ? screenHighQuality?.id : 'stream-audio'}
                      id={`${screenID}_hq_audio_video`}
                      position={{ x: spawnX, y: spawnY }}
                      setContent={(flag, ele) => getSetContent(screenHighQuality, ele)(flag)}
                      isLocal={props.isLocal}
                      isScreen={false}
                      streamLoading={false}
                    />
                  ) : (
                    <></>
                  )}
                  {!screenHighQuality && screenAudioTrack && !props.isLocal ? (
                    <ScreenShareAudioFullScreen
                      key={screenAudioTrack?.track?.id ? screenAudioTrack.track?.id : 'stream-screenshare'}
                      isMuted={props.isLocal}
                      videoStream={screenAudioTrack?.track}
                      trackID={screenAudioTrack?.track?.id ? screenAudioTrack?.track?.id : 'stream-audio'}
                      id={`${screenID}_video`}
                      position={{ x: spawnX, y: spawnY }}
                      setContent={(flag, ele) => getSetContent(screenAudioTrack?.track, ele)(flag)}
                      isLocal={props.isLocal}
                      isScreen={false}
                      streamLoading={track.state === 'loading'}
                    />
                  ) : (
                    <></>
                  )}
                  {participant.tracks.screenVideo.state === 'playable' ? (
                    <ScreenShareFullScreen
                      key={track.track?.id ? track.track.id : 'stream-screenshare'}
                      isMuted={track.state !== 'playable'}
                      videoStream={track.track}
                      trackID={track.track?.id ? track.track?.id : 'stream-audio'}
                      id={`${screenID}_video`}
                      position={{ x: spawnX, y: spawnY }}
                      setContent={(flag, ele) => getSetContent(track.track, ele)(flag)}
                      isLocal={props.isLocal}
                      isScreen={false}
                      streamLoading={track.state === 'loading'}
                    />
                  ) : (
                    <></>
                  )}
                </>
              );
            else
              screenEle = (
                <BasicDiv
                  isLocalUserScreenshare={auth.user.id === participant.user_id}
                  screenshareUsername={
                    presenceAvatar && presenceAvatar.name && presenceAvatar.name.length > 1
                      ? presenceAvatar.name
                      : participant.user_name
                  }
                  showScreenshareHeader
                  hideFloatingCrossBtn
                  hideFloatingDragBtn
                  id={screenID}
                  key={screenID}
                  onDeleteTrigger={onDeleteTrigger}
                  dragHandle={false}
                  w={props.positions && props.positions[screenID] ? props.positions[screenID].w : 300}
                  h={props.positions && props.positions[screenID] ? props.positions[screenID].h : 300}
                  x={props.positions && props.positions[screenID] ? props.positions[screenID].x : spawnX}
                  y={props.positions && props.positions[screenID] ? props.positions[screenID].y : spawnY}
                  d={props.positions && props.positions[screenID] ? props.positions[screenID].d : undefined}
                  onDimChange={(d) => {
                    updateRoomChild(props.spaceID, props.roomID, screenID, d);
                  }}
                  watcher={watcherScreen}
                  type={'screen'}
                >
                  <>
                    {participant.tracks.screenAudio.state === 'playable' && !props.isLocal ? (
                      <ScreenAudioEle
                        id={
                          participant.tracks.screenAudio.track?.id
                            ? participant.tracks.screenAudio.track?.id
                            : 'stream-screenshare-audio'
                        }
                        videoStream={participant.tracks.screenAudio.track}
                        trackID={
                          participant.tracks.screenAudio.track?.id
                            ? participant.tracks.screenAudio.track?.id
                            : 'stream-screenshare-audio'
                        }
                        dimension={{
                          w: props.positions && props.positions[screenID] ? props.positions[screenID].w : 300,
                          h: props.positions && props.positions[screenID] ? props.positions[screenID].h : 300,
                          x: props.positions && props.positions[screenID] ? props.positions[screenID].x : spawnX,
                          y: props.positions && props.positions[screenID] ? props.positions[screenID].y : spawnY,
                        }}
                        setContent={(flag, ele) => getSetContent(participant.tracks.screenAudio.track, ele)(flag)}
                        isMuted={false}
                        isLocal={props.isLocal}
                        streamLoading={false}
                        isSpatial={roomPermissions.spatial}
                        watcher={watcherScreen}
                        videoEleID={`${screenID}_video`}
                      />
                    ) : (
                      <></>
                    )}
                    <div
                      className="vidCont"
                      style={{
                        height: participant.tracks.screenVideo.state === 'playable' ? 'auto' : '100%',
                        position: 'relative',
                        border: 'none',
                      }}
                    >
                      {participant.tracks.screenVideo.state === 'playable' ? (
                        <ScreenShareNew
                          key={track.track?.id ? track.track.id : 'stream-screenshare'}
                          isMuted={track.state !== 'playable'}
                          videoStream={track.track}
                          trackID={track.track?.id ? track.track?.id : 'stream-audio'}
                          id={`${screenID}_video`}
                          position={{ x: spawnX, y: spawnY }}
                          setContent={(flag, ele) => getSetContent(track.track, ele)(flag)}
                          isLocal={props.isLocal}
                          isScreen={false}
                          streamLoading={track.state === 'loading'}
                        />
                      ) : (
                        <div
                          id={`${screenID}_video`}
                          className="screensharePlaceholder"
                          style={{ backgroundColor: `#${color}` }}
                        >
                          {getNumScreenshares() <= maxPlayableScreenshares ? (
                            <div className="hint">
                              Loading{' '}
                              {presenceAvatar && presenceAvatar.name && presenceAvatar.name.length > 1
                                ? presenceAvatar.name
                                : participant.user_name}
                              's screen
                            </div>
                          ) : (
                            <>
                              <div
                                className="hint"
                                id={`loader_${screenID}`}
                                style={{
                                  display: 'none',
                                  width: '70px',
                                  height: '70px',
                                  backgroundColor: 'transparent',
                                }}
                              >
                                <div className="loaderCont" style={{ display: 'flex' }}>
                                  <div className="loaderMain"></div>
                                </div>
                              </div>
                              <div className="hint" id={`hint_${screenID}`}>
                                Click{' '}
                                <span className="material-icons" style={{ transform: 'translateY(7px)' }}>
                                  visibility
                                </span>{' '}
                                to see or hide{' '}
                                {presenceAvatar && presenceAvatar.name && presenceAvatar.name.length > 1
                                  ? presenceAvatar.name
                                  : participant.user_name}
                                's screen
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                </BasicDiv>
              );
          } else if (key === 'audio') {
            streamUI.push(
              <MediaJitsiNew
                key={audioTrack?.track?.id ? audioTrack?.track?.id : 'stream-audio'}
                isMuted={track.state !== 'playable' && track.state !== 'sendable'}
                videoStream={audioTrack?.track}
                trackID={audioTrack?.track?.id ? audioTrack?.track?.id : 'stream-audio'}
                id={`${id}_audio`}
                position={{ x: spawnX, y: spawnY }}
                isAudio={true}
                setContent={(flag, ele) => getSetContent(audioTrack?.track, ele)(flag)}
                isLocal={props.isLocal}
                isScreen={false}
                streamLoading={track.state === 'loading'}
              />
            );
          } else if (key === 'video') {
            streamUI.push(
              track.state === 'playable' ? (
                <BadgeWrapper
                  isAdmin={presenceAvatar?.permission?.permission?.isAdmin === true}
                  isHost={presenceAvatar?.permission?.role === 'h'}
                  isSpeaker={presenceAvatar?.permission?.role === 's'}
                  isLocalUser={props.isLocal}
                  id={id}
                >
                  <div
                    className="vidCont"
                    style={{ overflow: 'hidden', border: `3px solid ${availabilityColor.color}` }}
                  >
                    <>
                      <MediaJitsiNew
                        key={videoTrack?.id ? videoTrack.id : 'stream-video'}
                        isMuted={track.state !== 'playable'}
                        videoStream={participant.videoTrack}
                        trackID={videoTrack?.id ? videoTrack.id : 'stream-video'}
                        id={`${id}_video`}
                        position={{
                          x: positionRef.current ? positionRef.current.x : spawnX,
                          y: positionRef.current ? positionRef.current.y : spawnX,
                        }}
                        isAudio={false}
                        setContent={(flag, ele) => getSetContent(videoTrack, ele)(flag)}
                        isLocal={props.isLocal}
                        isScreen={false}
                        streamLoading={false}
                      />
                      <Loader />
                    </>
                  </div>
                </BadgeWrapper>
              ) : (
                // <div
                //   className="videoPlaceholder"
                //   key={track.track?.id ? track.track.id : 'stream-video'}
                //   style={{ backgroundColor: '#' + color }}
                // >
                //   <span id={`${id}_video`}>{participant.user_name ? getInitials(participant.user_name) : 'A'}</span>
                // </div>
                <BadgeWrapper
                  isAdmin={presenceAvatar?.permission?.permission?.isAdmin === true}
                  isHost={presenceAvatar?.permission?.role === 'h'}
                  isSpeaker={presenceAvatar?.permission?.role === 's'}
                  isLocalUser={props.isLocal}
                  id={id}
                >
                  <>
                    <span id={`${id}_video`}></span>

                    <Avatar
                      src={presenceAvatar && presenceAvatar.profilePic ? presenceAvatar.profilePic : ''}
                      color={presenceAvatar && presenceAvatar.color ? presenceAvatar.color : color}
                      name={
                        presenceAvatar && presenceAvatar.name && presenceAvatar.name.length > 1
                          ? presenceAvatar.name
                          : participant.user_name
                      }
                    />
                  </>
                </BadgeWrapper>
              )
            );
          }
        });
      }
      return (
        <>
          {(bubbleRoomCopy === roomCopy || isMegaphoneEquipped) && (
            <>
              <div style={{ display: 'none' }} id={`${id}_audio_listener`} ref={divRef}></div>
              <OnlyDrag
                key={id}
                childrenKey={`${id}_audio`}
                x={positionRef.current ? positionRef.current.x : spawnX}
                y={positionRef.current ? positionRef.current.y : spawnY}
                isParticipant={true}
                onDimChange={(d) => {
                  updateRoomChild(props.spaceID, props.roomID, id, d);
                }}
                presence={presenceAvatar}
                name={
                  presenceAvatar && presenceAvatar.name && presenceAvatar.name.length > 1
                    ? presenceAvatar.name
                    : participant.user_name
                }
                watcher={watcher}
                children={
                  <>
                    {streamUI}
                    {audioTrack?.state === 'blocked' || audioTrack?.state === 'off' ? (
                      <div className="muteIcon">
                        <span className="material-icons">mic_off</span>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                }
              />
            </>
          )}
          <>{(bubbleRoomCopy === roomCopy || screenWall === id) && screenEle}</>
        </>
      );
    }
    return <></>;
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.isLocal === prevProps.isLocal &&
      prevProps.roomID === nextProps.roomID &&
      prevProps.spaceID === nextProps.spaceID
    );
  }
);

const Room = (props: { id: string; spaceID: string }) => {
  const firstJoin = useRef(true);
  const roomCopy = useSelector(getCurrentRoomCopyRedux);
  const roomPermissions = useSelector(getCurrentRoomPermission);
  const auth = useSelector(getAuth);
  const history = useHistory();
  const dontSubscribe = findGetParameter('dontSubscribe');
  const remoteParticipants = useSelector(getRemoteParticipants(), (left, right) => {
    if (_.keys(left).length !== _.keys(right).length) return false;
    for (let rKey in right) {
      if (!left[rKey]) return false;
    }
    return true;
  });
  const mediaPermissions = useSelector(getMediaPermissionsRedux);
  const [positions, setPositions] = useState<canvas>({});
  const customClaims = useSelector(getCustomClaimFromRedux);
  const roomCopyLoading = useSelector(getRoomCopyLoadingRedux);

  useEffect(() => {
    if (roomCopyLoading) return;
    if (mediaPermissions) {
      const permissions = mediaPermissions;
      Join(
        permissions.isVidDisabled,
        permissions.isMicDisabled,
        !permissions.isVidMute,
        !permissions.isMute,
        permissions.vidID,
        permissions.micID
      );
      firstJoin.current = false;
    } else {
      const localParticipant = getLocalParticipantFromStoreDailyco();
      Join(
        localParticipant.isVidDisabled,
        localParticipant.isMicDisabled,
        !localParticipant.isVidMute,
        !localParticipant.isMute,
        localParticipant.stream.videoID,
        localParticipant.stream.micID
      );
      firstJoin.current = false;
    }
    return () => {
      if (dailyCoRef.current) {
        dailyCoRef.current.leave();
      }
    };
  }, [props.id, props.spaceID, roomCopyLoading]);

  const dailyCoRef = useRef<DailyCoCall>();

  useEffect(() => {
    return () => {
      dailyCoRef.current?.leave();
    };
  }, [props.id, props.spaceID, dailyCoRef]);

  useEffect(() => {
    if (!customClaims?.roomUserRoleV2 || !customClaims?.roomUserRoleV2[props.id]) return;
    const megaphoneWatcher = spatialAudioController.megaphoneListener(props.spaceID, props.id);
    return () => megaphoneWatcher();
  }, [props, customClaims]);

  useEffect(() => {
    if (!roomPermissions.spatial) {
      spatialAudioController.spatialOff(getSpatialAudio(), true);
    } else {
      spatialAudioController.spatialOff(getSpatialAudio(), false);
      spatialAudioController.recalculateAllVolumesInInfluence();
    }
  }, [roomPermissions.spatial]);

  useEffect(() => {
    spatialAudioController.setBubbleSize(roomPermissions.size);
  }, [roomPermissions.size]);

  useEffect(() => {
    setEleRoomCopy(props.spaceID, props.id, auth.user.id, roomCopy).catch();
  }, [roomCopy]);

  const Join = (
    camDisabled: boolean,
    micDisabled: boolean,
    camOn: boolean,
    micOn: boolean,
    cameraID: string | undefined,
    micID: string | undefined
  ) => {
    // setCurrentRoomAndSpace(auth.user.id, props.spaceID, props.id);
    JoinRoom(camDisabled, micDisabled, camOn, micOn, cameraID, micID);
    closeCamSettings();
    closeJoinNow();
    firstJoin.current = false;
  };

  const JoinRoom = (
    camDisabled: boolean,
    micDisabled: boolean,
    camOn: boolean,
    micOn: boolean,
    cameraID: string | undefined,
    micID: string | undefined
  ): Promise<Function> => {
    return new Promise((resolve) => {
      /**
       * sync the device settings with the store
       */
      setDeviceSetting({ camDisabled, micDisabled, camOn, micOn, cameraID, micID });
      rooms.checkAndSetClaim(props.id).then(() => {
        getRoom(props.spaceID, props.id).then((c) => {
          let ids = _.keys(c);
          setPositions(c);
          const promises: Promise<any>[] = [
            dailyCoObj.leave().catch(() => {}),
            setEleRoomCopy(props.spaceID, props.id, auth.user.id, roomCopy),
          ];
          if (!c || !c[auth.user.id]?.x) {
            promises.push(
              new Promise(async (resolve) => {
                try {
                  const customClaim = getCustomClaimStore();
                  if (!(customClaim?.roomUserRoleV2 && customClaim?.roomUserRoleV2[props.id])) {
                    await rooms.checkAndSetClaim(props.id);
                  }
                } catch {}
                addEleRoom(props.spaceID, props.id, auth.user.id, {
                  x: spawnX,
                  y: spawnY,
                  w: 500,
                  h: 300,
                  d: {
                    rc: roomCopy,
                  },
                }).catch(() => console.error('failed to init bubble.'));
                resolve([]);
              })
            );
          }
          if (!ids.includes(auth.user.id + '_screen')) {
            promises.push(
              new Promise(async (resolve) => {
                try {
                  const customClaim = getCustomClaimStore();
                  if (!(customClaim?.roomUserRoleV2 && customClaim?.roomUserRoleV2[props.id])) {
                    await rooms.checkAndSetClaim(props.id);
                  }
                } catch {}
                addEleRoom(props.spaceID, props.id, auth.user.id + '_screen', {
                  x: spawnX,
                  y: spawnY,
                  w: 500,
                  h: 300,
                }).catch(() => console.error('failed to init screen.'));
                resolve([]);
              })
            );
          }
          Promise.all(promises).then(() => {
            const dailyCo = DailyCoObj;
            dailyco.setRoom(props.id, auth.user.token, auth.user.name, roomCopy);
            spatialAudioController.setCallObj(dailyCo);
            dailyCoRef.current = dailyCo;
            dailyCo
              .join(
                props.id,
                roomCopy,
                cameraID,
                micID,
                camDisabled,
                micDisabled,
                !micOn,
                !camOn,
                (threshold, quality) => {
                  if (threshold !== 'good') {
                    showNetworkWarning();
                  } else {
                    hideNetworkWarning();
                  }
                }
              )
              .then(() => {
                if (findGetParameter('screenshare')) {
                  navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
                    dailyCoRef.current?.callObj?.startScreenShare({ mediaStream: stream });
                  });
                }
                resolve(() => {});
              });
          });
        });
      });
    });
  };
  return (
    <>
      {/* <SoundSources num={numofSources ? parseInt(numofSources) : 0} /> */}
      {_.map(remoteParticipants, (participant, key) => {
        if (dailyCoRef.current && !dontSubscribe) {
          if (getNumScreenshares() <= maxPlayableScreenshares) {
            dailyCoRef.current.callObj?.updateParticipant(key, {
              setSubscribedTracks: { screenVideo: true, screenAudio: true },
            });
          }
        }
        return (
          <Participant
            key={key}
            id={key}
            spaceID={props.spaceID}
            roomID={props.id}
            isLocal={key === 'local'}
            positions={positions}
            roomCopy={roomCopy + ''}
          />
        );
      })}
      {/* <CamSetting roomID={props.id} joinCall={Join} /> */}
      <JoinRoomNew joinCall={Join} />
      <ScreenshareController />
      {findGetParameter('fakeSrc') ? <FakeSources num={parseInt(findGetParameter('fakeSrc') as string)} /> : <></>}
      {/*<OverflowStream spaceID={props.spaceID} roomID={props.id} />*/}
    </>
  );
};
export default Room;
