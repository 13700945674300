import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { formatLong } from '../../helper/interactive/misc';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getGetModalState } from '../../store/modal';
import { getSpacesRooms } from '../../store/spacesRooms';
import DropDown from '../dropdown/dropdown';
import { ModalStyle } from '../modal/modal';
import './spaceSwitcher.scss';

export const spaceSwitcherID = 'space-switcher-id';
export const openSpaceSwitcherModal = openModalThunk(spaceSwitcherID);
export const closeSpaceSwitcherModal = closeModalThunk(spaceSwitcherID);

const SpaceSwitcherModal = () => {
  const spaceSwitcherState = useSelector(getGetModalState(spaceSwitcherID));

  const [spaceList, setSpaceList] = useState<{ [id: string]: string }>({});
  const [spaceID, setSpaceID] = useState('123');
  const spacesRooms = useSelector(getSpacesRooms);

  const getSpacesIDFormatted = () => {
    let spaceIDSet = false;
    for (const i in spacesRooms.spaces) {
      setSpaceList((spaceList) => {
        if (!spaceIDSet && !spacesRooms.spaces[i]?.permission.isGuest) {
          setSpaceID(spacesRooms.spaces[i]?.spaceInfo.spaceID ?? '');
          spaceIDSet = true;
        }
        return {
          ...spaceList,
          [spacesRooms.spaces[i]?.spaceInfo.spaceID ?? '']: spacesRooms.spaces[i]?.spaceInfo.spaceName ?? '',
        };
      });
    }
  };

  useEffect(() => {
    getSpacesIDFormatted();
  }, [spacesRooms.spaces]);

  const DropDownStyle = {
    dropDownHeader: { paddingLeft: '8px', paddingRight: '8px' },
    listOuter: {
      backgroundColor: '#393B41',
      borderRadius: '8px',
      border: '1px solid #000000',
      top: '42px',
      marginTop: '8px',
    },
    listItems: {
      paddingLeft: '8px',
      paddingBottom: '6px',
      paddingTop: '6px',
    },
  };

  useEffect(() => {
    newModalThunk(spaceSwitcherID, false)();
  }, []);
  return (
    <ReactModal id={spaceSwitcherID} className={spaceSwitcherID} isOpen={spaceSwitcherState} style={ModalStyle}>
      <>
        <div className="body">
          <div className="header">
            <div className="title">You are part of multiple spaces</div>
            <div className="subtitle">Please select a space you want to join</div>
          </div>
          <div className="space-dd-container">
            <div className="space-dd-header">SPACE NAME</div>
            <div className="space-dd-selector">
              <DropDown
                headerTitle={spaceList[spaceID] ? formatLong(spaceList[spaceID], 30) : 'Select a space'}
                styles={DropDownStyle}
                showStatusColor={false}
                options={spaceList}
                optionsListType="array"
                isCamelcase={false}
                isItemDict={true}
                onSelectItem={(e: any, key: any) => {
                  setSpaceID(key);
                }}
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="buttons">
            <div
              className="enterspace"
              onClick={() => {
                window.location.href = '/s/' + spaceID;
              }}
            >
              Enter space
            </div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default SpaceSwitcherModal;
