import { configureStore, createSlice } from '@reduxjs/toolkit';

const initialState: {
  reaction:
    | { type: 'text' | 'sticker' | undefined; payload: { text?: string; url?: string }; sentTime?: number }
    | undefined;
} = {
  reaction: {
    type: undefined,
    payload: {},
    sentTime: new Date().getTime(),
  },
};

export const reactionsSlice = createSlice({
  name: 'reactions',
  initialState: initialState,
  reducers: {
    setReaction: (state, action) => {
      //   console.log(state, action.payload);
      state.reaction = action.payload;
    },
  },
});

export const reactionStore = configureStore({
  reducer: reactionsSlice.reducer,
  devTools: true,
});

export const getReactionRedux = (globalState: { reactions: ReturnType<typeof reactionStore.getState> }) =>
  globalState.reactions.reaction;
