import React, { useRef } from 'react';
import { config } from '../../../config';
import { getClaimToken } from '../../../helper/firebase/auth';
import { getAuthState } from '../../../store';
import FileIcon from '../fileUploadModal/fileIcon.png';
import '../ShareLinkBoxViewer/style.scss';
import './fileViewer.scss';

export const FileViewer = (props: {
  url: string;
  title: string;
  uid: string;
  isAdmin: boolean;
  icon: string;
  onDeleteTrigger: Function;
  spaceID: string;
}) => {
  const onMouseDownTimeRef = useRef<Date | null>(null);

  const getSecondsBetweenDates = (t1: Date, t2: Date) => {
    const dif = t1.getTime() - t2.getTime();
    const Seconds_from_T1_to_T2 = dif / 1000;
    return Math.abs(Seconds_from_T1_to_T2);
  };

  const openUrlInNewTab = async () => {
    const token = await getClaimToken();
    if (token) {
      const url =
        config.firebaseCloudURL + `/downloadFile?spaceID=${props.spaceID}&token=${token.token}&fileName=${props.url}`;
      window.open(url);
    }
  };

  const onMouseDown = () => {
    onMouseDownTimeRef.current = new Date();
  };

  const onMouseUp = () => {
    if (onMouseDownTimeRef.current) {
      const secondsBetweenDownAndUp = getSecondsBetweenDates(onMouseDownTimeRef.current, new Date());
      if (secondsBetweenDownAndUp < 0.5) {
        openUrlInNewTab();
      }
    }
  };

  const shouldShowDelete = () => {
    if (props.isAdmin || getAuthState().user.id === props.uid) {
      return true;
    }
    return false;
  };

  return (
    <div style={{ cursor: 'pointer' }} className="share-linkbox-viewer">
      <div onMouseDown={onMouseDown} onMouseUp={onMouseUp} onTouchStart={onMouseDown} onTouchEnd={onMouseUp}>
        <div className="icon">
          <div className="fileIcon">
            {!props.icon || props.icon.length < 1 ? (
              <img src={FileIcon} />
            ) : (
              <div className="file-icon-container">
                <i className={props.icon}></i>
              </div>
            )}
          </div>
        </div>
      </div>
      <p className="title" title={props.title}>
        {props.title}
      </p>
      <div className="actions">
        {shouldShowDelete() && (
          <button className="delete" onClick={() => props.onDeleteTrigger()}>
            Delete
          </button>
        )}
      </div>
    </div>
  );
};
