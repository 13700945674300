import { signin, signup } from './firebase/auth';
import logger from './logger';

export function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validateSpecialChars(text: string) {
  const format = /[!@#$%^&*()_+\- =\[\]{};':"\\|,.<>\/?]+/;
  return format.test(text);
}

export const signupHelper = async (fname: string, lname: string, email: string, password: string) => {
  const name = fname.trim() + ' ' + lname.trim();
  logger.info('Signup called', { enteredName: name, enteredEmail: email });

  if (!name || fname.length < 3) {
    return {
      message: 'First name should be 3 or more characters long.',
      success: false,
      user: null,
    };
  }

  if (fname.trim().length !== fname.length && fname.trim().length === 0) {
    return {
      message: 'First name should not contain only spaces.',
      success: false,
      user: null,
    };
  }

  if (validateSpecialChars(fname) || validateSpecialChars(fname)) {
    return {
      message: 'Name should not contain special characters.',
      success: false,
      user: null,
    };
  }

  if (fname.trim().length > 25) {
    return {
      message: 'First name should be atmost 25 characters.',
      success: false,
      user: null,
    };
  }

  if (lname.trim().length > 25) {
    return {
      message: 'Last name should be atmost 25 characters.',
      success: false,
      user: null,
    };
  }

  if (!email || email.length < 3 || !validateEmail(email)) {
    return {
      message: 'Email is invalid.',
      success: false,
      user: null,
    };
  }

  if (!password || password.length < 6) {
    return {
      message: 'Password should be 6 or more characters long.',
      success: false,
      user: null,
    };
  }

  try {
    const user = await signup(email, name, password);
    logger.info('Signup successful', { enteredName: name, enteredEmail: email });
    if (!user || !user.emailVerified) {
      return {
        message: `Check your inbox and verify your email to get started.`,
        success: true,
        user: null,
      };
    } else
      return {
        message: '',
        success: true,
        user: user,
      };
  } catch (e) {
    logger.warn('Signup error', { error: e });
    return {
      message: e.message || JSON.stringify(e),
      success: false,
      user: null,
    };
  }
};

export const signinHelper = async (email: string, password: string) => {
  logger.info('Signin called', { enteredEmail: email });
  if (email.length < 3) {
    return {
      message: 'Email is invalid.',
      success: false,
      user: null,
    };
  }
  if (password.length < 5) {
    return {
      message: 'Password is invalid.',
      success: false,
      user: null,
    };
  }
  try {
    logger.info('Signing requested.', { email });
    let user = await signin(email, password);
    if (user === null) {
      return {
        message: 'Email or password is wrong.',
        success: false,
        user: null,
      };
    } else if (user === false) {
      return {
        message: 'You havent verified your email yet.',
        success: false,
        user: false,
      };
    } else {
      return {
        message: '',
        success: true,
        user: true,
      };
    }
  } catch (e) {
    logger.warn('Signin failed:', { error: e.message || JSON.stringify(e) });
    return {
      message: 'Email or password is wrong.',
      success: false,
      user: null,
    };
  }
};
