import Tippy from '@tippyjs/react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import firebase from 'firebase';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { ChevronsLeft, Send, Settings, Smile } from 'react-feather';
import { useSelector } from 'react-redux';
import BackButton from '../../assets/svg/backButton';
import { sendAmplitudeData } from '../../helper/amplitude';
import { getClaimToken } from '../../helper/firebase/auth';
import { saveOnePermissionV2 } from '../../helper/interactive/firebaseRTD';
import { clearRoomChat } from '../../helper/requests/chat';
import { toggleChatOpenStore } from '../../store';
import { getAuth } from '../../store/auth';
import { getChatMessages } from '../../store/chat';
import { getSpacesRooms } from '../../store/spacesRooms';
import { useOutsideEvent } from '../interactive/giphy/Giphy';
import './chatbox.scss';
import Message from './message';

export const ChatWidget = (props: { toClose: () => void }) => {
  const auth = useSelector(getAuth);

  const user = auth.user;
  const spacesRooms = useSelector(getSpacesRooms);
  const currentRoomName = spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails.roomName ?? '';
  const userRole = spacesRooms.currentUserRole;
  const isAdmin = spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin;
  const [userInfo, setUserInfo] = useState({ name: '', profilePic: '', status: '', nickname: '', isDeleted: false });
  const [showChatSettings, setShowChatSettings] = useState(false);

  useEffect(() => {
    setUserInfo({
      name: user.name !== '' ? user.name : auth.userDetails.name,
      profilePic: auth.userDetails.profilePic,
      status: auth.userDetails.desc,
      nickname: user.email ? user.email : auth.userDetails.username,
      isDeleted: false,
    });
  }, [auth.userDetails]);

  const messages = useSelector(getChatMessages);
  const [limit, setLimit] = useState(50);
  const loaderRef = useRef<HTMLDivElement | null>(null);
  const [enableChat, setEnableChat] = useState(
    !spacesRooms.permissionV2['a']?.chat ? spacesRooms.permissionV2['a']?.chat : true
  );
  const [emoji, setEmoji] = useState(false);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);
  const chatSettingsRef = useRef<HTMLDivElement | null>(null);
  const [msgText, setMsg] = useState('');

  useOutsideEvent(
    emojiPickerRef,
    () => {
      setEmoji(false);
    },
    '<>',
    '#emoji-icon'
  );

  useOutsideEvent(
    chatSettingsRef,
    () => {
      setShowChatSettings(false);
    },
    'chatsettings',
    '#chatsettings'
  );

  const sendMessage = async (e: any) => {
    e.preventDefault();

    setMsg('');

    await firebase
      .firestore()
      .collection('roomV2')
      .doc(spacesRooms.currentRoom)
      .collection('messages')
      .add({
        text: msgText,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: user.id,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: user.id,
        isDeleted: false,
        userInfo: userInfo,
        permissions: { isGuest: spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isGuest },
      });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    var objDiv = document.getElementById('messages-content');
    if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  };

  return (
    <div className="chatbar">
      <div className="chatbox-header">
        <button
          className="chat-back-button"
          onClick={() => {
            toggleChatOpenStore(false);
          }}
        >
          <BackButton />
        </button>

        <div className="chat-room-name">
          {currentRoomName.length <= 20 ? (
            <span>{currentRoomName + ' Chat'}</span>
          ) : (
            <Tippy content={<span>{currentRoomName}</span>} placement="top" arrow={false} animation="scale">
              <span>{currentRoomName + ' Chat'}</span>
            </Tippy>
          )}
        </div>

        <div className="action-icons">
          {(isAdmin || userRole === 'h') && (
            <div
              className="chatsettings"
              id="chatsettings"
              ref={chatSettingsRef}
              onClick={() => {
                setShowChatSettings(!showChatSettings);
              }}
            >
              <div className="action-icon">
                <Settings />
              </div>

              {showChatSettings && (
                <div className="chat-settings-dropdown">
                  {(isAdmin || userRole === 'h') && (
                    <div
                      className="option"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        saveOnePermissionV2(spacesRooms.currentSpace, spacesRooms.currentRoom, !enableChat, 'chat');
                        setEnableChat(!enableChat);
                        setShowChatSettings(false);
                      }}
                    >
                      <span>{enableChat ? 'Disable audience chat' : 'Enable audience chat'}</span>
                    </div>
                  )}

                  {/* {(isAdmin || userRole === 'h') && (
                    <div
                      className="option"
                      onClick={() => {
                      }}
                    >
                      Hide chat
                    </div>
                  )} */}

                  {isAdmin && (
                    <div
                      className="option"
                      onClick={async () => {
                        const token = await getClaimToken();
                        if (token) clearRoomChat(spacesRooms.currentRoom, token.token);
                        setShowChatSettings(false);
                      }}
                    >
                      Clear chat
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <div
            className="action-icon"
            onClick={() => {
              props.toClose();
            }}
          >
            <ChevronsLeft />
          </div>
        </div>

        {!enableChat && spacesRooms.currentUserRole !== 'a' && (
          <div className="disabled-chat-notification">
            <span>Audience chat is currently disabled</span>
          </div>
        )}
      </div>
      <div
        className="message-box"
        id="messages-content"
        ref={loaderRef}
        onScroll={(e) => {
          if (loaderRef.current) {
            if (loaderRef.current.scrollTop === 0) {
              setLimit((limit) => limit + 20);
            }
          }
        }}
      >
        <div className="msg-content">
          {_.map(
            _.slice(messages, 0, limit),
            (msg: {
              text: string | undefined;
              id: string | undefined;
              createdAt: { seconds: number; nanoseconds: number } | undefined;
              createdBy: string | undefined;
              userInfo: { name: string; nickname: string; profilePic: string; status: string; email: string };
            }) => {
              return (
                <Message
                  text={msg.text ? msg.text : ''}
                  createdAt={msg.createdAt ? msg.createdAt : { seconds: 0, nanoseconds: 0 }}
                  createdBy={msg.createdBy ? msg.createdBy : ''}
                  displayName={msg.userInfo.name}
                  photoURL={msg.userInfo.profilePic}
                  email={msg.userInfo.email}
                  status={msg.userInfo.status}
                  key={msg.id ? msg.id : ''}
                  id={msg.id ? msg.id : ''}
                />
              );
            }
          )}
        </div>
      </div>
      <div className="input-box-container">
        {spacesRooms.permissionV2[spacesRooms.currentUserRole]?.chat && (
          <div className="input-box">
            <input
              placeholder="Type a message…"
              maxLength={256}
              value={msgText}
              onChange={(e) => setMsg(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  sendMessage(e);
                }
              }}
            ></input>
            <div className="emoji-button">
              {emoji && (
                <div className="emoji-picker" ref={emojiPickerRef}>
                  <Picker
                    theme="dark"
                    title=""
                    showPreview={false}
                    native={true}
                    style={{ backgroundColor: 'black', width: '308px' }}
                    onSelect={(e: any) => {
                      setMsg(msgText + e.native);
                    }}
                  />
                </div>
              )}
              <button
                className={emoji ? 'emoji-icon yellow' : 'emoji-icon'}
                id="emoji-icon"
                onClick={() => {
                  setEmoji(!emoji);
                }}
              >
                <Smile />
              </button>
            </div>
            <button
              className="send-button"
              onClick={(e) => {
                if (msgText.length > 0) {
                  sendMessage(e);
                  setMsg('');
                  sendAmplitudeData('Chat message sent', { messageLength: msgText.length });
                }
              }}
            >
              <Send />
            </button>
          </div>
        )}

        {!spacesRooms.permissionV2[spacesRooms.currentUserRole]?.chat && (
          <div className="disabled-chat-message">Audience Chat has been disabled in this room</div>
        )}
      </div>
    </div>
  );
};
// const ChatBox = () => {
//   const auth = useSelector(getAuth);
//   const [hideState, setHideState] = useState(true);

//   return (
//     <>
//       {auth.isLoggedIn && (
//         <>
//           {!hideState && (
//             <button
//               className="show-chat"
//               onClick={() => {
//                 setHideState(!hideState);
//               }}
//             >
//               <span className="material-icons">chat_bubble_outline</span>
//             </button>
//           )}
//           {hideState && <ChatWidget />}
//         </>
//       )}
//     </>
//   );
// };

// export default ChatBox;
