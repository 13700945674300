import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ScreenShareAudioFullScreen } from '../../components/interactive/video/video';
import dailyCoObj from '../../helper/interactive/dailyco';
import { watchOverflowStream } from '../../helper/interactive/firebaseRTD';
import { findGetParameter } from '../../helper/interactive/misc';
import { setCurrentScreenVol } from '../../store';
import { getAuth } from '../../store/auth';
import { overflowStreamType } from '../../types/canvasFB';

export const Connect = (props: { roomID: string; spaceID: string }) => {
  const [overflowStreamData, setoverflowStreamDate] = useState<overflowStreamType>();
  const auth = useSelector(getAuth);
  const [audioTrack, setAudioTrack] = useState<MediaStreamTrack>();
  const vidID = findGetParameter('vidID');
  const micID = findGetParameter('micID');

  useEffect(() => {
    const unsub = watchOverflowStream(props.spaceID, props.roomID, (data) => {
      setoverflowStreamDate(data);
    });
    return unsub;
  }, [props.roomID, props.spaceID]);

  useEffect(() => {
    if (overflowStreamData?.broadcasterID) {
      (async () => {
        let mediaStream: MediaStream | undefined = undefined;
        if (overflowStreamData.broadcasterID === auth.user.id) {
          if (!vidID || vidID?.length < 1)
            // @ts-ignore
            mediaStream = window.parent.displayStream;
          else {
            mediaStream = await navigator.mediaDevices.getUserMedia({
              video: {
                deviceId: vidID,
              },
              audio:
                micID && micID.length > 1
                  ? {
                      deviceId: micID,
                      autoGainControl: false,
                      noiseSuppression: false,
                      echoCancellation: false,
                      channelCount: 2,
                    }
                  : false,
            });
          }
        }
        window.setVolume = (vol) => {
          setCurrentScreenVol(vol);
        };
        await dailyCoObj.joinOverflowStream(
          overflowStreamData.broadcasterID,
          props.roomID.slice(0, 25) + '-of',
          (stream) => {
            //@ts-ignore
            window.parent.setStream(stream);
          },
          (stream) => {
            if (overflowStreamData?.broadcasterID !== auth.user.id) {
              //@ts-ignore
              window.parent.audioPlayable(stream !== undefined);
              setAudioTrack(stream);
            }
          },
          mediaStream,
          () => {
            //@ts-ignore
            window.parent.screenStopped(overflowStreamData.broadcasterID === auth.user.id, true);
          },
          vidID !== null
        );
      })();
    }
    return () => {
      dailyCoObj.leaveOverflowStream();
    };
  }, [overflowStreamData?.broadcasterID]);
  return (
    <>
      {audioTrack && (
        <ScreenShareAudioFullScreen
          key={'overflow-stream-screenshare-audio'}
          isMuted={false}
          videoStream={audioTrack}
          position={{ x: 0, y: 0 }}
          trackID={'overflow-stream-audio-audio'}
          id={`overflow-stream_audio`}
          isLocal={overflowStreamData?.broadcasterID === auth.user.id}
          setContent={(a, e) => false}
          isScreen={false}
          streamLoading={false}
        />
      )}
    </>
  );
};

export const StreamToOverflow = () => {
  const [roomID, setRoomID] = useState<string | undefined>(undefined);
  const [spaceID, setSpaceID] = useState<string | undefined>(undefined);

  useEffect(() => {
    //@ts-ignore
    setRoomID(window.parent.roomSpace.roomID);
    //@ts-ignore
    setSpaceID(window.parent.roomSpace.spaceID);
  }, []);

  if (roomID && spaceID) {
    return <Connect spaceID={spaceID} roomID={roomID} />;
  }
  return <></>;
};
