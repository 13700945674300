import { getAuthState, setDailyScreenSharingStore } from '../../store';
import { clearOverflowStream, setOverflowStream } from './firebaseRTD';

class OverflowStream {
  timer?: NodeJS.Timeout;
  started?: boolean;
  vidID?: string;
  micID?: string;

  async start(roomID: string, spaceID: string, micID?: string, vidID?: string) {
    this.vidID = vidID;
    this.micID = micID;
    if (!vidID) {
      //@ts-ignore
      window.displayStream = await navigator.mediaDevices.getDisplayMedia({
        audio: { echoCancellation: true, noiseSuppression: true },
        video: true,
      });
    }
    const timeNow = new Date().getTime();
    const timer = () => {
      setOverflowStream(spaceID, roomID, {
        broadcasterID: getAuthState().user.id,
        startedAt: timeNow,
        ping: new Date().getTime(),
      });
      this.started = true;
      setDailyScreenSharingStore(true);
    };
    timer();
    this.timer = setInterval(timer, 2000);
    setDailyScreenSharingStore(true);
  }

  stop(roomID: string, spaceID: string) {
    if (this.timer) {
      clearInterval(this.timer);
      clearOverflowStream(spaceID, roomID);
      this.started = false;
      setDailyScreenSharingStore(false);
      this.vidID = undefined;
      this.micID = undefined;
    }
  }
}

export default new OverflowStream();
