import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReslashLogo from '../../assets/svg/reslashLogo';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getGetModalState } from '../../store/modal';
import { getSpacesRooms } from '../../store/spacesRooms';
import { ModalStyle } from '../modal/modal';
import './scheduledRoomModal.scss';

const ScheduledRoomModal = (props: {
  inApp: boolean;
  isRoomClosed: boolean;
  opensAt?: number;
  endsAt?: number;
  roomName?: string;
  closeBtn?: boolean;
  toClose?: () => void;
}) => {
  const spacesRooms = useSelector(getSpacesRooms);
  let roomName = props.roomName ? props.roomName : spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.roomName;
  const [openTime, setOpenTime] = useState<Date | null>(null);

  if (!roomName) {
    roomName = props.roomName ? props.roomName : spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.roomName;
  }

  const TimerComp = (props: { days: number; hours: number; minutes: number; seconds: number }) => {
    return `${props.days}D : ${props.hours}H : ${props.minutes}M : ${props.seconds}S`;
  };

  useEffect(() => {
    // const from = spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.schedule?.from;
    if (props.opensAt) {
      setOpenTime(new Date(props.opensAt * 1000));
    } else {
      setOpenTime(null);
    }
  }, [props.opensAt]);

  return (
    <>
      <div
        className="scheduled-rooms-container"
        style={
          props.inApp ? { background: 'rgba(57, 59, 65, 0.75)', boxShadow: 'none', border: 'none', margin: '0px' } : {}
        }
      >
        <div className="scheduled-rooms-form-section">
          <div className="scheduled-rooms-form-items">
            <div className="scheduled-rooms-form-header" style={props.inApp ? { color: '#ffffff' } : {}}>
              <div className="scheduled-rooms-form-header-title">
                <span>{roomName ? roomName : 'Room Closed'}</span>
              </div>
              <div className="scheduled-rooms-form-company-logo">
                <ReslashLogo size={68} />
              </div>
              {props.isRoomClosed ||
                (!openTime && (
                  <div className="scheduled-rooms-form-header-subtitle">
                    <span>This room is no longer open to audiences. Please contact the admin to open up access.</span>
                  </div>
                ))}

              {openTime && (
                <div className="scheduled-rooms-form-header-subscripts" style={props.inApp ? { color: '#c4c4c4' } : {}}>
                  <div className="scheduled-rooms-form-header-subscripts-item">
                    <span>ROOM START TIME</span>
                    <div
                      className="scheduled-rooms-form-header-subscripts-item-info"
                      style={props.inApp ? { color: '#ffffff' } : {}}
                    >
                      {DateTime.fromJSDate(openTime).toLocaleString(DateTime.DATETIME_MED)}
                    </div>
                  </div>

                  <div className="scheduled-rooms-form-header-subscripts-item">
                    <span>ROOM STARTS IN</span>
                    <div
                      className="scheduled-rooms-form-header-subscripts-item-info"
                      style={props.inApp ? { color: '#ffffff' } : {}}
                    >
                      {/* 0D : 0H : 3M : 40S */}
                      <Countdown
                        date={openTime.getTime()}
                        renderer={TimerComp}
                        onComplete={() => window.location.reload()}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {props.closeBtn && (
            <div className="scheduled-rooms-form-action">
              <div
                className="scheduled-rooms-form-button"
                style={props.inApp ? { background: '#34E3B9', color: '#000000', marginTop: 32 } : {}}
                onClick={() => {
                  if (props.toClose) props.toClose();
                }}
              >
                <span>Close</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

let roomID = '';
let closeBtn = true;
export const closedRoomModalID = 'room-closed-modal';
export const openClosedRoomModal = (roomID_: string, closeBtn_: boolean) => {
  roomID = roomID_;
  closeBtn = closeBtn_;
  return openModalThunk(closedRoomModalID);
};
export const closeClosedRoomModal = closeModalThunk(closedRoomModalID);

export const RoomClosedModal = () => {
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const state = useSelector(getGetModalState(closedRoomModalID));
  const history = useHistory();
  const ref = useRef<HTMLDivElement | null>(null);
  const spacesRooms = useSelector(getSpacesRooms);
  const till = spacesRooms.rooms[roomID]?.roomDetails?.schedule?.till;
  const isEnded = till && till !== -1 && till < new Date().getTime() / 1000;

  // useOutsideEvent(ref, () => closeClosedRoomModal(), 'scheduled-rooms-container', '.scheduled-rooms-container');

  useEffect(() => {
    newModalThunk(closedRoomModalID, false)();
  }, []);

  useEffect(() => {
    setMsg('');
  }, [state]);

  return (
    <ReactModal
      isOpen={state}
      id="closedRoomModal"
      contentLabel={`${closedRoomModalID} Modal`}
      style={{
        overlay: ModalStyle.overlay,
        content: {
          ...ModalStyle.content,
          padding: '0px',
          borderRadius: '16px',
          border: '1px solid #000000',
          backgroundColor: 'rgba(57, 59, 65, 0.75);',
        },
      }}
      shouldCloseOnOverlayClick={true}
    >
      <div className="scheduled-room-modal" ref={ref}>
        <ScheduledRoomModal
          opensAt={isEnded ? undefined : spacesRooms.rooms[roomID]?.roomDetails?.schedule?.from}
          roomName={spacesRooms.rooms[roomID]?.roomDetails?.roomName}
          inApp={true}
          isRoomClosed={till ? till !== -1 && till > new Date().getTime() / 1000 : false}
          closeBtn={closeBtn}
          toClose={() => {
            closeClosedRoomModal();
          }}
        />
      </div>
    </ReactModal>
  );
};

let roomPermID = '';
export const closedRoomPermModalID = 'room-closed-perm-modal';
export const openClosedRoomPermModal = (roomID_: string) => {
  roomPermID = roomID_;
  return openModalThunk(closedRoomPermModalID);
};
export const closeClosedRoomPermModal = closeModalThunk(closedRoomPermModalID);

export const RoomClosedPermanentModal = () => {
  const state = useSelector(getGetModalState(closedRoomPermModalID));
  const ref = useRef<HTMLDivElement | null>(null);
  const spacesRooms = useSelector(getSpacesRooms);
  const till = spacesRooms.rooms[roomPermID]?.roomDetails?.schedule?.till;
  const isEnded = till && till !== -1 && till < new Date().getTime() / 1000;

  // useOutsideEvent(ref, () => closeClosedRoomModal(), 'scheduled-rooms-container', '.scheduled-rooms-container');

  useEffect(() => {
    newModalThunk(closedRoomPermModalID, false)();
  }, []);

  return (
    <ReactModal
      isOpen={state}
      id="closedRoomPermModal"
      contentLabel={`${closedRoomPermModalID} Modal`}
      style={{
        overlay: ModalStyle.overlay,
        content: {
          ...ModalStyle.content,
          padding: '0px',
          borderRadius: '16px',
          border: '1px solid #000000',
          backgroundColor: 'rgba(57, 59, 65, 0.75);',
        },
      }}
      shouldCloseOnOverlayClick={true}
    >
      <div className="scheduled-room-modal" ref={ref}>
        <ScheduledRoomModal
          opensAt={isEnded ? undefined : spacesRooms.rooms[roomPermID]?.roomDetails?.schedule?.from}
          roomName={spacesRooms.rooms[roomPermID]?.roomDetails?.roomName}
          inApp={true}
          isRoomClosed={till ? till !== -1 && till > new Date().getTime() / 1000 : false}
          closeBtn={false}
          toClose={() => {
            closeClosedRoomPermModal();
          }}
        />
      </div>
    </ReactModal>
  );
};

export default ScheduledRoomModal;
