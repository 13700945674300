import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { getPresenceOfRoom } from '../../store/presence';
import { getSpacesRooms } from '../../store/spacesRooms';

const NumPeopleInRoom = (props: { roomID: string }) => {
  const spacesRooms = useSelector(getSpacesRooms);
  const presenceRoom = useSelector(getPresenceOfRoom(props.roomID));
  if (presenceRoom)
    return (
      <span style={{ fontWeight: 'bold' }}>
        {_.filter(presenceRoom[spacesRooms.currentRoomCopy], { online: true }).length}
      </span>
    );
  return <span style={{ fontWeight: 'bold' }}>0</span>;
};

export default NumPeopleInRoom;
