import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { addNewCanvasChild } from '../../../helper/interactive/firebaseRTD';
import { getSpawnLocation } from '../../../helper/interactive/misc';
import { closeModalThunk, getAuthState, getListenerPosition, newModalThunk, openModalThunk } from '../../../store';
import { getGetModalState } from '../../../store/modal';
import { ModalStyle } from '../../modal/modal';
import { useOutsideEvent } from '../giphy/Giphy';
import '../ytDialog/ytDialog.scss';

const iframeModal = 'iframe-modal';

export enum IFRAME_MODAL_TYPE {
  DEFAULT = 'DEFAULT',
  GOOGLE_APPS = 'GOOGLE_APPS',
}

let type = IFRAME_MODAL_TYPE.DEFAULT;

export const openIframeModal = (t = IFRAME_MODAL_TYPE.DEFAULT) => {
  type = t;
  return openModalThunk(iframeModal)();
};
export const closeIframeModal = closeModalThunk(iframeModal);

function validURL(str: string) {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const addIframe = (
  url: string,
  spaceID: string,
  canvasID: string,
  onCancel: () => void,
  setMsg: (msg: string) => void,
  appName?: string
) => {
  if (validURL(url)) {
    const spawnLocation = getSpawnLocation(getListenerPosition(), 500);
    const auth = getAuthState();
    const app = appName ? appName : '';
    addNewCanvasChild(spaceID, canvasID, {
      w: 1000,
      h: 800,
      x: spawnLocation.x,
      y: spawnLocation.y,
      t: 'iframe',
      d: {
        u: url,
        p: false,
        s: 0,
        st: '',
        uname: auth.user.name,
        uid: auth.user.id,
        f: app,
      },
    }).then(onCancel);
  } else setMsg('Invalid URL.');
};

const IframeLinkBoxModal = (props: { canvasID: string; spaceID: string; toClose: () => void }) => {
  useEffect(() => {
    newModalThunk(iframeModal, false)();
  }, []);

  const isOpen = useSelector(getGetModalState(iframeModal));
  const [url, setURL] = useState('');
  const [msg, setMsg] = useState('');
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideEvent(ref, props.toClose, 'iframe_btn');

  const onCancel = () => {
    setURL('');
    setMsg('');
    closeIframeModal();
  };

  const onSave = () => {
    if (type === IFRAME_MODAL_TYPE.GOOGLE_APPS) {
      addIframe(url, props.spaceID, props.canvasID, onCancel, setMsg, 'Google app');
    } else {
      addIframe(url, props.spaceID, props.canvasID, onCancel, setMsg);
    }
  };

  const getHeader = () => {
    if (type === IFRAME_MODAL_TYPE.GOOGLE_APPS) {
      return 'Add Google App';
    }
    return 'Create a new Web page embed';
  };

  return (
    <ReactModal
      isOpen={isOpen}
      id="linkbox-modal"
      contentLabel={`${iframeModal} Modal`}
      // overlayClassName="hide"
      shouldCloseOnOverlayClick={true}
      style={ModalStyle}
    >
      <div ref={ref} className="">
        <div className="linkbox-modal-header">{getHeader()}</div>
        <div className="content">
          <div className="input-box">
            <div className="label">
              URL <span className="required">*</span>
            </div>
            <input placeholder="Link" value={url} onChange={(e) => setURL(e.target.value)}></input>
            <div className="second-txt">
              This must be a full URL including https:// part. This is in beta. Not all links may be supported.
            </div>
          </div>
        </div>
        <div className="hor-divider"></div>
        <div className="actions">
          <button className="btn cancel" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn" onClick={onSave}>
            Save
          </button>
        </div>
      </div>
    </ReactModal>
  );
};

export default IframeLinkBoxModal;
