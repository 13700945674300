import React, { useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage';

const ImageCropper = ({ getBlob, inputImg, customZoom }: any) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const onCropComplete = async (_: any, croppedAreaPixels: any) => {
    const croppedImage = await getCroppedImg(inputImg, croppedAreaPixels);
    getBlob(croppedImage);
  };

  useEffect(() => {
    setZoom(customZoom);
  }, [customZoom]);

  return (
    <div className="cropper">
      <Cropper
        image={inputImg}
        crop={crop}
        zoom={zoom}
        showGrid={false}
        cropShape="round"
        aspect={1}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
    </div>
  );
};

export default ImageCropper;
