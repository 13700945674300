import '@fortawesome/fontawesome-free/js/all';
import React, { useEffect, useRef, useState } from 'react';
import {
  CameraVideoFill,
  CameraVideoOffFill,
  MegaphoneFill,
  MicFill,
  MicMuteFill,
  PatchPlusFill,
} from 'react-bootstrap-icons';
import { ChevronDown, ChevronUp, LogOut } from 'react-feather';
import { useSelector } from 'react-redux';
import useLongPress from '../../customHooks/longPress';
import { sendAmplitudeData } from '../../helper/amplitude';
import dailyCoObj from '../../helper/interactive/dailyco';
import {
  addNewCanvasChild,
  getMegaphoneOnce,
  setKicked,
  setMicMuted,
  setScreenWall,
  setVideoMuted,
  toggleMegaphone,
  watchKicked,
  watchMegaphoneForOneUser,
  watchMuted,
} from '../../helper/interactive/firebaseRTD';
import { getBrowserDetails, getSpawnLocation, handleSubdomain } from '../../helper/interactive/misc';
import overflowStream from '../../helper/interactive/overflowStream';
import logger from '../../helper/logger';
import { getAuthState, getListenerPosition, setDeviceSetting, setHideMenuStore } from '../../store';
import { getAuth } from '../../store/auth';
import { getBroadcasterFuncRedux, getBroadcasterRedux } from '../../store/broadcast';
import { getCustomClaimFromRedux } from '../../store/customClaim';
import { getDailyJoinedState, getLocalState } from '../../store/dailycoParticipants';
import { getHideMenuState } from '../../store/hideMenu';
import { getSpacesRooms } from '../../store/spacesRooms';
import { getScreenWallRedux } from '../../store/wallpaper';
import ExitFeedbackModal, { openExitFeedbackModal } from '../exitFeedback/exitFeedback';
import FileUpload from '../interactive/fileUploadModal/fileUploadModal';
import Giphy, { useOutsideEvent } from '../interactive/giphy/Giphy';
import IframeLinkBoxModal from '../interactive/iframeLinkBoxModal';
import InsertImage, { closeInsertImage } from '../interactive/insertImage/insertImage';
import { AddPortalModal } from '../interactive/portals/addPortalModal';
import CamAsScreen, { openCamAsScreen } from '../interactive/room/camAsScreen';
import CamAsScreenModal, { openCamAsScreenModal } from '../interactive/room/camAsScreenNew';
import ShareLinkBoxModal from '../interactive/shareLinkBoxModal';
import Unsplash from '../interactive/unsplash/unsplash';
import YTDialog from '../interactive/ytDialog/ytDialog';
import ReactionsBox from '../reactionsModal/reactions';
import Recorder from '../recorder/recorder';
import './callTools.scss';

const BroadcastOnIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
          fill="#1DABCA"
        />
        <path
          d="M8 13C6.67392 13 5.40215 12.4732 4.46447 11.5355C3.52678 10.5979 3 9.32608 3 8C3 6.67392 3.52678 5.40215 4.46447 4.46447C5.40215 3.52678 6.67392 3 8 3C9.32608 3 10.5979 3.52678 11.5355 4.46447C12.4732 5.40215 13 6.67392 13 8C13 9.32608 12.4732 10.5979 11.5355 11.5355C10.5979 12.4732 9.32608 13 8 13ZM8 14C8.78793 14 9.56815 13.8448 10.2961 13.5433C11.0241 13.2417 11.6855 12.7998 12.2426 12.2426C12.7998 11.6855 13.2417 11.0241 13.5433 10.2961C13.8448 9.56815 14 8.78793 14 8C14 7.21207 13.8448 6.43185 13.5433 5.7039C13.2417 4.97595 12.7998 4.31451 12.2426 3.75736C11.6855 3.20021 11.0241 2.75825 10.2961 2.45672C9.56815 2.15519 8.78793 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.88258 13.3679 6.4087 14 8 14Z"
          fill="#EBFF00"
        />
        <path
          d="M8 11C7.20435 11 6.44129 10.6839 5.87868 10.1213C5.31607 9.55871 5 8.79565 5 8C5 7.20435 5.31607 6.44129 5.87868 5.87868C6.44129 5.31607 7.20435 5 8 5C8.79565 5 9.55871 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.79565 10.6839 9.55871 10.1213 10.1213C9.55871 10.6839 8.79565 11 8 11ZM8 12C9.06087 12 10.0783 11.5786 10.8284 10.8284C11.5786 10.0783 12 9.06087 12 8C12 6.93913 11.5786 5.92172 10.8284 5.17157C10.0783 4.42143 9.06087 4 8 4C6.93913 4 5.92172 4.42143 5.17157 5.17157C4.42143 5.92172 4 6.93913 4 8C4 9.06087 4.42143 10.0783 5.17157 10.8284C5.92172 11.5786 6.93913 12 8 12Z"
          fill="#34E3B9"
        />
        <path
          d="M9.5 8C9.5 8.39782 9.34196 8.77936 9.06066 9.06066C8.77936 9.34196 8.39782 9.5 8 9.5C7.60218 9.5 7.22064 9.34196 6.93934 9.06066C6.65804 8.77936 6.5 8.39782 6.5 8C6.5 7.60218 6.65804 7.22064 6.93934 6.93934C7.22064 6.65804 7.60218 6.5 8 6.5C8.39782 6.5 8.77936 6.65804 9.06066 6.93934C9.34196 7.22064 9.5 7.60218 9.5 8Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const CallTools = (props: { canvasID: string; spaceID: string }) => {
  const [giphyVisible, setGiphyVisible] = useState(false);
  const [ytVisible, setYtVisible] = useState(false);
  const [iframeLinkBoxVisible, setIframeLinkBoxVisible] = useState(false);
  const [shareLinkBoxVisible, setShareLinkBoxVisible] = useState(false);
  const [showMoreApps, setShowMoreApps] = useState(false);
  const broadcasterLocal = useSelector(getBroadcasterFuncRedux);
  const customClaim = useSelector(getCustomClaimFromRedux);
  const broadcastDetails = useSelector(getBroadcasterRedux);
  const screenWall = useSelector(getScreenWallRedux);

  // const [isMute, setIsMute] = useState(false);
  // const [isScreen, setIsScreen] = useState(false);
  // const [isVidMute, setIsVidMute] = useState(false);
  const [unsplashVisible, setUnslashVisible] = useState(false);
  const spacesRooms = useSelector(getSpacesRooms);
  const [isScreenLoading, setScreenLoading] = useState(false);
  const [megaphone, isMegaphone] = useState(false);
  const localJitsiParticipant = useSelector(getLocalState);
  const auth = useSelector(getAuth);
  const overflowStreamRunning = useRef(false);
  const [permissionChanged, setPermissionChanged] = useState(false); //To force rerender on permission change.
  const callJoined = useSelector(getDailyJoinedState);

  useEffect(() => {
    //To force rerender on permission change.
    setPermissionChanged(!permissionChanged);
  }, [spacesRooms.rooms[spacesRooms.currentRoom]?.permission, spacesRooms.currentUserRole]);

  useEffect(() => {
    const unsub = watchMegaphoneForOneUser(spacesRooms.currentSpace, spacesRooms.currentRoom, auth.user.id, (flag) => {
      isMegaphone(flag === true);
    });
    return unsub;
  }, [spacesRooms.currentSpace, spacesRooms.currentRoom, auth.user.id]);

  const toggleMute = async () => {
    if (broadcasterLocal.isLocal) {
      broadcasterLocal.toMute(!broadcasterLocal.isMuted);
    } else {
      if (!spacesRooms.permissionV2[spacesRooms.currentUserRole]?.mic) return;
      setDeviceSetting({ micOn: localJitsiParticipant.isMute });
      localJitsiParticipant.muteFunc(!localJitsiParticipant.isMute);
    }
  };

  useEffect(() => {
    const unsub = watchMuted(props.spaceID, props.canvasID, auth.user.id, (muted) => {
      if (muted?.isMuted) {
        logger.info('Muted by host.', {});
        localJitsiParticipant.muteFunc(true);
      }
      if (muted?.isVideoMuted) {
        logger.info('Video muted by host.', {});
        localJitsiParticipant.muteVidFunc(true);
      }
      setMicMuted(auth.user.id, props.spaceID, props.canvasID, false);
      setVideoMuted(auth.user.id, props.spaceID, props.canvasID, false);
    });
    return unsub;
  }, [auth, props.canvasID, props.spaceID, localJitsiParticipant]);

  useEffect(() => {
    const unsub = watchKicked(props.spaceID, props.canvasID, auth.user.id, (flag) => {
      if (flag) {
        const spaceID = spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID;
        logger.info('Kicked out by host.', {});
        setKicked(auth.user.id, props.spaceID, props.canvasID, false).then(() => {
          window.location.href = `${handleSubdomain(`/s/${spaceID}/r/${props.canvasID}`)}/kicked`;
        });
      }
    });
    return unsub;
  }, [auth, props.canvasID, props.spaceID, localJitsiParticipant]);

  const toggleMegaphoneLocal = (megaphone: boolean) => {
    toggleMegaphone(props.spaceID, props.canvasID, getAuthState().user.id, !megaphone);
    isMegaphone(!megaphone);
  };

  useEffect(() => {
    if (customClaim?.roomsV2 && customClaim?.roomsV2[props.canvasID] !== undefined)
      getMegaphoneOnce(props.spaceID, props.canvasID, getAuthState().user.id).then((on) => isMegaphone(on));
  }, [customClaim, props.canvasID, props.spaceID]);

  useEffect(() => {
    if (customClaim?.roomsV2 && customClaim?.roomsV2[props.canvasID] !== undefined) {
      if (!spacesRooms.permissionV2[spacesRooms.currentUserRole]?.cam) {
        localJitsiParticipant.muteVidFunc(true);
      }
      if (!spacesRooms.permissionV2[spacesRooms.currentUserRole]?.mic) {
        localJitsiParticipant.muteFunc(true);
      }
      if (!spacesRooms.permissionV2[spacesRooms.currentUserRole]?.screen) {
        localJitsiParticipant.screenshare(false, '');
      }
      if (!spacesRooms.permissionV2[spacesRooms.currentUserRole]?.megaphone) {
        toggleMegaphone(props.spaceID, props.canvasID, getAuthState().user.id, false);
      }
    }
  }, [
    spacesRooms.permissionV2[spacesRooms.currentUserRole]?.cam,
    spacesRooms.permissionV2[spacesRooms.currentUserRole]?.mic,
    spacesRooms.permissionV2[spacesRooms.currentUserRole]?.screen,
    spacesRooms.permissionV2[spacesRooms.currentUserRole]?.megaphone,
    customClaim,
    callJoined,
  ]);

  const toggleVidMute = async () => {
    if (broadcasterLocal.isLocal) {
      broadcasterLocal.toVidMute(!broadcasterLocal.isVidMuted);
    } else {
      if (!spacesRooms.permissionV2[spacesRooms.currentUserRole]?.cam) return;
      setDeviceSetting({ camOn: localJitsiParticipant.isVidMute });
      localJitsiParticipant.muteVidFunc(!localJitsiParticipant.isVidMute);
    }
  };

  const toggleScreen = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined = undefined,
    longPress = false
  ) => {
    if (isScreenLoading) return;
    if (overflowStream.started) {
      overflowStream.stop(spacesRooms.currentRoom, spacesRooms.currentSpace);
    }
    if ((!localJitsiParticipant.isScreensharing && event?.ctrlKey) || longPress) {
      setScreenLoading(true);
      openCamAsScreen();
      setScreenLoading(false);
      return;
    }
    try {
      setScreenLoading(true);
      try {
        localJitsiParticipant.screenshare(!localJitsiParticipant.isScreensharing, '');
        // }
      } catch {}
      setScreenLoading(false);
    } catch {}
  };

  useEffect(() => {
    if (
      !localJitsiParticipant.isScreensharing &&
      screenWall === localJitsiParticipant.participant?.user_id &&
      localJitsiParticipant.joined
    ) {
      setScreenWall(props.spaceID, props.canvasID, '');
    }
  }, [localJitsiParticipant.isScreensharing, screenWall]);
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 1000,
  };
  const longPressEvent = useLongPress(
    (event) => {
      toggleScreen(event, true);
      sendAmplitudeData('Screen share clicked', {
        state: !localJitsiParticipant.isScreensharing ? 'Enabled' : 'Disabled',
        type: 'cam-as-screen',
        category: 'button click',
        longpress: true,
      });
    },
    (event) => {
      toggleScreen(event, false);
      sendAmplitudeData('Screen share clicked', {
        state: !localJitsiParticipant.isScreensharing ? 'Enabled' : 'Disabled',
        type: 'screen',
        category: 'button click',
        longpress: false,
      });
    },
    defaultOptions
  );

  const addChess = () => {
    const spawnLocation = getSpawnLocation(getListenerPosition(), 500);
    addNewCanvasChild(props.spaceID, props.canvasID, {
      w: 400,
      h: 400,
      x: spawnLocation.x,
      y: spawnLocation.y,
      t: 'gc',
      d: { d: 1, uid: auth.user.id },
    });
  };

  const firstElementOverflow = { borderRadius: '10px 0px 0px 0px' };
  const lastElementOverflow = { borderRadius: '0px 10px 0px 0px' };

  const [userBrowser, setUserBrowser] = useState('');

  useEffect(() => {
    setUserBrowser(getBrowserDetails());
  }, []);
  const [selectScreenType, setSelectScreenType] = useState(false);

  const closeScreenOptions = () => {
    setSelectScreenType(false);
  };

  const screenOptionsRef = useRef<HTMLDivElement | null>(null);
  useOutsideEvent(screenOptionsRef, closeScreenOptions, 'screen-select-dropdown', '#screen-icon-dd-svg');

  const [hideMenu, setHideMenu] = useState(false);

  const hideMenuState = useSelector(getHideMenuState);

  const [showReactions, setShowReactions] = useState(false);

  return (
    <>
      {giphyVisible && (
        <div className="tool-options giphy">
          <Giphy canvasID={props.canvasID} spaceID={props.spaceID} toClose={() => setGiphyVisible(false)} />
        </div>
      )}
      {ytVisible && (
        <div className="tool-options">
          <YTDialog canvasID={props.canvasID} spaceID={props.spaceID} toClose={() => setYtVisible(false)} />
        </div>
      )}
      {unsplashVisible && (
        <div className="tool-options unsplash">
          <Unsplash canvasID={props.canvasID} spaceID={props.spaceID} toClose={() => setUnslashVisible(false)} />
        </div>
      )}
      <IframeLinkBoxModal
        canvasID={props.canvasID}
        spaceID={props.spaceID}
        toClose={() => setIframeLinkBoxVisible(false)}
      />

      <AddPortalModal canvasID={props.canvasID} spaceID={props.spaceID} />

      <InsertImage canvasID={props.canvasID} spaceID={props.spaceID} toClose={() => closeInsertImage()} />
      <ShareLinkBoxModal
        canvasID={props.canvasID}
        spaceID={props.spaceID}
        toClose={() => setShareLinkBoxVisible(false)}
      />
      <FileUpload roomID={props.canvasID} spaceID={props.spaceID} />

      <ExitFeedbackModal />

      <div className="call-tools" style={{ zIndex: broadcasterLocal.isLocal ? 1002 : 100 }}>
        {/* Add a reaction is inside because of absolute alignment*/}
        {showReactions && (
          <ReactionsBox
            onClose={() => {
              setShowReactions(false);
            }}
          />
        )}
        <div
          className="hide-menu-option has-v2-tooltip-top-longg"
          data-tooltip-text={hideMenu ? 'Show All Menus' : 'Hide All Menus'}
          style={hideMenu ? { backgroundColor: '#000000', height: '16px', border: '2px solid #ffffff' } : {}}
          onClick={() => {
            setHideMenu(!hideMenu);
            setHideMenuStore(!hideMenu);
            sendAmplitudeData('Immersive mode clicked', { state: !hideMenu ? 'Enabled' : 'Disabled' });
          }}
        >
          {!hideMenu && <ChevronDown color={'white'} />}
          {hideMenu && <ChevronUp color={'white'} />}
        </div>
        <div className="tool-container" style={hideMenuState ? { display: 'none' } : {}}>
          {isScreenLoading && (
            <div className="tool" style={firstElementOverflow}>
              <div className="loader"></div>
            </div>
          )}
          {spacesRooms.permissionV2[spacesRooms.currentUserRole]?.cam && (
            <div
              className={
                localJitsiParticipant.isVidDisabled || localJitsiParticipant.isVidMute
                  ? 'tool red has-v2-tooltip-top'
                  : 'tool has-v2-tooltip-top'
              }
              id="vidmute_btn"
              style={firstElementOverflow}
              data-tooltip-text="Camera"
              onClick={() => {
                toggleVidMute();
                sendAmplitudeData('Video toggle clicked', {
                  state: !localJitsiParticipant.isVidMute ? 'Disabled' : 'Enabled',
                });
              }}
            >
              {!broadcasterLocal.isLocal ? (
                localJitsiParticipant.isVidDisabled || localJitsiParticipant.isVidMute ? (
                  <CameraVideoOffFill />
                ) : (
                  <CameraVideoFill />
                )
              ) : broadcasterLocal.isVidMuted ? (
                <CameraVideoOffFill />
              ) : (
                <CameraVideoFill />
              )}
            </div>
          )}
          {spacesRooms.permissionV2[spacesRooms.currentUserRole]?.mic && (
            <div
              className={
                localJitsiParticipant.isMicDisabled || localJitsiParticipant.isMute
                  ? 'tool red has-v2-tooltip-top'
                  : 'tool has-v2-tooltip-top'
              }
              id="micmute_btn"
              data-tooltip-text="Mic"
              onClick={() => {
                toggleMute();
                sendAmplitudeData('Audio toggle clicked', {
                  state: !localJitsiParticipant.isMute ? 'Disabled' : 'Enabled',
                });
              }}
            >
              {!broadcasterLocal.isLocal ? (
                localJitsiParticipant.isMicDisabled || localJitsiParticipant.isMute ? (
                  <MicMuteFill />
                ) : (
                  <MicFill />
                )
              ) : broadcasterLocal.isMuted ? (
                <MicMuteFill />
              ) : (
                <MicFill />
              )}
            </div>
          )}
          {!broadcasterLocal.isLocal &&
            !isScreenLoading &&
            spacesRooms.permissionV2[spacesRooms.currentUserRole]?.screen && (
              <div className="tool screenshare_btn" id="screenshare_btn">
                <div className="screenshare-icon" {...longPressEvent}>
                  <span
                    className="material-icons has-v2-tooltip-top"
                    id="screenshare_btn_icon"
                    data-tooltip-text="Screenshare"
                  >
                    {localJitsiParticipant.isScreensharing ? 'stop_screen_share' : 'screen_share'}
                  </span>
                </div>
                {!localJitsiParticipant.isScreensharing && (
                  <div
                    className="screen-select-dropdown"
                    id="screen-select-dropdown"
                    onClick={() => {
                      setSelectScreenType(!selectScreenType);
                    }}
                  >
                    {!selectScreenType && <ChevronDown id="screen-icon-dd-svg" size={12} />}
                    {selectScreenType && <ChevronUp id="screen-icon-dd-svg" size={12} />}
                  </div>
                )}

                {selectScreenType && (
                  <div className="screen-selector-popup">
                    <div className="screen-selector-popup-options-container" ref={screenOptionsRef}>
                      <div
                        className="screen-selector-option"
                        onClick={() => {
                          localJitsiParticipant.screenshare(!localJitsiParticipant.isScreensharing, '');
                          setSelectScreenType(false);
                          sendAmplitudeData('Screen share clicked', {
                            state: !localJitsiParticipant.isScreensharing ? 'Enabled' : 'Disabled',
                            type: 'screen',
                            category: 'dropdown click',
                          });
                        }}
                      >
                        Share screen
                      </div>
                      <div
                        className="screen-selector-option"
                        onClick={() => {
                          openCamAsScreenModal('normal')();
                          setSelectScreenType(false);
                          sendAmplitudeData('Screen share clicked', {
                            state: !localJitsiParticipant.isScreensharing ? 'Enabled' : 'Disabled',
                            type: 'cam-as-screen',
                            category: 'dropdown click',
                          });
                        }}
                      >
                        Share camera as screen
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          {!broadcasterLocal.isLocal && spacesRooms.permissionV2[spacesRooms.currentUserRole]?.gifs && (
            <div
              className={giphyVisible ? 'tool active' : 'tool'}
              id="gif_btn"
              onClick={() => {
                setGiphyVisible(!giphyVisible);
                sendAmplitudeData('Gif toggle clicked', {
                  state: !giphyVisible ? 'Enabled' : 'Disabled',
                });
              }}
            >
              <span
                className="has-v2-tooltip-top-longg material-icons bigger"
                id="gif_btn_icon"
                data-tooltip-text="Gifs and Stickers"
              >
                gif
              </span>
            </div>
          )}

          {!broadcasterLocal.isLocal && spacesRooms.permissionV2[spacesRooms.currentUserRole]?.megaphone && (
            <div
              className={megaphone ? 'tool green has-v2-tooltip-top' : 'tool has-v2-tooltip-top'}
              data-tooltip-text="Megaphone"
              id="megaphone_btn"
              onClick={() => {
                toggleMegaphoneLocal(megaphone);
                sendAmplitudeData('Megaphone toggle clicked', {
                  state: !megaphone ? 'Enabled' : 'Disabled',
                });
              }}
            >
              <div className="svg-container">
                <MegaphoneFill />
              </div>
            </div>
          )}

          {/* {!broadcastDetails.isLive &&
            !broadcasterLocal.isLocal &&
            spacesRooms.spaces[spacesRooms.currentSpace]?.permission?.isOwner && (
              <div
                className="tool has-v2-tooltip-top"
                data-tooltip-text="Announcements"
                id="broadcast_btn"
                onClick={() => {
                  openBroadcastStart();
                  sendAmplitudeData('Broadcast clicked', {});
                }}
              >
                <Bullseye />
              </div>
            )} */}

          <div
            className="tool has-v2-tooltip-top-long"
            data-tooltip-text="Add reactions"
            id="add_reactions_btn"
            onClick={(e) => {
              setShowReactions(!showReactions);
            }}
          >
            <PatchPlusFill color={'#34E3B9'} />
          </div>

          {/* {!broadcasterLocal.isLocal && spacesRooms.permissionV2[spacesRooms.currentUserRole]?.apps && (
            <MoreApps
              openShareLinkModal={openShareLinkModal}
              ytVisible={ytVisible}
              setYtVisible={setYtVisible}
              addChess={addChess}
              spaceID={props.spaceID}
              canvasID={props.canvasID}
            />
          )} */}

          {!broadcasterLocal.isLocal &&
            spacesRooms.permissionV2[spacesRooms.currentUserRole]?.recording &&
            (userBrowser === 'chrome' || userBrowser === 'microsoft edge') && <Recorder />}

          <div
            className="tool has-v2-tooltip-top-long"
            style={lastElementOverflow}
            data-tooltip-text="Exit The Room"
            id="exit_btn"
            onClick={() => {
              sendAmplitudeData('Exit button clicked', {});
              dailyCoObj.leave(true).catch();
              openExitFeedbackModal('exit');
            }}
          >
            <LogOut color="red" />
          </div>
        </div>
      </div>
      <CamAsScreen onScreenSuccess={(flag) => {}} />
      <CamAsScreenModal />
    </>
  );
};

export default CallTools;
