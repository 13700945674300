import _ from 'lodash';
import Quill from 'quill';
import Delta from 'quill-delta';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from '../../../store/auth';
import { dragResizeSlice } from '../../../store/dragResizeState';
import { canvasChild } from '../../../types/canvasFB';
import './quill.snow.css';
import './quillEditor.scss';

const QuillEditor = (props: {
  watcher: (callback: (c: canvasChild) => void) => void;
  onDataChange: (d: any, path: string) => void;
}) => {
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  useEffect(() => {
    const editor = new Quill('#editor', {
      theme: 'snow',
    });
    editor.on('text-change', (d, oldDelta, source) => {
      if (source === 'api') return;
      if (_.isEqual(d, oldDelta)) return;
      const json = { c: JSON.parse(JSON.stringify(editor.getContents())), a: auth.user.id };
      props.onDataChange(json, `qu/c`);
    });
    props.watcher((c) => {
      if (!c.d?.qu?.c?.a) return;
      if (c.d.qu.c.a === auth.user.id) return;
      else {
        editor.setContents(new Delta(c.d.qu.c.c));
      }
    });
  }, []);
  return (
    <div className="editorContainer">
      <div
        id="editor"
        className="quillContainer"
        onFocus={() => {
          dispatch(dragResizeSlice.actions.setDragging(true));
        }}
        onBlur={() => {
          dispatch(dragResizeSlice.actions.setDragging(false));
        }}
      >
        Great power and majesty they still wore, the silent wardens ovanished kingdom.
      </div>
    </div>
  );
};

export default QuillEditor;
