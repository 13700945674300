import React from 'react';

const Cursor = ({ color }: { color: string }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill={color}
    >
      <g>
        <g>
          <path
            d="M434.214,344.448L92.881,3.115c-3.051-3.051-7.616-3.947-11.627-2.304c-3.989,1.643-6.592,5.547-6.592,9.856v490.667
			c0,4.459,2.773,8.448,6.976,10.005c1.195,0.448,2.453,0.661,3.691,0.661c3.051,0,6.037-1.323,8.085-3.733l124.821-145.6h208.427
			c4.309,0,8.213-2.603,9.856-6.592C438.182,352.085,437.265,347.52,434.214,344.448z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default Cursor;
