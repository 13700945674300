import React from 'react';
import ReslashLogo from '../../assets/svg/reslashLogo';
import './loaderPage.scss';

const LoaderPage = () => {
  return (
    <div className="loaderCont">
      <div className="branding-detail">
        <div className="brand-logo">
          <ReslashLogo size={68} />
        </div>
        <span className="brand-name">Reslash</span>
      </div>
      <div className="loaderPageMain">
        <div className="ring">
          <p>Loading</p>
        </div>
      </div>
    </div>
  );
};

export default LoaderPage;
