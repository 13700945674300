import axios, { AxiosResponse } from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'querystring';
import { config } from '../../config';
import logger from '../logger';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

export const headers = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};

class Space {
  defaultRooms: {
    [spaceID: string]: AxiosResponse<any>;
  };
  constructor() {
    this.defaultRooms = {};
  }

  async create(name: string, token: string, ID: string) {
    try {
      logger.info('createdNewSpace_v2 called:', { name, ID });
      const response = await axios.post(
        `${config.firebaseCloudURL}/createdNewSpace_v2`,
        qs.stringify({
          name,
          token,
          spaceID: ID,
          isLocked: 'open',
          guestAllowed: 'guests',
          password: '',
          teamDomain: '',
        }),
        headers
      );
      logger.info('createdNewSpace_v2 response:', { result: response.data });
      return response;
    } catch (e) {
      logger.warn('createdNewSpace_v2 error:', { error: e?.response?.data });
      throw e;
    }
  }

  async useLink(inviteLink: string, token: string, spaceID: string) {
    try {
      logger.info('useSpaceInviteLink_v2 called:', { inviteLink, spaceID });
      const response = await axios.post(
        `${config.firebaseCloudURL}/useSpaceInviteLink_v2`,
        qs.stringify({ token, spaceID, inviteLink }),
        headers
      );
      logger.info('useSpaceInviteLink_v2 result:', { result: response.data });
      return response;
    } catch (e) {
      logger.warn('useSpaceInviteLink_v2 error:', { error: e?.response?.data });
      throw e;
    }
  }

  async updatePermission(
    token: string,
    permission: 'owner' | 'teammember' | 'guest',
    spaceID: string,
    userID: string,
    isDeleted: boolean
  ) {
    //updateMemberPermissionSpace_v2
    try {
      logger.info('updateMemberPermissionSpace_v2 called:', { permission, spaceID, userID, isDeleted });
      const response = await axios.post(
        `${config.firebaseCloudURL}/updateMemberPermissionSpace_v2`,
        qs.stringify({ token, spaceID, permission, userID, isDeleted: isDeleted ? 'deleted' : '' }),
        headers
      );
      logger.info('updateMemberPermissionSpace_v2 response:', { result: response.data });
      return response;
    } catch (e) {
      logger.info('updateMemberPermissionSpace_v2 error:', { error: e?.response?.data });
      throw e;
    }
  }

  async useGuestLink(spaceID: string, token: string) {
    try {
      logger.info('useGuestLink_v2 called:', { spaceID });
      const response = await axios.post(
        `${config.firebaseCloudURL}/useGuestLink_v2`,
        qs.stringify({ token, spaceID }),
        headers
      );
      logger.info('useGuestLink_v2 response:', { result: response.data });
      return response;
    } catch (error) {
      logger.warn('useGuestLink_v2 error:', { error: error?.response?.data });
      throw error;
    }
  }

  async getID(spaceID: string, token: string) {
    try {
      logger.info('getSpaceID_v2 called:', { spaceID });
      const response = await axios.post(
        `${config.firebaseCloudURL}/getSpaceID_v2`,
        qs.stringify({ token, spaceID }),
        headers
      );
      logger.info('getSpaceID_v2 response:', { result: response.data });
      return response;
    } catch (error) {
      logger.info('getSpaceID_v2 error:', { error: error?.response?.data });
      throw error;
    }
  }

  async getIDFromSubdomain(spaceID: string, token: string) {
    try {
      logger.info('getSpaceIDfromSubdomain_v2 called:', { spaceID });
      const response = await axios.post(
        `${config.firebaseCloudURL}/getSpaceIDfromSubdomain_v2`,
        qs.stringify({ token, spaceID }),
        headers
      );
      logger.info('getSpaceIDfromSubdomain_v2 response:', { result: response.data });
      return response;
    } catch (e) {
      logger.info('getSpaceIDfromSubdomain_v2 error: ', { error: e?.response?.data });
      throw e;
    }
  }

  async setDefaultRoom(spaceID: string, token: string, roomID: string) {
    try {
      logger.info('setDefaultRoom called', { spaceID, roomID });
      const response = await axios.post(
        `${config.firebaseCloudURL}/setDefaultRoom_v2`,
        qs.stringify({ token, spaceID, roomID }),
        headers
      );
      logger.info('setDefaultRoom response:', { result: response.data });
      return response;
    } catch (error) {
      logger.warn('setDefaultRoom Error:', { error: error?.response?.data });
      throw error;
    }
  }

  async getDefaultRoom(spaceID: string, token: string) {
    if (!this.defaultRooms[spaceID]) {
      try {
        logger.info('getDefaultRoom_v2 called:', { spaceID });
        const dr = await axios.post(
          `${config.firebaseCloudURL}/getDefaultRoom_v2`,
          qs.stringify({ token, spaceID }),
          headers
        );
        this.defaultRooms[spaceID] = dr;
        logger.info('getDefaultRoom_v2 response:', { result: dr.data });
      } catch (error) {
        logger.warn('getDefaultRoom_v2 error: ', { error: error?.response?.data });
        throw error;
      }
    }
    return this.defaultRooms[spaceID];
  }

  async getSpaceName(spaceID: string, token: string) {
    try {
      logger.info('getSpaceName called:', { spaceID });
      const response = await axios.post(
        `${config.firebaseCloudURL}/getSpaceName`,
        qs.stringify({ token, spaceID }),
        headers
      );
      logger.info('getSpaceName response:', { result: response.data });
      return response;
    } catch (e) {
      logger.warn('getSpaceName error:', { error: e?.response?.data });
      throw e;
    }
  }

  async updateSpaceName(spaceID: string, token: string, spaceName: string) {
    try {
      logger.info('setSpaceName called:', { spaceID, spaceName });
      const response = await axios.post(
        `${config.firebaseCloudURL}/setSpaceName`,
        qs.stringify({ token, spaceID, spaceName }),
        headers
      );
      logger.info('setSpaceName response:', { result: response.data });
      return response;
    } catch (e) {
      logger.warn('setSpaceName error:', { error: e?.response?.data });
      throw e;
    }
  }

  async setSpaceCustomClaim(spaceID: string, token: string) {
    try {
      logger.info('setSpaceCustomClaimV2 called:', { spaceID });
      const response = await axios.post(
        `${config.firebaseCloudURL}/setSpaceCustomClaim_v2`,
        qs.stringify({ token, spaceID }),
        headers
      );
      logger.info('setSpaceCustomClaimV2 response:', { result: response.data });
      return response;
    } catch (e) {
      logger.warn('setSpaceCustomClaimV2 error:', { error: e?.response?.data });
      throw e;
    }
  }

  async getSpaceInviteLinks(spaceID: string, token: string) {
    try {
      logger.info('getSpaceInviteLinks_v2 called:', { spaceID });
      const response = await axios.post(
        `${config.firebaseCloudURL}/getSpaceInviteLinks_v2`,
        qs.stringify({ token, spaceID }),
        headers
      );
      logger.info('getSpaceInviteLinks_v2 response:', { result: response.data });
      return response;
    } catch (e) {
      logger.warn('getSpaceInviteLinks_v2 error:', { error: e?.response?.data });
      throw e;
    }
  }

  async jumpstart(token: string, spaceID: string, roomID: string, subdomain?: boolean) {
    try {
      logger.info('jumpstart called:', { spaceID, roomID });
      return await axios.post(
        `${config.firebaseCloudURL}/jumpstart_v2`,
        qs.stringify({ token, spaceID, roomID, type: subdomain ? 'subdomain' : 'normal' }),
        headers
      );
    } catch (e) {
      logger.warn('jumpstart failed', { error: e?.resposne?.data });
      throw e;
    }
  }
}

export default new Space();
