import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { presenceOnDeleteListener } from '../../helper/interactive/firebaseRTD';
import { getPresence } from '../../helper/interactive/presence';
import { getPresenceOfRoomStore, updatePresenceOfRoomStore, updatePresenceOfUsersRoomStore } from '../../store';
import { presenceRoom, presenceUser } from '../../store/presence';
import { getSpacesRooms } from '../../store/spacesRooms';

const comparePresence = (oldPresence: undefined | presenceUser, newPresence: presenceUser) => {
  return (
    oldPresence &&
    oldPresence.email === newPresence.email &&
    oldPresence.name === newPresence.name &&
    oldPresence.online === newPresence.online &&
    oldPresence.profilePic === newPresence.profilePic &&
    oldPresence.permission?.permission?.isAdmin === newPresence.permission?.permission?.isAdmin &&
    oldPresence.permission?.permission?.isTeamMember === newPresence.permission?.permission?.isTeamMember &&
    oldPresence.permission?.permission?.isGuest === newPresence.permission?.permission?.isGuest &&
    oldPresence.permission?.role === newPresence.permission?.role &&
    oldPresence.isRecording === newPresence.isRecording &&
    oldPresence?.desc === newPresence?.desc &&
    oldPresence?.status === newPresence?.status &&
    oldPresence?.socialProfiles?.twitter === newPresence?.socialProfiles?.twitter &&
    oldPresence?.socialProfiles?.facebook === newPresence?.socialProfiles?.facebook &&
    oldPresence?.socialProfiles?.linkedin === newPresence?.socialProfiles?.linkedin &&
    oldPresence?.socialProfiles?.email === newPresence?.socialProfiles?.email &&
    oldPresence?.socialProfiles?.phone === newPresence?.socialProfiles?.phone &&
    oldPresence?.socialProfiles?.link === newPresence?.socialProfiles?.link &&
    oldPresence?.reaction?.sentTime === newPresence?.reaction?.sentTime &&
    oldPresence?.reaction?.type === newPresence?.reaction?.type
  );
};

const PresenceV2 = () => {
  const spacesRooms = useSelector(getSpacesRooms);

  useEffect(() => {
    const unsubs: (() => void)[] = [];
    for (let key in spacesRooms.rooms) {
      const roomID = spacesRooms.rooms[key]?.roomID ?? '';
      unsubs.push(
        presenceOnDeleteListener(spacesRooms.currentSpace, roomID, (deletedUID, roomCopy) => {
          updatePresenceOfUsersRoomStore(roomID, deletedUID, roomCopy, false);
        })
      );
      unsubs.push(
        getPresence(spacesRooms.currentSpace, roomID, (val: null | presenceRoom) => {
          if (val) {
            const currentPresence = getPresenceOfRoomStore(roomID);
            const roomPresence: { [roomCopy: string]: presenceRoom } = {};
            let anyChangedFlag = false;
            let noOne = true;
            _.map(val, (online, uid) => {
              if (!online.online) return;
              noOne = false;
              const timestamp = new Date().getTime();
              const roomCopy = online.roomCopy ? online.roomCopy : 0;
              if (
                (!online.version || online.version !== 'v2') &&
                online.online &&
                timestamp - online.timestamp > 400000
              ) {
                online.online = false;
              }
              if (
                currentPresence &&
                (!currentPresence[roomCopy] || !comparePresence(currentPresence[roomCopy][uid], online))
              )
                anyChangedFlag = true;
              if (!currentPresence) anyChangedFlag = true;
              if (currentPresence && currentPresence[roomCopy] && currentPresence[roomCopy][uid]?.color) {
                online.color = currentPresence[roomCopy][uid]?.color;
              } else {
                online.color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
              }
              if (!roomPresence[roomCopy]) roomPresence[roomCopy] = {};
              roomPresence[roomCopy][uid] = online;
            });
            if (anyChangedFlag) updatePresenceOfRoomStore(roomID, roomPresence);
            if (noOne) updatePresenceOfRoomStore(roomID, {});
            for (let rc in currentPresence) {
              for (let uid in currentPresence[rc])
                if (!val[uid] || !val[uid]?.online) updatePresenceOfUsersRoomStore(roomID, uid, rc, false);
            }
          } else {
            updatePresenceOfRoomStore(roomID, {});
          }
        })
      );
    }
    return () => {
      for (let i in unsubs) {
        unsubs[i]();
      }
    };
  }, [spacesRooms.rooms]);

  return <></>;
};

export default PresenceV2;
