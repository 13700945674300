import { configureStore, createSlice } from '@reduxjs/toolkit';

type audioZoneParticipants = {
  [userID: string]: {
    addedBy: string;
    exited: boolean;
    position: number;
    addedOn: number;
  };
};

export type audioZone = {
  participants: audioZoneParticipants;
  position: {
    x: number;
    y: number;
  };
  size: {
    w: 0;
    h: 0;
  };
  createdBy: string;
};

export type audioZones = {
  audioZones: { [zoneID: string]: audioZone };
  myZone: { zone: audioZone; zoneID: string } | null;
  userZone: { [userID: string]: { zone: audioZone; zoneID: string } | null };
};

const initialState: audioZones = {
  myZone: null,
  audioZones: {},
  userZone: {},
};

export const audioZoneSlice = createSlice({
  name: 'audioZones',
  initialState,
  reducers: {
    setAudioZones(state, action) {
      state.audioZones = action.payload;
    },
    setMyZone(state, action) {
      state.myZone = {
        zone: action.payload.zone,
        zoneID: action.payload.id,
      };
    },
    setUserZone(state, action) {
      state.userZone[action.payload.uid] = action.payload.zid;
    },
  },
});

export const audioZoneStore = configureStore({
  reducer: audioZoneSlice.reducer,
  devTools: true,
});

export const getAudioZones = (globalState: { audioZones: ReturnType<typeof audioZoneStore.getState> }) =>
  globalState.audioZones;

export const getAudioZone = (zid: string) => (globalState: {
  audioZones: ReturnType<typeof audioZoneStore.getState>;
}) => globalState.audioZones.audioZones[zid];

export const getUserAudioZones = (globalState: { audioZones: ReturnType<typeof audioZoneStore.getState> }) => {
  return globalState.audioZones.myZone;
};

export const getOtherUserAudioZoneID = (uid: string) => (globalState: {
  audioZones: ReturnType<typeof audioZoneStore.getState>;
}) => globalState.audioZones.userZone[uid];
