import firebase from 'firebase';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { sendAmplitudeData } from '../../helper/amplitude';
import {
  saveBubbleSize,
  saveOnePermissionV2,
  saveRoomPermissionForObjectsV2,
  saveSpatialState,
  saveVideoAlwaysOnState,
} from '../../helper/interactive/firebaseRTD';
import { getBrowserDetails } from '../../helper/interactive/misc';
import { getPresenceOfRoom } from '../../store/presence';
import { getSpacesRooms } from '../../store/spacesRooms';
import DropDown from '../dropdown/dropdown';
import { useOutsideEvent } from '../interactive/giphy/Giphy';
import ToggleButtonV2 from '../toggleButton/toggleButtonV2';
import { getDefaultPermissions } from './permissionV2';
import './roomPermissions.scss';

let database = firebase.database();

const RoomPermissions = (props: { onClose: () => void }) => {
  const [enableNotifications, setNotifications] = useState(false);
  const [enableSpatialAudio, setSpatialAudio] = useState(true);
  const [audienceBubbleLock, setAudienceBubbleLock] = useState(false);
  const spacesRooms = useSelector(getSpacesRooms);
  const [bubbleSize, setBubbleSize] = useState(128);
  const presence = useSelector(getPresenceOfRoom(spacesRooms.currentRoom));
  const [numPeopleOnline, setNumPeopleOnline] = useState(0);
  const [spatialRadius, setSpatialRadius] = useState('Small');

  const [toggles, setToggles] = useState<{ [name: string]: boolean }>({});
  const [loading, setLoading] = useState(true);

  const roomPermRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const audiencePermissionsRef = database.ref(
      `space/${spacesRooms.currentSpace}/room/${spacesRooms.currentRoom}/permissions/a`
    );
    audiencePermissionsRef.on('value', (val) => {
      setLoading(true);
      const permission = val.val();
      const permissions = getDefaultPermissions(permission, permission, 'a');
      setToggles((toggles) => {
        toggles['obj'] = permissions?.apps !== undefined ? permissions.apps : false;
        toggles['gifs'] = permissions?.gifs !== undefined ? permissions.gifs : false;
        toggles['cam'] = permissions?.cam !== undefined ? permissions.cam : false;
        toggles['mic'] = permissions?.mic !== undefined ? permissions.mic : false;
        toggles['screen'] = permissions?.screen !== undefined ? permissions.screen : false;
        toggles['megaphone'] = permissions?.megaphone !== undefined ? permissions.megaphone : false;
        toggles['recording'] = permissions?.recording !== undefined ? permissions.recording : false;
        toggles['moveself'] = permissions?.moveself !== undefined ? permissions.moveself : true;
        toggles['openroom'] = permissions?.openroom !== undefined ? permissions.openroom : false;
        toggles['lockroom'] = permissions?.lockroom !== undefined ? permissions.lockroom : false;
        return toggles;
      });
      setLoading(false);
    });
  }, [spacesRooms.currentRoom, spacesRooms.currentSpace]);

  useEffect(() => {
    setBubbleSize(spacesRooms.currentRoomPermission.size);
    setSpatialAudio(spacesRooms.currentRoomPermission.spatial);
  }, [spacesRooms.currentRoomPermission]);

  useEffect(() => {
    const numPeopleOnline = _.filter(presence ? presence[spacesRooms.currentRoomCopy] : {}, { online: true }).length;
    setNumPeopleOnline(numPeopleOnline);
    if (numPeopleOnline > 50) {
      setBubbleSize(128);
      saveBubbleSize(spacesRooms.currentSpace, spacesRooms.currentRoom, 128);
    }
    if (numPeopleOnline > 20) {
      setSpatialAudio(true);
      saveSpatialState(spacesRooms.currentSpace, spacesRooms.currentRoom, true);
    }
  }, [presence, spacesRooms.currentRoom, spacesRooms.currentRoomCopy]);

  const sizes: { [size: number]: string } = {
    128: 'Small',
    184: 'Medium',
    256: 'Large',
  };

  const dropDownStyle = {
    listOuter: {
      paddingTop: '4px',
      paddingBottom: '4px',
      backgroundColor: '#393B41',
      borderRadius: '8px',
      width: 'auto',
    },
    listItems: {
      paddingLeft: '6px',
      paddingBottom: '2px',
      paddingRight: '6px',
    },
    dropDownHeader: {
      transform: 'translateX(2px)',
      fontSize: 12,
    },
  };

  useOutsideEvent(roomPermRef, props.onClose, 'room-name-container', '#room-name-container');

  const [userBrowser, setUserBrowser] = useState('');

  useEffect(() => {
    setUserBrowser(getBrowserDetails());
  }, []);

  return (
    <>
      <div className="room-permissions-container" ref={roomPermRef}>
        <div className="room-permissions-header">PERMISSIONS</div>
        <div className="room-permission-items-container">
          <div className="room-permission-item">
            {/* <div className="permission-top">
              <div className="permission-top-left"> Notifications </div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={enableNotifications}
                  name={'notificationsToggle'}
                  onChange={() => {
                    setNotifications(!enableNotifications);
                  }}
                />
              </div>
            </div> */}
            <div className="permission-desc"></div>
          </div>

          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left"> Bubble size </div>
              <div className="permission-top-right">
                <DropDown
                  headerTitle={sizes[bubbleSize]}
                  styles={dropDownStyle}
                  showStatusColor={false}
                  optionsListType="array"
                  options={sizes}
                  isItemDict={true}
                  arrowSize={12}
                  onSelectItem={(e: any, key: any) => {
                    if (numPeopleOnline <= 50) {
                      setBubbleSize(key);
                      saveBubbleSize(spacesRooms.currentSpace, spacesRooms.currentRoom, key);
                      sendAmplitudeData('Room permissions changed', { type: 'bubble size', size: sizes[bubbleSize] });
                    } else {
                      setBubbleSize(128);
                      sendAmplitudeData('Room permissions changed', { type: 'bubble size', size: 128 });
                    }
                  }}
                />
              </div>
            </div>

            <div className="permission-desc">If you've 50+ people, bubbles will automatically switch to small </div>
          </div>

          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left"> Spatial audio </div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={enableSpatialAudio}
                  name={'spatialAudioToggle'}
                  onChange={() => {
                    if (numPeopleOnline <= 20) {
                      saveSpatialState(spacesRooms.currentSpace, spacesRooms.currentRoom, !enableSpatialAudio);
                      setSpatialAudio(!enableSpatialAudio);
                      sendAmplitudeData('Room permissions changed', {
                        type: 'spatial audio',
                        state: !enableSpatialAudio ? 'Enabled' : 'Disabled',
                        category: 'less than 20  members',
                      });
                    } else {
                      setSpatialAudio(true);
                      sendAmplitudeData('Room permissions changed', {
                        type: 'spatial audio',
                        state: 'Enabled',
                        category: 'more than 20  members',
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="permission-desc">Spatial cannot be turned off if you’ve 20+ people in the room </div>
          </div>
          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left"> Spatial video </div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={
                    spacesRooms.currentRoomPermission.vidOnPerm !== undefined
                      ? !spacesRooms.currentRoomPermission.vidOnPerm
                      : true
                  }
                  name={'videoAlwaysOn'}
                  onChange={() => {
                    if (numPeopleOnline <= 110) {
                      saveVideoAlwaysOnState(
                        spacesRooms.currentSpace,
                        spacesRooms.currentRoom,
                        !spacesRooms.currentRoomPermission.vidOnPerm
                      );
                      sendAmplitudeData('Room permissions changed', {
                        type: 'spatial video',
                        state: !spacesRooms.currentRoomPermission.vidOnPerm ? 'Enabled' : 'Disabled',
                        category: 'less than 50  members',
                      });
                    } else {
                      saveVideoAlwaysOnState(spacesRooms.currentSpace, spacesRooms.currentRoom, false);
                      sendAmplitudeData('Room permissions changed', {
                        type: 'spatial video',
                        state: 'Enabled',
                        category: 'more than 50  members',
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="permission-desc">Spatial video cannot be turned off if you’ve 30+ people in the room</div>
          </div>

          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left">Bubble lock</div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={!toggles['moveself']}
                  name={'audienceBubbleLockToggle'}
                  onChange={(e) => {
                    setToggles((toggles) => {
                      toggles['moveself'] = e;
                      return toggles;
                    });
                    saveOnePermissionV2(spacesRooms.currentSpace, spacesRooms.currentRoom, !e, 'moveself');
                    sendAmplitudeData('Room permissions changed', {
                      type: 'bubble lock',
                      state: !toggles['moveself'] ? 'Enabled' : 'Disabled',
                    });
                  }}
                />
              </div>
            </div>

            <div className="permission-desc">Audience will not be able to move their bubbles</div>
          </div>
          {/*
{// Lock room toggle }
          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left">Lock room</div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={toggles['lockroom']}
                  name={'lockRoomToggle'}
                  onChange={(e) => {
                    setToggles((toggles) => {
                      toggles['lockroom'] = e;
                      return toggles;
                    });
                    saveOnePermissionV2(spacesRooms.currentSpace, spacesRooms.currentRoom, e, 'lockroom');
                    sendAmplitudeData('Room permissions changed', {
                      type: 'Lock room',
                      state: toggles['lockroom'] ? 'Enabled' : 'Disabled',
                    });
                  }}
                />
              </div>
            </div>

            <div className="permission-desc">Only people who are online when you lock the room have access to it</div>
          </div>

{// Open room toggle }

          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left">Open room</div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={toggles['openroom']}
                  name={'openRoomToggle'}
                  onChange={(e) => {
                    setToggles((toggles) => {
                      toggles['openroom'] = e;
                      return toggles;
                    });
                    saveOnePermissionV2(spacesRooms.currentSpace, spacesRooms.currentRoom, e, 'openroom');
                    sendAmplitudeData('Room permissions changed', {
                      type: 'Open room',
                      state: toggles['openroom'] ? 'Enabled' : 'Disabled',
                    });
                  }}
                />
              </div>
            </div>

            <div className="permission-desc">When you close the room, the audience would be kicked out</div>
          </div>
                */}

          {(userBrowser === 'chrome' || userBrowser === 'microsoft edge') && (
            <div className="room-permission-item recording-perm">
              <div className="permission-top">
                <div className="permission-top-left"> Recording </div>
                <div className="permission-top-right">
                  <ToggleButtonV2
                    val={toggles['recording']}
                    name={'recordingToggle'}
                    onChange={(e) => {
                      setToggles((toggles) => {
                        toggles['recording'] = e;
                        return toggles;
                      });
                      saveOnePermissionV2(spacesRooms.currentSpace, spacesRooms.currentRoom, e, 'recording');
                      sendAmplitudeData('Room permissions changed', {
                        type: 'recording ',
                        state: toggles['recording'] ? 'Enabled' : 'Disabled',
                      });
                    }}
                  />
                </div>
              </div>

              <div className="permission-desc">
                Give permission to use local recording by all (* Chrome, Edge support only).
              </div>
            </div>
          )}
          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left"> Apps </div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={toggles['obj']}
                  name={'objToggle'}
                  onChange={(e) => {
                    setToggles((toggles) => {
                      toggles['obj'] = e;
                      return toggles;
                    });
                    saveRoomPermissionForObjectsV2(spacesRooms.currentSpace, spacesRooms.currentRoom, e);
                    sendAmplitudeData('Room permissions changed', {
                      type: 'apps ',
                      state: toggles['obj'] ? 'Enabled' : 'Disabled',
                    });
                  }}
                />
              </div>
            </div>

            {/* <div className="permission-desc">Give permission to add apps to audience. </div> */}
          </div>

          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left"> Gifs </div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={toggles['gif']}
                  name={'gifToggle'}
                  onChange={(e) => {
                    setToggles((toggles) => {
                      toggles['gifs'] = e;
                      return toggles;
                    });
                    saveOnePermissionV2(spacesRooms.currentSpace, spacesRooms.currentRoom, e, 'gifs');
                    sendAmplitudeData('Room permissions changed', {
                      type: 'gifs ',
                      state: toggles['gifs'] ? 'Enabled' : 'Disabled',
                    });
                  }}
                />
              </div>
            </div>

            {/* <div className="permission-desc">Give permission to add gifs to audience. </div> */}
          </div>

          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left"> Camera </div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={toggles['cam']}
                  name={'camToggle'}
                  onChange={(e) => {
                    setToggles((toggles) => {
                      toggles['cam'] = e;
                      return toggles;
                    });
                    saveOnePermissionV2(spacesRooms.currentSpace, spacesRooms.currentRoom, e, 'cam');
                    sendAmplitudeData('Room permissions changed', {
                      type: 'cam ',
                      state: toggles['cam'] ? 'Enabled' : 'Disabled',
                    });
                  }}
                />
              </div>
            </div>

            {/* <div className="permission-desc">Let audience turn on their camera. </div> */}
          </div>

          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left"> Mic </div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={toggles['mic']}
                  name={'micToggle'}
                  onChange={(e) => {
                    setToggles((toggles) => {
                      toggles['mic'] = e;
                      return toggles;
                    });
                    saveOnePermissionV2(spacesRooms.currentSpace, spacesRooms.currentRoom, e, 'mic');
                    sendAmplitudeData('Room permissions changed', {
                      type: 'mic ',
                      state: toggles['mic'] ? 'Enabled' : 'Disabled',
                    });
                  }}
                />
              </div>
            </div>

            {/* <div className="permission-desc">Let audience turn on their microphone. </div> */}
          </div>

          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left"> Screen </div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={toggles['screen']}
                  name={'screenToggle'}
                  onChange={(e) => {
                    setToggles((toggles) => {
                      toggles['screen'] = e;
                      return toggles;
                    });
                    saveOnePermissionV2(spacesRooms.currentSpace, spacesRooms.currentRoom, e, 'screen');
                    sendAmplitudeData('Room permissions changed', {
                      type: 'screen ',
                      state: toggles['screen'] ? 'Enabled' : 'Disabled',
                    });
                  }}
                />
              </div>
            </div>

            {/* <div className="permission-desc">Let audience share their screen. </div> */}
          </div>

          <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left"> Megaphone </div>
              <div className="permission-top-right">
                <ToggleButtonV2
                  val={toggles['megaphone']}
                  name={'megaphoneToggle'}
                  onChange={(e) => {
                    setToggles((toggles) => {
                      toggles['megaphone'] = e;
                      return toggles;
                    });
                    saveOnePermissionV2(spacesRooms.currentSpace, spacesRooms.currentRoom, e, 'megaphone');
                    sendAmplitudeData('Room permissions changed', {
                      type: 'megaphone ',
                      state: toggles['megaphone'] ? 'Enabled' : 'Disabled',
                    });
                  }}
                />
              </div>
            </div>

            {/* <div className="permission-desc">Let audience use the megaphone. </div> */}
          </div>

          {/* <div className="room-permission-item">
            <div className="permission-top">
              <div className="permission-top-left"> Spatial radius </div>
              <div className="permission-top-right">
                {' '}
                <DropDown
                  headerTitle={spatialRadius}
                  styles={dropDownStyle}
                  showStatusColor={false}
                  optionsListType="array"
                  options={['Small', 'Medium', 'Large']}
                  arrowSize={12}
                  onSelectItem={(e: any) => {
                    setSpatialRadius(e.header);
                  }}
                />{' '}
              </div>
            </div>

            <div className="permission-desc"></div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default RoomPermissions;
