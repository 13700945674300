import React, { useRef, useState } from 'react';
import {
  ChatDotsFill,
  CompassFill,
  Discord,
  LayersFill,
  PlusCircleFill,
  QuestionCircleFill,
} from 'react-bootstrap-icons';
import { Package } from 'react-feather';
// import { tawkto } from '../../config';
// import { tawkTo } from '../../helper/interactive/tawk';
import { useSelector } from 'react-redux';
import tawk from '../../helper/requests/tawk';
import { getAuth } from '../../store/auth';
import { openExitFeedbackModal } from '../exitFeedback/exitFeedback';
import { useOutsideEvent } from '../interactive/giphy/Giphy';
import { closeOnboarding, openOnboarding } from '../productOnboarding/productOnboarding';
import './helpdesk.scss';

const Helpdesk = (props: { onClose: () => void }) => {
  const [startOnboarding, setStartOnboarding] = useState(false);
  const auth = useSelector(getAuth);
  const helpdeskRef = useRef<HTMLDivElement | null>(null);

  const handleOnboarding = () => {
    localStorage.removeItem('onboarding');
    closeOnboarding();
    openOnboarding();
  };

  // const tawkToPropertyId = tawkto.propertyId;
  // const tawkToKey = tawkto.widgetKey;
  // useEffect(() => {
  //   tawkTo(tawkToPropertyId, tawkToKey);
  // }, []);

  useOutsideEvent(helpdeskRef, props.onClose, 'helpdesk-menu-container', '#helpdesk-menu-container');

  return (
    <>
      <div className="helpdesk-container" ref={helpdeskRef}>
        <div className="helpdesk-header">
          <span>HELP</span>
        </div>

        <div className="helpdesk-items-container">
          {/* Item 1 - Chat support */}
          <div
            className="helpdesk-item"
            onClick={async () => {
              try {
                // (window as any).Tawk_API.visitor = {
                //   name: auth.userDetails.name,
                //   email: auth.userDetails.email,
                // };
                const tawk_API = (window as any).Tawk_API;
                try {
                  const hash = (await tawk.getHash(auth.userDetails.email)).data.hash;
                  tawk_API.setAttributes({
                    name: auth.userDetails.name,
                    email: auth.userDetails.email,
                    hash,
                  });
                } catch (e) {
                  console.error(e);
                }
                (window as any).Tawk_API.toggle();
                props.onClose();
              } catch (error) {}
              //   setTawkAttributes();
            }}
          >
            <div className="helpdesk-item-icon">
              <ChatDotsFill size={18} />
            </div>

            <div className="helpdesk-item-content">
              <div className="helpdesk-item-content-title">
                <span>Chat with us</span>
              </div>
              <div className="helpdesk-item-content-desc">
                <span>Ask us anything </span>
              </div>
            </div>
          </div>

          {/* Item 2 - FAQs */}
          <div
            className="helpdesk-item"
            onClick={() => {
              window.open('https://reslash.tawk.help/', '_blank');
              props.onClose();
            }}
          >
            <div className="helpdesk-item-icon">
              <QuestionCircleFill size={18} />
            </div>

            <div className="helpdesk-item-content">
              <div className="helpdesk-item-content-title">
                <span>FAQs</span>
              </div>
              <div className="helpdesk-item-content-desc">
                <span>Get answers quickly </span>
              </div>
            </div>
          </div>

          {/* Item 3 - Suggest a feature */}
          <div
            className="helpdesk-item"
            onClick={() => {
              window.open('https://feedback.reslash.co/', '_blank');
              props.onClose();
            }}
          >
            <div className="helpdesk-item-icon">
              <Package size={18} />
            </div>

            <div className="helpdesk-item-content">
              <div className="helpdesk-item-content-title">
                <span>Feature request</span>
              </div>
              <div className="helpdesk-item-content-desc">
                <span>Suggest a feature</span>
              </div>
            </div>
          </div>

          {/* Item 4 - Roadmap */}
          <div
            className="helpdesk-item"
            onClick={() => {
              window.open(
                'https://www.notion.so/01fccd946ccd46b8bfdac4f7ae218584?v=61ecf0b483f4444eac12dbd6437ab5a6',
                '_blank'
              );
              props.onClose();
            }}
          >
            <div className="helpdesk-item-icon">
              <CompassFill size={18} />
            </div>

            <div className="helpdesk-item-content">
              <div className="helpdesk-item-content-title">
                <span>Roadmap</span>
              </div>
              <div className="helpdesk-item-content-desc">
                <span>See what's ahead</span>
              </div>
            </div>
          </div>

          {/* Item 5 - Discord */}
          <div
            className="helpdesk-item"
            onClick={() => {
              window.open('https://discord.gg/aH5UdF7ynR', '_blank');
              props.onClose();
            }}
          >
            <div className="helpdesk-item-icon">
              <Discord size={18} />
            </div>

            <div className="helpdesk-item-content">
              <div className="helpdesk-item-content-title">
                <span>Discord</span>
              </div>
              <div className="helpdesk-item-content-desc">
                <span>Join our community</span>
              </div>
            </div>
          </div>

          {/* Item 6 - Onboarding */}
          <div
            className="helpdesk-item"
            onClick={() => {
              handleOnboarding();
              props.onClose();
            }}
          >
            <div className="helpdesk-item-icon">
              <LayersFill size={18} />
            </div>

            <div className="helpdesk-item-content">
              <div className="helpdesk-item-content-title">
                <span>Onboarding</span>
              </div>
              <div className="helpdesk-item-content-desc">
                <span>Go through it again </span>
              </div>
            </div>
          </div>

          {/* Item 7 - Feedback */}
          <div
            className="helpdesk-item"
            onClick={() => {
              openExitFeedbackModal('feedback');
              props.onClose();
            }}
          >
            <div className="helpdesk-item-icon">
              <PlusCircleFill size={18} />
            </div>

            <div className="helpdesk-item-content">
              <div className="helpdesk-item-content-title">
                <span>Feedback</span>
              </div>
              <div className="helpdesk-item-content-desc">
                <span>Rate your experience</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Helpdesk;
