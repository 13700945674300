type logType = 'track' | 'debug' | 'info' | 'warn' | 'error' | 'fatal';
// type messageType = {
//   type: 'log';
//   log: {
//     type: logType;
//     payload: any;
//   };
// };

class Logger {
  // ws: ReconnectingWebSocket;
  name: string;
  email: string;
  uid: string;
  space: string;
  room: string;
  roomCopy: number;
  dailySessionID: string;
  isGuest?: boolean;

  constructor() {
    // this.ws = new ReconnectingWebSocket(loggerEndpoint);
    this.name = '';
    this.email = '';
    this.uid = '';
    this.space = '';
    this.room = '';
    this.roomCopy = 0;
    this.dailySessionID = '';
    this.isGuest = undefined;
  }

  setUserInfo(name: string, email: string, uid: string, isGuest: boolean) {
    this.name = name;
    this.email = email;
    this.uid = uid;
    this.isGuest = isGuest;
    this.sendLog('info', 'User changed.', { name, email, uid, isGuest });
  }

  setDailySessionID(dailySessionID: string) {
    this.dailySessionID = dailySessionID;
    this.sendLog('info', 'Daily sessionID:', { dailySessionID });
  }

  setSpace(space: string) {
    this.space = space;
    this.sendLog('info', 'Space changed.', { space });
  }

  setRoom(room: string) {
    this.room = room;
    this.sendLog('info', 'Room changed.', { room });
  }

  setRoomCopy(roomCopy: number) {
    this.roomCopy = roomCopy;
    this.sendLog('info', 'RoomCopy changed.', { roomCopy });
  }

  async sendLog(type: logType, message: string, payload: any) {
    // if  (config.logging.console) console.log('Logger:', type, message, payload);
    // const inIframe = window.inIframe ? window.inIframe() : false;
    // if (this.ws.readyState === this.ws.OPEN)
    //   try {
    //     if (config.logging.cloud)
    //       this.ws.send(
    //         JSON.stringify({
    //           type: 'log',
    //           log: {
    //             type,
    //             payload: {
    //               ...payload,
    //               timestamp: new Date().getTime(),
    //               message: message,
    //               env: config.env,
    //               space: this.space,
    //               room: this.room,
    //               roomCopy: this.roomCopy,
    //               userDetails: { email: this.email, name: this.name, uid: this.uid, isGuest: this.isGuest },
    //               dailyInfo: { sessionID: this.dailySessionID },
    //               url: window.location.href,
    //               referrerURL: document.referrer,
    //               isReslashLoadedInIframe: inIframe,
    //             },
    //           },
    //         })
    //       );
    //   } catch (e) {
    //     console.error('Logger:', e);
    //   }
    // else {
    //   console.warn('Logger: socket is connecting.');
    // }
  }

  track(message: string, payload: any) {
    this.sendLog('track', message, payload);
  }

  debug(message: string, payload: any) {
    this.sendLog('debug', message, payload);
  }

  info(message: string, payload: any) {
    this.sendLog('info', message, payload);
  }

  warn(message: string, payload: any) {
    this.sendLog('warn', message, payload);
  }

  error(message: string, payload: any) {
    this.sendLog('error', message, payload);
  }

  fatal(message: string, payload: any) {
    this.sendLog('fatal', message, payload);
  }
}

export default new Logger();
