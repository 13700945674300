//createTokenDailyco

import axios from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'querystring';
import { config } from '../../config';
import { headers } from './spaces';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

export function appsumoCreateAccount(id: string, name: string, password: string, spaceName: string, spaceID: string) {
  return axios.post(
    `${config.firebaseCloudURL}/registerAppsumo`,
    qs.stringify({ id, name, password, spaceName, spaceID }),
    headers
  );
}

export function getBillingID(spaceID: string, token: string, subdomain: boolean) {
  return axios.post(
    `${config.firebaseCloudURL}/getBillingID`,
    qs.stringify({ spaceID, token, subdomain: subdomain ? 'subdomain' : '' }),
    headers
  );
}

export function getInvoiceItem(spaceID: string, token: string, subdomain: boolean) {
  return axios.post(
    `${config.firebaseCloudURL}/getInvoiceItem`,
    qs.stringify({ spaceID, token, subdomain: subdomain ? 'subdomain' : '' }),
    headers
  );
}
