import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Video from '../../components/interactive/video/video';
import dailyCoObj from '../../helper/interactive/dailyco';
import { setBroadcastPing, watchBroadcast } from '../../helper/interactive/firebaseRTD';
import { findGetParameter } from '../../helper/interactive/misc';
import { getAuth } from '../../store/auth';
import { getBroadcasterFuncRedux, getBroadcasterStreamRedux } from '../../store/broadcast';

const spaceID = findGetParameter('spaceID');
const token = findGetParameter('token');
const videoSource = findGetParameter('videoSource');
const audioSource = findGetParameter('audioSource');

export const BroadcastPage = () => {
  const auth = useSelector(getAuth);
  const broadcasterLocal = useSelector(getBroadcasterFuncRedux);
  const broadcastDetails = useSelector(getBroadcasterStreamRedux);

  (window as any).muteVid = (flag: boolean) => {
    broadcasterLocal.toVidMute(flag);
  };
  (window as any).muteAud = (flag: boolean) => {
    broadcasterLocal.toMute(flag);
  };

  useEffect(() => {
    if (broadcastDetails?.video) (window.parent as any).setBroadcasterVideo(new MediaStream([broadcastDetails?.video]));
    else (window.parent as any).setBroadcasterVideo(null);
    if (broadcastDetails?.audio) (window.parent as any).setBroadcasterAudio(new MediaStream([broadcastDetails?.audio]));
    else (window.parent as any).setBroadcasterAudio(null);
  }, [broadcastDetails]);

  const [broadcastInfo, setBroadcastInfo] = useState<{
    uid: string;
    name: string;
    profilePic: string;
    listeners: number;
    isLive: boolean;
    ping: number;
  } | null>(null);
  useEffect(() => {
    let unsub = () => {};
    if (spaceID) {
      console.log(spaceID);
      unsub = watchBroadcast(spaceID, (broadcastInfoRTD) => {
        setBroadcastInfo(broadcastInfoRTD);
      });
    }
    return unsub;
  }, [auth.isLoggedIn]);

  useEffect(() => {
    let timeInterval: NodeJS.Timeout;
    if (!auth.isLoggedIn) return;
    if (broadcastInfo && broadcastInfo.isLive && spaceID && token) {
      console.log(broadcastInfo, broadcastInfo);
      setBroadcastPing(spaceID, new Date().getTime());
      if (broadcastInfo.uid === auth.user.id) {
        timeInterval = setInterval(() => {
          setBroadcastPing(spaceID, new Date().getTime());
        }, 5000);
      }
      const isBroadcaster = auth.user.id === broadcastInfo.uid;
      dailyCoObj.joinBroadcastIframe(
        isBroadcaster && videoSource !== 'false' ? videoSource : false,
        isBroadcaster && audioSource !== 'false' ? audioSource : false,
        spaceID.slice(0, 25),
        token,
        broadcastInfo.uid,
        () => {
          (window.parent as any).onLeave();
        },
        (flag: boolean) => {
          (window.parent as any).onMute(flag);
        },
        (flag: boolean) => {
          (window.parent as any).onVidMute(flag);
        },
        (uid, flag) => {
          (window.parent as any).onParticipantUpdate(uid, flag);
        }
      );
    }
    return () => clearInterval(timeInterval);
  }, [broadcastInfo?.isLive, auth.user]);
  return (
    <>
      {broadcastDetails?.audio ? (
        <Video
          srcObject={new MediaStream([broadcastDetails.audio])}
          id={'broadcast-iframe-audio'}
          isAudio={true}
          isMuted={auth.user.id === broadcastInfo?.uid}
        />
      ) : (
        <></>
      )}{' '}
    </>
  );
};
