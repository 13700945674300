import { configureStore, createSlice } from '@reduxjs/toolkit';

export type screen = {
  position: {
    x: number;
    y: number;
  };
  shape: {
    w: number;
    h: number;
  };
  sessionID: string;
  playable: boolean;
  loading: boolean;
  paused: boolean;
};

export type canvasDims = {
  position: {
    x: number;
    y: number;
  };
  shape: {
    w: number;
    h: number;
  };
};

let initialState: {
  screens: { [id: string]: screen };
  canvas: canvasDims;
} = {
  screens: {},
  canvas: {
    position: {
      x: 0,
      y: 0,
    },
    shape: {
      w: 0,
      h: 0,
    },
  },
};

export const screenshareSlice = createSlice({
  name: 'screenshare',
  initialState,
  reducers: {
    addScreen(state, action) {
      state.screens[action.payload.id] = {
        position: {
          x: 0,
          y: 0,
        },
        sessionID: action.payload.sessionID,
        shape: {
          w: 0,
          h: 0,
        },
        paused: false,
        playable: true,
        loading: false,
      };
    },
    updateScreen(state, action) {
      state.screens[action.payload.id] = {
        position: action.payload.position,
        sessionID: action.payload.sessionID,
        shape: action.payload.shape,
        paused: action.payload.paused,
        playable: true,
        loading: false,
      };
    },
    removeScreen(state, action) {
      if (state.screens[action.payload.id]) delete state.screens[action.payload.id];
    },
    setLoading(state, action) {
      if (state.screens[action.payload.id]) state.screens[action.payload.id].loading = action.payload.flag;
    },
    updateCanvas(state, action) {
      state.canvas = action.payload.canvas;
    },
  },
});

export const screenshareStore = configureStore({
  reducer: screenshareSlice.reducer,
});

export const getScreenshareStore = (state: { screenshare: ReturnType<typeof screenshareStore.getState> }) =>
  state.screenshare;
