export const OnboardingSteps = [
  {
    step: 1,
    stepColor: '#FFAA00',
    title: 'TALK BY MOVING CLOSER, JUST LIKE REAL LIFE',
    media: {
      type: 'video',
      url: `/onboarding/assets/spatial-new-low.mp4`,
      thumbnail: `/onboarding/assets/spatial-new-low.png`,
    },
    description: 'Drag your bubble closer to talk or away to leave',
    action: 'Next',
  },

  {
    step: 2,
    stepColor: '#0088FF',
    title: 'ZOOM IN TO SEE PEOPLE MORE CLEARLY',
    media: {
      type: 'video',
      url: '/onboarding/assets/zoom-new-low.mp4',
      thumbnail: '/onboarding/assets/zoom-new-low.png',
    },
    description: 'Use the +/- icons or either scroll or pinch',
    action: 'Next',
  },

  {
    step: 3,
    stepColor: '#FF7700',
    title: 'COLLABORATE. PLAY. MAKE IT YOUR OWN.',
    media: {
      type: 'video',
      url: '/onboarding/assets/control-bar.mp4',
      thumbnail: '/onboarding/assets/control-bar.png',
    },
    description: 'Add apps, whiteboards, games, screenshares. Turn it into an office, classroom or an 80s bar ',
    action: 'Next',
  },

  {
    step: 4,
    stepColor: '#AADD22',
    title: 'USE THE SIDEBAR TO NAVIGATE',
    media: {
      type: 'video',
      url: '/onboarding/assets/sidebar-onboarding-low.mp4',
      thumbnail: '/onboarding/assets/sidebar-onboarding-low.png',
    },
    description: 'Switch rooms, chat with people, change settings - all in a single place',
    action: 'Next',
  },

  {
    step: 5,
    stepColor: '#EA4C89',
    title: 'INVITE TEAM TO GET STARTED',
    media: {
      type: 'image',
      url: '/onboarding/assets/invite-link-onboarding.jpg',
    },
    description: 'Invite at least 3 people to get things going',
    action: 'Next',
  },

  {
    step: 6,
    stepColor: '#FFFFFF',
    title: 'THANK YOU FOR CHOOSING RESLASH',
    media: {
      type: 'embed',
      url: 'https://www.youtube.com/embed/OO1zj1YT5k4?list=PLJ49ztoDfoFhwBki2RNJUE0-pUzTDLNN4',
    },
    description: 'Here’s a demo video to help you get started',
    action: 'Done',
  },
];
