import { configureStore, createSlice } from '@reduxjs/toolkit';

const initialState: {
  sidebarOpen: boolean;
  roomSettingsOpen: boolean;
  permissionOpen: boolean;
  membersOpen: boolean;
  highlightRoom: string;
  roomDetailsOpen: boolean;
  roomWallOpen: boolean;
  roomCreationOrEdit: 'create' | 'edit' | 'create-dynamic';
  chatOpen: boolean;
  backgroundMenuOpen?: boolean;
} = {
  sidebarOpen: false,
  roomSettingsOpen: false,
  permissionOpen: true,
  membersOpen: true,
  roomDetailsOpen: false,
  roomWallOpen: false,
  highlightRoom: '',
  roomCreationOrEdit: 'create',
  chatOpen: false,
  backgroundMenuOpen: false,
};
export const settingsMenuSlice = createSlice({
  name: 'settingsMenu',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      state.sidebarOpen = action.payload;
    },
    toggleRoomSettings: (state, action) => {
      state.roomSettingsOpen = action.payload;
    },
    togglePermissions: (state, action) => {
      state.permissionOpen = action.payload;
    },
    toggleMembers: (state, action) => {
      state.membersOpen = action.payload;
    },
    setHighLightRoom: (state, action) => {
      state.highlightRoom = action.payload;
    },
    setRoomCreationOrEdit: (state, action) => {
      state.roomCreationOrEdit = action.payload;
    },
    toggleRoomDetailsMenu: (state, action) => {
      state.roomDetailsOpen = action.payload;
    },
    toggleRoomWallMenu: (state, action) => {
      state.roomWallOpen = action.payload;
    },
    toggleChat: (state, action) => {
      state.chatOpen = action.payload;
    },
    toggleBackground: (state, action) => {
      state.backgroundMenuOpen = action.payload;
    },
  },
});

export const settingsMenuStore = configureStore({
  reducer: settingsMenuSlice.reducer,
  devTools: true,
});

export const getSidebarMenuStateRedux = (globalState: {
  settingsMenu: ReturnType<typeof settingsMenuStore.getState>;
}) => globalState.settingsMenu.sidebarOpen;
export const getRoomSettingsOpenStateRedux = (globalState: {
  settingsMenu: ReturnType<typeof settingsMenuStore.getState>;
}) => globalState.settingsMenu.roomSettingsOpen;
export const getPermissionMembersOpenStateRedux = (globalState: {
  settingsMenu: ReturnType<typeof settingsMenuStore.getState>;
}) => {
  return { permissionOpen: globalState.settingsMenu.permissionOpen, membersOpen: globalState.settingsMenu.membersOpen };
};
export const getHighlightedRoomStateRedix = (globalState: {
  settingsMenu: ReturnType<typeof settingsMenuStore.getState>;
}) => globalState.settingsMenu.highlightRoom;

export const getRoomCreateOrEditStateRedux = (globalState: {
  settingsMenu: ReturnType<typeof settingsMenuStore.getState>;
}) => globalState.settingsMenu.roomCreationOrEdit;

export const getRoomEditStateRedux = (globalState: { settingsMenu: ReturnType<typeof settingsMenuStore.getState> }) => {
  return {
    details: globalState.settingsMenu.roomDetailsOpen,
    wall: globalState.settingsMenu.roomWallOpen,
  };
};

export const getChatOpenStateRedux = (globalState: { settingsMenu: ReturnType<typeof settingsMenuStore.getState> }) => {
  if (!globalState.settingsMenu.sidebarOpen) return false;
  else return globalState.settingsMenu.chatOpen;
};
export const getBackgroundMenuOpenStateRedux = (globalState: {
  settingsMenu: ReturnType<typeof settingsMenuStore.getState>;
}) => {
  if (!globalState.settingsMenu.sidebarOpen) return false;
  else return globalState.settingsMenu.backgroundMenuOpen;
};
export const getSidebarMenuStatusRedux = (globalState: {
  settingsMenu: ReturnType<typeof settingsMenuStore.getState>;
}) => globalState.settingsMenu;
