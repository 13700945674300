import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { inactivityTimeout } from '../../config';
import dailyCoObj from '../../helper/interactive/dailyco';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getLastActivityStampRedux } from '../../store/inactivity';
import { getGetModalState } from '../../store/modal';
import { getPresenceOfRoom } from '../../store/presence';
import { getCurrentRoomRedux, getSpacesRooms } from '../../store/spacesRooms';
import { ModalStyle } from '../modal/modal';
import './inactivity.scss';

export const inactivityID = 'inactivity-id';
export const openInactivityModal = openModalThunk(inactivityID);
export const closeInactivityModal = closeModalThunk(inactivityID);

const InactivityModal = () => {
  const inactivityState = useSelector(getGetModalState(inactivityID));
  const [timer, setTimer] = useState(inactivityTimeout.countdown);
  const lastClickTimestamp = useSelector(getLastActivityStampRedux);
  const currentRoom = useSelector(getCurrentRoomRedux);
  const presence = useSelector(getPresenceOfRoom(currentRoom));
  const spacesRooms = useSelector(getSpacesRooms);

  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    const peopleInRoom = _.filter(presence ? presence[spacesRooms.currentRoomCopy] : {}, { online: true }).length;
    if (peopleInRoom === 1)
      timeOut = setTimeout(() => {
        openInactivityModal();
      }, inactivityTimeout.inactivityTime * 1000);
    return () => {
      clearTimeout(timeOut);
    };
  }, [lastClickTimestamp, presence]);

  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (inactivityState) {
      countdown = setInterval(() => {
        setTimer((timer) => {
          if (timer === 1) {
            dailyCoObj.leave(true).catch();
            openKickedInactivityModal();
            clearInterval(countdown);
          }
          return timer - 1;
        });
      }, 1000);
    } else {
      setTimer(inactivityTimeout.countdown);
    }
    return () => clearInterval(countdown);
  }, [inactivityState]);

  const timerDisplay = (timer: number) => {
    const min = Math.floor(timer / 60);
    const sec = Math.floor(timer % 60);
    if (sec < 10) {
      return `0${min}:0${sec}`;
    }
    return `0${min}:${sec}`;
  };
  useEffect(() => {
    newModalThunk(inactivityID, false)();
  }, []);
  return (
    <ReactModal id={inactivityID} className={inactivityID} isOpen={inactivityState} style={ModalStyle}>
      <>
        <div className="body">
          <div className="title">You seem to be inactive</div>
          We’ll disconnect you in the next 5 minutes, unless you click on the button below.
        </div>
        <div className="footer">
          <div className="buttons">
            <div className="timer">{timerDisplay(timer)}</div>
            <div
              className="takemeback"
              onClick={() => {
                closeInactivityModal();
              }}
            >
              Take me back
            </div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export const kickedInactivityID = 'inactivity-kicked-id';
export const openKickedInactivityModal = openModalThunk(kickedInactivityID);
export const closeKickedInactivityModal = closeModalThunk(kickedInactivityID);

export const KickedInactivityModal = () => {
  const kickedState = useSelector(getGetModalState(kickedInactivityID));
  useEffect(() => {
    newModalThunk(kickedInactivityID, false)();
  }, []);
  return (
    <ReactModal id={kickedInactivityID} className={kickedInactivityID} isOpen={kickedState} style={ModalStyle}>
      <>
        <div className="body">
          <div className="title">You have been disconnected because of inactivity</div>
        </div>
        <div className="footer">
          <div className="buttons">
            <div
              className="takemeback"
              onClick={() => {
                closeKickedInactivityModal();
                window.location.reload();
              }}
            >
              Enter room again
            </div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default InactivityModal;
