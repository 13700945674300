import axios from 'axios';
import axiosRetry from 'axios-retry';
import logger from '../logger';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

export async function getIPAddress() {
  try {
    const response = await axios.get(`https://api.ipify.org/?format=json`);
    return response.data.ip;
  } catch (e) {
    logger.warn('Failed to fetch IP:', { error: e?.response?.data });
    throw e;
  }
}
