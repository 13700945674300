import { configureStore, createSlice } from '@reduxjs/toolkit';
import { userRoleType } from './spacesRooms';

export type presenceUser = {
  name: string;
  online: boolean;
  version?: string;
  userID?: string;
  profilePic: string;
  email: string;
  timestamp: number;
  color: string;
  eventID?: string;
  roomCopy?: number;
  permission: {
    permission: {
      isAdmin: boolean;
      isTeamMember: boolean;
      isGuest: boolean;
    };
    role: userRoleType;
  } | null;
  isRecording: boolean;
  desc?: string;
  status?: string;
  socialProfiles?:
    | {
        twitter: string | undefined;
        facebook: string | undefined;
        linkedin: string | undefined;
        phone: string | undefined;
        email: string | undefined;
        link: string | undefined;
      }
    | undefined;
  reaction?: { type: 'text' | 'sticker'; payload: { text?: string; url?: string }; sentTime?: number } | undefined;
};
export type presenceRoom = {
  [uid: string]: presenceUser;
};
export type presenceType = {
  [roomID: string]: {
    [roomCopy: string]: presenceRoom;
  };
};
const initialState: presenceType = {};
export const presenceSlice = createSlice({
  name: 'presence',
  initialState: initialState,
  reducers: {
    updateRoomPresence(state, action) {
      state[action.payload.roomID] = action.payload.presence;
    },
    updateUsersRoomPresence(state, action) {
      if (
        state[action.payload.roomID] &&
        state[action.payload.roomID][action.payload.roomCopy] &&
        state[action.payload.roomID][action.payload.roomCopy][action.payload.userID]
      )
        state[action.payload.roomID][action.payload.roomCopy][action.payload.userID].online = action.payload.flag;
    },
  },
});
export const presenceStore = configureStore({
  reducer: presenceSlice.reducer,
  devTools: true,
});
export const getPresenceOfRoom = (rid: string) => (globalState: {
  presence: ReturnType<typeof presenceStore.getState>;
}) => globalState.presence[rid];
export const getPresenceAll = (globalState: { presence: ReturnType<typeof presenceStore.getState> }) =>
  globalState.presence;
export const getUserPresence = (roomID: string, roomCopy: string, userID: string) => (globalState: {
  presence: ReturnType<typeof presenceStore.getState>;
}) => {
  if (
    globalState.presence &&
    globalState.presence[roomID] &&
    globalState.presence[roomID][roomCopy] &&
    globalState.presence[roomID][roomCopy][userID]
  )
    return globalState.presence[roomID][roomCopy][userID];
  return null;
};
