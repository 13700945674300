import _ from 'lodash';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useOutsideEvent } from '../../components/interactive/giphy/Giphy';
import { openAddPortalModal } from '../../components/interactive/portals/addPortalModal';
import { openCamAsScreenModal } from '../../components/interactive/room/camAsScreenNew';
import { addNewCanvasChild } from '../../helper/interactive/firebaseRTD';
import { getSpawnLocation } from '../../helper/interactive/misc';
import {
  getListenerPosition,
  setRoomCreateOrEditStore,
  setZonesVisibleStore,
  toggleBackgroundMenuOpenStore,
  toggleChatOpenStore,
  toggleMembersMenuStore,
  togglePermissionMenuStore,
  toggleRoomDetailsMenuStore,
  toggleRoomSettingsMenuStore,
  toggleRoomWallMenuStore,
  toggleSidebarStore,
} from '../../store';
import { getAuth } from '../../store/auth';
import { getDailyJoinedState, getLocalState } from '../../store/dailycoParticipants';
import { getRestrictedZonesRedux, getZonesVisibleRedux } from '../../store/restrictedZones';
import { getSidebarMenuStateRedux, getSidebarMenuStatusRedux } from '../../store/settingsMenu';
import { getSpacesRooms } from '../../store/spacesRooms';
import './dropDown.scss';

const CustomiseDropDown = (props: { canvasID: string; spaceID: string; toClose: () => void }) => {
  const spacesRooms = useSelector(getSpacesRooms);
  const isAdmin = spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin;
  const customizeRef = useRef<HTMLDivElement | null>(null);
  const auth = useSelector(getAuth);
  const noGoZones = useSelector(getRestrictedZonesRedux);
  const zonesVisible = useSelector(getZonesVisibleRedux);
  const localJitsiParticipant = useSelector(getLocalState);
  const isJoined = useSelector(getDailyJoinedState);
  const menuState = useSelector(getSidebarMenuStateRedux);
  const menuStatus = useSelector(getSidebarMenuStatusRedux);

  const addNoGoZone = (spaceID: string, canvasID: string) => {
    const spawnLocation = getSpawnLocation(getListenerPosition(), 500);
    addNewCanvasChild(spaceID, canvasID, {
      w: 400,
      h: 400,
      x: spawnLocation.x,
      y: spawnLocation.y,
      t: 'nogo',
      d: { d: 1, uid: auth.user.id },
    });
  };

  useOutsideEvent(customizeRef, props.toClose, 'customizeOpenContainer', '#customizeOpenContainer');

  return (
    <div className="dropdown customise" ref={customizeRef}>
      {isAdmin && (
        <div
          className="option"
          onClick={() => {
            toggleSidebarStore(true);
            toggleRoomSettingsMenuStore(true);
            toggleChatOpenStore(false);
            togglePermissionMenuStore(false);
            toggleMembersMenuStore(false);
            toggleRoomDetailsMenuStore(false);
            toggleRoomWallMenuStore(true);
            setRoomCreateOrEditStore('edit');
            toggleBackgroundMenuOpenStore(false);
            props.toClose();
          }}
        >
          Change room settings
        </div>
      )}
      {isAdmin && (
        <div
          className="option"
          onClick={() => {
            toggleSidebarStore(true);
            toggleRoomSettingsMenuStore(false);
            toggleChatOpenStore(false);
            togglePermissionMenuStore(false);
            toggleMembersMenuStore(false);
            toggleRoomDetailsMenuStore(false);
            toggleRoomWallMenuStore(false);
            setRoomCreateOrEditStore('edit');
            toggleBackgroundMenuOpenStore(true);
            props.toClose();
          }}
        >
          Change background
        </div>
      )}

      {isAdmin && !localJitsiParticipant.isScreensharing && (
        <div
          className="option"
          onClick={() => {
            localJitsiParticipant.screenshare(!localJitsiParticipant.isScreensharing, '', undefined, true);
            props.toClose();
          }}
        >
          Share screen as room background
        </div>
      )}

      {isAdmin && !localJitsiParticipant.isScreensharing && (
        <div
          className="option"
          onClick={() => {
            openCamAsScreenModal('fullscreen')();
            props.toClose();
          }}
        >
          Share camera as room background
        </div>
      )}

      {isAdmin && (
        <div
          className="option"
          onClick={() => {
            openAddPortalModal();
            props.toClose();
          }}
        >
          Add a portal to room
        </div>
      )}

      {isAdmin && (
        <div
          className="option"
          onClick={() => {
            addNoGoZone(props.spaceID, props.canvasID);
            props.toClose();
          }}
        >
          Add a no-go zone
        </div>
      )}
      {isAdmin && _.keys(noGoZones).length > 0 && (
        <div
          className="option"
          onClick={() => {
            setZonesVisibleStore(!zonesVisible);
            props.toClose();
          }}
        >
          {zonesVisible ? 'Hide no-go zones' : 'Show no-go zones'}
        </div>
      )}
    </div>
  );
};

export default CustomiseDropDown;
