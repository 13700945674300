import { addMinutes, formatISO, roundToNearestMinutes } from 'date-fns';

class AssertionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AssertionError';
  }
}

function assert(value: any, message: string) {
  if (!value) {
    throw new AssertionError(message);
  }
}

function objectToGetParams(object: { [key: string]: string | number | undefined | null }) {
  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join('&')}` : '';
}

export function facebookLink(url: string, { quote, hashtag }: { quote?: string; hashtag?: string }) {
  assert(url, 'facebook.url');
  return (
    'https://www.facebook.com/sharer/sharer.php' +
    objectToGetParams({
      u: url,
      quote,
      hashtag,
    })
  );
}

export function linkedinLink(url: string) {
  assert(url, 'linkedin.url');

  return 'https://linkedin.com/shareArticle' + objectToGetParams({ url });
}

export function twitterLink(
  url: string,
  { title, via, hashtags = [], related = [] }: { title?: string; via?: string; hashtags?: string[]; related?: string[] }
) {
  assert(url, 'twitter.url');
  assert(Array.isArray(hashtags), 'twitter.hashtags is not an array');
  assert(Array.isArray(related), 'twitter.related is not an array');

  return (
    'https://twitter.com/share' +
    objectToGetParams({
      url,
      text: title,
      via,
      hashtags: hashtags.length > 0 ? hashtags.join(',') : undefined,
      related: related.length > 0 ? related.join(',') : undefined,
    })
  );
}

export function emailLink(
  url: string,
  { subject, body, separator, footer }: { body?: string; separator?: string; subject?: string; footer?: string }
) {
  return 'mailto:' + objectToGetParams({ subject, body: body ? body + separator + url + footer : url });
}

const formatDate = (date: Date, type: string) => {
  let formattedDate = '';
  if (date) {
    if (type === 'google') {
      formattedDate = formatISO(roundToNearestMinutes(addMinutes(date, 16), { nearestTo: 30 }), { format: 'basic' });
    } else if (type === 'outlook') {
      formattedDate = formatISO(roundToNearestMinutes(addMinutes(date, 16), { nearestTo: 30 }));
    }
  }
  return formattedDate;
};

export function googleCalendarLink(
  url: string,
  { title, description, location }: { title?: string; description?: string; location?: string }
) {
  assert(url, 'googleCalendar.url');

  const currentDate = new Date();

  const start = formatDate(currentDate, 'google');
  const end = formatDate(addMinutes(currentDate, 30), 'google');

  return (
    'https://calendar.google.com/calendar/render' +
    objectToGetParams({
      action: 'TEMPLATE',
      text: title,
      location: location,
      details: description,
      trp: 'true',
      dates: start + '/' + end,
    })
  );
}

export function outlookCalendarLink(
  url: string,
  { title, description, location }: { title?: string; description?: string; location?: string }
) {
  assert(url, 'outlook.url');

  const currentDate = new Date();

  const start = formatDate(currentDate, 'outlook');
  const end = formatDate(addMinutes(currentDate, 30), 'outlook');

  return (
    'https://outlook.live.com/calendar/0/deeplink/compose' +
    objectToGetParams({
      path: '/calendar/action/compose',
      rru: 'addevent',
      startdt: start,
      enddt: end,
      subject: title,
      body: description,
      location: location,
    })
  );
}

export function office365CalendarLink(
  url: string,
  { title, description, location }: { title?: string; description?: string; location?: string }
) {
  assert(url, 'office365.url');

  const currentDate = new Date();

  const start = formatDate(currentDate, 'outlook');
  const end = formatDate(addMinutes(currentDate, 30), 'outlook');

  return (
    'https://outlook.office.com/calendar/0/deeplink/compose' +
    objectToGetParams({
      path: '/calendar/action/compose',
      rru: 'addevent',
      startdt: start,
      enddt: end,
      subject: title,
      body: description,
      location: location,
    })
  );
}
