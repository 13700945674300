const findMid = (x1: number, x2: number) => {
  return (Math.max(x1, x2) + Math.min(x1, x2)) / 2;
};

export class Panzoom {
  target: HTMLElement;
  rect: DOMRect;
  bounds: HTMLElement;
  edges: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  };
  translateX: number;
  translateY: number;
  zoomLevel: number;
  dragging: boolean;
  dragStart: {
    x: number;
    y: number;
    translateX: number;
    translateY: number;
  };
  exclude: string;
  excludeScroll: string;
  constraintScale: number;
  zoomListeners: ((newZoom: number) => void)[];
  lastPinchDist?: number;
  destroy: () => void;

  constructor(target: HTMLElement, bounds: HTMLElement, exclude: string, excludeScroll: string) {
    this.exclude = exclude;
    this.target = target;
    this.rect = target.getBoundingClientRect();
    const bounds_ = bounds?.getBoundingClientRect();
    this.edges = {
      top: bounds_?.top ? bounds_.top : 0,
      bottom: bounds_?.height ? bounds_.height : window.innerHeight,
      left: bounds_?.left ? bounds_.left : 0,
      right: bounds_?.width ? bounds_.width : window.innerHeight,
    };
    this.bounds = bounds;
    this.excludeScroll = excludeScroll;
    this.translateX = 0;
    this.translateY = 0;
    this.zoomLevel = 1;
    this.dragging = false;
    this.lastPinchDist = undefined;
    this.dragStart = { x: 0, y: 0, translateX: 0, translateY: 0 };
    const wMinScale = (this.edges.right - this.edges.left) / this.rect.width;
    const hMinScale = (this.edges.bottom - this.edges.top) / this.rect.height;
    this.constraintScale = Math.max(wMinScale, hMinScale);
    this.zoomListeners = [];
    this.destroy = this.initListeners();
  }

  getEdges = (rect: { left: number; right: number; top: number; bottom: number }) => {
    return {
      topCenter: {
        x: (rect.right - rect.left) / 2,
        y: rect.top,
      },
      leftCenter: {
        x: rect.left,
        y: (rect.bottom - rect.top) / 2,
      },
      rightCenter: {
        x: rect.right,
        y: (rect.bottom - rect.top) / 2,
      },
      bottomCenter: {
        x: (rect.right - rect.left) / 2,
        y: rect.bottom,
      },
    };
  };

  setListener(event: 'zoomchange', cb: (newZoom: number) => void) {
    if (event === 'zoomchange') this.zoomListeners.push(cb);
  }

  initListeners() {
    const onWheel = (event: WheelEvent) => {
      //@ts-ignore
      if (event.target?.closest(this.excludeScroll)) return;
      event.preventDefault();
      let zoomChange = 0.1;
      const oldZoom = this.zoomLevel;
      let zoomLevel = this.zoomLevel;
      if (event.deltaY < 0) zoomLevel += 0.1;
      else {
        zoomLevel -= 0.1;
        zoomChange = -0.1;
      }
      this.zoom(oldZoom, zoomLevel, event.clientX, event.clientY);
    };
    const endDrag = () => {
      this.endDrag();
    };
    const mouseMove = (e: MouseEvent) => {
      this.drag(e.clientX, e.clientY);
    };
    const touchStart = (e: TouchEvent) => {
      if (e.touches.length > 1) return;
      //@ts-ignore
      if (e.target?.closest(this.exclude)) return;
      this.initDrag(e.touches[0].clientX, e.touches[0].clientY);
    };
    const touchEnd = () => {
      this.endDrag();
      this.lastPinchDist = undefined;
    };
    const touchMove = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        const dist = Math.hypot(
          e.touches[0].clientX - e.touches[1].clientX,
          e.touches[0].clientY - e.touches[1].clientY
        );
        const center = {
          x: findMid(e.touches[0].pageX, e.touches[1].pageX),
          y: findMid(e.touches[0].pageY, e.touches[1].pageY),
        };
        if (!this.lastPinchDist) this.lastPinchDist = dist;
        this.zoom(this.zoomLevel, (this.zoomLevel * dist) / this.lastPinchDist, center.x, center.y);
        this.lastPinchDist = dist;
      } else this.drag(e.touches[0].clientX, e.touches[0].clientY);
    };
    const mouseDown = (e: MouseEvent) => {
      //@ts-ignore
      if (e.target?.closest(this.exclude)) return;
      this.initDrag(e.clientX, e.clientY);
    };
    const resize = () => {
      const bounds_ = this.bounds?.getBoundingClientRect();
      this.edges = {
        top: bounds_?.top ? bounds_.top : 0,
        bottom: bounds_?.height ? bounds_.height : window.innerHeight,
        left: bounds_?.left ? bounds_.left : 0,
        right: bounds_?.width ? bounds_.width : window.innerHeight,
      };
      const oldConstraintScale = this.constraintScale;
      const wMinScale = (this.edges.right - this.edges.left) / this.rect.width;
      const hMinScale = (this.edges.bottom - this.edges.top) / this.rect.height;
      this.constraintScale = Math.max(wMinScale, hMinScale);
      if (Math.abs(this.zoomLevel - oldConstraintScale) < 0.1) this.zoom(this.zoomLevel, this.constraintScale, 5, 5);
      // this.rect = this.target?.getBoundingClientRect();
    };
    this.target.addEventListener('wheel', onWheel);
    this.target.addEventListener('mouseup', endDrag);
    this.target.addEventListener('mouseleave', endDrag);
    this.target.addEventListener('mousemove', mouseMove);
    this.target.addEventListener('touchstart', touchStart);
    this.target.addEventListener('touchend', touchEnd);
    this.target.addEventListener('touchmove', touchMove);
    this.target.addEventListener('mousedown', mouseDown);
    window.addEventListener('resize', resize);
    return () => {
      this.target.removeEventListener('wheel', onWheel);
      this.target.removeEventListener('mouseup', endDrag);
      this.target.removeEventListener('mouseleave', endDrag);
      this.target.removeEventListener('mousemove', mouseMove);
      this.target.removeEventListener('touchstart', touchStart);
      this.target.removeEventListener('touchend', touchEnd);
      this.target.removeEventListener('touchmove', touchMove);
      this.target.removeEventListener('mousedown', mouseDown);
      window.removeEventListener('resize', resize);
      this.zoomListeners = [];
      this.target.style.transform = ``;
    };
  }

  initDrag(x: number, y: number) {
    this.dragging = true;
    this.dragStart = {
      x,
      y,
      translateX: this.translateX,
      translateY: this.translateY,
    };
  }

  endDrag() {
    this.dragging = false;
  }

  pan(x: number, y: number) {
    let [contraintTransform, newScale] = this.constraints({ x, y }, this.zoomLevel, this.zoomLevel, {
      x: this.translateX,
      y: this.translateY,
    });
    this.translateX = contraintTransform.x;
    this.translateY = contraintTransform.y;
    this.target.style.transform = `translate(${this.translateX}px, ${this.translateY}px) 
      scale(${this.zoomLevel})`;
  }

  drag(x: number, y: number) {
    if (this.dragging) {
      this.translateX = this.dragStart.translateX + x - this.dragStart.x;
      this.translateY = this.dragStart.translateY + y - this.dragStart.y;
      this.pan(this.translateX, this.translateY);
    }
  }

  getTransformedEdges = (
    transform: { x: number; y: number },
    scale: number
  ): [
    {
      topCenter: {
        x: number;
        y: number;
      };
      leftCenter: {
        x: number;
        y: number;
      };
      rightCenter: {
        x: number;
        y: number;
      };
      bottomCenter: {
        x: number;
        y: number;
      };
    },
    { width: number; height: number }
  ] => {
    const left = transform.x - this.rect.left;
    const top = transform.y - this.rect.top;
    const width = this.rect.width * scale;
    const height = this.rect.height * scale;
    const right = left + width;
    const bottom = top + height;
    return [this.getEdges({ left, right, top, bottom }), { width, height }];
  };

  constraints(
    transform: { x: number; y: number },
    scale: number,
    oldScale: number,
    oldTransform: { x: number; y: number }
  ): [
    {
      x: number;
      y: number;
    },
    number
  ] {
    const [getNewEdges, newRect] = this.getTransformedEdges(transform, scale);
    let transformNew = transform;
    let scaleNew = scale;
    let topConstraint = false;
    let leftConstraint = false;
    if (getNewEdges.topCenter.y > this.edges.top) {
      topConstraint = true;
      transformNew.y = this.edges.top;
    }
    if (getNewEdges.bottomCenter.y < this.edges.bottom) {
      if (topConstraint) {
        scaleNew = this.constraintScale;
        // transformNew.x = oldTransform.x;
        // transformNew.y = this.edges.bottom - newRect.height;
      } else transformNew.y = this.edges.bottom - newRect.height;
    }
    if (getNewEdges.leftCenter.x > this.edges.left) {
      leftConstraint = true;
      transformNew.x = this.edges.left;
    }
    if (getNewEdges.rightCenter.x < this.edges.right) {
      if (leftConstraint) {
        scaleNew = this.constraintScale;
        // transformNew.x = this.edges.right - newRect.width;
        // transformNew.y = oldTransform.y;
      } else transformNew.x = this.edges.right - newRect.width;
    }
    return [transformNew, scaleNew];
  }

  zoom = (currentZoom: number, newZoom: number, x: number, y: number) => {
    if (newZoom < this.constraintScale) newZoom = this.constraintScale;
    if (newZoom > 2.5) newZoom = 2.5;
    const oldZoom = currentZoom;
    let zoomLevel = newZoom;
    const ratio = 1 - zoomLevel / oldZoom;
    this.target.style.transformOrigin = `0 0`;
    const oldTransform = { x: this.translateX, y: this.translateY };
    this.translateX += (x - this.translateX) * ratio;
    this.translateY += (y - this.translateY) * ratio;
    let [contraintTransform, newScale] = this.constraints(
      { x: this.translateX, y: this.translateY },
      zoomLevel,
      oldZoom,
      oldTransform
    );
    this.translateX = contraintTransform.x;
    this.translateY = contraintTransform.y;
    zoomLevel = newScale;
    this.zoomLevel = zoomLevel;
    this.target.style.transform = `translate(${this.translateX}px, ${this.translateY}px) 
      scale(${zoomLevel})`;
    if (zoomLevel !== oldZoom) {
      for (let i in this.zoomListeners) {
        const zoomCB = this.zoomListeners[i];
        zoomCB(zoomLevel);
      }
    }
  };
}
