import firebase from 'firebase';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { mainSubDomain, maxParticipants, spillOverLimit } from '../../config';
import {
  setAmplitudeUserId,
  setAmplitudeUserProperties,
  setRoomAccessPermissions,
  setSpaceRoom,
} from '../../helper/amplitude';
import { getClaimToken, getUserFromFirebase, onAuthChange, signinWithToken } from '../../helper/firebase/auth';
import dailyCoObj from '../../helper/interactive/dailyco';
import {
  watchBroadcast,
  watchOverflowStream,
  watchRestrictedZone,
  watchRoomPermissions,
  watchScreenWallpaper,
  watchScreenWallV2,
  watchWallpaper,
  watchWallpaperV2,
} from '../../helper/interactive/firebaseRTD';
import { findGetParameter } from '../../helper/interactive/misc';
import logger from '../../helper/logger';
import roomHelper from '../../helper/requests/rooms';
import spaceObj from '../../helper/requests/spaces';
import presenceManager from '../../helper/UsagePresence/presenceManager';
import usage from '../../helper/UsagePresence/usage';
import spatialAudioController, { updateAllBubbleSize } from '../../spatialAudio/spatialAudioOptimized';
import {
  deleteRoom,
  getAuthState,
  getChatOpenStore,
  getIsRecordingState,
  getJoinStatus,
  getReactionState,
  getRoomsFromStore,
  getSpacesRoomsState,
  getUnreadChatCountStore,
  setBroadcasterDetailsStore,
  setBroadcasterFuncStore,
  setBroadcasterStatusStore,
  setChatMessagesStore,
  setCurrentRoom,
  setCurrentRoomCopy,
  setCurrentRoomPassword,
  setCurrentScreenWall,
  setCurrentScreenWallV2,
  setCurrentSpace,
  setCurrentWall,
  setCurrentWallV2,
  setKickedOutOnEndStore,
  setLoadingAuthStore,
  setOverflowStreamBroadcasterID,
  setOverflowStreamIsLive,
  setOverflowStreamRoomID,
  setRestrictedZonesStore,
  setRoomCopyLoading,
  setRoomLoading,
  setRoomPermission,
  setRooms,
  setRoomsClaimLoading,
  setSpaceLoading,
  setSpaces,
  setTokenForBroadcastStore,
  setUnreadChatMessageCountStore,
  setUserDetails,
  setUserStore,
} from '../../store';
import { getAuth } from '../../store/auth';
import { getCustomClaimFromRedux } from '../../store/customClaim';
import { getDailyJoinedState } from '../../store/dailycoParticipants';
import { getPresenceOfRoom } from '../../store/presence';
import { getReactionRedux } from '../../store/reactions';
import { getIsRecordingRedux } from '../../store/recording';
import { getChatOpenStateRedux } from '../../store/settingsMenu';
import {
  getCurrentRoomPermissionAndUserRoleRedux,
  getCurrentSpace,
  getCurrentUserRoleRedux,
  getSpacesRooms,
  roomType,
} from '../../store/spacesRooms';
import { closeClosedRoomPermModal, openClosedRoomPermModal } from '../customiseLogin/scheduledRoomModal';

const getDefault = (val: any, def: boolean | number) => {
  return val === undefined || val === null ? def : val;
};

const getRoomPermissionDict = (
  roomPermissions: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
) => {
  const permissions: { [id: string]: any } = {};
  _.map(roomPermissions, (roomPermission) => {
    permissions[roomPermission.data().roomID] = roomPermission.data();
  });
  return permissions;
};

const LoginListener = () => {
  const auth = useSelector(getAuth);
  const history = useHistory();

  useEffect(() => {
    const loginToken = findGetParameter('loginToken');
    if (loginToken) signinWithToken(loginToken);
  }, []);

  useEffect(() => {
    const handleAuthChange = async (user: firebase.User | null) => {
      let redirection = localStorage.getItem('redirection');
      if (redirection === '[object Object]') redirection = '/';
      const isLoginToken = findGetParameter('loginToken');
      if (user) {
        // @ts-ignore
        window.reslashUserID = user.uid;
      }
      if (user) {
        setLoadingAuthStore(true);
        if (user.isAnonymous) {
          const name = localStorage.getItem('guestName');
          const email = localStorage.getItem('guestEmail');
          setUserStore({
            id: user.uid,
            name: name ? name : '',
            email: email ? email : '',
            token: '',
            isGuest: true,
          });
          // if (email) user?.updateEmail(email).catch((e) => {});
          if (name && user?.updateProfile) user?.updateProfile({ displayName: name }).catch((e) => {});
          if (auth.redirectTo) history.replace(auth.redirectTo);
          else if (redirection) history.replace(redirection);
        }
        if (user.emailVerified) {
          setUserStore({
            id: user.uid,
            name: user.displayName || '',
            email: user.email || '',
            token: '',
            isGuest: isLoginToken ? true : user.isAnonymous,
          });
          if (auth.redirectTo) history.replace(auth.redirectTo);
          else if (redirection) history.replace(redirection);
          else history.push('/');
        }
        // let loc: any = location.state;
        // let saved = localStorage.getItem('lastPath');
        // if (loc) loc = { from: loc.from.pathname + loc.from.search };
        // else if (!loc && saved && saved.length > 2) {
        //   let path = JSON.parse(saved);
        //   let pathName = path.pathname + path.search;
        //   loc = { from: pathName };
        //   localStorage.setItem('lastPath', '');
        // } else loc = { from: { pathname: '/' } };

        setLoadingAuthStore(false);
      } else {
        setLoadingAuthStore(false);
      }
    };
    onAuthChange(async (user) => {
      await handleAuthChange(user);
    });
  }, [auth.redirectTo]);
  return <></>;
};

const WallpaperWatcherEle = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  const customClaim = useSelector(getCustomClaimFromRedux);
  useEffect(() => {
    if (!customClaim?.roomUserRoleV2 || !customClaim?.roomUserRoleV2[spacesRooms.currentRoom]) {
      return;
    }
    const screenWallWatcher = watchScreenWallpaper(spacesRooms.currentSpace, spacesRooms.currentRoom, (id) => {
      setCurrentScreenWall(id);
    });
    const screenWallV2Watcher = watchScreenWallV2(spacesRooms.currentSpace, spacesRooms.currentRoom, (screenWallV2) => {
      setCurrentScreenWallV2(screenWallV2);
    });
    const wallpaperWatcherV2 = watchWallpaperV2(spacesRooms.currentSpace, spacesRooms.currentRoom, (wallObj) => {
      setCurrentWallV2(wallObj);
    });
    const wallpaperWatcher = watchWallpaper(spacesRooms.currentSpace, spacesRooms.currentRoom, (url) => {
      if (!url) {
        setCurrentWall('https://s3.us-west-1.wasabisys.com/reslashco-template/Party%203.jpg');
        return;
      }
      const img = new Image();
      setCurrentWall('loading');
      img.onload = function () {
        logger.info('Wallpaper changed', { url });
        setCurrentWall(url);
      };
      img.onerror = function (e) {
        logger.warn('Error in background:', { e, url });
        setCurrentWall('error');
      };
      img.src = url;
    });
    return () => {
      wallpaperWatcher();
      screenWallWatcher();
      wallpaperWatcherV2();
      screenWallV2Watcher();
    };
  }, [customClaim, spacesRooms.currentRoom, spacesRooms.currentSpace]);
  return <></>;
};

const BroadcastWatcher = () => {
  const auth = useSelector(getAuth);
  const spacesRooms = useSelector(getSpacesRooms);
  const customClaim = useSelector(getCustomClaimFromRedux);
  const timer = useRef<NodeJS.Timer>();
  const isLive = useRef({
    uid: '',
    isLive: false,
  });

  useEffect(() => {
    if (!auth.isLoggedIn) return;
    (async () => {
      const token = await dailyCoObj.getBroadcastToken(spacesRooms.currentSpace.slice(0, 25));
      setTokenForBroadcastStore(token);
    })();
  }, [spacesRooms.currentSpace, auth.isLoggedIn]);

  useEffect(() => {
    let unsub = () => {};
    if (
      spacesRooms.currentSpace.length > 3 &&
      spacesRooms.currentRoom.length > 3 &&
      customClaim?.roomUserRoleV2 &&
      customClaim?.roomUserRoleV2[spacesRooms.currentRoom]
    ) {
      logger.info('Watching overflowstream:', {
        currentRoom: spacesRooms.currentRoom,
        currentSpace: spacesRooms.currentSpace,
      });
      unsub = watchOverflowStream(spacesRooms.currentSpace, spacesRooms.currentRoom, (data) => {
        const timenow = new Date().getTime();
        const timeout = () => {
          setOverflowStreamIsLive(false);
          if (timer.current) clearTimeout(timer.current);
        };
        if (!data || timenow - data.ping > 5000) {
          setOverflowStreamIsLive(false);
          timer.current = setTimeout(timeout, 5000);
        } else {
          if (timer.current) clearTimeout(timer.current);
          setOverflowStreamIsLive(true);
          setOverflowStreamBroadcasterID(data.broadcasterID);
          setOverflowStreamRoomID(spacesRooms.currentRoom);
          timer.current = setTimeout(timeout, 5000);
        }
      });
    } else {
      logger.info('Unable to watch overflowstream:', {
        currentRoom: spacesRooms.currentRoom,
        currentSpace: spacesRooms.currentSpace,
        customClaim,
      });
    }
    return unsub;
  }, [spacesRooms.currentRoom, customClaim, spacesRooms.currentSpace]);

  useEffect(() => {
    let unsub = () => {};
    if (
      spacesRooms.currentSpace.length > 3 &&
      customClaim?.spacesV2 &&
      customClaim?.spacesV2[spacesRooms.currentSpace]
    ) {
      unsub = watchBroadcast(spacesRooms.currentSpace, (broadcastInfo) => {
        const timeNow = new Date().getTime();
        if (broadcastInfo) {
          if (!isLive.current.isLive !== broadcastInfo.isLive || isLive.current.uid !== broadcastInfo.uid)
            setBroadcasterDetailsStore(broadcastInfo);
          if (broadcastInfo.isLive && timeNow - broadcastInfo.ping < 13000) {
            setBroadcasterStatusStore(true);
          } else {
            setBroadcasterStatusStore(false);
            setBroadcasterDetailsStore({
              uid: '',
              name: '',
              profilePic: '',
              listeners: 0,
              isLive: false,
            });
          }
          isLive.current = {
            isLive: broadcastInfo.isLive,
            uid: broadcastInfo.uid,
          };
        } else {
          setBroadcasterStatusStore(false);
          setBroadcasterFuncStore({
            isLocal: false,
            toMute: () => {},
            toVidMute: () => {},
            isMuted: true,
            isVidMuted: true,
          });
        }
      });
    }
    return unsub;
  }, [customClaim, spacesRooms.currentSpace]);

  return <></>;
};

const ProfileWatcher = () => {
  const auth = useSelector(getAuth);
  const spacesRooms = useSelector(getSpacesRooms);
  const currentRoomPermissions = useSelector(getCurrentRoomPermissionAndUserRoleRedux);
  const currentRoomUserRole = useSelector(getCurrentUserRoleRedux);
  const isRecording = useSelector(getIsRecordingRedux);
  const reaction = useSelector(getReactionRedux);

  useEffect(() => {
    if (auth.isLoggedIn) {
      logger.setUserInfo(auth.user.name, auth.user.email, auth.user.id, auth.user.isGuest);
      setAmplitudeUserId(auth.user.id);
      setAmplitudeUserProperties({
        name: auth.user.name,
        email: auth.user.email,
        userID: auth.user.id,
        isGuest: auth.user.isGuest,
      });
    }
  }, [auth]);

  useEffect(() => {
    if (spacesRooms.roomsLoading || spacesRooms.spacesLoading) return;
    logger.info('Userrole changed', { currentRoomUserRole });
  }, [currentRoomUserRole]);

  useEffect(() => {
    if (spacesRooms.roomsLoading || spacesRooms.spacesLoading) return;
    logger.info('Permission changed', { currentRoomPermissions });
  }, [
    currentRoomPermissions.permission?.isAdmin,
    currentRoomPermissions.permission?.isGuest,
    currentRoomPermissions.permission?.isTeamMember,
  ]);

  useEffect(() => {
    if (
      auth.isLoggedIn &&
      !spacesRooms.roomsLoading &&
      !spacesRooms.spacesLoading &&
      spacesRooms.currentRoom &&
      spacesRooms.currentRoom.length > 5
    ) {
      const presenceRef = firebase
        .database()
        .ref(`space/${spacesRooms.currentSpace}/room/${spacesRooms.currentRoom}/presence/${auth.user.id}`);
      (async () => {
        const presence = await presenceRef.get();
        if (presence.val() && presence.val().online) {
          const presenceNew = {
            ...presence.val(),
            permission: {
              permission: currentRoomPermissions?.permission,
              role: currentRoomUserRole,
            },
            isRecording,
            reaction: reaction
              ? {
                  type: reaction.type ? reaction.type : 'text',
                  payload: {
                    text: reaction.payload.text ? reaction.payload.text : '',
                    url: reaction.payload.url ? reaction.payload.url : '',
                  },
                  sentTime: reaction.sentTime ? reaction.sentTime : 0,
                }
              : {},
            desc: auth.userDetails.desc ? auth.userDetails.desc : '',
            status: auth.userDetails.status ? auth.userDetails.status : '',
            socialProfiles: auth.userDetails.socialProfiles
              ? {
                  twitter: auth.userDetails.socialProfiles.twitter ? auth.userDetails.socialProfiles.twitter : '',
                  facebook: auth.userDetails.socialProfiles.facebook ? auth.userDetails.socialProfiles.facebook : '',
                  linkedin: auth.userDetails.socialProfiles.linkedin ? auth.userDetails.socialProfiles.linkedin : '',
                  phone: auth.userDetails.socialProfiles.phone ? auth.userDetails.socialProfiles.phone : '',
                  email: auth.userDetails.socialProfiles.email ? auth.userDetails.socialProfiles.email : '',
                  link: auth.userDetails.socialProfiles.link ? auth.userDetails.socialProfiles.link : '',
                }
              : { twitter: '', facebook: '', linkedin: '', phone: '', email: '', link: '' },
          };
          await presenceRef.set(presenceNew);
        }
      })();
    }
  }, [
    currentRoomUserRole,
    currentRoomPermissions?.permission?.isAdmin,
    currentRoomPermissions?.permission?.isGuest,
    currentRoomPermissions?.permission?.isTeamMember,
    spacesRooms.currentRoom,
    spacesRooms.roomsLoading,
    spacesRooms.spacesLoading,
    auth.isLoggedIn,
    auth.userDetails,
    isRecording,
    reaction?.sentTime,
    reaction?.type,
  ]);
  useEffect(() => {
    let unsub = () => {};
    if (
      auth.isLoggedIn &&
      !spacesRooms.roomsLoading &&
      !spacesRooms.spacesLoading &&
      spacesRooms.currentRoom &&
      spacesRooms.currentRoom.length > 5
    ) {
      const userRef = firebase.firestore().collection('userV2').doc(auth.user.id);
      unsub = userRef.onSnapshot(async (doc: any) => {
        const userInfo = {
          profilePic: '',
          name: '',
          username: '',
          email: '',
          desc: '',
          status: '',
          socialProfiles: {
            twitter: '',
            facebook: '',
            linkedin: '',
            phone: '',
            email: '',
            link: '',
          },
        };
        if (!doc.data()) {
          let name = auth.user.name;
          let email = auth.user.email;
          const nameStorage = localStorage.getItem('guestName');
          const emailStorage = localStorage.getItem('guestEmail');
          if (nameStorage) {
            name = nameStorage;
          }
          if (emailStorage) {
            email = emailStorage;
          }
          setUserDetails({
            name,
            email,
            profilePic: '',
            desc: '',
            username: email,
            status: 'Available',
            socialProfiles: {
              twitter: '',
              facebook: '',
              linkedin: '',
              phone: '',
              email: '',
              link: '',
            },
          });
          try {
            const user = getUserFromFirebase();
            if (user) {
              try {
                await user.updateProfile({ displayName: name });
                //await user.updateEmail(email);
              } catch {}
              userRef.set({
                name,
                email,
                profilePic: '',
                desc: '',
                username: email,
              });
            }
          } catch {}
          return;
        }
        userInfo.profilePic = doc.data().profilePic;
        userInfo.name = doc.data().name ? doc.data().name : auth.user.name;
        userInfo.username = doc.data().username ? doc.data().username : userInfo.name;
        userInfo.email = doc.data().email;
        userInfo.desc = doc.data().desc;
        userInfo.status = doc.data().status ? doc.data().status : 'Available';
        userInfo.socialProfiles = doc.data().socialProfiles
          ? doc.data().socialProfiles
          : { twitter: '', facebook: '', linkedin: '', phone: '', email: '', link: '' };

        setUserDetails(userInfo);
        const presenceRef = firebase
          .database()
          .ref(`space/${spacesRooms.currentSpace}/room/${spacesRooms.currentRoom}/presence/${auth.user.id}`);
        const presence = await presenceRef.get();
        if (presence.val() && presence.val().online) {
          await presenceRef.set({
            name: userInfo.name.length < 1 ? auth.user.name : userInfo.name,
            profilePic: userInfo.profilePic,
            email: auth.user.email,
            online: true,
            timestamp: new Date().getTime(),
            desc: userInfo.desc,
            status: userInfo.status,
            socialProfiles: {
              twitter: userInfo.socialProfiles.twitter ? userInfo.socialProfiles.twitter : '',
              facebook: userInfo.socialProfiles.facebook ? userInfo.socialProfiles.facebook : '',
              linkedin: userInfo.socialProfiles.linkedin ? userInfo.socialProfiles.linkedin : '',
              phone: userInfo.socialProfiles.phone ? userInfo.socialProfiles.phone : '',
              email: userInfo.socialProfiles.email ? userInfo.socialProfiles.email : '',
              link: userInfo.socialProfiles.link ? userInfo.socialProfiles.link : '',
            },
            ...presence.val(),
          });
        }
      });
    }
    return unsub;
  }, [auth.isLoggedIn, auth.user, spacesRooms.roomsLoading, spacesRooms.spacesLoading, spacesRooms.currentRoom]);
  return <></>;
};

const ChatWatcher = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  const customClaim = useSelector(getCustomClaimFromRedux);
  const chatOpenListener = useSelector(getChatOpenStateRedux);
  const openTime = useMemo(() => new Date().getTime(), [chatOpenListener, spacesRooms.currentRoom]);
  const [unread, setUnread] = useState<{ [id: string]: boolean }>({});

  useEffect(() => {
    if (chatOpenListener) setUnreadChatMessageCountStore(0);
  }, [chatOpenListener]);

  useEffect(() => {
    setUnreadChatMessageCountStore(0);
    setUnread({});
  }, [spacesRooms.currentRoom, chatOpenListener]);

  useEffect(() => {
    setUnreadChatMessageCountStore(_.filter(unread, (unread) => unread).length);
  }, [unread]);

  useEffect(() => {
    if (!spacesRooms.currentRoom || spacesRooms.currentRoom.length < 3) return;
    if (!customClaim?.roomUserRoleV2 || !customClaim?.roomUserRoleV2[spacesRooms.currentRoom]) {
      return;
    }
    const messagesRef = firebase.firestore().collection('roomV2').doc(spacesRooms.currentRoom).collection('messages');
    setChatMessagesStore([]);
    const unsubscribe = messagesRef
      .where('isDeleted', '==', false)
      .orderBy('createdAt', 'desc')
      .limit(1000)
      .onSnapshot((querySnapshot) => {
        const chatOpen = getChatOpenStore();
        const items: any[] = [];
        let i = 0;
        let unread = getUnreadChatCountStore();
        querySnapshot.forEach((doc) => {
          items.push({ id: doc.id, ...doc.data() });
          i++;
        });
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            let createdAt = 0;
            if (change.doc?.data()?.createdAt?.seconds) createdAt = change.doc.data().createdAt.seconds;
            if (!chatOpen && createdAt * 1000 > openTime && getAuthState().user.id !== change.doc?.data()?.createdBy)
              setUnread((unread) => {
                return {
                  ...unread,
                  [change.doc.id]: true,
                };
              });
            else
              setUnread((unread) => {
                return {
                  ...unread,
                  [change.doc.id]: false,
                };
              });
          } else {
            setUnread((unread) => {
              return {
                ...unread,
                [change.doc.id]: change.type === 'added',
              };
            });
          }
        });
        setChatMessagesStore(items);
        // setUnreadChatMessageCountStore(unread);
      });
    return unsubscribe;
  }, [spacesRooms.currentRoom, customClaim?.roomUserRoleV2]);

  return <></>;
};

const RoomSpacesWatcher = () => {
  const auth = useSelector(getAuth);
  const spacesRooms = useSelector(getSpacesRooms);
  const [roomLoadingInternal, setRoomLoadingInternal] = useState(true);
  const [roomPermissionLoading, setRoomPermissionLoading] = useState(true);
  const currentSpace = useSelector(getCurrentSpace);
  const roomPermissionsRef = useRef<{ [id: string]: roomType }>({});
  const [defaultRoomLoading, setDefaultRoomLoading] = useState(false);
  const [defaultRoom, setDefaultRoom] = useState<string>('');
  const roomPermissionCache = useRef<typeof spacesRooms.rooms>({});
  const roomCache = useRef<typeof spacesRooms.rooms>({});
  const customClaim = useSelector(getCustomClaimFromRedux);
  const roomPermissionLoadingRef = useRef(false);
  const roomLoadingRef = useRef(false);
  const firstLoad = useRef(true);
  const history = useHistory();

  useEffect(() => {
    logger.info('CustomClaim changed:', { customClaim });
  }, [customClaim]);

  useEffect(() => {
    logger.setSpace(spacesRooms.currentSpace);
    logger.setRoom(spacesRooms.currentRoom);
    logger.setRoomCopy(spacesRooms.currentRoomCopy);
  }, [spacesRooms.currentRoom, spacesRooms.currentSpace, spacesRooms.currentRoomCopy]);

  useEffect(() => {
    setSpaceRoom(
      spacesRooms.currentSpace,
      spacesRooms.spaces[spacesRooms.currentSpace]?.permission,
      spacesRooms.currentRoom,
      spacesRooms.currentRoomCopy + '',
      spacesRooms.currentUserRole,
      spacesRooms.rooms[spacesRooms.currentRoom]?.permission
    );
  }, [
    spacesRooms.currentRoom,
    spacesRooms.currentSpace,
    spacesRooms.spaces[spacesRooms.currentSpace],
    spacesRooms.currentRoomCopy,
    spacesRooms.currentUserRole,
    spacesRooms.rooms[spacesRooms.currentRoom],
  ]);

  useEffect(() => {
    const permissions = spacesRooms.permissionV2[spacesRooms.currentUserRole];
    if (permissions) {
      setRoomAccessPermissions({
        roomAccessPermission: permissions,
        roomUserRole: spacesRooms.currentUserRole,
        spatialOnOff: spacesRooms.currentRoomPermission.spatial,
        bubbleSize: spacesRooms.currentRoomPermission.size,
      });
    }
  }, [spacesRooms.permissionV2, spacesRooms.currentUserRole, spacesRooms.currentRoomPermission]);

  // const updateCache = (rooms: typeof spacesRooms.rooms, permission = true) => {
  //   if (permission)
  //     for (let key in roomPermissionCache.current) {
  //       if (!rooms[key]) {
  //         deleteRoom(key);
  //       }
  //     }
  //   else
  //     for (let key in roomCache.current) {
  //       if (!rooms[key] && !roomPermissionCache.current[key]) {
  //         deleteRoom(key);
  //       }
  //     }
  //   if (permission) {
  //     roomPermissionCache.current = rooms;
  //   } else {
  //     roomCache.current = rooms;
  //   }
  // };

  useEffect(() => {
    (async () => {
      const token = await getClaimToken();
      if (!window.location.href.includes('/r/') && token && defaultRoom.length < 2 && currentSpace.length > 2) {
        setDefaultRoomLoading(true);
        try {
          const dr = (await spaceObj.getDefaultRoom(currentSpace, token.token)).data.defaultRoom;
          setDefaultRoom(dr);
          setDefaultRoomLoading(false);
        } catch {
          setDefaultRoomLoading(false);
        }
      }
    })();
  }, [currentSpace]);

  useEffect(() => {
    if (spacesRooms.currentRoom && spacesRooms.currentRoom.length > 3) {
      (async () => {
        await roomHelper.setClaimThenRoom(spacesRooms.currentRoom, false);
        setRoomsClaimLoading(false);
      })();
    }
  }, [spacesRooms.currentRoom]);

  useEffect(() => {
    if (!roomPermissionLoading && !roomLoadingInternal) {
      (async () => {
        if (defaultRoomLoading && !window.location.href.includes('/r/')) return;
        if (!window.location.href.includes('/r/') && defaultRoom.length > 2 && spacesRooms.rooms[defaultRoom]) {
          setCurrentRoom(defaultRoom);
        } else if (
          !window.location.href.includes('/r/') &&
          (!spacesRooms.currentRoom || spacesRooms.currentRoom.length < 2)
        ) {
          setCurrentRoom(_.keys(spacesRooms.rooms)[0]);
        }
        setRoomLoading(false);
      })();
    } else setRoomLoading(true);
  }, [roomPermissionLoading, roomLoadingInternal, spacesRooms, defaultRoom, defaultRoomLoading]);

  useEffect(() => {
    if (spacesRooms.roomsLoading || spacesRooms.spacesLoading) return;
    if (roomPermissionLoadingRef.current || roomLoadingRef.current) return;
    for (let key in spacesRooms.rooms) {
      if (!roomPermissionCache.current[key] && !roomCache.current[key]) {
        deleteRoom(key);
      }
    }
  }, [spacesRooms, roomPermissionLoadingRef, roomLoadingRef, roomPermissionCache, roomCache]);

  useEffect(() => {
    if (!auth.isLoggedIn) return;
    const permissionListener = firebase
      .firestore()
      .collection('spacePermissionV2')
      .where('userID', '==', auth.user.id)
      .where('isDeleted', '==', false)
      .onSnapshot(async (docSnapshot) => {
        const spaces: { [id: string]: any } = {};
        for (let i in docSnapshot.docs) {
          const space = docSnapshot.docs[i].data();
          spaces[space.spaceID] = space;
        }
        if (_.keys(spaces).length > 0) setSpaces(spaces);
        let spaceSwitcherFlag = false;
        if (_.keys(spaces).length > 0) {
          const space = spaces[_.keys(spaces)[0]];
          if (
            !auth.user.isGuest &&
            !window.location.href.includes('/s/') &&
            (window.location.href.includes(mainSubDomain) || window.location.href.includes('localhost')) &&
            !window.location.href.includes('/onboard?sticky') &&
            !window.location.href.includes('/allowPermissions') &&
            !window.location.href.includes('/voiceSettings') &&
            !window.location.href.includes('/createSpace') &&
            (!currentSpace || currentSpace.length < 2)
          ) {
            const token = await getClaimToken();
            if (token && _.keys(spaces).length < 2) {
              await spaceObj.setSpaceCustomClaim(space.spaceID, token.token);
              await getClaimToken();
              setCurrentSpace(space.spaceID);
            } else if (_.keys(spaces).length > 2) {
              // openSpaceSwitcherModal();
              spaceSwitcherFlag = false;
            }
          }
        }
        if (!spaceSwitcherFlag) setSpaceLoading(false);
      });
    return () => {
      permissionListener();
    };
  }, [auth.isLoggedIn, spacesRooms.spaceClaimLoading]);
  let joinStatus = useSelector(getJoinStatus);

  useEffect(() => {
    //eject people on meeting end.
    let timeout: NodeJS.Timeout | undefined = undefined;
    const currentRoom = spacesRooms.rooms[spacesRooms.currentRoom];
    const clearTimeout_ = () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    if (currentRoom) {
      if (
        currentRoom.roomDetails?.schedule?.till &&
        currentRoom.roomDetails?.schedule?.ejectOnEnd &&
        !currentRoom.permission?.isAdmin &&
        joinStatus
      ) {
        if (currentRoom.roomDetails?.schedule?.till === -1) clearTimeout_();
        else {
          const timeNow = new Date().getTime() / 1000;
          timeout = setTimeout(() => {
            setKickedOutOnEndStore(true);
            if (joinStatus && dailyCoObj.callObj) {
              dailyCoObj.callObj
                .leave()
                .then(() => {})
                .catch(() => {
                  window.location.reload();
                });
              usage.endAllSessions().then().catch();
              openClosedRoomPermModal(currentRoom.roomID)();
            }
          }, (currentRoom.roomDetails?.schedule?.till - timeNow) * 1000);
        }
      } else {
        clearTimeout_();
        closeClosedRoomPermModal();
        setKickedOutOnEndStore(false);
      }
      if (
        currentRoom.roomDetails?.schedule?.from &&
        !currentRoom.permission?.isAdmin &&
        currentRoom.roomDetails?.schedule?.from > new Date().getTime() / 1000 &&
        joinStatus
      ) {
        setKickedOutOnEndStore(true);
        if (joinStatus && dailyCoObj.callObj) {
          dailyCoObj.callObj
            .leave()
            .then(() => {})
            .catch(() => {
              window.location.reload();
            });
          usage.endAllSessions().then().catch();
          openClosedRoomPermModal(currentRoom.roomID)();
        }
      } else {
        closeClosedRoomPermModal();
        setKickedOutOnEndStore(false);
      }
    }
  }, [spacesRooms.rooms[spacesRooms.currentRoom], spacesRooms.currentRoom, joinStatus]);

  useEffect(() => {
    if (!auth.isLoggedIn) return;
    let roomsListener = () => {};
    let roomPermissionListener = () => {};
    const currentSpace = spacesRooms.spaces[spacesRooms.currentSpace];
    if (currentSpace && !spacesRooms.spaceClaimLoading) {
      let roomQuery: firebase.firestore.Query<firebase.firestore.DocumentData>;
      let roomPermissionQuery: firebase.firestore.Query<firebase.firestore.DocumentData> = firebase
        .firestore()
        .collection('roomPermissionV2')
        .where('spaceID', '==', spacesRooms.currentSpace)
        .where('userID', '==', auth.user.id)
        .where('isDeleted', '==', false);
      if (currentSpace.permission.isOwner)
        roomQuery = firebase
          .firestore()
          .collection('roomV2')
          .where('spaceID', '==', spacesRooms.currentSpace)
          .where('isLocked', '==', false)
          .where('isDeleted', '==', false);
      else if (currentSpace.permission.isTeamMember)
        roomQuery = firebase
          .firestore()
          .collection('roomV2')
          .where('spaceID', '==', spacesRooms.currentSpace)
          .where('isLocked', '==', false)
          .where('isDeleted', '==', false);
      else
        roomQuery = firebase
          .firestore()
          .collection('roomV2')
          .where('spaceID', '==', spacesRooms.currentSpace)
          .where('isLocked', '==', false)
          .where('guestAllowed', '==', true)
          .where('isDeleted', '==', false);
      if (spacesRooms.currentSpace && spacesRooms.currentSpace.length > 2) {
        // if (noRoomPermissionFlag) roomPermissionQuery = roomQuery;
        // if (noRoomPermissionFlag) setRoomPermissionLoading(false);
        roomPermissionListener = roomPermissionQuery.onSnapshot((roomPermissionDocs) => {
          roomPermissionLoadingRef.current = true;
          const roomPermissions = roomPermissionDocs.docs;
          const rooms: { [id: string]: roomType } = {};
          for (let i in roomPermissionDocs.docs) {
            const room: roomType = roomPermissionDocs.docs[i].data() as roomType;
            if (
              room.revoked?.revokedAt &&
              room.revoked?.reason === 'password-changed' &&
              roomPermissionDocs.docChanges()[i]?.type === 'modified' &&
              !firstLoad.current
            ) {
              logger.info('Access revoked due to password change.', { timeStamp: room.revoked?.revokedAt });
              setCurrentRoomPassword(undefined);
            }
            const roomID = room.roomID;
            if (room.isDeleted === true) {
              if (spacesRooms.currentRoom === roomID) {
                setCurrentRoom('');
              }
              continue;
            }
            // if (
            //   !room?.permission.isAdmin &&
            //   room.roomDetails?.schedule &&
            //   room.roomDetails?.schedule?.till !== -1 &&
            //   room.roomDetails?.schedule?.till < new Date().getTime() / 1000
            // ) {
            //   continue;
            // }
            rooms[roomID] = room;
          }
          firstLoad.current = false;
          roomPermissionsRef.current = rooms;
          roomPermissionLoadingRef.current = false;
          roomPermissionCache.current = rooms;
          setRooms(rooms);
          setRoomPermissionLoading(false);
        });
        roomsListener = roomQuery.onSnapshot((roomsDocs) => {
          roomLoadingRef.current = true;
          let roomsOld = _.cloneDeep(getRoomsFromStore());
          let rooms: typeof roomsOld = {};
          for (let i in roomsDocs.docs) {
            const roomDoc = roomsDocs.docs[i];
            const roomData = roomDoc.data();
            if (!rooms[roomDoc.id]) {
              // if (
              //   !roomPermissionsRef.current[roomDoc.id]?.permission.isAdmin &&
              //   roomData.schedule?.till !== -1 &&
              //   roomData.schedule?.till < new Date().getTime() / 1000
              // ) {
              //   continue;
              // }
              const room: roomType = {
                permission: roomPermissionsRef.current[roomDoc.id]?.permission || {
                  isAdmin: false,
                  isTeamMember: currentSpace.permission.isTeamMember,
                  isGuest: !currentSpace.permission.isTeamMember,
                },
                roomDetails: {
                  roomName: roomData.name,
                  isOverflow: roomData.isOverflow,
                  capacity: roomData.capacity ? roomData.capacity : maxParticipants,
                  spillOverAt: roomData.spillOverAt ? roomData.spillOverAt : spillOverLimit,
                  enabledPassword: roomData.passwordEnabled,
                  isHidden: roomData.isHidden === undefined ? false : roomData.isHidden,
                  schedule: {
                    from: roomData.schedule?.from || -1,
                    till: roomData.schedule?.till || -1,
                    ejectOnEnd: roomData.schedule?.ejectOnEnd === undefined ? false : roomData.schedule?.ejectOnEnd,
                  },
                },
                userInfo: {
                  name: auth.user.name,
                  email: auth.user.email,
                  profilePic: '',
                  desc: '',
                  username: auth.user.email,
                },
                roomID: roomDoc.id,
                spaceID: spacesRooms.currentSpace,
                createdBy: '',
                userID: auth.user.id,
                createdOn: new Date(),
                updatedBy: '',
                updatedOn: undefined,
                isDeleted: false,
              };
              rooms[roomDoc.id] = room;
            }
            // updateCache(rooms, false);
          }
          roomCache.current = rooms;
          roomLoadingRef.current = false;
          setRooms(rooms);
          setRoomLoadingInternal(false);
        });
      }
    }
    return () => {
      roomsListener();
      roomPermissionListener();
    };
  }, [currentSpace, auth.isLoggedIn, spacesRooms.spaceClaimLoading, spacesRooms.spaces]);

  useEffect(() => {
    if (!spacesRooms.currentRoom || spacesRooms.currentRoom.length < 2) return () => {};
    if (!customClaim?.roomUserRoleV2 || !customClaim?.roomUserRoleV2[spacesRooms.currentRoom]) {
      return;
    }
    const permissionListener = watchRoomPermissions(spacesRooms.currentSpace, spacesRooms.currentRoom, (p) => {
      setRoomPermission({
        des: getDefault(p?.des, true),
        im: getDefault(p?.im, true),
        size: getDefault(p?.size, 128),
        wall: getDefault(p?.wall, true),
        mic: getDefault(p?.mic, true),
        cam: getDefault(p?.cam, true),
        spatial: getDefault(p?.spatial, true),
        meg: getDefault(p?.meg, true),
        par: getDefault(p?.par, true),
        move: getDefault(p?.move, true),
        chat: getDefault(p?.chat, true),
        rec: getDefault(p?.rec, true),
        vidOnPerm: getDefault(p?.vidOnPerm, false),
        openroom: getDefault(p?.openroom, false),
        lockroom: getDefault(p?.lockroom, false),
      });
      spatialAudioController.setBubbleSize(p?.size);
      spatialAudioController.recalculateAllVolumesInInfluence();
      spatialAudioController.setVideoOnPerm(getDefault(p?.vidOnPerm, false));
      setTimeout(() => updateAllBubbleSize(p?.size), 500);
    });
    return () => permissionListener();
  }, [spacesRooms.currentRoom, customClaim]);

  return <></>;
};

const RestrictedZoneListener = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  const customClaim = useSelector(getCustomClaimFromRedux);
  useEffect(() => {
    if (!customClaim?.roomUserRoleV2 || !customClaim?.roomUserRoleV2[spacesRooms.currentRoom]) {
      return;
    }
    if (spacesRooms.currentRoom?.length < 1 || spacesRooms.currentSpace?.length < 1) return;
    const unsub = watchRestrictedZone(spacesRooms.currentSpace, spacesRooms.currentRoom, (data) => {
      setRestrictedZonesStore(data ? data : {});
    });
    return unsub;
  }, [customClaim?.roomUserRoleV2, customClaim?.roomUserRoleV2, spacesRooms.currentSpace, spacesRooms.currentRoom]);
  return <></>;
};

const UsagePresenceWatcherNew = () => {
  const auth = useSelector(getAuth);
  const spacesRooms = useSelector(getSpacesRooms);
  const spacesRoomsRef = useRef<typeof spacesRooms>();
  const authRef = useRef<typeof auth>();

  useEffect(() => {
    spacesRoomsRef.current = spacesRooms;
  }, [spacesRooms]);

  useEffect(() => {
    authRef.current = auth;
  }, [auth]);

  useEffect(() => {
    presenceManager.init();
  }, []);

  useEffect(() => {
    firebase
      .database()
      .ref('.info/connected')
      .on('value', async function (snapshot) {
        if (snapshot.val() === false) {
          if (usage.getCurrentSession()) usage.isOffline = true;
        } else {
          if (usage.isOffline && spacesRoomsRef.current && authRef.current?.isLoggedIn)
            try {
              const currentSession = usage.getCurrentSession();
              await usage.createNewSession(
                spacesRoomsRef.current.currentRoom,
                spacesRoomsRef.current.currentSpace,
                authRef.current.user.id,
                true
              );
              setPresence();
              const dailyInfo = currentSession?.dailyInfo;
              if (dailyInfo) {
                await usage.setDailyInfo(dailyInfo.sessionID, dailyInfo.participantID);
              }
            } catch (error) {
              logger.error('Failed to create usage session on reconnect', {
                errorThrown: error,
              });
            }
        }
      });
  }, []);

  return <div id={'usagePresenceWatcherNew'} />;
};

const setPresence = () => {
  const spacesRooms = getSpacesRoomsState();
  const auth = getAuthState();
  const isRecording = getIsRecordingState().isRecording;
  const reaction = getReactionState().reaction;

  const presenceRef = firebase
    .database()
    .ref(`space/${spacesRooms.currentSpace}/room/${spacesRooms.currentRoom}/presence/${auth.user.id}`);

  presenceRef
    .update({
      name: auth.userDetails.name.length < 1 ? auth.user.name : auth.userDetails.name,
      version: 'v2',
      profilePic: auth.userDetails.profilePic,
      email: auth.user.email,
      userID: auth.user.id,
      permission: {
        permission: spacesRooms.rooms[spacesRooms.currentRoom]?.permission || {},
        role: spacesRooms.currentUserRole,
      },
      timestamp: new Date().getTime(),
      isRecording,
      reaction: reaction
        ? {
            type: reaction.type ? reaction.type : 'text',
            payload: {
              text: reaction.payload.text ? reaction.payload.text : '',
              url: reaction.payload.url ? reaction.payload.url : '',
            },
            sentTime: reaction.sentTime ? reaction.sentTime : 0,
          }
        : {},
      roomCopy: spacesRooms.currentRoomCopy,
      desc: auth.userDetails.desc ? auth.userDetails.desc : '',
      status: auth.userDetails.status ? auth.userDetails.status : '',
      socialProfiles: auth.userDetails.socialProfiles
        ? {
            twitter: auth.userDetails.socialProfiles.twitter ? auth.userDetails.socialProfiles.twitter : '',
            facebook: auth.userDetails.socialProfiles.facebook ? auth.userDetails.socialProfiles.facebook : '',
            linkedin: auth.userDetails.socialProfiles.linkedin ? auth.userDetails.socialProfiles.linkedin : '',
            phone: auth.userDetails.socialProfiles.phone ? auth.userDetails.socialProfiles.phone : '',
            email: auth.userDetails.socialProfiles.email ? auth.userDetails.socialProfiles.email : '',
            link: auth.userDetails.socialProfiles.link ? auth.userDetails.socialProfiles.link : '',
          }
        : { twitter: '', facebook: '', linkedin: '', phone: '', email: '', link: '' },
    })
    .catch((e) => {
      logger.error('Presence watcher error occurred in setting presenceRef:', e);
    });
};

const PresenceWatcher = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  const auth = useSelector(getAuth);
  const isRecording = useSelector(getIsRecordingRedux);
  const reaction = useSelector(getReactionRedux);
  const joined = useSelector(getDailyJoinedState);

  useEffect(() => {
    if (
      !spacesRooms.currentRoom ||
      spacesRooms.currentRoom?.length < 1 ||
      spacesRooms.currentSpace?.length < 1 ||
      !auth.isLoggedIn
    )
      return;
    const sessionID = v4();
    const updateAnalytics = () => {
      firebase
        .firestore()
        .collection('analytics')
        .doc(`${sessionID}`)
        .update({
          spaceID: spacesRooms.currentSpace,
          roomID: spacesRooms.currentRoom,
          roomCopy: spacesRooms.currentRoomCopy,
          userID: auth.user.id,
          till: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .catch((e) => {
          firebase
            .firestore()
            .collection('analytics')
            .doc(`${sessionID}`)
            .set({
              spaceID: spacesRooms.currentSpace,
              roomID: spacesRooms.currentRoom,
              userID: auth.user.id,
              roomCopy: spacesRooms.currentRoomCopy,
              from: firebase.firestore.FieldValue.serverTimestamp(),
              till: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .catch((e) => {
              // console.error(e);
            });
        });
    };
    // updateAnalytics();
    // const interval = setInterval(() => {
    //   updateAnalytics();
    // }, 60000);
    return () => {
      // clearInterval(interval);
      firebase
        .firestore()
        .collection('analytics')
        .doc(`${sessionID}`)
        .update({
          spaceID: spacesRooms.currentSpace,
          roomID: spacesRooms.currentRoom,
          roomCopy: spacesRooms.currentRoomCopy,
          userID: auth.user.id,
          till: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .catch((e) => {
          // console.error('Error', e);
        });
    };
  }, [spacesRooms.currentSpace, spacesRooms.currentRoom, spacesRooms.currentRoomCopy, auth]);

  useEffect(() => {
    if (spacesRooms.currentRoom?.length < 1 || spacesRooms.currentSpace?.length < 1 || !auth.isLoggedIn) return;
    setPresence();
    // const interval = setInterval(() => {
    //   setPresence();
    // }, 300000);

    return () => {};
  }, [
    spacesRooms.currentSpace,
    spacesRooms.currentRoom,
    auth,
    spacesRooms.currentUserRole,
    spacesRooms.rooms[spacesRooms.currentRoom]?.permission,
    spacesRooms.currentRoomCopy,
    isRecording,
    reaction?.type,
    reaction?.sentTime,
    joined,
  ]);
  return <></>;
};

const RoomCopySelector = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  const presenceOfRoom = useSelector(getPresenceOfRoom(spacesRooms.currentRoom));
  const [setRoomCopyFlag, setSetRoomCopyFlag] = useState(false);

  useEffect(() => {
    if (!setRoomCopyFlag) setRoomCopyLoading(true);
    else setRoomCopyLoading(false);
  }, [setRoomCopyFlag]);

  useEffect(() => {}, [spacesRooms.roomCopyLoading]);

  useEffect(() => {
    setSetRoomCopyFlag(false);
  }, [spacesRooms.currentRoom]);

  useEffect(() => {
    if (!presenceOfRoom) {
      return;
    }
    if (!spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails.isOverflow) {
      setSetRoomCopyFlag(true);
      return;
    }
    if (setRoomCopyFlag) {
      return;
    }
    const spillOverLimit_ = spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.spillOverAt || spillOverLimit;
    const roomCopies: { [roomCopy: string]: number } = {};
    let maxRoomCopy = 0;
    let createNew = true;
    let noOnline = true;
    for (let roomCopy in presenceOfRoom) {
      const presence = presenceOfRoom[roomCopy];
      const online = _.filter(presence, { online: true }).length;
      if (online > 0) noOnline = false;
      roomCopies[roomCopy] = online;
      const roomCopyInt = parseInt(roomCopy);
      if (online < spillOverLimit_) {
        setCurrentRoomCopy(roomCopyInt);
        setSetRoomCopyFlag(true);
        return;
      }
      if (roomCopyInt > maxRoomCopy) maxRoomCopy = roomCopyInt;
    }
    if (noOnline) {
      setCurrentRoomCopy(0);
      setSetRoomCopyFlag(true);
      return;
    }
    if (createNew) {
      setCurrentRoomCopy(maxRoomCopy + 1);
      setSetRoomCopyFlag(true);
      return;
    }
    let selectedRoom = 0;
    for (let roomCopyNum in roomCopies) {
      const online = roomCopies[roomCopyNum];
      if (online < spillOverLimit_) {
        selectedRoom = parseInt(roomCopyNum);
        setCurrentRoomCopy(selectedRoom);
        setSetRoomCopyFlag(true);
        return;
      }
    }
    setCurrentRoomCopy(maxRoomCopy + 1);
    setSetRoomCopyFlag(true);
    return;
  }, [presenceOfRoom, spacesRooms.currentRoom, setRoomCopyFlag]);

  return <></>;
};

const FirebaseWatcherV2 = () => {
  // const broadcastPage = window.location.href.includes('/broadcastPage?');
  return (
    <div className="fwv2">
      <RoomSpacesWatcher />
      <ChatWatcher />
      <ProfileWatcher />
      <WallpaperWatcherEle />
      <BroadcastWatcher />
      <RestrictedZoneListener />
      <UsagePresenceWatcherNew />
      <PresenceWatcher />
      <LoginListener />
      <RoomCopySelector />
    </div>
  );
};

export default FirebaseWatcherV2;
