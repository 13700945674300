import { configureStore, createSlice } from '@reduxjs/toolkit';

export const recordingSlice = createSlice({
  name: 'recording',
  initialState: {
    isRecording: false,
  },
  reducers: {
    setIsRecording: (state, action) => {
      state.isRecording = action.payload;
    },
  },
});

export const recordingStore = configureStore({
  reducer: recordingSlice.reducer,
  devTools: true,
});

export const getIsRecordingRedux = (globalState: { recording: ReturnType<typeof recordingStore.getState> }) =>
  globalState.recording.isRecording;
