import firebase from 'firebase';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { MoreHorizontal, Search } from 'react-feather';
import { useSelector } from 'react-redux';
import { getClaimToken } from '../../../helper/firebase/auth';
import { setUserRole } from '../../../helper/interactive/firebaseRTD';
import rooms from '../../../helper/requests/rooms';
import spaces from '../../../helper/requests/spaces';
import { getAuthState } from '../../../store';
import { getAuth } from '../../../store/auth';
import { getPresenceAll } from '../../../store/presence';
import { getSpacesRooms, roomType, userRoleType } from '../../../store/spacesRooms';
import { useOutsideEvent } from '../../interactive/giphy/Giphy';
import LoaderV2 from '../../loader/loaderV2';

const MemberV2 = (props: {
  name: string;
  id: string;
  role: 'h' | 's' | 'a';
  type: 'admin' | 'guest' | 'team';
  userID: string;
  roomID: string;
  spaceID: string;
  isOnline: boolean;
}) => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);
  const typeRef = useRef<HTMLDivElement | null>(null);
  const moreRef = useRef<HTMLDivElement | null>(null);
  const spacesRooms = useSelector(getSpacesRooms);
  const [loader, setLoader] = useState(false);
  const isSpaceOwner = spacesRooms.spaces[spacesRooms.currentSpace]?.permission?.isOwner;
  const auth = useSelector(getAuth);
  const sanitizeClass = (className: string) => {
    return 'class' + className;
  };

  useOutsideEvent(moreRef, () => setOptionsOpen(false), '<>', '.' + sanitizeClass(props.id + '_more'));
  useOutsideEvent(typeRef, () => setTypeOpen(false), '<>', '.' + sanitizeClass(props.id + '_changetype'));

  return (
    <div className="member" key={props.id}>
      <div className="left">
        <div className={props.isOnline ? 'online' : 'offline'}></div>
        <div className="nameType">
          <div className="name">{props.name}</div>
          <div className="type">
            <div
              className={'openType ' + sanitizeClass(props.id + '_changetype')}
              onClick={() => {
                if (props.userID !== auth.user.id) setTypeOpen(!typeOpen);
                else setTypeOpen(false);
              }}
            >
              {props.type === 'team' || props.type === 'admin' ? 'Team member' : ''}
              {props.type === 'guest' ? 'Guest' : ''}
              {props.userID !== auth.user.id && isSpaceOwner && (
                <i className={'ri-arrow-down-s-line ' + sanitizeClass(props.id + '_changetype')} />
              )}
            </div>
            {isSpaceOwner && (
              <div className="changeType" style={{ display: typeOpen ? 'block' : 'none' }} ref={typeRef}>
                {/* <div
                  className="type"
                  onClick={() => {
                    if (props.type === 'admin') return;
                    getClaimToken().then((token) => {
                      if (token)
                        rooms.updateMemberPermissionRoom(
                          props.roomID,
                          props.spaceID,
                          props.userID,
                          'admin',
                          false,
                          token.token
                        );
                    });
                  }}
                >
                  Room Owner
                </div> */}
                <div
                  className="type"
                  onClick={() => {
                    if (props.type === 'team') return;
                    getClaimToken().then((token) => {
                      if (token) {
                        setLoader(true);
                        setTypeOpen(false);
                        spaces
                          .updatePermission(token.token, 'teammember', props.spaceID, props.userID, false)
                          .finally(() => setLoader(false));
                      }
                    });
                  }}
                >
                  Team Member
                </div>
                <div
                  className="type"
                  onClick={() => {
                    if (props.type === 'guest') return;
                    getClaimToken().then((token) => {
                      setLoader(true);
                      setTypeOpen(false);
                      if (token) {
                        spaces
                          .updatePermission(token.token, 'guest', props.spaceID, props.userID, false)
                          .finally(() => setLoader(false));
                      }
                    });
                  }}
                >
                  Guest
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="right">
        {props.type === 'admin' && <div className="role green">Room Admin</div>}
        {props.type !== 'admin' && props.role === 'h' && <div className="role green">Host</div>}
        {props.role === 's' && <div className="role">Speaker</div>}
        {props.role === 'a' && <div className="role">Audience</div>}
        {props.userID !== auth.user.id ? (
          <div className={'moreMemberOptions ' + sanitizeClass(props.id + '_more')}>
            {!loader ? (
              <MoreHorizontal
                onClick={() => setOptionsOpen(!optionsOpen)}
                className={sanitizeClass(props.id + '_more')}
              />
            ) : (
              <div className="loaderCont">
                <LoaderV2 />
              </div>
            )}
            <div className="memberOption" style={{ display: optionsOpen ? 'block' : 'none' }} ref={moreRef}>
              {props.type !== 'admin' && props.type !== 'guest' ? (
                <div
                  className="type first"
                  onClick={() => {
                    if (props.type === 'admin') return;
                    getClaimToken().then((token) => {
                      if (token) {
                        setLoader(true);
                        setOptionsOpen(false);
                        rooms
                          .updateMemberPermissionRoom(
                            props.roomID,
                            props.spaceID,
                            props.userID,
                            'admin',
                            false,
                            token.token
                          )
                          .finally(() => setLoader(false));
                      }
                    });
                  }}
                >
                  Make Admin
                </div>
              ) : (
                <></>
              )}
              {props.type !== 'admin' && props.role !== 'h' ? (
                <div
                  className="type first"
                  onClick={() => {
                    setLoader(true);
                    setOptionsOpen(false);
                    setUserRole('h', props.userID, props.roomID, props.spaceID, getAuthState().user.id).finally(() =>
                      setLoader(false)
                    );
                  }}
                >
                  Make Host
                </div>
              ) : (
                <></>
              )}
              {props.role !== 's' ? (
                <div
                  className="type"
                  onClick={async () => {
                    setLoader(true);
                    setOptionsOpen(false);
                    if (props.type === 'admin') {
                      const token = await getClaimToken();
                      if (token?.token)
                        await rooms.updateMemberPermissionRoom(
                          props.roomID,
                          props.spaceID,
                          props.userID,
                          'teammember',
                          false,
                          token.token
                        );
                    }
                    setUserRole('s', props.userID, props.roomID, props.spaceID, getAuthState().user.id).finally(() =>
                      setLoader(false)
                    );
                  }}
                >
                  Make Speaker
                </div>
              ) : (
                <></>
              )}
              {props.role !== 'a' ? (
                <div
                  className="type last"
                  onClick={async () => {
                    setLoader(true);
                    setOptionsOpen(false);
                    if (props.type === 'admin') {
                      const token = await getClaimToken();
                      if (token?.token)
                        await rooms.updateMemberPermissionRoom(
                          props.roomID,
                          props.spaceID,
                          props.userID,
                          'teammember',
                          false,
                          token.token
                        );
                    }
                    setUserRole('a', props.userID, props.roomID, props.spaceID, getAuthState().user.id).finally(() =>
                      setLoader(false)
                    );
                  }}
                >
                  Make Audience
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div style={{ width: '37px' }}></div>
        )}
      </div>
    </div>
  );
};
const MembersV2 = (props: { setMembersCount: (num: number) => void }) => {
  const spacesRooms = useSelector(getSpacesRooms);
  const membersRef = useRef<HTMLDivElement | null>(null);
  const [search, setSearch] = useState('');
  const [roleFilter, setRoleFilter] = useState<'all' | 'h' | 's' | 'a'>('all');
  const [typeFilter, setTypeFilter] = useState<'all' | 'admin' | 'guest'>('all');
  const [filterName, setFilterName] = useState('All');
  const searchRef = useRef('');
  const [members, setMembers] = useState<{
    [id: string]: { permission: roomType; userRole: userRoleType };
  }>({});
  const [toShow, setToShow] = useState<typeof members>({});
  const [till, setTill] = useState(10);
  const [filterOpen, setFilterOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const spacePresence = useSelector(getPresenceAll);
  const [userPresence, setUserPresence] = useState<{
    [id: string]: { online: boolean };
  }>({});

  useEffect(() => {
    const presence: {
      [id: string]: { online: boolean };
    } = {};

    for (var room in spacePresence) {
      for (var user in spacePresence[room]) {
        let userInfo = spacePresence[room][user];
        if (userInfo.online) {
          presence[user] = { online: true };
        }
      }
    }
    setUserPresence(presence);
  }, [spacePresence]);

  useEffect(() => {
    filterByNameOrEmail(members);
  }, [roleFilter, typeFilter, members]);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  useEffect(() => {
    let unsub = () => {};
    setLoading(true);
    unsub = firebase
      .firestore()
      .collection('roomPermissionV2')
      .where('roomID', '==', spacesRooms.currentRoom)
      .where('isDeleted', '==', false)
      .onSnapshot((docs) => {
        const membersNew: typeof members = {};
        docs.docs.map((doc) => {
          const room: roomType = doc.data() as roomType;
          let userRole: userRoleType = 'a';
          if (spacesRooms.currentRoomAudience[room.userID]) userRole = 'a';
          else if (spacesRooms.currentRoomSpeakers[room.userID]) userRole = 's';
          else if (spacesRooms.currentRoomHosts[room.userID]) userRole = 'h';
          if (room.permission.isAdmin) userRole = 'h';
          membersNew[room.userID] = {
            permission: room,
            userRole,
          };
        });
        setMembers(membersNew);
        if (!docs.metadata.fromCache) {
          setLoading(false);
          props.setMembersCount(docs.docs.length);
        }
      });
    return () => unsub();
  }, [
    spacesRooms.currentRoom,
    spacesRooms.currentRoomSpeakers,
    spacesRooms.currentRoomHosts,
    spacesRooms.currentRoomAudience,
  ]);

  const filterByNameOrEmail = (memberDict: typeof members) => {
    const search = searchRef.current;
    if (search.length < 1) setToShow(memberDict);
    const memberList = _.values(memberDict);
    const nameList = _.filter(memberList, (member) => {
      return (
        member.permission.userInfo.name.includes(search) &&
        (roleFilter === 'all' || member.userRole === roleFilter) &&
        (typeFilter === 'all' ||
          (typeFilter === 'admin' && member.permission.permission.isAdmin) ||
          (typeFilter === 'guest' && member.permission.permission.isGuest))
      );
    });
    const emailList = _.filter(memberList, (member) => {
      return (
        member.permission.userInfo.email.includes(search) &&
        (roleFilter === 'all' || member.userRole === roleFilter) &&
        (typeFilter === 'all' ||
          (typeFilter === 'admin' && member.permission.permission.isAdmin) ||
          (typeFilter === 'guest' && member.permission.permission.isGuest))
      );
    });
    const result = [...nameList, ...emailList];
    const resultDict: typeof members = {};
    for (let i in result) {
      resultDict[result[i].permission.userID] = result[i];
    }
    setToShow(resultDict);
  };

  return (
    <div
      className="memberList"
      ref={membersRef}
      onScroll={() => {
        if (membersRef.current) {
          if (membersRef.current.scrollHeight - membersRef.current.scrollTop >= membersRef.current.clientHeight)
            setTill((till) => till + 10);
        }
      }}
    >
      <div className="searchBarRooms">
        <input
          placeholder="Search by name or email"
          value={search}
          onChange={(e) => {
            const str = e.target.value;
            setSearch(str);
            if (str.length < 1) filterByNameOrEmail(members);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              filterByNameOrEmail(members);
            }
          }}
        />
        <Search
          onClick={() => {
            filterByNameOrEmail(members);
          }}
        />
      </div>
      <div className="filter">
        <div className="openFilter" onClick={() => setFilterOpen(!filterOpen)}>
          {filterName}
          <i className="ri-arrow-down-s-line" />
        </div>
        <div className="filterType" style={{ display: filterOpen ? 'flex' : 'none' }}>
          <div
            className="type first"
            onClick={() => {
              setTypeFilter('all');
              setRoleFilter('all');
              setFilterName('All');
              setFilterOpen(false);
            }}
          >
            All
          </div>
          <div
            className="type"
            onClick={() => {
              setTypeFilter('all');
              setRoleFilter('h');
              setFilterName('Hosts');
              setFilterOpen(false);
            }}
          >
            Hosts
          </div>
          <div
            className="type"
            onClick={() => {
              setTypeFilter('all');
              setRoleFilter('s');
              setFilterName('Speakers');
              setFilterOpen(false);
            }}
          >
            Speakers
          </div>
          <div
            className="type"
            onClick={() => {
              setTypeFilter('admin');
              setRoleFilter('all');
              setFilterName('Room Owners');
              setFilterOpen(false);
            }}
          >
            Room Owners
          </div>
          <div
            className="type last"
            onClick={() => {
              setTypeFilter('guest');
              setRoleFilter('all');
              setFilterName('Guests');
              setFilterOpen(false);
            }}
          >
            Guests
          </div>
        </div>
      </div>
      {loading && (
        <div className="loaderV2Container">
          <LoaderV2 />
        </div>
      )}
      {!loading && (
        <div className="members">
          {_.map(_.keys(toShow).slice(0, till), (key) => {
            const member = members[key];
            if (!member) return <></>;
            let type: 'admin' | 'guest' | 'team' = 'guest';
            if (member?.permission?.permission?.isTeamMember) type = 'team';
            if (member?.permission?.permission?.isAdmin) type = 'admin';
            return (
              <>
                <MemberV2
                  name={member.permission.userInfo.name}
                  id={member.permission.userID}
                  role={member.userRole}
                  type={type}
                  userID={member.permission.userID}
                  roomID={member.permission.roomID}
                  spaceID={member.permission.spaceID}
                  isOnline={userPresence[member.permission.userID]?.online === true}
                  key={member.permission.userID}
                />
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MembersV2;
