import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { sendAmplitudeData } from '../../helper/amplitude';
import { getToken } from '../../helper/firebase/auth';
import { getFrontendURL, isSubDomain } from '../../helper/interactive/misc';
import spaces from '../../helper/requests/spaces';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getGetModalState } from '../../store/modal';
import { getSpacesRooms } from '../../store/spacesRooms';
import { ModalStyle } from '../modal/modal';
import './inviteAdmin.scss';
export const inviteAdminID = 'invite-guest';
export const openInvite = openModalThunk(inviteAdminID);
export const closeInvite = closeModalThunk(inviteAdminID);

const InviteAdmin = () => {
  useEffect(() => {
    newModalThunk(inviteAdminID, false)();
  }, []);
  const state = useSelector(getGetModalState(inviteAdminID));
  const spacesRooms = useSelector(getSpacesRooms);
  const [inviteURL, setInviteURL] = useState<{
    ownerLink: string | undefined;
    teamLink: string | undefined;
    guestLink: string | undefined;
  }>({ ownerLink: undefined, teamLink: undefined, guestLink: undefined });
  const [copied, setCopied] = useState('');

  useEffect(() => {
    (async () => {
      const token = await getToken();
      if (token) {
        const spaceLinks = await spaces.getSpaceInviteLinks(spacesRooms.currentSpace, token);
        if (spaceLinks.data.ownerLink) {
          setInviteURL({
            ownerLink: `${getFrontendURL()}/invite/${spaceLinks.data.ownerLink}?spaceID=${spacesRooms.currentSpace}`,
            teamLink: `${getFrontendURL()}/invite/${spaceLinks.data.teamLink}?spaceID=${spacesRooms.currentSpace}`,
            guestLink: !isSubDomain()
              ? `${getFrontendURL()}/s/${spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID}`
              : `${getFrontendURL()}`,
          });
        } else if (spaceLinks.data.teamLink) {
          setInviteURL({
            ownerLink: undefined,
            teamLink: `${getFrontendURL()}/invite/${spaceLinks.data.teamLink}?spaceID=${spacesRooms.currentSpace}`,
            guestLink: !isSubDomain()
              ? `${getFrontendURL()}/s/${spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID}`
              : `${getFrontendURL()}`,
          });
        }
      }
    })();
  }, [spacesRooms.currentSpace]);

  const copy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    setCopied('Copied!');
    setTimeout(() => setCopied(''), 1500);
  };

  return (
    <>
      <ReactModal isOpen={state} id={inviteAdminID} contentLabel={`${inviteAdminID} Modal`} style={ModalStyle}>
        <>
          <div className="body">
            <div className="header">
              Invite people to {spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceName}
            </div>
            {inviteURL.ownerLink && (
              <>
                <div className="sub-header">Invite a space owner</div>
                <div className="link">
                  <input value={inviteURL.ownerLink} readOnly={true} />
                  <div
                    className="button"
                    onClick={() => {
                      copy(inviteURL.ownerLink ? inviteURL.ownerLink : '');
                      sendAmplitudeData('Invite link copied', { type: 'admin' });
                    }}
                  >
                    Copy
                  </div>
                </div>
              </>
            )}
            {inviteURL.teamLink && (
              <>
                <div className="sub-header">Invite a team member</div>
                <div className="link">
                  <input value={inviteURL.teamLink} readOnly={true} />
                  <div
                    className="button"
                    onClick={() => {
                      copy(inviteURL.teamLink ? inviteURL.teamLink : '');
                      sendAmplitudeData('Invite link copied', { type: 'team' });
                    }}
                  >
                    Copy
                  </div>
                </div>
              </>
            )}
            {inviteURL.guestLink && (
              <>
                <div className="sub-header">Invite a guest</div>
                <div className="link">
                  <input value={inviteURL.guestLink} readOnly={true} />
                  <div
                    className="button"
                    onClick={() => {
                      copy(inviteURL.guestLink ? inviteURL.guestLink : '');
                      sendAmplitudeData('Invite link copied', { type: 'guest' });
                    }}
                  >
                    Copy
                  </div>
                </div>
              </>
            )}
            <div className="bottom btn-container">
              <div className="button" onClick={() => closeInvite()}>
                Done
              </div>
            </div>
            <div className="bottom">
              <div className="success-message">{copied}</div>
            </div>
          </div>
        </>
      </ReactModal>
    </>
  );
};

export default InviteAdmin;
