import firebase from 'firebase';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ExcaliDraw } from '../../components/interactive/whiteboard/ExcaliDraw';
import { ReslashAppsProvider } from '../../components/interactive/whiteboard/provider/ReslashAppsProvider';
import { getAuthState } from '../../store';

const WhiteBoardPage = () => {
  const { spaceID, roomID, whiteboardID } = useParams<{ spaceID: string; roomID: string; whiteboardID: string }>();

  return (
    <div className="whiteboard-container">
      <ReslashAppsProvider firebase={firebase}>
        <ExcaliDraw
          spaceId={spaceID}
          roomId={roomID}
          appId={whiteboardID}
          userId={getAuthState().user.id}
          x={0}
          y={0}
        />
      </ReslashAppsProvider>
    </div>
  );
};

export default WhiteBoardPage;
