import { createSlice } from '@reduxjs/toolkit';
import { maxParticipants, spillOverLimit } from '../config';

const initialState: {
  step: 'none' | 'create' | 'background';
  name: string;
  type: 'public' | 'team' | 'private';
  password: string;
  roomType: 'spatial' | 'dynamic';
  spillOverAt: number;
  limit: number;
  startTime: number;
  endTime: number;
  timeZone: {
    name: string;
    offset: number;
  };
  emails: string[];
} = {
  step: 'none',
  name: '',
  type: 'public',
  password: '',
  roomType: 'spatial',
  spillOverAt: spillOverLimit,
  limit: maxParticipants,
  startTime: -1,
  endTime: -1,
  timeZone: {
    name: '',
    offset: 0,
  },
  emails: [],
};

export const createRoomFlowSlice = createSlice({
  name: 'createRoomFlow',
  initialState,
  reducers: {
    setStep(state, action) {
      state.step = action.payload;
    },
    setAdvanceParams(state, action) {
      state.password = action.payload.password;
      state.roomType = action.payload.roomType;
      state.spillOverAt = action.payload.spillOverAt;
      state.limit = action.payload.limit;
      state.startTime = action.payload.startTime;
      state.endTime = action.payload.endTime;
      state.timeZone = action.payload.timeZone;
    },
    setName(state, action) {
      state.name = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    setEmails(state, action) {
      state.emails = action.payload;
    },
  },
});

export const getCreateRoomParamsRedux = (globalState: { createRoomFlow: typeof initialState }) =>
  globalState.createRoomFlow;
