import { getZoomFromStore } from '../../../../store';

type boundsType = { left: number; right: number; top: number; bottom: number };
type position = { x: number; y: number };
type eventObj = { newPos: position; delta: position };
type eventTypes = 'onDragStart' | 'onDrag' | 'onDragEnd';

function removeItemAll<T>(arr: T[], value: T) {
  let i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

export default class SimpleDraggable {
  target: HTMLElement;
  className: string;
  bounds?: boundsType;
  listeners: { [id in eventTypes]: ((e: eventObj) => void)[] };
  draggingInfo: {
    isDragging: boolean;
    startPos: position;
    startCurrentPos: position;
  };
  isDraggable: boolean;
  currentPos: position;
  lastTouch?: TouchList;

  constructor(target: HTMLElement, isDraggable: boolean, className?: string, bounds?: boundsType) {
    this.target = target;
    this.bounds = bounds;
    this.className = className ?? 'simple-draggable';
    this.listeners = {
      onDrag: [],
      onDragEnd: [],
      onDragStart: [],
    };
    this.draggingInfo = {
      isDragging: false,
      startPos: { x: 0, y: 0 },
      startCurrentPos: { x: 0, y: 0 },
    };
    this.isDraggable = isDraggable;
    this.currentPos = { x: 0, y: 0 };
  }

  addEventListener(event: eventTypes, callback: (e: eventObj) => void) {
    this.listeners[event].push(callback);
  }

  removeEventListener(event: eventTypes, callback: (e: eventObj) => void) {
    removeItemAll(this.listeners[event], callback);
  }

  currentPositionUpdate(pos: position) {
    this.currentPos = pos;
  }

  getCurrentZoom() {
    return getZoomFromStore();
  }

  onDragStart(startPos: position) {
    if (!this.isDraggable) return;
    this.draggingInfo = {
      isDragging: true,
      startPos: startPos,
      startCurrentPos: this.currentPos,
    };
    for (const callback of this.listeners['onDragStart']) {
      callback({ newPos: startPos, delta: { x: 0, y: 0 } });
    }
  }

  onDragEnd(endPos: position) {
    if (!this.isDraggable) return;
    this.draggingInfo = {
      isDragging: false,
      startPos: { x: 0, y: 0 },
      startCurrentPos: this.currentPos,
    };
    for (const callback of this.listeners['onDragEnd']) {
      callback({ newPos: endPos, delta: { x: 0, y: 0 } });
    }
  }

  onDrag(clickPos: position) {
    if (!this.isDraggable) return;
    if (!this.draggingInfo.isDragging) {
      return;
    }
    const zoom = this.getCurrentZoom();
    const newPos = {
      x: this.draggingInfo.startCurrentPos.x + (clickPos.x - this.draggingInfo.startPos.x) / zoom,
      y: this.draggingInfo.startCurrentPos.y + (clickPos.y - this.draggingInfo.startPos.y) / zoom,
    };
    const delta = { x: newPos.x - this.draggingInfo.startPos.x, y: newPos.y - this.draggingInfo.startPos.y };
    if (this.bounds) {
      if (newPos.x < this.bounds.left) newPos.x = this.bounds.left;
      if (newPos.x > this.bounds.right) newPos.x = this.bounds.right;
      if (newPos.y < this.bounds.top) newPos.y = this.bounds.top;
      if (newPos.y > this.bounds.bottom) newPos.y = this.bounds.bottom;
    }
    for (const callback of this.listeners['onDrag']) {
      callback({
        newPos,
        delta,
      });
    }
  }

  initListeners() {
    const onMouseDown = (e: MouseEvent) => {
      this.onDragStart({ x: e.pageX, y: e.pageY });
    };

    const onMouseUp = (e: MouseEvent) => {
      this.onDragEnd({ x: e.pageX, y: e.pageY });
    };

    const onMouseMove = (e: MouseEvent) => {
      this.onDrag({ x: e.pageX, y: e.pageY });
    };

    const onTouchStart = (e: TouchEvent) => {
      this.lastTouch = e.touches;
      this.onDragStart({ x: e.touches[0].pageX, y: e.touches[0].pageY });
    };

    const onTouchMove = (e: TouchEvent) => {
      this.lastTouch = e.touches;
      this.onDrag({ x: e.touches[0].pageX, y: e.touches[0].pageY });
    };

    const onTouchEnd = (e: TouchEvent) => {
      if (e.changedTouches) {
        this.lastTouch = e.changedTouches;
      }
      this.onDragEnd({
        x: this.lastTouch ? this.lastTouch[0].pageX : 0,
        y: this.lastTouch ? this.lastTouch[0].pageY : 0,
      });
    };

    this.target.addEventListener('mousedown', onMouseDown);
    this.target.addEventListener('touchstart', onTouchStart);

    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousemove', onMouseMove);

    document.addEventListener('touchend', onTouchEnd);
    document.addEventListener('touchmove', onTouchMove);

    return () => {
      this.target.removeEventListener('mousedown', onMouseDown);
      this.target.removeEventListener('touchstart', onTouchStart);

      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);

      document.removeEventListener('touchend', onTouchEnd);
      document.removeEventListener('touchmove', onTouchMove);
    };
  }
}
