import React, { useEffect, useState } from 'react';
import { CircleFill, EnvelopeFill, Facebook, Link, Linkedin, TelephoneFill, Twitter } from 'react-bootstrap-icons';
import { ChevronDown, ChevronUp, Edit2 } from 'react-feather';
import { useSelector } from 'react-redux';
import { getClaimToken } from '../../../../helper/firebase/auth';
import {
  setKicked,
  setMicMuted,
  setUserRole,
  setVideoMuted,
  toggleMegaphone,
  watchMegaphoneForOneUser,
} from '../../../../helper/interactive/firebaseRTD';
import { formatLong } from '../../../../helper/interactive/misc';
import rooms from '../../../../helper/requests/rooms';
import { getAuth } from '../../../../store/auth';
import { getUserPresence } from '../../../../store/presence';
import {
  getCurrentRoomPermissionV2Redux,
  getCurrentUserRoleRedux,
  getSpacesRooms,
  userRoleType,
} from '../../../../store/spacesRooms';
import LoaderRing from '../../../loader/loaderRing';
import { openProfileEditModal } from '../../../modals/templateModals/profileModal';
import { openConfirmAction } from '../../../roomSettings/confirmAction';
import './nameTag.scss';

const NameTagSettingsDD = (props: {
  id: string;
  canKickOrMute: boolean;
  currentRole: {
    isAdmin: boolean;
    isTeamMember: boolean;
    isGuest: boolean;
  };
  currentUserRole: userRoleType;
  userRole: userRoleType | undefined;
  isAdmin: boolean | undefined;
  isTeam: boolean | undefined;
}) => {
  const [hostSettingLoader, setHostSettingLoader] = useState(false);
  const [adminSettingLoader, setAdminSettingLoader] = useState(false);
  const [speakerSettingLoader, setSpeakerSettingLoader] = useState(false);
  const [audienceSettingLoader, setAudienceSettingLoader] = useState(false);
  const [micSettingLoader, setMicSettingLoader] = useState(false);
  const [camSettingLoader, setCamSettingLoader] = useState(false);
  const [kickSettingLoader, setKickSettingLoader] = useState(false);
  const [megaphoneLoader, setMegaphoneLoader] = useState(false);
  const [muteAudioForMeSettingLoader, setMuteAudioForMeSettingLoader] = useState(false);
  const [hideVideoForMeSettingLoader, setHideVideoForMeSettingLoader] = useState(false);

  const spacesRooms = useSelector(getSpacesRooms);
  const auth = useSelector(getAuth);
  const [isMegaphoneEquipped, setMegaphoneEquipped] = useState(false);

  useEffect(() => {
    const unsub = watchMegaphoneForOneUser(spacesRooms.currentSpace, spacesRooms.currentRoom, props.id, (flag) => {
      setMegaphoneEquipped(flag === true);
    });
    return unsub;
  }, [spacesRooms.currentSpace, spacesRooms.currentRoom, props.id]);

  const changePermission = async (permission: 'admin' | 'host' | 'speaker' | 'audience' | 'teammember') => {
    const token = await getClaimToken();
    if (token) {
      if (props.isAdmin && (permission === 'speaker' || permission === 'audience')) {
        await rooms.updateMemberPermissionRoom(
          spacesRooms.currentRoom,
          spacesRooms.currentSpace,
          props.id,
          'teammember',
          false,
          token.token
        );
        if (permission === 'speaker')
          await setUserRole('s', props.id, spacesRooms.currentRoom, spacesRooms.currentSpace, auth.user.id);
        else await setUserRole('a', props.id, spacesRooms.currentRoom, spacesRooms.currentSpace, auth.user.id);
      } else if (permission === 'admin') {
        await rooms.updateMemberPermissionRoom(
          spacesRooms.currentRoom,
          spacesRooms.currentSpace,
          props.id,
          'admin',
          false,
          token.token
        );
        await setUserRole('h', props.id, spacesRooms.currentRoom, spacesRooms.currentSpace, auth.user.id);
      } else if (permission === 'host') {
        await setUserRole('h', props.id, spacesRooms.currentRoom, spacesRooms.currentSpace, auth.user.id);
      } else if (permission === 'audience') {
        await setUserRole('a', props.id, spacesRooms.currentRoom, spacesRooms.currentSpace, auth.user.id);
      } else if (permission === 'speaker') {
        await setUserRole('s', props.id, spacesRooms.currentRoom, spacesRooms.currentSpace, auth.user.id);
      } else if (permission === 'teammember') {
        await rooms.updateMemberPermissionRoom(
          spacesRooms.currentRoom,
          spacesRooms.currentSpace,
          props.id,
          'teammember',
          false,
          token.token
        );
      }
    }
  };

  return (
    <>
      <div className="name-tag-settings-container interactive-scroll">
        {props.currentRole?.isAdmin && props.userRole !== 'h' && !props.isAdmin && (
          <div
            className="name-tag-setting"
            onClick={() => {
              setHostSettingLoader(true);
              changePermission('host')
                .catch()
                .finally(() => setHostSettingLoader(false));
            }}
          >
            <span>Make Host </span>
            {hostSettingLoader && <LoaderRing size={'10'} color={'#34e3b9'} />}
          </div>
        )}

        {props.currentRole?.isAdmin && !props.isAdmin && props.isTeam && (
          <div
            className="name-tag-setting"
            onClick={() => {
              setAdminSettingLoader(true);
              changePermission('admin')
                .catch()
                .finally(() => setAdminSettingLoader(false));
            }}
          >
            <span>Make Room Admin </span>
            {adminSettingLoader && <LoaderRing size={'10'} color={'#34e3b9'} />}
          </div>
        )}

        {props.currentRole?.isAdmin && props.userRole !== 's' && (
          <div
            className="name-tag-setting"
            onClick={() => {
              setSpeakerSettingLoader(true);
              changePermission('speaker')
                .catch()
                .finally(() => setSpeakerSettingLoader(false));
            }}
          >
            <span>Make Speaker </span>
            {speakerSettingLoader && <LoaderRing size={'10'} color={'#34e3b9'} />}
          </div>
        )}

        {props.currentRole?.isAdmin && props.userRole !== 'a' && (
          <div
            className="name-tag-setting"
            onClick={() => {
              setAudienceSettingLoader(true);
              changePermission('audience')
                .catch()
                .finally(() => setAudienceSettingLoader(false));
            }}
          >
            <span>Make Audience </span>
            {audienceSettingLoader && <LoaderRing size={'10'} color={'#34e3b9'} />}
          </div>
        )}

        {props.canKickOrMute && (
          <div
            className="name-tag-setting"
            onClick={() => {
              setMegaphoneLoader(true);
              toggleMegaphone(spacesRooms.currentSpace, spacesRooms.currentRoom, props.id, !isMegaphoneEquipped)
                .catch()
                .finally(() => setMegaphoneLoader(false));
            }}
          >
            <span>{isMegaphoneEquipped ? 'Disable' : 'Enable'} megaphone </span>
            {megaphoneLoader && <LoaderRing size={'10'} color={'#34e3b9'} />}
          </div>
        )}
        {props.canKickOrMute && (
          <div
            className="name-tag-setting"
            onClick={() => {
              setMicSettingLoader(true);
              setMicMuted(props.id, spacesRooms.currentSpace, spacesRooms.currentRoom, true)
                .catch()
                .finally(() => setMicSettingLoader(false));
            }}
          >
            <span>Mute mic </span>
            {micSettingLoader && <LoaderRing size={'10'} color={'#34e3b9'} />}
          </div>
        )}

        {props.canKickOrMute && (
          <div
            className="name-tag-setting"
            onClick={() => {
              setCamSettingLoader(true);
              setVideoMuted(props.id, spacesRooms.currentSpace, spacesRooms.currentRoom, true)
                .catch()
                .finally(() => setCamSettingLoader(false));
            }}
          >
            <span>Turn off camera </span>
            {camSettingLoader && <LoaderRing size={'10'} color={'#34e3b9'} />}
          </div>
        )}

        {props.canKickOrMute && (
          <div
            className="name-tag-setting"
            onClick={() => {
              openConfirmAction(
                () => {
                  setKickSettingLoader(true);
                  setKicked(props.id, spacesRooms.currentSpace, spacesRooms.currentRoom, true)
                    .catch()
                    .finally(() => setKickSettingLoader(false));
                },
                () => {}
              )();
            }}
          >
            <span>Kick out </span>
            {kickSettingLoader && <LoaderRing size={'10'} color={'#34e3b9'} />}
          </div>
        )}

        {/* Uncomment this to show Hide Video and Mute Audio options
         <div
          className="name-tag-setting"
          onClick={() => {
            setMuteAudioForMeSettingLoader(true);
            setMuteAudioForMeSettingLoader(false);
          }}
        >
          <span>Mute audio for me </span>
          {muteAudioForMeSettingLoader && <LoaderRing size={'10'} color={'#34e3b9'} />}
        </div>

        <div
          className="name-tag-setting"
          onClick={() => {
            setHideVideoForMeSettingLoader(true);
            setHideVideoForMeSettingLoader(false);
          }}
        >
          <span>Hide video for me </span>
          {hideVideoForMeSettingLoader && <LoaderRing size={'10'} color={'#34e3b9'} />}
        </div> */}
      </div>
    </>
  );
};

const NewProfileCard = (props: {
  aboutDesc: string;
  socialProfiles: { twitter: string; facebook: string; linkedin: string; phone: string; email: string; link: string };
}) => {
  const auth = useSelector(getAuth);
  return (
    <>
      <div className="new-profile-card-container">
        <div
          className="new-profile-card-about-you-box"
          style={!props.aboutDesc || props.aboutDesc.length === 0 ? { display: 'none' } : {}}
        >
          <div className="input-textarea interactive-scroll">
            <span>{props.aboutDesc}</span>
          </div>
        </div>
        <div className="new-profile-card-icons-box">
          <div className="social-profile-options">
            <div
              className={
                props.socialProfiles && props.socialProfiles.twitter && props.socialProfiles.twitter !== ''
                  ? 'social-option has-v2-tooltip-small'
                  : 'social-option has-v2-tooltip-small disabled'
              }
              data-tooltip-text="Twitter"
              onClick={() => {
                window.open(props.socialProfiles.twitter, '_blank');
              }}
            >
              <Twitter size={18} color={'white'} />
            </div>

            <div
              className={
                props.socialProfiles && props.socialProfiles.linkedin && props.socialProfiles.linkedin !== ''
                  ? 'social-option has-v2-tooltip-small'
                  : 'social-option has-v2-tooltip-small disabled'
              }
              data-tooltip-text="LinkedIn"
              onClick={() => {
                window.open(props.socialProfiles.linkedin, '_blank');
              }}
            >
              <Linkedin size={18} color={'white'} />
            </div>

            <div
              className={
                props.socialProfiles && props.socialProfiles.facebook && props.socialProfiles.facebook !== ''
                  ? 'social-option has-v2-tooltip-small'
                  : 'social-option has-v2-tooltip-small disabled'
              }
              data-tooltip-text="Facebook"
              onClick={() => {
                window.open(props.socialProfiles.facebook, '_blank');
              }}
            >
              <Facebook size={18} color={'white'} />
            </div>

            <div
              className={
                props.socialProfiles && props.socialProfiles.phone && props.socialProfiles.phone !== ''
                  ? 'social-option has-v2-tooltip-small'
                  : 'social-option has-v2-tooltip-small disabled'
              }
              data-tooltip-text="Phone"
              onClick={() => {
                window.open('tel:' + props.socialProfiles.phone, '_blank');
              }}
            >
              <TelephoneFill size={18} color={'white'} />
            </div>

            <div
              className={
                props.socialProfiles && props.socialProfiles.email && props.socialProfiles.email !== ''
                  ? 'social-option has-v2-tooltip-small'
                  : 'social-option has-v2-tooltip-small disabled'
              }
              data-tooltip-text="Email"
              onClick={() => {
                window.open('mailto:' + props.socialProfiles.email, '_blank');
              }}
            >
              <EnvelopeFill size={18} color={'white'} />
            </div>

            <div
              className={
                props.socialProfiles && props.socialProfiles.link && props.socialProfiles.link !== ''
                  ? 'social-option has-v2-tooltip-small'
                  : 'social-option has-v2-tooltip-small disabled'
              }
              data-tooltip-text="Website"
              onClick={() => {
                window.open(props.socialProfiles.link, '_blank');
              }}
            >
              <Link size={18} color={'white'} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NameTag = (props: {
  id: string;
  name: string;
  showNametag: (state: boolean) => void;
  getIsMouseOver: () => boolean;
  setIsMouseOver: (state: boolean) => void;
}) => {
  const [showPermissions, setShowPermissions] = useState(false);
  const [showProfileCard, setShowProfileCard] = useState(false);

  const auth = useSelector(getAuth);
  const spacesRooms = useSelector(getSpacesRooms);
  const id = props.id;
  const currentRole = useSelector(getCurrentRoomPermissionV2Redux(spacesRooms.currentRoom));
  const currentUserRole = useSelector(getCurrentUserRoleRedux);
  const presence = useSelector(getUserPresence(spacesRooms.currentRoom, spacesRooms.currentRoomCopy + '', id));
  const userRole = presence?.permission?.role;
  const isAdmin = presence?.permission?.permission?.isAdmin;
  const isTeam = presence?.permission?.permission?.isTeamMember;
  const aboutDesc = presence?.desc;
  const socialProfiles = presence?.socialProfiles;

  const [openProfileModal, setOpenProfileModal] = useState(false);

  let canKickOrMute = false;
  if (currentRole?.isAdmin) {
    canKickOrMute = true;
  } else if (userRole !== 'h' && currentUserRole === 'h') {
    canKickOrMute = true;
  } else {
    canKickOrMute = false;
  }

  const getHeaderStyle = () => {
    let headerStyle: React.CSSProperties;
    if (showProfileCard) {
      headerStyle = { minWidth: '240px', maxWidth: '240px' };
    } else if (showPermissions) {
      headerStyle = { minWidth: '163px', maxWidth: '163px' };
    } else {
      headerStyle = { maxWidth: '163px' };
    }

    return headerStyle;
  };

  const [availabilityColor, setAvailabilityColor] = useState({ state: 'available', color: '34e3b9' });

  useEffect(() => {
    if (presence && presence.status) {
      if (presence.status === 'Available') setAvailabilityColor({ state: 'available', color: '#34e3b9' });
      else if (presence.status === 'Busy') setAvailabilityColor({ state: 'busy', color: 'red' });
      else if (presence.status === 'Away') setAvailabilityColor({ state: 'away', color: 'orange' });
      else setAvailabilityColor({ state: 'available', color: '#34e3b9' });
    }
  }, [presence?.status]);

  return (
    <>
      <div
        className="name-tag-container"
        style={getHeaderStyle()}
        onMouseEnter={(e) => {
          props.setIsMouseOver(true);
          props.showNametag(true);
        }}
        onMouseLeave={(e) => {
          props.setIsMouseOver(false);
          setTimeout(() => {
            if (!props.getIsMouseOver()) props.showNametag(false);
          }, 1500);
        }}
      >
        <div className="name-tag-header-section">
          <div className="name-tag-status">
            <CircleFill color={availabilityColor.color} size={8} />
          </div>
          <div
            className="name-tag-text"
            onClick={() => {
              setShowProfileCard(!showProfileCard);
              setShowPermissions(false);
            }}
          >
            <span>{showProfileCard ? formatLong(props.name, 16) : formatLong(props.name.split(' ')[0], 10)}</span>
          </div>

          {/* Remove canKickOrMute below whenever you are adding Hide Video, Mute Audio option */}

          {id !== auth.user.id && canKickOrMute && (
            <div
              className="name-tag-settings-dropdown"
              onClick={() => {
                setShowProfileCard(false);
                setShowPermissions(!showPermissions);
              }}
            >
              {!showPermissions && <ChevronDown color={'white'} size={16} />}
              {showPermissions && <ChevronUp color={'white'} size={16} />}
            </div>
          )}

          {showProfileCard && id === auth.user.id && (
            <div
              className="name-tag-settings-edit"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openProfileEditModal();
              }}
            >
              <Edit2 color={'white'} size={12} />
            </div>
          )}
        </div>
        {(showProfileCard || showPermissions) && (
          <div className="name-tag-body-section">
            {showProfileCard && (
              <NewProfileCard
                aboutDesc={aboutDesc ? aboutDesc : ''}
                socialProfiles={
                  socialProfiles
                    ? {
                        twitter: socialProfiles.twitter ? socialProfiles.twitter : '',
                        facebook: socialProfiles.facebook ? socialProfiles.facebook : '',
                        linkedin: socialProfiles.linkedin ? socialProfiles.linkedin : '',
                        phone: socialProfiles.phone ? socialProfiles.phone : '',
                        email: socialProfiles.email ? socialProfiles.email : '',
                        link: socialProfiles.link ? socialProfiles.link : '',
                      }
                    : { twitter: '', facebook: '', linkedin: '', phone: '', email: '', link: '' }
                }
              />
            )}
            {id !== auth.user.id && showPermissions && (
              <NameTagSettingsDD
                id={props.id}
                canKickOrMute={canKickOrMute}
                currentRole={currentRole ?? { isAdmin: false, isTeamMember: false, isGuest: true }}
                currentUserRole={currentUserRole}
                userRole={userRole}
                isAdmin={isAdmin}
                isTeam={isTeam}
              />
            )}
          </div>
        )}
      </div>

      {/* {openProfileModal && (
        <ProfileSetUpModal
          toClose={(e) => {
            setOpenProfileModal(false);
          }}
        />
      )} */}
    </>
  );
};

export default NameTag;
