import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { maxParticipants, spillOverLimit } from '../../config';
import { sendAmplitudeData } from '../../helper/amplitude';
import { getToken } from '../../helper/firebase/auth';
import roomHelper from '../../helper/requests/rooms';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../store';
import { getGetModalState } from '../../store/modal';
import { getSpacesRooms } from '../../store/spacesRooms';
import { ModalStyle } from '../modal/modal';
import './roomSettings.scss';

export const roomDeleteConfirm = 'room-delete-confirm';
export const openRoomDeleteConfirm = openModalThunk(roomDeleteConfirm);
export const closeRoomDeleteConfirm = closeModalThunk(roomDeleteConfirm);

const DeleteConfirm = () => {
  const state = useSelector(getGetModalState(roomDeleteConfirm));
  const spacesRooms = useSelector(getSpacesRooms);
  const [msg, setMsg] = useState<string>('');
  useEffect(() => {
    newModalThunk(roomDeleteConfirm, false)();
  }, []);
  return (
    <ReactModal
      isOpen={state}
      id="roomSettings"
      contentLabel={`${roomDeleteConfirm} Modal`}
      style={ModalStyle}
      shouldCloseOnOverlayClick={true}
    >
      <>
        <div className="body">
          <div className="header hr-container">Are you sure you want to delete this room?</div>
          <div className="bottom btn-container">
            <div className="left"></div>
            <div className="right">
              <div
                className="button"
                onClick={async () => {
                  try {
                    setMsg('deleting...');
                    const token = await getToken();
                    if (token)
                      await roomHelper.edit(
                        spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails.roomName ?? '',
                        token,
                        false,
                        true,
                        true,
                        spacesRooms.currentRoom,
                        spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.isOverflow === true,
                        spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.spillOverAt || spillOverLimit,
                        spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.capacity || maxParticipants,
                        -1,
                        -1,
                        { name: '', offset: 0 }
                      );
                    closeRoomDeleteConfirm();
                    sendAmplitudeData('Room delete', { status: 'success' });
                  } catch (e) {
                    setMsg(JSON.stringify(e.response?.data.error));
                    sendAmplitudeData('Room delete', { status: 'failed', state: e.response?.data.error });
                  }
                }}
              >
                Yes
              </div>
              <div
                className="button red"
                onClick={() => {
                  closeRoomDeleteConfirm();
                  sendAmplitudeData('Room delete', { status: 'cancelled' });
                }}
              >
                No
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="success-message">{msg}</div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default DeleteConfirm;
