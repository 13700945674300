import _ from 'lodash';
import React, { useState } from 'react';
import { LogIn, X } from 'react-feather';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatLong, handleSubdomain } from '../../../helper/interactive/misc';
import rooms from '../../../helper/requests/rooms';
import { getPresenceOfRoom } from '../../../store/presence';
import { getSpacesRooms } from '../../../store/spacesRooms';
import { canvasChild, firebaseCallback } from '../../../types/canvasFB';
import { openClosedRoomModal } from '../../customiseLogin/scheduledRoomModal';
import { openPasswordModal } from '../../modals/passwordModal/passwordModal';
import './portal.scss';

const Portal = (props: {
  watcher: (callback: (c: canvasChild) => void) => firebaseCallback;
  onDataChange: (d: any, path: string) => void;
  spaceID: string;
  roomID: string;
  onDeleteTrigger: Function;
}) => {
  const spacesRooms = useSelector(getSpacesRooms);
  const presenceRoom = useSelector(getPresenceOfRoom(props.roomID));
  const isAdmin = spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin;
  const userRole = spacesRooms.currentUserRole;

  const roomMembers =
    presenceRoom && _.filter(presenceRoom[spacesRooms.currentRoomCopy], { online: true }).length
      ? _.filter(presenceRoom[spacesRooms.currentRoomCopy], { online: true }).length
      : 0;

  let history = useHistory();

  const switchRoom = async (id: string) => {
    const from = spacesRooms.rooms[id]?.roomDetails?.schedule?.from;
    const till = spacesRooms.rooms[id]?.roomDetails?.schedule?.till;
    if ((from && from > new Date().getTime() / 1000) || (till && till !== -1 && till < new Date().getTime() / 1000)) {
      openClosedRoomModal(id, true)();
    } else if (
      spacesRooms.rooms[id]?.roomDetails?.enabledPassword &&
      !spacesRooms.rooms[id]?.permission?.isAdmin &&
      spacesRooms.currentRoom !== id
    ) {
      openPasswordModal(id)();
    } else {
      await rooms.setClaimThenRoom(id);
      if (spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID)
        history.push(handleSubdomain(`/s/${spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID}/r/${id}`));
    }
  };

  function onDeleteTrigger() {
    props.onDeleteTrigger();
  }

  function activatePortal() {
    switchRoom(props.roomID);
  }

  const [showOptionButton, setShowOptionButton] = useState(false);

  return (
    <>
      <div
        className="portal-container open-pass-modal"
        onMouseEnter={(e) => {
          setShowOptionButton(true);
        }}
        onMouseLeave={(e) => {
          setShowOptionButton(false);
        }}
      >
        <div className="portal-room-img portal-activation" onClick={activatePortal}>
          <img src="https://s3.us-west-1.wasabisys.com/reslashco-assets/portal/portal-1.jpg" />
        </div>
        <div className="portal-room-section">
          <div className="portal-room-name portal-activation" onClick={activatePortal}>
            {spacesRooms.rooms[props.roomID] &&
              formatLong((spacesRooms.rooms[props.roomID]?.roomDetails?.roomName ?? '').toUpperCase(), 10)}
          </div>
          <div className="portal-room-enter" onClick={activatePortal}>
            <LogIn size={16} />
          </div>
        </div>

        <div className="portal-room-members">
          {roomMembers > 1 ? roomMembers + ' members' : roomMembers + ' member'}
        </div>
        {(isAdmin || userRole === 'h') && (
          <div className="portal-options" style={{ visibility: showOptionButton ? 'visible' : 'hidden' }}>
            <div className="portal-icon" onClick={onDeleteTrigger}>
              <X />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Portal;
