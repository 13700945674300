import ReactDOM from 'react-dom';
import './switcherModal.scss';

const RoomSwitcherModal = (props: {
  children: JSX.Element;
  isOpen: boolean;
  switcherRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  if (!props.isOpen) return null;

  const eventModal = document.getElementById('eventmodal');
  if (!eventModal) return null;

  return ReactDOM.createPortal(
    <>
      <div className="switcher-room-overlay"></div>
      <div className="switcher-room-modal" id="switcher-room-modal" ref={props.switcherRef}>
        {props.children}
      </div>
    </>,
    eventModal
  );
};

export default RoomSwitcherModal;
