import { presenceObjRef, setPresenceFlagInRTD } from '../interactive/firebaseRTD';
import Session from './session';
import usageObj, { Usage } from './usage';

export class PresenceManager {
  private readonly usage: Usage;
  private roomID: string;
  private spaceID: string;
  private userID: string;
  private isOnlineIsAnyRoom: boolean;

  constructor() {
    this.usage = usageObj;
    this.roomID = '';
    this.spaceID = '';
    this.userID = '';
    this.isOnlineIsAnyRoom = false;
  }

  init() {
    this.usage.onNewSession((sessionID, session, oldSessions) => {
      this.newSessionListener(sessionID, session, oldSessions).catch(() => {});
    });
  }

  private async newSessionListener(_sessionID: string, session: Session, oldSessions: Session[] | undefined) {
    if (oldSessions)
      for (const oldSession of oldSessions) {
        if (oldSession) {
          await this.endPresenceInCurrentRoom(oldSession);
        }
      }
    this.roomID = session.roomID;
    this.spaceID = session.spaceID;
    this.userID = session.userID;
    await setPresenceFlagInRTD(this.spaceID, this.roomID, this.userID, true);
    this.isOnlineIsAnyRoom = true;
    await presenceObjRef(this.spaceID, this.roomID, this.userID).onDisconnect().remove();
  }

  async endPresenceInCurrentRoom(session: Session) {
    await setPresenceFlagInRTD(session.spaceID, session.roomID, session.userID, false);
    await presenceObjRef(session.spaceID, session.roomID, session.userID).onDisconnect().cancel();
    this.isOnlineIsAnyRoom = false;
  }
}

export default new PresenceManager();
