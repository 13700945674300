import { ImageObject } from '@azure/cognitiveservices-imagesearch/esm/models';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { IGif } from '@giphy/js-types';
import { Grid, SearchBar, SearchContext, SearchContextManager } from '@giphy/react-components';
import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { sendAmplitudeData } from '../../../helper/amplitude';
import { getToken } from '../../../helper/firebase/auth';
import { addNewCanvasChild } from '../../../helper/interactive/firebaseRTD';
import { getSpawnLocation } from '../../../helper/interactive/misc';
import imagesearch from '../../../helper/requests/imagesearch';
import { getAuthState, getListenerPosition } from '../../../store';
import './Giphy.scss';
const giphyKey = 'kH3yISsqmBNVWPWGL8KFW95FHDTDw4Ha';

export function useOutsideEvent(
  ref: React.MutableRefObject<HTMLDivElement | null>,
  doSomething: () => void,
  ignore = '<>',
  ignoreSelector = '.noneselector'
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.id.includes(ignore) &&
        !event.target.closest(ignoreSelector)
      ) {
        doSomething();
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref]);
}

const Giphy = (props: { canvasID: string; spaceID: string; toClose: () => void }) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  useOutsideEvent(wrapperRef, props.toClose, 'gif_btn');
  return (
    <div ref={wrapperRef}>
      <div className="attribution">
        <img src={'/images/giphyAttribution.gif'} alt="Powered by Giphy" />
      </div>
      <SearchContextManager apiKey={giphyKey} initialTerm="">
        <GiphyComp canvasID={props.canvasID} spaceID={props.spaceID} toClose={props.toClose} />
      </SearchContextManager>
    </div>
  );
};

const ImageSearchComp = (props: { canvasID: string; spaceID: string; toClose: () => void }) => {
  const [query, setQuery] = useState('');
  const [images, setImages] = useState<ImageObject[]>([]);
  const offset = useRef(0);
  const finish = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const currentQuery = useRef('');

  const addImage = (img: ImageObject, e: React.SyntheticEvent<HTMLElement, Event>) => {
    const spawnLocation = getSpawnLocation(getListenerPosition(), 300);
    addNewCanvasChild(props.spaceID, props.canvasID, {
      w: 300,
      h: 300 * (img.width && img.height ? img.height / img.width : 1),
      x: spawnLocation.x,
      y: spawnLocation.y,
      t: 'gif',
      d: { u: img.contentUrl, uid: getAuthState().user.id },
    }).then(props.toClose);
    e.preventDefault();

    sendAmplitudeData('Gif added', {
      url: img.contentUrl,
      searchTerm: query,
      type: 'bing image',
    });
  };

  const search = async () => {
    if (query.length === 0) return;
    if (query !== currentQuery.current) {
      currentQuery.current = query;
      offset.current = 0;
      finish.current = false;
      setImages([]);
    }
    if (finish.current) return;
    setIsLoading(true);
    try {
      const token = await getToken();
      if (token) {
        const result = await imagesearch.search(token, query, offset.current);
        offset.current += result.value.length;
        finish.current = result.value.length === 0;
        setImages((imagesOld) => {
          imagesOld.push(...result.value);
          return _.cloneDeep(imagesOld);
        });
      }
    } catch {}
    setIsLoading(false);
  };

  const bottomScroll = useBottomScrollListener(search, {
    offset: 100,
    debounce: 500,
    triggerOnNoScroll: false,
  });

  return (
    /*@ts-ignore */
    <div ref={bottomScroll} className="gifGridContainer">
      <div className="image-search-bar searchBar">
        <input
          placeholder="Search images"
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="off"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div
          onClick={() => {
            search();
            sendAmplitudeData('Gif searched', {
              type: 'bing images',
              searchTerm: query,
            });
          }}
        >
          <div></div>
          <svg viewBox="0 0 30 30" version="1.1">
            <defs>
              <path
                d="M11.5482521,20.4090671 L4.24727698,28.2009189 C3.68084207,28.8054377 2.73159653,28.8363108 2.12707771,28.2698759 C1.5225589,27.703441 1.4916858,26.7541954 2.0581207,26.1496766 L9.40599838,18.3077689 C7.95982241,16.4371424 7.0978836,14.0789715 7.0978836,11.5181818 C7.0978836,5.44914339 11.9392549,0.518181818 17.9252787,0.518181818 C23.9113026,0.518181818 28.7526738,5.44914339 28.7526738,11.5181818 C28.7526738,17.5872202 23.9113026,22.5181818 17.9252787,22.5181818 C15.539851,22.5181818 13.3361963,21.7351359 11.5482521,20.4090671 Z M17.9252787,19.5181818 C22.242011,19.5181818 25.7526738,15.9425536 25.7526738,11.5181818 C25.7526738,7.09381 22.242011,3.51818182 17.9252787,3.51818182 C13.6085464,3.51818182 10.0978836,7.09381 10.0978836,11.5181818 C10.0978836,15.9425536 13.6085464,19.5181818 17.9252787,19.5181818 Z"
                id="giphy-search-icon-path-1"
              ></path>
            </defs>
            <g id="search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="icons/search">
                <mask id="giphy-search-icon-mask-2" fill="white">
                  <use xlinkHref="#giphy-search-icon-path-1"></use>
                </mask>
                <use id="Mask" fill="#FFFFFF" fill-rule="nonzero" xlinkHref="#giphy-search-icon-path-1"></use>
                <g mask="url(#giphy-search-icon-mask-2)">
                  <g transform="translate(0.250000, 0.250000)">
                    <g></g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div className="grid-images">
        {_.map(images, (image) => {
          return (
            <div>
              <img
                onClick={(e) => addImage(image, e)}
                src={image.contentUrl}
                alt={image.name}
                key={image.bingId}
                onError={(e) => {
                  //@ts-ignore
                  e.target.style.display = 'none';
                }}
              />
            </div>
          );
        })}
        {isLoading ? (
          <div id="loader-cont">
            <div id="loader-image"></div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const GiphyComp = (props: { canvasID: string; spaceID: string; toClose: () => void }) => {
  const giphyFetch = new GiphyFetch(giphyKey);
  const { searchKey } = useContext(SearchContext);
  const addGif = (gif: IGif, e: React.SyntheticEvent<HTMLElement, Event>) => {
    const spawnLocation = getSpawnLocation(getListenerPosition(), 300);
    addNewCanvasChild(props.spaceID, props.canvasID, {
      w: gif.images.fixed_height.width * 2,
      h: gif.images.fixed_height.height * 2,
      x: spawnLocation.x,
      y: spawnLocation.y,
      t: 'gif',
      d: { u: gif.images.fixed_height.url, uid: getAuthState().user.id },
    }).then(props.toClose);
    e.preventDefault();
    // sendAmplitudeData('Gif added', {
    //   url: gif.images.fixed_height.url,
    //   searchTerm: searchKey,
    // });
  };
  return (
    <div style={{ width: '300px' }}>
      <Tabs className="fillComplete">
        <TabList className="tablist fontSmall">
          <Tab>GIFs</Tab>
          <Tab>Stickers</Tab>
          <Tab>Images</Tab>
        </TabList>
        <TabPanel>
          <div className="gifGridContainer">
            <SearchBar />
            <Grid
              key={searchKey}
              onGifClick={addGif}
              fetchGifs={(offset) => {
                return searchKey.length > 0
                  ? giphyFetch.search(searchKey, { offset, rating: 'g' })
                  : giphyFetch.trending({ offset, rating: 'g' });
              }}
              width={300}
              columns={2}
              gutter={6}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="gifGridContainer">
            <SearchBar />
            <Grid
              key={searchKey}
              onGifClick={addGif}
              fetchGifs={(offset) => {
                return searchKey.length > 0
                  ? giphyFetch.search(searchKey, { offset, rating: 'g', type: 'stickers' })
                  : giphyFetch.trending({ offset, rating: 'g', type: 'stickers' });
              }}
              width={300}
              columns={2}
              gutter={6}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <ImageSearchComp canvasID={props.canvasID} spaceID={props.spaceID} toClose={props.toClose} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Giphy;
