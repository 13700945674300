import React, { useEffect, useState } from 'react';
import './notificationToast.scss';

type propsType = {
  message: string;
  show: boolean;
  icons: JSX.Element;
  width: number;
  onClose: () => void;
  id: string;
  lower?: boolean | undefined;
  persistent?: boolean;
};

const NotificationToast = (props: propsType) => {
  const [show, setShow] = useState(props.show);

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  return (
    <>
      <div
        className={
          show || (props.show && props.persistent)
            ? 'toast-message show' + (props.lower ? ' lower' : '')
            : 'toast-message'
        }
      >
        <div className="toast-content" style={{ width: props.width + 'px' }}>
          {props.icons}
          <span className="content">{props.message}</span>
          <span
            className="material-icons"
            onClick={() => {
              setShow(false);
              props.onClose();
            }}
          >
            close
          </span>
        </div>
      </div>
    </>
  );
};

export default NotificationToast;
