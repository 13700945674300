import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { addNewCanvasChild } from '../../../helper/interactive/firebaseRTD';
import { getSpawnLocation } from '../../../helper/interactive/misc';
import { closeModalThunk, getListenerPosition, newModalThunk, openModalThunk } from '../../../store';
import { getAuth } from '../../../store/auth';
import { getGetModalState } from '../../../store/modal';
import { getSpacesRooms } from '../../../store/spacesRooms';
import DropDown from '../../dropdown/dropdown';
import { ModalStyle } from '../../modal/modal';
import './addPortalModal.scss';

export const addPortalID = 'add-portal-id';
export const openAddPortalModal = openModalThunk(addPortalID);
export const closeAddPortalModal = closeModalThunk(addPortalID);

export const AddPortalModal = (props: { canvasID: string; spaceID: string }) => {
  const addPortalState = useSelector(getGetModalState(addPortalID));
  useEffect(() => {
    newModalThunk(addPortalID, false)();
  }, []);

  const spacesRooms = useSelector(getSpacesRooms);
  const spaceID = spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID;
  const auth = useSelector(getAuth);

  const addPortalToCanvas = (spaceID: string, canvasID: string, selectedRoomID: string) => {
    const spawnLocation = getSpawnLocation(getListenerPosition(), 150);
    addNewCanvasChild(spaceID, canvasID, {
      w: 150,
      h: 150,
      x: spawnLocation.x,
      y: spawnLocation.y,
      t: 'portal',
      d: { roomID: selectedRoomID, uid: auth.user.id },
    });
  };

  const DropDownStyle = {
    dropDownHeader: {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontSize: '16px',
      borderRadius: '8px',
      border: '1px solid #000000',
      height: '32px',
    },
    listOuter: {
      backgroundColor: 'rgba(55, 57, 62, 1)',
      borderRadius: '8px',
      border: '1px solid #000000',
      top: '42px',
      maxHeight: '200px',
      overflowX: 'hidden',
      overflowY: 'overlay',
    },
    listItems: {
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingBottom: '6px',
      paddingTop: '6px',
      fontSize: '16px',
      height: '32px',
    },
  };

  const [roomList, setRoomList] = useState<{ [id: string]: string }>({});
  const [selectedRoom, setSelectedRoom] = useState('');

  const createRoomDict = () => {
    for (const i in spacesRooms.rooms) {
      if (spacesRooms.currentRoom !== spacesRooms.rooms[i]?.roomID)
        setRoomList((roomList) => {
          return {
            ...roomList,
            [spacesRooms.rooms[i]?.roomID ?? '']: spacesRooms.rooms[i]?.roomDetails.roomName ?? '',
          };
        });
    }
  };

  useEffect(() => {
    setSelectedRoom('');
    createRoomDict();
  }, [spacesRooms.currentRoom, addPortalState]);

  useEffect(() => {
    setRoomList({});
  }, [spacesRooms.currentRoom]);

  return (
    <ReactModal id={addPortalID} className={addPortalID} isOpen={addPortalState} style={ModalStyle}>
      <>
        <div className="body">
          <div className="title">Add Portal to your room</div>
          <DropDown
            headerTitle={selectedRoom && roomList[selectedRoom] ? roomList[selectedRoom] : 'Select a portal room'}
            styles={DropDownStyle}
            options={roomList}
            optionsListType="array"
            isItemDict={true}
            onSelectItem={(e: any, key: any) => {
              setSelectedRoom(key);
            }}
          />
        </div>
        <div className="footer">
          <div className="buttons">
            <div
              className="cancel-btn"
              onClick={() => {
                closeAddPortalModal();
              }}
            >
              Cancel
            </div>
            <div
              className="takemeback"
              style={!selectedRoom || selectedRoom === 'Select a portal room' ? { pointerEvents: 'none' } : {}}
              onClick={() => {
                addPortalToCanvas(props.spaceID, props.canvasID, selectedRoom);
                closeAddPortalModal();
              }}
            >
              Submit
            </div>
          </div>
        </div>
      </>
    </ReactModal>
  );
};
