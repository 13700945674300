import { configureStore, createSlice } from '@reduxjs/toolkit';

type source = {
  position: { x: number; y: number; z: number };
  shape: { w: number; h: number };
  type: 'gif' | 'vid' | 'live';
};

type htmlEle = { [id: string]: source };

let initialState: { sources: htmlEle } = { sources: {} };

export const html2StreamSlice = createSlice({
  name: 'html2stream',
  initialState,
  reducers: {
    addSource(state, action) {
      state.sources[action.payload.id] = {
        position: { x: 0, y: 0, z: 0 },
        shape: { w: 100, h: 100 },
        type: action.payload.type,
      };
    },
    updateSource(state, action) {
      if (state.sources[action.payload.id])
        state.sources[action.payload.id] = {
          type: state.sources[action.payload.id].type,
          position: {
            x: action.payload.x ? action.payload.x : state.sources[action.payload.id].position.x,
            y: action.payload.y ? action.payload.y : state.sources[action.payload.id].position.y,
            z: action.payload.z ? action.payload.z : state.sources[action.payload.id].position.z,
          },
          shape: {
            w: action.payload.w ? action.payload.w : state.sources[action.payload.id].shape.w,
            h: action.payload.h ? action.payload.h : state.sources[action.payload.id].shape.h,
          },
        };
      else console.warn(`No source with id: ${action.payload.id} found`, state.sources);
    },
    removeSource(state, action) {
      if (state.sources[action.payload.id]) {
        delete state.sources[action.payload.id];
      }
    },
  },
});

export const html2stream = configureStore({
  reducer: html2StreamSlice.reducer,
});

export const getHTML2Stream = () => (state: { html2stream: ReturnType<typeof html2stream.getState> }) =>
  state.html2stream;
