import axios from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'querystring';
import { config } from '../../config';
import logger from '../logger';
import { headers } from './spaces';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

class User {
  async updateGuestUser(email: string) {
    try {
      logger.info('updateGuestUser called:', { email });
      const response = await axios.post(`${config.firebaseCloudURL}/updateGuestUser`, qs.stringify({ email }), headers);
      logger.info('updateGuestUser response: ', { result: response.data });
      return response;
    } catch (e) {
      logger.warn('updateGuestUser error:', { error: e?.response?.data });
      throw e;
    }
  }
}

export default new User();
