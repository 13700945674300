import DailyIframe, { DailyCall, DailyParticipant, DailyParticipantsObject } from '@daily-co/daily-js';
import _ from 'lodash';
import SpeakerVol from '../../components/interactive/room/speakerVol';
import {
  hideJoiningRoom,
  hideRejoiningRoom,
  showJoiningRoom,
  showRejoiningRoom,
} from '../../components/notifications/notifications';
import spatialAudioController, { cleanSpatial } from '../../spatialAudio/spatialAudioOptimized';
import {
  addLocalDailyco,
  addRemoteDailyco,
  changeJoinStatus,
  getAuthState,
  getCurrentRoom,
  getCurrentScreenWall,
  getCurrentSpace,
  getSpacesRoomsState,
  removeAllParticipantsDaily,
  removeRemoteDailyco,
  removeScreen,
  setBroadcasterFuncStore,
  setBroadcasterMediaTrackStore,
  setBroadcasterMuteStore,
  setBroadcasterStatusStore,
  setDailyScreenSharingStore,
  setLocalStreamIDDailyStore,
  setStreamForLocalDailyStore,
  updateDailycoLocalMute,
  updateLocalScreenDailyco,
  updateRemoteDailyco,
} from '../../store';
import { localParticipantDailyco } from '../../store/dailycoParticipants';
import { getClaimToken, getUserFromFirebase } from '../firebase/auth';
import logger from '../logger';
import { getDailyToken } from '../requests/daily';
import usage from '../UsagePresence/usage';
import { Producer } from '../yetiLiteClient/producer';
import { setScreenWall, setScreenWallV2RTD } from './firebaseRTD';

const logParticipant = (participant: DailyParticipant) => {
  return {
    name: participant.user_name,
    sessionID: participant.session_id,
    userID: participant.user_id,
    isLocal: participant.local,
    tracks: {
      audio: {
        subscribed: participant.tracks.audio.subscribed,
        state: participant.tracks.audio.state,
      },
      video: {
        subscribed: participant.tracks.video.subscribed,
        state: participant.tracks.video.state,
      },
      screenAudio: {
        subscribed: participant.tracks.screenAudio.subscribed,
        state: participant.tracks.screenAudio.state,
      },
      screenVideo: {
        subscribed: participant.tracks.screenVideo.subscribed,
        state: participant.tracks.screenVideo.state,
      },
    },
  };
};

const highQualityYetiLite = async (stream: MediaStreamTrack) => {
  const producer = new Producer();
  await producer.connect();
  const data = await producer.pushStream(stream);
  const spacesRooms = getSpacesRoomsState();
  await setScreenWallV2RTD(spacesRooms.currentSpace, spacesRooms.currentRoom, data);
};

export class DailyCoCall {
  room!: string;
  userToken!: string;
  callObj?: DailyCall | BojackAPI;
  broadcastObj!: DailyCall | undefined;
  overflowStreamCall!: DailyCall | undefined;
  userName!: string;
  joined!: boolean;
  screenshareStream?: MediaStream;

  setRoom(room: string, userToken: string, userName: string, roomCopy: number) {
    if (roomCopy !== 0) this.room = room.slice(0, 25) + '-' + roomCopy;
    else this.room = room.slice(0, 25);
    this.userToken = userToken;
    this.userName = userName;
    this.joined = false;
  }

  async init(
    roomID: string
  ): Promise<{
    token: string;
    url: string;
    room_id: string;
    is_daily: boolean;
  }> {
    const token = await getClaimToken(false, false);
    if (token) {
      roomID = roomID.slice(0, 10);
      let user_id = getUserFromFirebase()?.uid ?? Math.random().toFixed(36).slice(2, 10);
      let token_str = JSON.stringify({
        room_id: roomID,
        name: this.userName ?? 'guest_user',
        app_id: user_id,
      });
      return {
        url: roomID,
        room_id: roomID,
        token: token_str,
        is_daily: false,
      };
    } else throw new Error('Couldnt get firebase token.');
  }

  async getBroadcastToken(broadcastRoom: string) {
    const token = await getClaimToken(false, false);
    if (token) return await getDailyToken(broadcastRoom, token.token);
    else throw new Error('Couldnt get firebase token.');
  }

  async getOverflowStream(overflowStreamRoom: string) {
    const token = await getClaimToken(false, false);
    if (token) return await getDailyToken(overflowStreamRoom, token.token);
    else throw new Error('Couldnt get firebase token.');
  }

  async leaveBroadcast() {
    if (this.broadcastObj) {
      await this.broadcastObj.leave();
      await this.broadcastObj.destroy();
      setBroadcasterStatusStore(false);
      setBroadcasterFuncStore({
        isLocal: false,
        toMute: (flag) => {},
        toVidMute: (flag) => {},
        isMuted: true,
        isVidMuted: true,
      });
    }
  }

  async leaveOverflowStream() {
    if (this.overflowStreamCall) {
      this.overflowStreamCall?.leave();
      this.overflowStreamCall?.destroy();
      this.overflowStreamCall = undefined;
    }
  }

  async joinOverflowStream(
    broadcasterID: string,
    roomName: string,
    onStream: (stream: MediaStreamTrack | undefined) => void,
    onAudStream: (stream: MediaStreamTrack | undefined) => void,
    stream?: MediaStream,
    onStopScreenshare?: () => void,
    cameraMode?: boolean
  ) {
    if (this.overflowStreamCall) {
      await this.leaveOverflowStream();
    }
    const token = await this.getOverflowStream(roomName);
    const auth = getAuthState();
    logger.info('Starting overflow stream', { broadcasterID });
    const isBroadcaster = broadcasterID === auth.user.id;

    const videoID = localStorage.getItem('vidID') || '';
    const micID = localStorage.getItem('micID') || '';
    this.overflowStreamCall = DailyIframe.createCallObject({
      dailyConfig: { experimentalChromeVideoMuteLightOff: true },
      //@ts-ignore
      audioSource: isBroadcaster ? videoID : false,
      //@ts-ignore
      videoSource: isBroadcaster ? micID : false,
    });
    await this.overflowStreamCall.setInputDevicesAsync({
      videoDeviceId: isBroadcaster ? videoID : false,
      audioDeviceId: isBroadcaster ? micID : false,
    });

    //@ts-ignore
    window.overflowStreamCall = this.overflowStreamCall;

    const updateBroadcaster = (participant: DailyParticipant | undefined, flag = true) => {
      try {
        if (participant?.user_id === broadcasterID) {
          logger.info('Broadcaster updated.', logParticipant(participant));
        }
        if (!flag && participant?.user_id === broadcasterID) {
          this.overflowStreamCall?.leave();
          this.overflowStreamCall?.destroy();
        }
        if (participant && participant.user_id === broadcasterID) {
          // this.overflowStreamCall?.updateParticipant(participant.session_id, {
          //   setSubscribedTracks: { screenAudio: true, screenVideo: true },
          // });
          if (participant.tracks.screenVideo.state !== 'playable') return;
          if (participant.tracks.screenVideo.track || participant.tracks.screenAudio.track) {
            if (participant.tracks.screenVideo.track) {
              onStream(participant.tracks.screenVideo.track);
            }
            //@ts-ignore
            if (participant.tracks.customAudio0?.track) {
              logger.info('Got customAudio0', { tracks: participant.tracks });
              //@ts-ignore
              onAudStream(participant.tracks.customAudio0.track);
            } else if (participant.tracks.screenAudio.track) {
              onAudStream(participant.tracks.screenAudio.track);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
    this.overflowStreamCall.on('participant-joined', (participant) => {
      updateBroadcaster(participant?.participant);
    });
    this.overflowStreamCall.on('participant-updated', (participant) => {
      updateBroadcaster(participant?.participant);
    });
    this.overflowStreamCall.on('local-screen-share-stopped', () => {
      if (isBroadcaster) {
        if (onStopScreenshare) onStopScreenshare();
      }
    });
    this.overflowStreamCall.on('participant-left', (participant) => {
      updateBroadcaster(participant?.participant, false);
    });
    this.overflowStreamCall.on('joined-meeting', async () => {
      if (isBroadcaster) {
        this.overflowStreamCall?.startScreenShare({ mediaStream: stream });
        if (stream && stream?.getAudioTracks().length > 0 && cameraMode) {
          try {
            //@ts-ignore
            await window.betaStartCustomTrack({ track: stream.getAudioTracks()[0], mode: 'music' });
          } catch (e) {
            logger.warn('BetaStartCustomTrack failed', { error: e });
          }
        }
      } else {
        this.overflowStreamCall?.setLocalAudio(false);
        this.overflowStreamCall?.setLocalVideo(false);
      }
      const participants = this.overflowStreamCall?.participants();
      if (participants) {
        for (let i in participants) {
          const participant = participants[i];
          updateBroadcaster(participant);
        }
      }
    });
    await this.overflowStreamCall.join({
      url: `https://reslash.daily.co/${roomName}`,
      token: token,
      subscribeToTracksAutomatically: true,
    });
  }

  async joinBroadcastIframe(
    videoSource: string | undefined | false,
    audioSource: string | undefined | false,
    broadcastRoom: string,
    broadcastToken: string,
    broadcasterID: string,
    onLeave: () => void,
    onMute: (flag: boolean) => void,
    onVidMute: (flag: boolean) => void,
    onParticipantUpdate: (uid: string, flag: boolean) => void
  ) {
    if (this.broadcastObj) {
      await this.broadcastObj.leave();
      await this.broadcastObj.destroy();
      setBroadcasterStatusStore(false);
    }
    const auth = getAuthState();
    let isBroadcaster = broadcasterID === auth.user.id;
    // if (auth.user.id === broadcasterID) {
    //   getLocalParticipantFromStoreDailyco().muteFunc(true);
    //   getLocalParticipantFromStoreDailyco().muteVidFunc(true);
    //   const stream = getStreamForLocalDailyStore();
    //   videoSource = stream.videoID || undefined;
    //   audioSource = stream.micID || undefined;
    //   isBroadcaster = true;
    // }
    logger.info('Started announcement.', { videoSource, audioSource, broadcastRoom, broadcasterID });
    this.broadcastObj = DailyIframe.createCallObject({
      dailyConfig: { experimentalChromeVideoMuteLightOff: true },
    });
    //@ts-ignore
    window.broadcastObj = this.broadcastObj;
    await this.broadcastObj.setInputDevicesAsync({ videoDeviceId: videoSource, audioDeviceId: audioSource });
    const updateBroadcaster = (participant: DailyParticipant | undefined, flag = true) => {
      if (participant) {
        if (participant.user_id === broadcasterID) {
          logger.info('Announcer updated.', logParticipant(participant));
        }
        onParticipantUpdate(participant.user_id, flag);
        if (!flag) {
          if (participant.user_id === broadcasterID) onLeave();
        }
        if (participant.user_id === broadcasterID) {
          // if (participant.video && participant.videoTrack) setBroadcasterVideoStore(participant.videoTrack);
          // else setBroadcasterVideoStore(null);

          // if (participant.audio && participant.audioTrack) setBroadcasterAudioStore(participant.audioTrack);
          // else setBroadcasterAudioStore(null);
          setBroadcasterMediaTrackStore(
            participant.audio && participant.audioTrack ? participant.audio && participant.audioTrack : null,
            participant.video && participant.videoTrack ? participant.videoTrack : null
          );
          onMute(participant.tracks.audio.state !== 'playable');
          onVidMute(participant.tracks.audio.state !== 'playable');
        }
      }
    };
    this.broadcastObj.on('participant-joined', (participant) => {
      updateBroadcaster(participant?.participant);
    });
    this.broadcastObj.on('participant-updated', (participant) => {
      updateBroadcaster(participant?.participant);
    });
    this.broadcastObj.on('participant-left', (participant) => {
      updateBroadcaster(participant?.participant, false);
    });
    this.broadcastObj.on('joined-meeting', () => {
      setBroadcasterStatusStore(true);
      if (isBroadcaster) {
        setBroadcasterFuncStore({
          isLocal: true,
          toMute: (flag) => {
            this.broadcastObj?.setLocalAudio(!flag);
            setBroadcasterMuteStore(true, flag);
          },
          toVidMute: (flag) => {
            this.broadcastObj?.setLocalVideo(!flag);
            setBroadcasterMuteStore(false, flag);
          },
          isMuted: audioSource === undefined,
          isVidMuted: videoSource === undefined,
        });
      } else {
        this.broadcastObj?.setLocalAudio(false);
        this.broadcastObj?.setLocalVideo(false);
      }
    });
    const existingParticipants = await this.broadcastObj.join({
      url: `https://reslash.daily.co/${broadcastRoom}`,
      token: broadcastToken,
      subscribeToTracksAutomatically: true,
    });
    if (existingParticipants) {
      for (let i in existingParticipants) {
        const participant = existingParticipants[i];
        updateBroadcaster(participant);
      }
    }
  }

  async join(
    roomID: string,
    roomCopy: number,
    cameraID: string | undefined,
    micID: string | undefined,
    isVidDisabled: boolean,
    isMicDisabled: boolean,
    isMute: boolean,
    isVidMute: boolean,
    onNetworkChange: (threshold: string, quality: number) => void,
    rejoining = false
  ): Promise<void> {
    /**
     * Todo: Don't set camera and mic if mic and camera is disabled
     */
    if (!rejoining) showJoiningRoom();
    const currentRoom = roomID;
    // if (roomCopy !== 0) roomID = roomID.slice(0, 25) + '-' + roomCopy;
    roomID = roomID.slice(0, 25);
    const token = await this.init(roomID);
    if (this.joined) await this.leave();
    if (!this.callObj) this.callObj = new BojackAPI();

    //@ts-ignore
    window.callObj = this.callObj;
    const setInputDevicePayload: any = {};
    if (!isVidMute && !isVidDisabled) {
      setInputDevicePayload.videoDeviceId = cameraID;
    }
    if (!isMute && !isMicDisabled) {
      setInputDevicePayload.audioDeviceId = micID;
    }
    await this.callObj.setInputDevicesAsync(setInputDevicePayload);
    this.callObj.on('participant-joined', (participant) => {
      logger.info(
        `Participant-joined: ${participant?.participant.user_name}`,
        participant?.participant ? logParticipant(participant.participant) : {}
      );
      if (participant) {
        spatialAudioController.subscribedTo[participant.participant.session_id] =
          participant.participant.tracks.audio.subscribed;
        addRemoteDailyco(
          participant.participant.local ? 'local' : participant.participant.session_id,
          _.cloneDeep(participant.participant)
        );
        if (participant.participant.local) {
          setStreamForLocalDailyStore(
            participant.participant.videoTrack || null,
            participant.participant.audioTrack || null
          );
        }
      }
    });

    this.callObj.on('participant-left', (participant) => {
      logger.info(
        `Participant-left: ${participant?.participant.user_name}`,
        participant?.participant ? logParticipant(participant.participant) : {}
      );
      if (participant) {
        removeRemoteDailyco(participant.participant.session_id);
        cleanSpatial(participant.participant.user_id);
        removeScreen(participant.participant.user_id + '_screen');
      }
    });

    this.callObj.on('local-screen-share-stopped', () => {
      setDailyScreenSharingStore(false);
    });

    this.callObj.on('error', (e) => {
      logger.error('Daily Error', e);
      console.error(e);
      if (e && e.errorMsg === 'network unreachable') {
        showRejoiningRoom();
        this.leave().then(() =>
          this.join(
            roomID,
            roomCopy,
            cameraID,
            micID,
            isVidDisabled,
            isMicDisabled,
            isMute,
            isVidMute,
            onNetworkChange,
            true
          )
        );
      }
    });

    this.callObj.on('participant-updated', (participant) => {
      logger.info(
        `Participant-updated: ${participant?.participant.user_name}`,
        participant?.participant ? logParticipant(participant.participant) : {}
      );
      if (participant && participant.participant.tracks.screenVideo.state === 'interrupted') {
        return;
      }
      if (participant) {
        if (
          !participant.participant.tracks.audio.subscribed &&
          spatialAudioController.subscribedTo[participant.participant.session_id]
        ) {
          logger.info('Subscribing to unsubscribed audio.', logParticipant(participant.participant));
          spatialAudioController.subscribedTo[participant.participant.session_id] = false;
          spatialAudioController.changeDailySubscription(participant.participant.user_id, true);
        } else {
          spatialAudioController.subscribedTo[participant.participant.session_id] =
            participant.participant.tracks.audio.subscribed;
        }
        if (participant.participant.tracks.audio.track && participant.participant.tracks.audio.state === 'playable') {
          if (!window.volMeters[participant.participant.user_id]) {
            try {
              const stream = new MediaStream([participant.participant.tracks.audio.track]);
              window.volMeters[participant.participant.user_id] = new SpeakerVol(
                participant.participant.user_id,
                undefined,
                stream
              );
              window.volMeters[participant.participant.user_id].start();
            } catch {}
          }
        } else if (window.volMeters[participant.participant.user_id]) {
          try {
            window.volMeters[participant.participant.user_id].destroy();
            window.volMeters[participant.participant.user_id] = undefined;
          } catch {}
        }
        if (
          !participant.participant.tracks.video.subscribed &&
          spatialAudioController.vidOnPerm &&
          spatialAudioController.rcLookup[participant.participant.user_id] ===
            spatialAudioController.rcLookup[spatialAudioController.localID] &&
          this.callObj
        ) {
          this.callObj.updateParticipant(participant.participant.session_id, {
            setSubscribedTracks: { video: true },
          });
        }
        addRemoteDailyco(
          participant.participant.local ? 'local' : participant.participant.session_id,
          _.cloneDeep(participant.participant)
        );
        if (participant.participant.local) {
          setStreamForLocalDailyStore(
            participant.participant.videoTrack || null,
            participant.participant.audioTrack || null
          );
        }
        const state = participant.participant.tracks.screenVideo.state;
        if (state !== 'playable' && state !== 'interrupted' && state !== 'sendable') {
          removeScreen(participant.participant.user_id + '_screen');
        }
      }
    });

    this.callObj.on('network-quality-change', (e) => {
      if (e) {
        if (e.threshold !== 'good') {
          logger.warn('Network warning:', { event: e });
          // console.warn(e.threshold, 'network warning.', `Quality: ${e.quality}/100`);
          onNetworkChange(e.threshold, e.quality);
        }
      }
    });

    this.callObj.on('joined-meeting', async () => {
      window.volMeters = {};
      let participant_list = (await this.callObj?.participants()) as DailyParticipantsObject;
      const existingParticipants = { ...participant_list };
      if (existingParticipants.local) logger.setDailySessionID(existingParticipants.local.session_id);
      (async () => {
        try {
          const sessionData = await this.callObj?.getMeetingSession();
          let sessionID = 'undefined';
          if (sessionData) {
            sessionID = sessionData.meetingSession.id;
          }
          await usage.setDailyInfo(sessionID, existingParticipants.local?.session_id ?? '');
        } catch (e) {
          logger.error('error in setting daily info in usage', {
            errorObj: e,
          });
        }
      })();
      logger.info('Daily meeting joined', {
        roomID,
        roomCopy,
        cameraID,
        micID,
        isVidDisabled,
        isMicDisabled,
        isMute,
        isVidMute,
      });
      hideJoiningRoom();
      hideRejoiningRoom();
      if (existingParticipants && existingParticipants.local) {
        updateRemoteDailyco(existingParticipants);
        const localParticipantDaily = existingParticipants.local;
        const localParticipant: localParticipantDailyco = {
          participant: localParticipantDaily,
          muteFunc: (flag: boolean) => {
            logger.info('Mic mute function called:', { flag });
            if (isMicDisabled) return;
            this.callObj?.setLocalAudio(!flag);
            updateDailycoLocalMute(true, flag);
          },
          muteVidFunc: (flag: boolean) => {
            logger.info('Video mute function called:', { flag });
            if (isVidDisabled) return;
            this.callObj?.setLocalVideo(!flag);
            updateDailycoLocalMute(false, flag);
          },
          screenshare: (
            flag: boolean,
            screenShareDevice: string,
            screenShareAudioDevice?: string,
            isFullScreen?: boolean
          ) => {
            logger.info('Screenshare func called:', { flag, screenShareDevice, screenShareAudioDevice, isFullScreen });
            if (flag) {
              if (isFullScreen) {
                setScreenWall(getCurrentSpace(), getCurrentRoom(), localParticipantDaily.user_id);
              }
              if (screenShareDevice.length > 1) {
                navigator.mediaDevices
                  .getUserMedia({
                    video: { deviceId: screenShareDevice },
                    audio: screenShareAudioDevice
                      ? {
                          deviceId: screenShareAudioDevice,
                          autoGainControl: false,
                          noiseSuppression: false,
                          echoCancellation: false,
                          channelCount: 2,
                        }
                      : false,
                  })
                  .then(async (stream) => {
                    this.screenshareStream = stream;
                    this.callObj?.startScreenShare({ mediaStream: stream });
                    if (stream.getAudioTracks().length > 0 && isFullScreen) {
                      highQualityYetiLite(stream.getAudioTracks()[0]).catch((e) => {
                        logger.error('yetiLite producer failed.', { errorReturned: e });
                      });
                    }
                  })
                  .catch((e) => {
                    if (e && e?.message && e?.message?.includes('Permission denied')) return;
                    const inIframe = window.inIframe ? window.inIframe() : false;
                    logger.error(`Screenshare device get error ${inIframe ? 'in iframe' : ''}:`, {
                      e,
                      inIframe,
                    });
                  });
              } else {
                navigator.mediaDevices
                  //@ts-ignore
                  .getDisplayMedia({
                    video: true,
                    audio: {
                      autoGainControl: false,
                      noiseSuppression: false,
                      echoCancellation: false,
                      channelCount: 2,
                    },
                  })
                  .then(async (stream: MediaStream) => {
                    this.screenshareStream = stream;
                    this.callObj?.startScreenShare({ mediaStream: stream });
                    if (stream.getAudioTracks().length > 0 && isFullScreen) {
                      highQualityYetiLite(stream.getAudioTracks()[0]).catch((e) => {
                        logger.error('yetiLite producer failed.', { errorReturned: e });
                      });
                    }
                  })
                  .catch((e: any) => {
                    if (e && e?.message && e?.message?.includes('Permission denied')) return;
                    const inIframe = window.inIframe ? window.inIframe() : false;
                    logger.error(`Screenshare device get error ${inIframe ? 'in iframe' : ''}:`, {
                      e,
                      inIframe,
                    });
                  });
                // navigator.mediaDevices
                //   //@ts-ignore
                //   .getDisplayMedia({
                //     video: true,
                //     audio: {
                //       echoCancellation: true,
                //       noiseSuppression: false,
                //     },
                //   })
                //   .then((stream: MediaStream) => {
                //     this.callObj.startScreenShare({ mediaStream: stream });
                //   })
                //   .catch((e: any) => {
                //     console.error(e);
                //   });
              }
            } else {
              if (getCurrentScreenWall() === localParticipantDaily.user_id) {
                setScreenWall(getCurrentSpace(), getCurrentRoom(), '');
              }
              if (this.screenshareStream) {
                this.screenshareStream.getTracks().forEach(function (track) {
                  track.stop();
                });

                this.screenshareStream = undefined;
              }
              this.callObj?.stopScreenShare();
            }
            updateLocalScreenDailyco(flag);
          },
          isScreensharing: false,
          isMute,
          isVidMute,
          isMicDisabled,
          isVidDisabled,
          joined: this.joined,
          changeMicCamera: async (camID, micID) => {
            await this.callObj?.setInputDevicesAsync({ videoDeviceId: camID, audioDeviceId: micID });
            setLocalStreamIDDailyStore(camID, micID);
          },
          stream: {
            audio: existingParticipants.local.audioTrack || null,
            video: existingParticipants.local.videoTrack || null,
            micID,
            videoID: cameraID,
          },
        };
        addLocalDailyco(localParticipant);
        if (isMute || isMicDisabled) {
          localParticipant.muteFunc(true);
        } else {
          localParticipant.muteFunc(false);
        }
        if (isVidMute || isVidDisabled) {
          localParticipant.muteVidFunc(true);
        } else {
          localParticipant.muteVidFunc(false);
        }
      }
      if (!this.joined) {
        this.joined = true;
        changeJoinStatus(true);
      }
    });
    /**
     * Send mute options on joining only
     */
    logger.info('Status: Joining room.', { roomID });
    //  dialyCo Join
    await this.callObj.join({
      url: token.room_id,
      token: token.token,
    });
    // if (cameraID && micID) await this.callObj.setInputDevicesAsync({ videoDeviceId: cameraID, audioDeviceId: micID });
    // else if (cameraID) await this.callObj.setInputDevicesAsync({ videoDeviceId: cameraID });
    // else if (micID) await this.callObj.setInputDevicesAsync({ audioDeviceId: micID });
    this.callObj.setNetworkTopology({ topology: 'sfu' }).catch((e) => {});
    await this.callObj.setUserName(this.userName);
  }

  async leave(isFinalExit: boolean = false) {
    try {
      if (isFinalExit) usage.endAllSessions().then().catch();
      removeAllParticipantsDaily();
      if (this.screenshareStream) {
        this.screenshareStream.getTracks().forEach(function (track) {
          track.stop();
        });
      }
      if (this.callObj) {
        logger.info('Status: Leaving room.', {});
        await this.callObj.leave();
        await this.callObj.destroy();
        this.callObj = undefined;
      }
      if (this.joined) {
        this.joined = false;
        changeJoinStatus(false);
      }
    } catch (e) {
      logger.error('Error occured while leaving.', { e });
      if (isFinalExit) window.location.reload();
    }
  }
}

const dailyCoObj = new DailyCoCall();
export default dailyCoObj;
