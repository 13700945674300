import { configureStore, createSlice } from '@reduxjs/toolkit';

export const hideMenuSlice = createSlice({
  name: 'hideMenu',
  initialState: {
    hideMenuState: false,
  },
  reducers: {
    setHideMenuState: (state, action) => {
      console.log(state, action.payload);
      state.hideMenuState = action.payload;
    },
  },
});

export const hideMenuStore = configureStore({
  reducer: hideMenuSlice.reducer,
  devTools: true,
});

export const getHideMenuState = (globalState: { hideMenu: ReturnType<typeof hideMenuStore.getState> }) =>
  globalState.hideMenu.hideMenuState;
