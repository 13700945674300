import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { maxPlayableScreenshares } from '../../../config';
import DailyCoObj from '../../../helper/interactive/dailyco';
import { getScreenshareStore, screen } from '../../../store/screenshares';
import { showSS, showSSClosed } from '../../notifications/notifications';

class ScreenshareControllerClass {
  sourceSessionMapping: { [source: string]: string };
  clickListeners: { [sourceID: string]: () => void };
  isForced: { [sourceID: string]: boolean };
  isScreenOn: { [sourceID: string]: boolean };
  isSubscribed: { [sourceID: string]: boolean };
  fullscreen: { [sourceID: string]: boolean };
  notiShown: boolean;

  constructor() {
    this.sourceSessionMapping = {};
    this.clickListeners = {};
    this.isForced = {};
    this.isScreenOn = {};
    this.isSubscribed = {};
    this.fullscreen = {};
    this.notiShown = false;
  }

  addMapping(source: string, sessionID: string) {
    this.sourceSessionMapping[source] = sessionID;
  }

  canTurnOn() {
    let count = 0;
    for (let key in this.isSubscribed) {
      if (this.isSubscribed[key]) count += 1;
    }
    return count < maxPlayableScreenshares;
  }

  mapSessionIDS() {
    if (DailyCoObj.callObj) {
      const participants = DailyCoObj.callObj?.participants();
      for (let i in participants) {
        const participant = participants[i];
        if (participant.user_id) {
          this.sourceSessionMapping[participant.user_id + '_screen'] = participant.session_id;
        }
      }
    }
  }

  toggle(sourceID: string, flag: boolean, force = false) {
    if (!this.canTurnOn() && flag && force) {
      showSS();
      return;
    }
    if (!force) {
      if (this.isForced[sourceID] && !flag) return;
      if (!flag && this.isScreenOn[sourceID]) return;
      if (!flag && this.fullscreen[sourceID]) return;
    }
    let sessionID = this.sourceSessionMapping[sourceID];
    if (!sessionID) {
      this.mapSessionIDS();
      sessionID = this.sourceSessionMapping[sourceID];
    }
    if (sessionID && (force || this.isSubscribed[sourceID] !== flag)) {
      DailyCoObj.callObj?.updateParticipant(sessionID, {
        setSubscribedTracks: { screenVideo: flag, screenAudio: flag },
      });
      this.setIsSubscribed(sourceID, flag);
    }
    this.isForced[sourceID] = force;
  }

  setIsSubscribed(sourceID: string, flag: boolean) {
    this.isSubscribed[sourceID] = flag;
    const fullscreen = 'fullscreen_' + sourceID;
    const ele = document.getElementById(fullscreen);
    const loader = document.getElementById(`loader_${sourceID}`);
    const hint = document.getElementById(`hint_${sourceID}`);
    if (ele) {
      ele.style.display = flag ? 'block' : 'none';
    }
    if (loader && hint) {
      loader.style.display = flag ? 'block' : 'none';
      hint.style.display = !flag ? 'block' : 'none';
    }
    const visible = document.getElementById(`visible_${sourceID}`);
    const hidden = document.getElementById(`hidden_${sourceID}`);
    if (visible && hidden) {
      visible.style.display = flag ? 'block' : 'none';
      hidden.style.display = !flag ? 'block' : 'none';
    }
  }

  closeAll(
    screenshares: {
      screens: {
        [id: string]: screen;
      };
    },
    force: boolean,
    noti = false
  ) {
    for (let id in screenshares.screens) {
      if (!this.isSubscribed[id]) continue;
      this.sourceSessionMapping[id] = screenshares.screens[id].sessionID;
      this.isScreenOn[id] = false;
      this.toggle(id, false, force);
    }
    if (noti && !this.notiShown) {
      showSSClosed();
      this.notiShown = true;
    }
  }

  updateScreenshares = (screenshares: {
    screens: {
      [id: string]: screen;
    };
  }) => {
    const numScreensVisible = maxPlayableScreenshares;
    const onKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'v') this.closeAll(screenshares, true);
    };
    if (_.keys(screenshares.screens).length <= numScreensVisible) {
      //@ts-ignore
      for (let el of document.querySelectorAll('.visiblity_btn')) el.style.display = 'none';
      for (let id in screenshares.screens) {
        this.sourceSessionMapping[id] = screenshares.screens[id].sessionID;
        this.toggle(id, true);
        this.isScreenOn[id] = true;
      }
      document.removeEventListener('keypress', onKeyPress);
    } else {
      //@ts-ignore
      for (let el of document.querySelectorAll('.visiblity_btn')) el.style.display = 'block';
      this.closeAll(screenshares, false, true);
      document.removeEventListener('keypress', onKeyPress);
      document.addEventListener('keypress', onKeyPress);
    }
  };
}

export const screenshareControllerObj = new ScreenshareControllerClass();

const ScreenshareController = () => {
  const screenshares = useSelector(getScreenshareStore);

  useEffect(() => {
    screenshareControllerObj.updateScreenshares(screenshares);
  }, [screenshares]);
  return <></>;
};

export default ScreenshareController;
