import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronRight, User } from 'react-feather';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { sendAmplitudeData } from '../../../helper/amplitude';
import { formatLong, getFirstName, handleSubdomain } from '../../../helper/interactive/misc';
import rooms from '../../../helper/requests/rooms';
import { setHighlightedRoomStore, toggleSidebarStore } from '../../../store';
import { getPresenceOfRoom } from '../../../store/presence';
import { getHighlightedRoomStateRedix, getSidebarMenuStateRedux } from '../../../store/settingsMenu';
import { getSpacesRooms } from '../../../store/spacesRooms';
import Avatar from '../../avatars/avatar';
import { openClosedRoomModal } from '../../customiseLogin/scheduledRoomModal';
import { openPasswordModal } from '../../modals/passwordModal/passwordModal';
import './sidebarOption.scss';

const SidebarOption = (props: { title: string; id: string }) => {
  let history = useHistory();
  const menuState = useSelector(getSidebarMenuStateRedux);
  const spacesRooms = useSelector(getSpacesRooms);
  const [dropDownState, setDropDownState] = useState(false);
  const switchRoom = (id: string) => {
    const from = spacesRooms.rooms[id]?.roomDetails?.schedule?.from;
    const till = spacesRooms.rooms[id]?.roomDetails?.schedule?.till;
    const isAdmin = spacesRooms.rooms[id]?.permission?.isAdmin;
    if (
      !isAdmin &&
      ((from && from > new Date().getTime() / 1000) || (till && till !== -1 && till < new Date().getTime() / 1000))
    ) {
      openClosedRoomModal(id, true)();
      toggleSidebarStore(false);
    } else if (
      spacesRooms.rooms[id]?.roomDetails?.enabledPassword &&
      !spacesRooms.rooms[id]?.permission?.isAdmin &&
      spacesRooms.currentRoom !== id
    ) {
      openPasswordModal(id)();
      toggleSidebarStore(false);
      sendAmplitudeData('Room switched', { status: 'failed', nextRoomId: id });
    } else {
      rooms.setClaimThenRoom(props.id);
      sendAmplitudeData('Room switched', { status: 'success', nextRoomId: id });
      if (spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID)
        history.push(handleSubdomain(`/s/${spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID}/r/${id}`));
    }
  };

  const highLightedRoom = useSelector(getHighlightedRoomStateRedix);

  const [onlineUsers, setOnlineUsers] = useState<{ name: string; profilePic: string; uid: string; color: string }[]>(
    []
  );
  const [expand, setExpand] = useState(spacesRooms.currentRoom === props.id);
  const presenceRoom = useSelector(getPresenceOfRoom(props.id));

  useEffect(() => {
    if (menuState) {
      setExpand(spacesRooms.currentRoom === props.id);
    }
  }, [menuState]);

  useEffect(() => {
    if (props.id === highLightedRoom) {
      setTimeout(() => setHighlightedRoomStore(''), 4000);
    }
  }, [highLightedRoom]);

  useEffect(() => {
    const onlineusers: { name: string; profilePic: string; uid: string; color: string }[] = [];
    for (let roomCopy in presenceRoom) {
      for (let uid in presenceRoom[roomCopy]) {
        const user = presenceRoom[roomCopy][uid];
        if (user.online) {
          onlineusers.push({ ...user, uid, name: user.name });
        }
      }
    }
    setOnlineUsers(onlineusers);
  }, [presenceRoom]);

  return (
    <>
      <div
        className={
          'sidebar-option open-pass-modal ' +
          (spacesRooms.currentRoom === props.id ? 'current ' : '') +
          (props.id === highLightedRoom ? 'hightlighted' : '')
        }
      >
        <div
          className="onlineUser"
          style={onlineUsers.length > 0 ? { fontWeight: 900 } : { fontWeight: 900, cursor: 'none' }}
          onClick={(e) => {
            setExpand(!expand);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {onlineUsers.length > 0 && (
            <>
              <User />
              <div className="numOnline">{onlineUsers.length}</div>
            </>
          )}

          {/* Hide triple dot menu for now */}
          {/* {spacesRooms.currentUserRole === 'h' && (
            <div className="moreHorizontalContainer">
              <div
                className="moreHorizontal"
                style={{ visibility: spacesRooms.currentRoom === props.id ? 'visible' : 'hidden' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleRoomSettingsMenuStore(true);
                  togglePermissionMenuStore(false);
                  toggleMembersMenuStore(false);
                  setRoomCreateOrEditStore('edit');
                }}
              >
                <MoreHorizontal />
              </div>
              {/* {dropDownState && (
              <DropDownRoom
                toClose={() => {
                  setDropDownState(false);
                }}
              />
            )} 
            </div>
          )} */}
        </div>
        {/* <div className="sidebar-option__setting">
          {(spacesRooms.currentUserRole === 'h' || spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isAdmin) &&
            spacesRooms.currentRoom === props.id && (
              <SettingsSVG fill="#fefefe" onClick={(e: any) => openSettings(e)} />
            )}
        </div> */}
        <div className="sidebar-option__details">
          <div
            className="expand"
            onClick={(e) => {
              setExpand(!expand);
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {!expand ? (
              <div className="arrowDown right">
                <ChevronRight />
              </div>
            ) : (
              <div className="arrowDown">
                <ChevronDown />
              </div>
            )}
          </div>
          <div
            className="room-name"
            onClick={() => {
              sendAmplitudeData('Room clicked', { nextRoomName: props.title });
              switchRoom(props.id);
            }}
          >
            {formatLong(props.title, 24)}
          </div>
        </div>
      </div>
      {expand && (
        <div className={'sidebar-option__live-users ' + (spacesRooms.currentRoom === props.id ? 'current' : '')}>
          {_.map(onlineUsers, (user) => {
            return (
              <div className="username" key={user.uid}>
                <div>
                  <div className="avatarUser">
                    <Avatar src={user.profilePic} name={user.name} color={user.color} omitCircle={true} />
                  </div>
                  <span>{formatLong(getFirstName(user.name), 8)}</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default SidebarOption;
