import React from 'react';
import './loader.scss';

const Loader = () => {
  return (
    <div className="loaderCont">
      <div className="loaderMain"></div>
    </div>
  );
};

export default Loader;
