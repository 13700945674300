import React, { useState } from 'react';
import { Volume, Volume1, Volume2, VolumeX } from 'react-feather';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { useSelector } from 'react-redux';
import { setCurrentScreenVol } from '../../store';
import { getIsScreenWallRedux, getScreenVolRedux } from '../../store/wallpaper';
import './volComp.scss';

const VolumeComponent = () => {
  const volume = useSelector(getScreenVolRedux);
  const [showSlider, setShowSlider] = useState(false);
  const isScreenWall = useSelector(getIsScreenWallRedux);

  if (isScreenWall)
    return (
      <>
        <div
          className="globalVol"
          onClick={() => {
            setShowSlider(!showSlider);
          }}
        >
          {volume > 66 && <Volume2 size={18} />}
          {volume <= 66 && volume > 33 && <Volume1 size={18} />}
          {volume <= 33 && volume > 0 && <Volume size={18} />}
          {volume === 0 && <VolumeX size={18} />}
        </div>
        {showSlider && (
          <div className="slider orientation-reversed">
            <div className="slider-group">
              <div className="slider-vertical">
                <Slider
                  min={0}
                  max={100}
                  value={volume}
                  orientation="vertical"
                  onChange={(value) => {
                    setCurrentScreenVol(value);
                  }}
                  onChangeComplete={() => {
                    setShowSlider(false);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </>
    );
  else return <></>;
};

export default VolumeComponent;
