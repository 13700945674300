import _ from 'lodash';
import { CheckLg } from 'react-bootstrap-icons';
import './planCard.scss';

// In future this data should be obtained from DB
const PlanFeatures = (type: {
  product: 'free' | 'team' | 'event' | 'appsumo';
  plan: 'starter' | 'pro' | 'enterprise' | 'tier-1' | 'tier-2' | 'tier-3' | 'tier-4' | 'tier-4-event' | '';
}) => {
  if (type.product === 'free') {
    return [
      'YouTube watch party',
      '15 pre-defined templates',
      'Simultaneous screen shares per room',
      'Custom wallpapers',
      'Megaphone',
      '24 / 5 Email support',
      'Advanced room permissions',
      '60 minutes/Day',
      '1 room limit',
      '10 users/admin/hosts limit',
      '1 screen shares per room',
      '1 Google work apps (per room)',
      '1 simultaneous whiteboards',
    ];
  } else if (type.product === 'team') {
    return [
      'YouTube watch party',
      '15 pre-defined templates',
      'Simultaneous screen shares per room',
      'Custom wallpapers',
      'Megaphone',
      '24 / 5 Email support',
      'Advanced user roles and room permissions',
      'Unlimited minutes',
      'Unlimited rooms',
      '1 screen shares per room',
      '1 Google work apps (per room)',
      '1 simultaneous whiteboards',
      '200 free guest minutes per team per month',
    ];
  } else if (type.product === 'event') {
    return [
      'YouTube watch party',
      '15 pre-defined templates',
      'Simultaneous screen shares per room',
      'Custom wallpapers',
      'Megaphone',
      '24 / 5 Email support',
      'Advanced user roles and room permissions',
      'Unlimited minutes',
      'Unlimited rooms',
      '1 screen shares per room',
      '1 Google work apps (per room)',
      '1 simultaneous whiteboards',
    ];
  } else if (type.product === 'appsumo' && type.plan === 'tier-1') {
    return [
      'YouTube watch party',
      '15 pre-defined templates',
      'Simultaneous screen shares per room',
      'Custom wallpapers',
      'Megaphone',
      '24 / 5 Email support',
      'Advanced user roles and room permissions',
      'Unlimited minutes',
      'Unlimited rooms (1 for every 100 participants)',
      '5 users/admin/hosts limit',
      '4 screen shares per room',
      '4 Google work apps (per room)',
      '4 simultaneous whiteboards',
      '1000 free guest minutes per team per month',
    ];
  } else if (type.product === 'appsumo' && type.plan === 'tier-2') {
    return [
      'YouTube watch party',
      '15 pre-defined templates',
      'Simultaneous screen shares per room',
      'Custom wallpapers',
      'Megaphone',
      '24 / 5 Email support',
      'Advanced user roles room permissions',
      'Unlimited minutes',
      'Unlimited rooms (1 for every 100 participants)',
      '20 users/admin/hosts limit',
      '8 screen shares per room',
      '4 Google work apps (per room)',
      '4 simultaneous whiteboards',
      '4000 free guest minutes per team per month',
    ];
  } else if (type.product === 'appsumo' && type.plan === 'tier-3') {
    return [
      'YouTube watch party',
      '15 pre-defined templates',
      'Simultaneous screen shares per room',
      'Custom wallpapers',
      'Megaphone',
      '24 / 5 Email support',
      'Advanced user roles room permissions',
      'Unlimited minutes',
      'Unlimited rooms (1 for every 100 participants)',
      '75 users/admin/hosts limit',
      '35 screen shares per room',
      '6 Google work apps (per room)',
      '6 simultaneous whiteboards',
      '15000 free guest minutes per team per month',
    ];
  } else if (type.product === 'appsumo' && type.plan === 'tier-4') {
    return [
      'YouTube watch party',
      '15 pre-defined templates',
      'Simultaneous screen shares per room',
      'Custom wallpapers',
      'Megaphone',
      'Announcements',
      '24 / 5 Email support',
      'Advanced user roles room permissions',
      'Unlimited minutes',
      'Unlimited rooms (1 for every 100 participants)',
      '150 users/admin/hosts limit',
      '35 screen shares per room',
      '8 Google work apps (per room)',
      '8 simultaneous whiteboards',
      '30000 free guest minutes per team per month',
    ];
  } else if (type.product === 'appsumo' && type.plan === 'tier-4-event') {
    return [
      '10 team members (ability to act as room admin or room host)',
      '750 attendees per month',
      '6 Apps (Miro, Google Docs, and others) per room',
      'YouTube watch party',
      '15 pre-defined templates',
      '35 screen shares per room',
      'Custom wallpapers',
      'Megaphone',
      'Announcements',
      'User roles & permissions',
      'Unlimited rooms',
      'Unlimited minutes per team member',
    ];
  } else {
    return [
      'YouTube watch party',
      '15 pre-defined templates',
      'Simultaneous screen shares per room',
      'Custom wallpapers',
      'Megaphone',
      '24 / 7 Email support',
      'Advanced room permissions',
      '60 minutes/Day',
      '1 room limit',
      '10 users/admin/hosts limit',
      '1 screen shares per room',
      '1 Google work apps (per room)',
      '1 simultaneous whiteboards',
    ];
  }
};

const PlanHeading = (type: 'free' | 'team' | 'event' | 'appsumo') => {
  if (type === 'free') {
    return {
      title: 'Free Plan',
      subTitle: 'USE IT FREE FOREVER',
    };
  } else if (type === 'team') {
    return {
      title: 'Team Plan',
      subTitle: 'MONTHLY / PER MEMBER',
    };
  } else if (type === 'event') {
    return {
      title: 'Event Plan',
      subTitle: 'MONTHLY / PER GUEST',
    };
  } else if (type === 'appsumo') {
    return {
      title: 'AppSumo',
      subTitle: 'LIFETIME ACCESS 🌮',
    };
  } else {
    return {
      title: '',
      subTitle: '',
    };
  }
};

const PlanStyle = (type: 'free' | 'team' | 'event' | 'appsumo', isCurrent: boolean) => {
  if (!isCurrent) {
    if (type === 'free') {
      return {
        planBackground: { background: '#393b41' },
        heading: { background: '#1dabca' },
        memberBar: { background: '#1dabca' },
      };
    } else if (type === 'team') {
      return {
        planBackground: { background: 'rgba(0,0,0,1)', boxShadow: '0px 0px 10px rgba(52, 227, 185, 0.5)' },
        heading: { background: 'linear-gradient(90deg, #B9C900 46.77%, #34E3B9 89.15%)' },
        memberBar: {
          background: ' linear-gradient(90deg, rgba(185, 201, 0, 0.7) 38.1%, rgba(52, 227, 185, 0.7) 62.83%)',
        },
      };
    } else if (type === 'event') {
      return {
        planBackground: { background: 'rgba(0,0,0,1)', boxShadow: '0px 0px 10px rgba(255, 96, 191, 0.5)' },
        heading: { background: 'linear-gradient(90deg, #7593FF 10.67%, #7593FF 29.54%, #FF73C7 89.15%)' },
        memberBar: {
          background: 'linear-gradient(90deg, rgba(117, 147, 255, 0.7) 37.17%, rgba(255, 96, 191, 0.7) 62.83%)',
          backdropFilter: 'blur(40px)',
        },
      };
    } else if (type === 'appsumo') {
      return {
        planBackground: { background: 'rgba(0,0,0,1)', boxShadow: '0px 0px 10px rgba(254,148,0, 0.5)' },
        heading: { background: 'linear-gradient(129deg, rgba(254,148,0,1) 25%, rgba(255,255,255,1) 100%)' },
        memberBar: {
          background: 'rgba(254,148,0,1)',
          backdropFilter: 'blur(40px)',
        },
      };
    } else {
      return {
        planBackground: { background: '#393b41' },
        heading: { background: '#1dabca' },
        memberBar: { background: '#1dabca' },
      };
    }
  } else {
    if (type === 'free') {
      return {
        planBackground: { background: '#393b41' },
        heading: { background: '#1dabca' },
        memberBar: { background: '#1dabca' },
      };
    } else if (type === 'team') {
      return {
        planBackground: { background: '#393b41' },
        heading: { background: '#B9C900' },
        memberBar: {
          background: '#B9C900',
          backdropFilter: 'blur(40px)',
        },
      };
    } else if (type === 'event') {
      return {
        planBackground: { background: '#393b41' },
        heading: { background: '#7593FF' },
        memberBar: {
          background: '#7593FF',
          backdropFilter: 'blur(40px)',
        },
      };
    } else if (type === 'appsumo') {
      return {
        planBackground: { background: '#393b41' },
        heading: { background: 'rgba(254,148,0,1)' },
        memberBar: {
          background: 'rgba(254,148,0,1)',
          backdropFilter: 'blur(40px)',
        },
      };
    } else {
      return {
        planBackground: { background: '#393b41' },
        heading: { background: '#1dabca' },
        memberBar: { background: '#1dabca' },
      };
    }
  }
};

const PlanCard = (props: {
  planCardType: string;
  product: 'free' | 'team' | 'event' | 'appsumo';
  plan?: 'starter' | 'pro' | 'enterprise' | 'tier-1' | 'tier-2' | 'tier-3' | 'tier-4' | 'tier-4-event' | '';
  duration?: '4 HOURS' | 'DAILY' | 'MONTHLY' | 'ANNUALLY' | 'UNLIMITED' | '';
  members: string;
  unitPrice: string;
  invoiceItemId?: string;
  optionBtn?: string;
  setOptionAction?: any;
}) => {
  return (
    <>
      <div
        className="plan-card-container"
        style={PlanStyle(props.product, props.planCardType === 'current').planBackground}
      >
        <div className="plan-card-header">
          <div className="header-title" style={PlanStyle(props.product, props.planCardType === 'current').heading}>
            <div className="header-title-heading">
              <div className="plan-name">
                <span>{PlanHeading(props.product).title}</span>
              </div>
              <div className="plan-basic-info">
                <span>{PlanHeading(props.product).subTitle}</span>
              </div>
            </div>
            <div className="plan-cost">
              <span>{props.unitPrice}</span>
            </div>
          </div>
          <div className="header-plan-type"> {props.plan ? props.plan.toUpperCase() : ''} </div>
          <div
            className="header-plan-config"
            style={PlanStyle(props.product, props.planCardType === 'current').memberBar}
          >
            <div className="header-duration">{props.duration ? props.duration.toUpperCase() : ''}</div>
            <div className="header-members">{props.members.toUpperCase()}</div>
          </div>
        </div>
        <div className="plan-card-body">
          <div className="plan-feature">
            <div className="feature-text">
              <span>Here’s what you get -</span>
            </div>
          </div>
          {_.map(PlanFeatures({ product: props.product, plan: props.plan ? props.plan : '' }), (feature, num) => {
            return (
              <div className="plan-feature" key={num}>
                <div className="tick-icon">
                  <CheckLg size={12} color="#17C552" />
                </div>
                <div className="feature-text">{feature}</div>
              </div>
            );
          })}
        </div>

        {props.planCardType !== 'current' && props.optionBtn && props.optionBtn !== '' && (
          <div className="additional-button-section">
            <div className="option-btn" onClick={props.setOptionAction}>
              {props.optionBtn}
            </div>
          </div>
        )}

        {props.planCardType === 'current' && props.product !== 'appsumo' && (
          <div className="plan-card-footer">
            <span>CURRENT PLAN</span>
            <div
              className="action-btn"
              onClick={() => {
                window.open('https://calendly.com/ashwinreslash', '_blank');
              }}
            >
              TALK TO US
            </div>
          </div>
        )}

        {props.planCardType === 'current' && props.product === 'appsumo' && (
          <div className="plan-card-footer">
            <span>CURRENT PLAN</span>
            <div
              className="action-btn"
              onClick={() => {
                const URL = `https://appsumo.com/account/redemption/${props.invoiceItemId}/#change-plan`;
                window.open(URL, '_blank');
              }}
            >
              UPGRADE
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PlanCard;
