import { configureStore, createSlice } from '@reduxjs/toolkit';
import { volBounds } from '../config';

export type spatialPosition = { x: number; y: number; el: null | HTMLAudioElement };
export type source = {
  volume: number;
  position: spatialPosition;
  influenceGrid: [number, number][];
  currentGrid: [number, number];
};

export type spatialAudio = { [id: string]: source };

let initialState: { sources: spatialAudio; listener: source } = {
  sources: {},
  listener: { volume: 0, position: { x: 0, y: 0, el: null }, influenceGrid: [], currentGrid: [0, 0] },
};

const getInfluenceGrid = (
  x: number,
  y: number,
  lastGrid: [number, number],
  influence = false
): [[number, number], [number, number][]] => {
  const currentGrid: [number, number] = [Math.floor(x / volBounds.upper), Math.floor(y / volBounds.lower)];
  const influenceGrid: [number, number][] = [];
  // if(influence) {
  //   for(let i = currentGrid[0] - 1; i <= currentGrid[0] + 1; i++) {
  //     for(let j = currentGrid[1] - 1; j <= currentGrid[1] + 1; j++) {
  //       influenceGrid.push([i, j])
  //     }
  //   }
  // }
  return [currentGrid, influenceGrid];
};

export const spatialAudioSlice = createSlice({
  name: 'spatialAudio',
  initialState,
  reducers: {
    addSource(state, action) {
      state.sources[action.payload.id] = {
        volume: 0,
        position: { x: action.payload.position.x, y: action.payload.position.y, el: action.payload.el },
        influenceGrid: [],
        currentGrid: [0, 0],
      };
    },
    addListener(state, action) {
      const [currentGrid, influenceGrid]: [[number, number], [number, number][]] = [[0, 0], [[0, 0]]];
      state.listener = {
        volume: 0,
        position: {
          x: 0,
          y: 0,
          el: action.payload.el,
        },
        currentGrid,
        influenceGrid,
      };
    },
    updateSource(state, action) {
      if (!action.payload.isListener)
        if (state.sources[action.payload.id]) {
          const pos = {
            x: action.payload.x ? action.payload.x : state.sources[action.payload.id].position.x,
            y: action.payload.y ? action.payload.y : state.sources[action.payload.id].position.y,
            el: action.payload.el ? action.payload.el : state.sources[action.payload.id].position.el,
          };
          const [currentGrid, influenceGrid]: [[number, number], [number, number][]] = [[0, 0], [[0, 0]]];
          state.sources[action.payload.id] = {
            volume: action.payload.volume ? action.payload.volume : state.sources[action.payload.id].volume,
            position: pos,
            currentGrid,
            influenceGrid,
          };
        } else {
        }
      else {
        const pos = {
          x: action.payload.x ? action.payload.x : state.listener.position.x,
          y: action.payload.y ? action.payload.y : state.listener.position.y,
          el: action.payload.el ? action.payload.el : state.listener.position.el,
        };
        const [currentGrid, influenceGrid]: [[number, number], [number, number][]] = [[0, 0], [[0, 0]]];
        state.listener = {
          volume: action.payload.volume ? action.payload.volume : state.listener.volume,
          position: pos,
          currentGrid,
          influenceGrid,
        };
      }
    },
  },
});

export const spatialAudioStore = configureStore({
  reducer: spatialAudioSlice.reducer,
});

export const getSpatialState = (state: { spatialAudio: ReturnType<typeof spatialAudioStore.getState> }) =>
  state.spatialAudio;
export const getGetSpatialVolume = (id: string) => (state: {
  spatialAudio: ReturnType<typeof spatialAudioStore.getState>;
}) => state.spatialAudio.sources[id]?.volume;

export const getSpatialListenerState = (state: { spatialAudio: ReturnType<typeof spatialAudioStore.getState> }) =>
  state.spatialAudio.listener;
