import React, { Component, ErrorInfo, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';
import App from './App';
import logger from './helper/logger';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import mainStore, { setActivityTimestampStore } from './store';

interface Props {
  children: ReactNode;
}

class ErrorWrapper extends Component<Props> {
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error('Unexpected error:', { errorPayload: { error, message: error.message, errorInfo } });
  }

  public render() {
    return this.props.children;
  }
}

ReactModal.setAppElement('#root');

ReactDOM.render(
  <ErrorWrapper>
    <Provider store={mainStore}>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        onClick={() => {
          setActivityTimestampStore(new Date().getTime());
        }}
      >
        <App />
      </div>
    </Provider>
  </ErrorWrapper>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
