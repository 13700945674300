import React, { useRef, useState } from 'react';
import { addNewCanvasChild } from '../../../helper/interactive/firebaseRTD';
import { getSpawnLocation } from '../../../helper/interactive/misc';
import { getAuthState, getListenerPosition } from '../../../store';
import { useOutsideEvent } from '../giphy/Giphy';
import './ytDialog.scss';

function validateYouTubeUrl(url: string) {
  if (url !== undefined || url != '') {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length === 11) {
      return true;
    }
  }
  return false;
}

const YTDialog = (props: { canvasID: string; spaceID: string; toClose: () => void }) => {
  const [url, setURL] = useState('');
  const [msg, setMsg] = useState('');
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideEvent(ref, props.toClose, 'yt_btn');

  const addYT = () => {
    if (validateYouTubeUrl(url)) {
      const spawnLocation = getSpawnLocation(getListenerPosition(), 500);
      addNewCanvasChild(props.spaceID, props.canvasID, {
        w: 500,
        h: 300,
        x: spawnLocation.x,
        y: spawnLocation.y,
        t: 'yt',
        d: { u: url, p: false, s: 0, st: '', uid: getAuthState().user.id },
      }).then(props.toClose);
    } else setMsg('Invalid youtube URL.');
  };

  return (
    <div ref={ref}>
      <div className="ytDialogContainer">
        <input placeholder="Youtube Link" value={url} onChange={(e) => setURL(e.target.value)}></input>
        <div className="btn" onClick={addYT}>
          Go!
        </div>
      </div>
      <span style={{ marginTop: '10px', display: 'block', fontSize: '12px', color: 'white', fontWeight: 600 }}>
        {msg}
      </span>
    </div>
  );
};

export default YTDialog;
