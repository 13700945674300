import * as crypto from 'crypto';
import { addIframe } from '../../iframeLinkBoxModal';

export const md5 = (contents: string) => crypto.createHash('md5').update(contents).digest('hex');

const generateGameURL = (spaceID: string, canvasID: string, userID: string) => {
  const currentTimestamp = new Date().valueOf();
  const baseurl = 'https://www.horsepaste.com/';

  const gameid = spaceID.concat(canvasID).concat(userID).concat(currentTimestamp.toString());

  let base64data = md5(gameid);
  return baseurl.concat(base64data);
};

export const addCodenameGame = (
  spaceID: string,
  canvasID: string,
  userID: string,
  onCancel: () => void,
  setMsg: (msg: string) => void
) => {
  const gameURL = generateGameURL(spaceID, canvasID, userID);
  addIframe(gameURL, spaceID, canvasID, onCancel, setMsg, 'Codename');
};
