import firebase from 'firebase';
const debug = (...data: any[]) => {};

interface ReslashFirebaseState {
  isLoggedIn: boolean;
  user: firebase.User;
}

export enum APP_TYPE {
  WHITE_BOARDS = 'whiteboards',
}

const getAppDefaultData = (appType: APP_TYPE, userId: string) => {
  if (appType === APP_TYPE.WHITE_BOARDS) {
    return {
      appData: {
        sceneData: {
          //  @ts-ignore
          // elements: [],
          elements: [],
          appState: {
            viewBackgroundColor: '#ffffff',
          },
        },
      },
      createdBy: userId,
      updatedBy: userId,
    };
  }
};
class ReslashFirebase {
  firebase = firebase;
  database!: firebase.database.Database;

  public setUpFirebase(f: any) {
    if (f) {
      this.firebase = f;
      this.database = this.firebase.database();
    }
  }

  // public setUpFirebaseConfig = (config: FirebaseConfig) => {
  //   this.firebase.initializeApp(config);
  //   this.database = firebase.database();
  //   // @ts-ignore
  //   window.reslashAppsDatabase = database;
  //   return this.database;
  // };

  public createApp = (appType: APP_TYPE, spaceId: string, roomId: string, appId: string, userId: string) => {
    return new Promise((resolve, reject) => {
      this.database
        .ref(`apps/${appType}/${spaceId}/${roomId}/${appId}`)
        .set(getAppDefaultData(appType, userId), (error) => {
          if (error) {
            /**
             * throw error
             */
            reject(error);
          }
          resolve(
            this.database
              .ref(`apps/${appType}/${spaceId}/${roomId}/${appId}`)
              .get()
              .then((snapshot) => snapshot.val())
          );
        });
    });
  };

  public getApp = async (appType: APP_TYPE, spaceId: string, roomId: string, appId: string, userId: string) => {
    /**
     * check if the app is already present if not create app with basic data
     */
    const value = (await this.database.ref(`apps/${appType}/${spaceId}/${roomId}/${appId}`).get()).val();
    if (value === null) {
      /**
       * create new app
       */
      return new Promise((resolve, reject) => {
        this.database
          .ref(`apps/${appType}/${spaceId}/${roomId}/${appId}`)
          .set(getAppDefaultData(appType, userId), (error) => {
            if (error) {
              /**
               * throw error
               */
              reject(error);
            }
            resolve(
              this.database
                .ref(`app/${appType}/${spaceId}/${roomId}/${appId}`)
                .get()
                .then((snapshot) => snapshot.val())
            );
          });
      });
    } else {
      return value;
    }
  };

  public updateAppData = async (
    appType: APP_TYPE,
    spaceId: string,
    roomId: string,
    appId: string,
    userId: string,
    appData: any
  ) => {
    debug('firebase update called');
    // database.
    // var sfDocRef = database.collection(`apps/${appType}`).doc("SF");
    /**
     * UPDATE THE APP IN TRANSACTION
     */
    this.database.ref(`apps/${appType}/${spaceId}/${roomId}/${appId}`).update({
      appData: appData,
      updatedBy: userId,
    });
  };

  public watchForAppChanges(
    appType: APP_TYPE,
    spaceId: string,
    roomId: string,
    appId: string,
    callBack: (p: any) => void
  ) {
    const ref = this.database.ref(`apps/${appType}/${spaceId}/${roomId}/${appId}`);
    const callback = (snapshot: any) => {
      callBack(snapshot.val());
    };
    ref.on('value', callback);
    return () => {
      ref.off('value', callback);
    };
  }
}

export const reslashAppsFirebase = new ReslashFirebase();
