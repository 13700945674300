import React, { useEffect, useState } from 'react';
import CursorPresence from './CursorPresence';
import { APP_TYPE, watchForCursorPresenceChanges } from './firebase';
const debug = (...data: any[]) => {};

interface WhiteBoardCursorsProps {
  spaceId: string;
  roomId: string;
  appId: string;
  userId: string;
}

const WhiteBoardCursors = (props: WhiteBoardCursorsProps) => {
  const [cursorPresence, setCursorPresence] = useState<any>({});
  useEffect(() => {
    /**
     * subscribe to the changes of cursor
     */
    const unWatch = watchForCursorPresenceChanges(
      APP_TYPE.WHITE_BOARDS,
      props.spaceId,
      props.roomId,
      props.appId,
      (cursorPresenceUpdates) => {
        if (cursorPresenceUpdates !== null) {
          debug('cursorPresence: ', cursorPresenceUpdates);
          setCursorPresence(cursorPresenceUpdates);
        }
      }
    );
    return () => {
      unWatch();
    };
  }, []);
  return (
    <div>
      {Object.keys(cursorPresence).map(
        (uid) =>
          uid !== props.userId && (
            <CursorPresence
              userId={uid}
              x={cursorPresence[uid].x}
              y={cursorPresence[uid].y}
              uname={cursorPresence[uid].uname}
            />
          )
      )}
    </div>
  );
};

export default WhiteBoardCursors;
