import firebase from 'firebase';
import React, { useEffect, useRef, useState } from 'react';
import { Check, ChevronDown, ChevronRight, ChevronUp, Target, X } from 'react-feather';
import { useSelector } from 'react-redux';
import { maxParticipants, spillOverLimit } from '../../../config';
import { validateEmail } from '../../../helper/interactive/misc';
import {
  setCreateAdvanceParamsStore,
  setCreateRoomEmailsStore,
  setCreateRoomNameStore,
  setCreateRoomStepStore,
  setCreateRoomTypeStore,
  toggleMembersMenuStore,
  togglePermissionMenuStore,
  toggleRoomDetailsMenuStore,
  toggleRoomWallMenuStore,
} from '../../../store';
import { getAuth } from '../../../store/auth';
import { getCreateRoomParamsRedux } from '../../../store/createRoomFlow';
import { getRoomCreateOrEditStateRedux, getRoomEditStateRedux } from '../../../store/settingsMenu';
import { getSpacesRooms } from '../../../store/spacesRooms';
import { useOutsideEvent } from '../../interactive/giphy/Giphy';
import LoaderV2 from '../../loader/loaderV2';
import TagInput from '../../tagsInput/tagInput';
import './createNewRoom.scss';
import { AdvancedSettings } from './settingsSidebar';

const UploadCSV = () => {
  const [uploadCSVCheck, setUploadCSVCheck] = useState(false);
  return (
    <>
      <div className="upload-csv-container">
        <div className="upload-csv-check">
          <div
            className="upload-csv-checkbox"
            onClick={() => {
              setUploadCSVCheck(!uploadCSVCheck);
            }}
          >
            {uploadCSVCheck && <Check color={'white'} size={12} />}
          </div>
          <span>Upload CSV or connect Google Sheets</span>
        </div>
        {uploadCSVCheck && (
          <>
            <div className="upload-csv-buttons-section">
              <div className="upload-button">
                <span>Upload CSV</span>
              </div>
              <div className="upload-button">
                <span>Google Sheets</span>
              </div>
            </div>
            <div className="upload-csv-preview-section">
              <span>Here's the CSV you've uploaded</span>
              <div className="upload-csv-preview-file">
                <div className="upload-csv-preview-file-item">Event 1.csv</div>
                <div className="upload-csv-preview-file-item">(Remove)</div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const CreateNewRoom = (props: {
  toClose: () => void;
  onChange?: (state: boolean) => void;
  newValues?: (name: string, permission: 'public' | 'team' | 'private', emails?: string[]) => void;
}) => {
  const [type, setType] = useState<'team' | 'public' | 'private'>('public');
  const [roomName, setRoomName] = useState('');
  const roomParams = useSelector(getCreateRoomParamsRedux);
  const [openRoomType, setOpenRoomType] = useState(false);
  const typeMenuRef = useRef<HTMLDivElement | null>(null);
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);
  const advancedSettingsRef = useRef<any>();
  const [creating, setCreating] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [msg, setMsg] = useState('');
  // const [currentWall, setCurrentWall] = useState<string | null>(null);
  const createOrEdit = useSelector(getRoomCreateOrEditStateRedux);
  const roomCollapseState = useSelector(getRoomEditStateRedux);
  const auth = useSelector(getAuth);
  const spacesRooms = useSelector(getSpacesRooms);
  const isAdmin = spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin;
  const [advanceParams, setAdvanceParams] = useState({
    password: '',
    startTime: -1,
    endTime: -1,
    timeZone: {
      name: '',
      offset: -1,
    },
    limit: maxParticipants,
    spillOverAt: spillOverLimit,
  });
  const [existingMembers, setExistingMembers] = useState<string[]>([]);
  const [roomCurrent, setRoomCurrent] = useState<{
    copyNumber: number;
    createdBy: string;
    createdOn: Date;
    guestAllowed: boolean;
    isCopy: boolean;
    isDeleted: boolean;
    isLocked: boolean;
    isOpen: boolean;
    name: string;
    parentRoom: string;
    spaceID: string;
    updatedOn: Date;
    schedule?: {
      from: number;
      till: number;
      timezone: {
        timezoneOffset: number;
        timezoneStr: string;
      };
    };
  } | null>(null);
  const [memberEmails, setMemberEmails] = useState<string[]>([]);
  const [invitedMembers, setInvitedMembers] = useState<string[]>([]);
  const [roomAdminEmails, setRoomAdminEmails] = useState<string[]>([]);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    let unsub = () => {};
    if (createOrEdit === 'edit') {
      unsub = firebase
        .firestore()
        .collection('roomV2')
        .doc(spacesRooms.currentRoom)
        .onSnapshot((room) => {
          const roomData: any = room.data();
          if (roomData) setRoomCurrent(roomData);
        });
    }
    return unsub;
  }, [createOrEdit, spacesRooms.currentRoom]);

  useEffect(() => {
    let unsub = () => {};
    unsub = firebase
      .firestore()
      .collection('spacePermissionV2')
      .where('spaceID', '==', spacesRooms.currentSpace)
      .where('permission.isTeamMember', '==', true)
      .where('isDeleted', '==', false)
      .onSnapshot((permissions) => {
        const members: any = permissions.docs;
        if (members) {
          const emails: string[] = [];
          for (let i in members) {
            const member = members[i].data();
            if (member?.userInfo?.email && validateEmail(member.userInfo.email)) emails.push(member.userInfo.email);
          }
          setMemberEmails(emails);
        }
      });
    return unsub;
  }, [spacesRooms.currentSpace]);

  useEffect(() => {
    if (roomCurrent) {
      if (roomCurrent.isLocked) setType('private');
      else if (roomCurrent.guestAllowed) setType('public');
      else setType('team');
    }
  }, [roomCurrent]);

  useEffect(() => {
    let unsub = () => {};
    if (createOrEdit === 'edit') {
      unsub = firebase
        .firestore()
        .collection('roomPermissionV2')
        .where('roomID', '==', spacesRooms.currentRoom)
        .where('isDeleted', '==', false)
        .onSnapshot((permissions) => {
          const members: any = permissions.docs;
          if (members) {
            const emails: string[] = [];
            const admins: string[] = [];
            for (let i in members) {
              const member = members[i].data();
              if (member?.userInfo?.email && validateEmail(member.userInfo.email)) emails.push(member.userInfo.email);
              else if (member?.inviteEmail && validateEmail(member.inviteEmail)) emails.push(member.inviteEmail);
              if (member?.userInfo?.email && member?.permission?.isAdmin && validateEmail(member.userInfo.email)) {
                admins.push(member.userInfo.email);
              }
              if (member?.userInfo?.email && member.userID === auth.user.id && validateEmail(member.userInfo.email)) {
                setUserEmail(member.userInfo.email);
              }
            }
            setExistingMembers(emails);
            setRoomAdminEmails(admins);
          }
        });
    }
    return unsub;
  }, [createOrEdit, spacesRooms.currentRoom]);

  useEffect(() => {
    if (createOrEdit === 'edit') {
      setRoomName(spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails.roomName ?? '');
    }
  }, [spacesRooms.currentRoom]);

  useOutsideEvent(typeMenuRef, () => setOpenRoomType(false), 'dropDown', '#dropDown');

  useEffect(() => {
    if (spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.roomName !== roomName) {
      if (props.onChange) props.onChange(true);
    }
  }, [roomName, spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.roomName]);

  useEffect(() => {
    if (props.newValues) {
      props.newValues(roomName, type, type === 'private' ? invitedMembers : []);
    }
  }, [roomName, type, invitedMembers]);

  const [errorMsg, setErrorMsg] = useState('');

  return (
    <div
      className="createRoom"
      style={{
        paddingTop: '0px',
        paddingBottom: '0px',
        marginRight: '7px',
      }}
    >
      {(createOrEdit === 'create' || createOrEdit === 'create-dynamic') && (
        <div className="headerNewRoom">
          <div className="left">
            <Target />
            Create your new {createOrEdit === 'create' ? 'spatial room' : 'dynamic room'}
          </div>
          <div className="right" style={{ cursor: 'pointer' }}>
            <X className="x" onClick={props.toClose} />
          </div>
        </div>
      )}
      <div className="body">
        {createOrEdit === 'edit' && isAdmin && (
          <div
            className="roomDetailsHeader"
            onClick={() => {
              toggleRoomDetailsMenuStore(!roomCollapseState.details);
              toggleRoomWallMenuStore(false);
              togglePermissionMenuStore(false);
              toggleMembersMenuStore(false);
            }}
          >
            {roomCollapseState.details ? <ChevronDown size={14} /> : <ChevronRight size={14} />}
            Room details
          </div>
        )}
        {(createOrEdit === 'create' || createOrEdit === 'create-dynamic' || roomCollapseState.details) && (
          <div
            className="nameType"
            style={{
              marginBottom: createOrEdit === 'create' || createOrEdit === 'create-dynamic' ? '35px' : '24px',
              paddingLeft: createOrEdit === 'create' || createOrEdit === 'create-dynamic' ? '0px' : '13px',
            }}
          >
            <div className="inputContainer">
              <input
                placeholder="Room name"
                value={createOrEdit !== 'edit' ? roomParams.name : roomName}
                onChange={(e) => {
                  if (createOrEdit !== 'edit') setCreateRoomNameStore(e.target.value);
                  else setRoomName(e.target.value);
                  setErrorMsg('');
                }}
                // style={{ paddingRight: createOrEdit === 'edit' ? '70px' : '10px' }}
              />

              {errorMsg.length > 0 && <div className="room-name-error-box">{errorMsg}</div>}
            </div>
            <div className="dropDown" id="dropDown" onClick={() => setOpenRoomType(!openRoomType)}>
              <div className="dropDownLabel" id="dropDownLabel">
                <div className="label">
                  <span>{type}</span>
                </div>
                <div className="dropDownIcon" style={{ pointerEvents: 'none' }}>
                  {openRoomType ? <ChevronUp /> : <ChevronDown />}
                </div>
              </div>
              {openRoomType && (
                <div className="dropDownMenu" ref={typeMenuRef}>
                  <div
                    className="option"
                    onClick={async () => {
                      if (createOrEdit === 'edit') setType('public');
                      else {
                        setCreateRoomTypeStore('public');
                        setType('public');
                      }
                      setOpenRoomType(false);
                    }}
                  >
                    Public
                  </div>
                  <div
                    className="option"
                    onClick={async () => {
                      if (createOrEdit === 'edit') setType('team');
                      else {
                        setCreateRoomTypeStore('team');
                        setType('team');
                      }
                      setOpenRoomType(false);
                    }}
                  >
                    Team
                  </div>
                  <div
                    className="option"
                    onClick={async () => {
                      if (createOrEdit === 'edit') setType('private');
                      else {
                        setCreateRoomTypeStore('private');
                        setType('private');
                      }
                      setOpenRoomType(false);
                    }}
                  >
                    Private
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {type === 'private' && (
          <>
            <div
              className="room-details-email-inputs"
              style={{ paddingLeft: createOrEdit === 'create' || createOrEdit === 'create-dynamic' ? '0px' : '13px' }}
            >
              <TagInput
                suggestions={memberEmails}
                fetchTags={(tags) => {
                  setInvitedMembers(tags);
                  if (createOrEdit !== 'edit') {
                    setCreateRoomEmailsStore(tags);
                  }
                }}
                existingTags={existingMembers}
                highlightedTag={roomAdminEmails}
                undeleteableTag={userEmail}
              />
            </div>
            {/*<UploadCSV />*/}
          </>
        )}

        <div className="backgroundSearch">
          {createOrEdit !== 'edit' && (
            <div className="settingsSection">
              <div
                className="settingHeading"
                onClick={() => {
                  setAdvancedSettingsOpen(!advancedSettingsOpen);
                }}
              >
                <div className="leftSide">
                  {advancedSettingsOpen ? <ChevronDown size={14} /> : <ChevronRight size={14} />}
                  Advanced Settings
                </div>
              </div>
              {advancedSettingsOpen && (
                <AdvancedSettings
                  ref={advancedSettingsRef}
                  onChange={(state) => {}}
                  isCreate={true}
                  isDynamicCreate={createOrEdit === 'create-dynamic'}
                  createParams={(password, startDate, endDate, timezone, capacity, spillOver) => {
                    setAdvanceParams({
                      password,
                      startTime: startDate,
                      endTime: endDate,
                      timeZone: timezone,
                      limit: capacity,
                      spillOverAt: spillOver,
                    });
                    setCreateAdvanceParamsStore({
                      roomType: createOrEdit === 'create-dynamic' ? 'dynamic' : 'spatial',
                      password,
                      startTime: startDate,
                      endTime: endDate,
                      timeZone: timezone,
                      limit: capacity,
                      spillOverAt: spillOver,
                    });
                  }}
                />
              )}
            </div>
          )}
          {(createOrEdit === 'create' || createOrEdit === 'create-dynamic') && (
            <div
              className="btnContainer"
              style={{
                justifyContent: !creating ? 'flex-end' : 'center',
              }}
            >
              {!creating && (
                <>
                  <div className="cancel" onClick={() => props.toClose()}>
                    Cancel
                  </div>
                  <div
                    className="createRoomBtn"
                    onClick={() => {
                      if (roomParams.name.length > 2) setCreateRoomStepStore('background');
                    }}
                  >
                    Continue
                  </div>
                  {msg}
                </>
              )}
              {creating && <LoaderV2 />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateNewRoom;
