import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthState } from '../../../store';
import { getSpacesRooms } from '../../../store/spacesRooms';
import { canvasChild, firebaseCallback } from '../../../types/canvasFB';
import { useOutsideEvent } from '../giphy/Giphy';
import './whiteboard.scss';

const WhiteBoard = (props: {
  spaceID: string;
  roomID: string;
  appID: string;
  onDeleteTrigger: Function;
  uname: string;
  uid: string;
  localUname: string;
  localUid: string;
  sm: boolean;
  onDataChange: (d: any, path: string) => void;
  watcher: (callback: (c: canvasChild) => void, path?: string) => firebaseCallback;
}) => {
  const iframeEl = useRef<HTMLIFrameElement>(null);
  const spacesRooms = useSelector(getSpacesRooms);

  const [showSettings, setShowSettings] = useState(false);
  const [showMenuBar, setShowMenuBar] = useState(props.sm);

  const iframeSettingsRef = useRef<HTMLDivElement | null>(null);

  useOutsideEvent(
    iframeSettingsRef,
    () => {
      setShowSettings(false);
    },
    'iframe-settings',
    '#iframe-settings'
  );

  useEffect(() => {
    const watcherSM = props.watcher((sm: any) => {
      if (sm !== null) {
        setShowMenuBar(sm);
      }
    }, '/d/sm');
    return () => {
      watcherSM();
    };
  }, []);

  const refreshIframe = () => {
    try {
      iframeEl.current?.contentWindow?.location.reload(true);
    } catch (error) {}
  };

  function requestFullScreen() {
    const requestFullScreenWrapper: () => Promise<void> =
      iframeEl.current?.requestFullscreen ||
      // @ts-ignore
      iframeEl.current?.mozRequestFullScreen ||
      // @ts-ignore
      iframeEl.current?.webkitRequestFullScreen ||
      // @ts-ignore
      iframeEl.current?.msRequestFullscreen;
    requestFullScreenWrapper.call(iframeEl.current).catch(() => {});
  }

  function onDeleteTrigger() {
    props.onDeleteTrigger();
  }

  return (
    <>
      <div className={`iframe-wrapper ${props.uid === props.localUid ? 'local-user-screenshare' : ''}`}>
        <iframe src={`/s/${props.spaceID}/r/${props.roomID}/c/${props.appID}`} ref={iframeEl} />

        <div
          className="iframe-header"
          style={{
            opacity: showMenuBar ? '1' : '0',
            visibility:
              showMenuBar || props.uid === getAuthState().user.id || spacesRooms.currentUserRole === 'h'
                ? 'visible'
                : 'hidden',
          }}
        >
          <p className="iframe-name">{props.uname ? props.uname.split(' ')[0] : 'Anonymous'}'s Whiteboard</p>
          <div className="iframe-right-icons">
            {(props.uid === getAuthState().user.id || spacesRooms.currentUserRole === 'h') && (
              <div
                className="icon-btn iframe-settings"
                style={{ padding: '1px 6px' }}
                onClick={() => {
                  setShowSettings(!showSettings);
                }}
                id="iframe-settings"
                ref={iframeSettingsRef}
              >
                <span className="material-icons">more_horiz</span>
                {showSettings && (
                  <div className="iframe-settings-dropdown">
                    <div
                      className="option"
                      onClick={() => {
                        props.onDataChange(!showMenuBar, 'sm');
                        setShowMenuBar(!showMenuBar);
                      }}
                    >
                      {showMenuBar ? 'Hide menu bar' : 'Show menu bar'}
                    </div>
                  </div>
                )}
              </div>
            )}
            <button className={`icon-btn`} onClick={refreshIframe} id="fullscreen-btn">
              <span className="material-icons">refresh</span>
            </button>
            <button className={`icon-btn`} onClick={requestFullScreen} id="fullscreen-btn">
              <span className="material-icons">fullscreen</span>
            </button>
            {(props.uid === getAuthState().user.id || spacesRooms.currentUserRole === 'h') && (
              <button className="icon-btn" onClick={onDeleteTrigger} id="iframe-close">
                <span className="material-icons">close</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhiteBoard;
