import { configureStore, createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    user: {
      id: '',
      name: '',
      email: '',
      token: '',
      isGuest: false,
      claims: {},
    },
    userDetails: {
      name: '',
      username: '',
      email: '',
      profilePic: '',
      desc: '',
      status: '',
      socialProfiles: {
        twitter: '',
        facebook: '',
        linkedin: '',
        phone: '',
        email: '',
        link: '',
      },
    },
    loading: true,
    redirectTo: '',
  },
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setRedirectTo: (state, action) => {
      state.redirectTo = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = {
        id: '',
        name: '',
        email: '',
        token: '',
        claims: {},
        isGuest: false,
      };
    },
    setClaims: (state, action) => {
      state.user = {
        ...state.user,
        ...state.userDetails,
        claims: action.payload.claims,
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const authStore = configureStore({
  reducer: authSlice.reducer,
});

export const getAuth = (state: { auth: ReturnType<typeof authStore.getState> }) => state.auth;
