import { configureStore, createSlice } from '@reduxjs/toolkit';

export const zoomSlice = createSlice({
  name: 'zoom',
  initialState: {
    level: 1,
  },
  reducers: {
    setZoom: (zoom, action) => {
      zoom.level = action.payload;
    },
  },
});

export const zoomStore = configureStore({
  reducer: zoomSlice.reducer,
  devTools: true,
});

export const getZoom = (zoom: { zoom: ReturnType<typeof zoomStore.getState> }) => zoom.zoom.level;
