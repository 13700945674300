import { configureStore, createSlice } from '@reduxjs/toolkit';

type modalStateType = {
  modals: { [id: string]: { isOpen: boolean } };
  notifications: { [id: string]: { isOpen: boolean } };
};
const initialState: modalStateType = { modals: {}, notifications: {} };
export const modalSlice = createSlice({
  name: 'ModalState',
  initialState,
  reducers: {
    newModal: (state, action) => {
      state.modals[action.payload.id] = { isOpen: action.payload.isOpen };
    },
    newNotification: (state, action) => {
      state.notifications[action.payload.id] = { isOpen: action.payload.isOpen };
    },
    showNotification: (state, action) => {
      if (state.notifications[action.payload.id]) {
        state.notifications[action.payload.id].isOpen = true;
      }
    },
    hideNotification: (state, action) => {
      if (state.notifications[action.payload.id]) {
        state.notifications[action.payload.id].isOpen = false;
      }
    },
    changeState: (state, action) => {
      if (state.modals[action.payload.id]) state.modals[action.payload.id].isOpen = action.payload.isOpen;
      else console.warn('No modal found:', action.payload.id);
    },
    openModal: (state, action) => {
      if (state.modals[action.payload]) {
        state.modals = { ...state.modals, [action.payload]: { isOpen: true } };
      } else {
        console.warn('No modal found:', action.payload);
      }
    },
    closeModal: (state, action) => {
      if (state.modals[action.payload]) state.modals[action.payload].isOpen = false;
      else console.warn('No modal found:', action.payload);
    },
    toggleModal: (state, action) => {
      if (state.modals[action.payload]) state.modals[action.payload].isOpen = !state.modals[action.payload].isOpen;
      else console.warn('No modal found:', action.payload);
    },
  },
});

export const modalStore = configureStore({
  reducer: modalSlice.reducer,
  devTools: true,
});

export const getGetModalState = (id: string) => (state: { modal: ReturnType<typeof modalStore.getState> }) =>
  state.modal.modals[id]?.isOpen;

export const getGetNotificationState = (id: string) => (state: { modal: ReturnType<typeof modalStore.getState> }) =>
  state.modal.notifications[id]?.isOpen;
