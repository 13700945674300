import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  deleteCanvasChild,
  updatePartiallyCanvasChild,
  watchCanvasChild,
  watchForDeleteCanvasChild,
  watchForNewCanvasChild,
} from '../../../helper/interactive/firebaseRTD';
import { getAuthState } from '../../../store';
import { getCustomClaimFromRedux } from '../../../store/customClaim';
import { getSpacesRooms } from '../../../store/spacesRooms';
import { canvasChild, canvasObj, firebaseCallback } from '../../../types/canvasFB';
import BasicDiv from '../draggables/basicDiv/basicDiv';
import { FileViewer } from '../FileViewer/fileViewer';
import ChessBoard from '../games/chess/chess';
import IframeViewer from '../IframeViewer/index';
import NoGoZone from '../noGoZones/noGoZones';
import Portal from '../portals/portal';
import QuillEditor from '../quillEditor/quillEditor';
import ShareLinkBoxViewer from '../ShareLinkBoxViewer';
import WhiteBoard from '../whiteboard/whiteboard';
import YTContainer from '../ytContainer/ytContainer';

const CanvasChild = (props: {
  onDeleteTrigger: Function;
  spaceID: string;
  canvasID: string;
  id: string;
  t: string;
  data: any;
  watcher: (callback: (c: canvasChild) => void, path?: string) => firebaseCallback;
  onDataChange: (d: any, path: string) => void;
  onUpdate: (d: any, path: string) => void;
}) => {
  const auth = getAuthState();
  const spacesRooms = useSelector(getSpacesRooms);

  if (props.t === 'gif' || props.t === 'image') {
    return <img src={props.data.u} alt="parrot_party" className="pointer-event-none" />;
  } else if (props.t === 'yt') {
    // return (
    //   <YTContainer
    //     key={props.id}
    //     spaceID={props.spaceID}
    //     canvasID={props.canvasID}
    //     id={props.id}
    //     data={props.data}
    //     watcher={props.watcher}
    //     onDataChange={props.onDataChange}
    //   />
    // );
    return <></>;
  } else if (props.t === 'qu') {
    return <QuillEditor watcher={props.watcher} onDataChange={props.onDataChange} />;
  } else if (props.t === 'gc') {
    return <ChessBoard boardID={props.id} watcher={props.watcher} onDataChange={props.onDataChange} />;
  } else if (props.t === 'nogo' && auth.user.id === props.data.uid) {
    return (
      <NoGoZone
        watcher={props.watcher}
        onDataChange={props.onDataChange}
        showFinaliseOptions={true}
        showSettings={false}
        highlight={true}
        tempID={props.id}
        spaceID={props.spaceID}
        roomID={props.canvasID}
        mainID={props.data.mainID}
      />
    );
  } else if (props.t === 'portal') {
    return (
      <Portal
        watcher={props.watcher}
        onDataChange={props.onDataChange}
        spaceID={props.spaceID}
        roomID={props.data.roomID}
        onDeleteTrigger={props.onDeleteTrigger}
      />
    );
  } else if (props.t === 'iframe') {
    // return (
    //   <IframeViewer
    //     url={props.data.u}
    //     onDeleteTrigger={props.onDeleteTrigger}
    //     uname={props.data.uname}
    //     uid={props.data.uid}
    //     localUname={auth.user.name}
    //     localUid={auth.user.id}
    //     app={props.data.f}
    //     sm={props.data.sm !== undefined ? props.data.sm : true}
    //     onDataChange={props.onDataChange}
    //     watcher={props.watcher}
    //   />
    // );
    return <></>;
  } else if (props.t === 'linkbox') {
    return (
      <ShareLinkBoxViewer
        watcher={props.watcher}
        url={props.data.u}
        title={props.data.title}
        favicon={props.data.favicon}
        onDeleteTrigger={props.onDeleteTrigger}
        onUpdate={props.onUpdate}
        isAdmin={spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isAdmin ?? false}
        uname={props.data.uname}
        uid={props.data.uid}
        localUname={auth.user.name}
        localUid={auth.user.id}
      />
    );
  } else if (props.t === 'file') {
    return (
      <FileViewer
        onDeleteTrigger={props.onDeleteTrigger}
        isAdmin={spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isAdmin ?? false}
        url={props.data.u}
        title={props.data.title}
        uid={props.data.uid}
        icon={props.data.i}
        spaceID={props.spaceID}
      />
    );
  } else if (props.t === 'whiteboard') {
    return (
      <WhiteBoard
        uid={props.data.uid}
        localUname={auth.user.name}
        localUid={auth.user.id}
        uname={props.data.uname}
        spaceID={props.spaceID}
        roomID={props.canvasID}
        appID={props.id}
        onDeleteTrigger={props.onDeleteTrigger}
        sm={props.data.sm !== undefined ? props.data.sm : true}
        onDataChange={props.onDataChange}
        watcher={props.watcher}
      />
    );
  } else {
    return <></>;
  }
};
const Canvas = (props: { id: string; spaceID: string }) => {
  let children: any[] = [];
  const customClaims = useSelector(getCustomClaimFromRedux);
  const [canvasDataState, setCanvasData] = useState<canvasObj[]>([]);
  useEffect(() => {
    if (!customClaims?.roomUserRoleV2 || !customClaims?.roomUserRoleV2[props.id]) return;
    const watcherNew = watchForNewCanvasChild(props.spaceID, props.id, (obj, id) => {
      setCanvasData((canvasDataState) => {
        if (id && !_.find(canvasDataState, { id })) {
          canvasDataState.push({ ...obj, id });
        }
        return [...canvasDataState];
      });
    });
    const watcherDel = watchForDeleteCanvasChild(props.spaceID, props.id, (obj, id) => {
      setCanvasData((canvasDataState) => {
        if (id) {
          const ind = _.findIndex(canvasDataState, { id });
          if (ind !== -1) {
            canvasDataState.splice(ind, 1);
          }
        }
        return [...canvasDataState];
      });
    });
    return () => {
      setCanvasData([]);
      watcherNew();
      watcherDel();
    };
  }, [props.id, customClaims]);
  for (let i in canvasDataState) {
    const obj = canvasDataState[i];
    const { id, t, d: d_, ...dim } = obj;
    const watcher = (callback: (c: canvasChild) => void, path?: string) => {
      if (!path) path = '';
      return watchCanvasChild(props.spaceID, props.id, id + path, callback);
    };
    const onDeleteTrigger = () => deleteCanvasChild(props.spaceID, props.id, id);
    children.push(
      <BasicDiv
        id={id}
        onDeleteTrigger={onDeleteTrigger}
        dragHandle={t !== 'gif' && t !== 'image' && t !== 'whiteboard' && t !== 'yt' && t !== 'gc' && t !== 'portal'}
        w={dim.w}
        h={dim.h}
        x={dim.x}
        hideFloatingCrossBtn={
          t === 'iframe' ||
          t === 'linkbox' ||
          t === 'file' ||
          t === 'whiteboard' ||
          t === 'yt' ||
          t === 'gc' ||
          t === 'nogo' ||
          t === 'portal'
        }
        hideFloatingDragBtn={
          t === 'iframe' ||
          t === 'linkbox' ||
          t === 'file' ||
          t === 'whiteboard' ||
          t === 'yt' ||
          t === 'gc' ||
          t === 'nogo' ||
          t === 'portal'
        }
        turnSpatialOff={(flag) => updatePartiallyCanvasChild(props.spaceID, props.id, id, 'sp', flag)}
        canResize={t !== 'linkbox' && t !== 'file' && t !== 'portal'}
        y={dim.y}
        d={d_ !== undefined ? d_ : undefined}
        key={id}
        uid={d_?.uid ? d_.uid : ''}
        onDimChange={(d) => {
          updatePartiallyCanvasChild(props.spaceID, props.id, id, 'w', d.w);
          updatePartiallyCanvasChild(props.spaceID, props.id, id, 'h', d.h);
          updatePartiallyCanvasChild(props.spaceID, props.id, id, 'x', d.x);
          updatePartiallyCanvasChild(props.spaceID, props.id, id, 'y', d.y);
        }}
        onDataChange={(d, path: string) => {
          updatePartiallyCanvasChild(props.spaceID, props.id, id + '/d', path, d);
        }}
        watcher={watcher}
        type={t}
        children={
          <CanvasChild
            onDeleteTrigger={onDeleteTrigger}
            spaceID={props.spaceID}
            canvasID={props.id}
            id={t + '_' + id}
            t={t}
            data={{ ...d_, parentObj: obj }}
            watcher={watcher}
            // onDataUpdate={(d) => updateCanvasChild(props.spaceID, props.id, id, { ...d, d: d_, t })}
            onDataChange={(d, path: string) => {
              updatePartiallyCanvasChild(props.spaceID, props.id, id + '/d', path, d);
            }}
            onUpdate={(d, path: string) => {
              updatePartiallyCanvasChild(props.spaceID, props.id, id + '/d', path, d);
            }}
          />
        }
      />
    );
  }
  return <>{children}</>;
};

export default Canvas;
