import React from 'react';

const Banner = () => {
  return (
    <div
      style={{
        padding: '5px',
        backgroundColor: '#ff6e67',
        color: 'white',
      }}
    >
      We regret to inform you that we are shutting down Reslash. The app will stop working on 10th June 2024. We will
      forever be grateful to everyone who supported us.
    </div>
  );
};

export default Banner;
