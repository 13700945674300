import axios from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'querystring';
import { config } from '../../config';
import { getCurrentRoomPasswordStore, getSpacesRoomsState, setCurrentRoom } from '../../store';
import { getClaimToken } from '../firebase/auth';
import logger from '../logger';
import { headers } from './spaces';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function (e) {
    if (e?.response?.status === 500) return false;
    return true;
  },
});

class Room {
  isClaimCallPending: { [roomID: string]: number };
  isClaimCallProcessing: { [roomID: string]: boolean };

  constructor() {
    this.isClaimCallPending = {};
    this.isClaimCallProcessing = {};
  }

  async create(
    name: string,
    token: string,
    spaceID: string,
    guestAllowed: boolean,
    overflow: boolean,
    spillOverAt: number,
    capacity: number,
    startTime: number,
    endTime: number,
    timezone: {
      offset: number;
      name: string;
    },
    password: string,
    isLocked?: boolean,
    emails?: string[]
  ) {
    logger.info('Called createNewRoomV2.', { name, spaceID, guestAllowed, overflow });
    try {
      const response = await axios.post(
        `${config.firebaseCloudURL}/createNewRoom_v2`,
        qs.stringify({
          name,
          token,
          spaceID,
          isOpen: 'open',
          isLocked: isLocked ? 'locked' : 'false',
          guestAllowed: guestAllowed ? 'guests' : 'false',
          password,
          overflow: overflow ? 'overflow' : 'normal',
          spillOverAt,
          capacity,
          timezoneOffset: timezone.offset,
          timezoneStr: timezone.name,
          startTime,
          endTime,
          ejectOnEnd: 'eject',
          emails: emails ? emails.join(',') : '',
        }),
        headers
      );
      return response;
    } catch (e) {
      logger.warn('CreateNewRoomV2 error:', { error: e?.response?.data });
      throw e;
    }
  }

  async edit(
    name: string,
    token: string,
    isLocked: boolean,
    guestAllowed: boolean,
    isDeleted: boolean,
    roomID: string,
    overflow: boolean,
    spillOverAt: number,
    capacity: number,
    startTime: number,
    endTime: number,
    timezone: {
      offset: number;
      name: string;
    },
    password?: string,
    emails?: string[]
  ) {
    logger.info('called UpdateRoomV2', { name, isLocked, guestAllowed, isDeleted, roomID, overflow });
    try {
      const response = await axios.post(
        `${config.firebaseCloudURL}/updateRoom_v2`,
        qs.stringify({
          name,
          isOpen: 'open',
          isLocked: isLocked ? 'locked' : '',
          roomID,
          isDeleted: isDeleted ? 'deleted' : '',
          password: password !== undefined ? password : 'dontchange',
          guestAllowed: guestAllowed ? 'guests' : '',
          token,
          overflow: overflow ? 'overflow' : 'normal',
          spillOverAt,
          capacity,
          timezoneOffset: timezone.offset,
          timezoneStr: timezone.name,
          startTime,
          endTime,
          ejectOnEnd: 'eject',
          emails: emails ? emails.join(',') : '',
        }),
        headers
      );
      logger.info('UpdateRoomV2 response:', { result: response.data });
      return response;
    } catch (e) {
      logger.warn('Error in updateRoomV2', { error: e?.response?.data });
      throw e;
    }
  }

  async useLink(inviteLink: string, token: string, roomID: string) {
    try {
      logger.info('UseRoomInviteLinkV2 called', { roomID, inviteLink });
      const response = await axios.post(
        `${config.firebaseCloudURL}/useRoomInviteLink_v2`,
        qs.stringify({ token, roomID, inviteLink }),
        headers
      );
      logger.info('UseRoomInviteLinkV2 response:', { result: response.data });
      return response;
    } catch (error) {
      logger.warn('UserRoomInviteLinkV2 error:', { error: error?.response?.data });
      throw error;
    }
  }

  async setRoomCustomClaim(roomID: string, token: string, password?: string) {
    if (!password) password = getCurrentRoomPasswordStore();
    try {
      logger.info('SetRoomCustomClaim_v2 called', { roomID });
      if (!password && this.isClaimCallProcessing[roomID]) {
        await getClaimToken(true, true);
        return;
      }
      this.isClaimCallProcessing[roomID] = true;
      const response = await axios.post(
        `${config.firebaseCloudURL}/setRoomCustomClaim_v2`,
        qs.stringify({ token, roomID, password: password ? password : '' }),
        headers
      );
      this.isClaimCallProcessing[roomID] = false;
      await getClaimToken(true, true);
      logger.info('SetRoomCustomClaimv2 Response:', { result: response.data });
      return response;
    } catch (e) {
      this.isClaimCallProcessing[roomID] = false;
      logger.warn('SetRoomCustomClaimV2 failed:', { error: e?.response?.data });
      throw e;
    }
  }

  async updateMemberPermissionRoom(
    roomID: string,
    spaceID: string,
    userID: string,
    permission: 'admin' | 'teammember',
    isDeleted: boolean,
    token: string
  ) {
    try {
      logger.info('updateMemberPermissionRoom_v2 called.', { roomID, spaceID, userID, permission, isDeleted });
      const response = await axios.post(
        `${config.firebaseCloudURL}/updateMemberPermissionRoom_v2`,
        qs.stringify({ token, roomID, spaceID, userID, permission, isDeleted }),
        headers
      );
      logger.info('updateMemberPermissionRoom_v2 result.', { result: response.data });
      return response;
    } catch (e) {
      logger.warn('updateMemberPermissionRoom_v2 error:', { error: e?.response?.data });
      throw e;
    }
  }

  async useGuestLink(spaceID: string, token: string) {
    try {
      logger.info('useGuestLinkRoom_v2 called.', { spaceID });
      const response = await axios.post(
        `${config.firebaseCloudURL}/useGuestLinkRoom_v2`,
        qs.stringify({ token, spaceID }),
        headers
      );
      logger.info('useGuestLinkRoom_v2 response:', { result: response.data });
      return response;
    } catch (e) {
      logger.warn('useGuestLinkRoom_v2 error:', { error: e?.response?.data });
      throw e;
    }
  }

  async getRoomInviteLinks(roomID: string, token: string) {
    try {
      logger.info('getRoomInviteLinks_v2 called', { roomID });
      const response = await axios.post(
        `${config.firebaseCloudURL}/getRoomInviteLinks_v2`,
        qs.stringify({ token, roomID }),
        headers
      );
      logger.info('getRoomInviteLinks_v2 response:', { result: response.data });
      return response;
    } catch (error) {
      logger.warn('getRoomInviteLinks_v2 error:', { error: error?.response?.data });
      throw error;
    }
  }

  async checkAndSetClaim(roomID: string) {
    const timeNow = new Date().getTime() / 1000;
    if (this.isClaimCallPending[roomID] && timeNow - this.isClaimCallPending[roomID] < 60) return;
    const token = await getClaimToken(true);
    const spacesRooms = getSpacesRoomsState();
    const userRole = spacesRooms.currentUserRole;
    const permission = spacesRooms.rooms[spacesRooms.currentRoom]?.permission;
    let userType: 'a' | 't' | 'g' = 'g';
    if (permission?.isAdmin) userType = 'a';
    else if (permission?.isTeamMember) userType = 't';
    if (token) {
      if (
        !token.claims.roomUserRoleV2 ||
        token.claims.roomUserRoleV2[roomID] !== userRole ||
        !token.claims.roomsV2 ||
        token.claims.roomsV2[roomID] !== userType
      ) {
        try {
          await this.setRoomCustomClaim(roomID, token.token);
          await getClaimToken(true);
          this.isClaimCallPending[roomID] = timeNow;
        } catch {}
      }
    }
  }

  async setClaimThenRoom(roomID: string, setRoom = true) {
    if (roomID.length < 2) return;
    await this.checkAndSetClaim(roomID);
    if (setRoom) setCurrentRoom(roomID);
  }
}

export default new Room();
