import { useEffect, useRef, useState } from 'react';
import { Lock } from 'react-feather';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../../components/customiseLogin/quickBio.scss';
import { getClaimToken } from '../../../helper/firebase/auth';
import { handleSubdomain } from '../../../helper/interactive/misc';
import rooms from '../../../helper/requests/rooms';
import { closeModalThunk, newModalThunk, openModalThunk, setCurrentRoom } from '../../../store';
import { getGetModalState } from '../../../store/modal';
import { getSpacesRooms } from '../../../store/spacesRooms';
import FloatInput from '../../floatInput/floatInput';
import { useOutsideEvent } from '../../interactive/giphy/Giphy';
import LoaderRing from '../../loader/loaderRing';
import { ModalStyle } from '../../modal/modal';

let roomID = '';
export const passwordModalID = 'room-password-modal';
export const openPasswordModal = (roomID_: string) => {
  roomID = roomID_;
  return openModalThunk(passwordModalID);
};
export const closePasswordModal = closeModalThunk(passwordModalID);

const PasswordModal = () => {
  const [password, setPassword] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const state = useSelector(getGetModalState(passwordModalID));
  const history = useHistory();
  const ref = useRef<HTMLDivElement | null>(null);

  useOutsideEvent(ref, () => closePasswordModal(), 'open-pass-modal', '.open-pass-modal');

  useEffect(() => {
    newModalThunk(passwordModalID, false)();
  }, []);

  useEffect(() => {
    setMsg('');
  }, [state]);

  const spacesRooms = useSelector(getSpacesRooms);

  const checkPassword = async () => {
    if (loading) return;
    if (password.length < 1) {
      setMsg('Incorrect password, try again.');
      return;
    }
    try {
      setMsg('');
      setLoading(true);
      const token = await getClaimToken(false, false);
      if (token?.token) {
        await rooms.setRoomCustomClaim(roomID, token.token, password);
        await getClaimToken(true, true);
        setCurrentRoom(roomID, password);
        // setCurrentRoomPassword(password);
        if (spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID)
          history.push(
            handleSubdomain(`/s/${spacesRooms.spaces[spacesRooms.currentSpace]?.spaceInfo.spaceID}/r/${roomID}`)
          );
        closePasswordModal();
      }
    } catch (e) {
      setMsg('Incorrect password, try again.');
    }
    setLoading(false);
  };

  return (
    <ReactModal
      isOpen={state}
      id="passwordModal"
      contentLabel={`${passwordModalID} Modal`}
      style={{
        overlay: { ...ModalStyle.overlay, zIndex: 4 },
        content: { ...ModalStyle.content, padding: '0px', borderRadius: '16px', zIndex: 4 },
      }}
      shouldCloseOnOverlayClick={true}
    >
      <div
        className="quick-bio-container"
        style={{ margin: 0, border: '2px solid #000000', background: '#393B41' }}
        ref={ref}
      >
        <div className="quick-bio-form-section" id="passwordModalBody">
          <div className="quick-bio-form-items">
            <div className="quick-bio-form-header">
              <div className="quick-bio-form-header-title">
                <div style={{ color: '#C4C4C4', display: 'flex', flexDirection: 'row', fontSize: '18px' }}>
                  <Lock size={24} style={{ marginRight: '6px', transform: 'translateY(2px)' }} />
                  {spacesRooms.rooms[roomID]?.roomDetails?.roomName || 'Room'}
                </div>
              </div>

              {/* <div className="quick-bio-form-user-avatar">
          <img src="https://cdn4.iconfinder.com/data/icons/avatars-xmas-giveaway/128/batman_hero_avatar_comics-512.png" />
        </div> */}
            </div>
            <div className="quick-bio-form-email">
              <FloatInput
                type={'password'}
                label={'Room Password'}
                showHide={true}
                onChangeInput={(inputText) => {
                  setPassword(inputText);
                }}
              />
            </div>
            {/* <div className="quick-bio-form-email">
        <FloatInput
          type={'text'}
          label={'Email (optional)'}
          onChangeInput={(inputText) => {
            setEmail(inputText);
          }}
        />
      </div> */}
          </div>

          {msg.length > 0 && (
            <div className="sign-up-form-error-state">
              <span>{msg}</span>
            </div>
          )}

          <div className="quick-bio-form-action">
            <div className="quick-bio-form-button" onClick={checkPassword} style={{ backgroundColor: '#34E3B9' }}>
              {!loading && <span style={{ color: 'black' }}>Continue</span>}
              {loading && <LoaderRing size={'16px'} color={'black'} />}
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default PasswordModal;
