import React from 'react';
import LoaderV2 from '../../loader/loaderV2';
import '../styles/modalFooter.scss';

export default function ModalFooter(props: any) {
  return (
    <div className="modal-footer" style={props.style}>
      {props.footerActionButtonLeft ? (
        <button onClick={props.onActionLeft} className="left-btn">
          <span>{props.footerActionButtonLeft}</span>
        </button>
      ) : null}
      {props.buttonSize ? (
        <button
          className="action-btn"
          style={{ width: props.buttonSize }}
          onClick={() => {
            if (!props.footerActionButtonRight) return;
            props.onActionRight();
          }}
        >
          {props.footerActionButtonRight ? (
            <span className="action-btn-span">{props.footerActionButtonRight}</span>
          ) : (
            <div style={{ transform: 'translateY(-3px)' }}>
              <LoaderV2 color={'black'} />
            </div>
          )}
        </button>
      ) : (
        <button
          className="action-btn"
          onClick={() => {
            if (!props.footerActionButtonRight) return;
            props.onActionRight();
          }}
        >
          {props.footerActionButtonRight ? (
            <span className="action-btn-span">{props.footerActionButtonRight}</span>
          ) : (
            <div style={{ transform: 'translateY(-3px)' }}>
              <LoaderV2 />
            </div>
          )}
        </button>
      )}
      {props.children}
    </div>
  );
}
