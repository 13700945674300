import firebase from 'firebase';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getClaimToken } from '../../helper/firebase/auth';
import rooms from '../../helper/requests/rooms';
import { setCurrentRoomUserRoles, setCurrentUserRoleV2, setRoomPermissionV2 } from '../../store';
import { getAuth } from '../../store/auth';
import { getCustomClaimFromRedux } from '../../store/customClaim';
import { getSpacesRooms, permissionV2, userRoleType } from '../../store/spacesRooms';
import {
  hideChangedUserRole,
  hideChangedUserType,
  hideChangingUserRole,
  hideChangingUserType,
  showChangedUserRole,
  showChangedUserType,
  showChangingUserRole,
  showChangingUserType,
} from '../notifications/notifications';
let database = firebase.database();

export const getDefaultPermissions = (
  audiencePermission: permissionV2,
  permission: permissionV2 | undefined,
  userRole: 'h' | 's' | 'a'
): permissionV2 => {
  if (userRole === 'h')
    return {
      gifs: permission?.gifs !== undefined ? permission.gifs : true,
      wallpaper: permission?.wallpaper !== undefined ? permission.wallpaper : true,
      urlwallpaper: permission?.urlwallpaper !== undefined ? permission.urlwallpaper : true,
      apps: permission?.apps !== undefined ? permission.apps : true,
      objects: permission?.objects !== undefined ? permission.objects : true,
      moveself: permission?.moveself !== undefined ? permission.moveself : true,
      moveothers: permission?.moveothers !== undefined ? permission.moveothers : true,
      megaphone: permission?.megaphone !== undefined ? permission.megaphone : true,
      cam: permission?.cam !== undefined ? permission.cam : true,
      mic: permission?.mic !== undefined ? permission.mic : true,
      screen: permission?.screen !== undefined ? permission.screen : true,
      chat: true,
      recording: true,
      openroom: false,
      lockroom: false,
    };
  else if (userRole === 'a')
    return {
      gifs: permission?.gifs !== undefined ? permission.gifs : true,
      wallpaper: permission?.wallpaper !== undefined ? permission.wallpaper : false,
      urlwallpaper: permission?.urlwallpaper !== undefined ? permission.urlwallpaper : false,
      apps: permission?.apps !== undefined ? permission.apps : true,
      objects: permission?.objects !== undefined ? permission.objects : true,
      moveself: permission?.moveself !== undefined ? permission.moveself : true,
      moveothers: permission?.moveothers !== undefined ? permission.moveothers : false,
      megaphone: permission?.megaphone !== undefined ? permission.megaphone : false,
      cam: permission?.cam !== undefined ? permission.cam : true,
      mic: permission?.mic !== undefined ? permission.mic : true,
      screen: permission?.screen !== undefined ? permission.screen : true,
      chat: permission?.chat !== undefined ? permission.chat : true,
      recording: permission?.recording !== undefined ? permission.recording : true,
      openroom: permission?.openroom !== undefined ? permission.openroom : false,
      lockroom: permission?.lockroom !== undefined ? permission.lockroom : false,
    };
  else
    return {
      gifs: audiencePermission?.gifs !== undefined ? audiencePermission.gifs : true,
      wallpaper: audiencePermission?.wallpaper !== undefined ? audiencePermission.wallpaper : false,
      urlwallpaper: audiencePermission?.urlwallpaper !== undefined ? audiencePermission.urlwallpaper : false,
      apps: audiencePermission?.apps !== undefined ? audiencePermission.apps : true,
      objects: audiencePermission?.objects !== undefined ? audiencePermission.objects : true,
      moveself: audiencePermission?.moveself !== undefined ? audiencePermission.moveself : true,
      moveothers: audiencePermission?.moveothers !== undefined ? audiencePermission.moveothers : false,
      megaphone: true,
      cam: true,
      mic: true,
      screen: audiencePermission?.screen !== undefined ? audiencePermission.screen : true,
      chat: true,
      recording: permission?.recording !== undefined ? permission.recording : true,
      openroom: false,
      lockroom: false,
    };
};
const PermissionV2Watcher = (props: { spaceID: string }) => {
  const auth = useSelector(getAuth);
  const spacesRooms = useSelector(getSpacesRooms);
  const customClaim = useSelector(getCustomClaimFromRedux);

  useEffect(() => {
    const roomID = spacesRooms.currentRoom;
    if (!customClaim?.roomUserRoleV2 || !customClaim?.roomUserRoleV2[roomID]) {
      return;
    }
    if (roomID.length > 2) {
      const hostsRef = database.ref(`space/${props.spaceID}/room/${roomID}/hosts`);
      const speakersRef = database.ref(`space/${props.spaceID}/room/${roomID}/speakers`);
      const audienceRef = database.ref(`space/${props.spaceID}/room/${roomID}/audience`);
      // const hostPermissionsRef = database.ref(`space/${props.spaceID}/room/${roomID}/permissions/h`);
      // const speakerPermissionsRef = database.ref(`space/${props.spaceID}/room/${roomID}/permissions/s`);
      const audiencePermissionsRef = database.ref(`space/${props.spaceID}/room/${roomID}/permissions/a`);
      const admin = spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isAdmin;
      if (admin) {
        setCurrentUserRoleV2('h');
      }
      const onHosts = (snapshot: any) => {
        const val: { [id: string]: { userID: string; createdBy: string; createdOn: string } } = snapshot.val();
        const users: { [id: string]: { role: userRoleType; createdBy: string } } = {};
        for (let i in val) {
          const host = val[i];
          if (host.userID === auth.user.id) setCurrentUserRoleV2('h');
          users[host.userID] = { role: 'h', createdBy: host.createdBy };
        }
        setCurrentRoomUserRoles('h', users);
      };
      const onSpeakers = (snapshot: any) => {
        const val: { [id: string]: { userID: string; createdBy: string; createdOn: string } } = snapshot.val();
        const users: { [id: string]: { role: userRoleType; createdBy: string } } = {};
        for (let i in val) {
          const speaker = val[i];
          if (speaker.userID === auth.user.id) setCurrentUserRoleV2(admin ? 'h' : 's');
          users[speaker.userID] = { role: 's', createdBy: speaker.createdBy };
        }
        setCurrentRoomUserRoles('s', users);
      };
      const onAudience = (snapshot: any) => {
        const val: { [id: string]: { userID: string; createdBy: string; createdOn: string } } = snapshot.val();
        const users: { [id: string]: { role: userRoleType; createdBy: string } } = {};
        for (let i in val) {
          const audience = val[i];
          if (audience.userID === auth.user.id) setCurrentUserRoleV2(admin ? 'h' : 'a');
          users[audience.userID] = { role: 's', createdBy: audience.createdBy };
        }
        setCurrentRoomUserRoles('a', users);
      };
      // let onHostPermission: (snapShot: any) => void = () => {};
      // let onSpeakerPermission: (snapShot: any) => void = () => {};
      let onAudiencePermission: (snapShot: any) => void = () => {};
      const audiencePermissionListener = (audiencePermission: any) => {
        const basicPermission = audiencePermission.val();
        hostsRef.off('value', onHosts);
        speakersRef.off('value', onSpeakers);
        audienceRef.off('value', onAudience);
        // hostPermissionsRef.off('value', onHostPermission);
        // speakerPermissionsRef.off('value', onSpeakerPermission);
        audiencePermissionsRef.off('value', onAudiencePermission);
        setRoomPermissionV2('h', getDefaultPermissions(basicPermission, undefined, 'h'));
        setRoomPermissionV2('s', getDefaultPermissions(basicPermission, undefined, 's'));
        setRoomPermissionV2('a', getDefaultPermissions(basicPermission, basicPermission, 'h'));
        // onHostPermission = (snapshot: any) => {
        //   const val = snapshot.val();
        //   setRoomPermissionV2('h', getDefaultPermissions(basicPermission, val, 'h'));
        // };
        // onSpeakerPermission = (snapshot: any) => {
        //   const val = snapshot.val();
        //   setRoomPermissionV2('s', getDefaultPermissions(basicPermission, val, 's'));
        // };
        onAudiencePermission = (snapshot: any) => {
          const val = snapshot.val();
          setRoomPermissionV2('a', getDefaultPermissions(basicPermission, val, 'a'));
        };
        hostsRef.on('value', onHosts);
        speakersRef.on('value', onSpeakers);
        audienceRef.on('value', onAudience);
        // hostPermissionsRef.on('value', onHostPermission);
        // speakerPermissionsRef.on('value', onSpeakerPermission);
        audiencePermissionsRef.on('value', onAudiencePermission);
      };
      audiencePermissionsRef.on('value', audiencePermissionListener);

      return () => {
        hostsRef.off('value', onHosts);
        speakersRef.off('value', onSpeakers);
        audienceRef.off('value', onAudience);
        // hostPermissionsRef.off('value', onHostPermission);
        // speakerPermissionsRef.off('value', onSpeakerPermission);
        audiencePermissionsRef.off('value', onAudiencePermission);
        audiencePermissionsRef.off('value', audiencePermissionListener);
        // permissionsRef.off('value', onNullPermission);
      };
    }
  }, [
    props.spaceID,
    auth,
    spacesRooms.currentRoom,
    customClaim,
    spacesRooms.rooms[spacesRooms.currentRoom]?.permission,
  ]);

  useEffect(() => {
    if (!spacesRooms.currentRoom || spacesRooms.currentRoom.length < 2) return;
    if (spacesRooms.roomsLoading || spacesRooms.spacesLoading) return;
    (async () => {
      const token = await getClaimToken(false, true);
      const time = spacesRooms.rooms[spacesRooms.currentRoom]?.updatedOn;
      const timeNow = new Date().getTime() / 1000;
      const changedAfterLoad = !(time && time.seconds > timeNow);
      const permission = spacesRooms.rooms[spacesRooms.currentRoom]?.permission;
      const roomID = spacesRooms.currentRoom;
      let userType: 'a' | 't' | 'g' = 'g';
      if (permission?.isAdmin) userType = 'a';
      else if (permission?.isTeamMember) userType = 't';
      if (token?.token) {
        if (!token.claims.roomsV2 || token.claims.roomsV2[roomID] !== userType) {
          if (!permission?.isGuest && changedAfterLoad) showChangingUserType();
          await rooms.setRoomCustomClaim(spacesRooms.currentRoom, token.token);
          await getClaimToken();
          if (!permission?.isGuest && changedAfterLoad) hideChangingUserType();
          if (!permission?.isGuest && changedAfterLoad) showChangedUserType();
          if (!permission?.isGuest && changedAfterLoad) setTimeout(() => hideChangedUserType(), 3000);
        }
      }
    })();
  }, [
    spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isAdmin,
    spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isTeamMember,
    spacesRooms.rooms[spacesRooms.currentRoom]?.permission?.isGuest,
    spacesRooms.currentRoom,
    auth.isLoggedIn,
    spacesRooms.roomsLoading,
    spacesRooms.spacesLoading,
  ]);

  useEffect(() => {
    if (!spacesRooms.currentRoom || spacesRooms.currentRoom.length < 2) return;
    if (spacesRooms.roomsLoading || spacesRooms.spacesLoading) return;
    (async () => {
      const token = await getClaimToken(false, true);
      const userRole = spacesRooms.currentUserRole;
      const roomID = spacesRooms.currentRoom;
      if (token) {
        if (!token.claims.roomUserRoleV2 || token.claims.roomUserRoleV2[roomID] !== userRole) {
          showChangingUserRole();
          await rooms.setRoomCustomClaim(spacesRooms.currentRoom, token.token);
          await getClaimToken();
          hideChangingUserRole();
          showChangedUserRole();
          setTimeout(() => hideChangedUserRole(), 3000);
        }
      }
    })();
  }, [
    spacesRooms.currentUserRole,
    spacesRooms.currentRoom,
    auth.isLoggedIn,
    spacesRooms.roomsLoading,
    spacesRooms.spacesLoading,
  ]);
  return <></>;
};

export default PermissionV2Watcher;
