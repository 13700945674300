import React, { useEffect, useState } from 'react';
//@ts-ignore
import UrlSafeString from 'url-safe-string';
import Loader from '../../components/loader/loader';
import { config } from '../../config';
import { getEmailFromAppsumoOrder, signin } from '../../helper/firebase/auth';
import { findGetParameter } from '../../helper/interactive/misc';
import { appsumoCreateAccount } from '../../helper/requests/appsumo';
import '../signin/signin.scss';

const AppsumoSignup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPass] = useState('');
  const [rePass, setRePass] = useState('');
  const [message, setMsg] = useState('');
  const [page, setPage] = useState<1 | 2>(1);
  const [loading, setLoading] = useState(true);
  const [ID, setID] = useState('');
  const [spaceName, setSpaceName] = useState('');

  useEffect(() => {
    setLoading(true);
    const id = findGetParameter('id');
    if (id) {
      getEmailFromAppsumoOrder(id).then((data) => {
        const payload = data.data();
        if (payload) {
          setEmail(payload.activationEmail);
          setLoading(false);
        }
      });
    }
  }, []);

  const signUp = async () => {
    if (name.length < 3) {
      setMsg('Name must be 3 or more characters');
      return;
    }
    if (password.length < 5 || rePass.length < 5) {
      setMsg('Password must be 5 or more characters');
      return;
    }
    if (password !== rePass) {
      setMsg("Passwords don't match.");
      return;
    }
    try {
      setMsg('');
      setPage(2);
    } catch (e) {
      if (e.message.includes('password is invalid')) {
        setMsg('Your password is incorrect.');
      }
      setMsg(`Error occured: ${e.message}`);
    }
  };

  const createAppsumoAcc = async () => {
    const id = findGetParameter('id');
    if (id) {
      try {
        setMsg('Creating account...');
        const response = await appsumoCreateAccount(id, name, password, spaceName, ID);
        console.log(response);
        setMsg('Logging you into your account...');
        const user = await signin(email, password);
        if (user) {
          window.location.href = config.frontendURL + '/s/' + response.data.space.spaceID;
        } else {
          setMsg(`Couldnt create your account.`);
        }
      } catch (e) {
        const error = JSON.parse(e.request.response);
        console.error(error);
        setMsg(`Error occured: ${error.error}`);
      }
    }
  };

  const IDGen = UrlSafeString();

  return (
    <>
      {loading && <Loader />}
      {!loading && page === 1 ? (
        <div className="login" data-current-state="log-in">
          <div className="login__container" data-state="log-in">
            <div className="logo-wrapper" id="with-appsumo">
              <a href="/">
                <img src={'/images/reslashLogo.png'} alt="reslash logo" className="small-logo" />
              </a>
              <div className="with">with</div>
              <div className="appsumo-logo">
                <img src={'/images/appsumo-logo.jpeg'} alt="appsumo logo" />
              </div>
            </div>
            <div className="sumolings">Welcome Sumo-ling! 🌮</div>
            <input
              value={email}
              type="text"
              name="email"
              placeholder="email"
              className="emailDisabled"
              disabled={true}
            />
            <input
              id="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              type="text"
              placeholder="Name"
            />
            <input
              value={password}
              onChange={(e) => {
                setPass(e.target.value);
              }}
              type="password"
              placeholder="Password"
            />
            <input
              value={rePass}
              onChange={(e) => {
                setRePass(e.target.value);
              }}
              type="password"
              placeholder="Confirm Password"
            />
            <div className="on-boarding__bottom">{message}</div>
            <button type="submit" className="login__button" onClick={() => signUp()}>
              Next
            </button>
          </div>
        </div>
      ) : (
        <div className="on-boarding">
          <div className="on-boarding__container" data-current-step="1">
            <div data-step="1">
              <div className="sumolings">Set Name and URL of your Reslash Space</div>
              <input
                id="teamName"
                onChange={(e) => setSpaceName(e.target.value)}
                value={spaceName}
                type="text"
                name="teamname"
                placeholder="Space Name"
              />
              <input
                id="teamName"
                onChange={(e) => setID(IDGen.generate(e.target.value))}
                value={ID}
                type="text"
                name="teamname"
                placeholder="Space URL ID"
              />
              <div className="on-boarding__bottom invite-link">
                <b>Invite Link:</b> https://app.reslash.co/s/{ID}
              </div>
              <div
                style={{
                  fontSize: '12px',
                  marginTop: '10px',
                }}
              >
                {message}
              </div>
              <button type="submit" className="on-boarding__button" onClick={createAppsumoAcc}>
                Create Account
              </button>
              <div
                className="on-boarding__bottom"
                style={{ fontSize: '12px', textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => setPage(1)}
              >
                Go Back
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppsumoSignup;
