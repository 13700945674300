//createTokenDailyco

import axios from 'axios';
import axiosRetry from 'axios-retry';
import qs from 'querystring';
import { config } from '../../config';
import { getClaimToken } from '../firebase/auth';
import logger from '../logger';
import { headers } from './spaces';

axiosRetry(axios, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 5,
  retryCondition: function () {
    return true;
  },
});

export async function updatePresenceV2(sessionID: string) {
  try {
    const token = await getClaimToken(false, true);
    if (!token) throw new Error('No Token!');
    await axios.post(
      `${config.firebaseCloudURL}/updatePresenceV2`,
      qs.stringify({ sessionID, token: token.token }),
      headers
    );
    return;
  } catch (e) {
    logger.warn('Failed to call updatePresence:', { error: e?.response?.data });
    throw e;
  }
}
