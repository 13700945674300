import React from 'react';
import { useHistory } from 'react-router-dom';
import ReslashLogo from '../../assets/svg/reslashLogo';
import { sendAmplitudeData } from '../../helper/amplitude';
import './createSpace.scss';

const CreateSpace = () => {
  const history = useHistory();

  return (
    <>
      <div className="create-space-container">
        <div className="create-space-form-section">
          <div className="create-space-form-items">
            <div className="create-space-form-header">
              <div className="create-space-form-header-title">
                <span>Welcome to Reslash</span>
              </div>
              <div className="create-space-form-company-logo">
                <ReslashLogo size={68} />
              </div>
              <div className="create-space-form-header-subtitle">
                <span>Let's build your next virtual office/event/classroom/hangout spot</span>
              </div>
            </div>
          </div>

          <div className="create-space-form-action">
            <div
              className="create-space-form-button"
              onClick={() => {
                sendAmplitudeData('Space create clicked', { type: 'onboarding' });
                history.push('/createSpace?sticky');
              }}
            >
              <span>Create new virtual estate</span>
            </div>
            {/* <div className="create-space-msg-section">
              <span>
                Not looking to create a new space? <a href="https://reslash.co"> Join an existing space</a>
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSpace;
