import React, { useEffect, useState } from 'react';
import Modal from '../components/modal';
import ModalBody from '../components/modalBody';
import ModalFooter from '../components/modalFooter';
import ModalHeader from '../components/modalHeader';

const JoinRoomModal = (props: any) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <Modal isOpen={isOpen} className="joinNowModal" style={{ width: '575px', maxHeight: '400px' }}>
      <ModalHeader
        showBack={false}
        hideClose={true}
        header={props.header}
        style={{
          paddingTop: '32px',
          paddingRight: '12px',
          paddingLeft: '12px',
          dispplay: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClose={() => setIsOpen(false)}
      ></ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter
        footerActionButtonLeft={props.footerActionButtonLeft ? props.footerActionButtonLeft : null}
        onActionLeft={props.setActionLeft ? props.setActionLeft : null}
        footerActionButtonRight={props.footerActionButtonRight}
        onActionRight={props.setActionRight}
        buttonSize={props.buttonSize ? props.buttonSize : '126px'}
      ></ModalFooter>
    </Modal>
  );
};

export default JoinRoomModal;
