import { volBounds } from '../../../config';
import { sendAmplitudeData } from '../../../helper/amplitude';
import spatialAudioController from '../../../spatialAudio/spatialAudioOptimized';
import { getAuthState, getListenerPosition } from '../../../store';
import HarkV2 from './harkV2';

export default class SpeakerVol {
  audioEle: HTMLAudioElement | undefined;
  audioStream: MediaStream | undefined;
  rID?: NodeJS.Timeout;
  harkMeter?: HarkV2;
  lastCheckTime: number;
  id: string;
  paused: boolean;
  mediaStreamSource!: MediaElementAudioSourceNode | MediaStreamAudioSourceNode;
  startTime: number;
  speakingTime: number;

  constructor(
    id: string,
    audioEle: HTMLAudioElement | undefined = undefined,
    audioStream: MediaStream | undefined = undefined
  ) {
    this.audioStream = audioStream;
    this.audioEle = audioEle;
    this.id = id;
    this.lastCheckTime = -1;
    this.paused = true;
    this.startTime = new Date().getTime();
    this.speakingTime = 0;
  }

  start() {
    if (this.audioStream) {
      this.harkMeter = new HarkV2(this.audioStream, { threshold: -50, interval: 100 });
    }
    if (this.harkMeter) {
      this.harkMeter.harker.on('speaking', () => {
        const ele = document.getElementById(this.id + '_video');
        if (ele && ele.parentElement) {
          ele.parentElement.classList.add('speaking');
        }
        this.startTime = new Date().getTime();
      });
      this.harkMeter.harker.on('stopped_speaking', () => {
        const ele = document.getElementById(this.id + '_video');
        if (ele && ele.parentElement) {
          ele.parentElement.classList.remove('speaking');
        }
        const time = new Date().getTime();
        const speakingTimeInMilliSeconds = time - this.startTime;
        if (speakingTimeInMilliSeconds > 0) {
          this.speakingTime += speakingTimeInMilliSeconds;
        }
        this.startTime = new Date().getTime();
      });
      if (this.id === getAuthState().user.id) {
        this.rID = setInterval(() => {
          this.amplitudeLoop(this.speakingTime);
          this.speakingTime = 0;
        }, 10000);
      }
    }
  }

  amplitudeLoop(speakingTime: number) {
    if (speakingTime > 0) {
      const speakingTimeInMilliSeconds = speakingTime;
      const { x, y } = getListenerPosition();
      const spatialRadius = (spatialAudioController.bubbleSize * volBounds.upper) / 128;
      sendAmplitudeData('Bubble speaking', { speakingTimeInMilliSeconds, position: { x, y, spatialRadius } });
    }
  }

  destroy() {
    if (this.harkMeter) {
      this.harkMeter.stop();
    }
    const ele = document.getElementById(this.id + '_video');
    if (ele && ele.parentElement) {
      ele.parentElement.classList.remove('speaking');
    }
    this.startTime = new Date().getTime();
    if (this.rID) {
      clearInterval(this.rID);
    }
    this.speakingTime = 0;
  }
}
