import firebase from 'firebase';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { ChatTextFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { sendAmplitudeData } from '../../helper/amplitude';
import { setReactionStore } from '../../store';
import { getAuth } from '../../store/auth';
import { getSpacesRooms } from '../../store/spacesRooms';
import { useOutsideEvent } from '../interactive/giphy/Giphy';
import './reactions.scss';

const CHAR_LIMIT = 50;
const WASABI_STICKER_URL =
  'https://s3.us-west-1.wasabisys.com/reslash-sticker-packs/Stickers/openmoji/openmoji-svg-color/';

export const Reaction = (props: {
  id?: string;
  type: 'text' | 'sticker';
  payload: { text?: string; url?: string };
  sentTime?: number;
}) => {
  const textDisappearTime = 15000; // 15 seconds to disappear the text reaction
  const stickerDisappearTime = 1490; // 1.5 seconds to disappear the sticker reaction
  const [showTextReaction, setShowTextReaction] = useState(false);
  const [showStickerReaction, setShowStickerReaction] = useState(false);
  const spacesRooms = useSelector(getSpacesRooms);

  useEffect(() => {
    if (props.type === 'text' && props.sentTime && props.sentTime + textDisappearTime >= new Date().getTime()) {
      setShowTextReaction(true);
      const timer = setTimeout(() => {
        setShowTextReaction(false);
      }, textDisappearTime);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowTextReaction(false);
    }

    if (props.type === 'sticker' && props.sentTime && props.sentTime + stickerDisappearTime >= new Date().getTime()) {
      setShowStickerReaction(true);
      const timer = setTimeout(() => {
        setShowStickerReaction(false);
      }, stickerDisappearTime);

      return () => {
        clearTimeout(timer);
      };
    } else {
      setShowStickerReaction(false);
    }
  }, [props.sentTime, props.type]);

  return (
    <>
      {props.type === 'text' && props.payload.text && showTextReaction && (
        <div
          className="text-reaction-container"
          id={`text-reaction-container_${props.id}`}
          style={{ bottom: `calc( var(--bubbleScale)  * ${spacesRooms.currentRoomPermission.size}px + 20px)` }}
        >
          <span>{props.payload.text}</span>
        </div>
      )}
      {props.type === 'sticker' && props.payload.url && showStickerReaction && (
        <div className="bubble-reaction-container">
          <img src={props.payload.url} />
        </div>
      )}
    </>
  );
};

const ReactionsBox = (props: { onClose: () => void }) => {
  const ReactionEmojis = [
    { name: 'Ghost', hexCode: '1F47B' },
    { name: 'Pumpkin', hexCode: '1F383' },
    { name: 'Candy', hexCode: '1F36D' },
    { name: 'Monster', hexCode: '1F47A' },
    { name: 'Laughing', hexCode: '1F602' },
    { name: 'Surprised', hexCode: '1F62F' },
    { name: 'Happy', hexCode: '1F60D' },
    { name: 'Crying', hexCode: '1F622' },
    { name: 'Clapping', hexCode: '1F44F' },
    { name: 'Fire', hexCode: '1F525' },
    { name: 'Party popper', hexCode: '1F389' },
    { name: '100 points', hexCode: '1F4AF' },
    { name: 'Thumbs up', hexCode: '1F44D' },
    { name: 'Heart', hexCode: '2764' },
    { name: 'Partying', hexCode: '1F973' },
    { name: 'Raising hands', hexCode: '1F64C' },
  ];

  const [message, setMessage] = useState('');
  const reactionModalRef = useRef<HTMLDivElement | null>(null);
  const spacesRooms = useSelector(getSpacesRooms);
  const auth = useSelector(getAuth);
  const [userInfo, setUserInfo] = useState({ name: '', profilePic: '', status: '', nickname: '', isDeleted: false });

  useOutsideEvent(
    reactionModalRef,
    () => {
      props.onClose();
    },
    'reactions-container',
    '#add_reactions_btn'
  );

  const sendTextReactionAsChat = async (msgText: string) => {
    if (spacesRooms?.permissionV2[spacesRooms.currentUserRole]?.chat)
      await firebase
        .firestore()
        .collection('roomV2')
        .doc(spacesRooms.currentRoom)
        .collection('messages')
        .add({
          text: msgText,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: auth.user.id,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: auth.user.id,
          isDeleted: false,
          userInfo: userInfo,
          permissions: { isGuest: spacesRooms.rooms[spacesRooms.currentRoom]?.permission.isGuest },
        });
  };

  useEffect(() => {
    setUserInfo({
      name: auth.user.name !== '' ? auth.user.name : auth.userDetails.name,
      profilePic: auth.userDetails.profilePic,
      status: auth.userDetails.desc,
      nickname: auth.user.email ? auth.user.email : auth.userDetails.username,
      isDeleted: false,
    });
  }, [auth.userDetails]);

  return (
    <>
      <div className="reactions-container" ref={reactionModalRef}>
        {/*  Use this when header section UI click to slide is figured out         
        <div className="reactions-header-section">
          <div className="reactions-header-categories-row"></div>
          <div className="reactions-header-recents-row"></div>
        </div>*/}

        <div className="reactions-body-section">
          <div className="reactions-body-icons">
            {_.map(ReactionEmojis, (reactionEmoji, key) => {
              return (
                <div
                  className="reaction-emoji-box"
                  key={key}
                  onClick={() => {
                    setReactionStore({
                      type: 'sticker',
                      payload: { text: '', url: WASABI_STICKER_URL + reactionEmoji.hexCode + '.svg' },
                      sentTime: new Date().getTime(),
                    });
                    sendAmplitudeData('Reaction sent', {
                      sentTime: new Date().getTime(),
                      type: 'sticker',
                      url: WASABI_STICKER_URL + reactionEmoji.hexCode + '.svg',
                    });
                    // props.onClose();
                  }}
                >
                  <img
                    style={{ width: '150%', height: '150%' }}
                    className={'pointer-event-none'}
                    src={WASABI_STICKER_URL + reactionEmoji.hexCode + '.svg'}
                  />
                </div>
              );
            })}
          </div>

          <div className="reactions-body-divider">
            <div className="line"></div>
            <span>or</span>
            <div className="line"></div>
          </div>

          <div className="reactions-body-text">
            <div className="reactions-text-box">
              <input
                placeholder="Share a quick text blurb"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey && message.length <= CHAR_LIMIT && message.length !== 0) {
                    setReactionStore({
                      type: 'text',
                      payload: { text: message, url: '' },
                      sentTime: new Date().getTime(),
                    });

                    sendTextReactionAsChat(message);

                    sendAmplitudeData('Reaction sent', {
                      sentTime: new Date().getTime(),
                      type: 'text',
                      messageLength: message.length,
                    });

                    setMessage('');
                    // props.onClose();
                  }
                }}
              />

              <div
                className="character-limit-indicator"
                style={message.length > CHAR_LIMIT ? { color: '#ff0000' } : {}}
              >
                {CHAR_LIMIT - message.length}/{CHAR_LIMIT}
              </div>
            </div>
            <ChatTextFill
              size={18}
              color={message.length > CHAR_LIMIT ? '#ff000075' : '#34E3B9'}
              style={message.length > CHAR_LIMIT ? { pointerEvents: 'none' } : { cursor: 'pointer' }}
              onClick={() => {
                if (message.length <= CHAR_LIMIT && message.length !== 0) {
                  setReactionStore({
                    type: 'text',
                    payload: { text: message, url: '' },
                    sentTime: new Date().getTime(),
                  });
                  sendTextReactionAsChat(message);
                  setMessage('');
                  sendAmplitudeData('Reaction sent', {
                    sentTime: new Date().getTime(),
                    type: 'text',
                    messageLength: message.length,
                  });
                  // props.onClose();
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactionsBox;
