const prod_firebase = {
  apiKey: 'AIzaSyDHM48O53p7DhJG7XE_7drgu3GEi-xhjyI',
  authDomain: 'app.reslash.co',
  databaseURL: 'https://reslash.firebaseio.com',
  projectId: 'reslash',
  firebaseCloudURL: 'https://us-central1-reslash.cloudfunctions.net',
};

const staging_firebase = {
  apiKey: 'AIzaSyD5sKwwBVkut_K40B0TXbdDx4tsUtyK01I',
  authDomain: 'app.reslash.co',
  databaseURL: 'https://reslashstaging-default-rtdb.firebaseio.com',
  projectId: 'reslashstaging',
  firebaseCloudURL: 'https://us-central1-reslashstaging.cloudfunctions.net',
};

const prod_config = {
  firebaseCloudURL: prod_firebase.firebaseCloudURL,
  firebaseCloudLocal: 'https://us-central1-reslash.cloudfunctions.net',
  frontendURL: 'https://app.reslash.co',
  jitsi8x8: {
    tenant: 'vpaas-magic-cookie-77eb8400a19741809b5564c7c9262fc2',
  },
  firebase: prod_firebase,
  env: 'production',
  logging: {
    console: false,
    cloud: true,
  },
  amplitudeKey: '2bedb35be43d442915da628b3071f99f',
};
const local_config = {
  firebaseCloudURL: prod_firebase.firebaseCloudURL,
  firebaseCloudLocal: 'http://localhost:5001/reslashstaging/us-central1',
  frontendURL: 'http://localhost:5173',
  jitsi8x8: {
    tenant: 'vpaas-magic-cookie-77eb8400a19741809b5564c7c9262fc2',
  },
  firebase: prod_firebase,
  env: 'dev',
  logging: {
    console: true,
    cloud: false,
  },
  amplitudeKey: '',
};
const staging_config = {
  firebaseCloudURL: staging_firebase.firebaseCloudURL,
  firebaseCloudLocal: 'http://localhost:5001/reslashstaging/us-central1',
  frontendURL: 'https://staging.reslash.co',
  jitsi8x8: {
    tenant: 'vpaas-magic-cookie-77eb8400a19741809b5564c7c9262fc2',
  },
  firebase: staging_firebase,
  env: 'staging',
  logging: {
    console: true,
    cloud: true,
  },
  amplitudeKey: 'c267fc05888a6c35e5074ecc515c275b',
};
function getConfig() {
  if (window.location.href.includes('http://localhost')) {
    return local_config;
  } else if (
    window.location.href.includes('https://staging.reslash.co') ||
    window.location.href.includes('.ngrok.io') ||
    window.location.href.includes('.vercel.app') ||
    window.location.href.includes('reslashdev.co') ||
    window.location.href.includes('getreslash.com') ||
    window.location.href.includes('reslash.dev')
  ) {
    return staging_config;
  }
  return prod_config;
}

const getMainSubdomain = () => {
  if (
    window.location.href.includes('https://staging.reslash.co') ||
    window.location.href.includes('http://localhost') ||
    window.location.href.includes('.ngrok.io') ||
    window.location.href.includes('.vercel.app') ||
    window.location.href.includes('reslashdev.co') ||
    window.location.href.includes('getreslash.com') ||
    window.location.href.includes('reslash.dev')
  )
    return 'https://staging.';
  if (window.location.href.includes('bojack2.') || window.location.href.includes('.vercel.app')) {
    return window.location.href.split('.')[0];
  }
  return 'https://app.';
};

export const mainSubDomain = getMainSubdomain();

export const config = getConfig();

export const bounds = {
  x: Infinity,
  y: Infinity,
};

export const volBounds = {
  lower: 200,
  upper: 300,
};

export const canvasSize = {
  w: 3200,
  h: 1800,
};

export const maxPlayableScreenshares = 4;

export const fileUploadLimit = 10;

export const WASABIBASEURL = 'https://s3.us-west-1.wasabisys.com/reslash-profile-pics/';

export const tawkto = { propertyId: '5fffaa24c31c9117cb6e6869', widgetKey: '1ervb18f3' };

export const maxParticipants = 100;

export const inactivityTimeout = {
  countdown: 600,
  inactivityTime: 30 * 60,
};

export const spillOverLimit = 70;

export const loggerEndpoint = 'wss://logger.reslash.dev';
