import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export type claims =
  | {
      roomsV2: { [roomID: string]: 'g' | 'a' | 't' } | undefined;
      roomUserRoleV2: { [roomID: string]: 'a' | 'h' | 's' } | undefined;
      spacesV2: { [spaceID: string]: 'g' | 'o' | 't' } | undefined;
    }
  | undefined;

let initialState: claims = {
  roomsV2: {},
  roomUserRoleV2: {},
  spacesV2: {},
};

export const customClaimSlice = createSlice({
  name: 'customClaim',
  initialState,
  reducers: {
    setCustomClaim(state, action) {
      const claim: claims = _.cloneDeep(action.payload);
      if (!state) {
        state = {
          roomsV2: {},
          roomUserRoleV2: {},
          spacesV2: {},
        };
      }
      if (claim) {
        state.roomsV2 = claim.roomsV2;
        state.roomUserRoleV2 = claim.roomUserRoleV2;
        state.spacesV2 = claim.spacesV2;
      }
    },
  },
});

export const getCustomClaimFromRedux = (state: { customClaim: claims }) => state.customClaim;
