import _ from 'lodash';
import { CircleFill, ThreeDotsVertical } from 'react-bootstrap-icons';
import { LogIn } from 'react-feather';
import { useSelector } from 'react-redux';
import ReslashLogo from '../../assets/svg/reslashLogo';
import { maxParticipants } from '../../config';
import { formatLong } from '../../helper/interactive/misc';
import { setCurrentRoomCopy } from '../../store';
import { getSpacesRooms } from '../../store/spacesRooms';
import { getWallpaperRedux } from '../../store/wallpaper';
import Avatar from '../avatars/avatar';
import './dynamicRoom.scss';

const NameTag = (props: {
  user: { email: string; online: boolean; profilePic: string; color: string; name: string };
}) => {
  // const [tripleDotVisible, setTripleDotVisible] = useState(false);
  return (
    <>
      <div
        className="d-room-member"
        // onMouseEnter={(e) => {
        //   setTripleDotVisible(true);
        // }}
        // onMouseLeave={(e) => {
        //   setTripleDotVisible(false);
        // }}
      >
        <div className="d-room-member-avatar">
          <Avatar src={props.user.profilePic} name={props.user.name} color={props.user.color} omitCircle={true} />
        </div>
        <div className="d-room-member-nametag-options">
          <div className="d-room-member-nametag-options-status">
            <CircleFill color={'green'} size={6} />
          </div>
          <div className="d-room-member-nametag-options-name">
            <span>{formatLong(props.user.name.split(' ')[0], 8)}</span>
          </div>
          <div className="d-room-member-nametag-options-settings" style={{ visibility: 'hidden' }}>
            <ThreeDotsVertical size={12} />
          </div>
        </div>
      </div>
    </>
  );
};

const DynamicRoom = (props: {
  ind: number;
  presence: { [userID: string]: { email: string; online: boolean; profilePic: string; color: string; name: string } };
  roomName: string;
  isPlaceHolder?: boolean;
  onClose?: () => void;
}) => {
  const currentWall = useSelector(getWallpaperRedux);
  const spacesRooms = useSelector(getSpacesRooms);
  const limit = spacesRooms.rooms[spacesRooms.currentRoom]?.roomDetails?.capacity || maxParticipants;

  return (
    <>
      {!props.isPlaceHolder && (
        <div className="dynamic-room-container">
          <div className="dynamic-room-controls-section">
            <div className="d-room-counter">{props.ind + 1}</div>
            <div className="d-room-title">{props.roomName}</div>
            <div className="d-room-vacant-status">
              {_.keys(props.presence).length}/{limit}
            </div>
            {/* <div className="d-room-logo">
              <img src={currentWall} />
            </div> */}
            {_.keys(props.presence).length < limit && (
              <div
                className="d-room-enter-icon"
                onClick={() => {
                  setCurrentRoomCopy(props.ind);
                  if (props.onClose) props.onClose();
                }}
              >
                <LogIn size={16} />
              </div>
            )}
          </div>
          <div className="dynamic-room-members-section">
            {_.map(props.presence, (user) => {
              return <NameTag user={user} />;
            })}
          </div>
        </div>
      )}

      {props.isPlaceHolder && (
        <div
          className="dynamic-room-container"
          style={{ border: '1px solid #c4c4c4', flexDirection: 'column', pointerEvents: 'none' }}
        >
          <ReslashLogo size={56} />
          <span
            style={{
              width: '70%',
              marginTop: '12px',
              fontWeight: 'bold',
              textAlign: 'center',
              verticalAlign: 'middle',
              wordWrap: 'break-word',
            }}
          >
            MORE ROOMS WILL BE ALLOCATED DYNAMICALLY.
          </span>
        </div>
      )}
    </>
  );
};

export default DynamicRoom;
