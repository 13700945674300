import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import GoogleLogo from '../../assets/svg/googleLogo';
import { sendAmplitudeData } from '../../helper/amplitude';
import { initSignInWithGoogle } from '../../helper/firebase/auth';
import { signupHelper } from '../../helper/signin';
import FloatInput from '../floatInput/floatInput';
import './signup.scss';

const SignUp = () => {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [state, setState_] = useState<{ message: string; user: firebase.User | null; success: boolean }>({
    message: '',
    user: null,
    success: false,
  });
  const history = useHistory();

  useEffect(() => {
    if (!state.user && state.success) {
      history.push('/confirmEmail', { email: email });
    }
  }, [state]);

  const onSignup = async () => {
    const state = await signupHelper(fname, lname, email.trim(), password);
    if (state.success) {
      sendAmplitudeData('Signed up', { state, status: 'success' });
    } else {
      sendAmplitudeData('Signed up', { state, status: 'failed' });
    }
    setState_({ ...state });
  };

  return (
    <>
      <div className="sign-up-container">
        <div className="sign-up-form-section">
          <div className="sign-up-form-items">
            <div className="sign-up-form-header">
              <div className="sign-up-form-header-title">
                <span>Sign up</span>
              </div>
              <div className="sign-up-form-header-subtitle">
                <span>Sign up to start building your Reslash space</span>
              </div>
            </div>
            <div className="sign-up-form-name">
              <div className="sign-up-form-fname">
                <FloatInput
                  type={'text'}
                  label={'First Name*'}
                  onChangeInput={(inputText) => {
                    setFname(inputText);
                    setState_({ message: '', user: null, success: false });
                  }}
                />
              </div>
              <div className="sign-up-form-lname">
                <FloatInput
                  type={'text'}
                  label={'Last Name'}
                  onChangeInput={(inputText) => {
                    setLname(inputText);
                    setState_({ message: '', user: null, success: false });
                  }}
                />
              </div>
            </div>
            <div className="sign-up-form-field">
              <FloatInput
                type={'text'}
                label={'Email Address*'}
                onChangeInput={(inputText) => {
                  setEmail(inputText);
                  setState_({ message: '', user: null, success: false });
                }}
              />
            </div>
            <div className="sign-up-form-field">
              <FloatInput
                type={'password'}
                label={'Password*'}
                showHide={true}
                onChangeInput={(inputText) => {
                  setPassword(inputText);
                  setState_({ message: '', user: null, success: false });
                }}
              />
            </div>

            {state.message.length > 0 && (
              <div className="sign-up-form-error-state">
                <span>{state.message}</span>
              </div>
            )}
          </div>

          <div className="sign-up-form-action">
            <div
              className="sign-up-form-button"
              onClick={() => {
                sendAmplitudeData('Sign up clicked', {});
                onSignup();
              }}
            >
              <span>Continue</span>
            </div>
            <div className="sign-up-msg-section">
              <span>
                Signed up already?{' '}
                <a
                  href="#"
                  onClick={() => {
                    history.push('/login');
                  }}
                >
                  Sign in.
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="sign-up-sso-section">
          <div
            className="sso-button google"
            onClick={() => {
              initSignInWithGoogle();
            }}
          >
            <GoogleLogo size={24} />
            <span style={{ paddingLeft: '14px' }}>Sign Up with Google</span>
          </div>
          {/* <div className="sso-button apple">
            <AppleLogo size={24} />
            <span style={{ paddingLeft: '14px' }}>Sign Up with Apple</span>
          </div>
          <div className="sso-button facebook">
            <Facebook size={24} />
            <span style={{ paddingLeft: '14px' }}>Sign Up with Facebook</span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SignUp;
