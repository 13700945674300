import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ArrowsFullscreen, Dash, FullscreenExit, Plus } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { canvasSize } from '../../../config';
import { sendAmplitudeData } from '../../../helper/amplitude';
import { clip } from '../../../helper/interactive/misc';
import { getListenerPosition, setIsBackgroundLoadingState, setZoomStore, updateCanvas } from '../../../store';
import { getHideMenuState } from '../../../store/hideMenu';
import { getWallpaperRedux, getWallpaperV2Redux } from '../../../store/wallpaper';
import { zoomSlice } from '../../../store/zoom';
import CallTools from '../../callTools/callTools';
import NotificationToast from '../../notificationToast/notificationToast';
import NoGoZonesComp from '../noGoZones/noGoZonesComp';
import './canvasView.scss';
import { Panzoom } from './panzoom';

function scale(number: number, inMin: number, inMax: number, outMin: number, outMax: number) {
  return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

function openFullscreen(elem: HTMLElement) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
    //@ts-ignore
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    //@ts-ignore
    elem.webkitRequestFullscreen();
    //@ts-ignore
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    //@ts-ignore
    elem.msRequestFullscreen();
  }
}

function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
    //@ts-ignore
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    //@ts-ignore
    document.webkitExitFullscreen();
    //@ts-ignore
  } else if (document.msExitFullscreen) {
    /* IE11 */
    //@ts-ignore
    document.msExitFullscreen();
  }
}

const BackGround = (props: { setBgState: (state: number) => void }) => {
  let [bgImage, setbgImage] = useState('');
  const [isThumb, setIsThumb] = useState(true);
  const [bgLoading, setBgLoading] = useState(true);
  const [backgroundProp, setBackgroundProp] = useState<
    | {}
    | {
        backgroundSize: string;
        backgroundPosition: string;
        backgroundRepeat: string;
      }
  >({});
  const currentWall = useSelector(getWallpaperRedux);
  const currentWallV2 = useSelector(getWallpaperV2Redux);

  useEffect(() => {
    if (!currentWallV2) {
      setIsThumb(false);
      setbgImage(currentWall);
      setBackgroundProp({});
    } else {
      setBgLoading(true);
      setIsBackgroundLoadingState(true);
      // setbgImage(currentWallV2.thumbnail);
      const imgThumb = new Image();
      imgThumb.src = currentWallV2.thumbnail;
      imgThumb.onload = () => {
        if (bgLoading) {
          setbgImage(currentWallV2.thumbnail);
          setIsThumb(true);
          setBackgroundProp(currentWallV2.properties);
        }
      };
      const img = new Image();
      img.src = currentWallV2.full;
      props.setBgState(1);
      img.onload = () => {
        setBgLoading(false);
        setIsBackgroundLoadingState(false);
        setbgImage(currentWallV2.full);
        setIsThumb(false);
        setBackgroundProp(currentWallV2.properties);
        props.setBgState(2);
        setTimeout(() => {
          props.setBgState(0);
        }, 2000);
      };
      img.onerror = () => {
        props.setBgState(-1);
        setIsBackgroundLoadingState(false);
        setTimeout(() => {
          props.setBgState(0);
        }, 2000);
      };
    }
  }, [currentWall, currentWallV2]);

  return (
    <>
      <div
        className="room-background"
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          filter: !isThumb ? 'none' : 'blur(20px)',
          ...backgroundProp,
        }}
      >
        {/* <BlurhashCanvas hash="LEHV6nWB2yk8pyo0adR*.7kCMdnj" width={400} height={300} punch={1} /> */}
      </div>
    </>
  );
};

export default function CanvasView(props: { children: JSX.Element; canvasID: string; spaceID: string }) {
  const dispatch = useDispatch();
  const setZoom = (zoom: number) => {
    dispatch(zoomSlice.actions.setZoom(zoom));
  };
  let [bgLoading, setBGLoading] = useState(1);
  const minScaleRef = useRef(0.1);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  function isTouch() {
    return 'ontouchstart' in window;
  }

  useEffect(() => {
    let el = document.getElementById('canvas') as HTMLElement;
    const panzoom = new Panzoom(el, el.parentElement as HTMLElement, '.interactive', '.interactive-scroll');
    // @ts-ignore
    window.panzoom = panzoom;
    window.inIframe = () => {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };

    const homingFunction = () => {
      panzoom.zoom(panzoom.zoomLevel, 1, 5, 5);
      const listenerLocation = getListenerPosition();
      const width = window.innerWidth;
      const height = window.innerHeight;
      setTimeout(() => panzoom.pan(-listenerLocation.x + width / 2 - 80, -listenerLocation.y + height / 2 - 80), 100);
    };

    //@ts-ignore
    window.homingFunction = homingFunction;
    if (el) {
      const init = () => {
        panzoom.zoom(panzoom.zoomLevel, panzoom.constraintScale, 5, 5);
      };
      setTimeout(init, 1000);
      const setMinScale = () => {
        let minWidHeight = window.innerHeight;
        let minCanvasWidHeight = 1800;
        if (window.innerWidth > window.innerHeight) {
          minWidHeight = window.innerWidth;
          minCanvasWidHeight = 3200;
        }
        minScaleRef.current = minWidHeight / minCanvasWidHeight;
      };
      if (minScaleRef.current === 0.1) {
        setMinScale();
      }
      window.addEventListener('resize', () => {
        // panzoom.setOptions({
        //   contain: 'outside',
        // });
        // panzoom.reset();
        // init();
        // updateCanvas({ position: { x: 0, y: 0 }, shape: { w: window.innerWidth, h: window.innerHeight } });
        // setTimeout(fix, 500);
        setMinScale();
      });
      window.addEventListener('resize', () => {
        // panzoom.setOptions({
        //   contain: 'outside',
        // });
        // panzoom.reset();
        // init();
        updateCanvas({ position: { x: 0, y: 0 }, shape: { w: window.innerWidth, h: window.innerHeight } });
        // setTimeout(fix, 500);
        setMinScale();
      });
      // window.addEventListener('orientationchange', () => {
      //   setMinScale();
      //   panzoom.reset();
      //   init();
      // });
      // panzoom.zoomOut()
      panzoom.setListener('zoomchange', (newzoom) => {
        // if (didBGChange.current) {
        //   didBGChange.current = false;
        //   return;
        // }
        // panzoomState.current.scale = event.detail.scale;
        // panzoomState.current.x = event.detail.x;
        // panzoomState.current.y = event.detail.y;
        // const minWidHeight = Math.min(window.innerWidth, window.innerHeight);

        setZoomLevel(scale(newzoom, minScaleRef.current, 2.5, 0, 1));
        // setPanzoomState({x: event.detail.x, y: event.detail.y, scale: event.detail.scale})
        el.style.setProperty('--transformScale', clip(1 / newzoom, 0, 1.75) + ''); // this transform will be used to scale nametag
        setZoom(newzoom);
        setZoomStore(newzoom);
      });
      document.getElementById('zoomIn')?.addEventListener('click', () => {
        panzoom.zoom(panzoom.zoomLevel, panzoom.zoomLevel * 1.2, window.innerWidth / 2, window.innerHeight / 2);
      });
      document.getElementById('zoomOut')?.addEventListener('click', () => {
        panzoom.zoom(panzoom.zoomLevel, panzoom.zoomLevel * 0.8, window.innerWidth / 2, window.innerHeight / 2);
        //setTimeout(fix, 10);
      });
    }

    return () => {
      panzoom.destroy();
    };
  }, [props.canvasID, props.spaceID]);
  // const style = isTouch() ? { height: canvasSize.h } : {};

  const hideMenuState = useSelector(getHideMenuState);

  const escFunction = useCallback((event) => {
    if (
      !document.fullscreenElement &&
      //@ts-ignore
      !document.webkitIsFullScreen &&
      //@ts-ignore
      !document.mozFullScreen &&
      //@ts-ignore
      !document.msFullscreenElement
    ) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('webkitfullscreenchange', escFunction, false);
    document.addEventListener('mozfullscreenchange', escFunction, false);
    document.addEventListener('fullscreenchange', escFunction, false);
    document.addEventListener('MSFullscreenChange', escFunction, false);
    return () => {
      document.removeEventListener('webkitfullscreenchange', escFunction, false);
      document.removeEventListener('mozfullscreenchange', escFunction, false);
      document.removeEventListener('fullscreenchange', escFunction, false);
      document.removeEventListener('MSFullscreenChange', escFunction, false);
    };
  }, [isFullScreen]);

  return (
    <div className="canvasContainer" id="canvasContainer">
      <NotificationToast
        icons={<span className="material-icons">done</span>}
        message={'Background changed'}
        show={bgLoading === 2}
        width={220}
        onClose={() => {}}
        id={'change-bg-success'}
      />
      <NotificationToast
        icons={<span className="material-icons">error</span>}
        message={'Failed to load background'}
        show={bgLoading === -1}
        width={220}
        onClose={() => {}}
        id={'change-bg-fail'}
      />
      <div className="controls" style={hideMenuState ? { display: 'none' } : {}}>
        <span
          className="material-icons location has-v2-tooltip-top-small-long"
          data-tooltip-text="Find yourself"
          onClick={() => {
            //@ts-ignore
            window.homingFunction();
            sendAmplitudeData('Find me clicked', {});
          }}
        >
          my_location
        </span>
        <div
          className="fullscreen has-v2-tooltip-top-small"
          data-tooltip-text="Fullscreen"
          onClick={() => {
            sendAmplitudeData('Fullscreen button clicked', {});
            if (!isFullScreen) {
              openFullscreen(document.documentElement);
              setIsFullScreen(true);
            } else {
              closeFullscreen();
              setIsFullScreen(false);
            }
          }}
        >
          {!isFullScreen ? <ArrowsFullscreen /> : <FullscreenExit />}
        </div>
        <div id="zoomIn" className="has-v2-tooltip-top-small" data-tooltip-text="Zoom in">
          <Plus />
        </div>
        <span className="zoomLevel has-v2-tooltip-top-small" data-tooltip-text="Zoom %">
          {Math.ceil(zoomLevel * 100)}%
        </span>
        <div id="zoomOut" className="has-v2-tooltip-top-small-long" data-tooltip-text="Zoom out">
          <Dash />
        </div>
      </div>
      <div
        className="canvas"
        data-clarity-unmask="True"
        id="canvas"
        style={{
          width: canvasSize.w,
          height: canvasSize.h,
          position: 'absolute',
          // filter: loadedBackground ? 'none' : 'blur(20px)',
          // backgroundImage: `url(${
          //   loadedBackground ||
          //   'https://images.unsplash.com/photo-1446071103084-c257b5f70672?ixid=MnwxODcwMDN8MHwxfGFsbHwyfHx8fHx8MXx8MTYzMDA5MDE5Ng&q=70&w=200'
          // })`,
          // backgroundSize: 'cover',
        }}
      >
        <BackGround
          setBgState={(number) => {
            setBGLoading(number);
          }}
        />
        {props.children}
        <NoGoZonesComp spaceID={props.spaceID} roomID={props.canvasID} />
      </div>
      <CallTools canvasID={props.canvasID} spaceID={props.spaceID} />
    </div>
  );
}
