//@ts-ignore
import WildEmitter from 'wildemitter';

let audioContextType: any;

if (typeof window !== 'undefined') {
  audioContextType = window.AudioContext || ((window as any).webkitAudioContext as AudioContext);
}

function getMaxVolume(analyser: AnalyserNode, fftBins: Float32Array) {
  let maxVolume = -Infinity;
  analyser.getFloatFrequencyData(fftBins);
  for (let i = 4, ii = fftBins.length; i < ii; i++) {
    if (fftBins[i] > maxVolume && fftBins[i] < 0) {
      maxVolume = fftBins[i];
    }
  }
  return maxVolume;
}

type harkOptions = {
  smoothing?: number;
  interval?: number;
  threshold?: number;
  history?: number;
};

export default class HarkV2 {
  harker: any;
  options?: harkOptions;
  smoothing: number;
  interval: number;
  threshold: number;
  history: number;
  running: boolean;
  audioContext: AudioContext;
  analyser: AnalyserNode;
  fftBins: Float32Array;
  stream: MediaStream;
  sourceNode: MediaStreamAudioSourceNode;
  lastRun: number;
  timer?: number;

  constructor(stream: MediaStream, conf: harkOptions | undefined) {
    this.harker = new WildEmitter();
    this.options = conf || undefined;
    this.smoothing = this.options?.smoothing ?? 0.1;
    this.interval = this.options?.interval ?? 50;
    this.threshold = this.options?.threshold ?? -50;
    this.history = this.options?.history ?? 10;
    this.running = true;
    this.harker.speakingHistory = [];
    for (let i = 0; i < this.history; i++) {
      this.harker.speakingHistory.push(0);
    }
    this.audioContext = new audioContextType();
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 512;
    this.analyser.smoothingTimeConstant = this.smoothing;
    this.fftBins = new Float32Array(this.analyser.frequencyBinCount);
    this.stream = stream;
    this.sourceNode = this.audioContext.createMediaStreamSource(stream);
    this.sourceNode.connect(this.analyser);
    this.harker.speaking = false;
    this.audioContext.onstatechange = () => {
      this.harker.emit('state_change', this.audioContext.state);
    };
    this.lastRun = 0;
    this.checkVolume();
  }

  checkVolume() {
    const currentVolume = getMaxVolume(this.analyser, this.fftBins);
    const now = performance.now();
    if (now - this.lastRun >= this.interval) {
      this.lastRun = now;
      this.harker.emit('volume_change', currentVolume, this.threshold);
      let history = 0;
      if (currentVolume > this.threshold && !this.harker.speaking) {
        for (let i = this.harker.speakingHistory.length - 3; i < this.harker.speakingHistory.length; i++) {
          history += this.harker.speakingHistory[i];
        }
        if (history >= 2) {
          this.harker.speaking = true;
          this.harker.emit('speaking');
        }
      } else if (currentVolume < this.threshold && this.harker.speaking) {
        for (let i = 0; i < this.harker.speakingHistory.length; i++) {
          history += this.harker.speakingHistory[i];
        }
        if (history == 0) {
          this.harker.speaking = false;
          this.harker.emit('stopped_speaking');
        }
      }
      this.harker.speakingHistory.shift();
      this.harker.speakingHistory.push(0 + (currentVolume > this.threshold ? 1 : 0));
    }
    this.timer = requestAnimationFrame(() => {
      this.checkVolume();
    });
  }

  setThreshold(t: number) {
    this.threshold = t;
  }

  stop() {
    this.running = false;
    if (this.timer) cancelAnimationFrame(this.timer);
    this.harker.emit('volume_change', -100, this.threshold);
    if (this.harker.speaking) {
      this.harker.speaking = false;
      this.harker.emit('stopped_speaking');
    }
    this.analyser.disconnect();
    this.sourceNode.disconnect();
  }

  async suspend() {
    await this.audioContext.suspend();
  }

  async resume() {
    await this.audioContext.resume();
  }
}
