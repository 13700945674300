import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { X } from 'react-bootstrap-icons';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { setBroadcast } from '../../helper/interactive/firebaseRTD';
import {
  closeModalThunk,
  getLocalParticipantFromStoreDailyco,
  getSpacesRoomsState,
  newModalThunk,
  openModalThunk,
  setBroadcasterFuncStore,
  setBroadcasterMuteStore,
  setBroadcasterStatusStore,
} from '../../store';
import { getAuth } from '../../store/auth';
import { getBroadcasterRedux, getBroadcasterTokenRedux } from '../../store/broadcast';
import { getLocalStreamRedux } from '../../store/dailycoParticipants';
import { getGetModalState } from '../../store/modal';
import { getPresenceAll } from '../../store/presence';
import { getCurrentSpace } from '../../store/spacesRooms';
import Avatar from '../avatars/avatar';
import Video from '../interactive/video/video';
import LoaderV2 from '../loader/loaderV2';
import { ModalStyle } from '../modal/modal';
import './broadcast.scss';

const broadcastStyle = ModalStyle;

export const broadcastStartID = 'broadcast-start-setting';
export const openBroadcastStart = openModalThunk(broadcastStartID);
export const closeBroadcastStart = closeModalThunk(broadcastStartID);

const BroadcastStart = () => {
  const localStream = useSelector(getLocalStreamRedux);
  const [localVideo, setLocalVideo] = useState<MediaStream | null>(null);
  const broadcastStartState = useSelector(getGetModalState(broadcastStartID));
  const auth = useSelector(getAuth);
  const presence = useSelector(getPresenceAll);
  const [listeners, setListeners] = useState(0);

  useEffect(() => {
    let listeners_ = 0;
    for (let roomID in presence) {
      const room = presence[roomID];
      for (let userID in room) {
        const user = room[userID];
        if (user.online) listeners_ += 1;
      }
    }
    setListeners(listeners_);
  }, [presence]);
  // const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    newModalThunk(broadcastStartID, false)();
  }, []);

  useEffect(() => {
    if (localStream.video && broadcastStartState) {
      const newStream = new MediaStream([localStream.video]);
      setLocalVideo(newStream);
    } else {
      setLocalVideo(null);
    }
  }, [localStream, broadcastStartState]);

  return (
    <ReactModal
      id="BroadcastStart"
      className="broadcastModal"
      isOpen={broadcastStartState}
      style={{ overlay: broadcastStyle.overlay, content: broadcastStyle.content }}
    >
      <div className="header">ANNOUNCEMENT</div>
      <div className="cross" onClick={() => closeBroadcastStart()}>
        <X />
      </div>
      <div className="body">
        <div className="info">You’ll be heard across rooms but you won’t hear anyone</div>
        <div className="vidContainer">
          {localVideo ? (
            <Video id="broadcast-vid" srcObject={localVideo} />
          ) : (
            <Avatar src={auth.userDetails.profilePic} name={auth.userDetails.name} />
          )}
        </div>
        <div className="nameAvatar">
          <Avatar src={auth.userDetails.profilePic} name={auth.userDetails.name} />
          <div className="nameListener">
            <div className="name">{auth.userDetails.name}</div>
          </div>
        </div>
        <div
          className="mainButton"
          onClick={() => {
            closeBroadcastStart();
            openBroadcasterViewer();
          }}
        >
          Start Announcing
        </div>
        <div className="bottom">Limited to space owners with a 5-minute time limit</div>
      </div>
    </ReactModal>
  );
};

const BroadcastListener = () => {
  // const localStream = useSelector(getLocalStreamRedux);
  const [isMute, setIsMute] = useState(false);
  const [isVidMute, setIsVidMute] = useState(false);
  const [video, setVideo] = useState<MediaStream | null>(null);
  const [audio, setAudio] = useState<MediaStream | null>(null);
  const [participants, setParticipants] = useState<{ [uid: string]: boolean }>({});
  const broadcastDetails = useSelector(getBroadcasterRedux);
  const auth = useSelector(getAuth);
  const broadcasterToken = useSelector(getBroadcasterTokenRedux);
  const currentSpace = useSelector(getCurrentSpace);

  (window as any).setBroadcasterVideo = (vid: MediaStream | null) => {
    setVideo(vid);
  };
  (window as any).setBroadcasterAudio = (aud: MediaStream | null) => {
    setAudio(aud);
  };

  (window as any).onLeave = () => {
    setBroadcasterStatusStore(false);
  };
  (window as any).onMute = (flag: boolean) => {
    setIsMute(flag);
  };
  (window as any).onVidMute = (flag: boolean) => {
    setIsVidMute(flag);
  };
  (window as any).onParticipantUpdate = (uid: string, flag: boolean) => {
    setParticipants((participants) => {
      participants[uid] = flag;
      return participants;
    });
  };

  return broadcastDetails.isLive && auth.user.id !== broadcastDetails.uid ? (
    <div className="broadcastModal" id="broadcast-listener">
      {broadcasterToken && (
        <iframe
          id="broadcast-iframe"
          style={{ display: 'none' }}
          src={`/broadcastPage?token=${broadcasterToken}&spaceID=${currentSpace}`}
          allow="microphone; camera; autoplay; display-capture;"
        />
      )}
      <div className="header">ANNOUNCEMENT</div>
      <div className="body">
        <div className={'vidContainer ' + (!participants[broadcastDetails.uid] ? 'centered' : '')}>
          {participants[broadcastDetails.uid] ? (
            !isVidMute && video ? (
              <Video id="broadcast-vid" srcObject={video} />
            ) : (
              <Avatar src={broadcastDetails.profilePic} name={broadcastDetails.name} />
            )
          ) : (
            <LoaderV2 />
          )}
          {!isMute && audio ? (
            <Video id="broadcast-aud" srcObject={audio} isAudio={true} />
          ) : (
            <div className="muteIcon">
              <span className="material-icons">mic_off</span>
            </div>
          )}
        </div>
        <div className="nameAvatar">
          <Avatar src={broadcastDetails.profilePic} name={broadcastDetails.name} />
          <div className="nameListener">
            <div className="name">{broadcastDetails.name}</div>
            <div className="listener">
              {
                _.filter(participants, (flag) => {
                  return flag;
                }).length
              }{' '}
              listeners
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export const broadcasterViewerID = 'broadcast-viewer-modal';
export const openBroadcasterViewer = openModalThunk(broadcasterViewerID);
export const closeBroadcasterViewer = closeModalThunk(broadcasterViewerID);

const BroadcasterView = () => {
  const [isMute, setIsMute] = useState(false);
  const [isVidMute, setIsVidMute] = useState(false);
  const [video, setVideo] = useState<MediaStream | null>(null);
  const [audio, setAudio] = useState<MediaStream | null>(null);
  const [timer, setTimer] = useState(60 * 5);
  const [countDown, setCounterDown] = useState(5);
  const [participants, setParticipants] = useState<{ [uid: string]: boolean }>({});
  const broadcasterViewerState = useSelector(getGetModalState(broadcasterViewerID));
  const broadcastDetails = useSelector(getBroadcasterRedux);
  const broadcasterToken = useSelector(getBroadcasterTokenRedux);
  const currentSpace = useSelector(getCurrentSpace);
  const localStream = useSelector(getLocalStreamRedux);
  const auth = useSelector(getAuth);

  useEffect(() => {
    let timeInterval: NodeJS.Timeout;
    if (participants[broadcastDetails.uid])
      timeInterval = setInterval(() => {
        setTimer((timer) => timer - 1);
      }, 1000);
    return () => {
      clearInterval(timeInterval);
    };
  }, [participants[broadcastDetails.uid], broadcastDetails]);

  useEffect(() => {
    if (broadcastDetails.isLive && auth.user.id === broadcastDetails.uid) {
      openBroadcasterViewer();
    }
  }, [broadcastDetails]);

  useEffect(() => {
    let timeInterval: NodeJS.Timeout | undefined = undefined;
    if (broadcasterViewerState) {
      if (countDown > 0)
        timeInterval = setInterval(() => {
          setCounterDown((countDown) => {
            if (countDown === 4) {
              setBroadcast(getSpacesRoomsState().currentSpace, {
                uid: auth.user.id,
                name: auth.userDetails.name,
                profilePic: auth.userDetails.profilePic,
                isLive: true,
                listeners: 0,
                ping: new Date().getTime(),
              });
            }
            if (countDown === 1 && timeInterval) clearInterval(timeInterval);
            return countDown - 1;
          });
        }, 1000);
      else if (timeInterval) clearInterval(timeInterval);
    } else {
      setTimer(60 * 5);
      setCounterDown(5);
      setParticipants({});
      if (timeInterval) clearInterval(timeInterval);
    }
  }, [broadcasterViewerState]);

  useEffect(() => {
    if (auth.user.id === broadcastDetails.uid) {
      (window as any).setBroadcasterAudio = (aud: MediaStream | null) => {
        setAudio(aud);
      };
      (window as any).onLeave = () => {
        setBroadcasterStatusStore(false);
      };
      (window as any).onMute = (flag: boolean) => {
        setIsMute(flag);
      };
      (window as any).onVidMute = (flag: boolean) => {
        setIsVidMute(flag);
      };
      (window as any).onParticipantUpdate = (uid: string, flag: boolean) => {
        setParticipants((participants) => {
          participants[uid] = flag;
          return participants;
        });
      };
    }
  }, [broadcastDetails.isLive, auth, broadcastDetails]);
  // const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (broadcastDetails.isLive) {
      if (auth.user.id === broadcastDetails.uid) {
        const localParticipant = getLocalParticipantFromStoreDailyco();
        localParticipant.muteFunc(true);
        localParticipant.muteVidFunc(true);
        setBroadcasterFuncStore({
          isLocal: true,
          toMute: (flag) => {
            const broadcastIframe = document.getElementById('broadcast-iframe') as HTMLIFrameElement;
            if (broadcastIframe) {
              (broadcastIframe.contentWindow as any).muteAud(flag);
              setBroadcasterMuteStore(true, flag);
            }
          },
          toVidMute: (flag) => {
            const broadcastIframe = document.getElementById('broadcast-iframe') as HTMLIFrameElement;
            if (broadcastIframe) {
              (broadcastIframe.contentWindow as any).muteVid(flag);
              setBroadcasterMuteStore(false, flag);
            }
          },
          isMuted: false,
          isVidMuted: false,
        });
      }
    }
  }, [broadcastDetails.isLive]);

  useEffect(() => {
    newModalThunk(broadcasterViewerID, false)();
  }, []);

  (window as any).setBroadcasterVideo = (vid: MediaStream | null) => {
    setVideo(vid);
  };

  const formatSeconds = (seconds: number) => {
    const sec = Math.floor(timer % 60);
    if (sec < 10) return `0${sec}`;
    return `${sec}`;
  };

  return (
    <ReactModal
      id="broadcasterViewModal"
      className="broadcastModal"
      isOpen={broadcasterViewerState}
      style={{ overlay: broadcastStyle.overlay, content: broadcastStyle.content }}
    >
      <div className="broadcastModal">
        {broadcastDetails.isLive && broadcasterToken && (
          <iframe
            id="broadcast-iframe"
            style={{ display: 'none' }}
            src={`/broadcastPage?token=${broadcasterToken}&spaceID=${currentSpace}&videoSource=${localStream.videoID}&audioSource=${localStream.micID}`}
            allow="microphone; camera; autoplay; display-capture;"
          />
        )}
        <div className="header">ANNOUNCEMENT</div>
        <div className="body">
          <div className="paddingContainer"></div>
          {countDown <= 0 && (
            <div className={'vidContainer ' + (!participants[broadcastDetails.uid] ? 'centered' : '')}>
              {participants[broadcastDetails.uid] ? (
                !isVidMute && video ? (
                  <Video id="broadcast-vid" srcObject={video} />
                ) : (
                  <Avatar src={broadcastDetails.profilePic} name={broadcastDetails.name} />
                )
              ) : (
                <LoaderV2 />
              )}
              {!isMute && audio ? (
                <Video id="broadcast-aud" srcObject={audio} isAudio={false} />
              ) : (
                <div className="muteIcon">
                  <span className="material-icons">mic_off</span>
                </div>
              )}
            </div>
          )}
          {countDown > 0 && <div className={'vidContainer centered countdown'}>{countDown}</div>}
          <div className="nameAvatar">
            <Avatar src={auth.userDetails.profilePic} name={auth.userDetails.name} />
            <div className="nameListener">
              <div className="name">{broadcastDetails.name}</div>
              <div className="listener">
                {
                  _.filter(participants, (flag) => {
                    return flag;
                  }).length
                }{' '}
                listeners
              </div>
            </div>
          </div>
          <div
            className="mainButton red"
            onClick={() => {
              setBroadcast(currentSpace, null).then(() => closeBroadcasterViewer());
            }}
          >
            Stop Announcing
          </div>
          <div className="bottom">
            {Math.floor(timer / 60)}:{formatSeconds(timer)}
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

const Broadcast = () => {
  return (
    <>
      <BroadcastStart />
      <BroadcastListener />
      <BroadcasterView />
    </>
  );
};

export default Broadcast;
