import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReslashLogo from '../../assets/svg/reslashLogo';
import BannerDead from '../../components/banner_dead/banner_dead';
import AllowPermissions from '../../components/customiseLogin/allowPermissions';
import ConfirmEmail from '../../components/customiseLogin/confirmEmail';
import CreateSpace from '../../components/customiseLogin/createSpace';
import EnterPassword from '../../components/customiseLogin/enterPassword';
import GuestLogIn from '../../components/customiseLogin/guestLogin';
import QuickBio from '../../components/customiseLogin/quickBio';
import ScheduledRoomModal from '../../components/customiseLogin/scheduledRoomModal';
import SignIn from '../../components/customiseLogin/signin';
import SignUp from '../../components/customiseLogin/signup';
import SpaceInputs from '../../components/customiseLogin/spaceInput';
import NewSpaceSwitcher from '../../components/customiseLogin/spaceSwitcher';
import VoiceAndVideoSettings from '../../components/customiseLogin/voiceVideoSettings';
import LoaderPage from '../../components/loader/loaderPage';
import { signOut } from '../../helper/firebase/auth';
import { getAuth } from '../../store/auth';
import { getSpacesRooms } from '../../store/spacesRooms';
import './login.scss';

const LoginPage = (props: {
  modal:
    | 'signUp'
    | 'signIn'
    | 'guestLogIn'
    | 'confirmEmail'
    | 'createSpace'
    | 'spaceInputs'
    | 'allowPermissions'
    | 'voiceAndVideoSettings'
    | 'loginAsGuest'
    | 'spaceSwitcher'
    | 'room-password'
    | 'room-closed';
  opensAt?: number;
  inApp?: boolean;
}) => {
  const auth = useSelector(getAuth);
  const history = useHistory();
  const spacesRooms = useSelector(getSpacesRooms);

  // console.log(auth);

  return (
    <>
      <BannerDead />
      <div className="personalised-page-container">
        {!auth.loading && !(props.modal === 'spaceSwitcher' && spacesRooms.spacesLoading) ? (
          <>
            <div className="personalised-page-grid">
              <div className="cust-page-header-container">
                <div className="cust-page-header-company-info">
                  <div className="company-logo">
                    <ReslashLogo size={60} />
                  </div>
                  <div className="company-name">
                    <span>Reslash</span>
                  </div>
                </div>
              </div>
              <div className="cust-page-body-section">
                <div className="cust-page-body-container">
                  {props.modal === 'signUp' && <SignUp />}
                  {props.modal === 'signIn' && <SignIn />}
                  {props.modal === 'guestLogIn' && <GuestLogIn />}
                  {props.modal === 'confirmEmail' && <ConfirmEmail />}
                  {props.modal === 'createSpace' && <CreateSpace />}
                  {props.modal === 'spaceInputs' && <SpaceInputs />}
                  {props.modal === 'allowPermissions' && <AllowPermissions />}
                  {props.modal === 'loginAsGuest' && <QuickBio />}
                  {props.modal === 'voiceAndVideoSettings' && <VoiceAndVideoSettings />}
                  {props.modal === 'spaceSwitcher' && <NewSpaceSwitcher />}
                  {props.modal === 'room-password' && <EnterPassword />}
                  {/* {props.modal === 'room-closed' && <RoomClosed opensAt={props.opensAt} />} */}
                  {props.modal === 'room-closed' && (
                    <ScheduledRoomModal
                      inApp={props.inApp === undefined ? false : props.inApp}
                      isRoomClosed={false}
                      opensAt={props.opensAt}
                    />
                  )}
                  {/* <PasswordReset />
                <NewSpaceSwitcher /> */}
                </div>
              </div>
              <div className="cust-page-footer-container">
                <div className="cust-page-header-footer-items">
                  <div
                    className="cust-footer-item"
                    onClick={() => {
                      window.location.href = 'https://reslash.co';
                    }}
                  >
                    <span>About</span>
                  </div>
                  <div
                    className="cust-footer-item"
                    onClick={() => {
                      window.location.href =
                        'https://app.termly.io/document/privacy-policy/3758da20-d6cb-4c5c-be23-a7921851a76f';
                    }}
                  >
                    <span>Privacy Policy</span>
                  </div>
                  <div
                    className="cust-footer-item"
                    onClick={() => {
                      window.location.href =
                        'https://app.termly.io/document/terms-and-conditions/7a7fa00b-3f55-4f4c-bcf1-4eb64244dfba';
                    }}
                  >
                    <span>Terms and Conditions</span>
                  </div>
                  <div
                    className="cust-footer-item"
                    onClick={() => {
                      window.location.href = 'https://reslash.tawk.help/';
                    }}
                  >
                    <span>Help Center</span>
                  </div>
                  {auth.isLoggedIn && (
                    <div
                      className="cust-footer-item"
                      onClick={async () => {
                        await signOut();
                        history.push('/login');
                      }}
                    >
                      <span>Logout</span>
                      {/* <ChevronDown size={14} /> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <LoaderPage />
        )}
      </div>
    </>
  );
};

export default LoginPage;
