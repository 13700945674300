import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAuth } from '../../store/auth';
import './guestLogin.scss';

const GuestLogIn = () => {
  const history = useHistory();

  const auth = useSelector(getAuth);

  useEffect(() => {
    let redirection = localStorage.getItem('redirection');
    if (!redirection || redirection === '[object Object]') redirection = '/';
    if (auth.isLoggedIn) {
      history.replace(redirection);
    }
  }, [auth]);

  return (
    <>
      <div className="sign-in-container">
        <div className="sign-in-form-section">
          <div className="sign-in-form-action">
            <div
              className="sign-in-form-button"
              onClick={() => {
                history.push('/login');
              }}
            >
              <span>Sign in to enter space</span>
            </div>
            <div className="sign-in-msg-section">
              <span>
                Haven't signed up?{' '}
                <a
                  href="#"
                  onClick={() => {
                    history.push('/signup');
                  }}
                >
                  Do it here.
                </a>
              </span>
            </div>
          </div>
          <div className="guest-sign-in-form-action">
            <div
              className="guest-sign-in-form-button"
              onClick={() => {
                history.push('/loginAsGuest');
              }}
            >
              <span>Enter as guest</span>
            </div>
            {/* <div className="guest-sign-in-msg-section">
              <span>You'll enter the space lobby</span>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default GuestLogIn;
