import React, { useEffect, useRef, useState } from 'react';
import { Check } from 'react-feather';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { formatLong } from '../../../helper/interactive/misc';
import { closeModalThunk, newModalThunk, openModalThunk } from '../../../store';
import { getLocalState } from '../../../store/dailycoParticipants';
import { getGetModalState } from '../../../store/modal';
import { getSpacesRooms } from '../../../store/spacesRooms';
import DropDown from '../../dropdown/dropdown';
import { ModalStyle } from '../../modal/modal';
import './camAsScreenNew.scss';

export const camAsScreenID = 'cam-as-screen-id';
let type: 'fullscreen' | 'normal' = 'normal';

export const openCamAsScreenModal = (type_: 'fullscreen' | 'normal') => {
  type = type_;
  return openModalThunk(camAsScreenID);
};
export const closeCamAsScreenModal = closeModalThunk(camAsScreenID);

export const CamAsScreenModal = () => {
  const spacesRooms = useSelector(getSpacesRooms);
  const localJitsiParticipant = useSelector(getLocalState);
  const camAsScreenState = useSelector(getGetModalState(camAsScreenID));
  useEffect(() => {
    newModalThunk(camAsScreenID, false)();
  }, []);

  const [audioCheck, setAudioCheck] = useState(false);
  const [vidDevices, setVidDevices] = useState<{ [id: string]: string }>({});
  const [audDevices, setAudDevices] = useState<{ [id: string]: string }>({});
  const [vidID, setVidID] = useState<string | undefined>(undefined);
  const [vid, setVid] = useState<MediaStream>();
  const [micID, setMicID] = useState<string | undefined>(undefined);
  const [warning, setWarning] = useState('');
  const localDailyParticipant = useSelector(getLocalState);
  const vidIDref = useRef<string | undefined>(undefined);
  useEffect(() => {
    vidIDref.current = vidID;
  }, [vidID]);

  useEffect(() => {
    let stream = new MediaStream();
    if (camAsScreenState) {
      (async () => {
        function getCam() {
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            setVidDevices({});
            // const vidDevices_: typeof vidDevices = {};
            for (const i in devices) {
              if (devices[i].kind === 'videoinput') {
                setVidID((vidID) => {
                  if (!vidID) vidID = devices[i].deviceId;
                  return vidID;
                });
                setVidDevices((vidDevices) => {
                  return {
                    ...vidDevices,
                    [devices[i].deviceId]: devices[i].label,
                  };
                });
              }
              if (devices[i].kind === 'audioinput') {
                setMicID((micID) => {
                  if (!micID) micID = devices[i].deviceId;
                  return micID;
                });
                setAudDevices((audDevices) => {
                  return {
                    ...audDevices,
                    [devices[i].deviceId]: devices[i].label,
                  };
                });
              }
            }
          });
        }
        const cameraOnly = {
          audio: false,
          video: { deviceId: vidIDref.current },
        };
        const accessCam = async (level: MediaStreamConstraints) => {
          // @ts-ignore
          if (
            // @ts-ignore
            (level.audio === undefined || level.audio.deviceId === undefined) &&
            // @ts-ignore
            (level.video === undefined || level.video.deviceId === undefined)
          ) {
            return;
          }
          stream.getTracks().forEach((track) => {
            track.enabled = false;
            track.stop();
          });
          if (!camAsScreenState) return;
          stream = await navigator.mediaDevices.getUserMedia(level);
          getCam();
          setVid(stream);
        };
        const displayVideo = () => {
          let vidHTML: any = document.getElementById('camAsScreenPreview');
          if (vidHTML) vidHTML.srcObject = stream;
        };
        try {
          getCam();
          await accessCam(cameraOnly);
          displayVideo();
          setWarning('');
        } catch (e) {
          setWarning("Camera permission hasn't been granted, you will not be able to share your camera as screen.");
        }
      })();
    }
    return () => {
      stream.getTracks().forEach((track) => {
        track.enabled = false;
        track.stop();
      });
    };
  }, [vidID, camAsScreenState]);

  return (
    <ReactModal id={camAsScreenID} className={camAsScreenID} isOpen={camAsScreenState} style={ModalStyle}>
      <>
        <div className="body">
          <div className="title">Select your camera and audio source</div>
          <div className="cam-screen">
            <video
              id="camAsScreenPreview"
              style={{
                height: '100%',
                width: '100%',
              }}
              autoPlay
              muted
              playsInline
            />{' '}
          </div>
          <span style={{ paddingTop: '16px', paddingBottom: '5px' }}>Camera</span>
          <DropDown
            headerTitle={vidID && vidDevices[vidID] ? formatLong(vidDevices[vidID], 30) : 'Select Camera'}
            styles={{
              dropDownHeader: {
                paddingLeft: '8px',
                paddingRight: '8px',
                fontSize: '14px',
                border: '1px solid black',
                height: '36px',
                background: 'rgba(0, 0, 0, 0.3)',
                borderRadius: '8px',
              },
              listOuter: {
                backgroundColor: '#393B41',
                borderRadius: '8px',
                border: '1px solid #000000',
                top: '42px',
              },
              listItems: {
                paddingLeft: '8px',
                overflow: 'hidden',
                paddingBottom: '6px',
                paddingTop: '6px',
                fontSize: '14px',
              },
            }}
            showStatusColor={false}
            options={vidDevices}
            optionsListType="array"
            isCamelcase={false}
            isItemDict={true}
            onSelectItem={(e: any, key: any) => {
              setVidID(key);
            }}
          />
          {audioCheck && (
            <>
              <span style={{ paddingTop: '24px', paddingBottom: '5px' }}>Audio</span>
              <DropDown
                headerTitle={micID && audDevices[micID] ? formatLong(audDevices[micID], 30) : 'Select Microphone'}
                styles={{
                  dropDownHeader: {
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    fontSize: '14px',
                    border: '1px solid black',
                    height: '36px',
                    background: 'rgba(0, 0, 0, 0.3)',
                    borderRadius: '8px',
                  },
                  listOuter: {
                    backgroundColor: '#393B41',
                    borderRadius: '8px',
                    border: '1px solid #000000',
                    top: '42px',
                  },
                  listItems: {
                    paddingLeft: '8px',
                    overflow: 'hidden',
                    paddingBottom: '6px',
                    paddingTop: '6px',
                    fontSize: '14px',
                  },
                }}
                showStatusColor={false}
                options={audDevices}
                optionsListType="array"
                isCamelcase={false}
                isItemDict={true}
                onSelectItem={(e: any, key: any) => {
                  setMicID(key);
                }}
              />
            </>
          )}
          <div className="audio-checkbox">
            <div
              className="checkbox"
              onClick={() => {
                setAudioCheck(!audioCheck);
              }}
            >
              {audioCheck && <Check color={'black'} size={12} />}
            </div>
            <span>Share audio</span>
          </div>
        </div>
        <div className="footer">
          <span className="warning">{warning}</span>
          <div className="buttons">
            <div
              className="cancel-btn"
              onClick={() => {
                closeCamAsScreenModal();
              }}
            >
              {warning.length > 0 ? 'Close' : 'Cancel'}
            </div>
            {warning.length === 0 && (
              <div
                className="share-btn"
                onClick={() => {
                  if (vidID) {
                    if (type !== 'fullscreen')
                      localDailyParticipant.screenshare(true, vidID, audioCheck ? micID : undefined, false);
                    else {
                      localDailyParticipant.screenshare(true, vidID, audioCheck ? micID : undefined, true);
                    }
                  }
                  closeCamAsScreenModal();
                }}
              >
                Share
              </div>
            )}
          </div>
        </div>
      </>
    </ReactModal>
  );
};

export default CamAsScreenModal;
